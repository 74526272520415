import { getPixiFieldStrict } from '../../../PixiFieldRepository';
import { getAlignPosition, Layout } from './Layout';
import { marginByFunc } from './utils';
import { resByFunc } from '../../../views/utils';
import { UpdateParams, View } from './types';
import { Point } from '../Shapes/types';

export class HLayout extends Layout {
  TEST_NAME = 'HLayout';
  renderElement({
    shouldRunOnBeforeRender,
    disabledRenderAfterUpdate,
  }: UpdateParams = {}) {
    let zIndex = 0;
    this._background.zOrder(zIndex++);
    let x = 0;
    let maxHeight = 0;
    this._views.forEach((lView, i) => {
      const { view } = lView;
      if (shouldRunOnBeforeRender) {
        view.onBeforeRender();
      }
      const { props: vProps } = lView;
      const gone = resByFunc(vProps.gone, this);
      const visible = resByFunc(vProps.visible, this);
      if (!gone) {
        const itemsOffset = resByFunc(this._layoutProps.itemsOffset!, this);
        this._updateSizeAndZIndex(vProps, view, zIndex);
        const margin = marginByFunc(vProps.margin, this);
        view.y = margin.top;
        view.x = x + margin.left;
        view.renderElement({
          shouldRunOnBeforeRender,
          disabledRenderAfterUpdate,
        });

        x += margin.left + view.width() + margin.right;
        if (i < this._views.length - 1) x += itemsOffset;
        maxHeight = Math.max(
          maxHeight,
          margin.top + view.height() + margin.bottom,
        );
        zIndex += 1;
      }
      if (gone || !visible) {
        view.hide();
      } else {
        view.show();
      }
    });
    this.height(this._layoutProps.height || maxHeight);
    this.width(this._layoutProps.width || x);
    this._updateBackground();
    this._views.forEach((lView) => {
      const { view, props: vProps } = lView;
      const margin = marginByFunc(vProps.margin, this);
      if (vProps.align) {
        view.y =
          getAlignPosition(vProps.align, this.height(), view.height()) +
          margin.top;
      }
    });
    if (!disabledRenderAfterUpdate) {
      getPixiFieldStrict().render();
    }
    return this;
  }

  dragLimits({ view }: { view: Layout }) {
    const endIdx = this._views.length - 1;
    const endView = this._views[endIdx].view;
    const startView = this._views[0].view;
    const { viewport } = getPixiFieldStrict();
    return {
      x: {
        min: (startView.x - view.x) * viewport.scale.x,
        max:
          (endView.x + endView.width() - view.x - view.width()) *
          viewport.scale.x,
      },
      y: { min: 0, max: 0 },
    };
  }

  dragFunc(view: Layout) {
    const limits = this.dragLimits({ view });
    const minY = Math.min(
      0,
      ...this.views()
        .map((v) => resByFunc(v.layoutProperties?.margin)!.top!)
        // filter because type issues with top margin might be undefined
        .filter(Boolean),
    );
    const minYScaled = minY * getPixiFieldStrict().viewport.scale.y;
    return (pos: Point) => {
      const x = Math.min(Math.max(pos.x, limits.x.min), limits.x.max);
      return {
        x,
        y: (minYScaled * x) / (limits.x.max - limits.x.min),
      };
    };
  }

  distanceFunc(view: View) {
    return {
      coordinate: view.x,
      distance: view.width(),
    };
  }
}
