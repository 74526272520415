import React from 'react';
import { PluginType } from '@components/Plugins/common/PluginTypes';
import {
  mapPluginConfigToSegmentationParameter,
  mapSegmentationParameterToPluginConfig,
} from './helpers';
import { EditorPanelPluginProps } from '../../../types';
import { tooltips } from '../../../../views/Menu/menu_view';
import { UserFilter } from '../../common/UserFilter';
import { UserFilterParameter } from '../../common/UserFilter/types';
import { PluginHeader } from '../../common/PluginHeader';
import { logFlowPluginEvent } from '../../../../utils/Analytics';
import { PluginDataChildrenParams } from '../../../../../Plugins/common';
import { Spacer } from '../../../../../../modern-ui/Spacer';
import { Anchor } from '../../../../../../modern-ui/Links';
import { sendEvent } from '../../../../../../utils/Analytics';
import { addAttributePluginFragment_config as AddAttributePluginConfig } from '../../../../../Plugins/AddAttributePlugin/@types/addAttributePluginFragment';
import { ParametersOperator } from '../../../../../../../@types/globalTypes';

export const AddAttributePlugin: React.FC<
  EditorPanelPluginProps<AddAttributePluginConfig>
> = ({ botId, pluginData, pluginParams }) => {
  const { pluginId, pluginType, blockId } = pluginData;

  return (
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    <AddAttributePluginContent
      id={pluginId}
      blockId={blockId}
      botId={botId}
      pluginType={pluginType}
      {...pluginParams}
    />
  );
};

interface AddAttributePluginContentProps
  extends PluginDataChildrenParams<AddAttributePluginConfig> {
  id: string;
  blockId: string;
  botId: string;
  pluginType: PluginType;
}

const AddAttributePluginContent: React.FC<AddAttributePluginContentProps> = ({
  id: cardId,
  botId,
  blockId,
  pluginType,
  pluginConfig,
  savePlugin,
  savePluginAsync,
  setPluginConfigState,
}) => {
  const handleParameterUpdate = (updatedParam: UserFilterParameter) => {
    const config = mapSegmentationParameterToPluginConfig(updatedParam);
    const { title, value } = config;
    const { title: prevTitle, value: prevValue } = pluginConfig;
    if (title !== prevTitle) {
      logFlowPluginEvent(pluginType, 'update attribute title', {
        blockId,
        cardId,
        title,
      });
    } else if (value !== prevValue) {
      logFlowPluginEvent(pluginType, 'update attribute value', {
        blockId,
        cardId,
        value,
      });
    }

    setPluginConfigState({ config });
  };

  return (
    <>
      <PluginHeader
        title={window.i18next.t(
          'AddAttributePlugin-string-2074-set-user-attribute',
        )}
        tooltip={
          <>
            {window.i18next.t(
              'AddAttributePlugin-JSXText-5797-save-a-value-for-a-custom-user-attribute-based-on-the-users-action-in-your-bot',
            )}
            <br />
            <Anchor
              intent="tooltip"
              size="regular"
              target="_blank"
              rel="noopener noreferrer"
              href={tooltips().add_attribute.url}
              onMouseDown={() => {
                sendEvent({
                  category: 'flows',
                  action: 'learn more',
                  label: 'add attribute',
                });
              }}
            >
              {window.i18next.t('AddAttributePlugin-JSXText-1076-learn-more')}
            </Anchor>
          </>
        }
      />
      <Spacer factor={2} />
      <UserFilter
        botId={botId}
        parameter={mapPluginConfigToSegmentationParameter(pluginConfig)}
        operator={ParametersOperator.and}
        onParameterUpdate={handleParameterUpdate}
        savePlugin={savePlugin}
        savePluginAsync={() => savePluginAsync(pluginConfig)}
        userAttributeTypeSelectorHidden
        parametersOperatorDisabled
        customAttributesOnly
        suggestAttributesInValue
        forceMultilineValue
      />
    </>
  );
};
