import React, { useRef } from 'react';
import cn from 'classnames';
import { Flex } from '@ui/Flex';
import { Input } from '@ui/Input';
import { InputErrorBox } from '@ui/Input/InputErrorBox';
import { Spacer } from '@ui/Spacer';
import { AttributeInput } from '@components/AttributeInput';
import { IconButton } from '@ui/IconButton';
import { useHoverDisclosure } from '@ui/HoverDisclosure';
import { AttributesQuery_bot_variableSuggest as Attribute } from '@utils/AttributesUtils/@types/AttributesQuery';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Platform } from '@globals';
import { useJsonPathToAttributeValidate } from './useJsonPathToAttributeValidate';
import * as css from './JsonPathToAttribute.css';

const JSONPATH_MAX_LENGTH = 10000;

interface JsonPathToAttributeProps {
  path: string;
  attr: string;
  index: number;
  platform: Platform;
  autoFocus: boolean;
  uniqueAttributes: Attribute[];
  botAttributes: Attribute[];
  duplicatedAttributeNames: string[];
  onDeleteAt(index: number): void;
  onChangeAttributeAt(index: number, nextAttributeName: string): void;
  onChangeJsonPathAt(index: number, nextJsonPath: string): void;
  onBlur(): void;
}

export const JsonPathToAttribute: React.FC<JsonPathToAttributeProps> = ({
  path,
  attr,
  index,
  platform,
  autoFocus,
  uniqueAttributes,
  botAttributes,
  duplicatedAttributeNames,
  onDeleteAt,
  onChangeAttributeAt,
  onChangeJsonPathAt,
  onBlur,
}) => {
  const { t } = useSafeTranslation();
  const { hasAttributeError, hasJsonPathError, errorText } =
    useJsonPathToAttributeValidate({
      attr,
      path,
      botAttributes,
      duplicatedAttributeNames,
    });

  const containerRef = useRef<HTMLDivElement>(null);
  const { visible } = useHoverDisclosure(containerRef);

  return (
    <>
      <Flex
        ref={containerRef}
        alignItems="center"
        data-testid={`json-path-to-attr_row-${index}`}
      >
        <div
          className={css.jsonPathInput}
          data-testid={`json-path-to-attr_path-${index}`}
        >
          <Input
            value={path}
            autoFocus={autoFocus}
            placeholder="JSON Path"
            error={hasJsonPathError}
            onChange={({ target }) => onChangeJsonPathAt(index, target.value)}
            maxLength={JSONPATH_MAX_LENGTH}
            onBlur={onBlur}
          />
        </div>
        <Spacer horizontalFactor={2} />
        {t('modernComponents.FlowBuilder.plugins.JSONPlugin.setTo')}
        <Spacer horizontalFactor={2} />
        <div
          className={css.attributeInput}
          data-testid={`json-path-to-attr_attribute-${index}`}
        >
          <AttributeInput
            value={attr}
            error={hasAttributeError}
            attributes={uniqueAttributes}
            platform={platform}
            customAttributesOnly
            placeholder={t(
              'modernComponents.FlowBuilder.views.components.CollectUserData.common.attribute',
            )}
            onChange={(nextValue) => onChangeAttributeAt(index, nextValue)}
            onBlur={onBlur}
          />
        </div>
        <Spacer horizontalFactor={2} />
        <IconButton
          icon="delete"
          data-testid={`json-path-to-attr_delete-at-${index}`}
          className={cn(css.deleteButton, {
            [css.deleteButtonVisible]: visible,
          })}
          onClick={() => onDeleteAt(index)}
        />
      </Flex>
      {errorText && (
        <InputErrorBox
          errorText={errorText}
          data-testid={`json-path-to-attr_error-${index}`}
        />
      )}
    </>
  );
};
