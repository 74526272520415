import { useSafeTranslation } from '@utils/useSafeTranslation';
import React, { useMemo } from 'react';
import { MAX_TOKENS } from '../consts';
import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';
import { useTokenCount } from '../TokenCounter/useTokenCount';
import cn from 'classnames';
import { openAiPluginFragment_config_intents } from '@components/Plugins/OpenAiPlugin/@types/openAiPluginFragment';
import { getPromptPreview } from '../PromptTab/getPromptPreview';
import css from './PluginTitle.css';
import { Tooltip2 } from '@ui/Tooltip2';
import { Icon } from '@ui/Icon';
import { Anchor } from '@ui/Links';

interface PluginTitleProps {
  intents: Array<openAiPluginFragment_config_intents>;
  prompt: string;
}

const OPEN_AI_ABOUT_TOKENS_LINK =
  'https://help.openai.com/en/articles/4936856-what-are-tokens-and-how-to-count-them';

export const PluginTitle: React.FC<PluginTitleProps> = ({
  intents,
  prompt,
}) => {
  const { t } = useSafeTranslation();

  const text = useMemo(
    () => getPromptPreview({ intents, prompt }),
    [intents, prompt],
  );
  const { count, loading } = useTokenCount(text);

  const error = (() => {
    if (loading) return null;
    if (count === 0)
      return t(
        'modernComponents.FlowBuilder.views.components.OpenAiPlugin.addSomeInfoForPlugin',
      );
    if (count > MAX_TOKENS)
      return t(
        'modernComponents.FlowBuilder.views.components.OpenAiPlugin.excitedTheTokenLimit',
      );
    return null;
  })();

  return (
    <Flex flexDirection="column" data-testid="openai_plugin_title">
      {t(
        'modernComponents.FlowBuilder.views.components.OpenAiPlugin.editorPanelTitle',
      )}
      <Flex>
        <Type
          data-testid="openai_plugin_title-tokens_count"
          className={cn(css.tokens, loading && css.loading)}
          size="18px"
          weight="medium"
          color={error ? 'red' : 'greyDark'}
        >{`${count}/${MAX_TOKENS} ${t(
          'modernComponents.FlowBuilder.views.components.OpenAiPlugin.tokens',
          { count },
        )}`}</Type>
        <Tooltip2
          placement="right"
          boundariesElement="viewport"
          type="small"
          content={
            <>
              <Type size="12px" color="white">
                {t(
                  'modernComponents.FlowBuilder.views.components.OpenAiPlugin.editorPanelTooltip',
                )}
              </Type>{' '}
              <Anchor
                href={OPEN_AI_ABOUT_TOKENS_LINK}
                intent="external"
                target="_blank"
                size="small"
              >
                {t(
                  'modernComponents.FlowBuilder.views.components.OpenAiPlugin.learnMore',
                )}
              </Anchor>
            </>
          }
          positionFixed
          hoverable
        >
          {(ref, bind) => (
            <Icon
              data-testid="openai_plugin_title-tooltip"
              icon="help"
              color="blue"
              ref={ref}
              {...bind}
            />
          )}
        </Tooltip2>
      </Flex>
      {error && (
        <Type size="12px" color="red" weight="medium">
          {error}
        </Type>
      )}
    </Flex>
  );
};
