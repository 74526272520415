import i18n from 'i18next';
import { logFlowPluginEvent } from '../../utils/Analytics';
import { MainLayout, VLayout } from '../../components/Elements/Layouts';
import { PluginTitleLayout } from '../../views/components/PluginTitleLayout';
import { Polzunok } from '../../views/kit/Polzunok';
import { HTMLText } from '../../components/Elements/Shapes';
import {
  pluginWidth,
  textCardBackgroundColor,
} from '../../views/plugin_consts';
import { Node } from '../../Node';
import { StatefulPlugin, StatefulPluginDelegate } from '../../StatefulPlugin';
import { TypingPluginConfig } from './TypingPluginConst';
import { TypingPlugin } from './TypingPlugin';
import { flowBuilderModule } from '@components/FlowBuilder/FlowBuilderModule';
import { PluginType } from '@components/FlowBuilder/types';
import { Margin } from '@components/FlowBuilder/views/utility_classes';

export class TypingPluginView
  extends VLayout
  implements StatefulPluginDelegate<TypingPluginConfig>
{
  TEST_NAME = 'TypingPluginView';
  private readonly typing: HTMLText;
  private readonly titleLayout: PluginTitleLayout;
  private readonly polzunok: Polzunok;
  private readonly state: StatefulPlugin<TypingPluginConfig>;
  private readonly node: Node;
  private updatedBySource?: string;
  private plugin = flowBuilderModule.getPlugin(
    PluginType.typing,
  ) as TypingPlugin;

  constructor(state: StatefulPlugin<TypingPluginConfig>, node: Node) {
    super({
      width: pluginWidth,
      background: {
        cornerRadius: 10,
        fill: textCardBackgroundColor,
      },
    });
    this.state = state;
    this.state.setDelegate(this);

    this.node = node;

    this.titleLayout = new PluginTitleLayout(
      false,
      i18n.t('modernComponents.FlowBuilder.views.components.Typing.title'),
      this.plugin.getIconTexture(),
      this.state.data,
      this.plugin.tooltip(),
    );
    const wrapper = new MainLayout();

    const margin = new Margin({ left: 10, top: 15, bottom: 10 });
    wrapper.addToLayout(this.titleLayout, { margin });

    this.typing = new HTMLText({
      width: 70,
      align: 'right',
      fontSize: 15,
      verticalAlign: 'center',
      height: this.titleLayout.height(),
    });
    wrapper.layout(this.typing, {
      margin: {
        left: pluginWidth - this.typing.width() - margin.x - 10,
        top: 20,
      },
    });

    this.addToLayout(wrapper);

    this.polzunok = new Polzunok(
      this.state.data.config.timeout_milliseconds! / 20000,
      (newValue, source) => {
        this.updatedBySource = source;
        this.state.set(({ config }) => ({
          config: {
            ...config,
            timeout_milliseconds: Math.round(20 * newValue) * 1000,
          },
        }));
      },
      () => {
        this.state.save();
      },
    );
    this.layout(this.polzunok, {
      margin: { left: 30, bottom: 15 },
      gone: () => !this.state.isEditing,
    });
  }

  pluginDidSave() {
    this.renderNode();
    logFlowPluginEvent('typing', 'update', {
      blockId: this.node.id,
      cardId: this.state.data.id,
      value: this.state.data.config.timeout_milliseconds,
      source: this.updatedBySource,
    });
  }

  onBeforeRender() {
    this.typing.text(`${this.msToText()} sec`);
  }

  msToText() {
    const ms = this.state.data.config.timeout_milliseconds;
    return ms ? Math.round(ms / 1000) : 0;
  }

  destroy() {
    this.state.destroy();
    super.destroy();
  }
}
