import React from 'react';
import { Anchor } from '@ui/Links';
import { sendEvent } from '@utils/Analytics';
import { PluginHeader } from '../../../common/PluginHeader';
import { tooltips } from '../../../../../views/Menu/menu_view';
import { PluginType } from '../../../../../../Plugins/common/PluginTypes';

interface ChatPluginHeaderProps {
  children: string;
  pluginType: PluginType;
}

export const ChatPluginHeader: React.FC<ChatPluginHeaderProps> = ({
  children,
  pluginType,
}) => {
  const tooltip = tooltips()[pluginType as keyof typeof tooltips] as any;

  return (
    <PluginHeader
      title={children}
      tooltip={
        tooltip ? (
          <Anchor
            noUnderline
            intent="unstyled"
            href={tooltip.url}
            target="_blank"
            style={{
              color: 'var(--white)',
            }}
            onClick={() => {
              sendEvent({
                category: 'flows',
                action: 'click to tooltip (learn more)',
                label: pluginType,
              });
            }}
            dangerouslySetInnerHTML={{
              __html: tooltip.text || tooltip.html,
            }}
          />
        ) : null
      }
    />
  );
};
