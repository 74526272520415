import { request, Callback, ErrorCallback } from './common';
import { FlowBlock } from '../types';
import { BlockContextType } from '@globals';

export function createFlowBlock({
  flowId,
  position,
  subtype,
  contextType,
  blockTitle,
  callback,
  cards,
  messageTag,
  otnPurpose,
  onError,
}: {
  flowId: string;
  subtype: string;
  contextType?: BlockContextType;
  position: any;
  cards?: any;
  callback?: Callback<FlowBlock>;
  onError?: ErrorCallback<unknown>;
  blockTitle?: string;
  messageTag?: string;
  otnPurpose?: string;
}) {
  return request({
    url: `/flows/${flowId}/blocks`,
    callback,
    method: 'POST',
    jsonData: {
      type: 'flow',
      cards,
      subtype,
      context_type: contextType,
      position_in_flow: position,
      title: blockTitle,
      message_tag: messageTag,
      otn_purpose: otnPurpose,
    },
    onError,
  });
}
