import React from 'react';
import { Spacer } from '@ui/Spacer';
import {
  LoggedInGreetingConfig,
  LoggedInGreetingControl,
  LoggedOutGreetingConfig,
  LoggedOutGreetingControl,
} from '../controls';
import { ControlProps } from '../types';

interface WelcomeMessageConfig
  extends LoggedInGreetingConfig,
    LoggedOutGreetingConfig {}

interface WelcomeMessageSectionContentProps<T> extends ControlProps<T> {
  prepareDataAndSavePlugin: () => void;
}

export function WelcomeMessageSection<T extends WelcomeMessageConfig>({
  state,
  pluginType,
  pluginConfig,
  prepareDataAndSavePlugin,
}: WelcomeMessageSectionContentProps<T>) {
  return (
    <>
      <LoggedInGreetingControl
        state={state}
        pluginType={pluginType}
        pluginConfig={pluginConfig}
        prepareDataAndSavePlugin={prepareDataAndSavePlugin}
      />

      <Spacer factor={6} />

      <LoggedOutGreetingControl
        state={state}
        pluginType={pluginType}
        pluginConfig={pluginConfig}
        prepareDataAndSavePlugin={prepareDataAndSavePlugin}
      />
    </>
  );
}
