import i18next from 'i18next';

export const pagesListValidator = (item: string) => {
  if (item && (item.trim()[0] !== '/' || item.trim().indexOf(' ') !== -1)) {
    return {
      isValid: false,
      errorMessage: i18next.t('common.validation.badUrlPleaseEnterAValid'),
    };
  }
  return { isValid: true };
};
