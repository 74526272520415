import gql from 'graphql-tag';
import {
  COMMON_PLUGIN_FRAGMENT,
  VALIDATION_FRAGMENT,
} from '../../../Plugins/common/PluginQuery';
import { FlowBuilderPluginParams } from '../../FlowBuilderPlugin';

export const PLUGIN_FRAGMENT = gql`
  fragment assignAdminPluginFragment on AssignAdminPlugin {
    ...commonPluginFragment
    config {
      admin_ids
    }
  }
  ${COMMON_PLUGIN_FRAGMENT}
`;

const ASSIGN_ADMIN_PLUGIN_DATA_QUERY = gql`
  query AssignAdminPluginDataQuery($id: ID!) {
    card(id: $id) {
      ...assignAdminPluginFragment
    }
  }
  ${PLUGIN_FRAGMENT}
`;

const SAVE_ASSIGN_ADMIN_PLUGIN_MUTATION = gql`
  mutation SaveAssignAdminPluginMutationPluginData(
    $blockId: String!
    $pluginType: String!
    $position: Int
    $id: String
    $refresh: Boolean
    $enabled: Boolean
    $synced: Boolean
    $localization: [LocalizationInput!]
    $assignAdminPluginConfig: AssignAdminPluginConfigInput
  ) {
    saveCard(
      blockId: $blockId
      pluginType: $pluginType
      position: $position
      id: $id
      refresh: $refresh
      enabled: $enabled
      synced: $synced
      localization: $localization
      assignAdminPluginConfig: $assignAdminPluginConfig
    ) {
      ... on CardI {
        id
        plugin_id
        is_valid
      }
      ...validationFragment
    }
  }

  ${VALIDATION_FRAGMENT}
`;

const CREATE_ASSIGN_ADMIN_PLUGIN_MUTATION = gql`
  mutation SaveAssignAdminPluginMutationCommon(
    $blockId: String!
    $pluginType: String!
    $position: Int
    $id: String
    $refresh: Boolean
    $enabled: Boolean
    $synced: Boolean
    $localization: [LocalizationInput!]
    $assignAdminPluginConfig: AssignAdminPluginConfigInput
  ) {
    saveCard(
      blockId: $blockId
      pluginType: $pluginType
      position: $position
      id: $id
      refresh: $refresh
      enabled: $enabled
      synced: $synced
      localization: $localization
      assignAdminPluginConfig: $assignAdminPluginConfig
    ) {
      id
      source_card_id
      plugin_id
      is_valid
      ...assignAdminPluginFragment
    }
  }
  ${PLUGIN_FRAGMENT}
`;

export const documentNodes: FlowBuilderPluginParams = {
  cacheDocumentNode: ASSIGN_ADMIN_PLUGIN_DATA_QUERY,
  saveDocumentNode: SAVE_ASSIGN_ADMIN_PLUGIN_MUTATION,
  createDocumentNode: CREATE_ASSIGN_ADMIN_PLUGIN_MUTATION,
};
