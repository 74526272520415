import React from 'react';
import { cardButtonFragment_payment_summary_price_list as Item } from '../../../../../../../Plugins/common/@types/cardButtonFragment';
import { Box } from '@ui/Box';
import { PriceListItem } from './components/PriceListItem';
import { ButtonUnstyled } from '@ui/Button';
import { Type } from '@ui/Type';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { getPriceListItem } from '../../helpers/getDefaultPriceList';
import { Spacer } from '@ui/Spacer';
import { useOneTimeState } from 'cf-common/src/utils/hooks';
import { Divider } from './components/Divider';
import { Callout } from '@ui/Callout';
import { formatPriceEx } from '@utils/Format/priceFormatter';
import { Anchor } from '@ui/Links';
import * as css from './PriceList.css';
import { useCurrencies, calcStripeMinimalAmount } from '@utils/Data/Currency';
import { calcTotalAmount } from '../../helpers/calcTotalAmount';

interface PriceListProps {
  items: Item[];
  currency: string;
  onChange: (items: Item[]) => void;
  onChangeCurrency: (currency: string) => void;
  onSaveRequest: () => void;
}

export const PriceList: React.FC<PriceListProps> = ({
  items,
  currency,
  onChange,
  onSaveRequest,
  onChangeCurrency,
}) => {
  const { t } = useSafeTranslation();
  const [needFocusAfterAdd, setNeedFocusAfterAdd] =
    useOneTimeState<boolean>(false);
  const { hasMinAmountError, getCurrencyByCode } = useCurrencies();

  const currencyInfo = getCurrencyByCode(currency);

  return (
    <Box
      color="baseExtralight"
      paddingFactor={3}
      borderRadiusFactor={2}
      data-testid="price-list__setting-component"
    >
      {items.map((item, index) => (
        <React.Fragment key={item.id}>
          <PriceListItem
            item={item}
            onSaveRequest={onSaveRequest}
            onChange={(updatedItem) => {
              const updatedItems = [...items];
              updatedItems[index] = updatedItem;
              onChange(updatedItems);
            }}
            currency={currency}
            onChangeCurrency={onChangeCurrency}
            showCurrencySelector={index === 0}
            onRemoveRequest={
              items.length > 1
                ? (removedItemId) => {
                    onChange(items.filter(({ id }) => id !== removedItemId));
                    onSaveRequest();
                  }
                : undefined
            }
            autoFocus={needFocusAfterAdd && index === items.length - 1}
          />
          {index < items.length - 1 && <Divider />}
        </React.Fragment>
      ))}
      {hasMinAmountError(calcTotalAmount({ price_list: items }), currency) && (
        <>
          <Divider />
          <Callout
            flexDirection="column"
            data-testid="price-list__total-price-error"
            text={
              <div className={css.callout}>
                <Type color="accent3Text" as="div" weight="semibold">
                  {t('InBotPayments.Panel.PriceList.totalPriceError', {
                    amount: formatPriceEx(
                      calcStripeMinimalAmount(currencyInfo),
                      currency,
                    ),
                  })}
                </Type>
                <Spacer factor={2} />
                <Anchor
                  href="https://stripe.com/docs/currencies#minimum-and-maximum-charge-amounts"
                  intent="external"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {t('InBotPayments.Panel.PriceList.totalPriceErrorLink')}
                </Anchor>
              </div>
            }
            type="warning"
            alignItems="flex-start"
            iconDisabled
          />
        </>
      )}
      <Spacer factor={3} />
      <ButtonUnstyled
        onClick={() => {
          setNeedFocusAfterAdd(true);
          onChange([...items, getPriceListItem('')]);
          onSaveRequest();
        }}
        data-testid="price-list__add-price"
      >
        <Type size="15px" color="accent1Normal">
          {t('InBotPayments.Panel.PriceList.add1')}
        </Type>{' '}
        <Type size="15px">{t('InBotPayments.Panel.PriceList.add2')}</Type>
      </ButtonUnstyled>
    </Box>
  );
};
