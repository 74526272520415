import React, { useCallback, useState } from 'react';
import cn from 'classnames';
import { PluginControlLabel } from '../../../../../Plugins/common/PluginControlLabel';
import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';
import { Tooltip2 } from '@ui/Tooltip2';
import { Icon } from '@ui/Icon';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { TextOutsideControls } from '@ui/TextOutsideControls';
import { InputLikeElement, insertText } from '@utils/documentExecCommand';
import { Input } from '@ui/Input';
import { ControlProps } from '../CustomerChatEntryPoint/components/types';
import { sendEvent } from 'cf-common/src/analytics';
import { TextArea } from '@ui/Input/TextArea';
import * as css from './EntryPointWhatsappPopup.css';

interface TextControlBase<T> extends ControlProps<T> {
  tooltip?: string;
  label: string;
  testid: string;
  required?: boolean;
  placeholder?: string;
  field: string;
  maxLength?: number;
  unit?: string;
  disableEmoji?: boolean;
}

export type TextControlProps<T> = TextControlBase<T> &
  (
    | {
        type: 'number';
        value: number | null;
      }
    | {
        type: 'text' | 'textarea';
        value: string | null;
      }
  );

export const TextControl = <T,>({
  label,
  disableEmoji,
  tooltip,
  testid,
  value: initialValue,
  required,
  state,
  field,
  pluginType,
  maxLength,
  placeholder,
  unit,
  type,
}: TextControlProps<T>) => {
  const { t } = useSafeTranslation();
  const [value, setValue] = useState(initialValue);
  const currentTextLimit =
    maxLength && typeof value !== 'number'
      ? maxLength - (value || '').trim().length
      : undefined;

  const onChange = useCallback(
    (value: string) => {
      const newValue =
        type === 'number'
          ? Number(value.replaceAll(/[^0-9]/g, '') || 0)
          : value;
      setValue(newValue);
      state.set(({ config }) => ({
        config: {
          ...config,
          [field]: typeof newValue === 'number' ? newValue : newValue.trim(),
        },
      }));
    },
    [field, state, type],
  );

  const onBlur = useCallback(() => {
    state.save();
    sendEvent({
      category: 'flows',
      action: `change ${String(field)}`,
      label: pluginType,
      propertyBag: {
        [field]: value,
      },
    });
  }, [field, pluginType, state, value]);

  const InputComponent = type === 'textarea' ? TextArea : Input;

  return (
    <PluginControlLabel
      light
      label={
        <Flex inline>
          <Type weight="semibold" size="12px" color="cobaltTertiary">
            {label}
          </Type>

          {tooltip && (
            <Tooltip2 boundariesElement="viewport" content={tooltip} hoverable>
              {(ref, bind) => (
                <Icon
                  icon="info"
                  size="16px"
                  color="cobaltTertiary"
                  ref={ref}
                  {...bind}
                />
              )}
            </Tooltip2>
          )}
        </Flex>
      }
      data-testid={testid}
    >
      {(bind) => (
        <TextOutsideControls
          onInsertRequest={(text, el) => {
            insertText(text, el as InputLikeElement);
          }}
          currentTextLimit={currentTextLimit}
          shouldShowOutsideControls={{
            symbolsLimit: !!currentTextLimit,
            emoji: !disableEmoji,
          }}
          tight
          initialShow={false}
        >
          {({ ref, getInputProps }) => (
            <Flex alignItems="center" style={{ gap: 4 }}>
              <div
                className={cn({
                  [css.input]: !unit,
                  [css.inputWithUnit]: unit,
                })}
              >
                <InputComponent
                  error={
                    required && typeof value !== 'number' && !value?.length
                  }
                  renderErrorText={() => (
                    <Type size="12px" color="red" weight="medium">
                      {t(
                        'modernComponents.FlowBuilder.EditorPanel.WhatsappPopup.requiredField',
                      )}
                    </Type>
                  )}
                  value={value || value === 0 ? `${value}` : ''}
                  maxLength={maxLength}
                  {...getInputProps<HTMLInputElement | HTMLTextAreaElement>({
                    onBlur,
                    onChange: (e) => {
                      e.preventDefault();
                      onChange(e.currentTarget.value);
                    },
                  })}
                  {...bind}
                  placeholder={placeholder}
                  ref={ref}
                />
              </div>

              {unit && (
                <label htmlFor={bind.id}>
                  <Type className={css.unit}>{unit}</Type>
                </label>
              )}
            </Flex>
          )}
        </TextOutsideControls>
      )}
    </PluginControlLabel>
  );
};
