import React from 'react';
import { Flex } from '@ui/Flex';
import { Input } from '@ui/Input';
import { Type } from '@ui/Type';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Anchor } from '@ui/Links';
import { Spacer } from '@ui/Spacer';
import { TokenCounter } from '../../TokenCounter/TokenCounter';
import { OnboardingTourEventType, OnboardingTourHTMLElementId } from '@globals';
import { OnboardingEmitter } from '@components/Onboarding/OnboardingTour/utils/onboardingEmitter';
import TextareaAutosize from 'react-textarea-autosize';
import css from './OpenAiPluginIntent.css';

interface Intent {
  key: string;
  value: string;
}

interface OpenAiPluginIntentProps {
  intent: Intent;
  onChange: (i: Intent) => void;
  canDelete: boolean;
  onDelete: (i: Intent) => void;
  index: number;
}

export const OpenAiPluginIntent: React.FC<OpenAiPluginIntentProps> = ({
  intent,
  onChange,
  canDelete,
  onDelete,
  index,
}) => {
  const { t } = useSafeTranslation();
  return (
    <Flex
      flexDirection="column"
      alignItems="flex-end"
      className={css.intent}
      data-testid="open_ai_intents"
    >
      <Flex flexDirection="row" gapFactor={1.5} fullWidth>
        <Type size="12px" color="greyDark" className={css.label}>
          {t(
            'modernComponents.FlowBuilder.views.components.OpenAiPlugin.userIntent',
          )}
        </Type>
        <Input
          data-testid="open_ai_intent_key"
          containerClassName={css.input}
          value={intent.key}
          onChange={(input) =>
            onChange({
              key: input.target.value,
              value: intent.value,
            })
          }
        />
      </Flex>
      <Spacer factor={1.5} />
      <Flex
        flexDirection="row"
        gapFactor={1.5}
        fullWidth
        id={`${OnboardingTourHTMLElementId.OpenAiPluginInstruction}${index}`}
      >
        <Type size="12px" color="greyDark" className={css.label}>
          {t(
            'modernComponents.FlowBuilder.views.components.OpenAiPlugin.aiInstructions',
          )}
        </Type>
        <Input
          containerClassName={css.input}
          value={intent.value}
          render={({ getInputProps }) => (
            <TextareaAutosize
              data-testid="open_ai_intent_value"
              {...getInputProps({
                className: css.textarea,
                value: intent.value,
              })}
              onChange={(input) => {
                onChange({
                  key: intent.key,
                  value: input.target.value,
                });
                OnboardingEmitter.emit(OnboardingTourEventType.change, {
                  element: `${OnboardingTourHTMLElementId.OpenAiPluginInstruction}${index}`,
                  value: input.target.value,
                });
              }}
            />
          )}
        />
      </Flex>
      <Spacer factor={2} />
      <Flex justifyContent="space-between" fullWidth>
        <TokenCounter text={`${intent.key} ${intent.value}`} />
        {canDelete && (
          <Anchor
            intent="external"
            noUnderline
            size="small"
            onClick={() => onDelete(intent)}
          >
            {t(
              'modernComponents.FlowBuilder.views.components.OpenAiPlugin.delete',
            )}
          </Anchor>
        )}
      </Flex>
    </Flex>
  );
};
