import React, { useRef } from 'react';
import Maybe from 'graphql/tsutils/Maybe';
import { useFocusWithin } from '@ui/FocusWithin';
import { Input } from '@ui/Input';
import {
  deserialize,
  TextWithAttributesEditor,
} from '@ui/TextWithAttributesEditor';
import { AttributesQuery_bot_variableSuggest as Attribute } from '@utils/AttributesUtils/@types/AttributesQuery';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { ignoreEnterKey } from '@utils/DOM/keyHandlers';

interface JsonUrlFieldProps {
  url: Maybe<string>;
  error: boolean;
  autoFocus: boolean;
  botAttributes: Attribute[];
  className?: string;
  onChange(nextValue: string): void;
  onBlur(): void;
}

export const JsonUrlField: React.FC<JsonUrlFieldProps> = ({
  url,
  error,
  autoFocus,
  botAttributes,
  className,
  onChange,
  onBlur,
}) => {
  const { t } = useSafeTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const { focusedWithin } = useFocusWithin(containerRef);

  return (
    <div ref={containerRef}>
      <Input
        error={!focusedWithin && error}
        render={() => (
          <TextWithAttributesEditor
            defaultValue={deserialize(url || '')}
            onStringChange={onChange}
            onBlur={onBlur}
            placeholder={t('JSONPlugin-string-1144-enter-url')}
            attributes={botAttributes}
            containerClassName={className}
            autoFocus={autoFocus}
            onKeyDown={ignoreEnterKey}
            trimOnBlur
            hasManageAttributes
          />
        )}
      />
    </div>
  );
};
