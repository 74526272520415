import { ATTR_SIGN } from '@ui/TextWithAttributesEditor';
import {
  attributeWrapper,
  wrapAllAttributes,
} from '@ui/TextWithAttributesEditor/attributesBoundariesUtils';
import { SCALE_MODES, Sprite, Texture } from 'pixi.js-legacy';
import { escapeAttrValue, filterXSS } from 'xss';
import { debounce } from 'lodash-es';
import { getPixiFieldStrict, getPixiField } from '../../../PixiFieldRepository';
import { renderHtmlToTexture } from './helpers';
import { Shape } from './Shape';
import {
  FontWeightKey,
  HTMLTextProps,
  TextAlign,
  TextDecoration,
  VerticalTextAlign,
} from './types';
import { fontFamily } from '../../../consts';
import { wrapAllLinks } from '../../../views/utils';
import { HEXColors } from '@ui/_common/colors';
import { getHtmlElementSize } from '@utils/DOM/getHtmlElementSize';

const CELL_V_ALIGN_TO_FLEX = {
  top: 'flex-start',
  center: 'center',
  bottom: 'flex-end',
};

export const FONT_STYLE_TO_WEIGHT: Record<FontWeightKey, number> = {
  bold: 700,
  semibold: 600,
  normal: 400,
};

const defaultTextProps = {
  text: '',
  resolution: window.devicePixelRatio,
  fontFamily,
  fontSize: 12,
  fontStyle: 'normal' as 'normal',
  fill: HEXColors.black,
  lineHeight: 1.4,
  textDecoration: 'none' as 'none',
  align: 'left' as 'left',
  verticalAlign: 'top' as 'top',
  singleLine: false,
  attributeOnly: false,
  trustedHtml: false,
};

// eslint-disable-next-line no-control-regex
const lineTabulationRegex = new RegExp('\u000b', 'g');
const replaceUnicode = (text: string) => text.replace(lineTabulationRegex, ' ');

export class HTMLText extends Shape<HTMLTextProps, Sprite> {
  TEST_NAME = 'HTMLText';

  _cacheTexture?: Texture;

  _tempWidth?: number;

  _tempHeight?: number;

  _needCacheUpdate = false;

  rendered = false;

  constructor(props: HTMLTextProps) {
    super(
      {
        viewport: getPixiFieldStrict().viewport,
        ...defaultTextProps,
        ...props,
      },
      Sprite,
    );

    const sprite = this._shape;
    sprite.on('added', () => {
      setTimeout(() => {
        if (this._props.viewport) {
          // TODO remove during rewrite destroy logic
          // @ts-ignore
          this.shape().zoomedFunc = this.updateScale;
          this._props.viewport.on('zoomed', this.updateScale);
        }
      });
      if (this._props.viewport) {
        this.resolution(this._props.viewport.scale.x * window.devicePixelRatio);
      }
    });
    sprite.once('removed', () => {
      if (this._props.viewport) {
        this._props.viewport.off('zoomed', this.updateScale);
      }
    });
  }

  destroy() {
    this._shape.off('added');
    this._shape.off('removed');
    super.destroy();
    this._cacheTexture?.destroy(true);
    this._cacheTexture = undefined;
  }

  text(t?: string) {
    return this._changeProp('text', t);
  }

  fill(t?: string) {
    return this._changeProp('fill', t);
  }

  fontSize(size?: number) {
    return this._changeProp<number>('fontSize', size);
  }

  fontFamily(f?: string) {
    return this._changeProp('fontFamily', f);
  }

  fontStyle(f?: string) {
    return this._changeProp('fontStyle', f);
  }

  fontColor(f?: string) {
    return this._changeProp('fontColor', f);
  }

  lineHeight(f?: number) {
    return this._changeProp<number>('lineHeight', f);
  }

  align(f?: TextAlign) {
    return this._changeProp<TextAlign>('align', f);
  }

  verticalAlign(f?: VerticalTextAlign) {
    return this._changeProp<VerticalTextAlign>('verticalAlign', f);
  }

  textDecoration(f?: TextDecoration) {
    return this._changeProp<TextDecoration>('textDecoration', f);
  }

  resolution(scale?: number) {
    return this._changeProp<number>('resolution', scale);
  }

  singleLine(value?: boolean) {
    return this._changeProp<boolean>('singleLine', value);
  }

  updateScaleDebounce = debounce(() => {
    if (this._props.viewport) {
      this.resolution(this._props.viewport.scale.x * window.devicePixelRatio);
      this.renderElement();
    }
  }, 300);

  updateScale = () => {
    if (this._cacheTexture && this._shape.texture !== this._cacheTexture) {
      this._shape.texture?.destroy(true);
      this._shape.texture = this._cacheTexture;
    }
    this.updateScaleDebounce();
  };

  width(w?: number) {
    const result = this._changeProp<number>('width', w);
    return result || this._tempWidth || this._shape.width || 0;
  }

  height(h?: number) {
    const result = this._changeProp<number>('height', h);
    return result || this._tempHeight || this._shape.height || 0;
  }

  _correctedX() {
    const { width, align, x } = this._props;
    if (width && width > 0) {
      if (align === 'center') {
        return (x || 0) + (width - (this._tempWidth || 0)) / 2;
      }
      if (align === 'right') {
        return (x || 0) + (width - (this._tempWidth || 0));
      }
    }
    return x;
  }

  _correctedY() {
    const { height, verticalAlign, y } = this._props;
    if (height && height > 0) {
      if (verticalAlign === 'center') {
        return (y || 0) + (height - (this._tempHeight || 0)) / 2;
      }
      if (verticalAlign === 'bottom') {
        return height - (this._tempHeight || 0);
      }
    }
    return y;
  }

  _version = 0;

  move() {
    /**
     * На x и у висят геттеры/сеттеры при присвоении значение записывается в transform.position.x
     * https://github.com/pixijs/pixi.js/blob/4d32f412ee5b16b8ddb77072ee38d9b924979f77/packages/display/src/DisplayObject.ts#L822
     * Но при деинициализации transform зачищает свою ссылку https://github.com/pixijs/pixi.js/blob/4d32f412ee5b16b8ddb77072ee38d9b924979f77/packages/display/src/DisplayObject.ts#L750
     * Поэтому перед тем как сетнуть значения, надо проверить не был ли деинициализирован текущий инстанс
     */
    // @ts-ignore
    if (this.destroyed) {
      return;
    }

    this._shape.x = this._correctedX() || 0;
    this._shape.y = this._correctedY() || 0;
  }

  _changeProp<V = string>(property: keyof HTMLTextProps, value?: V) {
    const currentValue = this._props[property] as unknown as V;
    if (property !== 'resolution' && currentValue !== value) {
      this._needCacheUpdate = true;
    }
    return super._changeProp<V>(property, value);
  }

  _drawCacheTexture(drawingVersion: number) {
    if (this._needCacheUpdate || !this._cacheTexture) {
      this._needCacheUpdate = false;
      this.createTexture(2, SCALE_MODES.LINEAR, (texture) => {
        if (drawingVersion === this._version) {
          if (this._shape.texture !== this._cacheTexture) {
            this._cacheTexture?.destroy(true);
          }
          this._cacheTexture = texture;
        }
      });
    }
  }

  _drawShape() {
    this._version += 1;
    const drawingVersion = this._version;

    if (this.rendered) {
      this._drawCacheTexture(drawingVersion);
    } else {
      setTimeout(() => {
        this._drawCacheTexture(drawingVersion);
        getPixiField()?.render();
      }, Math.random() * 10000);
    }

    const { x, y } = this.createTexture(
      this._props.resolution || 0,
      SCALE_MODES.NEAREST,
      (texture) => {
        if (drawingVersion === this._version) {
          if (this._shape.texture !== this._cacheTexture) {
            this._shape.texture?.destroy(true);
          }
          this._shape.texture = texture;
          this._tempWidth = x;
          this._tempHeight = y;
          this.rendered = true;
          this.move();
        }
      },
    );

    this._tempWidth = x;
    this._tempHeight = y;
    this.move();
  }

  createDefaultTextCSS() {
    const {
      fontSize,
      lineHeight,
      fill,
      align,
      fontFamily,
      fontStyle,
      verticalAlign,
      textDecoration,
    } = this._props;

    return `
            display: flex;
            color: ${fill};
            line-height: ${lineHeight};
            justify-content: ${align};
            align-items: ${
              CELL_V_ALIGN_TO_FLEX[
                verticalAlign || defaultTextProps.verticalAlign
              ]
            };
            font-size: ${fontSize}px;
            font-family: ${fontFamily};
            font-weight: ${
              FONT_STYLE_TO_WEIGHT[fontStyle || defaultTextProps.fontStyle]
            };
            text-align: ${align};
            min-height: ${
              (lineHeight || defaultTextProps.lineHeight) *
              (fontSize || defaultTextProps.fontSize)
            }px;
            text-decoration: ${textDecoration};
        `;
  }

  createTextCss() {
    let cssText = '';

    const { singleLine, width } = this._props;

    if (singleLine && width) {
      cssText = `
        overflow: hidden;
        width: ${width}px;
        text-overflow: ellipsis;
      `;
    }

    if (!singleLine && width) {
      cssText = `
        ${cssText}
        width: ${width}px;
        white-space: pre-wrap;
        word-break: break-word;
        word-wrap: break-word;
      `;
    } else {
      cssText = `
        ${cssText}
        display: inline-block;
        white-space: pre;
      `;
    }

    return cssText;
  }

  createTypeTextElement() {
    const div = document.createElement('div');
    const {
      text,
      singleLine,
      shouldShowAttributes,
      shouldUnderlineLinks,
      shouldJoinAttributes,
      attributeOnly,
      trustedHtml,
      predefinedAttributes,
    } = this._props;

    let html = replaceUnicode(text?.toString() || '');

    if (!trustedHtml) {
      html = filterXSS(html, {
        onIgnoreTagAttr: (_, name, value) =>
          name === 'style' ? `${name}="${escapeAttrValue(value)}"` : undefined,
      });
    }

    const attributeWrapperCurrent = attributeWrapper(
      !!singleLine,
      Boolean(shouldJoinAttributes),
      predefinedAttributes,
    );

    if (shouldUnderlineLinks) {
      html = wrapAllLinks(html);
    }

    if (shouldShowAttributes && !attributeOnly) {
      html = wrapAllAttributes(html, attributeWrapperCurrent);
    }

    if (attributeOnly) {
      html = attributeWrapperCurrent(
        `${ATTR_SIGN.start}${html}${ATTR_SIGN.end}`,
        'inherit',
      );
    }

    div.innerHTML = `<span>${html}${
      [10, 13].includes(html.charCodeAt(html.length - 1))
        ? '<span style="color:transparent">.</span>'
        : '' // fix height error if text end NL symbol
    }</span>`;

    return div;
  }

  createTexture(
    resolution: number,
    scaleMode: SCALE_MODES,
    callback: (texture: Texture) => void,
  ) {
    const { width } = this._props;

    const div = this.createTypeTextElement();
    div.style.cssText = `
    ${this.createDefaultTextCSS()}
    ${this.createTextCss()}
    `;

    return renderHtmlToTexture({
      html: div.outerHTML,
      width,
      scale: resolution,
      scaleMode,
      callback,
    });
  }

  getTextWidth() {
    const el = this.createTypeTextElement();
    el.style.cssText = `
        ${this.createDefaultTextCSS()}
        display: inline-block;
        white-space: pre;
      `;
    const { currentWidth } = getHtmlElementSize(el.outerHTML);
    return currentWidth;
  }
}
