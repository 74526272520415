import React, { FC } from 'react';
import { connectPage } from '@components/ConnectPageDialog';
import { logFlowEvent } from '../../../../../utils/Analytics';
import { Flex } from '../../../../../../../modern-ui/Flex';
import { Type } from '../../../../../../../modern-ui/Type';
import { Spacer } from '../../../../../../../modern-ui/Spacer';
import { Button } from '../../../../../../../modern-ui/Button';
import { ReactComponent as InfoIcon } from '../../../../../../../modern-ui/_deprecated/Icon/icons/error-info.svg';
import * as css from './NeedConnectPageNote.css';
import { useCurrentBotId } from '../../../../../../../utils/Routing';

export const NeedConnectPageNote: FC = () => {
  const botId = useCurrentBotId();
  return (
    <>
      <Flex>
        <InfoIcon className={css.infoIcon} />
        <div>
          <Type size="15px_DEPRECATED">
            {window.i18next.t(
              'NeedConnectPageNote-JSXText-8500-note-entry-point-is-not-working-because',
            )}
            <br />
            {window.i18next.t(
              'NeedConnectPageNote-JSXText-2839-facebook-page-is-not-connected',
            )}
          </Type>
          <Spacer factor={2} />
          <Button
            onClick={() => {
              if (botId) {
                logFlowEvent('entry_point', 'connect facebook page click');
                connectPage({ botId });
              }
            }}
          >
            {window.i18next.t(
              'NeedConnectPageNote-JSXText--133-connect-facebook-page',
            )}
          </Button>
          <Spacer factor={6} />
        </div>
      </Flex>
    </>
  );
};
