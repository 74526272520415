import { RequestedUserInfo } from '@globals';
import i18next from 'i18next';
import memoize from 'lodash-es/memoize';
import { getCurrencyFormatOptions } from '@utils/Format/priceFormatter';

export const DEFAULT_PAYMENT_ATTRIBUTES = [
  'messenger user id',
  'first name',
  'last name',
];

export const getAmountMaskConfig = memoize((currency: string) => {
  const {group, decimal, fraction} = getCurrencyFormatOptions(currency);
  return {
    mask: Number,
    scale: fraction.length,
    signed: false,
    thousandsSeparator: group,
    padFractionalZeros: true,
    normalizeZeros: false,
    radix: decimal,
    mapToRadix: ['.', ','],
    min: 0,
    max: 1000000000000,
  };
});

export const PANEL_WIDTH = 414;

export const getRequestedUserInfoItems = memoize(() => [
  {
    id: RequestedUserInfo.contact_name,
    title: i18next.t(
      'InBotPayments.Panel.PriceList.RequestUserData.contact_name',
    ),
  },
  {
    id: RequestedUserInfo.contact_phone,
    title: i18next.t(
      'InBotPayments.Panel.PriceList.RequestUserData.contact_phone',
    ),
  },
  {
    id: RequestedUserInfo.contact_email,
    title: i18next.t(
      'InBotPayments.Panel.PriceList.RequestUserData.contact_email',
    ),
  },
  {
    id: RequestedUserInfo.shipping_address,
    title: i18next.t(
      'InBotPayments.Panel.PriceList.RequestUserData.shipping_address',
    ),
  },
]);
