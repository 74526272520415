import React from 'react';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import { Icon } from '@ui/Icon';

interface ChannelTitleProps {
  title: string;
  icon: string;
}

export const ChannelTitle: React.FC<ChannelTitleProps> = ({ title, icon }) => (
  <Flex alignItems="center">
    <Icon icon={icon} size="16px" color="black" />
    <Spacer factor={0} horizontalFactor={2} />
    <Type size="15px" weight="semibold">
      {title}
    </Type>
  </Flex>
);
