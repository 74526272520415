import React, { ChangeEvent } from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { TextArea } from '@ui/Input/TextArea';
import { Flex } from '@ui/Flex';
import { Input } from '@ui/Input';
import { Spacer } from '@ui/Spacer';
import { Button } from '@ui/Button';
import { TextOutsideControls } from '@ui/TextOutsideControls';
import { sendEvent } from '@utils/Analytics';
import { PluginControlLabel } from '@components/Plugins/common/PluginControlLabel';
import { DEFAULT_GREETING } from '@components/Plugins/CustomerChatEntryPoint/CustomerChatEntryPointConst';
import { PluginType } from '@components/Plugins/common/PluginTypes';
import { InputLikeElement, insertText } from '@utils/documentExecCommand';
import { Item, SimpleCombobox } from '@ui/SimpleCombobox';
import { SendToMessengerColor, SendToMessengerSize } from '@globals';
import { PluginHeader } from '../../common/PluginHeader';
import { EditorPanelPluginProps } from '../../../types';
import { shopifyBackInStockEntryPointFragment_config as ShopifyBackInStockEntryPointConfig } from '../../../../../Plugins/ShopifyBackInStockEntryPoint/@types/shopifyBackInStockEntryPointFragment';
import { ReactComponent as DropdownIcon } from '../../../../../../modern-ui/_deprecated/Icon/icons/ic_dropdown_arr.svg';
import { withDefaultConfig } from '../../../../views/entry-points/EntryPointShopifyBackInStock';
import {
  BUTTON_COLORS,
  BUTTON_SIZE,
  getCTATexts,
} from '../SendToMessengerEntryPoint/consts';
import * as css from './BackInStockEntryPoint.css';
import backInStock from '../../../../assets/entry_point/menu-images/back-in-stock.png';

const TEXTS_LIMITS = {
  title: 52,
  description: 80,
};

export const BackInStockEntryPoint: React.FC<
  EditorPanelPluginProps<ShopifyBackInStockEntryPointConfig>
> = ({ pluginParams }) => {
  const { pluginConfig, savePlugin, setPluginConfigState } = pluginParams;
  const { t } = useSafeTranslation();

  const CTA_TEXTS = getCTATexts();

  const config = withDefaultConfig(pluginConfig);

  return (
    <>
      <PluginHeader
        title={t('shopify.backInStock.settings.header')}
        tooltip={
          <div
            style={{
              width: 214,
            }}
          >
            <img src={backInStock} alt="" width={214} height={247} />
            <br />
            {t('shopify.backInStock.settings.text')}
          </div>
        }
      />

      <PluginControlLabel label={t('shopify.backInStock.settings.title')}>
        {(bind) => {
          const currentTextLimit =
            TEXTS_LIMITS.title - config.title.trim().length;

          return (
            <TextOutsideControls
              onInsertRequest={(text, el) => {
                insertText(text, el as InputLikeElement);
              }}
              currentTextLimit={currentTextLimit}
              shouldShowOutsideControls={{
                emoji: true,
                symbolsLimit: true,
              }}
              tight
              initialShow={false}
            >
              {({ ref, getInputProps }) => {
                const inputProps = getInputProps<HTMLInputElement>({
                  onBlur: () => {
                    savePlugin();
                    sendEvent({
                      category: 'flows',
                      action: 'title changed',
                      label: PluginType.shopify_back_in_stock,
                    });
                  },
                });

                const onChange = (event: ChangeEvent<HTMLInputElement>) => {
                  const newConfig = {
                    ...config,
                    title: event.currentTarget.value,
                  };
                  setPluginConfigState({ config: newConfig });
                };

                return (
                  <Input
                    data-testid="flows_back-in-stock-entry-point_title"
                    value={config.title}
                    onChange={onChange}
                    placeholder={DEFAULT_GREETING}
                    maxLength={TEXTS_LIMITS.title}
                    {...inputProps}
                    {...bind}
                    ref={ref}
                  />
                );
              }}
            </TextOutsideControls>
          );
        }}
      </PluginControlLabel>

      <Spacer factor={4} />

      <PluginControlLabel label={t('shopify.backInStock.settings.text')}>
        {(bind) => {
          const currentTextLimit =
            TEXTS_LIMITS.description - config.description.length;
          const controls = {
            emoji: true,
            symbolsLimit: true,
          };

          return (
            <TextOutsideControls
              onInsertRequest={(text, el) => {
                insertText(text, el as InputLikeElement);
              }}
              currentTextLimit={currentTextLimit}
              shouldShowOutsideControls={controls}
              tight
              initialShow={false}
            >
              {({ ref, getInputProps }) => {
                const inputProps = getInputProps<HTMLTextAreaElement>({
                  onBlur: () => {
                    savePlugin();
                    sendEvent({
                      category: 'flows',
                      action: 'text changed',
                      label: PluginType.shopify_back_in_stock,
                    });
                  },
                });

                const onChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
                  const newConfig = {
                    ...config,
                    description: event.currentTarget.value,
                  };
                  setPluginConfigState({ config: newConfig });
                };

                return (
                  <TextArea
                    data-testid="flows_popup-entry-point_main-text"
                    rows={2}
                    value={config.description}
                    onChange={onChange}
                    placeholder={DEFAULT_GREETING}
                    maxLength={TEXTS_LIMITS.description}
                    {...inputProps}
                    {...bind}
                    ref={ref}
                  />
                );
              }}
            </TextOutsideControls>
          );
        }}
      </PluginControlLabel>

      <Spacer factor={4} />

      <Flex justifyContent="space-between">
        <PluginControlLabel label={t('shopify.buttonSize')}>
          {(bind) => {
            const selectedItem = BUTTON_SIZE.find(
              ({ id }) => id === config.button_size,
            );

            const onChange = (item: Item | null) => {
              const button_size =
                (item?.id as SendToMessengerSize) ||
                SendToMessengerSize.standard;

              setPluginConfigState({
                config: {
                  ...config,
                  button_size,
                },
              });
              savePlugin();
              sendEvent({
                category: 'flows',
                action: 'change size',
                label: PluginType.shopify_back_in_stock,
                propertyBag: {
                  size: button_size,
                },
              });
            };

            return (
              <SimpleCombobox
                items={BUTTON_SIZE}
                selectedItem={selectedItem}
                onChange={onChange}
                renderInput={({ getToggleButtonProps, selectedItem }) => (
                  <Button
                    data-testid="flows_back-in-stock-entry-point_button-size"
                    intent="secondary"
                    {...getToggleButtonProps()}
                    iconRight={<DropdownIcon />}
                    className={css.ddButton}
                    {...bind}
                  >
                    {selectedItem?.title}
                  </Button>
                )}
                menuItemStyle={{
                  minWidth: 146,
                }}
              />
            );
          }}
        </PluginControlLabel>

        <PluginControlLabel label={t('shopify.buttonColor')}>
          {(bind) => {
            const selectedItem = BUTTON_COLORS.find(
              ({ id }) => id === config.button_color,
            );

            const onChange = (item: Item | null) => {
              const button_color =
                (item?.id as SendToMessengerColor) || SendToMessengerColor.blue;

              setPluginConfigState({
                config: {
                  ...config,
                  button_color,
                },
              });
              savePlugin();
              sendEvent({
                category: 'flows',
                action: 'change color',
                label: PluginType.shopify_back_in_stock,
                propertyBag: {
                  color: button_color,
                },
              });
            };

            return (
              <SimpleCombobox
                items={BUTTON_COLORS}
                selectedItem={selectedItem}
                onChange={onChange}
                renderInput={({ getToggleButtonProps, selectedItem }) => (
                  <Button
                    data-testid="flows_back-in-stock-entry-point_button-color"
                    intent="secondary"
                    {...getToggleButtonProps()}
                    iconRight={<DropdownIcon />}
                    className={css.ddButton}
                    {...bind}
                  >
                    {selectedItem?.title}
                  </Button>
                )}
                menuItemStyle={{
                  minWidth: 146,
                }}
              />
            );
          }}
        </PluginControlLabel>
      </Flex>

      <Spacer factor={4} />

      <PluginControlLabel label={t('shopify.buttonText')}>
        {(bind) => {
          const selectedItem =
            CTA_TEXTS.find(({ id }) => id === config.button_text) ||
            CTA_TEXTS[0];

          const onChange = (item: Item | null) => {
            const button_text = item?.id || '';

            setPluginConfigState({
              config: {
                ...config,
                button_text,
              },
            });
            savePlugin();
            sendEvent({
              category: 'flows',
              action: 'change cta_text',
              label: PluginType.shopify_back_in_stock,
              propertyBag: {
                cta_text: button_text,
              },
            });
          };

          return (
            <SimpleCombobox
              items={CTA_TEXTS}
              selectedItem={selectedItem}
              onChange={onChange}
              renderInput={({ getToggleButtonProps, selectedItem }) => (
                <Button
                  data-testid="flows_back-in-stock-entry-point_button-text"
                  intent="secondary"
                  {...getToggleButtonProps()}
                  iconRight={<DropdownIcon />}
                  className={css.ddButton}
                  style={{ width: 360 }}
                  {...bind}
                >
                  {selectedItem?.title}
                </Button>
              )}
            />
          );
        }}
      </PluginControlLabel>
    </>
  );
};
