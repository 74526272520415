import React from 'react';
import { lensPath } from 'ramda';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { AdditionalProps } from '../types';
import { LabeledTokensView } from './LabeledTokensView';
import { useAttributes } from '@utils/AttributesUtils/AttributesData';
import { VariableSuggestType } from '@utils/AttributesUtils/AttributesUtilsTypes';
import { useCurrentBotId } from '@utils/Routing';
import { Platform } from '@globals';

export interface DynamicUrlButtonProps extends AdditionalProps {
  text: string;
}

export const DynamicUrlButton: React.FC<DynamicUrlButtonProps> = (props) => {
  const botId = useCurrentBotId()!;
  const { t } = useSafeTranslation();
  const { urlButtonParameter } = props.pluginConfig;
  const { attributes } = useAttributes(
    botId,
    VariableSuggestType.abandoned_cart_template,
    Platform.whatsapp,
  );

  return (
    <LabeledTokensView
      getTotalLength={() => 2000}
      parameters={urlButtonParameter && [urlButtonParameter.parameter]}
      getLens={() => lensPath(['urlButtonParameter', 'parameter', 'text'])}
      label={t(
        'modernComponents.FlowBuilder.plugins.WhatsappTemplatePlugin.button',
      )}
      {...props}
      attributes={attributes}
    />
  );
};
