import { useCallback, useEffect, useRef, useState } from 'react';
import {
  paymentButtonEditorEmitter,
  PaymentButtonEditorEvent,
} from '../events';
import {
  CardButtonWithPaymentData,
  PaymentButtonEditorEventPayload,
} from '../types';
import { DEFAULT_PAYMENT_ATTRIBUTES } from '../consts';
import { ButtonPopupOverlayConfig as CardButton } from '../../../../../FlowBuilderOverlay/overlays';
import { clone } from 'ramda';
import { useSafeTranslation } from '@utils/useSafeTranslation';

export const usePaymentButtonConfig = () => {
  const [config, setConfigState] = useState<CardButton | undefined>();
  const configRef = useRef<CardButton | undefined>(); // for immediate save
  const buttonSymbolRef = useRef<Symbol | undefined>();
  const { t } = useSafeTranslation();

  const setConfig = useCallback((updatedConfig: CardButton | undefined) => {
    setConfigState(updatedConfig);
    configRef.current = updatedConfig;
  }, []);

  const saveConfig = useCallback(() => {
    const config = configRef.current;
    if (!config || !buttonSymbolRef.current) {
      return;
    }
    paymentButtonEditorEmitter.emit<PaymentButtonEditorEventPayload>(
      PaymentButtonEditorEvent.updateConfig,
      {
        config: {
          ...config,
          title:
            config?.title.trim() ||
            t('InBotPayments.Panel.defaultButtonName'),
          variables: DEFAULT_PAYMENT_ATTRIBUTES,
        },
        symbol: buttonSymbolRef.current,
      },
    );
  }, [t]);

  const updateConfig = useCallback(
    (
      callback: (
        updatedConfig: CardButtonWithPaymentData,
      ) => CardButtonWithPaymentData,
    ) => {
      const updatedConfig = clone(config);
      if (!updatedConfig?.payment_summary) {
        return;
      }
      setConfig(callback(updatedConfig as CardButtonWithPaymentData));
    },
    [config, setConfig],
  );

  const dropConfig = useCallback(() => {
    setConfig(undefined);
  }, [setConfig]);

  useEffect(
    () =>
      paymentButtonEditorEmitter.on<PaymentButtonEditorEventPayload>(
        PaymentButtonEditorEvent.show,
        ({ config, symbol }) => {
          setConfig(config);
          buttonSymbolRef.current = symbol;
        },
      ),
    [setConfig],
  );

  useEffect(
    () =>
      paymentButtonEditorEmitter.on(PaymentButtonEditorEvent.hide, () => {
        if (!config) {
          return;
        }
        saveConfig();
        setConfig(undefined);
      }),
    [config, saveConfig, setConfig],
  );

  return {
    updateConfig,
    saveConfig,
    config,
    dropConfig,
  };
};
