import { UserFilterParameter } from '../../common/UserFilter/types';
import { addAttributePluginFragment_config as AddAttributePluginConfig } from '../../../../../Plugins/AddAttributePlugin/@types/addAttributePluginFragment';
import {
  ParameterFilterValueOperator,
  UserAttributeType,
} from '../../../../../../../@types/globalTypes';

export const mapPluginConfigToSegmentationParameter = ({
  title: name,
  value,
}: AddAttributePluginConfig): UserFilterParameter => ({
  __typename: 'SegmentationParameter',
  name,
  operation: ParameterFilterValueOperator.is,
  type: UserAttributeType.custom,
  values: [value],
});

export const mapSegmentationParameterToPluginConfig = ({
  name: title,
  values,
}: UserFilterParameter): AddAttributePluginConfig => ({
  __typename: 'AddAttributePluginConfig',
  title,
  value: values[0] ?? '',
});
