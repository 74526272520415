import isEmpty from 'lodash-es/isEmpty';
import { clone } from 'ramda';
import {
  prepareValidationErrors,
  getPreparedToSaveMutationVariables,
  Card,
} from '@components/Plugins/common';
import { log } from 'cf-common/src/logger';
import {
  temporaryPluginDataQuery,
  PLUGINS_FRAGMENT,
  savePluginMutation,
} from '../../../Plugins/common/PluginGQL';
import * as Types from '../types';
import client from '../../../../common/services/ApolloService';
import { getFlowControllerStrict } from '../../PixiFieldRepository';
import { refetchJsonAdsDataIfNeed } from '@utils/Data/Marketing/getAdsJsonDataObservable';
import { FLOW_BLOCKS_QUERY } from '@utils/Data/Flow';
import {
  FlowBlocksQuery,
  FlowBlocksQueryVariables,
} from '@utils/Data/Flow/@types/FlowBlocksQuery';

export const subscribeEditPlugin = <TPluginConfig extends {}>(
  pluginId: string,
  onPluginUpdate: (
    plugin: Types.Card<TPluginConfig>,
    validationErrors: Types.ValidationErrors,
  ) => void,
  onError: (errorValue: any) => void,
) => {
  const querySubscription = temporaryPluginDataQuery
    .watch({ id: pluginId })
    .subscribe({
      next: ({ loading, data }) => {
        if (!loading && !isEmpty(data.card)) {
          const plugin = data.card;
          const validationErrors = prepareValidationErrors(
            plugin as any,
          ) as Types.ValidationErrors;
          onPluginUpdate(plugin as Types.Card, validationErrors);
        }
      },
      error: (value) => {
        onError(value);
      },
    });

  return () => querySubscription.unsubscribe();
};

export const getPluginData = <TPluginConfig>(
  id: string,
): Types.PluginDataType<TPluginConfig> | null => {
  try {
    return temporaryPluginDataQuery.read({
      id,
    }) as Types.PluginDataType<TPluginConfig>;
  } catch (error) {
    return null;
  }
};

export const setPluginData = <TPluginConfig>(
  id: string,
  data: Types.PluginDataType<TPluginConfig>,
) => {
  try {
    temporaryPluginDataQuery.write(data, { id });
  } catch (error) {
    log.warn({ error, msg: `Error update plugin in cache (id: ${id})` });
  }
};

export const updatePluginData = <TPluginConfig>(
  id: string,
  mutableUpdateFn: (data: Types.PluginDataType<TPluginConfig>) => void,
) => {
  const data = getPluginData<TPluginConfig>(id);
  if (data) {
    const newData = clone(data);
    mutableUpdateFn(newData);
    setPluginData(id, newData);
  }
};

export const removePluginFromCache = (pluginId: string) => {
  const {
    flow: { id: flowId, botId },
  } = getFlowControllerStrict();
  let data;
  try {
    data = client.readQuery<FlowBlocksQuery, FlowBlocksQueryVariables>({
      query: FLOW_BLOCKS_QUERY,
      variables: { botId, flowId },
    });
  } catch {
    data = null;
  }
  if (!data) {
    return;
  }
  const updatedData = clone(data);
  updatedData.bot.flowBlocks?.forEach((block) => {
    // eslint-disable-next-line no-param-reassign
    block.cards = block.cards?.filter(({ id }) => id !== pluginId) || [];
  });
  client.writeQuery<FlowBlocksQuery, FlowBlocksQueryVariables>({
    query: FLOW_BLOCKS_QUERY,
    variables: { botId, flowId },
    data: updatedData,
  });
};

interface AfterSavePluginActionsArgs {
  botId: string;
  blockId: string;
}

const afterSavePluginActions = ({
  botId,
  blockId,
}: AfterSavePluginActionsArgs) => {
  refetchJsonAdsDataIfNeed(botId, blockId);
};

export const savePlugin = async <TPluginConfig extends {}>({
  pluginId: id,
  blockId,
  pluginType,
  position,
}: Types.SavePluginConfig): Promise<void> => {
  const { botId } = getFlowControllerStrict().flow;

  let card: Types.PluginDataType<TPluginConfig>['card'] | undefined;
  try {
    card = getPluginData<TPluginConfig>(id)?.card;
  } catch (error) {
    log.warn({ error, msg: `Error while reading ${id} plugin data` });
  }

  if (!card) {
    return Promise.resolve();
  }

  const pluginConfig = card.config;
  const localization = card.localization ?? [];
  const refresh = card.refresh ?? null;
  const enabled = card.enabled ?? null;
  const synced = card.synced ?? null;

  const variables = getPreparedToSaveMutationVariables({
    pluginConfig,
    localization,
    restVariables: {
      id,
      blockId,
      pluginType,
      position,
      refresh,
      enabled,
      synced,
    },
  });

  await savePluginMutation(pluginType, variables);

  afterSavePluginActions({ botId, blockId });
  return undefined;
};

export const setPluginConfigState = <TPluginConfig>(
  id: string,
  card: Types.ISetPluginStateParams<TPluginConfig>,
) => {
  const pluginData = getPluginData<typeof card>(id);
  if (pluginData) {
    setPluginData(id, {
      card: {
        ...pluginData.card,
        ...card,
      },
    });
  }
};

export const writePluginFragment = (card: Card) => {
  try {
    client.writeFragment({
      fragment: PLUGINS_FRAGMENT,
      fragmentName: 'pluginsFragment',
      id: card.id,
      data: card,
    });
  } catch (error) {
    log.warn({
      error,
      msg: `Error update plugin fragment in cache (id: ${card.id})`,
    });
  }
};
