import memoize from 'lodash-es/memoize';
import { Spacer } from '@ui/Spacer';
import i18next from 'i18next';
import React, { useCallback } from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { PluginDataChildrenParams } from '../../../../../../Plugins/common';
import { PluginType } from '../../../../../../Plugins/common/PluginTypes';
import { StatefulPlugin } from '../../../../../StatefulPlugin';
import { getEntryPointClientCode } from '../../common/getEntryPointClientCode';
import { NeedConnectPageNote } from '../../common/NeedConnectPageNote';
import { pagesListValidator } from '../../common/pageListValidator';
import { customerChatEntryPointFragment_config as CustomerChatEntryPointConfig } from '../../../../../../Plugins/CustomerChatEntryPoint/@types/customerChatEntryPointFragment';
import { ChatPluginHeader } from './ChatPluginHeader';
import { CodeControl } from './controls';
import { simpleDomainValidate } from '@components/DomainsWhitelisting';
import { CollapsibleSectionWithShadow } from '@ui/CollapsibleSection/CollapsibleSectionWithShadow/CollapsibleSectionWithShadow';
import * as css from './Sections/Section.css';
import {
  SiteSettingsSection,
  WelcomeMessageSection,
  AppearanceSection,
} from './Sections';

interface CustomerChatEntryPointContentProps
  extends PluginDataChildrenParams<CustomerChatEntryPointConfig> {
  botId: string;
  blockId: string;
  pluginId: string;
  pageId: string;
  state: StatefulPlugin<CustomerChatEntryPointConfig>;
}

const getPanelTitles = memoize(
  () =>
    ({
      [PluginType.customer_chat_entry_point]: i18next.t(
        'modernComponents.FlowBuilder.views.components.CustomerChat.editPanelTitle',
      ),
      [PluginType.shopify_customer_chat_entry_point]: i18next.t(
        'shopify.editPanelTitle',
      ),
    } as Record<string, string>),
);

const getPanelTitle = (pluginType: string) =>
  getPanelTitles()[pluginType] ?? '';

export const CustomerChatEntryPointContent: React.FC<CustomerChatEntryPointContentProps> =
  ({ blockId, pluginConfig, pageId, state }) => {
    const pluginType = state.data.plugin_id;

    const { t } = useSafeTranslation();
    const prepareDataAndSavePlugin = useCallback(() => {
      const config = {
        ...pluginConfig,
        logged_in_greeting: pluginConfig.logged_in_greeting?.trim() || null,
        logged_out_greeting: pluginConfig.logged_out_greeting?.trim() || null,
      };
      state.set({ config });
      state.save();
    }, [pluginConfig, state]);

    const code = getEntryPointClientCode(blockId);

    const hasDomainError =
      pluginType === PluginType.customer_chat_entry_point &&
      !simpleDomainValidate(pluginConfig.domains?.[0] || '');

    return (
      <>
        <ChatPluginHeader pluginType={pluginType}>
          {getPanelTitle(pluginType)}
        </ChatPluginHeader>

        {!pageId && <NeedConnectPageNote />}

        {pluginType === PluginType.customer_chat_entry_point && (
          <>
            <CodeControl code={code} pluginType={pluginType} />

            <Spacer factor={6} />
          </>
        )}

        <CollapsibleSectionWithShadow
          initialValue={hasDomainError}
          title={t(
            'modernComponents.FlowBuilder.EditorPanel.CustomerChat.siteSettings',
          )}
        >
          <div className={css.section}>
            <SiteSettingsSection
              state={state}
              pluginConfig={pluginConfig}
              pluginType={pluginType}
              validationErrorFunc={pagesListValidator}
            />
          </div>
        </CollapsibleSectionWithShadow>

        <Spacer factor={6} />

        <CollapsibleSectionWithShadow
          title={t(
            'modernComponents.FlowBuilder.EditorPanel.CustomerChat.welcomeMessage',
          )}
        >
          <div className={css.section}>
            <WelcomeMessageSection
              state={state}
              pluginType={pluginType}
              pluginConfig={pluginConfig}
              prepareDataAndSavePlugin={prepareDataAndSavePlugin}
            />
          </div>
        </CollapsibleSectionWithShadow>

        <Spacer factor={6} />

        <CollapsibleSectionWithShadow
          title={t(
            'modernComponents.FlowBuilder.EditorPanel.CustomerChat.appearance',
          )}
        >
          <div className={css.section}>
            <AppearanceSection
              state={state}
              pluginConfig={pluginConfig}
              pluginType={pluginType}
            />
          </div>
        </CollapsibleSectionWithShadow>
      </>
    );
  };
