import React from 'react';
import { Spacer } from '@ui/Spacer';
import { GreetingDialogDisplay } from '@globals';
import {
  GreetingDialogDelayConfig,
  GreetingDialogDelayControl,
  GreetingDialogDisplayConfig,
  GreetingDialogDisplayControl,
  SupportedDevicesConfig,
  SupportedDevicesControl,
  ThemeColorConfig,
  ThemeColorControl,
} from '../controls';
import { ControlProps } from '../types';
import { GREETING_DIALOG_DISPLAY_VARIANTS } from '../../consts';

interface AppearanceConfig
  extends ThemeColorConfig,
    GreetingDialogDisplayConfig,
    GreetingDialogDelayConfig,
    SupportedDevicesConfig {}

export function AppearanceSection<T extends AppearanceConfig>({
  pluginConfig,
  state,
  pluginType,
}: ControlProps<T>) {
  return (
    <>
      <ThemeColorControl
        pluginConfig={pluginConfig}
        state={state}
        pluginType={pluginType}
      />

      <Spacer factor={6} />

      <GreetingDialogDisplayControl
        items={GREETING_DIALOG_DISPLAY_VARIANTS()}
        field="greeting_dialog_display"
        value={
          pluginConfig.greeting_dialog_display || GreetingDialogDisplay.show
        }
        state={state}
        pluginType={pluginType}
        pluginConfig={pluginConfig}
      />

      {pluginConfig.greeting_dialog_display !== GreetingDialogDisplay.hide && (
        <>
          <Spacer factor={6} />

          <GreetingDialogDelayControl
            field="greeting_dialog_delay"
            value={pluginConfig.greeting_dialog_delay || 0}
            state={state}
            pluginType={pluginType}
            pluginConfig={pluginConfig}
          />
        </>
      )}

      <Spacer factor={6} />

      <SupportedDevicesControl
        state={state}
        pluginType={pluginType}
        pluginConfig={pluginConfig}
      />
    </>
  );
}
