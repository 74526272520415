import React from 'react';
import { QuickReplyType } from '@globals';
import { CollectEmailsView } from './CollectEmails/CollectEmailsView';
import { EditorPanelPluginProps } from '../../../types';
import { QuickReplyPluginConfig } from '../../../../../Plugins/QuickReplyPlugin/QuickReplyPluginConst';
import { CollectPhonesView } from './CollectPhones/CollectPhonesView';
import { CollectNumbersView } from './CollectNumbers/CollectNumbersView';
import { CollectAnswers } from './CollectAnswers/CollectAnswersView';
import { QuickReplyPluginData } from './types';
import { BLOCK_SUBTYPES } from '../../../../consts';

export const QuickReplyPlugin: React.FC<
  EditorPanelPluginProps<QuickReplyPluginConfig>
> = ({ pluginData, pluginParams }) => {
  const VIEW_BY_TYPE: Record<string, React.FC<QuickReplyPluginData>> = {
    [QuickReplyType.email]: CollectEmailsView,
    [QuickReplyType.number]: CollectNumbersView,
    [QuickReplyType.phone]: CollectPhonesView,
    [QuickReplyType.text]: CollectAnswers,
  };

  const { type } = pluginParams.pluginConfig;
  const View = VIEW_BY_TYPE[type!];

  if (!View) {
    throw new Error(`Unsupported type ${type}`);
  }

  const hideFollowUps = [
    BLOCK_SUBTYPES.entrypoint,
    BLOCK_SUBTYPES.comments_autoreply_content,
    BLOCK_SUBTYPES.instagram_comments_autoreply_content,
    BLOCK_SUBTYPES.broadcast_flow_root,
  ].includes(pluginData.blockSubtype as string);

  const showResponseTimeout = [BLOCK_SUBTYPES.send_message].includes(
    pluginData.blockSubtype as string,
  );

  return (
    <View
      {...pluginParams}
      hideFollowUps={hideFollowUps}
      showResponseTimeout={showResponseTimeout}
    />
  );
};
