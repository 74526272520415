import gql from 'graphql-tag';
import { QueryHookOptions, useQuery } from 'react-apollo';
import {
  KommoPipelinesQuery,
  KommoPipelinesQueryVariables,
} from './@types/KommoPipelinesQuery';

const KOMMO_PIPELINES_QUERY = gql`
  query KommoPipelinesQuery($botId: String!) {
    kommoPipelines(botId: $botId) {
      id
      name
      statuses {
        id
        name
        type
      }
    }
  }
`;

export const useKommoPipelines = (
  options: QueryHookOptions<KommoPipelinesQuery, KommoPipelinesQueryVariables>,
) =>
  useQuery<KommoPipelinesQuery, KommoPipelinesQueryVariables>(
    KOMMO_PIPELINES_QUERY,
    options,
  );
