import {
  ServerStorageItemKeys,
  serverStorageItemSet,
} from '@utils/ServerStorage';
import { request, Callback, ErrorCallback } from './common';
import { createFlowBlock as createFlowBlockCommon } from './createFlowBlock';
import { getPixiField } from '../PixiFieldRepository';
import { FlowBlock, FlowTypes, Point } from '../types';
import { NodePosition } from '../utils/organizeBlocks/helpers';
import { BlockContextType } from '@globals';
import Maybe from 'graphql/tsutils/Maybe';

interface CreateFlowBlockArgs {
  flowId: string;
  subtype: string;
  contextType?: BlockContextType;
  position: any;
  cards?: any;
  callback?: Callback<FlowBlock>;
  onError?: ErrorCallback<unknown>;
  blockTitle?: string;
  messageTag?: string;
  otnPurpose?: string;
}

export function createFlowBlock({
  flowId,
  subtype,
  contextType,
  position,
  cards,
  callback,
  onError,
  blockTitle,
  messageTag,
  otnPurpose,
}: CreateFlowBlockArgs) {
  return createFlowBlockCommon({
    flowId,
    subtype,
    contextType,
    position,
    cards,
    callback: (dataResult: any, data: any) => {
      callback?.(dataResult, data);
      // TODO: check where it should be
      return serverStorageItemSet(ServerStorageItemKeys.isBlockAdded, true);
    },
    onError,
    blockTitle,
    messageTag,
    otnPurpose,
  });
}

export function getBlocks(
  flowId: string,
): Promise<FlowTypes.AggregatedFlowQuery_bot_flowBlocks[]> {
  return request({ url: `/flows/${flowId}/blocks` });
}

export function getUser() {
  return request({ url: '/user' });
}

export function getBlock(blockId: string) {
  return request({ url: `/blocks/${blockId}` });
}

export function createBlock(
  groupId: string,
  title: string,
  callback: Callback,
) {
  return request({
    callback,
    url: `/groups/${groupId}/blocks`,
    method: 'POST',
    jsonData: { title },
  });
}

export function removeBlock(
  blockId: string,
  callback: Callback,
  onError: ErrorCallback,
) {
  return request({
    url: `/blocks/${blockId}?block_type=PUBLISHED`,
    method: 'DELETE',
    callback,
    onError,
  });
}

export type QueryParams = Record<string, string>;

export function uploadFile(
  file: FormData,
  callback: (url: string) => void,
  onError: ErrorCallback,
  query?: QueryParams,
) {
  return request({
    url: '/media/upload',
    callback,
    method: 'POST',
    plainData: file,
    customHeaders: {},
    onError,
    query,
  });
}

export function updateFlowCard(
  blockId: string,
  card: any,
  callback?: Callback,
  onError?: ErrorCallback,
) {
  if (getPixiField()?.isViewOnly()) {
    return undefined;
  }
  const jsonData = {
    id: card.id,
    config: card.config,
    plugin_id: card.plugin_id,
    block_type: 'flow',
    enabled: card.enabled,
    forced: card.forced,
    refresh: card.refresh,
  };
  if (typeof card.position !== 'undefined') {
    (jsonData as any).position = card.position;
  }
  return request({
    url: `/blocks/${blockId}/cards`,
    method: 'POST',
    jsonData,
    callback,
    onError,
  });
}

export function updateRootBlock(
  blockId: string,
  title: string,
  nextBlockIds: string[],
  callback?: Callback,
) {
  return request({
    url: `/blocks/${blockId}`,
    callback,
    method: 'POST',
    jsonData: {
      title,
      next_block_ids: nextBlockIds,
    },
  });
}

interface UpdateFlowBlockArgs {
  blockId: string;
  title: string;
  nextBlockIds: string[];
  position: Point;
  callback: Callback;
  messageTag: Maybe<string>;
  otnPurpose: Maybe<string>;
  notificationTopicId: Maybe<string>;
}

export function updateFlowBlock({
  blockId,
  title,
  nextBlockIds,
  position,
  callback,
  messageTag,
  otnPurpose,
  notificationTopicId,
}: UpdateFlowBlockArgs) {
  return request({
    url: `/blocks/${blockId}`,
    callback,
    method: 'POST',
    jsonData: {
      title,
      type: 'flow',
      next_block_ids: nextBlockIds,
      position_in_flow: position,
      message_tag: messageTag,
      otn_purpose: otnPurpose,
      notification_topic_id: notificationTopicId,
    },
  });
}

export function updateFlowBlockPositions(
  flowId: string,
  blockPositions: NodePosition[],
  callback?: Callback,
) {
  return request({
    url: `/flows/${flowId}/block_positions`,
    callback,
    method: 'POST',
    jsonData: { blocks: blockPositions },
  });
}

export function removeFlowCard(cardId: string, callback: Callback) {
  return request({
    url: `/cards/${cardId}`,
    method: 'DELETE',
    callback,
  });
}
