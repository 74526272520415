import React, { useCallback } from 'react';
import { GreetingDialogDisplay } from '@globals';
import { Button } from '@ui/Button';
import { ReactComponent as DropdownIcon } from '@ui/_deprecated/Icon/icons/ic_dropdown_arr.svg';
import { sendEvent } from '@utils/Analytics';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { PluginControlLabel } from '../../../../../../../Plugins/common/PluginControlLabel';
import { Item, SimpleCombobox } from '@ui/SimpleCombobox';
import { ControlProps } from '../types';
import * as css from '../../CustomerChatEntryPoint.css';

export interface GreetingDialogDisplayConfig {
  greeting_dialog_display: GreetingDialogDisplay | null;
}

export interface GreetingDialogDisplayControlProps<T> extends ControlProps<T> {
  items: Item[];
  field: string;
  value: string;
}

export function GreetingDialogDisplayControl<T>({
  state,
  pluginType,
  field,
  value,
  items,
}: GreetingDialogDisplayControlProps<T>) {
  const { t } = useSafeTranslation();

  const selectedItem = items.find(({ id }) => id === value);

  const onChange = useCallback(
    (item: Item) => {
      state.set(({ config }) => ({
        config: {
          ...config,
          [field]: item.id as GreetingDialogDisplay,
        },
      }));
      state.save();
      sendEvent({
        category: 'flows',
        action: `change ${field}`,
        label: pluginType,
        propertyBag: {
          [field]: item.id,
        },
      });
    },
    [state, field, pluginType],
  );

  return (
    <PluginControlLabel
      light
      label={t(
        'modernComponents.FlowBuilder.EditorPanel.GreetingDialogDisplayControl',
      )}
      data-testid="whatsapp-chat-widget__welcome-message-behavior-dropdown"
    >
      {(bind) => (
        <SimpleCombobox<Item>
          items={items}
          selectedItem={selectedItem}
          // @ts-ignore
          onChange={onChange}
          menuboxStyle={{
            minWidth: 326,
          }}
          renderInput={({ getToggleButtonProps, selectedItem }) => (
            <Button
              intent="secondary"
              {...getToggleButtonProps()}
              iconRight={<DropdownIcon />}
              className={css.dropdownButton}
              {...bind}
            >
              {selectedItem?.title}
            </Button>
          )}
        />
      )}
    </PluginControlLabel>
  );
}
