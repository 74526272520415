import React, { useState } from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { GestureNavigation } from 'cf-common/src/gestureNavigation';
import { Dialog, DialogHeading } from '@ui/Dialog';
import { CloseButton, FlexDialogContent } from '@ui/Dialog/ActionDialogParts';
import { IS_CHROME_BROWSER } from 'cf-common/src/environment';
import { Flex } from '@ui/Flex';
import {
  SegmentedGroupTrack,
  SegmentedGroupTrackColorWay,
} from '@ui/SegmentedGroup/SegmentedGroupTrack';
import {
  Button,
  ButtonColorWay,
  ButtonIntent,
  ButtonSize,
} from '@ui/_deprecated/Button';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import { MouseInfo, TrackpadInfo } from './Infos';
import * as css from './GesturesModal.css';
import { ChromeAlert } from '../../../ChromeAlert';

export interface GesturesModalProps {
  initialGesturesType: GestureNavigation;
  onSubmit: (chosenType: GestureNavigation) => void;
  onDismiss: () => void;
}

export const GesturesModal: React.FC<GesturesModalProps> = ({
  initialGesturesType,
  onSubmit,
  onDismiss,
}) => {
  const { t } = useSafeTranslation();
  const [gesturesType, setGesturesType] =
    useState<GestureNavigation>(initialGesturesType);

  return (
    <Dialog className={css.gesturesModal}>
      <FlexDialogContent>
        <DialogHeading>
          <Flex alignItems="baseline" justifyContent="flex-end">
            <CloseButton aria-label="close" onClick={onDismiss} />
          </Flex>
        </DialogHeading>
        <Flex alignItems="center" className={css.gesturesModalTitle}>
          <Type size="24px" weight="semibold" align="center">
            {t('modernComponents.FlowBuilder.modals.GesturesModal.header')}
          </Type>
        </Flex>

        <Spacer factor={6} />
        {!IS_CHROME_BROWSER && (
          <>
            <ChromeAlert mode="block" />
            <Spacer factor={8} />
          </>
        )}

        <Flex flexDirection="column" alignItems="center">
          <SegmentedGroupTrack
            role="tablist"
            colorWay={SegmentedGroupTrackColorWay.grey}
            style={{ width: '200px' }}
          >
            <Button
              role="tab"
              intent={ButtonIntent.toggle}
              colorWay={ButtonColorWay.toggleWhiteHover}
              size={ButtonSize.s}
              aria-selected={gesturesType === GestureNavigation.mouse}
              onClick={() => setGesturesType(GestureNavigation.mouse)}
            >
              {t('modernComponents.FlowBuilder.modals.GesturesModal.mouse')}
            </Button>
            <Button
              role="tab"
              intent={ButtonIntent.toggle}
              colorWay={ButtonColorWay.toggleWhiteHover}
              size={ButtonSize.s}
              aria-selected={gesturesType === GestureNavigation.trackpad}
              onClick={() => setGesturesType(GestureNavigation.trackpad)}
            >
              {t('modernComponents.FlowBuilder.modals.GesturesModal.trackpad')}
            </Button>
          </SegmentedGroupTrack>
          {gesturesType === GestureNavigation.mouse && <MouseInfo />}
          {gesturesType === GestureNavigation.trackpad && <TrackpadInfo />}
          <Button
            intent={ButtonIntent.primary}
            onClick={() => onSubmit(gesturesType)}
          >
            {t('modernComponents.FlowBuilder.modals.GesturesModal.apply')}
          </Button>
          <Spacer horizontalFactor={9} />
        </Flex>
      </FlexDialogContent>
    </Dialog>
  );
};
