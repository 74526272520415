import { HEXColors } from '@ui/_common/colors';
import { Graphics } from 'pixi.js-legacy';
import { colorToHex } from '../../../views/utils';
import { Shape } from './Shape';
import { ShapeProps } from './types';

export class Cross extends Shape<ShapeProps, Graphics> {
  TEST_NAME = 'Cross';

  constructor(props: ShapeProps) {
    super(
      {
        stroke: HEXColors.black,
        strokeWidth: 1,
        ...props,
      },
      Graphics,
    );
  }

  _drawShape() {
    const { _props: props, _shape: shape } = this;
    shape.clear();
    shape.lineStyle(
      props.strokeWidth || 0,
      colorToHex(props.stroke),
      props.opacity,
    );
    shape.moveTo(0, 0);
    shape.lineTo(props.width || 0, props.height || 0);
    shape.moveTo(props.width || 0, 0);
    shape.lineTo(0, props.height || 0);
  }
}
