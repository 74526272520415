import { Point } from '../../types';
import { ClipboardFlowBlock } from './types';

export const CANVAS_CENTER: Point = { x: 0, y: 0 };

export const getBlocksPositionsOffsetFromMousePosition = (
  mousePosition: Point,
  blocks: ClipboardFlowBlock[],
) =>
  blocks
    .sort(({ position: lhsPosition }, { position: rhsPosition }) => {
      if (lhsPosition && rhsPosition) {
        return lhsPosition.x > rhsPosition.x
          ? 1
          : lhsPosition.x < rhsPosition.x
          ? -1
          : 0;
      }
      if (lhsPosition && !rhsPosition) {
        return -1;
      }
      if (!lhsPosition && rhsPosition) {
        return 1;
      }
      return 0;
    })
    .reduce((acc, { position }, index) => {
      const { x: currentBlockX, y: currentBlockY } = position ?? CANVAS_CENTER;
      if (index === 0) {
        acc.push({
          x: mousePosition?.x ?? currentBlockX,
          y: mousePosition?.y ?? currentBlockY,
        });
      } else {
        const { x: pivotBlockX, y: pivotBlockY } = acc[0];
        const pivotBlockOriginalPosition = blocks[0].position ?? CANVAS_CENTER;
        const pivotBlockOriginalX = pivotBlockOriginalPosition.x;
        const pivotBlockOriginalY = pivotBlockOriginalPosition.y;
        const pivotBlockXDiff =
          pivotBlockX > pivotBlockOriginalX
            ? pivotBlockX - pivotBlockOriginalX
            : pivotBlockOriginalX - pivotBlockX;
        const pivotBlockYDiff =
          pivotBlockY > pivotBlockOriginalY
            ? pivotBlockY - pivotBlockOriginalY
            : pivotBlockOriginalY - pivotBlockY;

        acc.push({
          x: currentBlockX + pivotBlockXDiff,
          y: currentBlockY + pivotBlockYDiff,
        });
      }
      return acc;
    }, [] as Point[]);
