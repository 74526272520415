import i18next from 'i18next';
import { Item } from '@ui/SimpleCombobox';
import { GreetingDialogDisplay, SupportedDevices } from '@globals';
import { memoize } from 'lodash-es';

export const GREETING_MESSAGE_LIMIT = 80;

export const GREETING_DIALOG_DISPLAY_VARIANTS = memoize(
  () =>
    [
      {
        id: GreetingDialogDisplay.show,
        title: i18next.t(
          'CustomerChatEntryPointContent-string--271-display-and-leave-open',
        ),
      },
      {
        id: GreetingDialogDisplay.fade,
        title: i18next.t(
          'CustomerChatEntryPointContent-string--169-display-briefly-then-hide',
        ),
      },
      {
        id: GreetingDialogDisplay.hide,
        title: i18next.t(
          'CustomerChatEntryPointContent-string-1080-display-on-click',
        ),
      },
    ] as Item[],
);

export const DEVICES_VARIANTS = memoize(
  () =>
    [
      {
        id: SupportedDevices.ALL,
        title: i18next.t(
          'CustomerChatEntryPointContent-string--132-mobile-and-desktop',
        ),
      },
      {
        id: SupportedDevices.DESKTOP,
        title: i18next.t(
          'CustomerChatEntryPointContent-string-1105-desktop-only',
        ),
      },
      {
        id: SupportedDevices.MOBILE,
        title: i18next.t(
          'CustomerChatEntryPointContent-string-2948-mobile-only',
        ),
      },
    ] as Item[],
);
