import { FlowBuilderPlugin } from './FlowBuilderPlugin';
import { PluginType } from './types';

export interface ModulePararms {
  plugins: ReadonlyArray<FlowBuilderPlugin>;
}

export interface IModule {
  getPlugin(id: PluginType): FlowBuilderPlugin;
  getPlugins(): ReadonlyArray<FlowBuilderPlugin>;
  hasPlugin(id: PluginType): boolean;
}

export class Module implements IModule {
  private readonly pluginsMap: Map<PluginType, FlowBuilderPlugin>;

  constructor(private readonly params: ModulePararms) {
    this.pluginsMap = new Map(this.params.plugins.map((p) => [p.id, p]));
  }

  getPlugin(id: PluginType): FlowBuilderPlugin {
    const plugin = this.pluginsMap.get(id);

    if (!plugin) {
      throw new Error(
        'Module error: plugin was not found. You must add it before using',
      );
    }

    return plugin;
  }

  getPlugins(): readonly FlowBuilderPlugin[] {
    return this.params.plugins;
  }

  hasPlugin(id: PluginType): boolean {
    return this.pluginsMap.has(id);
  }
}
