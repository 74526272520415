import React from 'react';
import { CheckBox } from '@ui/CheckBox';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useFlowPlatform } from '@utils/Data/Flow/useFlowPlatform';
import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';
import { Spacer } from '@ui/Spacer';
import { Dropdown } from '@ui/_deprecated/Dropdown';
import * as ButtonDeprecated from '@ui/_deprecated/Button';
import { PluginDataChildrenParams } from '@components/Plugins/common';
import {
  jsonPluginRequestTypes,
  JsonPluginRequestType,
  bodilessTypes,
} from '@components/Plugins/JsonPlugin/JsonPluginConst';
import { useReactPluginConfig } from '@components/Plugins/common/useReactPluginConfig';
import { JsonRequestHeaders } from '@components/Plugins/JsonPlugin/JsonRequestHeaders';
import { jsonPluginFragment_config as JSONPluginConfig } from '@components/Plugins/JsonPlugin/@types/jsonPluginFragment';
import { EditorPanelPluginProps } from '../../../types';
import { AttributesMapping } from './AttributesMapping';
import { AttributesMappingHeader } from './AttributesMappingHeader';
import { useJsonResponseMappingActions } from './useJsonResponseMappingActions';
import { JsonTestRequest } from './JsonTestRequest';
import { JsonPluginHeader } from './JsonPluginHeader';
import { JsonUrlField } from './JsonUrlField';
import { JsonPostData } from './JsonPostData';
import { useAttributes } from '@utils/AttributesUtils/AttributesData';
import { VariableSuggestType } from '@utils/AttributesUtils/AttributesUtilsTypes';
import * as css from './JSONPlugin.css';

export const JSONPlugin: React.FC<EditorPanelPluginProps<JSONPluginConfig>> = ({
  botId,
  pluginData,
  pluginParams,
}) => {
  const { pluginId } = pluginData;

  return (
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    <JsonPluginContent botId={botId} pluginId={pluginId} {...pluginParams} />
  );
};

interface JsonPluginContentProps
  extends PluginDataChildrenParams<JSONPluginConfig> {
  botId: string;
  pluginId: string;
}

const JsonPluginContent: React.FC<JsonPluginContentProps> = ({
  botId,
  pluginId,
  savePluginAsync,
  pluginConfig,
  validationErrors,
  saving,
  isNewPlugin,
  setPluginConfigState,
}) => {
  const { t } = useSafeTranslation();
  const { configState, updateConfig, savePluginSafely } = useReactPluginConfig({
    saving,
    pluginConfig,
    setPluginConfigState,
    savePluginAsync,
  });
  const platform = useFlowPlatform();
  const { attributes: botAttributes } = useAttributes(
    botId,
    VariableSuggestType.template,
    platform,
  );

  const {
    switchResponseMapping,
    handleAdd,
    handleDeleteAt,
    handleChangeAttributeAt,
    handleChangeJsonPathAt,
  } = useJsonResponseMappingActions({
    pluginConfig: configState,
    updateConfig,
    savePluginSafely,
  });

  return (
    <>
      <JsonPluginHeader />
      <Spacer />
      <Dropdown
        className={css.pluginMethodDropdown}
        itemClassName={css.pluginMethodDropdownItem}
        boxClasses={css.pluginMethodDropdownBox}
        items={jsonPluginRequestTypes}
        selectedItem={configState.method}
        buttonColorWay={ButtonDeprecated.ButtonColorWay.white}
        onChange={(method) => {
          savePluginSafely({
            method: method as JsonPluginRequestType,
          });
        }}
        id="url-field"
        fullWidth
      />
      <Spacer factor={4} />
      <JsonUrlField
        url={configState.url}
        autoFocus={isNewPlugin}
        error={!saving && !!validationErrors.url}
        botAttributes={botAttributes}
        className={css.urlInput}
        onChange={(url) => {
          updateConfig({
            url,
          });
        }}
        onBlur={() => savePluginSafely()}
      />
      <Spacer />
      <JsonRequestHeaders
        headers={configState.headers}
        botAttributes={botAttributes}
        onHeadersChange={(headers) => updateConfig({ headers })}
        onHeadersSave={(headers) => savePluginSafely({ headers })}
      />
      {!bodilessTypes.includes(pluginConfig.method) && (
        <>
          <Spacer />
          <Type as="h3" weight="semibold" size="15px">
            {t('JSONPlugin-JSXText-1056-send')}
          </Type>
          <Spacer factor={1} />
          <JsonPostData
            postFormat={configState.post_format}
            body={configState.body}
            botAttributes={botAttributes}
            platform={platform}
            updateConfig={updateConfig}
            savePluginSafely={savePluginSafely}
          />
        </>
      )}
      <Spacer factor={6} />
      <AttributesMappingHeader />
      <Spacer factor={4} />
      <Flex alignItems="center" as="label">
        <CheckBox
          checked={!!configState.use_custom_response}
          onChange={switchResponseMapping}
          data-testid="json-request-setup-attribute-mapping"
        />
        <Spacer horizontalFactor={2} />
        <Type size="15px">
          {t(
            'modernComponents.FlowBuilder.plugins.JSONPlugin.setupAttributeMapping',
          )}
        </Type>
      </Flex>
      <Spacer factor={4} />
      {configState.use_custom_response && (
        <AttributesMapping
          pathToAttributes={configState.custom_response_config}
          platform={platform}
          botAttributes={botAttributes}
          onAdd={handleAdd}
          onDeleteAt={handleDeleteAt}
          onChangeAttributeAt={handleChangeAttributeAt}
          onChangeJsonPathAt={handleChangeJsonPathAt}
          onBlur={() => savePluginSafely()}
        />
      )}
      <Spacer />
      <JsonTestRequest
        botId={botId}
        pluginId={pluginId}
        pluginConfig={configState}
        isNewPlugin={isNewPlugin}
        savePluginAsync={savePluginAsync}
      />
    </>
  );
};
