import {
  ButtonPopupOverlayConfig,
} from '../../../../FlowBuilderOverlay/overlays';
import { PaymentButtonEditorEventPayload } from './types';
import { EventEmitter } from '@utils/EventEmitter';

export enum PaymentButtonEditorEvent {
  show = 'flow:button:showPanel',
  hide = 'flow:button:hidePanel',
  updateConfig = 'flow:button:updateConfig',
}

export const paymentButtonEditorEmitter = new EventEmitter();

export const showEditorPanelPaymentButton = (
  config: ButtonPopupOverlayConfig,
  symbol: Symbol,
) => {
  paymentButtonEditorEmitter.emit<PaymentButtonEditorEventPayload>(PaymentButtonEditorEvent.show, {
    config,
    symbol
  });
};

export const hideEditorPanelPaymentButton = (
) => {
  paymentButtonEditorEmitter.emit(PaymentButtonEditorEvent.hide);
};
