import React from 'react';
import { Spacer } from '@ui/Spacer';
import * as css from './Divider.css';

export const Divider: React.FC = () => (
  <>
    <div className={css.Divider} />
    <Spacer factor={3} />
  </>
);
