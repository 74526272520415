/* eslint-disable no-param-reassign */
import { useQuery } from '@apollo/react-hooks';
import { isOptimisticBlockId } from '@components/Aside/Mutations/BlockMutations';
import { PluginType } from '@components/Plugins/common/PluginTypes';
import { sendEvent } from '@utils/Analytics';
import { complement, prop } from 'ramda';
import React, { useMemo, useState } from 'react';
import { lazyConcat, toRecord } from '@utils/ArrayUtils';
import { Platform } from '@globals';
import {
  BlocksSuggestOverlayOptions,
  OverlayComponentEvent,
  overlayEventEmitter,
  OverlayProps,
} from '..';
import { CenteredLoader } from '../../../../modern-ui/Loader';
import { BlockWithPermissions } from '../../../../pages/AiSetupPage';
import { BlocksSelector } from '../../../../pages/AiSetupPage/BlocksSelector';
import { useCurrentBotId } from '../../../../utils/Routing';
import {
  BlockGroupsAndBlocksTitlesQuery,
  BlockGroupsAndBlocksTitlesQueryVariables,
} from '../../../ButtonEditorPopup/common/@types/BlockGroupsAndBlocksTitlesQuery';
import { BLOCK_GROUPS_AND_BLOCKS_TITLES_QUERY } from '../../../ButtonEditorPopup/common/ButtonPopupBlocksSelector';

// import * as css from './Textarea.css';

export interface Block {
  __typename: 'Block';
  id: string;
  title: string;
  removed: boolean | null;
  is_flow: boolean | null;
}

const blocksById = (blocksIds: string[], blockMap: Record<string, Block>) =>
  blocksIds.map((id) => blockMap[id]).filter(Boolean);

export const BlocksSuggestOverlay: React.FC<OverlayProps> = ({
  value,
  onChange,
  options,
}) => {
  const botId = useCurrentBotId();
  const [blocksSelectedCache, setBlocksSelectedCache] = useState<Block[]>([]);

  const { data, loading } = useQuery<
    BlockGroupsAndBlocksTitlesQuery,
    BlockGroupsAndBlocksTitlesQueryVariables
  >(BLOCK_GROUPS_AND_BLOCKS_TITLES_QUERY, {
    variables: {
      botId: botId || '',
    },
    skip: !botId,
    fetchPolicy: 'cache-and-network',
  });
  const { blocksTitles, platform } = options as BlocksSuggestOverlayOptions;

  const isInstagramFlowSuggest = platform === Platform.instagram;
  const blocksGroups = (
    isInstagramFlowSuggest ? [] : data?.bot.blocksGroups || []
  ) as BlockWithPermissions[];
  const flowsGroups = data?.bot.flowGroupsSuggest || [];
  const archiveBlocks = data?.bot.archiveBlocks;

  const blocksMap = useMemo(() => {
    return toRecord(
      lazyConcat(archiveBlocks, blocksTitles, blocksSelectedCache),
      ({ id }) => id,
    );
  }, [archiveBlocks, blocksTitles, blocksSelectedCache]);

  if (!botId) {
    return null;
  }

  if (loading && Object.keys(blocksMap).length === 0) {
    return <CenteredLoader />;
  }

  return (
    <div
      style={{
        margin: '-4px -12px 0px',
        position: 'relative',
      }}
    >
      {!blocksGroups && (
        <CenteredLoader
          style={{
            position: 'absolute',
            zIndex: 2,
            width: '100%',
          }}
        />
      )}
      <BlocksSelector
        blocksSelected={blocksById(value, blocksMap)}
        blocksGroups={blocksGroups}
        flowsGroups={flowsGroups}
        onChange={(blocksSelected) => {
          setBlocksSelectedCache(blocksSelected);
          const blocksIds = blocksSelected.map(prop('id'));
          const realBlocksIds = blocksIds.filter(
            complement(isOptimisticBlockId),
          );
          if (realBlocksIds.length) {
            sendEvent({
              category: 'flow',
              action: 'set blocks',
              label: PluginType.go_to_block_plugin,
              propertyBag: {
                ids: realBlocksIds,
              },
            });
          }
          return onChange(blocksIds);
        }}
        currentBotId={botId}
        autofocus
        enablePortal
        hideTips={false}
        placeholder=" "
        disableDropdown={!blocksGroups}
        onGoToBlock={(block) => {
          if (block) {
            if (
              blocksGroups.some(({ blocks }) =>
                blocks.some(({ id }) => id === block.id),
              )
            ) {
              window.open(`/bot/${botId}/structure/${block.id}`, '_blank');
            } else {
              window.open(`/bot/${botId}/flows/${block.id}`, '_blank');
            }
            return false;
          }
          return undefined;
        }}
        onAdded={(block) => {
          overlayEventEmitter.emit(OverlayComponentEvent.add, block);
        }}
        platform={platform}
      />
    </div>
  );
};
