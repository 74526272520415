import * as R from 'ramda';

import { FlowData, PluginType } from '../types';
import { BLOCK_SUBTYPES, HIDDEN_DEPRECATED_BLOCKS } from '../consts';
import { HIDDEN_DEPRECATED_ENTRY_POINTS } from '@components/Plugins/common/PluginTypes';

const botStatusPageInfoPageId = R.lensPath(['botStatus', 'page_info', 'id']);
const botStatusPage = R.lensPath(['botStatus', 'page']);
const botStatusStatus = R.lensPath(['botStatus', 'status']);
const statusPageInfoPageId = R.lensPath(['bot', 'status', 'page_info', 'id']);
const statusPage = R.lensPath(['bot', 'status', 'page']);
const statusStatus = R.lensPath(['bot', 'status', 'status']);

export const getUpdatedFlowData = (flow: FlowData, pageId: string) =>
  R.compose<
    FlowData,
    FlowData,
    FlowData,
    FlowData,
    FlowData,
    FlowData,
    FlowData
  >(
    R.set(botStatusPageInfoPageId, pageId),
    R.set(botStatusPage, pageId),
    R.set(botStatusStatus, pageId),
    R.set(statusPageInfoPageId, pageId),
    R.set(statusPage, pageId),
    R.set(statusStatus, pageId),
  )(flow);

export const getRidOfHiddenDeprecatedBlocks = (flow: FlowData) => {
  // eslint-disable-next-line no-param-reassign
  flow.blocks = flow.blocks.filter((block) => {
    const subtypeInHiddenList =
      block.subtype && HIDDEN_DEPRECATED_BLOCKS.includes(block.subtype);
    const isEntryPoint = block.subtype === BLOCK_SUBTYPES.entrypoint;
    const entryPointId = isEntryPoint && block.cards?.[0]?.plugin_id;
    const entryPointInHiddenList =
      entryPointId &&
      HIDDEN_DEPRECATED_ENTRY_POINTS.includes(entryPointId as PluginType);
    return !(subtypeInHiddenList || entryPointInHiddenList);
  });

  return flow;
};
