import React from 'react';
import { useQuery } from 'react-apollo';
import cn from 'classnames';
import { GenericFilter, Divider } from './GenericFilter';
import { UserFilterProps } from '../types';
import { ButtonUnstyled } from '../../../../../../../modern-ui/Button';
import { SimpleCombobox } from '@ui/SimpleCombobox';
import { Dropdown } from '../../../../../../../modern-ui/_deprecated/Dropdown';
import { Type } from '../../../../../../../modern-ui/Type';
import { Input } from '../../../../../../../modern-ui/Input';
import { MenuItem } from '../../../../../../../modern-ui/Menu/MenuItem';
import { BLOCKS_TITLES_ARCHIVE_QUERY } from '../../../../../../Aside/BlocksGroupsQueries';
import { parameterToOperation } from '../../../../../../../common/services/Segmentation';
import {
  BlocksTitlesArchiveQuery,
  BlocksTitlesArchiveQueryVariables,
} from '../../../../../../Aside/@types/BlocksTitlesArchiveQuery';
import { ParameterFilterValueOperator } from '../../../../../../../../@types/globalTypes';
import * as css from './Filter.css';

export const BlockFilter: React.FC<UserFilterProps> = (props) => {
  const { botId, parameter, onParameterUpdate } = props;
  const { data, loading } = useQuery<
    BlocksTitlesArchiveQuery,
    BlocksTitlesArchiveQueryVariables
  >(BLOCKS_TITLES_ARCHIVE_QUERY, { variables: { botId } });
  const blocks = (data?.bot.archiveBlocks || []).filter((b) => !b.removed);
  const blockIsOptions = Object.keys(parameterToOperation.blockIs);
  const operationsMap = parameterToOperation[parameter.type!];
  const operations = Object.keys(operationsMap);

  let [predicate, operation] = parameter.operation.split('_');
  if (operation) {
    predicate = 'is_not';
  } else {
    operation = predicate;
    predicate = 'is';
  }

  return (
    <GenericFilter {...props}>
      <SimpleCombobox
        items={blocks}
        downshiftContainerClassName={css.dropdown}
        menuboxStyle={{ maxWidth: '200px' }}
        itemToString={(item) => item?.title ?? ''}
        selectedItem={blocks.find((s) => s.id === parameter.values[0]) || null}
        onChange={(item) => {
          onParameterUpdate({
            ...parameter,
            values: item?.id ? [item?.id] : [],
          });
        }}
        renderInput={({ getInputProps, openMenu, isOpen }) => (
          <Input
            className={cn(css.selectorInput, css.secondColumnSelector)}
            containerClassName={css.selectorInputContainer}
            {...getInputProps({
              placeholder: window.i18next.t('BlockFilter-string-9383-block'),
              name: 'value',
              onFocus: openMenu,
            })}
            onClick={() => !isOpen && openMenu()}
            autoComplete="off"
            disabled={loading}
          />
        )}
      />
      <Divider />
      <Dropdown
        className={css.dropdown}
        boxClasses={css.selectorBox}
        itemClassName={css.selectorBoxItem}
        items={blockIsOptions}
        selectedItem={operation}
        onChange={(item) => {
          const newOperation = item === 'is' ? operation : `not_${operation}`;
          onParameterUpdate({
            ...parameter,
            operation: newOperation as ParameterFilterValueOperator,
          });
        }}
        menuItemFactory={(itemProps: any, item, customItemProps) => (
          <MenuItem
            {...itemProps}
            {...customItemProps}
            key={item}
            title={
              parameterToOperation.blockIs[
                item as keyof typeof parameterToOperation.blockIs
              ]
            }
          />
        )}
        buttonsFactory={(props, ref: React.Ref<HTMLButtonElement>) => (
          <ButtonUnstyled
            {...(props as React.ButtonHTMLAttributes<HTMLButtonElement>)}
            className={cn(css.selectorButton, css.thirdColumnSelector)}
            ref={ref}
          >
            <Type as="div" align="left" noWrap size="15px_DEPRECATED">
              {
                parameterToOperation.blockIs[
                  predicate as keyof typeof parameterToOperation.blockIs
                ]
              }
            </Type>
          </ButtonUnstyled>
        )}
      />
      <Divider />
      <Dropdown
        className={css.dropdown}
        boxClasses={css.selectorBox}
        itemClassName={css.selectorBoxItem}
        items={operations}
        selectedItem={operation}
        onChange={(item) => {
          const newOperation = predicate === 'is' ? item : `not_${item}`;
          onParameterUpdate({
            ...parameter,
            operation: newOperation as ParameterFilterValueOperator,
          });
        }}
        menuItemFactory={(itemProps: any, item, customItemProps) => (
          <MenuItem
            {...itemProps}
            {...customItemProps}
            key={item}
            title={(operationsMap as Record<string, string>)[item]}
          />
        )}
        buttonsFactory={(props, ref: React.Ref<HTMLButtonElement>) => (
          <ButtonUnstyled
            {...(props as React.ButtonHTMLAttributes<HTMLButtonElement>)}
            className={cn(css.selectorButton, css.fourthColumnShortSelector)}
            ref={ref}
          >
            <Type as="div" align="left" noWrap size="15px_DEPRECATED">
              {operation}
            </Type>
          </ButtonUnstyled>
        )}
      />
    </GenericFilter>
  );
};
