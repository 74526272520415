import React, { useMemo } from 'react';
import { QuickReplyPluginData } from '../types';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Type } from '@ui/Type';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import {
  TimeInterval,
  TimeIntervalsIds,
  TimeoutInputByTimestamp,
} from '@ui/TimeoutInput';
import { sendEvent } from '@utils/Analytics';
import { Tooltip2 } from '@ui/Tooltip2';
import { Icon } from '@ui/Icon';
import { LS, LSKey } from 'cf-common/src/localStorage';

const ALLOWED_TIME_INTERVALS_IDS = LS.get(LSKey.collectUserDataTestTimeouts)
  ? [
      TimeIntervalsIds.immediately,
      TimeIntervalsIds.seconds,
      TimeIntervalsIds.minutes,
      TimeIntervalsIds.hours,
      TimeIntervalsIds.days,
    ]
  : [
      TimeIntervalsIds.immediately,
      TimeIntervalsIds.minutes,
      TimeIntervalsIds.hours,
      TimeIntervalsIds.days,
    ];

export const InputTimeout: React.FC<QuickReplyPluginData> = ({
  savePlugin,
  pluginConfig,
  setPluginConfigState,
}) => {
  const { t } = useSafeTranslation();

  const customTimeIntervals = useMemo<TimeInterval[]>(
    () => [
      {
        id: TimeIntervalsIds.immediately,
        divider: 1,
        titleKey:
          'modernComponents.FlowBuilder.views.components.CollectUserData.common.never',
      },
    ],
    [],
  );

  return (
    <Flex alignItems="center">
      <Type size="15px">
        {t(
          'modernComponents.FlowBuilder.views.components.CollectUserData.common.inputTimoutLabel',
        )}
      </Type>
      <Spacer factor={0} horizontalFactor={2} />
      <TimeoutInputByTimestamp
        data-testid="collect-data_input-timeout"
        value={pluginConfig.input_timeout_millis || 0}
        onChange={(value) => {
          setPluginConfigState({
            config: {
              ...pluginConfig,
              input_timeout_millis: value || null,
            },
          });
          sendEvent({
            category: 'collect-data-plugin',
            action: 'input timeout change',
            propertyBag: {
              value,
            },
          });
        }}
        onBlur={savePlugin}
        allowedTimeIntervalsIds={ALLOWED_TIME_INTERVALS_IDS}
        customTimeIntervals={customTimeIntervals}
      />
      <Spacer factor={0} horizontalFactor={2} />
      <Tooltip2
        placement="right"
        boundariesElement="viewport"
        content={
          <Type as="p" size="12px" color="white">
            {t(
              'modernComponents.FlowBuilder.views.components.CollectUserData.common.inputTimoutInfo',
            )}
          </Type>
        }
        type="small"
        positionFixed
      >
        {(ref, bind) => (
          <Icon icon="info" color="grey" size="24px" ref={ref} {...bind} />
        )}
      </Tooltip2>
    </Flex>
  );
};
