/* eslint-disable no-param-reassign */
import React, { useCallback, useEffect } from 'react';
import { Editor } from 'slate-react';
import {
  deserialize,
  EditorModeEnum,
  TextWithAttributesEditor,
} from '../../../../modern-ui/TextWithAttributesEditor';
import {
  FlowBuilderOverlayEvent,
  overlayEventEmitter,
  OverlayProps,
  TextFieldOverlayOptions,
} from '..';
import { AttributesData } from '../../../../utils/AttributesUtils/AttributesData';
import {
  filterAttributes,
  addAttributeToGQLCaches,
} from '../../../../utils/AttributesUtils/AttributesUtils';
import { useCurrentBotId } from '../../../../utils/Routing';
import { TextOutsideControls } from '../../../../modern-ui/TextOutsideControls';
import {
  extractAllUniqAttributes,
  wrapAllAttributes,
} from '../../../../modern-ui/TextWithAttributesEditor/attributesBoundariesUtils';
import { AttributesQuery_bot_variableSuggest } from '../../../../utils/AttributesUtils/@types/AttributesQuery';
import { Platform } from '../../../../../@types/globalTypes';

import { insertText } from '../../../../utils/Slite/insertText';
import { getFlowPlatform } from '../../utils/getFlowPlatform';

export const TextareaWithAttributes: React.FC<OverlayProps> = ({
  options,
  value = '',
  onChange,
  onKeyDown,
}) => {
  const {
    maxLength,
    shouldShowOutsideControls,
    singleLine,
    style,
    customAttributesOnly,
    predefinedAttributes,
    shouldJoinAttributes,
  } = options as TextFieldOverlayOptions;
  const botId = useCurrentBotId();

  useEffect(() => () => insertText.cancel(), []);

  useEffect(() => {
    return overlayEventEmitter.on(FlowBuilderOverlayEvent.unmount, () => {
      if (!botId) {
        return;
      }
      addAttributeToGQLCaches(
        botId,
        extractAllUniqAttributes(value),
        getFlowPlatform() || Platform.facebook,
      );
    });
  }, [value, botId]);

  const editorRender = useCallback(
    (
      attributes: AttributesQuery_bot_variableSuggest[],
      highlightFullAttributesOnly: boolean,
    ) => (
      <TextOutsideControls
        onInsertRequest={(text, el) => {
          insertText(text, el as Editor);
        }}
        currentTextLimit={
          maxLength
            ? maxLength - wrapAllAttributes(value, () => '').trim().length
            : undefined
        }
        shouldShowOutsideControls={shouldShowOutsideControls}
      >
        {({ ref }) => (
          <TextWithAttributesEditor
            data-testid="flowbuilder__textarea-with-attributes"
            defaultValue={deserialize(value)}
            attributes={filterAttributes(attributes, !!customAttributesOnly)}
            onStringChange={onChange}
            onKeyDown={(event) => {
              onKeyDown?.(event as unknown as React.KeyboardEvent<HTMLElement>);
              if (singleLine && (event as KeyboardEvent).key === 'Enter') {
                return false;
              }
              return undefined;
            }}
            maxLength={maxLength}
            autoFocus
            shouldMoveCursorToEndOnFocus
            fixedEditorMode={EditorModeEnum.edit}
            style={{
              padding: 0,
              wordBreak: 'break-word',
              ...style,
            }}
            editorRef={ref}
            singleLine={singleLine}
            highlightFullAttributesOnly={highlightFullAttributesOnly}
            hasManageAttributes
          />
        )}
      </TextOutsideControls>
    ),
    [
      customAttributesOnly,
      maxLength,
      onChange,
      onKeyDown,
      shouldShowOutsideControls,
      singleLine,
      style,
      value,
    ],
  );

  if (!botId) {
    return null;
  }

  return predefinedAttributes && !shouldJoinAttributes ? (
    editorRender(predefinedAttributes, true)
  ) : (
    <AttributesData botId={botId} platform={getFlowPlatform()}>
      {({ attributes }) =>
        editorRender(
          shouldJoinAttributes
            ? [...(predefinedAttributes || []), ...attributes]
            : attributes,
          false,
        )
      }
    </AttributesData>
  );
};
