import { Sprite, Texture } from 'pixi.js-legacy';
import { Shape } from './Shape';
import { TextureShapeProps } from './types';

export class TextureShape extends Shape<TextureShapeProps, Sprite> {
  TEST_NAME = 'TextureShape';

  constructor(props: TextureShapeProps) {
    super(props, Sprite);
  }

  texture(texture?: Texture) {
    return this._changeProp<Texture>('texture', texture);
  }

  _drawShape() {
    this._shape.texture = this._props.texture;
    this._shape.width = this._props.width || 0;
    this._shape.height = this._props.height || 0;
  }

  /*
     We don't need to destroy icons' textures from memory
     cause they are reusable
    */
  destroy() {
    super.destroy({ destroyBaseTexture: false });
  }
}
