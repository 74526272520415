import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  useCallback,
} from 'react';
import * as PopperJS from 'popper.js';
import { Manager, Popper, Reference } from 'react-popper';
import { Menu as NestedMenu, useNestedMenu } from '@ui/NestedMenu';
import { Divider, Menubox } from '@ui/Menu';
import { Input } from '@ui/Input';
import { Icon } from '@ui/Icon';
import { ButtonUnstyled } from '@ui/Button';
import { Type } from '@ui/Type';
import { Spacer } from '@ui/Spacer';
import { Flex } from '@ui/Flex';
import {
  GetPlanLimitReachedTextPath,
  PlanLimitReachedCallout,
} from '@components/PlanLimitReachedCallout';
import { usePlanLimitReached } from '@utils/Data/Pricing';
import { getGroupedMenu, filterMenu, getPopperModifiers } from './utils';
import { GROUP_STYLES } from './consts';
import * as css from './Menu.css';
import { MenuOverlayOptions, OverlayProps } from '../../types';
import { Tooltip } from '../../../views/Menu/types';
import { TooltipContent } from '../TooltipContent';
import { resByFunc } from '../../../views/utils';

export interface MenuOverlayProps extends OverlayProps {
  tooltipPlacement?: PopperJS.Placement;
  isRoot?: boolean;
  parentsSetOpenedMenuIndex?: (index: number) => void;
}

const getPlanLimitReachedTextPath: GetPlanLimitReachedTextPath = (
  dialogPricingEnabled: boolean,
) => {
  if (!dialogPricingEnabled)
    return {
      title:
        'modernComponents.FlowBuilder.OverlayMenu.planLimitReached.subscribers.title',
      button:
        'modernComponents.FlowBuilder.OverlayMenu.planLimitReached.subscribers.button',
    };
  return {
    title:
      'modernComponents.FlowBuilder.OverlayMenu.planLimitReached.dialogues.title',
    button:
      'modernComponents.FlowBuilder.OverlayMenu.planLimitReached.dialogues.button',
  };
};

const EMPTY_LIST = [] as any[];

export const Menu: React.FC<OverlayProps> = ({
  options,
  onChange,
  onKeyDown,
}) => {
  const {
    items: optionsItems,
    style: optionsStyle,
    isPlanLimitMessage,
    searchable,
  } = options as MenuOverlayOptions;
  const menuRef = useRef<HTMLDivElement | null>(null);
  const [searchInput, setSearchInput] = useState('');
  const isSearching = searchInput !== '';
  const { staticItems = EMPTY_LIST, filterableItems = EMPTY_LIST } = useMemo(
    () => getGroupedMenu(optionsItems),
    [optionsItems],
  );

  const [items, setItems] = useState(filterableItems);

  const planLimitReached = usePlanLimitReached();

  const nestedMenuProps = useNestedMenu({
    isRoot: true,
    allItems: staticItems.concat(items),
    // @ts-expect-error
    onKeyDown,
    onChange,
  });

  useEffect(() => {
    if (!isSearching) {
      setItems(filterableItems);
      return;
    }

    setItems(filterMenu(searchInput, filterableItems));
    nestedMenuProps.setHighlightedIndex(staticItems.length);
    // eslint-disable-next-line
  }, [setItems, filterableItems, searchInput, isSearching]);

  const renderTooltip = useCallback((tooltip, id) => {
    const tooltipOptions = resByFunc(tooltip);
    const tooltipUrl = tooltipOptions?.url;
    const isLinkActive = tooltipUrl || tooltipOptions?.html;

    return (
      <TooltipContent
        id={id}
        tooltipUrl={tooltipUrl as string}
        inactive={!isLinkActive}
        tooltipOptions={tooltipOptions as Tooltip}
      />
    );
  }, []);

  const modifiers = getPopperModifiers();

  return (
    <Manager>
      <Reference>
        {({ ref }) => (
          <div
            ref={(el) => {
              menuRef.current = el;
              // @ts-expect-error
              ref(el);
            }}
          />
        )}
      </Reference>
      <Popper
        placement="bottom-start"
        referenceElement={menuRef.current || undefined}
        modifiers={modifiers}
      >
        {({ ref, style }) => (
          <Menubox
            data-testid="nested-menu__root"
            ref={ref}
            style={{
              ...style,
              ...optionsStyle,
              overflow: 'visible',
            }}
          >
            {staticItems.length !== 0 && (
              <>
                <NestedMenu
                  getPopperModifiers={modifiers}
                  items={staticItems}
                  renderTooltip={renderTooltip}
                  {...nestedMenuProps}
                />
                {items.length !== 0 ? (
                  <Divider style={{ backgroundColor: 'var(--greyLight20)' }} />
                ) : (
                  <Spacer factor={1} />
                )}
              </>
            )}
            {searchable && (
              <Input
                data-testid="nested-menu__search-bar"
                autoFocus
                containerClassName={css.inputSearch}
                renderIcon={() => (
                  <Icon icon="search" style={{ color: 'var(--greyDark)' }} />
                )}
                renderIconEnd={() =>
                  searchInput.length !== 0 && (
                    <ButtonUnstyled onClick={() => setSearchInput('')}>
                      <Icon icon="close" />
                    </ButtonUnstyled>
                  )
                }
                type="text"
                value={searchInput}
                placeholder={window.i18next.t('Menu-string--182-search')}
                onKeyDown={(e) => {
                  if (['ArrowUp', 'ArrowDown', 'Enter'].includes(e.key)) {
                    e.preventDefault();
                  }
                }}
                onChange={(e) => {
                  setSearchInput(e.currentTarget.value);

                  if (nestedMenuProps.openedMenuIndex !== -1) {
                    nestedMenuProps.setOpenedMenuIndex(-1);
                  }
                }}
              />
            )}
            {items.length === 0 && isSearching ? (
              <div className={css.noResults}>
                <Type size="15px_DEPRECATED">
                  {window.i18next.t('Menu-JSXText-1389-no-results-found')}
                </Type>
              </div>
            ) : (
              <NestedMenu
                getPopperModifiers={modifiers}
                getGroupStyles={(_, index) => (index !== 0 ? GROUP_STYLES : {})}
                renderTooltip={renderTooltip}
                items={items}
                indexDelta={staticItems.length}
                renderGroupView={({ title }) => (
                  <Flex alignItems="center" className={css.groupTitle}>
                    <Spacer factor={1} horizontalFactor={4} />
                    <Type size="15px" weight="semibold">
                      {title}
                    </Type>
                  </Flex>
                )}
                {...nestedMenuProps}
              />
            )}
            {isPlanLimitMessage && planLimitReached?.visible && (
              <PlanLimitReachedCallout
                {...planLimitReached}
                buttonNewLine
                isAnchor
                sourcePage="nested menu"
                className={css.callout}
                iconView={
                  <Icon
                    size="16px"
                    icon="info"
                    color="white"
                    className={css.calloutIcon}
                  />
                }
                getTextPath={getPlanLimitReachedTextPath}
              />
            )}
          </Menubox>
        )}
      </Popper>
    </Manager>
  );
};
