import React, { useCallback, useState } from 'react';
import { sendEvent } from '@utils/Analytics';
import { Input } from '@ui/Input';
import { TextOutsideControls } from '@ui/TextOutsideControls';
import { PluginControlLabelChildrenProps } from '@components/Plugins/common/PluginControlLabel';
import { InputLikeElement, insertText } from '@utils/documentExecCommand';
import { GREETING_MESSAGE_LIMIT } from '../CustomerChatEntryPoint/consts';
import { ControlProps } from '../CustomerChatEntryPoint/components/types';
import { Type } from '@ui/Type';
import { Spacer } from '@ui/Spacer';
import { useSafeTranslation } from '@utils/useSafeTranslation';

export interface WelcomeMessageControlProps<T>
  extends ControlProps<T>,
    PluginControlLabelChildrenProps {
  value: string;
  field: keyof T;
  error?: boolean;
  required?: boolean;
}

export const WelcomeMessageControl = <T,>({
  value: initialValue,
  field,
  state,
  required,
  pluginType,
  pluginConfig,
  ...bind
}: WelcomeMessageControlProps<T>) => {
  const { t } = useSafeTranslation();
  const [value, setValue] = useState(initialValue);
  const currentTextLimit = GREETING_MESSAGE_LIMIT - value.trim().length;

  const onChange = useCallback(
    (value: string) => {
      setValue(value);
      state.set(({ config }) => ({
        config: { ...config, [field]: value.trim() },
      }));
    },
    [field, state],
  );

  const onBlur = useCallback(() => {
    state.save();
    sendEvent({
      category: 'flows',
      action: `change ${String(field)}`,
      label: pluginType,
      propertyBag: {
        [field]: value,
      },
    });
  }, [field, pluginType, state, value]);

  return (
    <TextOutsideControls
      onInsertRequest={(text, el) => {
        insertText(text, el as InputLikeElement);
      }}
      currentTextLimit={currentTextLimit}
      shouldShowOutsideControls={{
        symbolsLimit: true,
      }}
      tight
      initialShow={false}
    >
      {({ ref, getInputProps }) => (
        <>
          <Input
            value={value}
            maxLength={GREETING_MESSAGE_LIMIT}
            {...getInputProps<HTMLInputElement>({
              onBlur,
              onChange: (e) => onChange(e.currentTarget.value),
            })}
            {...bind}
            ref={ref}
          />

          <Spacer factor={2} />

          {required && value.length === 0 && (
            <Type size="12px" color="red" weight="medium">
              {t(
                'modernComponents.FlowBuilder.EditorPanel.WhatsappChat.requiredField',
              )}
            </Type>
          )}
        </>
      )}
    </TextOutsideControls>
  );
};
