import { PluginType } from '../../types';

export const getPanelWidth = (type?: PluginType) => {
  switch (type) {
    case PluginType.go_to_block_plugin:
    case PluginType.add_attribute:
      return 465;
    case PluginType.json_plugin:
      return 540;
    case PluginType.send_to_messenger_entry_point:
      return 354;
    case PluginType.inbound_links_entry_point:
      return 396;
    case PluginType.whatsapp_template:
      return 464;
    case PluginType.whatsapp_widget_entry_point:
      return 464;
    case PluginType.whatsapp_popup_entry_point:
      return 464;
    default:
      return 412;
  }
};
