import { openAiPluginFragment_config_intents } from '@components/Plugins/OpenAiPlugin/@types/openAiPluginFragment';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import React from 'react';
import { getPromptPreview } from './getPromptPreview';

interface PromptTabProps {
  intents: Array<openAiPluginFragment_config_intents>;
  prompt: string;
}

export const PromptTab: React.FC<PromptTabProps> = ({ intents, prompt }) => {
  return (
    <div>
      <Spacer factor={6} />
      <Type data-testid="openai_plugin_prompt_preview" whiteSpace="pre-line">
        {getPromptPreview({ intents, prompt })}
      </Type>
    </div>
  );
};
