import { DocumentNode } from 'graphql';
import { Card, PluginType } from './types';
import { Node } from './Node';
import { MainLayout } from './components/Elements/Layouts';
import { CreateLineMenuParams, IMenuItem, Tooltip } from './views/Menu/types';
import { Texture } from 'pixi.js-legacy';
import { PluginDataType } from './StatefulPlugin/types';
import {
  CacheManager,
  GraphqlCacheManager,
} from '@utils/GQL/GraphqlCacheManager';
import { CreatePluginMutationParams } from '@components/Plugins/common/PluginGQL';
import client from '@common/services/ApolloService';

export interface FlowBuilderPluginParams {
  cacheDocumentNode: DocumentNode;
  saveDocumentNode: DocumentNode;
  createDocumentNode: DocumentNode;
}

export abstract class FlowBuilderPlugin<Config = {}, CardType = {}> {
  private readonly saveDocumentNode: DocumentNode;
  private readonly createDocumentNode: DocumentNode;

  /**
   * Все редактирование конфига происходит в кеше, так же {@link StatefulPlugin} подписывается на изменения
   * кеша чтобы вызывать методы у делегата:
   * {@link StatefulPluginDelegate.pluginDidSet}
   * {@link StatefulPluginDelegate.pluginDidSetError}
   */
  public readonly cacheManager: CacheManager<PluginDataType<Config>, unknown>;

  constructor({
    cacheDocumentNode,
    saveDocumentNode,
    createDocumentNode,
  }: FlowBuilderPluginParams) {
    this.cacheManager = new GraphqlCacheManager(client, cacheDocumentNode);
    this.saveDocumentNode = saveDocumentNode;
    this.createDocumentNode = createDocumentNode;
  }

  public abstract id: PluginType;

  /**
   * В момент создания плагина отрисовывается вью загрузки {@link PluginLoadingView}
   * Чтобы интерфейс не "прыгал" надо заранее знать высоту плагина
   */
  public abstract loadingPreviewHeight: number;

  /**
   * Есть некоторое количество плагинов которые работают не через {@link StatefulPlugin}
   * их надо различать, чтобы правильно сохранять конфиг
   */
  public abstract isApolloBased: boolean;

  /**
   * Название поля в котором лежит конфиг. Должно совпадать с именем в saveCard мутации на BFF
   * @see {@link packages/api-gateway/src/domains/Automate/Card.graphql:161}
   * @see {@link packages/api-gateway/src/domains/Automate/saveCard.ts:8}
   */
  public abstract inputField: string;

  /**
   * Сохраняет конфиг, который на данный момент лежит в кеше {@link cacheManager}
   * Обычно вызывается на `onBlur`
   */
  public async savePlugin(card: CardType): Promise<void> {
    await client.mutate({
      mutation: this.saveDocumentNode,
      variables: card,
    });
    return undefined;
  }

  /**
   * Создание плагина. Вызывается при выборе плагина из Nested Menu или при выборе айтема из дропдауна
   * выбора плагина
   */
  public createPlugin(props: CreatePluginMutationParams): Promise<unknown> {
    return client.mutate({
      mutation: this.createDocumentNode,
      ...props,
    });
  }

  /**
   * Тултип отрисовывается в меню при наведении на айтем, так же может быть использован в {@link PluginTitleLayout}
   */
  public abstract tooltip(): Tooltip;

  /**
   * Иконка плагина. Используется в {@link PluginTitleLayout}
   */
  public abstract getIconTexture(): Texture;

  /**
   * Метод для получения конфига меню айтема. Может вернуть `undefined` если
   * исходя из переданного `config` меню айтем отображаться не должен
   */
  public abstract getMenuItem(
    config: CreateLineMenuParams,
  ): IMenuItem | undefined;

  /**
   * Возвращает название плагина, которое может отображаться, например в модалке
   * подтверждения удаления плагина
   */
  public abstract getPluginDisplayName(): string;

  /**
   * Возвращает дефолтны конфиг для плагина, с которым плагин сохранится при его
   * создании. Так же может использоваться для проверки на какие либо изменения
   * в конфиге (если текущий конфиг не равен конфигу который вернул этот метод, то
   * в конфиге были какие то изменения)
   */
  public abstract getDefaultConfig(): Config;

  /**
   * Возвращает PIXI контейнер который будет отрисован на флоу
   */
  public abstract getView(card: Card<Config>, node: Node): MainLayout;
}
