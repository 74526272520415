import React, { ReactNode, useCallback } from 'react';
import copyToClipboard from 'clipboard-copy';
import { Flex } from '@ui/Flex';
import { CodeTextArea, useCopiedToaster } from '@ui/CodeTextArea';
import { Spacer } from '@ui/Spacer';
import { Anchor } from '@ui/Links';
import { sendEvent } from '@utils/Analytics';
import { removeExtraSpaces } from '@utils/String/removeExraSpaces';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { PluginControlLabel } from '@components/Plugins/common/PluginControlLabel';
import { Button } from '@ui/Button';
import { Modal } from '@services/index';
import { FBPluginCodeInstructionsPopup } from '../../../common/FBPluginCodeInstructionsPopup';
import { PluginType } from '../../../../../../../Plugins/common/PluginTypes';

export interface CodeControlProps {
  code: string;
  pluginType: PluginType;
  hideInstructions?: boolean;
  light?: boolean;
  customLabel?: ReactNode;
}

export const CodeControl: React.FC<CodeControlProps> = ({
  code,
  pluginType,
  hideInstructions,
  light,
  customLabel,
}) => {
  const { t } = useSafeTranslation();
  const showCopiedToaster = useCopiedToaster();

  const miniCode = removeExtraSpaces(code, ' ');

  const showCodeModal = useCallback(() => {
    Modal.show(({ close }) => (
      <FBPluginCodeInstructionsPopup
        onRequestClose={() => {
          close();
        }}
        onCopy={(reason) => {
          sendEvent({
            category: 'flows',
            action: `code copied from popup ${reason}`,
            label: pluginType,
          });
        }}
        code={code}
        text={
          <>
            {t(
              'modernComponents.FlowBuilder.EditorPanel.CustomerChat.popupDescription',
            )}
            <Anchor
              hideArrow
              intent="external"
              target="_blank"
              rel="noopener noreferrer"
              href="https://developers.facebook.com/docs/messenger-platform/discovery/customer-chat-plugin/"
            >
              {t(
                'CustomerChatEntryPointContent-JSXText--192-customer-chat-plugin-reference',
              )}
            </Anchor>
            .
          </>
        }
      />
    ));
  }, [t, code, pluginType]);

  return (
    <>
      <PluginControlLabel
        light={light}
        label={
          customLabel || (
            <Flex alignItems="center" justifyContent="space-between">
              {t(
                'CustomerChatEntryPointContent-JSXText--211-code-to-insert-on-your-website',
              )}
            </Flex>
          )
        }
      >
        {(bind) => (
          <CodeTextArea
            rows={5}
            value={code}
            onCopy={() => {
              sendEvent({
                category: 'flows',
                action: 'code copied from textarea',
                label: pluginType,
              });
            }}
            {...bind}
          />
        )}
      </PluginControlLabel>
      <Spacer factor={2} />

      <Flex>
        <Button
          intent="secondary"
          style={{
            marginRight: 8,
          }}
          onClick={() => {
            copyToClipboard(miniCode);
            showCopiedToaster();
            sendEvent({
              category: 'flows',
              action: 'code copied by button click',
              label: pluginType,
            });
          }}
        >
          {t('CustomerChatEntryPointContent-JSXText-9361-copy-code')}
        </Button>
        {!hideInstructions && (
          <Button
            intent="text"
            onClick={() => {
              showCodeModal();
              sendEvent({
                category: 'flows',
                action: 'show instructions popup',
                label: pluginType,
              });
            }}
          >
            {t('CustomerChatEntryPointContent-JSXText-8069-see-instructions')}
          </Button>
        )}
      </Flex>
    </>
  );
};
