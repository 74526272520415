import React from 'react';
import { useCurrentBroadcastId } from '@utils/Routing';
import { EditorPanelPluginProps } from '../../../types';
import { PluginHeader } from '../../common/PluginHeader';
import {
  whatsappTemplatePluginFragment_config as WhatsappTemplatePluginConfig,
  whatsappTemplatePluginFragment_config_template_components as Components,
} from '../../../../../Plugins/WhatsappTemplatePlugin/@types/whatsappTemplatePluginFragment';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import {
  Platform,
  WhatsappTemplateButtonType,
  WhatsappTemplateComponentFormat,
  WhatsappTemplateComponentType,
} from '@globals';
import { HeaderText, HeaderMedia, Body, DynamicUrlButton } from './components';
import { AdditionalProps } from './types';
import { useAttributes } from '@utils/AttributesUtils/AttributesData';
import { VariableSuggestType } from '@utils/AttributesUtils/AttributesUtilsTypes';
import { useWhatsappBroadcast } from '@pages/BotPage/WhatsappReEngageTab/hooks/useWhatsappBroadcast';
import { HeaderParameterMediaType } from './components/HeaderMedia/types';
import { sendEvent } from '@utils/Analytics';
import { PluginType } from '@components/FlowBuilder/types';
import { isEditableBroadcast } from '@pages/BotPage/WhatsappReEngageTab/utils';

export const WhatsappTemplatePluginView: React.FC<
  EditorPanelPluginProps<WhatsappTemplatePluginConfig> & { disabled: boolean }
> = ({ botId, pluginParams, disabled }) => {
  const { t } = useSafeTranslation();
  const { attributes } = useAttributes(
    botId,
    VariableSuggestType.template,
    Platform.whatsapp,
  );

  const { pluginConfig, savePlugin, validationErrors } = pluginParams;
  const componentsMap = pluginConfig.template?.components.reduce(
    (acc, component) => ({ ...acc, [component.type]: component }),
    {} as Record<WhatsappTemplateComponentType, Components>,
  );

  const body = componentsMap?.[WhatsappTemplateComponentType.BODY];
  const header = componentsMap?.[WhatsappTemplateComponentType.HEADER];
  const footer = componentsMap?.[WhatsappTemplateComponentType.BUTTONS];
  const button = footer?.buttons?.find(
    ({ type }) => type === WhatsappTemplateButtonType.URL,
  );

  const additionalProps: Omit<AdditionalProps, 'onAttributeInputBlur'> = {
    disabled,
    pluginConfig,
    attributes,
    setPluginConfigState: pluginParams.setPluginConfigState,
  };

  const onAttributeInputBlur = (action: string) => () => {
    savePlugin();
    sendEvent({
      category: 'flows',
      action,
      label: PluginType.whatsapp_template,
    });
  };

  const templateComponentViewMap: Record<
    WhatsappTemplateComponentFormat,
    () => React.ReactNode
  > = {
    [WhatsappTemplateComponentFormat.TEXT]: () => (
      <HeaderText
        text={header!.text!}
        {...additionalProps}
        onAttributeInputBlur={onAttributeInputBlur('header text update')}
      />
    ),
    [WhatsappTemplateComponentFormat.IMAGE]: () => (
      <HeaderMedia
        {...additionalProps}
        type={HeaderParameterMediaType.image}
        validationErrors={validationErrors}
        onAttributeInputBlur={onAttributeInputBlur('header image update')}
      />
    ),
    [WhatsappTemplateComponentFormat.DOCUMENT]: () => (
      <HeaderMedia
        {...additionalProps}
        type={HeaderParameterMediaType.document}
        validationErrors={validationErrors}
        onAttributeInputBlur={onAttributeInputBlur('header document update')}
      />
    ),
    [WhatsappTemplateComponentFormat.VIDEO]: () => (
      <HeaderMedia
        {...additionalProps}
        type={HeaderParameterMediaType.video}
        validationErrors={validationErrors}
        onAttributeInputBlur={onAttributeInputBlur('header video update')}
      />
    ),
    [WhatsappTemplateComponentFormat.LOCATION]: () => null,
  };

  const HeaderView = templateComponentViewMap[header?.format!]?.();

  return (
    <>
      <PluginHeader
        title={t(
          'modernComponents.FlowBuilder.plugins.WhatsappTemplatePlugin.headerTitle',
        )}
      />

      <Spacer factor={3} />

      <Type size="12px" color="greyDark">
        {t(
          'modernComponents.FlowBuilder.plugins.WhatsappTemplatePlugin.subscription',
        )}
      </Type>

      <Spacer factor={10} />

      {header?.format && HeaderView && (
        <>
          {HeaderView}

          <Spacer factor={8} />
        </>
      )}

      {body?.text && (
        <>
          <Body
            text={body.text}
            {...additionalProps}
            onAttributeInputBlur={onAttributeInputBlur('body attribute update')}
          />

          <Spacer factor={8} />
        </>
      )}

      {pluginConfig.urlButtonParameter && button?.url && (
        <DynamicUrlButton
          text={button.url}
          {...additionalProps}
          onAttributeInputBlur={onAttributeInputBlur('button attribute update')}
        />
      )}
    </>
  );
};

export const BroadcastWhatsappTemplatePluginView: React.FC<
  EditorPanelPluginProps<WhatsappTemplatePluginConfig> & { broadcastId: string }
> = ({ broadcastId, ...props }) => {
  const { data } = useWhatsappBroadcast(broadcastId);

  const disabled = !isEditableBroadcast(data?.whatsappBroadcast);

  return <WhatsappTemplatePluginView disabled={disabled} {...props} />;
};

export const WhatsappTemplatePlugin: React.FC<
  EditorPanelPluginProps<WhatsappTemplatePluginConfig>
> = (props) => {
  const broadcastId = useCurrentBroadcastId();

  return broadcastId ? (
    <BroadcastWhatsappTemplatePluginView broadcastId={broadcastId} {...props} />
  ) : (
    <WhatsappTemplatePluginView disabled={false} {...props} />
  );
};
