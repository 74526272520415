import gql from 'graphql-tag';
import { QueryHookOptions, useQuery } from 'react-apollo';
import {
  KommoUsersQuery,
  KommoUsersQueryVariables,
} from './@types/KommoUsersQuery';

const KOMMO_USERS_QUERY = gql`
  query KommoUsersQuery($botId: String!) {
    kommoUsers(botId: $botId) {
      id
      name
    }
  }
`;

export const useKommoUsers = (
  options: QueryHookOptions<KommoUsersQuery, KommoUsersQueryVariables>,
) =>
  useQuery<KommoUsersQuery, KommoUsersQueryVariables>(
    KOMMO_USERS_QUERY,
    options,
  );
