import React from 'react';
import cn from 'classnames';
import { Icon } from '@ui/Icon';
import { ButtonUnstyled } from '@ui/Button';
import { Dropdown } from '@ui/_deprecated/Dropdown';
import { Type } from '@ui/Type';
import { MenuItem } from '@ui/Menu';
import { usePageConnected } from '@utils/FacebookPages/usePageConnected';
import { attributeIdFromNameAndType } from '@utils/AttributesUtils/AttributesUtils';
import { GenericFilterProps } from '../types';
import {
  parameterToOperation,
  SEGMENTATION_PARAMETERS_STRING,
} from '../../../../../../../common/services/Segmentation';
import { goToBlockPluginFragment_config_user_filter_parameters as UserFilterParameter } from '../../../../../../Plugins/GoToBlockPlugin/@types/goToBlockPluginFragment';
import {
  AttributeValuesQuery_bot_variableValueSuggest as VariableSuggest,
  AttributeValuesQuery_bot_variableValueSuggest_suggests as Suggests,
} from '../../../../../../../utils/AttributesUtils/@types/AttributeValuesQuery';
import {
  UserAttributeType,
  ParametersOperator,
  ParameterFilterValueOperator,
} from '@globals';
import * as css from './Filter.css';

export const GenericFilter: React.FC<GenericFilterProps> = ({
  botId,
  parameter,
  parameters,
  operator,
  onOperatorUpdate,
  onParameterUpdate,
  onRemoveFilter,
  children,
  invalid,
  userAttributeTypeSelectorHidden,
  disabled,
}) => (
  <div className={css.userFilterItemContainer}>
    <div
      className={cn(css.userFilterItem, {
        [css.userFilterItemInvalid]: invalid,
      })}
    >
      {!userAttributeTypeSelectorHidden && (
        <>
          {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
          <UserAttributeTypeSelector
            botId={botId}
            disabled={disabled}
            parameter={parameter}
            onParameterUpdate={onParameterUpdate}
          />
          {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
          <Divider />
        </>
      )}
      {children}
      {(parameters?.length ?? 0) > 1 && onOperatorUpdate && (
        <>
          {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
          <Divider />
          {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
          <ParametersOperatorSelector
            disabled={disabled}
            className={css.lastOperatorSelector}
            operator={operator}
            onOperatorUpdate={onOperatorUpdate}
          />
        </>
      )}
      {onRemoveFilter && !disabled && (
        <>
          <div className={css.removeFilterPlaceholder} />
          <ButtonUnstyled
            className={css.removeFilterButton}
            onClick={onRemoveFilter}
          >
            <Icon icon="delete" />
          </ButtonUnstyled>
        </>
      )}
    </div>
  </div>
);

const getTypeTitle = (type: UserAttributeType) => {
  const string =
    SEGMENTATION_PARAMETERS_STRING[
      type as Exclude<UserAttributeType, UserAttributeType.compound>
    ] || '';
  return string[0].toUpperCase() + string.slice(1);
};

const USER_ATTRIBUTE_OPTIONS = [
  UserAttributeType.system,
  UserAttributeType.segment,
];

export const UserAttributeTypeSelector: React.FC<{
  botId: string;
  disabled?: boolean;
  parameter: UserFilterParameter;
  onParameterUpdate(param: UserFilterParameter): void;
}> = ({ botId, parameter, onParameterUpdate, disabled }) => {
  const title = getTypeTitle(parameter.type!);
  const { isConnected, loading } = usePageConnected(botId);
  return (
    <Dropdown
      className={css.dropdown}
      boxClasses={css.selectorBox}
      itemClassName={css.selectorBoxItem}
      disabled={loading}
      items={USER_ATTRIBUTE_OPTIONS.filter(
        (a) => !(!isConnected && a === UserAttributeType.segment),
      )}
      menuItemFactory={(itemProps: any, item, customItemProps) => (
        <MenuItem
          {...itemProps}
          {...customItemProps}
          key={item}
          title={getTypeTitle(item as UserAttributeType)}
        />
      )}
      selectedItem={title}
      onChange={(item) => {
        onParameterUpdate({
          ...parameter,
          type: item as UserAttributeType,
        });
      }}
      buttonsFactory={(props, ref: React.Ref<HTMLButtonElement>) => (
        <ButtonUnstyled
          {...(props as React.ButtonHTMLAttributes<HTMLButtonElement>)}
          className={cn(css.selectorButton, css.attributeTypeSelector)}
          ref={ref}
          disabled={disabled}
        >
          <Type as="div" align="left" noWrap size="15px_DEPRECATED">
            {title}
          </Type>
        </ButtonUnstyled>
      )}
    />
  );
};

export const ParametersOperatorSelector: React.FC<{
  operator: ParametersOperator;
  onOperatorUpdate(operator: ParametersOperator): void;
  className?: string;
  disabled?: boolean;
}> = ({ operator, onOperatorUpdate, className, disabled }) => (
  <Dropdown
    disabled={disabled}
    className={cn(css.dropdown, className)}
    boxClasses={css.userFilterOperatorSelector}
    itemClassName={css.userFilterOperatorSelectorBoxItem}
    items={Object.keys(ParametersOperator)}
    selectedItem={operator}
    onChange={(item) => onOperatorUpdate(item as ParametersOperator)}
    buttonsFactory={(props, ref: React.Ref<HTMLButtonElement>) => (
      <ButtonUnstyled
        {...(props as React.ButtonHTMLAttributes<HTMLButtonElement>)}
        className={cn(css.selectorButton, css.userFilterOperatorSelector)}
        disabled={disabled}
        ref={ref}
      >
        <Type size="15px_DEPRECATED">{operator}</Type>
      </ButtonUnstyled>
    )}
  />
);

export const ParameterFilterValueOperatorSelector: React.FC<{
  className?: string;
  disabled?: boolean;
  parameter: UserFilterParameter;
  onParameterUpdate(param: UserFilterParameter): void;
}> = ({ className, disabled, parameter, onParameterUpdate }) => {
  const operationsMap = parameterToOperation[parameter.type!];
  const operations = Object.keys(operationsMap);
  const selectedOperation = parameter.operation;
  return (
    <Dropdown
      className={css.dropdown}
      boxClasses={css.selectorBox}
      itemClassName={css.selectorBoxItem}
      items={operations}
      selectedItem={selectedOperation}
      disabled={disabled}
      onChange={(item) => {
        onParameterUpdate({
          ...parameter,
          operation: item as ParameterFilterValueOperator,
        });
      }}
      menuItemFactory={(itemProps: any, item, customItemProps) => (
        <MenuItem
          {...itemProps}
          {...customItemProps}
          key={item}
          title={(operationsMap as Record<string, string>)[item]}
        />
      )}
      buttonsFactory={(props, ref: React.Ref<HTMLButtonElement>) => (
        <ButtonUnstyled
          {...(props as React.ButtonHTMLAttributes<HTMLButtonElement>)}
          className={cn(css.selectorButton, className)}
          disabled={disabled}
          ref={ref}
        >
          <Type as="div" align="left" noWrap size="15px_DEPRECATED">
            {(operationsMap as Record<string, string>)[selectedOperation]}
          </Type>
        </ButtonUnstyled>
      )}
    />
  );
};

export const Divider: React.FC<{}> = () => <div className={css.divider} />;

export const getAttributeSuggestsForParameter = (
  parameter: { type: UserAttributeType; name: string },
  attributeValues: VariableSuggest[],
) =>
  ((
    attributeValues.find(
      (av) =>
        av.id === attributeIdFromNameAndType(parameter.type!, parameter.name),
    ) || {}
  ).suggests || []) as Suggests[];
