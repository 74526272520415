import React from 'react';
import { ClosableEditorPanelView } from '../EditorPanelView';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { PANEL_WIDTH } from './consts';
import { PluginHeader } from '../../common/PluginHeader';
import { PriceList } from './components/PriceList';
import { DEFAULT_CURRENCY } from '@utils/Format/constants';
import { PluginControlLabel } from '../../../../../Plugins/common/PluginControlLabel';
import { Spacer } from '@ui/Spacer';
import { RequestedUserInfoSelector } from './components/RequestedUserInfoSelector';
import { StripeAccount } from '../../../../../Stripe/StripeAccount';
import { ButtonTitle } from './components/ButtonTitle';
import { Flex } from '@ui/Flex';
import { Icon } from '@ui/Icon';
import { Tooltip2 } from '@ui/Tooltip2';
import { MAX_BUTTON_LENGTH } from '../../../../views/constants';
import { usePaymentButtonConfig } from './hooks/usePaymentButtonConfig';

export const EditorPanelPaymentButton: React.FC = () => {
  const { t } = useSafeTranslation();
  const { saveConfig, updateConfig, config, dropConfig } =
    usePaymentButtonConfig();

  if (!config) {
    return null;
  }

  return (
    <ClosableEditorPanelView
      width={PANEL_WIDTH}
      onClose={() => {
        saveConfig();
        dropConfig();
      }}
    >
      <PluginHeader title={t('InBotPayments.Panel.title')} />
      <StripeAccount />
      <Spacer factor={6} />
      <PriceList
        items={config.payment_summary?.price_list || []}
        currency={config.payment_summary?.currency || DEFAULT_CURRENCY}
        onSaveRequest={saveConfig}
        onChange={(items) =>
          updateConfig((updatedConfig) => {
            // eslint-disable-next-line no-param-reassign
            updatedConfig.payment_summary.price_list = items;
            return updatedConfig;
          })
        }
        onChangeCurrency={(currency) =>
          updateConfig((updatedConfig) => {
            // eslint-disable-next-line no-param-reassign
            updatedConfig.payment_summary.currency = currency;
            return updatedConfig;
          })
        }
      />
      <Spacer factor={6} />
      <PluginControlLabel
        label={
          <Flex alignItems="center">
            {t('InBotPayments.Panel.PriceList.buttonTitleLabel')}
            <Spacer factor={1} horizontalFactor={1} />
            <Tooltip2
              content={t('InBotPayments.Panel.PriceList.buttonTitleError', {
                maxLength: MAX_BUTTON_LENGTH,
              })}
              boundariesElement="viewport"
            >
              {(ref, bind) => (
                <Icon
                  data-testid="request-user-info__button-name-tooltip"
                  icon="info"
                  size="20px"
                  color="baseTertiary"
                  ref={ref}
                  {...bind}
                />
              )}
            </Tooltip2>
          </Flex>
        }
      >
        {({ id }) => (
          <ButtonTitle
            config={config}
            onSaveRequest={saveConfig}
            onChange={(value) =>
              updateConfig((updatedConfig) => ({
                ...updatedConfig,
                title: value,
              }))
            }
            htmlId={id}
          />
        )}
      </PluginControlLabel>
      <Spacer factor={6} />
      <PluginControlLabel
        label={
          <Flex alignItems="center">
            {t('InBotPayments.Panel.PriceList.RequestUserData.title')}
            <Spacer factor={1} horizontalFactor={1} />
            <Tooltip2
              content={t('InBotPayments.Panel.PriceList.RequestUserData.info')}
              boundariesElement="viewport"
            >
              {(ref, bind) => (
                <Icon
                  data-testid="request-user-info__user-data-tooltip"
                  icon="info"
                  size="20px"
                  color="baseTertiary"
                  ref={ref}
                  {...bind}
                />
              )}
            </Tooltip2>
          </Flex>
        }
      >
        {() => (
          <RequestedUserInfoSelector
            requestedUserInfo={
              config?.payment_summary?.requested_user_info || []
            }
            onChange={(requestedUserInfo) => {
              updateConfig((updatedConfig) => {
                // eslint-disable-next-line no-param-reassign
                updatedConfig.payment_summary.requested_user_info =
                  requestedUserInfo;
                return updatedConfig;
              });
              saveConfig();
            }}
          />
        )}
      </PluginControlLabel>
    </ClosableEditorPanelView>
  );
};
