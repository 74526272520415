import React, { useCallback, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { Type } from '@ui/Type';
import { Flex } from '@ui/Flex';
import { Input } from '@ui/Input';
import { TokenCounter } from '../TokenCounter/TokenCounter';
import css from './Intent.css';
import { SupportedModels } from '../TokenCounter/useTokenCount';
import { debounce } from 'lodash-es';
import { CHANGE_DEBOUNCE_TIMEOUT } from '../../consts';

export const Intent = ({
  description,
  onUpdate,
  onBlur,
  prompt,
  title,
  model,
}: {
  onBlur: () => void;
  title: string;
  description: string;
  prompt: string;
  model: SupportedModels;
  onUpdate: (prompt: string) => void;
}) => {
  const [newPrompt, setNewPrompt] = useState(prompt);

  const debouncedUpdate = useCallback(
    debounce((prompt: string) => {
      onUpdate(prompt);
    }, CHANGE_DEBOUNCE_TIMEOUT),
    [onUpdate],
  );

  return (
    <div className={css.intent}>
      <Flex gapFactor={1} flexDirection="column">
        <Type weight="bold" size="15px">
          {title}
        </Type>

        <Type size="15px">{description}</Type>
      </Flex>

      <Input
        containerClassName={css.prompt}
        render={({ getInputProps }) => (
          <TextareaAutosize
            data-testid="custom_ai_intent_value"
            {...getInputProps({
              className: css.textarea,
              value: newPrompt,
              onBlur: () => onBlur(),
            })}
            onChange={(e) => {
              const newPrompt = e.target.value;
              setNewPrompt(newPrompt);
              debouncedUpdate(newPrompt);
            }}
          />
        )}
      />

      <div className={css.tokens}>
        <TokenCounter model={model} text={prompt} />
      </div>
    </div>
  );
};
