import React, { useRef } from 'react';
import cn from 'classnames';
import { getAttributeSuggestsForParameter } from './GenericFilter';
import { UserFilterParameter } from '../types';
import { SimpleCombobox } from '@ui/SimpleCombobox';
import { Input } from '../../../../../../../modern-ui/Input';
import {
  AttributeValuesQuery_bot_variableValueSuggest as VariableSuggest,
  AttributeValuesQuery_bot_variableValueSuggest_suggests as Suggest,
} from '../../../../../../../utils/AttributesUtils/@types/AttributeValuesQuery';
import * as css from './Filter.css';
import { NonNullableFields } from '@utils/NotNullableParameters';

interface AttributeFilterComboboxProps {
  botId: string;
  savePlugin: () => void;
  singleLine: boolean;
  parameter: UserFilterParameter;
  onParameterUpdate(param: UserFilterParameter): void;
  attributeValuesSuggests: VariableSuggest[];
  attributeValuesSuggestsLoading: boolean;
  disabled?: boolean;
}

export const AttributeFilterCombobox: React.FC<AttributeFilterComboboxProps> =
  ({
    savePlugin,
    singleLine,
    parameter,
    onParameterUpdate,
    attributeValuesSuggests,
    attributeValuesSuggestsLoading,
    disabled,
  }) => {
    const attributeValuesInputRef = useRef<HTMLInputElement | null>(null);
    const currentAttributeValue = parameter.values[0];

    const selectedAttributesValues = getAttributeSuggestsForParameter(
      parameter as NonNullableFields<typeof parameter>,
      attributeValuesSuggests || [],
    );

    const handleAttributeValueBlur = () => {
      const refValue = attributeValuesInputRef.current?.value;
      if (currentAttributeValue !== refValue) {
        onParameterUpdate({
          ...parameter,
          values: refValue ? [refValue] : [],
        });
      }
    };

    const currentAttributeValueLowerCase = (
      currentAttributeValue || ''
    ).toLowerCase();
    const items = selectedAttributesValues.filter((v) =>
      v.value.toLowerCase().includes(currentAttributeValueLowerCase),
    );

    const selectedItem =
      selectedAttributesValues.find((v) => v.value === currentAttributeValue) ??
      (parameter.values.length
        ? ({
            id: currentAttributeValue,
            value: currentAttributeValue,
            type: parameter.type,
          } as Suggest)
        : null);

    return (
      <SimpleCombobox
        position="bottom-end"
        boundariesElement="viewport"
        items={items}
        downshiftContainerClassName={css.dropdown}
        menuboxStyle={{ maxWidth: '200px' }}
        itemToString={(item) => item?.value ?? ''}
        selectedItem={selectedItem}
        onChange={(item) => {
          onParameterUpdate({
            ...parameter,
            values: item?.value ? [item?.value] : [],
          });
        }}
        renderInput={({ getInputProps, openMenu, closeMenu, isOpen }) => (
          <Input
            ref={attributeValuesInputRef}
            className={cn(css.attributeValue, {
              [css.fourthColumnShortSelector]: !singleLine,
              [css.fourthColumnMediumSelector]: singleLine,
            })}
            containerClassName={css.selectorInputContainer}
            {...getInputProps({
              placeholder: 'value',
              name: 'value',
              title: selectedItem?.value,
              onFocus: openMenu,
              onBlur: () => {
                savePlugin();
                closeMenu();
                handleAttributeValueBlur();
              },
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                const { value } = e.target;

                onParameterUpdate({
                  ...parameter,
                  values: value ? [value] : [],
                });
              },
              onKeyDown({ key }: React.KeyboardEvent<HTMLInputElement>) {
                if (key === 'Enter') {
                  attributeValuesInputRef.current?.blur();
                }
              },
            })}
            onClick={() => !isOpen && openMenu()}
            autoComplete="off"
            disabled={attributeValuesSuggestsLoading || disabled}
          />
        )}
      />
    );
  };
