import React, { useCallback, useEffect, useState } from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Focus } from 'react-powerplug';
import { propEq } from 'ramda';
import { AttributesData } from '@utils/AttributesUtils/AttributesData';
import { AttributesQuery_bot_variableSuggest } from '@utils/AttributesUtils/@types/AttributesQuery';
import { usePreventWheel } from '@utils/Event/usePreventWheel';
import { isValidUrl } from '@utils/UrlUtils';
import { useCurrentBotId } from '@utils/Routing';
import { ButtonUnstyled } from '@ui/Button/Unstyled';
import { Flex } from '@ui/Flex';
import { FocusWithin } from '@ui/FocusWithin';
import { ReactComponent as DropdownIcon } from '@ui/_deprecated/Icon/icons/ic_dropdown_arr.svg';
import { Input } from '@ui/Input';
import { PhoneInput } from '@ui/PhoneInput/PhoneInput';
import { Spacer } from '@ui/Spacer';
import {
  deserialize,
  getFullAttributesBoundaries,
  TextWithAttributesEditor,
} from '@ui/TextWithAttributesEditor';
import { Type } from '@ui/Type';
import { SimpleCombobox } from '@ui/SimpleCombobox';
import { CardButtonType, Platform, ShopifyDiscountsCodeType } from '@globals';
import { isFlowHasCalendlyBlock } from '../../../views/plugins/CalendlyIntegration/utils/isFlowHasCalendlyBlock';
import { ButtonPopupOverlayOptions, OverlayProps } from '../../types';
import { ReactComponent as UrlIcon } from '../images/url.svg';
import { ReactComponent as ShopifyIcon } from '../images/shopify.svg';
import { ButtonPopupOverlayShopify } from './components/ButtonPopupOverlayShopify';
import { InAppBrowserWindowSelector } from './components/InAppBrowserWindowSelector';
import { WhatsappListDescription } from './components/WhatsappListDescription';
import {
  addPlusToNumber,
  getButtonTypesList,
  getTypeId,
  isCalendlyButtonType,
  isPaymentButtonType,
  updateConfig,
} from './utils';
import { ButtonPopupOverlayConfig, ButtonTypes } from './types';
import { BIG_CURSOR_POS } from './constants';
import * as css from './ButtonPopupOverlay.css';
import { getFlowPlatform } from '../../../utils/getFlowPlatform';
import { getFlowControllerStrict } from '../../../PixiFieldRepository';
import { MagicButton } from './MagicButton';
import { ButtonPopupOverlayContainer } from './ButtonPopupOverlayContainer';

export const ButtonPopupOverlay: React.FC<OverlayProps> = ({
  onChange,
  onKeyDown,
  options,
}) => {
  const {
    config: initialConfig,
    hideCallPhoneOption,
    hideContinueToFlowOption,
    hideCalendlyOptions,
    hidePaymentOption,
    disablePaymentOption,
    shouldShowOutsideControls,
    predefinedAttributes,
    platform,
  } = options as ButtonPopupOverlayOptions;

  const { t } = useSafeTranslation();
  const botId = useCurrentBotId();
  const hasCalendlyPlugin = isFlowHasCalendlyBlock();

  const [config, setConfig] = useState<ButtonPopupOverlayConfig>({
    ...initialConfig,
    phone_number:
      initialConfig.phone_number && addPlusToNumber(initialConfig.phone_number),
  });
  const {
    url,
    phone_number: phoneNumber,
    title,
    webview_height_ratio: webviewHeightRatio = 'full',
  } = config;
  const [touched, setTouched] = useState<boolean>(false);

  const preventWheelRef = usePreventWheel();

  const typeId = getTypeId(config, hideCallPhoneOption);

  let buttonTypesList = getButtonTypesList({
    shouldShowCalendlyMenuItems:
      hasCalendlyPlugin || isCalendlyButtonType(config.type),
    hideCalendlyOptions,
    hidePaymentOption,
    disablePaymentOption,
  });
  if (hideContinueToFlowOption) {
    buttonTypesList = buttonTypesList.filter(
      (v) => !hideContinueToFlowOption || v.id !== ButtonTypes.default,
    );
  }
  if (hideCallPhoneOption) {
    buttonTypesList = buttonTypesList.filter(
      (v) => !hideCallPhoneOption || v.id !== ButtonTypes.phone,
    );
  }
  if (hidePaymentOption) {
    buttonTypesList = buttonTypesList.filter(
      (v) => !hidePaymentOption || v.id !== ButtonTypes.payment,
    );
  }
  if (hideCalendlyOptions) {
    buttonTypesList = buttonTypesList.filter(
      (v) =>
        !hideCalendlyOptions || !isCalendlyButtonType(v.id as CardButtonType),
    );
  }
  if (platform === Platform.whatsapp) {
    buttonTypesList = buttonTypesList.filter(
      (v) => v.id === ButtonTypes.default,
    );
  }

  const [type, setType] = useState<MagicButton | undefined>(
    buttonTypesList.find(propEq('id', typeId)),
  );

  const currentTypeId =
    config.type === CardButtonType.listButton ? config.type : type?.id;

  useEffect(() => {
    setTouched(false);
  }, [currentTypeId]);

  useEffect(() => {
    onChange(updateConfig({ config, currentTypeId }));
  }, [config, currentTypeId, onChange]);

  const editorRender = useCallback(
    (
      attributes: AttributesQuery_bot_variableSuggest[],
      onFocus: () => void,
      onBlur: () => void,
    ) => (
      <TextWithAttributesEditor
        placeholder={window.i18next.t(
          'ButtonPopupOverlay-string--133-insert-url',
        )}
        defaultValue={deserialize(url || '')}
        autoFocus={touched && !hideCallPhoneOption}
        attributes={attributes}
        singleLine
        onStringChange={(url) => {
          setConfig({
            ...config,
            url,
          });
        }}
        onFocus={() => {
          onFocus();
          setTouched(true);
        }}
        onBlur={() => {
          onBlur();
        }}
        hasManageAttributes
      />
    ),
    [config, hideCallPhoneOption, touched, url],
  );

  return (
    <ButtonPopupOverlayContainer
      title={title}
      config={config}
      setConfig={setConfig}
      options={options}
      onKeyDown={onKeyDown}
    >
      {({ scheduleUpdate }) => {
        if (config.type === CardButtonType.listButton) {
          return (
            <WhatsappListDescription
              touched={touched}
              setTouched={setTouched}
              value={config.description || ''}
              setValue={(description) => setConfig({ ...config, description })}
            />
          );
        }

        return (
          <>
            <Flex alignItems="center">
              {currentTypeId === ButtonTypes.shopify_page ? (
                <ShopifyIcon className={css.icon} />
              ) : (
                <UrlIcon className={css.icon} />
              )}
              {buttonTypesList.length === 1 ? (
                <Type weight="semibold" size="15px_DEPRECATED">
                  {type?.title ?? ''}
                </Type>
              ) : (
                <SimpleCombobox<MagicButton>
                  items={buttonTypesList}
                  selectedItem={type}
                  onChange={(item) => {
                    setType(item || undefined);
                    setTimeout(() => {
                      setTouched(true);
                      scheduleUpdate();
                    });
                  }}
                  menuItemStyle={{
                    minWidth: 85,
                  }}
                  renderInput={({ getToggleButtonProps, selectedItem }) => (
                    <ButtonUnstyled
                      data-testid="flowbuilder__button-type-selector"
                      type="button"
                      {...getToggleButtonProps()}
                      style={{
                        outline: 'none',
                      }}
                    >
                      <Flex alignItems="center">
                        <Type weight="semibold" size="15px_DEPRECATED">
                          {selectedItem?.title}
                        </Type>
                        <DropdownIcon style={{ marginLeft: 8 }} />
                      </Flex>
                    </ButtonUnstyled>
                  )}
                />
              )}
            </Flex>
            <Spacer factor={5} />
            <>
              {type?.id === ButtonTypes.default && (
                <Type size="15px_DEPRECATED">
                  {t(
                    'modernComponents.FlowBuilder.views.buttonView.SendUsersToTheNextBlockWhenTheyClickTheButton',
                  )}
                </Type>
              )}
              {isPaymentButtonType(type?.id as CardButtonType) && (
                <Type size="15px_DEPRECATED">
                  {t(
                    'modernComponents.FlowBuilder.views.buttonView.paymentButtonText',
                  )}
                </Type>
              )}
              {type?.id === ButtonTypes.shopify_page && (
                <ButtonPopupOverlayShopify
                  path={config?.shopify_page?.path || ''}
                  onPathChange={(path) => {
                    setConfig({
                      ...config,
                      shopify_page: {
                        __typename: 'ShopifyPageButtonConfig',
                        discount: null,
                        ...config.shopify_page,
                        path,
                      },
                    });
                  }}
                  discount={{
                    discount_type:
                      config?.shopify_page?.discount?.discount_type ||
                      ShopifyDiscountsCodeType.common,
                    code: config?.shopify_page?.discount?.code || undefined,
                    price_rule_id:
                      config?.shopify_page?.discount?.price_rule_id ||
                      undefined,
                  }}
                  webviewHeightRatio={webviewHeightRatio}
                  onWebviewHeightChange={(webviewHeight) => {
                    setConfig({
                      ...config,
                      webview_height_ratio: webviewHeight,
                    });
                  }}
                  onDiscountChange={(discount) => {
                    setConfig({
                      ...config,
                      shopify_page: {
                        __typename: 'ShopifyPageButtonConfig',
                        path: null,
                        ...config.shopify_page,
                        discount: discount
                          ? {
                              ...discount,
                              __typename: 'ShopifyPageButtonDiscountConfig',
                            }
                          : null,
                      },
                    });
                  }}
                />
              )}
              {type?.id === ButtonTypes.url && (
                <>
                  <Focus>
                    {({ bind: { onFocus, onBlur }, focused }) =>
                      shouldShowOutsideControls?.attributes ? (
                        <Input
                          error={
                            (!url ||
                              !(
                                isValidUrl(url) ||
                                getFullAttributesBoundaries(url).length > 0
                              )) &&
                            !focused &&
                            touched
                          }
                          render={() =>
                            predefinedAttributes ? (
                              editorRender(
                                predefinedAttributes,
                                onFocus,
                                onBlur,
                              )
                            ) : (
                              <AttributesData
                                botId={botId || ''}
                                platform={getFlowPlatform()}
                              >
                                {({ attributes }) =>
                                  editorRender(attributes, onFocus, onBlur)
                                }
                              </AttributesData>
                            )
                          }
                        />
                      ) : (
                        <Focus>
                          {({ bind: { onFocus, onBlur }, focused }) => (
                            <Input
                              placeholder={window.i18next.t(
                                'ButtonPopupOverlay-string--133-insert-url',
                              )}
                              value={url || ''}
                              error={
                                (!url || !isValidUrl(url)) &&
                                !focused &&
                                touched
                              }
                              autoFocus={touched && !hideCallPhoneOption}
                              onChange={({ currentTarget: { value: url } }) => {
                                setConfig({
                                  ...config,
                                  url,
                                });
                              }}
                              onFocus={() => {
                                onFocus();
                                setTouched(true);
                              }}
                              onBlur={() => {
                                onBlur();
                              }}
                            />
                          )}
                        </Focus>
                      )
                    }
                  </Focus>
                  <Spacer factor={3} />
                </>
              )}

              {getFlowControllerStrict().flow.platform !== Platform.instagram &&
                (type?.id === ButtonTypes.url ||
                  type?.id === CardButtonType.shopify_order_list ||
                  isCalendlyButtonType(type?.id as CardButtonType)) && (
                  <InAppBrowserWindowSelector
                    webviewHeightRatio={webviewHeightRatio}
                    onChange={(webviewHeight) => {
                      setConfig({
                        ...config,
                        webview_height_ratio: webviewHeight,
                      });
                    }}
                  />
                )}
            </>
            {type?.id === ButtonTypes.phone && (
              <>
                <FocusWithin
                  render={({ focusedWithin, bind }) => (
                    <div
                      {...bind}
                      onFocus={() => {
                        // hack phone input lib - focus to end
                        const target = document.activeElement;
                        if (target && target instanceof HTMLInputElement) {
                          setTimeout(() => {
                            target.selectionStart = BIG_CURSOR_POS;
                            target.selectionEnd = BIG_CURSOR_POS;
                          }, 0);
                        }
                        setTouched(true);
                      }}
                    >
                      <div ref={preventWheelRef}>
                        <PhoneInput
                          defaultValue={phoneNumber || ''}
                          onChange={(phone) => {
                            setConfig({
                              ...config,
                              phone_number: phone,
                            });
                          }}
                          autoFocus
                          error={
                            (phoneNumber || '')?.length < 5 &&
                            !focusedWithin &&
                            touched
                          }
                        />
                      </div>
                    </div>
                  )}
                />
              </>
            )}
          </>
        );
      }}
    </ButtonPopupOverlayContainer>
  );
};
