import React, { useState } from 'react';
import cn from 'classnames';
import { lensPath, set } from 'ramda';
import { ButtonUnstyled } from '@ui/Button';
import { Flex } from '@ui/Flex';
import { CircleLoader } from '@ui/Loader/CircleLoader';
import { Type } from '@ui/Type';
import { assert } from '@utils/Assert';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { PluginControlLabel } from '@components/Plugins/common/PluginControlLabel';
import { ValidationErrors } from '@components/Plugins/common';
import { Platform, WhatsappMediaObjectInput } from '@globals';
import { getFileSizeLimit } from '@utils/PlatformSupportedFiles/getFileSizeLimit';
import { HeaderMediaFileSelector } from './components/HeaderMediaFileSelector';
import { AdditionalProps } from '../../types';
import { HeaderMediaFileSelectorData, HeaderParameterMediaType } from './types';
import Maybe from 'graphql/tsutils/Maybe';
import { HEADER_PARAMETER_TO_ATTACHMENT_TYPE } from './consts';
import * as css from './HeaderMedia.css';

export interface HeaderMediaProps extends AdditionalProps {
  type: HeaderParameterMediaType;
  validationErrors: ValidationErrors;
}

export const HeaderMedia: React.FC<HeaderMediaProps> = ({
  type,
  disabled,
  onAttributeInputBlur,
  pluginConfig,
  setPluginConfigState,
  validationErrors,
}) => {
  const [value, setValue] = useState<Maybe<HeaderMediaFileSelectorData>>(
    pluginConfig.headerParameter?.[type],
  );
  const { t } = useSafeTranslation();

  const headerChangeHandler = (value: HeaderMediaFileSelectorData | null) => {
    setValue(value);
    assert(pluginConfig.headerParameter, {});

    const oldMedia: WhatsappMediaObjectInput =
      pluginConfig.headerParameter[type]!;
    const newMedia: WhatsappMediaObjectInput = {
      ...oldMedia,
      ...value,
    };
    const newConfig = set(
      lensPath(['headerParameter', type]),
      newMedia,
      pluginConfig,
    );

    setPluginConfigState({ config: newConfig });
    onAttributeInputBlur();
  };

  return (
    <PluginControlLabel
      label={t(
        'modernComponents.FlowBuilder.plugins.WhatsappTemplatePlugin.header.common',
        {
          type: t(
            `modernComponents.FlowBuilder.plugins.WhatsappTemplatePlugin.header.${type}`,
          ),
        },
      )}
    >
      {() => (
        <HeaderMediaFileSelector
          type={type}
          value={value}
          onChange={headerChangeHandler}
          disabled={disabled}
          showFormatErrorInit={
            !!value?.link && !!validationErrors?.headerParameter
          }
        >
          {({ chooseFile, loading, dropState, attachmentType }) => (
            <ButtonUnstyled
              onClick={() => {
                chooseFile(attachmentType);
              }}
              disabled={disabled || loading}
              className={css.button}
            >
              <Flex
                alignItems="center"
                justifyContent="center"
                className={cn(
                  css.fileSelector,
                  disabled && css.disabled,
                  css[dropState],
                )}
              >
                {loading ? (
                  <Flex alignItems="center" justifyContent="center">
                    <Flex alignItems="center" gapFactor={2}>
                      <CircleLoader
                        size={24}
                        color="accent1Tertiary"
                        opacity={1}
                      />
                      <Type weight="semibold" color="baseSecondary">
                        {t(
                          'modernComponents.FlowBuilder.plugins.WhatsappTemplatePlugin.header.uploading',
                        )}
                      </Type>
                    </Flex>
                  </Flex>
                ) : (
                  <div>
                    <Type color="accent1Normal">
                      {t(
                        'modernComponents.FlowBuilder.plugins.WhatsappTemplatePlugin.header.upload1',
                      )}
                    </Type>{' '}
                    <Type color="baseNormal">
                      {t(
                        'modernComponents.FlowBuilder.plugins.WhatsappTemplatePlugin.header.upload2',
                      )}
                    </Type>{' '}
                    <Type color="baseTertiary">
                      {t(
                        'modernComponents.FlowBuilder.plugins.WhatsappTemplatePlugin.header.size',
                        {
                          size: getFileSizeLimit(
                            HEADER_PARAMETER_TO_ATTACHMENT_TYPE[type],
                            Platform.whatsapp,
                          ),
                        },
                      )}
                    </Type>
                  </div>
                )}
              </Flex>
            </ButtonUnstyled>
          )}
        </HeaderMediaFileSelector>
      )}
    </PluginControlLabel>
  );
};
