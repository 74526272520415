import React, { useState } from 'react';
import { useComponentWillUnmount } from 'cf-common/src/utils/hooks';
import { Modal } from '@services/.';
import { DefaultDialog } from '@ui/Dialog';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Icon } from '@ui/Icon';
import { Type } from '@ui/Type';
import { ButtonUnstyled } from '@ui/Button';
import { useAdminQuery } from '@common/user/UserNavigationItem/queries';
import { Admin, AdminListItem } from '@components/AdminList/AdminListItem';
import { sendFlowBuilderEvent } from '@components/FlowBuilder/utils/Analytics';
import { PluginType } from '@components/FlowBuilder/types';
import * as css from './ChooseAssignee.css';

export interface ChooseAssigneeProps {
  allAdmins: Array<Admin>;
  selectedAdminsIds: Array<string>;
  onDismiss: VoidFunction;
  onSubmit: (ids: Array<string>) => void;
}

export const ChooseAssignee: React.FC<ChooseAssigneeProps> = ({
  allAdmins,
  selectedAdminsIds,
  onSubmit,
  onDismiss,
}) => {
  const { data } = useAdminQuery();
  const { t } = useSafeTranslation();
  const [selectedIds, setSelectedIds] = useState(selectedAdminsIds);

  const handleSubmit = () => {
    sendFlowBuilderEvent({
      category: 'entry point',
      action: 'submit admins',
      label: PluginType.conversation_assign_admin,
    });
    onSubmit(selectedIds);
  };

  // Надо сохранять состояние при клике на оверлей
  useComponentWillUnmount(handleSubmit);

  const onRemoveClick = (admin: Admin) => {
    sendFlowBuilderEvent({
      category: 'entry point',
      action: 'remove assignee click',
      label: PluginType.conversation_assign_admin,
    });
    setSelectedIds((ids) => ids.filter((id) => id !== admin.id));
  };

  const onAddClick = (admin: Admin) => {
    sendFlowBuilderEvent({
      category: 'entry point',
      action: 'add assignee click',
      label: PluginType.conversation_assign_admin,
    });
    setSelectedIds((ids) => [...ids, admin.id]);
  };

  return (
    <DefaultDialog
      header={
        <Type as="h1" weight="extrabold" size="18px">
          {t(
            'modernComponents.FlowBuilder.views.components.AssignAdmin.modal.title',
          )}
        </Type>
      }
      onDismiss={() => {
        handleSubmit();
        onDismiss();
      }}
      className={css.dialog}
      small
    >
      <ul className={css.list}>
        {allAdmins.map((admin) => {
          const isAdminAdded = selectedIds.includes(admin.id);

          return (
            <li key={admin.id} className={css.listItem}>
              <AdminListItem
                admin={{ ...admin, name: admin.name || '' }}
                currentUserId={data?.me.id}
                textWidth={268}
              />
              {isAdminAdded ? (
                <ButtonUnstyled
                  data-testid="choose-assignee-modal__remove"
                  className={css.button}
                  onClick={() => onRemoveClick(admin)}
                >
                  <Icon icon="delete" color="blue" />
                  <Type color="blue" weight="medium">
                    {t(
                      'modernComponents.FlowBuilder.views.components.AssignAdmin.modal.remove',
                    )}
                  </Type>
                </ButtonUnstyled>
              ) : (
                <ButtonUnstyled
                  data-testid="choose-assignee-modal__add"
                  className={css.button}
                  onClick={() => onAddClick(admin)}
                >
                  <Type color="blue" weight="medium">
                    {t(
                      'modernComponents.FlowBuilder.views.components.AssignAdmin.modal.add',
                    )}
                  </Type>
                </ButtonUnstyled>
              )}
            </li>
          );
        })}
      </ul>
    </DefaultDialog>
  );
};

type SpecifiedProps = 'onDismiss' | 'onSubmit';
export interface ShowChooseAssigneeDialogProps
  extends Omit<ChooseAssigneeProps, SpecifiedProps> {}

export const showChooseAssigneeDialog = (
  props: ShowChooseAssigneeDialogProps,
) =>
  Modal.show(({ close, resolve }) => (
    <ChooseAssignee {...props} onDismiss={close} onSubmit={resolve} />
  ));
