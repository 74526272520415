import React, { useCallback } from 'react';
import { set, view } from 'ramda';
import { PluginControlLabel } from '@components/Plugins/common/PluginControlLabel';
import { cleanUpText, extractTokens } from '../utils';
import { AdditionalProps } from '../types';
import { DisplayTokens } from './DisplayTokens';
import { LengthLimit, getLenghtLimit, GetLengthLimit } from './LengthLimit';
import { wrapAllAttributes } from '@ui/TextWithAttributesEditor/attributesBoundariesUtils';

export interface LabeledTokensViewProps
  extends AdditionalProps,
    GetLengthLimit {
  text: string;
  label: string;
  getLens(index: number): R.Lens;
}

export const LabeledTokensView: React.FC<LabeledTokensViewProps> = ({
  text,
  getTotalLength,
  parameters,
  getLens,
  label,
  disabled,
  pluginConfig,
  attributes,
  setPluginConfigState,
  onAttributeInputBlur,
}) => {
  const tokens = extractTokens(text);
  const lengthLimit = getLenghtLimit({
    text,
    parameters,
    getTotalLength,
  });

  const getOnAttributeChange = useCallback(
    (index: number) => {
      return (value: string) => {
        const newConfig = set(getLens(index), cleanUpText(value))(pluginConfig);

        setPluginConfigState({ config: newConfig });
      };
    },
    [pluginConfig, setPluginConfigState, getLens],
  );

  const getAttributeName = useCallback(
    (index: number) => view(getLens(index), pluginConfig) ?? '',
    [pluginConfig, getLens],
  );

  return (
    <PluginControlLabel
      label={<LengthLimit {...lengthLimit}>{label}</LengthLimit>}
    >
      {() => (
        <DisplayTokens
          disabled={disabled}
          attributes={attributes}
          tokens={tokens}
          getAttributeInputMaxLength={(index) =>
            wrapAllAttributes(getAttributeName(index), () => '').length +
            lengthLimit.remaining
          }
          getAttributeName={getAttributeName}
          getOnAttributeChange={getOnAttributeChange}
          onAttributeInputBlur={onAttributeInputBlur}
        />
      )}
    </PluginControlLabel>
  );
};
