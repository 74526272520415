/* eslint-disable no-param-reassign */
import React, { useEffect, useRef } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { TextOutsideControls } from '@ui/TextOutsideControls';
import { InputLikeElement, insertText } from '@utils/documentExecCommand';
import { OverlayProps, TextFieldOverlayOptions } from '../types';
import * as css from './Textarea.css';

const DOT = '.';
const COMMA = ',';

const getCorrectValue = (
  value: string,
  min: number | undefined,
  max: number | undefined,
) => {
  let correctedValue = value;
  const numberValue = value ? parseInt(value, 10) : 0;
  if (min !== undefined && numberValue < min) {
    correctedValue = min.toString(10);
  }
  if (max !== undefined && numberValue > max) {
    correctedValue = max.toString(10);
  }
  return correctedValue;
};

export const Textarea: React.FC<OverlayProps> = ({
  options,
  value = '',
  onChange,
  onKeyDown,
}) => {
  const {
    maxLength,
    shouldShowOutsideControls,
    singleLine,
    style,
    numbersOnly,
    maskPrefixLength,
    max,
    min,
    numbersFractionAllowed,
  } = options as TextFieldOverlayOptions;
  /**
   * Hack for add `word-break: break-all` style in hidden textarea of TextareaAutosize
   */
  useEffect(() => {
    document.body.classList.add(css.textareaAutosizeHack);
    return () => {
      document.body.classList.remove(css.textareaAutosizeHack);
    };
  }, []);

  useEffect(() => {
    if (numbersOnly) {
      if (value === '') {
        return;
      }
      const correctedValue = getCorrectValue(value, min, max);
      if (value !== correctedValue) {
        setTimeout(() => {
          onChange(correctedValue);
        });
      }
    }
  }, [value, max, min, numbersOnly, onChange]);

  const textAreaAutoResizeRef = useRef<any>();

  useEffect(() => {
    setTimeout(() => {
      textAreaAutoResizeRef.current?._resizeComponent(); // force size update
    }, 10);
  }, []);

  const isValueHasFraction =
    value.indexOf(DOT) !== -1 || value.indexOf(COMMA) !== -1;

  return (
    <TextOutsideControls
      onInsertRequest={(text, el) => {
        insertText(text, el as InputLikeElement);
      }}
      currentTextLimit={maxLength ? maxLength - value.trim().length : undefined}
      shouldShowOutsideControls={shouldShowOutsideControls}
    >
      {({ ref }) => (
        <TextareaAutosize
          data-testid="flowbuilder__textarea"
          autoFocus
          value={value}
          onChange={({ currentTarget: { value: updatedValue } }) => {
            let correctedValue = updatedValue;

            if (numbersOnly && correctedValue !== '') {
              correctedValue = getCorrectValue(correctedValue, min, max);
            }

            if (maxLength) {
              correctedValue = correctedValue.substring(0, maxLength); // manual crop for FF :(
            }

            onChange(correctedValue);
          }}
          onHeightChange={() => {
            onChange(value);
          }}
          onKeyDown={(event) => {
            if (
              numbersOnly &&
              Number.isNaN(Number.parseInt(event.key, 10)) &&
              ![
                'Enter',
                'Backspace',
                'Delete',
                'ArrowLeft',
                'ArrowRight',
                ...(numbersFractionAllowed && !isValueHasFraction
                  ? [DOT, COMMA]
                  : []),
              ].includes(event.key)
            ) {
              event.preventDefault();
              return;
            }

            if (maskPrefixLength) {
              const { currentTarget, ctrlKey, metaKey, key } = event;
              if (
                !ctrlKey &&
                !metaKey &&
                currentTarget.selectionStart < maskPrefixLength &&
                key.indexOf('Arrow') !== 0
              ) {
                currentTarget.selectionStart = maskPrefixLength;
              }
              if (
                currentTarget.selectionEnd === maskPrefixLength &&
                key === 'Backspace'
              ) {
                event.preventDefault();
              }
            }

            onKeyDown?.(event);
            if (singleLine && event.key === 'Enter') {
              event.preventDefault();
            }
          }}
          onCut={(event) => {
            const { currentTarget } = event;
            if (maskPrefixLength) {
              if (currentTarget.selectionStart < maskPrefixLength) {
                currentTarget.selectionStart = maskPrefixLength;
              }
            }
          }}
          onFocus={({ currentTarget }) => {
            const { value } = currentTarget;
            currentTarget.value = '';
            currentTarget.value = value;
          }}
          onPaste={(event) => {
            event.preventDefault();
            const { clipboardData, currentTarget } = event;
            let text = clipboardData?.getData('text/plain') || '';
            if (singleLine) {
              text = text.replace(/(\r\n|\n|\r)+/gm, ' ');
            }
            if (
              maskPrefixLength &&
              currentTarget.selectionStart < maskPrefixLength
            ) {
              currentTarget.selectionStart = maskPrefixLength;
              currentTarget.selectionEnd = Math.max(
                currentTarget.selectionStart,
                currentTarget.selectionEnd,
              );
            }
            insertText(text, currentTarget);
          }}
          style={{
            display: 'block',
            padding: 0,
            overflow: 'hidden',
            wordBreak: 'break-word',
            width: '100%',
            border: 'none',
            margin: '0px',
            background: 'none',
            outline: 'none',
            resize: 'none',
            whiteSpace: singleLine ? 'nowrap' : undefined,
            maxHeight: singleLine ? 23 : undefined,
            minHeight: singleLine ? 23 : undefined,
            lineHeight: singleLine ? '23px' : undefined,
            ...style,
          }}
          maxLength={maxLength}
          rows={1}
          inputRef={(el) => {
            ref.current = el;
          }}
          ref={textAreaAutoResizeRef}
        />
      )}
    </TextOutsideControls>
  );
};
