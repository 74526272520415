import React, { useState, useContext, useEffect } from 'react';
import noop from 'lodash-es/noop';
import { useCurrentBotId } from '@utils/Routing';

export enum SearchType {
  search = 'search',
}

export type EnhancedFilterableSearchType<T> = T | SearchType;

export interface FilterableSearchState<T = void, U extends Object = Object> {
  type: EnhancedFilterableSearchType<T>;
  value: string | null;
  parameters: U | null;
}

type FilterableSearchContextType<
  T,
  U extends Object = Object,
> = FilterableSearchState<T, U> & {
  setFilter(filter: FilterableSearchState<T, U>): void;
  resetFilter(): void;
};

const DEFAULT_FILTER: FilterableSearchState<SearchType> = {
  type: SearchType.search,
  value: null,
  parameters: null,
};

const FilterableSearchContext = React.createContext<
  FilterableSearchContextType<EnhancedFilterableSearchType<any>, any>
>({
  ...DEFAULT_FILTER,
  setFilter: noop,
  resetFilter: noop,
});

export const FilterableSearchContextProvider: React.FC<{}> = ({ children }) => {
  const [{ type, value, parameters }, setFilter] =
    useState<FilterableSearchState<any>>(DEFAULT_FILTER);
  const botId = useCurrentBotId()!;

  const resetFilter = () => {
    setFilter(DEFAULT_FILTER);
  };

  useEffect(() => {
    resetFilter();
  }, [botId]);

  return (
    <FilterableSearchContext.Provider
      value={{ type, value, parameters, setFilter, resetFilter }}
    >
      {children}
    </FilterableSearchContext.Provider>
  );
};

export const useFilterableSearch = <T, U extends Object = Object>() =>
  useContext(
    FilterableSearchContext as React.Context<FilterableSearchContextType<T, U>>,
  );
