import { getPixiFieldStrict } from '../../../PixiFieldRepository';
import { getAlignPosition, Layout, LayoutView } from './Layout';
import { UpdateParams } from './types';
import { marginByFunc } from './utils';
import { resByFunc } from '../../../views/utils';

const findFirstVisibleViewIndex = (views: LayoutView[]) =>
  views.map(({ view }) => (view as Layout).visible).indexOf(true);

const findLastVisibleViewIndex = (views: LayoutView[]) =>
  views.map(({ view }) => (view as Layout).visible).lastIndexOf(true);

export class VLayout extends Layout {
  TEST_NAME = 'VLayout';
  renderElement({
    shouldRunOnBeforeRender,
    disabledRenderAfterUpdate,
  }: UpdateParams = {}) {
    let zIndex = 0;
    this._background.zOrder(zIndex++);
    let y = 0;
    let maxWidth = 0;
    this._views.forEach((lView, i) => {
      const { view } = lView;
      if (shouldRunOnBeforeRender) {
        view.onBeforeRender();
      }
      const { props: vProps } = lView;
      const gone = resByFunc(vProps.gone, this);
      const visible = resByFunc(vProps.visible, this);
      if (!gone) {
        const itemsOffset = resByFunc(this._layoutProps.itemsOffset, this)!;
        this._updateSizeAndZIndex(vProps, view, zIndex);
        const margin = marginByFunc(vProps.margin, this);
        view.x = margin.left;
        view.y = y + margin.top;
        view.renderElement({
          shouldRunOnBeforeRender,
          disabledRenderAfterUpdate,
        });
        y += margin.top + view.height() + margin.bottom;

        if (i < this._views.length - 1) y += itemsOffset;
        maxWidth = Math.max(
          maxWidth,
          margin.left + view.width() + margin.right,
        );
        zIndex += 1;
      }
      if (gone || !visible) {
        view.hide();
      } else {
        view.show();
      }
    });
    this.width(this._layoutProps.width || maxWidth);
    let newHeight = this._layoutProps.height || y;
    if (this._layoutProps.minHeight) {
      newHeight = Math.max(this._layoutProps.minHeight, newHeight);
    }
    this.height(newHeight);
    this._updateBackground();
    this._views.forEach((lView) => {
      const { view, props: vProps } = lView;
      const margin = marginByFunc(vProps.margin, this);
      if (vProps.align) {
        view.x =
          getAlignPosition(vProps.align, this.width(), view.width()) +
          margin.left;
      }
    });
    if (!disabledRenderAfterUpdate) {
      getPixiFieldStrict().render();
    }
    return this;
  }

  dragLimits({ view }: { view: Layout }) {
    const startIdx = findFirstVisibleViewIndex(this._views);
    const endIdx = findLastVisibleViewIndex(this._views);
    const startView = this._views[startIdx].view;
    const endView = this._views[endIdx].view;
    const { viewport } = getPixiFieldStrict();
    return {
      x: { min: 0, max: 0 },
      y: {
        min: (startView.y - view.y) * viewport.scale.y,
        max:
          (endView.y + endView.height() - view.y - view.height()) *
          viewport.scale.y,
      },
    };
  }
}
