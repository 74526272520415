import React, { useCallback } from 'react';
import { SupportedDevices } from '@globals';
import { Button } from '@ui/Button';
import { sendEvent } from '@utils/Analytics';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Item, SimpleCombobox } from '@ui/SimpleCombobox';
import { ReactComponent as DropdownIcon } from '@ui/_deprecated/Icon/icons/ic_dropdown_arr.svg';
import { PluginControlLabel } from '../../../../../../../Plugins/common/PluginControlLabel';
import { DEVICES_VARIANTS } from '../../consts';
import * as css from '../../CustomerChatEntryPoint.css';
import { ControlProps } from '../types';

export interface SupportedDevicesConfig {
  supported_devices: SupportedDevices | null;
}

export function SupportedDevicesControl<T extends SupportedDevicesConfig>({
  state,
  pluginConfig,
  pluginType,
}: ControlProps<T>) {
  const { t } = useSafeTranslation();

  const items = DEVICES_VARIANTS();
  const selectedItem = items.find(
    ({ id }) => id === (pluginConfig.supported_devices || SupportedDevices.ALL),
  );

  const onChange = useCallback(
    (item: Item | null) => {
      state.set(({ config }) => ({
        config: {
          ...config,
          supported_devices:
            (item?.id as SupportedDevices) || SupportedDevices.ALL,
        },
      }));
      state.save();
      sendEvent({
        category: 'flows',
        action: 'change supported_devices',
        label: pluginType,
        propertyBag: {
          supported_devices: item?.id || SupportedDevices.ALL,
        },
      });
    },
    [state, pluginType],
  );

  return (
    <PluginControlLabel
      label={t(
        'modernComponents.FlowBuilder.EditorPanel.SupportedDevicesControl',
      )}
    >
      {(bind) => (
        <SimpleCombobox
          items={items}
          selectedItem={selectedItem}
          onChange={onChange}
          menuboxStyle={{
            minWidth: 326,
          }}
          renderInput={({ getToggleButtonProps, selectedItem }) => (
            <Button
              intent="secondary"
              {...getToggleButtonProps()}
              iconRight={<DropdownIcon />}
              className={css.dropdownButton}
              {...bind}
            >
              {selectedItem?.title}
            </Button>
          )}
        />
      )}
    </PluginControlLabel>
  );
}
