import i18next from 'i18next';
import { GoogleSpreadsheets_bot_googleSpreadsheets } from '@utils/Data/Google/@types/GoogleSpreadsheets';

export const generateTitle = (
  googleSpreadsheets: GoogleSpreadsheets_bot_googleSpreadsheets[],
) => {
  const defaultSheetTitle = i18next.t('GoogleSheet-sheet-title');
  const sheetTitleRegExp = new RegExp(
    `^${defaultSheetTitle}(\\s+[0-9]+)?$`,
    'i',
  );

  const sameTitlesCount = googleSpreadsheets.reduce((acc, { title }) => {
    if (title) {
      const isSame = sheetTitleRegExp.test(title);
      if (isSame) {
        return acc + 1;
      }
    }
    return acc;
  }, 0);

  if (sameTitlesCount > 0) {
    return `${defaultSheetTitle} ${sameTitlesCount + 1}`;
  }

  return defaultSheetTitle;
};
