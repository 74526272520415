import React from 'react';
import { Spacer } from '@ui/Spacer';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { PluginHeader } from '../../../common/PluginHeader';
import { FollowUps, AttributeInput, AllowSkipButton } from '../components';
import { QuickReplyPluginData } from '../types';

export const DateAndTime: React.FC<QuickReplyPluginData> = (props) => {
  const { t } = useSafeTranslation();

  return (
    <div>
      <PluginHeader
        title={t(
          'modernComponents.FlowBuilder.views.components.CollectUserData.dateAndTime.title',
        )}
      />

      <AttributeInput {...props} />

      <Spacer factor={4} />

      <AllowSkipButton
        tooltipText={t(
          'modernComponents.FlowBuilder.views.components.CollectUserData.common.datePickerSkipButtonTooltip',
        )}
        {...props}
      />

      <Spacer factor={4} />

      <FollowUps
        tooltipText={t(
          'modernComponents.FlowBuilder.views.components.CollectUserData.dateAndTime.followUpsTooltip',
        )}
        {...props}
      />
    </div>
  );
};
