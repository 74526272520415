import React from 'react';
import { lensPath } from 'ramda';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { AdditionalProps } from '../types';
import { LabeledTokensView } from './LabeledTokensView';

export interface HeaderTextProps extends AdditionalProps {
  text: string;
}

export const HeaderText: React.FC<HeaderTextProps> = (props) => {
  const { t } = useSafeTranslation();
  const { headerParameter } = props.pluginConfig;

  return (
    <LabeledTokensView
      getTotalLength={() => 60}
      parameters={headerParameter && [headerParameter]}
      getLens={() => lensPath(['headerParameter', 'text'])}
      label={t(
        'modernComponents.FlowBuilder.plugins.WhatsappTemplatePlugin.headerText',
      )}
      {...props}
    />
  );
};
