import nanoid from 'nanoid';
import { getAuthToken } from '@utils/Auth/getAuthToken';

type TData<TResult> = { result: TResult };

export type Callback<TResult = {}> = (
  dataResult: TResult,
  data: TData<TResult>,
) => void;
export type ErrorCallback<TError = {}> = (
  error: TError,
  requestId: string,
) => void;

interface RequestConfig<TResult, TError> {
  url: string;
  callback?: Callback<TResult>;
  jsonData?: object;
  method?: string;
  plainData?: any;
  customHeaders?: any;
  fullUrl?: string;
  onError?: ErrorCallback<TError>;
  query?: any;
}

export function request<TResult, TError>({
  url,
  callback,
  jsonData,
  plainData,
  customHeaders,
  fullUrl,
  onError,
  query,
  ...rest
}: RequestConfig<TResult, TError>) {
  const token = getAuthToken();
  let { method } = rest;
  const requestId = nanoid();
  if (typeof method === 'undefined') {
    method = 'GET';
  }
  let headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
    'x-request-id': requestId,
  };
  if (typeof customHeaders !== 'undefined') {
    headers = customHeaders;
    headers.Authorization = `Bearer ${token}`;
    headers['x-request-id'] = requestId;
  }
  const obj = { method, headers };
  if (typeof jsonData !== 'undefined') {
    (obj as any).body = JSON.stringify(jsonData);
  }
  if (typeof plainData !== 'undefined') {
    (obj as any).body = plainData;
  }
  let finalUrl = `/api${url}`;
  if (typeof fullUrl !== 'undefined') {
    finalUrl = fullUrl;
  }
  if (query) {
    const queryKeys = Object.keys(query);
    finalUrl += queryKeys.reduce((acc, key) => {
      const value = Array.isArray(query[key])
        ? query[key].join(',')
        : query[key];
      return `${acc === '?' ? acc : `${acc}&`}${key}=${value}`;
    }, '?');
  }
  return fetch(finalUrl, obj)
    .then((response) => {
      const { status } = response;
      if ([400, 409, 429].includes(status)) {
        throw response;
      }
      return response.json();
    })
    .then((data) => {
      if (typeof callback !== 'undefined') {
        callback(data.result, data);
      }
      return data.result;
    })
    .catch((error) => {
      if (onError) {
        onError(error, requestId);
      }
      console.error('Error:', error);
    });
}
