import React from 'react';
import { SimpleCombobox, SimpleComboboxProps } from '@ui/SimpleCombobox';
import { Icon } from '@ui/Icon';
import { MenuItem } from '@ui/Menu';

const TITLE_ITEM: Partial<FilterMenuItem<string>> = {
  id: '_title',
  type: 'title',
};

interface FilterTypeMenuProps<TFilter> {
  title: string;
  items: FilterMenuItem<TFilter>[];
  children(props: any): JSX.Element;
  onChooseFilter(type: TFilter): void;
  comboboxProps?: Partial<SimpleComboboxProps<any>>;
}

export interface FilterMenuItem<TFilter> {
  id: TFilter;
  title: string;
  type?: string;
  icon?: string;
}

export const FilterTypeMenu = <T,>({
  title,
  items,
  children,
  comboboxProps,
  onChooseFilter,
}: React.PropsWithChildren<FilterTypeMenuProps<T>>) => {
  const titleItem: any = TITLE_ITEM;
  if (title) {
    titleItem.title = title;
  }
  return (
    <SimpleCombobox<FilterMenuItem<T>>
      {...comboboxProps}
      items={[titleItem].concat(items)}
      position="bottom-start"
      renderInput={children}
      renderItem={({
        getItemProps,
        item,
        index,
        highlightedIndex,
        closeMenu,
      }) => {
        const active = index === highlightedIndex;

        const commonProps = {
          ...getItemProps({ item }),
          key: `${item.id}_${index}`,
          type: item.type,
          active,
          title: item.title,
          onClick: (event: Event) => {
            event.preventDefault();
            if (item?.title && item.type === 'item') {
              onChooseFilter(item.id);
              closeMenu();
            }
          },
        };

        switch (item.type) {
          case 'title':
            return <MenuItem {...commonProps} />;

          default:
            return (
              <MenuItem
                {...commonProps}
                leftElement={
                  <Icon
                    size="20px"
                    icon={item.icon!}
                    color={active ? 'white' : 'black'}
                  />
                }
              />
            );
        }
      }}
      unsaveSelectedOption
    />
  );
};
