import React, { useMemo } from 'react';
import {
  BaseComboboxItem,
  ComboboxWithTriangleButton,
} from '@ui/SimpleCombobox';
import { CurrenciesQuery_currencies } from '@utils/Data/Currency/@types/CurrenciesQuery';
import { Type } from '@ui/Type';
import { useCurrencies } from '@utils/Data/Currency';
import { MenuItem } from '@ui/Menu';

interface CurrencySelectorProps {
  value: string;
  onChange: (value: string) => void;
}

interface CurrencyBaseComboboxItem extends BaseComboboxItem {
  currency: CurrenciesQuery_currencies;
}

const mapCurrencies = (
  currency: CurrenciesQuery_currencies,
): CurrencyBaseComboboxItem => ({
  id: currency.code,
  title: currency.name ?? currency.code,
  currency,
});

export const CurrencySelector: React.FC<CurrencySelectorProps> = ({
  value,
  onChange,
}) => {
  const { currencies } = useCurrencies();
  const selectedCurrencyItem = useMemo(
    () => currencies.find(({ code }) => code === value),
    [currencies, value],
  );

  return (
    <ComboboxWithTriangleButton<CurrencyBaseComboboxItem>
      onChange={({ currency }) => {
        onChange(currency.code);
      }}
      items={currencies.map(mapCurrencies)}
      buttonChildren={() => <Type size="15px">{value}</Type>}
      intent="secondary"
      selectedItem={selectedCurrencyItem && mapCurrencies(selectedCurrencyItem)}
      placement="top-end"
      data-testid="currency-selector_button"
    >
      {({
        downshift: { getItemProps, highlightedIndex },
        props: { item, index },
      }) => (
        <MenuItem
          title={item.title}
          active={index === highlightedIndex}
          key={item.id}
          data-testid={`currency-selector_item_${item.id}`}
          {...getItemProps({ item })}
        />
      )}
    </ComboboxWithTriangleButton>
  );
};
