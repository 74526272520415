import gql from 'graphql-tag';
import client from '@common/services/ApolloService';
import { CurrentWorkspaceId } from './@types/CurrentWorkspaceId';

export const CURRENT_WORKSPACE_ID = gql`
  query CurrentWorkspaceId($botId: String!) {
    bot(id: $botId) {
      workspace_id
    }
  }
`;

export const getCurrentWorksaceId = (botId: string) =>
  client.query<CurrentWorkspaceId>({
    query: CURRENT_WORKSPACE_ID,
    variables: { botId },
  });
