import React from 'react';
import { lensPath } from 'ramda';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { extractTokens } from '../utils';
import { AdditionalProps, TokenType, Token } from '../types';
import {
  WHATSAPP_ATTRIBUTE_DEFAULT_LENGTH,
  WHATSAPP_BODY_DEFAULT_MAX_LENGTH,
} from './consts';
import { LabeledTokensView } from './LabeledTokensView';

const getTotalBodyLength = (tokens: Token[]) => {
  const attributesLength = tokens.reduce((acc, curr) => {
    if (curr.type === TokenType.attribute) {
      /**
       * Whatsapp как то странно считает длинну аттрибутов в боди. По умолчанию макс длинна
       * текста в боди равна 1024. Но когда добавляется аттрибут например {{1}}, то максимальная
       * длинна увеличивается на 4! Даже учтя то, что в строке {{1}} символов 5. Если индекс
       * аттрибута состоит из 2х цифр, то есть {{22}} то макс длинна увеличится на 5. То вычислять
       * надо так:
       * (кол-во цифр в аттрибуте) - 1 + (2 откр фигурные скобки) + (2 закр фигурные скобки)
       */
      const weirdAttributeLength =
        curr.value.toString().length - 1 + WHATSAPP_ATTRIBUTE_DEFAULT_LENGTH;

      return acc + weirdAttributeLength;
    }

    return acc;
  }, 0);

  return WHATSAPP_BODY_DEFAULT_MAX_LENGTH + attributesLength;
};

export interface BodyProps extends AdditionalProps {
  text: string;
}

export const Body: React.FC<BodyProps> = (props) => {
  const { t } = useSafeTranslation();
  const tokens = extractTokens(props.text);

  return (
    <LabeledTokensView
      getTotalLength={() => getTotalBodyLength(tokens)}
      parameters={props.pluginConfig.bodyParameters}
      getLens={(index: number) => lensPath(['bodyParameters', index, 'text'])}
      label={t(
        'modernComponents.FlowBuilder.plugins.WhatsappTemplatePlugin.body',
      )}
      {...props}
    />
  );
};
