import {
  customAiPluginFragment_config_aiConditions,
  customAiPluginFragment_config_aiIntents,
} from '../../../../../Plugins/CustomAiPlugin/@types/customAiPluginFragment';

export const getPromptPreview = ({
  intents,
  conditions,
}: {
  intents: customAiPluginFragment_config_aiIntents[];
  conditions: customAiPluginFragment_config_aiConditions[];
}): string => {
  const intentsUserFilledContent = intents.map(intent => (intent.prompt || '').trim()).join('\n');
  const conditionsUserFilledContent = conditions.map(({ title, prompt }) => `${title ? `${title}: ` : ''}${prompt}`.trim())

  return `${intentsUserFilledContent}\n\n${conditionsUserFilledContent}`;
};
