import React from 'react';
import { Button } from '@ui/Button';
import { Flex } from '@ui/Flex';
import { Icon } from '@ui/Icon';
import { Spacer } from '@ui/Spacer';
import { DEFAULT_INTENT } from '../consts';
import { sendEvent } from '@utils/Analytics';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { OpenAiPluginIntent } from './OpenAiPluginIntent/OpenAiPluginIntent';
import { openAiPluginFragment_config_intents } from '@components/Plugins/OpenAiPlugin/@types/openAiPluginFragment';
import { AttributesQuery_bot_variableSuggest } from '@utils/AttributesUtils/@types/AttributesQuery';
import { TextWithAttributeEditor } from '../TextWithAttributeEditor/TextWithAttributeEditor';
import { OnboardingTourHTMLElementId } from '@globals';
import { Type } from '@ui/Type';
import { Tooltip2 } from '@ui/Tooltip2';
import { Input } from '@ui/Input';
import css from './IntentsTab.css';
import Maybe from 'graphql/tsutils/Maybe';

interface IntentsTabProps {
  updateIntents(newIntents: Array<openAiPluginFragment_config_intents>): void;
  intents: Array<openAiPluginFragment_config_intents>;
  botId: string;
  prompt: string;
  updatePrompt(newPrompt: string): void;
  updatePromptSafely(newPrompt: string): void;
  availableAttributes: AttributesQuery_bot_variableSuggest[];
  updateMessagesCount(count: string): void;
  updateMessagesCountSafely(count: string): void;
  messagesCount: Maybe<number>;
}

export const IntentsTab: React.FC<IntentsTabProps> = ({
  updateIntents,
  updatePrompt,
  updatePromptSafely,
  messagesCount,
  updateMessagesCount,
  updateMessagesCountSafely,
  intents,
  botId,
  prompt,
  availableAttributes,
}) => {
  const { t } = useSafeTranslation();
  return (
    <div>
      <Spacer factor={6} />
      <Flex
        flexDirection="column"
        gapFactor={1}
        data-testid="open_ai_delete_intent"
      >
        {intents.map((intent, i) => (
          <OpenAiPluginIntent
            /* eslint-disable-next-line react/no-array-index-key */
            key={i}
            index={i}
            intent={intent}
            canDelete={intents.length > 1}
            onChange={(changedIntent) => {
              const newIntents = [...intents];
              // @ts-ignore
              newIntents[i] = changedIntent;
              updateIntents(newIntents);
              sendEvent({
                category: 'open ai plugin',
                action: 'change intent',
                propertyBag: {
                  botId,
                  ...changedIntent,
                },
              });
            }}
            onDelete={(deletedIntent) => {
              const newIntents = [...intents];
              newIntents.splice(i, 1);
              updateIntents(newIntents);
              sendEvent({
                category: 'open ai plugin',
                action: 'delete intent',
                propertyBag: {
                  botId,
                  ...deletedIntent,
                },
              });
            }}
          />
        ))}
      </Flex>
      <Spacer factor={1.5} />
      <Button
        intent="secondary"
        icon={<Icon icon="plus" />}
        data-testid="open_ai_add_intent_button"
        onClick={() => {
          updateIntents([...intents, DEFAULT_INTENT]);
          sendEvent({
            category: 'open ai plugin',
            action: 'add intent',
            propertyBag: {
              botId,
            },
          });
        }}
      >
        {t(
          'modernComponents.FlowBuilder.views.components.OpenAiPlugin.userIntent',
        )}
      </Button>
      <Spacer factor={6} />
      <TextWithAttributeEditor
        inputContainerId={
          OnboardingTourHTMLElementId.OpenAiPluginAdditionInfoTextArea
        }
        title={t(
          'modernComponents.FlowBuilder.views.components.OpenAiPlugin.promptTitle',
        )}
        tooltip={t(
          'modernComponents.FlowBuilder.views.components.OpenAiPlugin.promptTitleTooltip',
        )}
        text={prompt}
        update={updatePrompt}
        updateSafely={updatePromptSafely}
        placeholder={t(
          'modernComponents.FlowBuilder.views.components.OpenAiPlugin.textareaPlaceholder',
        )}
        botId={botId}
        availableAttributes={availableAttributes}
      />
      <Spacer factor={6} />
      <Flex flexDirection="row" justifyContent="flex-start">
        <Type size="15px" weight="medium" as="span">
          {t(
            'modernComponents.FlowBuilder.views.components.OpenAiPlugin.messageHistory',
          )}
        </Type>
        <Spacer horizontalFactor={1.5} />
        <Tooltip2
          placement="right"
          boundariesElement="viewport"
          type="small"
          content={
            <Type size="12px" color="white">
              {t(
                'modernComponents.FlowBuilder.views.components.OpenAiPlugin.messageHistoryTooltip',
              )}
            </Type>
          }
          positionFixed
          hoverable
        >
          {(ref, bind) => (
            <Icon
              data-testid="openai_plugin-additional_instructions_tooltip"
              icon="help"
              color="blue"
              ref={ref}
              {...bind}
            />
          )}
        </Tooltip2>
      </Flex>
      <Spacer factor={2} />
      <Flex flexDirection="row" alignItems="center">
        <Type size="15px" as="span">
          {t(
            'modernComponents.FlowBuilder.views.components.OpenAiPlugin.rememberLast',
          )}
        </Type>
        <Spacer horizontalFactor={1.5} />
        <Input
          data-testid="open_ai_message-history"
          containerClassName={css.messageHistory}
          value={messagesCount ?? ''}
          onChange={(e) => {
            const value = e.target.value.replace(/[^0-9]+/g, '') || '0';
            const validateValue = Math.min(20, Number(value));
            updateMessagesCount(String(validateValue));
          }}
          onBlur={(e) => updateMessagesCountSafely(e.target.value)}
        />
        <Spacer horizontalFactor={1.5} />
        <Type size="15px" as="span">
          {t(
            'modernComponents.FlowBuilder.views.components.OpenAiPlugin.messages',
          )}
        </Type>
      </Flex>
    </div>
  );
};
