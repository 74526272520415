import React, { useEffect, useRef } from 'react';
import { Manager, Popper, Reference } from 'react-popper';
import { Portal } from 'react-portal';
import cn from 'classnames';
import { Flex } from '@ui/Flex';
import { ButtonPopupOverlayOptions, OverlayProps } from '../../types';
import {
  FlowBuilderOverlayEvent,
  MoveOverlayEvent,
  overlayEventEmitter,
} from '../../events';
import { TextareaWithAttributes } from '../TextareaWithAttributes';
import { Textarea } from '../Textarea';
import { ButtonPopupOverlayConfig } from './types';
import * as css from './ButtonPopupOverlay.css';

export interface ButtonPopupOverlayWrapperChildrenProps {
  scheduleUpdate: () => void;
}

export interface ButtonPopupOverlayContainerProps
  extends Omit<OverlayProps, 'value' | 'onChange'> {
  title: string;
  config: ButtonPopupOverlayConfig;
  setConfig: (config: ButtonPopupOverlayConfig) => void;
  children: (props: ButtonPopupOverlayWrapperChildrenProps) => React.ReactNode;
}

export const ButtonPopupOverlayContainer: React.FC<ButtonPopupOverlayContainerProps> =
  ({ title, config, setConfig, children, options, onKeyDown }) => {
    const {
      style,
      shouldShowOutsideControls,
      maxLength,
      hidePopup,
      predefinedAttributes,
    } = options as ButtonPopupOverlayOptions;

    const scheduleUpdateRef = useRef<undefined | (() => void)>();
    const boxRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
      const unsubscribers = [
        overlayEventEmitter.on<MoveOverlayEvent>(
          FlowBuilderOverlayEvent.move,
          () => {
            scheduleUpdateRef.current?.();
          },
        ),
        overlayEventEmitter.on(FlowBuilderOverlayEvent.show, () => {
          if (boxRef.current) {
            boxRef.current.style.opacity = '1';
            boxRef.current.style.pointerEvents = 'auto';
          }
        }),
        overlayEventEmitter.on(FlowBuilderOverlayEvent.hide, () => {
          if (boxRef.current) {
            boxRef.current.style.opacity = '0';
            boxRef.current.style.pointerEvents = 'none';
          }
        }),
      ];
      return () => {
        unsubscribers.forEach((fn) => fn());
      };
    }, []);

    const TextField = shouldShowOutsideControls?.attributes
      ? TextareaWithAttributes
      : Textarea;

    return (
      <Manager>
        <Reference>
          {(bind) => (
            <Flex justifyContent="center" className={css.anchor} {...bind}>
              <TextField
                options={{
                  style,
                  maxLength,
                  singleLine: true,
                  shouldShowOutsideControls,
                  initialValue: '',
                  predefinedAttributes,
                }}
                value={title}
                onKeyDown={onKeyDown}
                onChange={(value) => {
                  setConfig({
                    ...config,
                    title: value,
                  });
                }}
              />
            </Flex>
          )}
        </Reference>
        {!hidePopup && (
          <Portal>
            <Popper
              placement="right"
              modifiers={{
                offset: {
                  offset: '0, 12',
                },
              }}
            >
              {({ ref, style, placement, arrowProps, scheduleUpdate }) => {
                scheduleUpdateRef.current = scheduleUpdate;
                return (
                  <div
                    style={style}
                    ref={(el) => {
                      if (ref) {
                        (ref as (el: any) => void)(el);
                      }
                      boxRef.current = el;
                    }}
                    className={css.box}
                    data-testid="flowbuilder__button-type-popup"
                    onKeyDown={onKeyDown}
                  >
                    {children({ scheduleUpdate })}

                    <div
                      className={cn(css.arrow, css[`${placement}Arrow`])}
                      {...arrowProps}
                    />
                  </div>
                );
              }}
            </Popper>
          </Portal>
        )}
      </Manager>
    );
  };
