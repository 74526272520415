import { cardButtonFragment_payment_summary } from '../../../../../../Plugins/common/@types/cardButtonFragment';
import Maybe from 'graphql/tsutils/Maybe';

export const calcTotalAmount = (
  paymentSummary: Maybe<Partial<cardButtonFragment_payment_summary>>,
) =>
  paymentSummary?.price_list?.reduce(
    (total, { amount }) =>
      total + (typeof amount === 'string' ? parseFloat(amount) : amount || 0),
    0,
  ) || 0;
