import React from 'react';
import cn from 'classnames';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import { Anchor } from '@ui/Links';
import { usePageConnected } from '@utils/FacebookPages/usePageConnected';
import { useCurrentBotId } from '@utils/Routing';
import { StatItem } from '../../../../../components/StatItem';
import { FullCounterStats } from '../../../../../utils/Statistics/types';

import * as css from './InboundLink.css';

interface InboundLinkProps {
  title: string;
  stats?: FullCounterStats;
  href: string;
  onOpenHref(): void;
}

export const InboundLink: React.FC<InboundLinkProps> = ({
  title,
  href,
  stats,
  onOpenHref,
}) => {
  const botId = useCurrentBotId()!;
  const { isConnected } = usePageConnected(botId);
  return (
    <Flex justifyContent="space-between" style={{ marginTop: 8 }}>
      <Anchor
        intent="internal"
        href={href}
        rel="noopener noreferrer"
        target="_self"
        onMouseDown={() => {
          onOpenHref();
        }}
      >
        <Type
          size="15px"
          as="p"
          noWrap
          className={cn({
            [css.inboundLink]: !isConnected,
            [css.inboundLinkShort]: isConnected,
          })}
        >
          {title}
        </Type>
      </Anchor>
      {stats && (
        <>
          <Spacer factor={0} horizontalFactor={8} />
          <StatItem stats={stats} />
        </>
      )}
    </Flex>
  );
};
