import React from 'react';
import { PluginDataChildrenParams } from '@components/Plugins/common';
import { PluginType } from '@components/Plugins/common/PluginTypes';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import { Button } from '@ui/Button';
import { InputErrorBox } from '@ui/Input/InputErrorBox';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useFlowPlatform } from '@utils/Data/Flow/useFlowPlatform';
import { Timezone } from '@globals';
import { EditorPanelPluginProps } from '../../../types';
import { PluginHeader } from '../../common/PluginHeader';
import { googleSheetPluginFragment_config as GoogleSheetPluginConfig } from '../../../../../Plugins/GoogleSheetPlugin/@types/googleSheetPluginFragment';
import { GoogleSheetCombobox } from './GoogleSheetCombobox/GoogleSheetCombobox';
import { GoogleSheetAttributes } from './GoogleSheetAttributes/GoogleSheetAttributes';
import { AllAttributesCheckbox } from './AllAttributesCheckbox/AllAttributesCheckbox';
import { AttributesCallout } from './AttributesCallout/AttributesCallout';
import { useGoogleSheetPlugin } from './hooks/useGoogleSheetPlugin';
import * as css from './GoogleSheetPlugin.css';

export const GoogleSheetPlugin: React.FC<
  EditorPanelPluginProps<GoogleSheetPluginConfig>
> = ({ botId, pluginData, pluginParams }) => {
  const { pluginId, pluginType, blockId } = pluginData;

  return (
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    <GoogleSheetsPluginContent
      cardId={pluginId}
      blockId={blockId}
      botId={botId}
      pluginType={pluginType}
      {...pluginParams}
    />
  );
};

interface GoogleSheetsPluginContentProps
  extends PluginDataChildrenParams<GoogleSheetPluginConfig> {
  cardId: string;
  blockId: string;
  botId: string;
  pluginType: PluginType;
}

const GoogleSheetsPluginContent: React.FC<GoogleSheetsPluginContentProps> = (
  props,
) => {
  const { t } = useSafeTranslation();
  const platform = useFlowPlatform();

  const {
    ownerId,
    spreadsheetId,
    userAttributes,
    timezone,
    openedWithUtcTimezone,
    hasAuthenticationError,
    selectedSpreadsheetIsDeleted,
    selectedSpreadsheetHasItems,
    googleUserItems,
    googleSpreadsheetItems,
    attributes,
    googleUsersInputLoading,
    googleSpreadsheetsInputLoading,
    attributesLoading,
    allAttributesChecked,
    timezoneItems,
    handleGoogleUserAdd,
    handleGoogleUserSelect,
    handleGoogleUserReconnect,
    handleGoogleSpreadsheetAdd,
    handleGoogleSpreadsheetSelect,
    handleCheckboxClick,
    handleUserAttributeAdd,
    handleRearrangeUserAttributes,
    handleUserAttributeDeleteAt,
    handleTimezoneSelect,
  } = useGoogleSheetPlugin({ ...props, platform });

  /**
   * If there is something wrong with owner field we suggest reconnecting account
   */
  const renderGoogleUserSelect = () => {
    if (hasAuthenticationError) {
      return (
        <Button onClick={handleGoogleUserReconnect} intent="primary">
          {t('GoogleSheet-string-5862-reconnect-google-account')}
        </Button>
      );
    }

    return (
      <GoogleSheetCombobox
        items={googleUserItems}
        selectedItemId={ownerId}
        label={t(
          'modernComponents.FlowBuilder.plugins.GoogleSheetPlugin.selectAccount',
        )}
        placeholder={t('GoogleSheet-string-9518-choose-google-account')}
        addButtonTitle={t('GoogleSheet-string--291-connect-google-account')}
        loading={googleUsersInputLoading}
        onAdd={handleGoogleUserAdd}
        onSelect={handleGoogleUserSelect}
      />
    );
  };

  return (
    <div className={css.wrapper}>
      <PluginHeader
        title={t(
          'modernComponents.FlowBuilder.plugins.GoogleSheetPlugin.googleSheets',
        )}
      />
      <Spacer factor={4} />
      {renderGoogleUserSelect()}
      <Spacer factor={6} />
      <GoogleSheetCombobox
        items={googleSpreadsheetItems}
        selectedItemId={spreadsheetId}
        label={t(
          'modernComponents.FlowBuilder.plugins.GoogleSheetPlugin.selectSpreadsheet',
        )}
        placeholder={t('GoogleSheet-string--214-choose-google-sheet')}
        error={selectedSpreadsheetIsDeleted}
        addButtonTitle={t('GoogleSheet-string--684-add-new-spreadsheet')}
        loading={googleSpreadsheetsInputLoading}
        disabled={!ownerId}
        onAdd={handleGoogleSpreadsheetAdd}
        onSelect={handleGoogleSpreadsheetSelect}
      />
      {selectedSpreadsheetIsDeleted && (
        <InputErrorBox
          errorText={t(
            'modernComponents.FlowBuilder.views.plugins.GoogleSheet.deletedFromGoogle',
          )}
        />
      )}
      <Spacer factor={6} />
      {/* Legacy part. We have lots of bot with UTC timezone (because it was default value), */}
      {/* but this isn't clear for user (advanced users frequently change UTC to BOT), so we */}
      {/* decided to make BOT timezone default and forbid to change it */}
      {(openedWithUtcTimezone || timezone === Timezone.utc) && (
        <>
          <GoogleSheetCombobox
            items={timezoneItems}
            selectedItemId={timezone}
            label={t('GoogleSheet-string-2059-timestamp')}
            placeholder={t('GoogleSheet-string-1474-choose-timezone')}
            onSelect={handleTimezoneSelect}
          />
          <Spacer factor={6} />
        </>
      )}
      <Type size="15px" weight="medium">
        {t(
          'modernComponents.FlowBuilder.plugins.GoogleSheetPlugin.selectAttributes',
        )}
      </Type>
      <Spacer factor={3} />
      <AllAttributesCheckbox
        checked={allAttributesChecked}
        loading={attributesLoading}
        disabled={selectedSpreadsheetHasItems}
        onChange={handleCheckboxClick}
      />
      <Spacer factor={3} />
      <AttributesCallout
        spreadsheetHasItems={selectedSpreadsheetHasItems}
        allAttributesChecked={allAttributesChecked}
      />
      <Spacer factor={3} />
      {!allAttributesChecked && (
        <GoogleSheetAttributes
          attributeNames={userAttributes}
          platform={platform}
          allAttributes={attributes}
          editable={!selectedSpreadsheetHasItems}
          onAttributeAdd={handleUserAttributeAdd}
          onAttributesRearrange={handleRearrangeUserAttributes}
          onAttributeDeleteAt={handleUserAttributeDeleteAt}
        />
      )}
    </div>
  );
};
