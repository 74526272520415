import i18next from 'i18next';
import { prop, uniqBy } from 'ramda';
import { log } from 'cf-common/src/logger';
import {
  Plugin,
  StatefulPlugin,
  StatefulPluginDelegate,
} from '@components/FlowBuilder/StatefulPlugin';
import {
  HLayout,
  VLayout,
} from '@components/FlowBuilder/components/Elements/Layouts';
import { DividerView } from '@components/FlowBuilder/views/plugins/WhatsappListPlugin/DividerView';
import { PluginLogger } from '@components/FlowBuilder/utils/Analytics';
import {
  pluginWidth,
  textCardBackgroundColor,
} from '@components/FlowBuilder/views/plugin_consts';
import {
  Node,
  PluginType,
  SetPluginResult,
} from '@components/FlowBuilder/StatefulPlugin/types';
import { PluginTitleLayout } from '@components/FlowBuilder/views/components/PluginTitleLayout';
import { flowBuilderModule } from '@components/FlowBuilder/FlowBuilderModule';
import { Margin } from '@components/FlowBuilder/views/utility_classes';
import { getRolesAndInvites } from '../../../../components/settings/admins/AdminsConst';
import { RoleIds } from '../../../../components/settings/admins/RolesList';
import {
  getFlowControllerStrict,
  getPixiField,
} from '@components/FlowBuilder/PixiFieldRepository';
import { Permission } from '@common/services/RoleService';
import { ButtonView } from '@components/FlowBuilder/views/button_view';
import { HEXColors } from '@ui/_common/colors';
import { showChooseAssigneeDialog } from './components/ChooseAssignee';
import {
  closeSvgTexture,
  unknownUserSvgTexture,
} from '@components/FlowBuilder/assets/textures';
import {
  HTMLText,
  Image,
  TextureShape,
} from '@components/FlowBuilder/components/Elements/Shapes';
import { Admin } from '@components/AdminList/AdminListItem';
import { AssignAdminPlugin } from './AssignAdminPlugin';
import { AssignAdminPluginConfig } from './AssignAdminPluginConst';
import { getWorkspaceUsersAndInvites } from '@pages/BotListPage/components/WorkspaceList/Workspace/useWorkspaceUsersAndInvites';
import { getWorkspacesAvailable } from '@utils/Data/Workspaces/useWorkspacesAvailable';
import { getCurrentWorksaceId } from './utils';

const WRAPPER_MARGIN = new Margin({ x: 12, bottom: 12 });

interface Assignee {
  id: string;
  name: string;
  url: string;
}

interface AssigneeListItemProps {
  assignee: Assignee;
  onRemoveAssignee(assignee: Assignee): void;
}

export class AssigneeListItem extends VLayout {
  TEST_NAME = 'AssigneeListItem';

  constructor(private readonly props: AssigneeListItemProps) {
    super({
      width: pluginWidth - WRAPPER_MARGIN.x,
      margin: new Margin({ x: 12, y: 6 }),
      background: {
        fill: HEXColors.greyLight30,
      },
    });

    const wrapper = new HLayout();

    wrapper
      .addToLayout(this.renderAssignee(), {
        margin: new Margin({ y: 2 }),
      })
      .addToLayout(this.renderCloseButton());

    this.addToLayout(wrapper, { margin: new Margin({ x: 12, y: 6 }) });
  }

  private renderAssignee() {
    const wrapper = new HLayout();

    const assigneeImage = new Image({
      url: this.props.assignee.url,
      width: 16,
      height: 16,
      cornerRadius: 8,
    });
    const unknownAssignee = new TextureShape({
      texture: unknownUserSvgTexture,
      width: 16,
      height: 16,
      cornerRadius: 8,
    });

    wrapper
      .addToLayout(assigneeImage, {
        margin: new Margin({ y: 2, right: 8 }),
        gone: () => !this.props.assignee.url,
      })
      .addToLayout(unknownAssignee, {
        margin: new Margin({ y: 2, right: 8 }),
        gone: () => !!this.props.assignee.url,
      })
      .addToLayout(
        new HTMLText({
          text: this.props.assignee.name,
          singleLine: true,
          width: pluginWidth - WRAPPER_MARGIN.x - 16 - 8 - 12 - 12 - 24,
          height: 20,
          fontSize: 15,
        }),
      );

    return wrapper;
  }

  private renderCloseButton() {
    const button = new TextureShape({
      texture: closeSvgTexture,
      width: 24,
      height: 24,
    });

    button.on('pointerdown', (e) => {
      e.stopPropagation();

      this.props.onRemoveAssignee(this.props.assignee);
    });

    return button;
  }
}

export class AssignAdminPluginView
  extends VLayout
  implements StatefulPluginDelegate<AssignAdminPluginConfig>
{
  TEST_NAME = 'AssignAdminPluginView';

  private readonly logger: PluginLogger;
  private readonly state: StatefulPlugin<AssignAdminPluginConfig>;
  private readonly node: Node;
  private plugin = flowBuilderModule.getPlugin(
    PluginType.conversation_assign_admin,
  ) as AssignAdminPlugin;

  private readonly titleLayout: PluginTitleLayout;
  private assigneesContainer = new VLayout();
  private admins: Admin[] = [];

  constructor(state: StatefulPlugin<AssignAdminPluginConfig>, node: Node) {
    super({
      width: pluginWidth,
      background: {
        cornerRadius: 10,
        fill: textCardBackgroundColor,
      },
    });
    this.state = state;
    this.state.setDelegate(this);

    this.node = node;

    this.logger = new PluginLogger(
      this.state.data.plugin_id,
      this.node.id,
      this.state.data.id,
    );

    this.titleLayout = new PluginTitleLayout(
      false,
      this.plugin.getPluginDisplayName(),
      this.plugin.getIconTexture(),
      this.state.data,
    );

    const wrapper = new VLayout();

    wrapper
      .addToLayout(this.assigneesContainer)
      .addToLayout(this.renderAddAssigneeButton())
      .addToLayout(this.renderNote(), {
        gone: () => Number(this.getChoosedAdmins().length) < 2,
      });

    const margin = new Margin({ x: 12, y: 12 });
    this.addToLayout(this.titleLayout, { margin }).addToLayout(wrapper, {
      margin: WRAPPER_MARGIN,
    });

    if (!getPixiField()?.isViewOnly()) {
      this.queryAdminsData();
    }
  }

  private queryAdminsData() {
    const { botId } = getFlowControllerStrict().flow;

    const queryWorkspaceUsers = (workspaceId: string): Promise<Admin[]> =>
      getWorkspaceUsersAndInvites(workspaceId)
        .then(({ data }) =>
          (data.workspaceAdmins || [])
            .filter((admin) => admin.roleId && admin.roleId !== RoleIds.viewer)
            .map((admin) => ({
              id: admin.id,
              picture: admin.pictureUrl || '',
              name: admin.name!,
              white_label_role: null,
              roleId: admin.roleId,
            })),
        )
        .catch((error) => {
          log.error({
            error,
            msg: 'Failed to query workspace admins',
          });
          return [];
        });

    const queryCurrentWorkspaceId = (): Promise<Admin[]> =>
      getCurrentWorksaceId(botId)
        .then(({ data }) => {
          if (data.bot.workspace_id) {
            return queryWorkspaceUsers(data.bot.workspace_id);
          }

          return [];
        })
        .catch((error) => {
          log.error({
            error,
            msg: 'Failed to query id of current workspace',
          });
          return [];
        });

    const queryWorkspaceAdmins = (): Promise<Admin[]> =>
      getWorkspacesAvailable()
        .then(({ data }) => {
          if (data.me.workspacesAvailable) {
            return queryCurrentWorkspaceId();
          }

          return [];
        })
        .catch((error) => {
          log.error({
            error,
            msg: 'Failed to query availability of worksaces',
          });
          return [];
        });

    const queryBotAdmins = (): Promise<Admin[]> =>
      getRolesAndInvites({ botId: getFlowControllerStrict().flow.botId })
        .then(({ data }) =>
          data.bot.admins
            .filter(
              (admin) =>
                admin.role.permissions.inbox.permission === Permission.EDIT,
            )
            .map((admin) => ({
              id: admin.id,
              picture: admin.picture || '',
              name: admin.name!,
              white_label_role: admin.white_label_role,
              roleId: admin.role.id,
            })),
        )
        .catch((error) => {
          log.error({ error, msg: 'Could not query admins list' });
          return [];
        });

    Promise.all([queryWorkspaceAdmins(), queryBotAdmins()]).then(
      ([workspaceAdmins, botAdmins]) => {
        this.admins = uniqBy(prop('id'), [...workspaceAdmins, ...botAdmins]);
        this.renderAssignees();
        this.renderNode();
      },
    );
  }

  private renderNote() {
    const wrapper = new VLayout();

    wrapper
      .addToLayout(
        new DividerView({
          width: pluginWidth - WRAPPER_MARGIN.x,
          fill: HEXColors.grey,
        }),
        { margin: new Margin({ y: 12 }) },
      )
      .addToLayout(
        new HTMLText({
          text: i18next.t(
            'modernComponents.FlowBuilder.views.components.AssignAdmin.plugin.note',
          ),
          singleLine: false,
          width: pluginWidth - WRAPPER_MARGIN.x,
          trustedHtml: true,
          fontSize: 12,
          fill: HEXColors.greyDark,
        }),
      );

    return wrapper;
  }

  private getChoosedAdmins() {
    const admins = (this.state.data.config.admin_ids || [])
      ?.map((id) => this.admins.find((admin) => admin.id === id)!)
      .filter(Boolean);

    return admins;
  }

  private renderAssignees() {
    this.assigneesContainer._views.forEach(({ view }) => view.destroy());
    this.assigneesContainer._views.length = 0;

    this.getChoosedAdmins().forEach((admin) => {
      this.assigneesContainer.addToLayout(
        new AssigneeListItem({
          assignee: {
            id: admin.id,
            url: admin.picture || '',
            name: admin.name || '',
          },
          onRemoveAssignee: this.onRemoveAssignee.bind(this),
        }),
        { margin: new Margin({ bottom: 12 }) },
      );
    });
  }

  private onRemoveAssignee(assignee: Assignee) {
    this.logger.log('Assignee removed', { assignee });

    this.state.setAndSave(({ config }) => ({
      config: {
        ...config,
        admin_ids: config.admin_ids?.filter((id) => id !== assignee.id) || [],
      },
    }));
    this.renderAssignees();
    this.renderNode();
  }

  private renderAddAssigneeButton() {
    return new ButtonView({
      title: i18next.t(
        'modernComponents.FlowBuilder.views.components.AssignAdmin.plugin.addAssignee',
      ),
      fontStyle: 'semibold',
      width: pluginWidth - WRAPPER_MARGIN.x,
      bgColor: HEXColors.white,
      textColor: HEXColors.black,
      onClick: this.onAddAssigneeButton.bind(this),
    });
  }

  private onAddAssigneeButton() {
    this.logger.log('Add assignee click');

    showChooseAssigneeDialog({
      allAdmins: this.admins,
      selectedAdminsIds: this.state.data.config.admin_ids || [],
    })?.then((ids) => {
      this.state.setAndSave(({ config }) => ({
        config: {
          ...config,
          admin_ids: ids as Array<string>,
        },
      }));
      this.renderAssignees();
      this.renderNode();
    });
  }

  public hasSignificantChangesInConfig() {
    return Boolean(this.state.data.config.admin_ids?.length);
  }

  public validationError() {
    if ((this.state.data.config.admin_ids?.length ?? 0) === 0) {
      return {
        message: i18next.t(
          'modernComponents.FlowBuilder.views.components.AssignAdmin.plugin.validationError',
        ),
      };
    }

    return undefined;
  }

  pluginDidSet(
    _: Plugin<AssignAdminPluginConfig>,
    __: SetPluginResult<AssignAdminPluginConfig>,
  ): void {
    this.renderNode();
  }

  destroy(): void {
    this.state.destroy();
    super.destroy();
  }
}
