import React from 'react';
import { log } from 'cf-common/src/logger';
import { EditorPanelPluginProps } from '../../../types';
import { QuickReplyPluginConfig } from '../../../../../Plugins/QuickReplyPlugin/QuickReplyPluginConst';
import { QuickReplyType } from '@globals';
import { DatePicker } from './DatePicker/DatePicker';
import { DateAndTime } from './DateAndTime/DateAndTime';
import { QuickReplyPluginData } from './types';

export const DatePickerPlugin: React.FC<
  EditorPanelPluginProps<QuickReplyPluginConfig>
> = ({ pluginParams }) => {
  const VIEW_BY_TYPE: Record<string, React.FC<QuickReplyPluginData>> = {
    [QuickReplyType.date]: DatePicker,
    [QuickReplyType.datetime]: DateAndTime,
  };

  const { type } = pluginParams.pluginConfig;
  const View = VIEW_BY_TYPE[type!];

  if (!View) {
    log.error({ msg: `Unsupported type ${type}` });
    return null;
  }

  return <View {...pluginParams} />;
};
