import React from 'react';
import cn from 'classnames';
import { ButtonUnstyled } from '@ui/Button';
import { usePreventWheelOnRef } from '@utils/Event/usePreventWheel';
import { ReactComponent as CloseIcon } from '../../../../../modern-ui/_deprecated/Icon/icons/ic_close.svg';
import { useEditorPanelOutsideClick } from '../../hooks/useEditorPanelOutsideClick';
import * as css from '../../EditorPanel.css';
import { CollapseButton } from './components/CollapseButton';
import { OnboardingTourEventType, OnboardingTourHTMLElementId } from '@globals';
import { OnboardingEmitter } from '@components/Onboarding/OnboardingTour/utils/onboardingEmitter';

export interface EditorPanelViewProps {
  width: number;
  editorPanelId?: string;
  children: React.ReactNode;
  className?: string;
}

export const EditorPanelView = React.forwardRef<
  HTMLDivElement,
  EditorPanelViewProps
>(({ children, width, className, editorPanelId }, ref) => {
  return (
    <div
      ref={ref}
      className={cn(css.editorPanelContainer, className)}
      style={{ minWidth: width, width }}
    >
      <div
        id={editorPanelId}
        data-testid="editor-panel"
        className={css.editorPanel}
      >
        {children}
      </div>
    </div>
  );
});

export interface DismissableEditorPanelViewProps extends EditorPanelViewProps {
  onDismiss: () => void;
}

export const DismissableEditorPanelView: React.FC<DismissableEditorPanelViewProps> =
  ({ onDismiss, ...props }) => {
    const ref = useEditorPanelOutsideClick({ onOutsideClick: onDismiss });
    usePreventWheelOnRef(ref);

    return <EditorPanelView ref={ref} {...props} />;
  };

export interface ClosableEditorPanelViewProps
  extends Omit<EditorPanelViewProps, 'onDismiss'> {
  onClose: () => void;
}

export const ClosableEditorPanelView: React.FC<ClosableEditorPanelViewProps> =
  ({ children, onClose, width, editorPanelId }) => {
    const handleClose = () => {
      onClose();
      OnboardingEmitter.emit(OnboardingTourEventType.click, {
        element: OnboardingTourHTMLElementId.PluginCloseButton,
        value: null,
      });
    };
    const ref = useEditorPanelOutsideClick({ onOutsideClick: handleClose });
    usePreventWheelOnRef(ref);

    return (
      <DismissableEditorPanelView
        width={width}
        onDismiss={onClose}
        editorPanelId={editorPanelId}
      >
        <ButtonUnstyled
          id={OnboardingTourHTMLElementId.PluginCloseButton}
          data-testid="close-editor-panel"
          onClick={handleClose}
          className={css.closeButton}
          autoFocus
        >
          <CloseIcon />
        </ButtonUnstyled>
        {children}
      </DismissableEditorPanelView>
    );
  };

export interface CollapsableEditorPanelViewProps {
  showAside: boolean;
  width: number;
  setShowAside(showAside: boolean): void;
  className?: string;
}

export const CollapsableEditorPanelViewProps: React.FC<CollapsableEditorPanelViewProps> =
  ({ children, showAside, setShowAside, className, width }) => (
    <EditorPanelView
      className={cn(className, !showAside && css.collapse)}
      width={showAside ? width : 24}
    >
      {showAside && children}
      <CollapseButton
        active={showAside}
        onClick={() => {
          setShowAside(!showAside);
        }}
      />
    </EditorPanelView>
  );
