import React, { useRef } from 'react';
import { prop } from 'ramda';
import Downshift from 'downshift';
import { Popper } from 'react-popper';
import { Menubox, MenuItem } from '@ui/Menu';
import { Icon } from '@ui/Icon';
import { sendEvent } from '@utils/Analytics';
import { testThisInChatfuel } from '@components/QuickAccessToolbar/TestThis';
import { usePreventWheel } from '@utils/Event/usePreventWheel';
import { OverlayProps, TestInFacebookOverlayOptions } from '../types';

export const TestInChatfuel: React.FC<OverlayProps> = ({ options, onDone }) => {
  const boxRef = usePreventWheel();
  const anchorRef = useRef<HTMLButtonElement | null>(null);

  const { blockId } = options as TestInFacebookOverlayOptions;

  return (
    <div ref={boxRef}>
      <Downshift itemToString={prop('title')}>
        {({ getItemProps, getToggleButtonProps, highlightedIndex }) => (
          <div>
            <div
              {...getToggleButtonProps({
                // use not focused element (div) for prevent keyboard interaction
                style: {
                  width: 1,
                  height: 1,
                  opacity: 0,
                },
                ref: (el) => {
                  anchorRef.current = el;
                },
              })}
            />
            <Popper
              placement="bottom-start"
              referenceElement={anchorRef.current || undefined}
            >
              {({ ref, style }) => {
                const active = highlightedIndex === 0;
                const item = {
                  title: window.i18next.t(
                    'TestInChatfuel-string--122-test-here-in-chatfuel',
                  ),
                  icon: 'chatSimple',
                };
                const itemProps = getItemProps({ item });

                return (
                  <Menubox ref={ref} style={style}>
                    <MenuItem
                      title={item.title}
                      active={active}
                      {...itemProps}
                      onClick={() => {
                        sendEvent({
                          category: 'test in chatfuel',
                          action: 'test this block clicked',
                          propertyBag: { refId: blockId },
                        });
                        testThisInChatfuel(blockId);
                        onDone?.();
                      }}
                      leftElement={
                        <Icon
                          icon={item.icon}
                          color={active ? 'white' : 'blue'}
                          style={{
                            marginLeft: -8,
                          }}
                        />
                      }
                    />
                  </Menubox>
                );
              }}
            </Popper>
          </div>
        )}
      </Downshift>
    </div>
  );
};
