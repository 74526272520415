import { SEND_TO_MESSENGER_TEXTS } from '../../../../../../pages/GrowPage/AbandonedCart/AbandonedCartPage/sendToMessengerTexts';
import { SendToMessengerColor, SendToMessengerSize } from '@globals';
import { Item } from '@ui/SimpleCombobox';

export const getCTATexts: () => Item[] = () => [
  {
    id: '',
    title: 'Send to Messenger',
  },
  ...SEND_TO_MESSENGER_TEXTS(),
];

export const CTA_TEXTS = getCTATexts();

export const BUTTON_COLORS: Item[] = [
  {
    title: 'Blue',
    id: SendToMessengerColor.blue,
  },
  {
    title: 'White',
    id: SendToMessengerColor.white,
  },
];

export const BUTTON_SIZE: Item[] = [
  {
    title: 'Standard',
    id: SendToMessengerSize.standard,
  },
  {
    title: 'Large',
    id: SendToMessengerSize.large,
  },
  {
    title: 'XLarge',
    id: SendToMessengerSize.xlarge,
  },
];
