import React from 'react';
import { Input } from '@ui/Input';
import { CurrencySelector } from '../CurrencySelector';
import { InputWithPostfix } from '@ui/Input/InputWithPostfix';
import { Type } from '@ui/Type';
import { Flex } from '@ui/Flex';
import { useIMask } from 'react-imask';
import { getAmountMaskConfig } from '../../../../../../consts';
import * as css from '../../PriceListItem.css';

interface AmountInputProps {
  currency: string;
  amount: number | null;
  showCurrencySelector: boolean;
  onChangeCurrency: (currency: string) => void;
  onChangeAmount: (amount: number) => void;
  onSaveRequest: () => void;
}

export const AmountInput: React.FC<AmountInputProps> = ({
  currency,
  amount,
  showCurrencySelector,
  onChangeAmount,
  onChangeCurrency,
  onSaveRequest,
}) => {
  const amountMaskConfig = getAmountMaskConfig(currency);
  const { ref: maskRef, value } = useIMask(amountMaskConfig, {
    onAccept: (_, maskRef) => {
      onChangeAmount(parseFloat(maskRef.unmaskedValue) || 0);
    },
  });

  const amountInputProps = {
    defaultValue: value || (amount || '').toString(10),
    ref: maskRef,
    placeholder: `0${amountMaskConfig.radix}${'0'.repeat(
      amountMaskConfig.scale,
    )}`,
    onBlur: onSaveRequest,
    className: css.input,
  };

  return (
    <Flex gapFactor={3} data-testid="amount-input__currency">
      {showCurrencySelector ? (
        <>
          <Input
            {...amountInputProps}
            data-testid="amount-input__currency-input"
          />
          <CurrencySelector
            value={currency}
            onChange={(code) => {
              onChangeCurrency(code);
              onSaveRequest();
            }}
          />
        </>
      ) : (
        <InputWithPostfix
          renderPostfix={() => (
            <Type
              size="15px"
              color="baseTertiary"
              className={css.amountPostfix}
            >
              {currency}
            </Type>
          )}
          {...amountInputProps}
        />
      )}
    </Flex>
  );
};
