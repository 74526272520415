import React, { CSSProperties } from 'react';
import { Position } from '@ui/Tooltip2';
import { AttributesQuery_bot_variableSuggest } from '@utils/AttributesUtils/@types/AttributesQuery';
import { ShouldShowOutsideControls } from '@ui/TextOutsideControls';
import { ExternalAccountDropdownProps as ExternalAccountOverlayOptions } from '@utils/Integrations/common/ExternalAccountDropdown';
import { Platform } from '@globals';
import {
  Block,
  ButtonPopupOverlayConfig,
  BubbleInputOverlayConfig,
} from './overlays';
import { IMenuItem, IMenuNode } from '../views/Menu/types';

export interface OverlayPosition {
  x: number;
  y: number;
  scale: number;
  width?: number;
  height?: number;
}

export enum OverlayType {
  textarea = 'textarea',
  textareaWithAttributes = 'textareaWithAttributes',
  menu = 'menu',
  tooltip = 'tooltip',
  inputAttribute = 'inputAttribute',
  blocksSuggest = 'blocksSuggest',
  buttonPopup = 'buttonPopup',
  nativeHtml = 'nativeHtml',
  testInFacebook = 'testInFacebook',
  bubbleInput = 'bubbleInput',
  accountSelector = 'accountSelector',
}

export enum ControlVisibility {
  auto = 'auto',
  show = 'show',
  hide = 'hide',
}

export interface TextOutsideControlsVisibility {
  emoji?: ControlVisibility;
  attributes?: ControlVisibility;
  symbolsLimit?: ControlVisibility;
}

export interface TextFieldOverlayOptions {
  shouldShowOutsideControls: ShouldShowOutsideControls;
  maxLength?: number;
  style?: CSSProperties;
  singleLine?: boolean;
  customAttributesOnly?: boolean;
  numbersOnly?: boolean;
  numbersFractionAllowed?: boolean;
  maskPrefixLength?: number;
  max?: number;
  min?: number;
  predefinedAttributes?: AttributesQuery_bot_variableSuggest[];
  shouldJoinAttributes?: boolean;
}

interface TooltipOptions {
  html?: string;
  text?: string;
  url?: string;
  width?: number;
  height?: number;
  onClick?(): void;
  position?: Position;
}

export interface MenuOverlayOptions {
  items: IMenuNode<IMenuItem>[];
  flowId?: string;
  defaultValue?: string | null;
  style?: React.CSSProperties;
  searchable?: boolean;
  isPlanLimitMessage?: boolean;
}

export interface TestInFacebookOverlayOptions {
  blockId: string;
}

export interface BlocksSuggestOverlayOptions {
  platform: Platform;
  blocksIds: string[];
  blocksTitles: Block[];
}

export interface ButtonPopupOverlayOptions {
  config: ButtonPopupOverlayConfig;
  style?: CSSProperties;
  hideCalendlyOptions?: boolean;
  hidePaymentOption?: boolean;
  hideCallPhoneOption?: boolean;
  disablePaymentOption?: boolean;
  hideContinueToFlowOption?: boolean;
  hidePopup?: boolean;
  shouldShowOutsideControls?: ShouldShowOutsideControls;
  maxLength?: number;
  predefinedAttributes?: AttributesQuery_bot_variableSuggest[];
  platform: Platform;
}

export interface BubbleInputOverlayOptions {
  config: BubbleInputOverlayConfig;
  height: number;
  width: number;
}

export interface TooltipOverlayOptions extends TooltipOptions {
  children?: React.ReactNode;
}

type OverlayOptionsUni =
  | TextFieldOverlayOptions
  | MenuOverlayOptions
  | TooltipOverlayOptions
  | BlocksSuggestOverlayOptions
  | ButtonPopupOverlayOptions
  | TestInFacebookOverlayOptions
  | ExternalAccountOverlayOptions
  | BubbleInputOverlayOptions;

export type OverlayOptions = OverlayOptionsUni & {
  initialValue: string;
  zIndex?: number;
};

export interface OverlayProps<T = any> {
  options: OverlayOptions;
  value: T;
  onChange: (value: T) => void;
  onDone?: () => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLElement>) => void;
}
