import * as PopperJS from 'popper.js';
import { filter, flatten, groupBy, map, pipe, uniqWith } from 'ramda';
import { FLOW_BUILDER_ROOT_ID } from '../../../consts';
import {
  IMenuItem,
  IMenuNode,
  MenuItemType,
  MenuNodeId,
} from '../../../views/Menu/types';

export const getGroupedMenu = (items: IMenuNode<IMenuItem>[]) => {
  const { staticItems, filterableItems } = groupBy<IMenuNode<IMenuItem>>(
    ({ id }) =>
      id === MenuNodeId.contextItems || id === MenuNodeId.buttons
        ? 'staticItems'
        : 'filterableItems',
    items,
  );

  return {
    staticItems: staticItems?.map(({ children }) => children).flat() as
      | IMenuNode<IMenuItem>[]
      | undefined,
    filterableItems,
  };
};

export const filterMenu = (input: string, menu: IMenuNode<IMenuItem>[]) =>
  pipe(
    // @ts-expect-error
    map((item) => (item.children ? item.children : item)),
    flatten,
    map(({ children, ...item }) => [item, ...(children || [])]),
    flatten,
    uniqWith((a, b) => a.id === b.id),
    filter<IMenuItem>(({ type }) =>
      [
        MenuItemType.plugin,
        MenuItemType.block,
        MenuItemType.entrypoint,
      ].includes(type),
    ),
    filter(({ title }) => title.toLowerCase().includes(input.toLowerCase())),
  )(menu);

export const getPopperModifiers: () => PopperJS.Modifiers = () => {
  const boundariesElement = document.getElementById(FLOW_BUILDER_ROOT_ID);

  if (!boundariesElement) {
    throw new Error('Could not find flow bulder root element');
  }

  return {
    flip: {
      boundariesElement,
      enabled: true,
    },
    preventOverflow: {
      boundariesElement,
      enabled: true,
    },
  };
};
