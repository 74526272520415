import { useRef } from 'react';
import { PluginType } from '@components/Plugins/common/PluginTypes';
import { PluginDataChildrenParams } from '@components/Plugins/common';
import { googleSheetPluginFragment_config as GoogleSheetPluginConfig } from '@components/Plugins/GoogleSheetPlugin/@types/googleSheetPluginFragment';
import { Platform, Timezone } from '@globals';
import { logFlowPluginEvent } from '../../../../../utils/Analytics';
import {
  getPluginData,
  updatePluginData,
} from '../../../../../StatefulPlugin/ApolloState/utils';
import { getFlowControllerStrict } from '../../../../../PixiFieldRepository';
import { Item } from '../GoogleSheetCombobox/GoogleSheetCombobox';
import { useGoogleSheetData } from './useGoogleSheetData';
import { useGoogleSheetItems } from './useGoogleSheetItems';
import { useGoogleSheetAttributes } from './useGoogleSheetAttributes';

const EMPTY_ATTRIBUTES: string[] = [];

interface UseGoogleSheetPluginArgs
  extends PluginDataChildrenParams<GoogleSheetPluginConfig> {
  cardId: string;
  blockId: string;
  botId: string;
  platform: Platform;
  pluginType: PluginType;
}

export const useGoogleSheetPlugin = ({
  cardId,
  botId,
  platform,
  loading,
  saving,
  savePlugin,
  setPluginConfigState,
  pluginConfig,
  validationErrors,
}: UseGoogleSheetPluginArgs) => {
  const {
    owner_id,
    spreadsheet_id,
    user_attributes,
    use_all_attributes,
    timezone,
  } = pluginConfig;

  const openedWithUtcTimezoneRef = useRef(timezone === Timezone.utc);
  const hasAuthenticationError = Boolean(owner_id && validationErrors.owner_id);
  const userAttributes = user_attributes ?? EMPTY_ATTRIBUTES;

  const {
    googleUsers,
    googleSpreadsheets,
    googleUsersLoading,
    googleSpreadsheetsLoading,
    connectGoogleAccount,
    accountConnecting,
    createGoogleSheet,
    googleSheetCreating,
  } = useGoogleSheetData(botId, owner_id);

  const itemProps = useGoogleSheetItems(
    googleUsers,
    googleSpreadsheets,
    spreadsheet_id,
  );
  const { attributes, attributesLoading } = useGoogleSheetAttributes(
    botId,
    platform,
    userAttributes,
  );

  const updateOwnerId = (ownerId: string) => {
    if (saving) return;
    const config = {
      ...pluginConfig,
      spreadsheet_id: null,
      owner_id: ownerId,
    };
    setPluginConfigState({ config });
    savePlugin();
  };

  const updateGoogleSpreadsheetId = (spreadsheetId: string) => {
    if (saving) return;
    const config = {
      ...pluginConfig,
      spreadsheet_id: spreadsheetId,
    };
    setPluginConfigState({ config });
    savePlugin();
  };

  /**
   * @param config new config
   *
   * If config changed we have to update all Google Sheets plugin with the same sheet
   * in this flow (it is backend logic). Because Google Sheet plugins with same google share configs
   */
  const updateConnectedPlugins = (config: GoogleSheetPluginConfig) => {
    const currentSpreadsheetId = config.spreadsheet_id;
    if (!currentSpreadsheetId) return;

    const otherGoogleSheetPluginsIds = getFlowControllerStrict()
      .flow.blocks.flatMap(({ cards }) => cards)
      .filter((card) => {
        return (
          card?.plugin_id === PluginType.google_sheet && card?.id !== cardId
        );
      })
      .map((card) => card!.id);
    const otherGoogleSheetPluginsIdsWithSameSpreadsheet =
      otherGoogleSheetPluginsIds.filter(
        (id) =>
          getPluginData<GoogleSheetPluginConfig>(id)?.card.config
            .spreadsheet_id === currentSpreadsheetId,
      );
    otherGoogleSheetPluginsIdsWithSameSpreadsheet.forEach((id) => {
      updatePluginData<GoogleSheetPluginConfig>(id, (data) => {
        data.card.config = config; // eslint-disable-line no-param-reassign
      });
    });
  };

  const updateUserAttributes = (nextUserAttributes: string[]) => {
    if (saving) return;
    const config = {
      ...pluginConfig,
      user_attributes: nextUserAttributes,
    };
    setPluginConfigState({ config });
    savePlugin();
    updateConnectedPlugins(config);
  };

  const handleRearrangeUserAttributes = (nextUserAttributes: string[]) => {
    logFlowPluginEvent(PluginType.google_sheet, 'rearrange attributes');
    updateUserAttributes(nextUserAttributes);
  };

  const handleUserAttributeAdd = (attributeName: string) => {
    logFlowPluginEvent(PluginType.google_sheet, 'add attribute', {
      attribute: attributeName,
    });
    updateUserAttributes([...userAttributes, attributeName]);
  };

  const handleUserAttributeDeleteAt = (indexToDelete: number) => {
    logFlowPluginEvent(PluginType.google_sheet, 'remove attribute', {
      attribute: userAttributes[indexToDelete],
    });
    updateUserAttributes(
      userAttributes.filter((_, index) => index !== indexToDelete),
    );
  };

  const handleGoogleUserSelect = (item: Item) => {
    logFlowPluginEvent(PluginType.google_sheet, 'select google account', {
      googleAccountId: item.id,
    });
    updateOwnerId(item.id);
  };

  const handleConnectGoogleAccount = async () => {
    const ownerId = await connectGoogleAccount();
    if (ownerId) {
      updateOwnerId(ownerId);
    }
  };

  const handleGoogleUserAdd = () => {
    logFlowPluginEvent(PluginType.google_sheet, 'connect google account');
    return handleConnectGoogleAccount();
  };

  const handleGoogleUserReconnect = () => {
    logFlowPluginEvent(PluginType.google_sheet, 'reconnect google account');
    return handleConnectGoogleAccount();
  };

  const handleGoogleSpreadsheetAdd = async () => {
    logFlowPluginEvent(PluginType.google_sheet, 'add new spreadsheet');
    const spreadsheetId = await createGoogleSheet();
    if (spreadsheetId) {
      updateGoogleSpreadsheetId(spreadsheetId);
    }
  };

  const handleGoogleSpreadsheetSelect = (item: Item) => {
    logFlowPluginEvent(PluginType.google_sheet, 'select spreadsheet', {
      spreadsheetId: item.id,
    });
    updateGoogleSpreadsheetId(item.id);
  };

  const handleCheckboxClick = () => {
    if (saving) return;
    logFlowPluginEvent(PluginType.google_sheet, 'send all attributes', {
      checked: !pluginConfig.use_all_attributes,
    });
    const config = {
      ...pluginConfig,
      use_all_attributes: !pluginConfig.use_all_attributes,
    };
    setPluginConfigState({ config });
    savePlugin();
    updateConnectedPlugins(config);
  };

  const handleTimezoneSelect = (item: Item) => {
    if (saving) return;
    logFlowPluginEvent(PluginType.google_sheet, 'select timestamp', {
      timestamp: item.id,
    });
    const config = {
      ...pluginConfig,
      timezone: item.id as Timezone,
    };
    setPluginConfigState({ config });
    savePlugin();
    updateConnectedPlugins(config);
  };

  const selectedSpreadsheet = googleSpreadsheets.find(
    ({ id }) => id === spreadsheet_id,
  );
  const selectedSpreadsheetIsDeleted = !!selectedSpreadsheet?.deletedFromGoogle;
  const selectedSpreadsheetHasItems = !!selectedSpreadsheet?.hasItems;

  const googleUsersInputLoading =
    loading || googleUsersLoading || accountConnecting;
  const googleSpreadsheetsInputLoading =
    googleUsersInputLoading || googleSpreadsheetsLoading || googleSheetCreating;

  return {
    ownerId: owner_id,
    spreadsheetId: spreadsheet_id,
    userAttributes,
    timezone,
    openedWithUtcTimezone: openedWithUtcTimezoneRef.current,
    hasAuthenticationError,
    selectedSpreadsheetIsDeleted,
    selectedSpreadsheetHasItems,
    ...itemProps,
    attributes,
    googleUsersInputLoading,
    googleSpreadsheetsInputLoading,
    attributesLoading,
    allAttributesChecked: Boolean(use_all_attributes),
    handleGoogleUserAdd,
    handleGoogleUserReconnect,
    handleGoogleUserSelect,
    handleGoogleSpreadsheetAdd,
    handleGoogleSpreadsheetSelect,
    handleCheckboxClick,
    handleUserAttributeAdd,
    handleRearrangeUserAttributes,
    handleUserAttributeDeleteAt,
    handleTimezoneSelect,
  };
};
