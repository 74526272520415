import { useEffect, useState } from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { timeDiff, flowBuilderRenderTime } from 'cf-common/src/metrics';
import { onRenderedFBRouteCallbackService } from '@services/RouteCallbackService/instances/onRenderedFBRouteCallbackService';
import { log } from 'cf-common/src/logger';
import { Feature, ViewMode } from '../../consts';
import { FlowController } from '../../flow/controller';
import {
  pixiFieldRepository,
  getFlowController,
  getPixiField,
} from '../../PixiFieldRepository';
import { FlowData } from '../../types';
import { getFocusedBlock } from '../../utils/focusedBlock';
import { PixiField } from '../../PixiField';
import { syncWithConfigurePersistentMenu } from './syncWithConfigurePersistentMenu';

interface FlowBuilderExternalActions {
  getBlob(): Promise<Blob | null>;
}

interface DrawFlowBuilderParams {
  mode: ViewMode;
  loadFlow(): Promise<FlowData>;
  availableFeatures: Feature[];
  rootElementId: string;
  routeFlowId: string;
  defaultScale?: number;
  drawLifecycleActions: DrawFlowBuilderLifecycleActions;
}

export interface DrawFlowBuilderLifecycleActions {
  onLoading?(): void;
  onReady?(): void;
  onRendered?(actions: FlowBuilderExternalActions): void;
  onDestroy?(): void;
  onError?(e?: Error): void;
}

export const useDrawFlowBuilder = ({
  rootElementId,
  routeFlowId,
  loadFlow,
  defaultScale,
  mode,
  availableFeatures,
  drawLifecycleActions: { onDestroy, onError, onLoading, onReady, onRendered },
}: DrawFlowBuilderParams) => {
  const { i18n } = useSafeTranslation();
  const [actualFlowId, setActualFlowId] = useState<string | null>(null);

  const drawFlowBuilder = async () => {
    const calculateRenderTime = timeDiff();
    const currentRouteFlowId = routeFlowId;
    let controller: FlowController | undefined;
    let pixiField: PixiField | undefined;
    try {
      const repositoryItem = await pixiFieldRepository.add(
        rootElementId,
        loadFlow,
        mode,
        availableFeatures,
      );
      controller = repositoryItem?.controller;
      pixiField = repositoryItem?.pixiField;
    } catch (error) {
      log.error({
        error,
        msg: 'Error adding new flow builder',
        data: { label: 'flow_builder_core' },
      });
      onError?.(error as any);
      return;
    }

    onReady?.();
    if (currentRouteFlowId === routeFlowId) {
      const activeController = getFlowController();
      if (!controller || activeController !== controller) return;
      const { focusedBlockId } = getFocusedBlock();

      await controller.drawFlow(defaultScale).catch((error: any) => {
        log.error({
          error,
          msg: 'Error rendering flow builder',
          data: { label: 'flow_builder_core' },
        });
        onError?.(error);
      });

      setActualFlowId(routeFlowId);

      if (focusedBlockId) {
        controller.focusOnBlock(focusedBlockId);
      }

      // TODO: remove this code after deprecate persistent menu from configure tab
      //  (see 4551 JIRA task)
      await syncWithConfigurePersistentMenu(controller);

      try {
        const activePixiField = getPixiField();

        if (!pixiField || pixiField !== activePixiField) return;

        onRendered?.(pixiField);
      } catch (error) {
        log.error({ error, data: { label: 'flow_builder_core' } });
      }

      onRenderedFBRouteCallbackService.emit();
      flowBuilderRenderTime.observe(calculateRenderTime());
    }
  };

  useEffect(() => {
    onLoading?.();
    drawFlowBuilder();

    return () => {
      onDestroy?.();
      pixiFieldRepository.remove(rootElementId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeFlowId, mode, i18n?.language, rootElementId]);

  return { actualFlowId };
};
