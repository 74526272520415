import React, { useCallback } from 'react';
import {
  BubbleEditor,
  UrlsPlaceholderBubble,
  ValidationErrorFunc,
} from '@ui/BubbleEditor';
import { Flex } from '@ui/Flex';
import { Input } from '@ui/Input';
import { sendEvent } from '@utils/Analytics';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { UrlsListHelp } from '../../../common/UrlListHelp';
import { PluginControlLabel } from '../../../../../../../Plugins/common/PluginControlLabel';
import { ControlProps } from '../types';

export interface PagesWhitelistConfig {
  pages_whitelist: string[] | null;
}

export interface PagesWhitelistControlProps<T>
  extends ControlProps<T>,
    ValidationErrorFunc {}

export function PagesWhitelistControl<T extends PagesWhitelistConfig>({
  state,
  pluginConfig,
  validationErrorFunc,
  pluginType,
}: PagesWhitelistControlProps<T>) {
  const { t } = useSafeTranslation();

  const defaultValue = (pluginConfig.pages_whitelist || []).join('\n');

  const onStringChange = useCallback(
    (line: string) => {
      state.set(({ config }) => ({
        config: {
          ...config,
          pages_whitelist: line
            .split('\n')
            .map((item) => item.trim())
            .filter(Boolean),
        },
      }));
      state.save();
    },
    [state],
  );

  const onBlur = useCallback(() => {
    state.save();
    sendEvent({
      category: 'flows',
      action: 'change pages whitelist',
      label: pluginType,
      propertyBag: {
        pages_whitelist: pluginConfig.pages_whitelist,
      },
    });
  }, [state, pluginType, pluginConfig]);

  return (
    <PluginControlLabel
      label={
        <Flex>
          {t('modernComponents.FlowBuilder.EditorPanel.PagesWhiteListControl')}
          <UrlsListHelp targetText="include" />
        </Flex>
      }
    >
      {(bind) => (
        <Input
          render={() => (
            <BubbleEditor
              onStringChange={onStringChange}
              defaultValue={defaultValue}
              validationErrorFunc={validationErrorFunc}
              onBlur={onBlur}
              placeholder={<UrlsPlaceholderBubble />}
              preventSpaces
              {...bind}
            />
          )}
        />
      )}
    </PluginControlLabel>
  );
}
