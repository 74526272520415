import React from 'react';
import { Flex } from '@ui/Flex';
import { Tooltip2 } from '@ui/Tooltip2';
import { ReactComponent as InfoIcon } from '@ui/_deprecated/Icon/icons/ic_alert_circle2.svg';
import * as css from './HelpIconWithTooltip.css';

interface HelpIconWithTooltipProps {
  content: React.ReactNode;
}

export const HelpIconWithTooltip: React.FC<HelpIconWithTooltipProps> = ({
  content,
}) => {
  return (
    <Tooltip2
      hoverable
      type="small"
      placement="right"
      boundariesElement="viewport"
      content={content}
    >
      {(ref, bind) => (
        <Flex {...bind} ref={ref} alignItems="center">
          <InfoIcon className={css.infoIcon} />
        </Flex>
      )}
    </Tooltip2>
  );
};
