import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  TestRequestMutation,
  TestRequestMutationVariables,
} from '@components/Plugins/JsonPlugin/@types/TestRequestMutation';
import {
  SEND_TO_MESSENGER_DATA_QUERY,
  TEST_REQUEST_MUTATION,
} from '@components/Plugins/JsonPlugin/JsonPluginQuery';
import { sendEvent } from '@utils/Analytics';
import { jsonPluginFragment_config as JSONPluginConfig } from '@components/Plugins/JsonPlugin/@types/jsonPluginFragment';
import { log } from 'cf-common/src/logger';
import { isValidUrl } from '@utils/UrlUtils';
import { Button } from '@ui/Button';
import { useToaster } from '@ui/Toaster';
import { PluginBlock } from '@components/Plugins/common/PluginBlock';
import { Flex } from '@ui/Flex';
import {
  JsonPluginRequestPostFormat,
  SEND_TO_MESSENGER_REF,
} from '@components/Plugins/JsonPlugin/JsonPluginConst';
import {
  SendToMessengerDataQuery,
  SendToMessengerDataQueryVariables,
} from '@components/Plugins/JsonPlugin/@types/SendToMessengerDataQuery';
import { pathOr } from 'ramda';
import { SendToMessengerPluginButton } from '@components/FBPlugins/SendToMessengerPluginButton';
import { PluginDataChildrenParams } from '@components/Plugins/common';
import { setSendToMessengerClickedToLocaleStore } from '@components/Plugins/JsonPlugin/JsonPluginUtils';
import { JsonPluginRequestTestResult } from '@components/Plugins/JsonPlugin/JsonPluginRequestTestResult/JsonPluginRequestTestResult';
import { useSendToMessengerRenderWatchDog } from '@components/Plugins/JsonPlugin';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import timer from '@utils/timer';
import * as css from '../JSONPlugin.css';

interface JsonTestRequestProps {
  botId: string;
  pluginId: string;
  pluginConfig: JSONPluginConfig;
  savePluginAsync: PluginDataChildrenParams<JSONPluginConfig>['savePluginAsync'];
  isNewPlugin: PluginDataChildrenParams<JSONPluginConfig>['isNewPlugin'];
}

export const JsonTestRequest: React.FC<JsonTestRequestProps> = ({
  botId,
  pluginId,
  pluginConfig,
  isNewPlugin,
  savePluginAsync,
}) => {
  const { t } = useSafeTranslation();
  const { addToaster } = useToaster();
  const [testRequestSend, setTestRequestSend] = useState(false);

  const {
    dontSendToMessenger,
    setDontShowSendToMessenger,
    sendToMessengerRenderWatchDog,
  } = useSendToMessengerRenderWatchDog(botId);

  const [testRequest, { data }] = useMutation<
    TestRequestMutation,
    TestRequestMutationVariables
  >(TEST_REQUEST_MUTATION, {
    variables: {
      cardId: pluginId,
    },
    fetchPolicy: 'no-cache',
  });

  const shouldNotQuerySendToMessenger =
    pluginConfig.post_format !==
      JsonPluginRequestPostFormat.full_json_profile || dontSendToMessenger;

  const { data: sendToMessengerData } = useQuery<
    SendToMessengerDataQuery,
    SendToMessengerDataQueryVariables
  >(SEND_TO_MESSENGER_DATA_QUERY, {
    variables: { botId },
    skip: shouldNotQuerySendToMessenger,
  });

  const res = data && data.testJsonPluginRequest;
  const saveDataAndSendRequest = async () => {
    sendEvent({
      category: 'flows',
      action: 'test the request',
      label: 'json',
      propertyBag: {
        url: pluginConfig.url,
        body: pluginConfig.body,
        headers: pluginConfig.headers?.map(({ __typename, ...rest }) => rest),
        method: pluginConfig.method,
        post_format: pluginConfig.post_format,
        show_error_messages: pluginConfig.show_error_messages,
      } as JSONPluginConfig,
    });
    try {
      setTestRequestSend(true);
      await savePluginAsync(pluginConfig);
      await testRequest();
    } catch (error) {
      addToaster({
        type: 'error',
        content: (
          <div style={{ width: '300px' }}>
            {t(
              'JSONPlugin-JSXText-8890-something-went-wrong-please-try-again-later',
            )}
          </div>
        ),
        timeout: 5000,
        closeButton: true,
      });

      log.error({
        error,
        msg: 'Error test request in JSON plugin',
        data: { label: 'flow_json_plugin' },
      });
    } finally {
      setTestRequestSend(false);
    }
  };
  const sendTestRequestDontReady =
    isNewPlugin ||
    testRequestSend ||
    !pluginConfig.url ||
    !isValidUrl(pluginConfig.url);

  const testTheRequestButton = (
    <Button
      className={css.testRequestButton}
      intent="primary"
      disabled={sendTestRequestDontReady}
      onClick={saveDataAndSendRequest}
    >
      {t('JSONPlugin-JSXText-2012-test-the-request')}
    </Button>
  );

  const renderSendToMessengerData = () => {
    if (!sendToMessengerData || !sendToMessengerData.bot) {
      return null;
    }
    const pageId = pathOr<string>(window.CHATFUEL_CONFIG.CF_BOT_TEST_PAGE_ID!, [
      'bot',
      'status',
      'page',
    ])(sendToMessengerData);

    const adminId = pathOr<string>('', ['me', 'id'])(sendToMessengerData);

    return (
      <SendToMessengerPluginButton
        render={() => testTheRequestButton}
        dataRef={`${SEND_TO_MESSENGER_REF}${adminId}`}
        pageId={pageId}
        appId={window.CHATFUEL_CONFIG.APP_ID!}
        onOptIn={async () => {
          setTestRequestSend(true);
          await timer(1000);
          await saveDataAndSendRequest();
          setDontShowSendToMessenger(true);
          setSendToMessengerClickedToLocaleStore(botId);
        }}
        preventAutoReInit
        onClick={() => {
          if (!sendTestRequestDontReady) {
            saveDataAndSendRequest();
          }
        }}
        onRendered={sendToMessengerRenderWatchDog.cancel}
      />
    );
  };

  return (
    <>
      <PluginBlock>
        <Flex justifyContent="space-between" alignItems="center">
          {shouldNotQuerySendToMessenger
            ? testTheRequestButton
            : renderSendToMessengerData()}
        </Flex>
      </PluginBlock>
      {res && <JsonPluginRequestTestResult result={res} />}
    </>
  );
};
