import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { PluginControlLabel } from '../../../../../Plugins/common/PluginControlLabel';
import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';
import { Icon } from '@ui/Icon';
import { ControlProps } from '../CustomerChatEntryPoint/components/types';
import * as css from './EntryPointWhatsappPopup.css';
import { UploadFile } from '@utils/UploadFile';
import { Platform } from '@globals';
import { FileAttachmentType } from '@utils/UploadService/types';
import { cropImage } from '../../../../components';
import { AspectRatioValue } from '../../../../../Plugins/ImagePlugin/ratio';
import { uploadFile } from '../../../../api/network';
import { getFlowPlatform } from '../../../../utils/getFlowPlatform';
import { showErrorToaster } from '../../../../../../services/MessageService';
import { Button } from '@ui/Button';
import { sendEvent } from 'cf-common/src/analytics';

export interface ImageUploadControlProps<T> extends ControlProps<T> {
  label: string;
  testid: string;
  field: string;
  value: string | null;
  description: string;
}

export const ImageUploadControl = <T,>({
  label,
  testid,
  value: initialValue,
  state,
  field,
  description,
  pluginType,
}: ImageUploadControlProps<T>) => {
  const [tempFile, setTempFile] = useState<string | null>(initialValue);

  useEffect(() => {
    setTempFile(initialValue);
  }, [initialValue]);

  const handleUpload = (file: {
    url?: string;
    blob?: Blob;
    name: string;
    size: number;
    type: string;
  }) => {
    if (!file.blob) {
      return;
    }

    cropImage({
      image: new File([file.blob], file.name),
      ratio: AspectRatioValue.horizontal,
      onDismiss: () => {
        // do nothing
      },
      onSave: (blob) => {
        sendEvent({
          category: 'flows',
          action: 'image uploaded',
          label: pluginType,
        });

        const fileToSave = new File([blob], file.name);
        const fd = new FormData();
        fd.append('file', fileToSave, file.name);

        const reader = new FileReader();
        reader.readAsDataURL(blob);

        reader.onloadend = () => {
          setTempFile(reader.result as string);
          uploadFile(
            fd,
            (url) => {
              state.setAndSave(({ config }) => ({
                config: {
                  ...config,
                  [field]: url,
                },
              }));
            },
            () => {
              showErrorToaster(
                'modernComponents.FlowBuilder.EditorPanel.WhatsappPopup.error',
              );
            },
            {
              platform: getFlowPlatform()!,
              filetype: FileAttachmentType.image,
              filename: state.data.id,
            },
          );
        };
      },
    });
  };

  const handleDelete = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setTempFile(null);
    state.setAndSave(({ config }) => ({
      config: {
        ...config,
        [field]: null,
      },
    }));
  };

  return (
    <Flex gapFactor={2} flexDirection="column">
      <PluginControlLabel
        light
        label={
          <Type weight="semibold" size="12px" color="cobaltTertiary">
            {label}
          </Type>
        }
        data-testid={testid}
      >
        {(bind) => (
          <UploadFile
            {...bind}
            className={css.imgContainer}
            onFileProcessed={handleUpload}
            platform={Platform.whatsapp}
            attachmentTypeInit={FileAttachmentType.image}
          >
            {({ chooseFile, dropState }) => (
              <div
                className={cn(css.imageUpload, {
                  [css.imageUploadDrop]: dropState !== 'none',
                })}
                onKeyDown={() => chooseFile(FileAttachmentType.image)}
                onClick={() => chooseFile(FileAttachmentType.image)}
              >
                {tempFile ? (
                  <>
                    <img
                      className={css.imgPreview}
                      src={tempFile}
                      alt="Preview"
                    />
                    <Button
                      fitToText
                      className={css.deleteBtn}
                      data-testid={`${testid}-delete`}
                      intent="secondary"
                      onClick={handleDelete}
                    >
                      <Icon icon="delete" color="black" size="24px" />
                    </Button>
                  </>
                ) : (
                  <Icon icon="addImage" color="accent1Normal" size="24px" />
                )}
              </div>
            )}
          </UploadFile>
        )}
      </PluginControlLabel>

      <Type weight="medium" size="12px" color="cobaltTertiary">
        {description}
      </Type>
    </Flex>
  );
};
