import { useCallback } from 'react';
import { useMutation } from 'react-apollo';
import { FLOW_ITEM_QUERY } from '@utils/Data/Flow';
import {
  prepareFlowBlock,
  EventLogData,
} from '@utils/Data/Flow/Aggregated/helpers';
import { CLONE_FLOW_BLOCKS_MUTATION } from './queries';
import {
  CloneFlowBlocksMutation,
  CloneFlowBlocksMutationVariables,
} from './@types/CloneFlowBlocksMutation';
import client from '../../../../common/services/ApolloService';

export const cloneFlowBlocks = async (
  fromFlowId: string,
  toFlowId: string,
  blocks: CloneFlowBlocksMutationVariables['blocks'],
  eventLogData: EventLogData,
) => {
  const { data, errors } = await client.mutate<
    CloneFlowBlocksMutation,
    CloneFlowBlocksMutationVariables
  >({
    mutation: CLONE_FLOW_BLOCKS_MUTATION,
    variables: {
      fromFlowId,
      toFlowId,
      blocks,
    },
    refetchQueries: [
      {
        query: FLOW_ITEM_QUERY,
        variables: { botId: eventLogData.botId, flowId: toFlowId },
      },
    ],
  });

  return {
    blocks:
      data?.cloneFlowBlocks.map((block) =>
        prepareFlowBlock(block, eventLogData),
      ) ?? [],
    errors,
  };
};

export const useCloneFlowBlocks = () => {
  const [mutate, { loading }] = useMutation<
    CloneFlowBlocksMutation,
    CloneFlowBlocksMutationVariables
  >(CLONE_FLOW_BLOCKS_MUTATION);

  const cloneFlowBlocks = useCallback(
    async (
      fromFlowId: string,
      toFlowId: string,
      blocks: CloneFlowBlocksMutationVariables['blocks'],
      eventLogData: EventLogData,
    ) => {
      const { data, errors } = await mutate({
        variables: {
          fromFlowId,
          toFlowId,
          blocks,
        },
        refetchQueries: [
          {
            query: FLOW_ITEM_QUERY,
            variables: { botId: eventLogData.botId, flowId: toFlowId },
          },
        ],
      });
      return {
        blocks:
          data?.cloneFlowBlocks.map((block) =>
            prepareFlowBlock(block, eventLogData),
          ) ?? [],
        errors,
      };
    },
    [mutate],
  );

  return { cloneFlowBlocks, loading };
};
