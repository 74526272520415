import React, { forwardRef } from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import cn from 'classnames';
import { SimpleComboboxProps } from '@ui/SimpleCombobox';
import { Input } from '@ui/Input';
import { ButtonUnstyled } from '@ui/Button';
import { Icon } from '@ui/Icon';
import { Tooltip2 } from '@ui/Tooltip2';
import { Anchor } from '@ui/Links';
import { Spacer } from '@ui/Spacer';
import { getHandleConnectClick } from '../../../../views/plugins/NTimeNotificationPluginView/utils/getHandleConnectClick';
import css from './NTimeNotificationPlugin.css';

export const PluginInput = forwardRef(
  (
    {
      blockId,
      botId,
      pageId,
      getInputProps,
      openMenu,
      clearSelection,
      reset,
      selectedItem,
      selectItem,
    }: Parameters<SimpleComboboxProps<unknown>['renderInput']>[0] & {
      pageId?: string;
      botId: string;
      blockId: string;
    },
    inputRef,
  ) => {
    const { t } = useSafeTranslation();
    const inputProps = getInputProps({
      placeholder: t('NTimeNotificationPlugin.Card.topicPlaceholder'),
      onFocus: openMenu,
      onChange: () => clearSelection(),
      ref: inputRef,
    });

    function getRenderIconEnd() {
      if (!pageId)
        return (
          <Tooltip2
            /**
             * The sidebar closes on mousedown and click won't fire
             */
            onMouseDown={getHandleConnectClick(botId, blockId)}
            className={css.facebookConnectTooltip}
            boundariesElement="viewport"
            hoverable
            content={
              <>
                <div>
                  {t('NTimeNotificationPlugin.facebookCoonectTooltip.title')}
                </div>
                <Spacer factor={2} />
                <Anchor intent="tooltip">
                  {t('NTimeNotificationPlugin.facebookCoonectTooltip.link')}
                </Anchor>
              </>
            }
          >
            {(ref, bind) => (
              <Icon ref={ref} {...bind} color="red" icon="warning" />
            )}
          </Tooltip2>
        );
      return inputProps.value ? (
        <ButtonUnstyled
          onClick={() => {
            reset();
            selectItem(null);
          }}
        >
          <Icon icon="close" />
        </ButtonUnstyled>
      ) : null;
    }

    return (
      <>
        <Input
          {...inputProps}
          maxLength="65"
          className={cn(
            selectedItem && css.inputTransparent,
            !pageId && css.inputDisabled,
          )}
          renderIconEnd={getRenderIconEnd}
          onMouseDown={(e) => {
            /**
             * disabled onFocus
             */
            if (!pageId || selectedItem) {
              e.stopPropagation();
              e.preventDefault();
            }
          }}
        />
        {selectedItem && (
          <div
            data-testid="ntime-notification__topic-item"
            className={css.inputItem}
          >
            {selectedItem.title}
          </div>
        )}
      </>
    );
  },
);
