import React from 'react';
import { getRequestedUserInfoItems } from '../../consts';
import { Flex } from '@ui/Flex';
import { CheckBox } from '@ui/CheckBox';
import { Type } from '@ui/Type';
import * as css from './RequestedUserInfoSelector.css';
import { RequestedUserInfo } from '@globals';
import { Spacer } from '@ui/Spacer';

interface RequestedUserInfoSelectorProps {
  requestedUserInfo: RequestedUserInfo[];
  onChange: (requestedUserInfo: RequestedUserInfo[]) => void;
}

const LOCKED_FIELDS = [RequestedUserInfo.contact_email];

export const RequestedUserInfoSelector: React.FC<RequestedUserInfoSelectorProps> =
  ({ requestedUserInfo, onChange }) => {
    return (
      <Flex gapFactor={2} flexWrap data-testid="request-user-info__user-data">
        {getRequestedUserInfoItems().map(({ id, title }) => {
          const checked = requestedUserInfo.includes(id);
          const locked = LOCKED_FIELDS.includes(id);
          return (
            <label key={id}>
              <Flex gapFactor={2} alignItems="center" className={css.item}>
                <CheckBox
                  disabled={locked}
                  checked={checked}
                  onChange={() => {
                    if (!checked) {
                      onChange([...requestedUserInfo, id]);
                    } else {
                      onChange(requestedUserInfo.filter((item) => item !== id));
                    }
                  }}
                />
                <Type size="15px">{title}</Type>
                <Spacer factor={1} horizontalFactor={1} />
              </Flex>
            </label>
          );
        })}
      </Flex>
    );
  };
