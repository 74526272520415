import { getHtmlElementSize } from '@utils/DOM/getHtmlElementSize';
import { htmlToXml } from '@utils/DOM/htmlToXml';
import { log } from 'cf-common/src/logger';
import { SCALE_MODES, Texture } from 'pixi.js-legacy';
import { getFlowController } from '../../../PixiFieldRepository';
import { PropFunc } from '../../../types';
import { resByFunc } from '../../../views/utils';
import { Alignment, CornerRadiusAdv } from '../Layouts';
import { RectProps } from './types';

interface GetCornersRadiusObjectParams {
  cornerRadius?: number;
  corners?: PropFunc<CornerRadiusAdv>;
}

export function getCornersRadiusObject({
  cornerRadius,
  corners,
}: GetCornersRadiusObjectParams) {
  const r = cornerRadius || 0;
  const calculatedCorners = resByFunc(corners);
  return calculatedCorners
    ? {
        topLeft: 0,
        topRight: 0,
        bottomLeft: 0,
        bottomRight: 0,
        ...calculatedCorners,
      }
    : { topLeft: r, topRight: r, bottomLeft: r, bottomRight: r };
}

interface RenderHtmlToTextureParams {
  html: string;
  width?: number;
  scale: number;
  scaleMode: SCALE_MODES;
  callback(texture: Texture): void;
}

export function renderHtmlToTexture({
  html,
  width,
  scale,
  scaleMode,
  callback,
}: RenderHtmlToTextureParams) {
  const { currentHeight, currentWidth } = getHtmlElementSize(html);
  const scaledHeight = Math.ceil(currentHeight * scale);
  const scaledWidth = Math.ceil((width || currentWidth) * scale);
  const svg = `<svg xmlns="http://www.w3.org/2000/svg" width="${scaledWidth}" height="${scaledHeight}"><foreignObject width="1" height="1" style="overflow: visible;">${htmlToXml(
    `<div style="transform: scale(${scale},${scale});transform-origin: top left;">${html}</div>`,
  )}</foreignObject></svg>`;
  const svgDataString = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(
    svg,
  )}`;
  getFlowController()
    ?.imageLoader.load(svgDataString, {
      resolution: scale,
      height: currentHeight,
      width: currentWidth,
      scaleMode,
    })
    .then(({ texture }: { texture: Texture }) => callback(texture))
    .catch(() => {
      log.error({
        msg: 'load texture error',
        data: { label: 'flow_builder_core' },
      });
    });
  return { x: currentWidth, y: currentHeight };
}

interface RenderGradientTextureParams {
  width: number;
  height: number;
  backgroundStyle: string;
  props: RectProps;
  callback(texture: Texture): void;
}

export function renderRectBackgroundTexture({
  width,
  height,
  backgroundStyle,
  props,
  callback,
}: RenderGradientTextureParams) {
  const { topLeft, topRight, bottomLeft, bottomRight } = getCornersRadiusObject(
    {
      corners: props.corners,
      cornerRadius: props.cornerRadius,
    },
  );
  const borderRadiusStyle = `${topLeft}px ${topRight}px ${bottomRight}px ${bottomLeft}px`;
  const borderStyle = `${props.strokeWidth}px solid ${props.stroke} ${
    props.strokeAlignment === Alignment.inner ? 'inset' : ''
  }`;

  const html = `<div style="background:${backgroundStyle};width:${width}px;height:${height}px;border-radius:${borderRadiusStyle};border:${borderStyle};opacity:${props.opacity}"></div>`;
  return renderHtmlToTexture({
    html,
    width,
    scale: 1,
    scaleMode: SCALE_MODES.NEAREST,
    callback,
  });
}
