import { Type } from '@ui/Type';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import React, { useState } from 'react';
import { Flex } from '@ui/Flex';
import { Input } from '@ui/Input';
import { ButtonUnstyled } from '@ui/Button';
import * as css from './PriceListItem.css';
import { cardButtonFragment_payment_summary_price_list as Item } from '../../../../../../../../../Plugins/common/@types/cardButtonFragment';
import { TextEllipsis } from '@ui/TextEllipsis';
import { IconButton } from '@ui/IconButton';
import { Spacer } from '@ui/Spacer';
import { Icon } from '@ui/Icon';
import { AmountInput } from './components/AmountInput';

interface PriceListItemProps {
  item: Item;
  currency: string;
  autoFocus: boolean;
  showCurrencySelector: boolean;
  onChange: (item: Item) => void;
  onChangeCurrency: (currency: string) => void;
  onSaveRequest: () => void;
  onRemoveRequest?: (id: string) => void;
}

export const PriceListItem: React.FC<PriceListItemProps> = ({
  item,
  currency,
  autoFocus,
  onChange,
  onSaveRequest,
  onRemoveRequest,
  onChangeCurrency,
  showCurrencySelector,
}) => {
  const { t } = useSafeTranslation();
  const [editMode, setEditMode] = useState<boolean>(autoFocus);

  return (
    <>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        data-testid="price-list-item__item"
      >
        {editMode ? (
          <Input
            autoFocus
            value={item.label || ''}
            onChange={({ currentTarget: { value } }) => {
              onChange({
                ...item,
                label: value,
              });
            }}
            onBlur={() => {
              setEditMode(false);
              onSaveRequest();
            }}
            placeholder={t('InBotPayments.Panel.PriceList.titlePlaceholder2')}
            className={css.input}
          />
        ) : (
          <ButtonUnstyled
            data-testid="price-list-item__price-title-input"
            onClick={() => {
              setEditMode(true);
            }}
            className={css.label}
          >
            <Flex alignItems="center">
              <TextEllipsis>
                <Type
                  size="15px"
                  color={item.label ? 'black' : 'accent4Normal'}
                >
                  {item.label ||
                    t('InBotPayments.Panel.PriceList.titlePlaceholder')}
                </Type>
              </TextEllipsis>
              {!item.label && <Icon icon="edit" color="accent4Normal" />}
            </Flex>
          </ButtonUnstyled>
        )}
        {onRemoveRequest && (
          <IconButton
            icon="delete"
            className={css.removeButton}
            data-testid="price-list-item__price-item-remove-button"
            onMouseDown={(event) => {
              event.preventDefault();
              onRemoveRequest(item.id);
            }}
          />
        )}
      </Flex>
      <Spacer factor={3} />
      <AmountInput
        showCurrencySelector={showCurrencySelector}
        amount={item.amount}
        currency={currency}
        onChangeAmount={(amount) => {
          onChange({
            ...item,
            amount,
          });
        }}
        onChangeCurrency={onChangeCurrency}
        onSaveRequest={onSaveRequest}
      />
      <Spacer factor={3} />
    </>
  );
};
