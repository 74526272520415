import React, { useCallback } from 'react';
import debounce from 'lodash-es/debounce';
import { sendEvent } from '@utils/Analytics';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { PluginControlLabel } from '../../../../../../../Plugins/common/PluginControlLabel';
import { DomainsWhitelisting } from '../../../../../../../DomainsWhitelisting';
import { ControlProps } from '../types';

const sendEventDebounced = debounce(sendEvent, 300);

export interface DomainsConfig {
  domains: string[] | null;
}

export function DomainsControl<T extends DomainsConfig>({
  pluginConfig,
  pluginType,
  state,
}: ControlProps<T>) {
  const { t } = useSafeTranslation();

  const value = pluginConfig.domains?.[0] || '';

  const onChange = useCallback(
    (domain) => {
      sendEventDebounced({
        category: 'flows',
        action: 'change domain',
        label: pluginType,
        propertyBag: {
          domain: [domain],
        },
      });
      state.set(({ config }) => ({
        config: { ...config, domains: [domain] },
      }));
    },
    [state, pluginType],
  );

  const onSelect = useCallback(
    (domain: string) => {
      state.set(({ config }) => ({
        config: { ...config, domains: [domain] },
      }));
      state.save();
    },
    [state],
  );

  const onBlur = useCallback(() => {
    state.save();
  }, [state]);

  return (
    <PluginControlLabel
      label={t('CustomerChatEntryPointContent-string-1535-website-domain')}
    >
      {(bind) => (
        <DomainsWhitelisting
          value={value}
          onChange={onChange}
          onSelect={onSelect}
          onBlur={onBlur}
          {...bind}
        />
      )}
    </PluginControlLabel>
  );
}
