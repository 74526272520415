/* eslint-disable no-param-reassign */
import React from 'react';
import { AttributeInputBase } from '@components/AttributeInput';
import { itemToString } from '@ui/TextWithAttributesEditor/Attribute';
import { Platform } from '@globals';
import {
  OverlayComponentEvent,
  overlayEventEmitter,
  OverlayProps,
  TextFieldOverlayOptions,
} from '..';
import { useCurrentBotId } from '../../../../utils/Routing';
import { AttributesData } from '../../../../utils/AttributesUtils/AttributesData';
import { getFlowPlatform } from '../../utils/getFlowPlatform';

export const InputAttribute: React.FC<OverlayProps> = ({
  options,
  value,
  onChange,
  onKeyDown,
}) => {
  const { maxLength, style, customAttributesOnly } =
    options as TextFieldOverlayOptions;
  const botId = useCurrentBotId();
  const platform = getFlowPlatform() ?? Platform.facebook;

  if (!botId) {
    return null;
  }

  return (
    <AttributesData botId={botId} platform={platform}>
      {({ attributes }) => (
        <AttributeInputBase
          mode="overlay"
          value={value}
          platform={platform}
          attributes={attributes}
          maxLength={maxLength}
          customAttributesOnly={customAttributesOnly}
          style={style}
          onSelect={(item) => {
            onChange(itemToString(item));
            overlayEventEmitter.emit(OverlayComponentEvent.keydown, {
              key: 'Enter',
            });
          }}
          onKeyDown={onKeyDown}
          onChange={onChange}
        />
      )}
    </AttributesData>
  );
};
