import React from 'react';
import cn from 'classnames';
import {
  GenericFilter,
  Divider,
  ParameterFilterValueOperatorSelector,
} from './GenericFilter';
import { UserFilterProps } from '../types';
import { SimpleCombobox } from '@ui/SimpleCombobox';
import { Input } from '../../../../../../../modern-ui/Input';
import { useSegments } from '../../../../../../../common/services/SegmentService';
import * as css from './Filter.css';

export const SegmentFilter: React.FC<UserFilterProps> = (props) => {
  const { botId, parameter, onParameterUpdate, parametersOperatorDisabled } =
    props;
  const { segments, loading } = useSegments(botId);

  return (
    <GenericFilter {...props}>
      <ParameterFilterValueOperatorSelector
        className={css.filterValueOperatorSelector}
        parameter={parameter}
        disabled={parametersOperatorDisabled}
        onParameterUpdate={onParameterUpdate}
      />
      <Divider />
      <SimpleCombobox<{
        id: string;
        name: string;
        removed: boolean;
        additional_ids: string[];
        segmentation: any;
      }>
        items={segments}
        downshiftContainerClassName={css.dropdown}
        menuboxStyle={{ maxWidth: '200px' }}
        itemToString={(item) => item?.name ?? ''}
        selectedItem={
          segments.find((s: { id: string }) => s.id === parameter.values[0]) ||
          null
        }
        onChange={(item) => {
          onParameterUpdate({
            ...parameter,
            values: item?.id ? [item?.id] : [],
          });
        }}
        renderInput={({ getInputProps, openMenu, isOpen }) => (
          <Input
            className={cn(css.selectorInput, css.thirdColumnLargeSelector)}
            containerClassName={css.selectorInputContainer}
            {...getInputProps({
              placeholder: 'segment',
              name: 'value',
              onFocus: openMenu,
            })}
            onClick={() => !isOpen && openMenu()}
            autoComplete="off"
            disabled={loading}
          />
        )}
      />
    </GenericFilter>
  );
};
