import { ButtonView } from '../../../views/button_view';
import { LineView } from '../../../views/components/Line/LineView';
import { PropFunc } from '../../../types';
import {
  Circle,
  DownArrow,
  Rect,
  Shape,
  HTMLText,
  FlowArrow,
  TextureShape,
  Image,
} from '../Shapes';
import { Layout } from './Layout';
import { LineStartView } from '../../../views/components/Line/LineStartView';
import { HEXColors } from '@ui/_common/colors';

export enum Alignment {
  inner = 0,
  middle = 0.5,
  outter = 1,
}

export interface UpdateParams {
  shouldRunOnBeforeRender?: boolean;
  shouldRenderParentLayout?: boolean;
  disabledRenderAfterUpdate?: boolean;
}

export type OpacityView =
  | Shape
  | Rect
  | Circle
  | DownArrow
  | HTMLText
  | FlowArrow
  | TextureShape
  | LineView
  | Image;

export type View = Layout | ButtonView | OpacityView;

export interface MarginType {
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
}

export interface CornerRadiusAdv {
  topLeft?: number;
  topRight?: number;
  bottomLeft?: number;
  bottomRight?: number;
}

interface BackgroundPropsCore {
  cornerRadius?: number;
  corners?: PropFunc<CornerRadiusAdv>;
  fill?: PropFunc<HEXColors>;
  backgroundStyle?: PropFunc<string>;
  opacity?: PropFunc<number>;

  stroke?: PropFunc<HEXColors>;
  strokeWidth?: PropFunc<number>;
  strokeOpacity?: PropFunc<number>;
  strokeAlignment?: PropFunc<Alignment>;
}

export interface BackgroundProps extends BackgroundPropsCore {
  onhover?: BackgroundPropsCore;
}

export type Cursor =
  | 'grab'
  | 'pointer'
  | 'text'
  | 'default'
  | 'wait'
  | 'not-allowed';

export interface CursorProps {
  in?: Cursor;
  out?: Cursor;
}

export type VerticalAlignType = 'top' | 'center' | 'bottom';
export type AlignType = 'start' | 'center' | 'end';

export interface MarginProps {
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
}

/**
 * @param margin {PropFunc<MarginProps>}
 * @param align {AlignType}
 * @param verticalAlign {VerticalAlignType} use only for MainLayout
 * @param gone {PropFunc<boolean | null>}
 */
export interface AddToLayoutProps {
  margin?: PropFunc<MarginProps>;
  align?: AlignType;
  verticalAlign?: VerticalAlignType; // use only for MainLayout
  gone?: PropFunc<boolean | null | undefined>;

  bottom?: number;
  cornerRadius?: number;
  width?: number;
  height?: number;
  fill?: string;
  visible?: boolean;
}

export interface DraggingContext {
  startX: number;
  startY: number;
  started: boolean;
  tempView: View;
  idx: number;
  startIdx: number;
  height: number;
  width: number;
}

export interface LayoutProps {
  name?: string;
  backgroundName?: string;
  x?: number;
  y?: number;
  width?: number;
  height?: number;
  minHeight?: number;
  title?: string;

  fill?: HEXColors;
  opacity?: number;
  margin?: PropFunc<MarginType>;
  verticalAlign?: VerticalAlignType;
  itemsOffset?: PropFunc<number>;
  draggableItems?: boolean;
  clip?: boolean;
  draggable?: boolean;
  zIndexFunc?: (zIndex: number) => number;

  cursor?: PropFunc<CursorProps | undefined>;
  background?: BackgroundProps;
  visible?: boolean;

  showShadow?: boolean;
  gone?: PropFunc<boolean | null>;
  isValid?: PropFunc<boolean>;
  onElementPositionChanged?: (
    view: View,
    index: number,
    startIndex: number,
  ) => void;
}

export interface ViewProp<T = Layout> {
  view: T;
  props?: AddToLayoutProps;
  index?: number;
}

export interface LinkedLayout extends Layout {
  lineStartView: LineStartView;
}
