import { CSSProperties } from 'react';
import { INTERCOM_Z_INDEX } from '../../../../QuickAccessToolbar/consts';

export const MENU_MIN_WIDTH = '290px';

export const GROUP_STYLES: CSSProperties = {
  borderTop: '1px solid var(--greyLight20)',
  marginTop: '10px',
  paddingTop: '10px',
};

export const MENU_Z_INDEX = INTERCOM_Z_INDEX + 100;
