import i18next from 'i18next';
import { Resource, Texture } from 'pixi.js-legacy';
import { Platform } from '@globals';
import {
  CreateLineMenuParams,
  IMenuItem,
  MenuItemType,
  Tooltip,
} from '@components/FlowBuilder/views/Menu/types';
import { BLOCK_SUBTYPES } from '@components/FlowBuilder/consts';
import { PlatformHolder } from '@components/FlowBuilder/views/Menu/PlatformHolder';
import { createStatefulPlugin } from '@components/FlowBuilder/views/createStatefulPlugin';
import { Node } from '@components/FlowBuilder/Node';
import { MainLayout } from '@components/FlowBuilder/components/Elements/Layouts';
import { FlowBuilderPlugin } from '@components/FlowBuilder/FlowBuilderPlugin';
import { Card } from '@components/Plugins/common';
import { PluginType } from '@components/FlowBuilder/types';
import pluginTypingSvg from './assets/typing.svg';
import { typingPluginFragment_config } from './@types/typingPluginFragment';
import { ReactComponent as PluginTypingIcon } from './assets/menu_icon.svg';
import { TypingPluginView } from './TypingPluginView';
import {
  PLUGIN_DEFAULT_CONFIG,
  PLUGIN_INPUT_FIELD,
  TypingPluginConfig,
} from './TypingPluginConst';
import { documentNodes } from './TypingPluginQuery';
import { createTexture } from '../../assets/textures';
import { SaveTypingPluginMutationCommonVariables } from './@types/SaveTypingPluginMutationCommon';

export class TypingPlugin extends FlowBuilderPlugin<
  TypingPluginConfig,
  SaveTypingPluginMutationCommonVariables
> {
  public id = PluginType.typing;
  public loadingPreviewHeight: number = 82;
  public isApolloBased: boolean = true;
  public inputField: string = PLUGIN_INPUT_FIELD;

  public tooltip(): Tooltip {
    return {
      text: i18next.t(
        'menu_view-string-1691-typing-animation-creates-a-feeling-of-personal-interaction-and-gives-users-time-to-read-the-previous-message',
      ),
    };
  }

  public getIconTexture(): Texture<Resource> {
    return createTexture(pluginTypingSvg);
  }

  public getMenuItem(_: CreateLineMenuParams): IMenuItem | undefined {
    return {
      subtype: BLOCK_SUBTYPES.send_message,
      id: this.id,
      img: PluginTypingIcon,
      platform: new PlatformHolder(Platform.facebook),
      title: i18next.t('menu_view-string--177-typing'),
      type: MenuItemType.plugin,
      tooltip: this.tooltip(),
    };
  }

  public getPluginDisplayName(): string {
    return i18next.t('common.pluginNames.typing');
  }

  public getDefaultConfig(): typingPluginFragment_config {
    return PLUGIN_DEFAULT_CONFIG;
  }

  public getView(card: Card<any>, node: Node): MainLayout {
    return createStatefulPlugin(TypingPluginView, card, node);
  }

  constructor() {
    super(documentNodes);
  }
}
