import React from 'react';
import cn from 'classnames';
import { Flex } from '@ui/Flex';
import { Button } from '@ui/Button';
import css from './ButtonGroup.css';
import { OnboardingEmitter } from '@components/Onboarding/OnboardingTour/utils/onboardingEmitter';
import { OnboardingTourEventType } from '@globals';

interface ButtonGroupProps<Id extends string = string> {
  selectedItemId: Id;
  onSelect(id: Id): void;
  items: Array<{ title: string; id: Id; buttonId?: string }>;
}

export const ButtonGroup = <Id extends string = string>({
  selectedItemId,
  onSelect,
  items,
}: ButtonGroupProps<Id>) => (
  <Flex className={css.container}>
    {items.map(({ title, id, buttonId }) => (
      <Button
        key={id}
        size="s"
        intent={selectedItemId === id ? 'primary' : 'text'}
        textClassName={cn(selectedItemId !== id && css.itemText)}
        data-testid={`button-group_${buttonId}`}
        onClick={() => {
          onSelect(id);
          if (buttonId)
            OnboardingEmitter.emit(OnboardingTourEventType.click, {
              element: buttonId,
              value: null,
            });
        }}
        id={buttonId}
      >
        {title}
      </Button>
    ))}
  </Flex>
);
