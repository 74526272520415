import { cond, always as aw, T } from 'ramda';
import { useCurrentFlowId } from '@utils/Routing';
import { useGlobalFollowUpsData } from './useGlobalFollowUpsData';
import { useFlowBlocks } from '@utils/Data/Flow/useFlowBlocks';
import {
  FlowBlocksQuery_bot_flowBlocks,
  FlowBlocksQuery_bot_flowBlocks_cards_QuickReplyPlugin,
} from '@utils/Data/Flow/@types/FlowBlocksQuery';
import Maybe from 'graphql/tsutils/Maybe';
import { FollowUpsType } from '../consts';
import { useMemo } from 'react';

const isFoundLocalFlows = (
  flowBlocks: Maybe<FlowBlocksQuery_bot_flowBlocks[]>,
) =>
  Boolean(
    flowBlocks?.some(({ cards }) =>
      cards?.some((card) =>
        Boolean(
          (card as FlowBlocksQuery_bot_flowBlocks_cards_QuickReplyPlugin).config
            ?.follow_ups?.length,
        ),
      ),
    ),
  );

export const useFollowUpsTypeForCurrentFlow = () => {
  const flowId = useCurrentFlowId()!;
  const { flowBlocks, loading: flowLoading } = useFlowBlocks(flowId);
  const { followUps, loading: globalFollowUps } =
    useGlobalFollowUpsData(flowId);

  const loading = flowLoading || globalFollowUps;
  const isFoundGlobalFollowUps = Boolean(followUps?.length);

  const followUpsType = useMemo<Maybe<FollowUpsType>>(
    cond([
      [aw(loading), aw(undefined)],
      [aw(isFoundGlobalFollowUps), aw(FollowUpsType.global)],
      [aw(isFoundLocalFlows(flowBlocks)), aw(FollowUpsType.local)],
      [T, aw(null)],
    ]),
    [flowBlocks, isFoundGlobalFollowUps, loading],
  );

  return {
    loading,
    followUpsType,
  };
};
