import { openAiPluginFragment_config_intents } from '@components/Plugins/OpenAiPlugin/@types/openAiPluginFragment';

type Payload = {
  intents: Array<openAiPluginFragment_config_intents>;
  prompt: string;
};

export const getPromptPreview = ({ intents, prompt }: Payload) => {
  const intentString = intents
    ?.map(({ key, value }) => (key || value) && `${key}: ${value}`)
    .filter(Boolean)
    .join('\n');

  return [intentString, prompt].filter(Boolean).join('\n\n');
};
