import React from 'react';

import { Modal } from '@services/index';

import {
  ConnectInstagramAccountDialog,
  ConnectInstagramAccountDialogProps,
} from '@components/dialogs/ConnectInstagramAccountDialog';
import {
  FlowSelectorDialogContainer,
  FlowSelectorDialogContainerProps,
} from '@components/FlowSelectorDialog/containers/FlowSelectorDialogContainer';
import { DialogLocalizationContextProvider } from '@components/FlowSelectorDialog/LocalizationContext';

import {
  ChooseFacebookCommentAutoreplyPostsDialog,
  ChooseFacebookCommentAutoreplyPostsDialogProps,
} from '../../../dialogs/ChooseFacebookCommentAutoreplyPostsDialog';
import {
  ChooseInstagramPostDialog,
  ChooseInstagramPostDialogProps,
} from '../../../dialogs/ChooseInstagramPostDialog';

import { PermissionModal } from '../../views/entry-points/common/components/ChooseAdsPopup/components';
import { PermissionModalProps } from '../../views/entry-points/common/components/ChooseAdsPopup/components/PermissionModal';
import {
  ChooseAdsPopup,
  ChooseAdsPopupProps,
} from '../../views/entry-points/common/components/ChooseAdsPopup';
import {
  ImageResizeDialog,
  ImageResizeDialogProps,
} from '../../../Plugins/ImagePlugin';
import {
  RemoveEntityDialog,
  RemoveEntityDialogProps,
} from '../../../RemoveEntityDialog';
import {
  MessageTagModal,
  MessageTagModalProps,
} from '../../../MessageTagModal';
import {
  ChooseFlowBlockDialog,
  ChooseFlowBlockDialogProps,
} from '../../../ChooseFlowBlockDialog';
import { GesturesModal, GesturesModalProps } from '../Gestures/GesturesModal';
import {
  ChooseAdPostsDialog,
  ChooseAdPostsDialogProps,
} from '@components/dialogs/ChooseAdPostsDialog';

export const cropImage = (props: ImageResizeDialogProps) => {
  Modal.show(({ close }) => (
    <ImageResizeDialog
      {...props}
      onDismiss={close}
      onSave={(...args) => {
        close();
        props.onSave(...args);
      }}
    />
  ));
};

export const removeEntity = (props: RemoveEntityDialogProps) =>
  Modal.show(({ resolve, close }) => (
    <RemoveEntityDialog
      {...props}
      onDismiss={close}
      onSubmit={(...args) => {
        resolve(...args);
        return props.onSubmit(...args);
      }}
    />
  ));

export const resolveConflict = (props: RemoveEntityDialogProps) => {
  Modal.show(({ close }) => (
    <RemoveEntityDialog
      {...props}
      onDismiss={() => {
        close();
        return props.onDismiss?.();
      }}
      onSubmit={(...args) => {
        close();
        return props.onSubmit(...args);
      }}
    />
  ));
};

export const showUpdateMessageTagDialog = (props: MessageTagModalProps) => {
  Modal.show(({ close }) => (
    <MessageTagModal
      {...props}
      onDismiss={() => {
        props.onDismiss?.();
        close();
      }}
      onChange={(...args) => {
        close();
        return props.onChange(...args);
      }}
    />
  ));
};

export const showChooseFacebookPostsDialog = (
  props: ChooseFacebookCommentAutoreplyPostsDialogProps,
) => {
  Modal.show(({ close }) => (
    <ChooseFacebookCommentAutoreplyPostsDialog
      {...props}
      onDismiss={() => {
        props.onDismiss?.();
        close();
      }}
      onSubmit={(...args) => {
        close();
        return props.onSubmit(...args);
      }}
      onPostRemove={props.onPostRemove}
    />
  ));
};

export const showChooseAdPostsDialog = (props: ChooseAdPostsDialogProps) => {
  Modal.show(({ close }) => (
    <ChooseAdPostsDialog
      {...props}
      onDismiss={() => {
        props.onDismiss?.();
        close();
      }}
      onSubmit={(...args) => {
        close();
        return props.onSubmit(...args);
      }}
    />
  ));
};

export const showInstagramChoosePostDialog = (
  props: ChooseInstagramPostDialogProps,
) => {
  Modal.show(({ close }) => (
    <ChooseInstagramPostDialog
      {...props}
      onDismiss={() => {
        props.onDismiss?.();
        close();
      }}
      onSubmit={(...args) => {
        close();
        return props.onSubmit(...args);
      }}
    />
  ));
};

export const showChooseAdsPopup = (props: ChooseAdsPopupProps) => {
  Modal.show(({ close }) => (
    <ChooseAdsPopup
      {...props}
      onDismiss={() => {
        props.onDismiss?.();
        close();
      }}
      onSubmit={(...args) => {
        close();
        return props.onSubmit(...args);
      }}
    />
  ));
};

export const showPermissionPopup = (props: PermissionModalProps) => {
  Modal.show(({ close }) => (
    <PermissionModal
      pluginId={props.pluginId}
      onDismiss={() => {
        props.onDismiss?.();
        close();
      }}
    />
  ));
};

export const showChooseFlowBlockDialog = (
  props: ChooseFlowBlockDialogProps,
) => {
  Modal.show(({ close }) => (
    <ChooseFlowBlockDialog
      {...props}
      onConnectBlock={(...args) => {
        props.onConnectBlock?.(...args);
        close();
      }}
      onDismiss={() => {
        props.onDismiss?.();
        close();
      }}
    />
  ))?.onClose(props.onDismiss);
};

export const showConnectInstagramAccountDialog = (
  props: Partial<ConnectInstagramAccountDialogProps> = {},
) => {
  Modal.show(({ close }) => (
    <ConnectInstagramAccountDialog
      {...props}
      onConnected={async (...args) => {
        await props.onConnected?.(...args);
        close();
      }}
      onDismiss={() => {
        props.onDismiss?.();
        close();
      }}
    />
  ));
};

export const showChooseFlowDialog = (
  props: FlowSelectorDialogContainerProps,
) => {
  Modal.show(({ close }) => (
    <DialogLocalizationContextProvider
      header="modernComponents.FlowBuilder.modals.ChooseFlowBlockDialog.header"
      createFlowButtonTitle="modernComponents.FlowBuilder.modals.ChooseFlowBlockDialog.createButton"
      selectFlowButtonTitle="modernComponents.FlowBuilder.modals.ChooseFlowBlockDialog.selectButton"
    >
      <FlowSelectorDialogContainer
        {...props}
        onCreateNewFlow={(...args) => {
          props.onCreateNewFlow(...args);
          close();
        }}
        onSelectExisting={(...args) => {
          props.onSelectExisting(...args);
          close();
        }}
        onClose={() => {
          props.onClose?.();
          close();
        }}
      />
    </DialogLocalizationContextProvider>
  ));
};

export const toggleGesturesDialog = ({
  onSubmit,
  onDismiss,
  initialGesturesType,
}: GesturesModalProps) => {
  Modal.show(({ close }) => (
    <GesturesModal
      initialGesturesType={initialGesturesType}
      onDismiss={() => {
        onDismiss();
        close();
      }}
      onSubmit={(chosenType) => {
        onSubmit(chosenType);
        close();
      }}
    />
  ));
};
