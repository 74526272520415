import React from 'react';
import cn from 'classnames';
import { Type } from '@ui/Type';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { SupportedModels, useTokenCount } from './useTokenCount';
import css from './TokenCounter.css';

interface TokenCounterProps {
  className?: string;
  text: string;
  model: SupportedModels;
}

export const TokenCounter: React.FC<TokenCounterProps> = ({
  className,
  text,
  model,
}) => {
  const { count, calculatedText } = useTokenCount(text, model);
  const { t } = useSafeTranslation();

  return (
    <Type
      data-testid="customai_plugin_token_counter"
      className={cn(
        css.counter,
        calculatedText !== text && css.loading,
        className,
      )}
      size="12px"
    >{`${count} ${t(
      'modernComponents.FlowBuilder.views.components.CustomAiPlugin.tokens',
      { count },
    )}`}</Type>
  );
};
