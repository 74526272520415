import React from 'react';
import { propEq } from 'ramda';
import { useSafeTranslation } from '@utils/useSafeTranslation';

import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';
import { SimpleCombobox } from '@ui/SimpleCombobox';
import { Button } from '@ui/_deprecated/Button/Button';
import { ButtonIntent } from '@ui/_deprecated/Button';
import { ReactComponent as DropdownIcon } from '@ui/_deprecated/Icon/icons/ic_dropdown_arr.svg';

export enum WebviewHeightRatio {
  full = '100%',
  tall = '70%',
  compact = '40%',
}
export const WEBVIEW_HEIGHT_RATIO_LIST = Object.entries(WebviewHeightRatio).map(
  ([id, title]) => ({ id, title }),
);

export const InAppBrowserWindowSelector: React.FC<{
  onChange(value: string): void;
  webviewHeightRatio: string;
}> = ({ onChange, webviewHeightRatio }) => {
  const { t } = useSafeTranslation();
  return (
    <Flex alignItems="center" justifyContent="space-between">
      <Type size="15px_DEPRECATED">
        {t(
          'modernComponents.FlowBuilder.buttonPopupOverlay.windowHeight.label',
        )}
      </Type>
      <SimpleCombobox
        items={WEBVIEW_HEIGHT_RATIO_LIST}
        selectedItem={WEBVIEW_HEIGHT_RATIO_LIST.find(
          propEq('id', webviewHeightRatio),
        )}
        menuItemStyle={{
          minWidth: 85,
        }}
        onChange={(item) => {
          onChange(item?.id || 'full');
        }}
        renderInput={({ getToggleButtonProps, selectedItem }) => (
          <Button
            data-testid="flowbuilder__webview-size-selector"
            type="button"
            intent={ButtonIntent.secondary}
            {...getToggleButtonProps()}
            renderIconRight={() => <DropdownIcon />}
          >
            {selectedItem?.title}
          </Button>
        )}
      />
    </Flex>
  );
};
