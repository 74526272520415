import i18next from 'i18next';
import { memoize } from 'lodash-es';
import { Item } from '@ui/SimpleCombobox';
import { DisplayVariant } from '@globals';

export const WELCOME_MESSAGE_DISPLAY_VARIANTS = memoize(
  () =>
    [
      {
        id: DisplayVariant.leaveOpen,
        title: i18next.t(
          'modernComponents.FlowBuilder.EditorPanel.WhatsappChat.leaveOpen',
        ),
      },
      {
        id: DisplayVariant.tenSecThenHide,
        title: i18next.t(
          'modernComponents.FlowBuilder.EditorPanel.WhatsappChat.displayBrieflyThenHide',
        ),
      },
    ] as Item[],
);
