import throttle from 'lodash-es/throttle';
import {
  AbstractRenderer,
  Application,
  CanvasExtract,
  Container,
  DisplayObject,
  InteractionManager,
  Rectangle,
  Renderer,
} from 'pixi.js-legacy';
import { Viewport } from 'pixi-viewport';
import debounce from 'lodash-es/debounce';
import { isEmpty } from 'ramda';
import { Level, logLocally } from 'cf-common/src/logger';
import { LS, LSKey } from 'cf-common/src/localStorage';
import {
  GestureNavigation,
  isTrackpadEnabled,
  setTrackpadEnabled,
} from 'cf-common/src/gestureNavigation';
import { GesturePinchPlugin } from './gesturePinchPlugin/GesturePinchPlugin';
import { SingleView } from './views/SingleView';
import { colorToHex } from './views/utils';
import { hideControls, updateControls } from './views/helpers/ControlsHelpers';
import { MainControlView } from './views/main_flow_controls';
import { Feature, FlowBuilderAnimations, ViewMode } from './consts';
import { CursorHandler } from './views/entry-points/common/utils/eventHandlers/CursorHandler';
import { HoverH } from './views/entry-points/common/utils/eventHandlers/HoverHandler';
import { EventHandler } from './views/entry-points/common/utils/eventHandlers/EventHandler';
import { BlockControlView } from './views/block_control_view';
import { CardControlView } from './views/card_control_view';
import { GalleryCardControlView } from './views/gallery_card_control_view';
import { ButtonControlViewVertical } from './views/button_control_view';
import { blockWidth } from './views/plugin_consts';
import { trackFPS } from './utils/fpsMetrics';
import { HEXColors } from '@ui/_common/colors';

export const MIN_SCALE = 0.1;
export const MAX_SCALE = 1.3;

window.switchForceCanvas = () => {
  const isForceCanvas = LS.has(LSKey.forceCanvas);
  if (isForceCanvas) {
    LS.remove(LSKey.forceCanvas);
  } else {
    LS.set(LSKey.forceCanvas, true);
  }
  window.location.reload();
  return !isForceCanvas;
};

const preventMouseEvent = (e: MouseEvent) => {
  e.preventDefault();
};

interface StartAnimationParams {
  animationId: string;
  animationFunction?: () => void;
}

interface StopAnimationParams {
  animationId: string;
}

export class PixiField {
  app: Application;
  viewport: Viewport;
  private stage: Container;
  screen: Rectangle;
  renderer: Renderer | AbstractRenderer;
  interaction: InteractionManager;
  public availableFeatures: Feature[];
  private resizeObserver: any | null = null;

  private hoverHandlerInner: HoverH;
  private cursorHandlerInner: CursorHandler;
  private eventHandlerInner: EventHandler;
  private viewMode: ViewMode;
  private element: HTMLElement;
  panel: Container;
  /**
   * Public only for autotesting
   */
  activeAnimations: Record<string, (() => void) | null> = {};
  private onStopTicker?: () => void;

  // block: this fields initialize not in constructor
  // ***
  cardControlView!: CardControlView;
  galleryCardControlView!: GalleryCardControlView;
  buttonControlView!: ButtonControlViewVertical;
  blockControlView!: BlockControlView;
  tooltipView!: SingleView;
  cardControllerView!: SingleView;
  galleryCardControllerView!: SingleView;
  mainControlView!: MainControlView;
  // ***
  // end block

  constructor(
    elementId: string,
    viewMode: ViewMode,
    availableFeatures: Feature[],
  ) {
    this.availableFeatures = availableFeatures;
    const element = document.getElementById(elementId)!;
    this.viewMode = viewMode;
    const forceCanvas = LS.has(LSKey.forceCanvas);
    const app = new Application({
      forceCanvas,
      antialias: true,
      resizeTo: element,
      resolution: window.devicePixelRatio || 1,
      autoDensity: true,
      backgroundColor: colorToHex(HEXColors.greyLight30),
    });
    this.hoverHandlerInner = new HoverH(this, app.renderer.plugins.interaction);
    this.cursorHandlerInner = new CursorHandler(this);
    this.eventHandlerInner = new EventHandler();
    this.app = app;
    this.screen = app.screen;

    element.appendChild(app.view);
    // create viewport
    this.viewport = new Viewport({
      worldWidth: app.screen.width,
      worldHeight: app.screen.height,
      interaction: app.renderer.plugins.interaction, // the interaction module is important for wheel to work properly when renderer.view is placed or scaled,
      ticker: app.ticker,
    });

    this.stopTicker();
    element.addEventListener('contextmenu', preventMouseEvent);
    // add the viewport to the stage
    app.stage.addChild(this.viewport);
    this.stage = app.stage;
    // activate plugins

    this.updateViewportPlugins(isTrackpadEnabled());

    this.viewport.on('moved', () => {
      updateControls();
      this.startAnimation({
        animationId: FlowBuilderAnimations.viewportMove,
      });
    });

    this.viewport.on('moved-end', () => {
      this.stopAnimation({ animationId: FlowBuilderAnimations.viewportMove });
    });

    this.viewport.on('zoomed', () => {
      hideControls();
      this.startAnimation({
        animationId: FlowBuilderAnimations.viewportZoom,
      });
      this.stopZoomAnimation(); // this function is debounced
    });

    this.viewport.on('zoomed-end', () => {
      this.zoomEnd();
      this.stopZoomAnimation();
    });

    const gesturePinchPlugin = new GesturePinchPlugin({
      viewport: this.viewport,
      listenerNode: element,
    });
    this.viewport.plugins.add('gesture-pinch', gesturePinchPlugin);

    this.viewport.sortableChildren = true;
    this.interaction = app.renderer.plugins.interaction;
    this.renderer = app.renderer;

    this.panel = new Container();
    this.stage.addChild(this.panel);

    this.element = element;
  }

  setGestureNavigationType(type: GestureNavigation): void {
    const isTrackpadEnabled = type === GestureNavigation.trackpad;
    setTrackpadEnabled(isTrackpadEnabled);
    this.updateViewportPlugins(isTrackpadEnabled);
  }

  updateViewportPlugins(isTrackpadEnabled: boolean): void {
    this.viewport.plugins.remove('pinch');
    this.viewport.plugins.remove('wheel');
    this.viewport.plugins.remove('drag');
    this.viewport.plugins.remove('clampZoom');

    this.viewport.drag();
    if (isTrackpadEnabled) {
      this.viewport.wheel({
        trackpadPinch: true,
        wheelZoom: false,
        percent: 0.8,
      });
    } else {
      this.viewport.pinch().wheel();
    }
    this.viewport.clampZoom({
      minScale: MIN_SCALE,
      maxScale: MAX_SCALE,
    });
  }

  init() {
    this.cursorHandler().createCursor(this.viewport, 'grab');
    this.tooltipView = new SingleView('tooltip_view', 1200, 1000);
    this.cardControllerView = new SingleView('card_controller', 300, 500);
    this.galleryCardControllerView = new SingleView(
      'gallery_card_controller',
      300,
      1000,
    );
    this.cardControlView = new CardControlView();
    this.galleryCardControlView = new GalleryCardControlView();
    this.buttonControlView = new ButtonControlViewVertical();
    this.blockControlView = new BlockControlView();

    this.element.addEventListener(
      'pointerdown',
      this.startDragAnimationListener,
      true,
    );
    window.addEventListener('pointerup', this.stopDragAnimationListener, true);
    window.addEventListener('blur', this.blurWindowListener, true);

    this.mainControlView = new MainControlView();
    this.mainControlView.renderElement();
    this.panel.addChild(this.mainControlView);
    this.setObserver();
  }

  private zoomEnd = debounce(() => {
    try {
      this.hoverHandler().mouseEvt(true);
      this.hoverHandler().mouseEvt();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  }, 500);

  private stopZoomAnimation = debounce(() => {
    this.stopAnimation({ animationId: FlowBuilderAnimations.viewportZoom });
  }, 1000);

  render = throttle(() => {
    if (isEmpty(this.activeAnimations)) {
      this.app.renderer?.render(this.stage);
    }
  }, 50);

  private trackTickerFPS = () => {
    if (this.app.ticker) {
      trackFPS(this.app.ticker.FPS);
    }
  };

  setOnStopTicker(sender: () => void) {
    this.onStopTicker = sender;
  }

  startTicker() {
    this.app.ticker?.start();
    this.app.ticker?.add(this.trackTickerFPS);
  }

  stopTicker() {
    this.app.ticker?.stop();
    this.onStopTicker?.();
    this.app.ticker?.remove(this.trackTickerFPS);
  }

  startAnimation = ({
    animationId,
    animationFunction,
  }: StartAnimationParams) => {
    if (!animationId) {
      return;
    }
    if (isEmpty(this.activeAnimations)) {
      this.startTicker();
      logLocally(Level.debug, 'set unlimited fps');
    }
    if (animationFunction) {
      const existingAnimationFunction = this.activeAnimations[animationId];
      if (existingAnimationFunction) {
        this.app.ticker.remove(existingAnimationFunction);
      }
      this.app.ticker.add(animationFunction);
      this.activeAnimations[animationId] = animationFunction;
    } else {
      this.activeAnimations[animationId] = null;
    }
  };

  stopAnimation = ({ animationId }: StopAnimationParams) => {
    /*
      we can define this.activeAnimations[animationId] = null
      so, we need to make equation with undefined
     */
    if (!animationId || this.activeAnimations[animationId] === undefined) {
      return;
    }
    const existingAnimationFunction = this.activeAnimations[animationId];
    if (existingAnimationFunction) {
      this.app.ticker.remove(existingAnimationFunction);
    }
    delete this.activeAnimations[animationId];
    if (isEmpty(this.activeAnimations)) {
      this.stopTicker();
      logLocally(
        Level.debug,
        'set low fps',
        JSON.stringify(this.activeAnimations),
      );
    }
  };

  startDragAnimationListener = () => {
    this.startAnimation({ animationId: FlowBuilderAnimations.elementDrag });
  };

  blurWindowListener = (e: Event) => {
    if (e.target === window) {
      this.stopAnimation({ animationId: FlowBuilderAnimations.elementDrag });
    }
  };

  stopDragAnimationListener = () => {
    this.stopAnimation({ animationId: FlowBuilderAnimations.elementDrag });
  };

  getBlob = async () => {
    this.mainControlView.hide();
    return new Promise<Blob | null>((resolve) => {
      (this.app.renderer.plugins.extract as CanvasExtract)
        .canvas(this.stage)
        .toBlob((flowBlob: Blob | null) => {
          resolve(flowBlob);
        }, 'image/png');
      this.mainControlView.show();
    });
  };

  isViewOnly = () => this.viewMode === ViewMode.view;

  private handleResize = () => {
    if (this.app.resize && this.app.screen) {
      this.app.resize();
      this.viewport.resize(
        this.app.screen.width,
        this.app.screen.height,
        this.app.screen.width,
        this.app.screen.height,
      );
      if (this.mainControlView) {
        this.mainControlView.show();
        this.mainControlView.x = this.screen.width - 60;
        this.mainControlView.y =
          (this.screen.height - this.mainControlView.height()) / 2;
      }
    }
    this.app.render();
  };

  private setObserver = () => {
    const { ResizeObserver } = window as any;
    if (ResizeObserver) {
      this.resizeObserver = new ResizeObserver(this.handleResize);
      this.resizeObserver.observe(this.element);
    } else {
      this.handleResize();
      window.addEventListener('resize', this.handleResize);
    }
  };

  private unobserve() {
    if (this.resizeObserver) {
      this.resizeObserver.unobserve(this.element);
    } else {
      window.removeEventListener('resize', this.handleResize);
    }
  }

  getViewPortAllChildren(): DisplayObject[] {
    const viewPortAllChildren: DisplayObject[] = [];

    const deeplyAllChildren = (children: DisplayObject) => {
      const innerChildren = (
        children as unknown as {
          children: DisplayObject[];
        }
      ).children;
      if (innerChildren) {
        innerChildren.forEach((innerChild) => {
          deeplyAllChildren(innerChild);
        });
      }
      viewPortAllChildren.push(children);
    };

    this.viewport.children.forEach((child) => {
      deeplyAllChildren(child);
    });

    return viewPortAllChildren;
  }

  destroy() {
    this.unobserve();
    this.viewport.destroy();
    this.app.destroy({
      children: true,
      texture: true,
      baseTexture: true,
    } as any);

    this.element.removeEventListener('contextmenu', preventMouseEvent);
    this.element.removeEventListener(
      'pointerdown',
      this.startDragAnimationListener,
      true,
    );
    window.removeEventListener(
      'pointerup',
      this.stopDragAnimationListener,
      true,
    );
    window.removeEventListener('blur', this.blurWindowListener, true);

    this.cursorHandlerInner.destroy();
    this.eventHandlerInner.destroy();
    this.hoverHandlerInner.destroy();
  }

  hoverHandler = () => {
    return this.hoverHandlerInner;
  };

  cursorHandler = () => {
    return this.cursorHandlerInner;
  };

  eventHandler = () => {
    return this.eventHandlerInner;
  };

  fixBlockPosition(view: DisplayObject, leftBoarderX: number): Promise<void> {
    return new Promise((resolve, reject) => {
      let viewPosition;
      try {
        viewPosition = view.getGlobalPosition();
      } catch {
        reject();
        return;
      }

      const screenWidth = this.screen.width - leftBoarderX;
      if (screenWidth > 0) {
        let position = null;
        const scaledBlockWidth = blockWidth * this.getScale();
        if (viewPosition.x < leftBoarderX) {
          if (screenWidth > scaledBlockWidth) {
            position = leftBoarderX + 20;
          } else {
            position = leftBoarderX - (scaledBlockWidth - screenWidth) / 2;
          }
        } else if (viewPosition.x + scaledBlockWidth > this.screen.width) {
          if (screenWidth > scaledBlockWidth) {
            position = this.screen.width - scaledBlockWidth - 60;
          } else {
            position = leftBoarderX - (scaledBlockWidth - screenWidth) / 2;
          }
        }
        if (position !== null) {
          hideControls();
          this.startAnimation({
            animationId: FlowBuilderAnimations.viewportMove,
          });
          this.viewport.animate({
            position: {
              x:
                this.viewport.center.x -
                (position - viewPosition.x) / this.getScale(),
              y: this.viewport.center.y,
            },
            time: 300,
            callbackOnComplete: () => {
              this.stopAnimation({
                animationId: FlowBuilderAnimations.viewportMove,
              });
              this.viewport.emit('moved');
              resolve();
            },
          });
          this.app.ticker.update();
        }
      }
    });
  }

  getScale(): number {
    return this.viewport.scale.x ?? 1;
  }

  getMouseLocalPosition() {
    const mouseGlobalPosition = this.renderer.plugins.interaction.mouse.global;
    return mouseGlobalPosition && this.viewport.toLocal(mouseGlobalPosition);
  }
}
