import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { nTimeNotificationPluginFragment_config as NTNPluginConfig } from '@components/Plugins/NTimeNotificationPlugin/@types/nTimeNotificationPluginFragment';
import { CenteredLoader } from '@ui/Loader';
import {
  SimpleCombobox,
  defaultItemToString,
  defaultFilterItemsToShow,
  Item,
} from '@ui/SimpleCombobox';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import { Button } from '@ui/Button';
import { ServiceMessageType } from '@ui/ServiceMessage2';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Flex } from '@ui/Flex';
import { useMutation } from 'react-apollo';
import { usePageConnected } from '@utils/FacebookPages/usePageConnected';
import {
  NOTIFICATION_MESSAGES_TOPICS_QUERY,
  useNotificationMessagesTopics,
} from '@utils/Data/NotificationMessages/useNotificationMessagesTopics';
import { toaster } from '@services/MessageService';
import { getNTimeNotificationPluginTexts } from '../../../../views/plugins/NTimeNotificationPluginView/utils/getNTimeNotificationPluginTexts';
import { EditorPanelPluginProps } from '../../../types';
import { CREATE_N_TIME_NOTIFICATION_TOPICS_MUTATION } from './query';
import {
  CreateNTimeNotificationTopicsMutation,
  CreateNTimeNotificationTopicsMutationVariables,
} from './@types/CreateNTimeNotificationTopicsMutation';
import { PluginHeader } from '../../common/PluginHeader';
import { PluginInput } from './PluginInput';
import { PluginMenuItem } from './PluginMenuItem';
import css from './NTimeNotificationPlugin.css';

const CREATE_OPTION = 'CREATE_OPTION';

export const NTimeNotificationPlugin = ({
  botId,
  pluginData,
}: EditorPanelPluginProps<NTNPluginConfig>) => {
  const { state, blockId } = pluginData;

  const config = state?.data.config as NTNPluginConfig | null;
  const [selectedTopic, setSelectedTopic] = useState(
    config?.topic_id ? { id: config.topic_id, title: '' } : null,
  );

  const { t } = useSafeTranslation();
  const texts = getNTimeNotificationPluginTexts(config?.frequency);

  const inputRef = useRef<HTMLInputElement>();
  const history = useHistory();
  const { pageId } = usePageConnected(botId);

  const { notificationMessagesTopics, notificationMessagesTopicsLoading } =
    useNotificationMessagesTopics(config?.frequency);

  const [createTopic] = useMutation<
    CreateNTimeNotificationTopicsMutation,
    CreateNTimeNotificationTopicsMutationVariables
  >(CREATE_N_TIME_NOTIFICATION_TOPICS_MUTATION, {
    refetchQueries: [
      {
        query: NOTIFICATION_MESSAGES_TOPICS_QUERY,
        variables: { pageId: pageId!, frequency: config?.frequency },
      },
    ],
    onCompleted({ createNTimeNotificationTopics }) {
      state?.set(({ config }) => ({
        config: { ...config, topic_id: createNTimeNotificationTopics.id },
      }));
    },
    onError() {
      toaster.show({
        type: ServiceMessageType.error,
        payload: {
          message: t('NTimeNotificationPlugin.couldntSave'),
        },
      });
      state?.set(({ config }) => ({
        config: { ...config, topic_id: undefined },
      }));
    },
  });

  const topics =
    notificationMessagesTopics?.map(({ id, name }) => ({
      id,
      title: name ?? '',
    })) ?? [];

  if (notificationMessagesTopicsLoading) {
    return <CenteredLoader />;
  }

  function getSelectedItem() {
    if (!selectedTopic || !pageId) return null;
    return selectedTopic.id === CREATE_OPTION
      ? selectedTopic
      : topics.find(({ id }) => id === selectedTopic?.id) || null;
  }

  return (
    <>
      <PluginHeader
        title={`${texts.frequency} ${t('NTimeNotificationPlugin.Card.title')}`}
        tooltip={
          <Type color="white" size="12px" whiteSpace="pre-wrap">
            {t('NTimeNotificationPlugin.Card.tolltip')}
          </Type>
        }
      />
      <Spacer factor={2} />
      <Flex>
        <Type as="p" weight="semibold" size="15px">
          {`${t('NTimeNotificationPlugin.Card.frequency')}:`}
        </Type>
        <Spacer horizontalFactor={1.5} />
        <Type as="p" weight="regular" size="15px">
          {texts.pluginTitle}
        </Type>
      </Flex>
      <Spacer factor={6} />
      <Type as="p" weight="semibold" size="15px">
        {t('NTimeNotificationPlugin.Card.topic')}
      </Type>
      <Spacer factor={2} />
      <SimpleCombobox
        dataTestId="ntime-notification__topic-selector"
        selectedItem={getSelectedItem()}
        onChange={(item) => {
          if (item?.id === CREATE_OPTION)
            createTopic({
              variables: {
                pageId,
                name: item.title,
                frequency: config?.frequency,
              },
            });
          else
            state?.set(({ config }) => ({
              config: { ...config, topic_id: item && item.id },
            }));
          setSelectedTopic(item);
          if (item) inputRef.current?.blur();
        }}
        filter={(
          itemToString: (item: Item) => string,
          items: Item[],
          selectedItem: Item,
          inputValue: string | null,
        ) => {
          const matchingItems = defaultFilterItemsToShow(
            itemToString,
            items,
            selectedItem,
            inputValue,
          );
          const noItemsMatchFully =
            inputValue &&
            !matchingItems.some((item) => {
              const matchesFully = itemToString(item) === inputValue;
              return matchesFully;
            });
          if (noItemsMatchFully) {
            return [
              {
                id: CREATE_OPTION,
                title: inputValue as string,
              },
              ...matchingItems,
            ];
          }

          return matchingItems;
        }}
        renderItem={({ item, getItemProps, index, highlightedIndex }) => {
          const title = defaultItemToString(item);
          return (
            <PluginMenuItem
              key={item.id}
              leftElement={item.id === CREATE_OPTION ? '+ ' : undefined}
              {...getItemProps({ item })}
              title={title}
              tooltip={
                <div className={css.menuItemTooltip}>{title as string}</div>
              }
              tooltipBoundariesElement="viewport"
              active={index === highlightedIndex}
              className={css.menuItem}
            />
          );
        }}
        renderInput={(inputProps) => (
          <PluginInput
            {...inputProps}
            pageId={pageId}
            botId={botId}
            blockId={blockId}
            ref={inputRef}
          />
        )}
        items={topics}
      />
      <Spacer factor={6} />
      <Type as="p" weight="semibold" size="15px">
        {t('NTimeNotificationPlugin.Card.notifications')}
      </Type>
      <Spacer factor={3} />
      <Button onClick={() => history.push(`/bot/${botId}/broadcast/`)}>
        {t('NTimeNotificationPlugin.Card.reengage')}
      </Button>
    </>
  );
};
