import React from 'react';
import { Type } from '@ui/Type';
import * as css from './AttributeText.css';

export interface AttributeTextProps {}

export const AttributeText: React.FC<AttributeTextProps> = ({ children }) => (
  <Type size="15px" color="grey" className={css.text}>
    {children}
  </Type>
);
