import { Token, TokenType } from './types';
import { drop } from 'ramda';
import {
  WhatsappDocumentType,
  getWhatsappMediaTypes,
  makeMessageFromMediaType,
} from '@components/FlowBuilder/views/utils/createHTMLMediaTooltip';
import { HeaderParameterMediaType } from './components/HeaderMedia/types';

const findTwoCharsInRow =
  (char: string) => (value: string, index: number, obj: Array<string>) =>
    value === char && obj[index + 1] === char;

const digits = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];

const parseBetweenBraces = (chars: Array<string>) => {
  const startIndex = chars.findIndex(findTwoCharsInRow('{'));
  const endIndex = chars.findIndex(findTwoCharsInRow('}'));

  if (startIndex !== -1 && endIndex !== -1) {
    const value = chars.slice(startIndex + 2, endIndex);
    const isAllDigits = value.every((char) => digits.includes(char));

    if (!isAllDigits) {
      return null;
    }

    return {
      startIndex,
      value: Number(value.join('')),
      endIndex,
    };
  }

  return null;
};

export const extractTokens = (str: string): Array<Token> => {
  if (!str.length) {
    return [];
  }

  const result: Array<Token> = [];
  let chars = str.split('');
  let stack: Array<string> = [];

  const collectStack = () => {
    if (stack.length && stack.join('').trim().length) {
      result.push({
        type: TokenType.text,
        value: stack.join('').trim(),
      });
      stack = [];
    }

    return '';
  };

  // eslint-disable-next-line
  while (true) {
    const char = chars.shift();
    const next = chars[0];

    if (!char) {
      collectStack();

      break;
    }

    if (char === '\n') {
      collectStack();

      result.push({ type: TokenType.newline });
    } else if (char + next === '{{') {
      const maybeAttr = parseBetweenBraces([char, ...chars]);

      if (!maybeAttr) {
        stack.push(char);
        // eslint-disable-next-line
        continue;
      }

      collectStack();
      result.push({ type: TokenType.attribute, value: maybeAttr.value });
      chars = drop(maybeAttr.endIndex + 1, chars);
    } else {
      stack.push(char);
    }
  }

  return result;
};

export const getErrorDescription = (type: HeaderParameterMediaType) => {
  return {
    [HeaderParameterMediaType.image]: makeMessageFromMediaType(
      getWhatsappMediaTypes().image,
    ),
    [HeaderParameterMediaType.video]: makeMessageFromMediaType(
      getWhatsappMediaTypes().video,
    ),
    [HeaderParameterMediaType.document]: makeMessageFromMediaType(
      WhatsappDocumentType(),
    ),
  }[type];
};

export const cleanUpText = (text: string) => {
  return text.trim().replace(/\n|\t/g, ' ');
};
