import React from 'react';
import { useCurrentBotId } from '@utils/Routing';
import { useGlobalFollowUps } from '../hooks/useGlobalFollowUps';
import { useAttributes } from '@utils/AttributesUtils/AttributesData';
import { VariableSuggestType } from '@utils/AttributesUtils/AttributesUtilsTypes';
import { getFlowPlatform } from '../../../../../utils/getFlowPlatform';
import { Platform } from '@globals';
import { CenteredLoader } from '@ui/Loader';
import { FollowUpsList } from '../FollowUpsList';
import { GlobalFollowUpsNote } from './GlobalFollowUpsNote';

export const GlobalFollowUps: React.FC = () => {
  const botId = useCurrentBotId()!;
  const {
    hasGlobalFollowUps,
    globalFollowUpsLoading,
    setupGlobalFollowUps,
    ...globalFollowUpsListParams
  } = useGlobalFollowUps();

  const { attributes, loading: attributesLoading } = useAttributes(
    botId,
    VariableSuggestType.template,
    getFlowPlatform() || Platform.facebook,
  );

  if (globalFollowUpsLoading || attributesLoading) {
    return <CenteredLoader />;
  }

  if (!hasGlobalFollowUps) {
    return null;
  }

  return (
    <FollowUpsList
      textAttributes={attributes}
      renderNote={() => <GlobalFollowUpsNote />}
      {...globalFollowUpsListParams}
    />
  );
};
