import React from 'react';
import { TextWithAttributeEditor } from '../TextWithAttributeEditor/TextWithAttributeEditor';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { AttributesQuery_bot_variableSuggest } from '@utils/AttributesUtils/@types/AttributesQuery';
import { Spacer } from '@ui/Spacer';
import { Flex } from '@ui/Flex';
import css from './LiveChatTab.css';
import { Type } from '@ui/Type';
import { OnboardingTourHTMLElementId } from '@globals';

interface LiveChatTabProps {
  liveChatPrompt: string;
  updateText(text: string): void;
  updateTextSafely(text: string): void;
  availableAttributes: AttributesQuery_bot_variableSuggest[];
  botId: string;
}

export const LiveChatTab: React.FC<LiveChatTabProps> = ({
  liveChatPrompt,
  updateText,
  updateTextSafely,
  botId,
  availableAttributes,
}) => {
  const { t } = useSafeTranslation();

  return (
    <div>
      <Spacer factor={6} />
      <TextWithAttributeEditor
        inputContainerId={
          OnboardingTourHTMLElementId.OpenAiPluginLiveChatInstruction
        }
        title={t(
          'modernComponents.FlowBuilder.views.components.OpenAiPlugin.instructionsForAI',
        )}
        tooltip={
          <Flex flexDirection="column">
            <Flex
              flexDirection="column"
              className={css.tooltipList}
              gapFactor={1.5}
            >
              <Type size="12px" weight="semibold">
                {t(
                  'modernComponents.FlowBuilder.views.components.OpenAiPlugin.instructionsForAITooltip.launchLive',
                )}
              </Type>
              <Type size="12px">
                {t(
                  'modernComponents.FlowBuilder.views.components.OpenAiPlugin.instructionsForAITooltip.isAnnoyed',
                )}
              </Type>
              <Type size="12px">
                {t(
                  'modernComponents.FlowBuilder.views.components.OpenAiPlugin.instructionsForAITooltip.saysThe',
                )}
              </Type>
              <Type size="12px">
                {t(
                  'modernComponents.FlowBuilder.views.components.OpenAiPlugin.instructionsForAITooltip.asksTo',
                )}
              </Type>
            </Flex>
            <Spacer factor={4} />
            <Type size="12px" color="white">
              {t(
                'modernComponents.FlowBuilder.views.components.OpenAiPlugin.instructionsForAITooltip.explainWhenChatGPT',
              )}
            </Type>
          </Flex>
        }
        text={liveChatPrompt}
        update={updateText}
        updateSafely={updateTextSafely}
        placeholder={t(
          'modernComponents.FlowBuilder.views.components.OpenAiPlugin.textareaPlaceholder',
        )}
        botId={botId}
        availableAttributes={availableAttributes}
        data-testid="openai_plugin_settings-livechat_tab_prompt_input"
      />
    </div>
  );
};
