import { BLOCK_SUBTYPES } from '../../consts';
import { FlowController } from '../../flow/controller';
import { showToasterAfterCreatePersistentMenuFromConfigureTab } from '../../views/entry-points/EntryPointPersistentMenu/utils';
import { organizeBlocksAndBlocksWithZeroCoordinates } from '../../utils/organizeBlocks';
import { getEntryPointCard } from '../../views/entry-points/common/utils';
import { Node, PluginType } from '../../StatefulPlugin/types';

// TODO: remove this code after deprecate persistent menu from configure tab
//  see 4551 JIRA task
export const syncWithConfigurePersistentMenu = async (
  controller: FlowController,
) => {
  const needToOrganizePersistentMenus = controller
    .allNodes()
    .filter((node: Node) => {
      const { block, x, y, outLinks } = node;
      if (block.subtype !== BLOCK_SUBTYPES.entrypoint) {
        return false;
      }
      const entryPointCard = getEntryPointCard(block.cards);
      if (
        entryPointCard?.plugin_id !== PluginType.persistent_menu_entry_point
      ) {
        return false;
      }

      if (!outLinks) {
        return false;
      }

      const outLinkWithTheSameCoordinates = Object.keys(outLinks).some(
        (firstLink) => {
          return Object.keys(outLinks).find(
            (secondLink) =>
              secondLink !== firstLink &&
              controller._nodesMap[firstLink].x ===
                controller._nodesMap[secondLink].x &&
              controller._nodesMap[firstLink].y ===
                controller._nodesMap[secondLink].y,
          );
        },
      );

      if (outLinkWithTheSameCoordinates) {
        return true;
      }

      const outLinkWithNodeCoordinates = Object.keys(outLinks).find(
        (link) =>
          controller._nodesMap[link].x === x &&
          controller._nodesMap[link].y === y,
      );

      return !!outLinkWithNodeCoordinates;
    });
  const uniqueNeedToOrganizePersistentMenus = [
    ...new Set(needToOrganizePersistentMenus),
  ];

  if (uniqueNeedToOrganizePersistentMenus.length) {
    await organizeBlocksAndBlocksWithZeroCoordinates(
      controller.flow.id,
      uniqueNeedToOrganizePersistentMenus,
      controller._nodesMap,
    );
    showToasterAfterCreatePersistentMenuFromConfigureTab();
  }
};
