import { BubbleEditor } from '@ui/BubbleEditor';
import { UrlsPlaceholderBubble } from '@ui/BubbleEditor/RichPlaceholder/RichPlaceholder';
import { Button } from '@ui/Button';
import { CheckBox } from '@ui/CheckBox';
import { Flex } from '@ui/Flex';
import { Input } from '@ui/Input';
import { TextArea } from '@ui/Input/TextArea';
import { Anchor } from '@ui/Links';
import { CenteredLoader } from '@ui/Loader';
import { Spacer } from '@ui/Spacer';
import { TextOutsideControls } from '@ui/TextOutsideControls';
import {
  TimeIntervalsIds,
  TimeInterval,
  TimeoutInputByTimestamp,
} from '@ui/TimeoutInput';
import { Type } from '@ui/Type';
import { sendEvent } from '@utils/Analytics';
import { InputLikeElement, insertText } from '@utils/documentExecCommand';
import { usePageConnected } from '@utils/FacebookPages/usePageConnected';
import React, { useMemo } from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { SendToMessengerColor, SendToMessengerSize } from '@globals';
import { ReactComponent as DropdownIcon } from '../../../../../../modern-ui/_deprecated/Icon/icons/ic_dropdown_arr.svg';
import { PluginControlLabel } from '../../../../../Plugins/common/PluginControlLabel';
import { DEFAULT_GREETING } from '../../../../../Plugins/CustomerChatEntryPoint/CustomerChatEntryPointConst';
import {
  popupEntryPointFragment_config as PopupEntryPointConfig,
  popupEntryPointFragment_config_shopify_popup,
} from '../../../../../Plugins/PopupEntryPoint/@types/popupEntryPointFragment';
import { PLUGIN_DEFAULT_CONFIG } from '../../../../../Plugins/PopupEntryPoint/PopupEntryPointConst';
import { SimpleCombobox } from '@ui/SimpleCombobox';
import { PluginType } from '../../../../types';
import { tooltips } from '../../../../views/Menu/menu_view';
import { EditorPanelPluginProps } from '../../../types';
import { PluginHeader } from '../../common/PluginHeader';
import { HelpIconWithTooltip } from '../common/HelpIconWithTooltip';
import { NeedConnectPageNote } from '../common/NeedConnectPageNote';
import { pagesListValidator } from '../common/pageListValidator';
import { UrlsListHelp } from '../common/UrlListHelp';
import {
  BUTTON_COLORS,
  BUTTON_SIZE,
  getCTATexts,
} from '../SendToMessengerEntryPoint/consts';
import { changeTriggerType } from './helpers';
import * as css from './PopupEntryPoint.css';
import { Translation } from '@translations';

const TEXTS_LIMITS = {
  title: 52,
  description: 80,
};

enum TriggerTypes {
  idleTimeout = 'idle_timeout',
  exitIntent = 'exit_intent',
  addToCart = 'add_to_cart',
}

interface TriggerOrder {
  id: TriggerTypes;
  titleKey: Translation;
}

const TRIGGER_ORDER: Array<TriggerOrder> = [
  {
    id: TriggerTypes.addToCart,
    titleKey: 'shopify.userAddsAProductToCart',
  },
  {
    id: TriggerTypes.exitIntent,
    titleKey: 'shopify.userShowsExitIntent',
  },
  {
    id: TriggerTypes.idleTimeout,
    titleKey: 'shopify.whenTheUserLogsInToTheSite',
  },
];

export const PopupEntryPoint: React.FC<
  EditorPanelPluginProps<PopupEntryPointConfig>
> = ({ botId, pluginParams }) => {
  const { pluginConfig, setPluginConfigState, savePlugin } = pluginParams;

  const { t } = useSafeTranslation();

  const { pageId, loading } = usePageConnected(botId);

  const customTimeIntervals = useMemo<TimeInterval[]>(
    () => [
      {
        id: TimeIntervalsIds.immediately,
        divider: 1,
        titleKey: 'shopify.None',
      },
    ],
    [],
  );

  if (loading) {
    return <CenteredLoader />;
  }

  const CTA_TEXTS = getCTATexts();

  const shopifyPopup =
    pluginConfig.shopify_popup ||
    (PLUGIN_DEFAULT_CONFIG.shopify_popup as popupEntryPointFragment_config_shopify_popup);

  const setTriggerType = (id: string) => {
    const type = changeTriggerType(shopifyPopup.trigger?.type || '', id);
    setPluginConfigState({
      config: {
        ...pluginConfig,
        shopify_popup: {
          ...shopifyPopup,
          trigger: {
            __typename: 'ShopifyIntegrationPopupTrigger',
            idle_timeout_seconds: 0,
            ...shopifyPopup.trigger,
            type,
          },
        },
      },
    });
    sendEvent({
      category: 'flows',
      action: 'change trigger type',
      label: PluginType.popup_entry_point,
      propertyBag: {
        trigger_type: type,
      },
    });
  };

  return (
    <>
      <PluginHeader
        title={t('shopify.popUpSettings')}
        tooltip={
          <Anchor
            noUnderline
            intent="unstyled"
            //    href={tooltips.shopifyPopup.url} // todo add video
            target="_blank"
            style={{
              color: 'var(--white)',
            }}
            onClick={() => {
              sendEvent({
                category: 'flows',
                action: 'click to tooltip (learn more)',
                label: PluginType.popup_entry_point,
              });
            }}
            dangerouslySetInnerHTML={{
              __html: tooltips().shopifyPopup.html as string,
            }}
          />
        }
      />
      {!pageId && <NeedConnectPageNote />}
      <PluginControlLabel label={t('shopify.headline')}>
        {(bind) => (
          <TextOutsideControls
            onInsertRequest={(text, el) => {
              insertText(text, el as InputLikeElement);
            }}
            currentTextLimit={
              TEXTS_LIMITS.title - (shopifyPopup.title || '').trim().length
            }
            shouldShowOutsideControls={{
              emoji: true,
              symbolsLimit: true,
            }}
            tight
            initialShow={false}
          >
            {({ ref, getInputProps }) => (
              <Input
                data-testid="flows_popup-entry-point_headline"
                value={shopifyPopup.title || ''}
                onChange={({ currentTarget: { value } }) => {
                  const config = {
                    ...pluginConfig,
                    shopify_popup: {
                      ...shopifyPopup,
                      title: value,
                    },
                  };
                  setPluginConfigState({ config });
                }}
                placeholder={DEFAULT_GREETING}
                maxLength={TEXTS_LIMITS.title}
                {...getInputProps<HTMLInputElement>({
                  onBlur: () => {
                    sendEvent({
                      category: 'flows',
                      action: 'change title',
                      label: PluginType.popup_entry_point,
                      propertyBag: {
                        title: shopifyPopup.title,
                      },
                    });
                    savePlugin();
                  },
                })}
                {...bind}
                ref={ref}
              />
            )}
          </TextOutsideControls>
        )}
      </PluginControlLabel>
      <Spacer factor={4} />
      <PluginControlLabel label={t('shopify.mainText')}>
        {(bind) => (
          <TextOutsideControls
            onInsertRequest={(text, el) => {
              insertText(text, el as InputLikeElement);
            }}
            currentTextLimit={
              TEXTS_LIMITS.description - (shopifyPopup.description || '').length
            }
            shouldShowOutsideControls={{
              emoji: true,
              symbolsLimit: true,
            }}
            tight
            initialShow={false}
          >
            {({ ref, getInputProps }) => (
              <TextArea
                data-testid="flows_popup-entry-point_main-text"
                rows={2}
                value={shopifyPopup.description || ''}
                onChange={({ currentTarget: { value } }) => {
                  const config = {
                    ...pluginConfig,
                    shopify_popup: {
                      ...shopifyPopup,
                      description: value,
                    },
                  };
                  setPluginConfigState({ config });
                }}
                placeholder={DEFAULT_GREETING}
                maxLength={TEXTS_LIMITS.description}
                {...getInputProps<HTMLTextAreaElement>({
                  onBlur: () => {
                    sendEvent({
                      category: 'flows',
                      action: 'change description',
                      label: PluginType.popup_entry_point,
                      propertyBag: {
                        title: shopifyPopup.title,
                      },
                    });
                    savePlugin();
                  },
                })}
                {...bind}
                ref={ref}
              />
            )}
          </TextOutsideControls>
        )}
      </PluginControlLabel>
      <Spacer factor={4} />
      <Flex justifyContent="space-between">
        <PluginControlLabel label={t('shopify.buttonSize')}>
          {(bind) => (
            <SimpleCombobox
              items={BUTTON_SIZE}
              selectedItem={BUTTON_SIZE.find(
                ({ id }) =>
                  id === (shopifyPopup.size || SendToMessengerSize.standard),
              )}
              onChange={(item) => {
                setPluginConfigState({
                  config: {
                    ...pluginConfig,
                    shopify_popup: {
                      ...shopifyPopup,
                      size:
                        (item?.id as SendToMessengerSize) ||
                        SendToMessengerSize.standard,
                    },
                  },
                });
                savePlugin();
                sendEvent({
                  category: 'flows',
                  action: 'change size',
                  label: PluginType.send_to_messenger_entry_point,
                  propertyBag: {
                    size: item?.id || SendToMessengerSize.standard,
                  },
                });
              }}
              renderInput={({ getToggleButtonProps, selectedItem }) => (
                <Button
                  data-testid="flows_popup-entry-point_button-size"
                  intent="secondary"
                  {...getToggleButtonProps()}
                  iconRight={<DropdownIcon />}
                  className={css.ddButton}
                  {...bind}
                >
                  {selectedItem?.title}
                </Button>
              )}
              menuItemStyle={{
                minWidth: 146,
              }}
            />
          )}
        </PluginControlLabel>
        <PluginControlLabel label={t('shopify.buttonColor')}>
          {(bind) => (
            <SimpleCombobox
              items={BUTTON_COLORS}
              selectedItem={BUTTON_COLORS.find(
                ({ id }) =>
                  id === (shopifyPopup.color || SendToMessengerColor.blue),
              )}
              onChange={(item) => {
                setPluginConfigState({
                  config: {
                    ...pluginConfig,
                    shopify_popup: {
                      ...shopifyPopup,
                      color:
                        (item?.id as SendToMessengerColor) ||
                        SendToMessengerColor.blue,
                    },
                  },
                });
                savePlugin();
                sendEvent({
                  category: 'flows',
                  action: 'change color',
                  label: PluginType.send_to_messenger_entry_point,
                  propertyBag: {
                    color: item?.id || SendToMessengerColor.blue,
                  },
                });
              }}
              renderInput={({ getToggleButtonProps, selectedItem }) => (
                <Button
                  data-testid="flows_popup-entry-point_button-color"
                  intent="secondary"
                  {...getToggleButtonProps()}
                  iconRight={<DropdownIcon />}
                  className={css.ddButton}
                  {...bind}
                >
                  {selectedItem?.title}
                </Button>
              )}
              menuItemStyle={{
                minWidth: 146,
              }}
            />
          )}
        </PluginControlLabel>
      </Flex>
      <Spacer factor={4} />
      <PluginControlLabel label={t('shopify.buttonText')}>
        {(bind) => (
          <SimpleCombobox
            items={CTA_TEXTS}
            selectedItem={
              CTA_TEXTS.find(({ id }) => id === shopifyPopup?.button_text) ||
              CTA_TEXTS[0]
            }
            onChange={(item) => {
              setPluginConfigState({
                config: {
                  ...pluginConfig,
                  shopify_popup: {
                    ...shopifyPopup,
                    button_text: item?.id || '',
                  },
                },
              });
              savePlugin();
              sendEvent({
                category: 'flows',
                action: 'change cta_text',
                label: PluginType.popup_entry_point,
                propertyBag: {
                  cta_text: item?.id || '',
                },
              });
            }}
            renderInput={({ getToggleButtonProps, selectedItem }) => (
              <Button
                data-testid="flows_popup-entry-point_button-text"
                intent="secondary"
                {...getToggleButtonProps()}
                iconRight={<DropdownIcon />}
                className={css.ddButton}
                style={{ width: 360 }}
                {...bind}
              >
                {selectedItem?.title}
              </Button>
            )}
          />
        )}
      </PluginControlLabel>
      <Spacer factor={4} />
      <PluginControlLabel
        label={
          <Flex>
            {t('shopify.showOnPages')}
            <UrlsListHelp targetText="include" />
          </Flex>
        }
      >
        {(bind) => (
          <Input
            render={() => (
              <BubbleEditor
                data-testid="flows_popup-entry-point_show-on-pages"
                onStringChange={(line) => {
                  setPluginConfigState({
                    config: {
                      ...pluginConfig,
                      shopify_popup: {
                        ...shopifyPopup,
                        included_pages: line
                          .split('\n')
                          .map((item) => item.trim())
                          .filter(Boolean),
                      },
                    },
                  });
                }}
                defaultValue={(shopifyPopup.included_pages || []).join('\n')}
                validationErrorFunc={pagesListValidator}
                onBlur={() => {
                  savePlugin();
                  sendEvent({
                    category: 'flows',
                    action: 'change pages whitelist',
                    label: PluginType.popup_entry_point,
                    propertyBag: {
                      included_pages: shopifyPopup.included_pages,
                    },
                  });
                }}
                placeholder={<UrlsPlaceholderBubble />}
                preventSpaces
                {...bind}
              />
            )}
          />
        )}
      </PluginControlLabel>
      <Spacer factor={4} />
      <PluginControlLabel
        label={
          <Flex>
            {t('shopify.dontShowPopUpOnThesePages')}
            <UrlsListHelp targetText="exclude" />
          </Flex>
        }
      >
        {(bind) => (
          <Input
            render={() => (
              <BubbleEditor
                data-testid="flows_popup-entry-point_dont-show-on-pages"
                onStringChange={(line) => {
                  setPluginConfigState({
                    config: {
                      ...pluginConfig,
                      shopify_popup: {
                        ...shopifyPopup,
                        excluded_urls: line
                          .split('\n')
                          .map((item) => item.trim())
                          .filter(Boolean),
                      },
                    },
                  });
                }}
                defaultValue={(shopifyPopup.excluded_urls || []).join('\n')}
                validationErrorFunc={pagesListValidator}
                onBlur={() => {
                  savePlugin();
                  sendEvent({
                    category: 'flows',
                    action: 'change pages blacklist',
                    label: PluginType.popup_entry_point,
                    propertyBag: {
                      excluded_urls: shopifyPopup.excluded_urls,
                    },
                  });
                }}
                placeholder={<UrlsPlaceholderBubble />}
                preventSpaces
                {...bind}
              />
            )}
          />
        )}
      </PluginControlLabel>
      <Spacer factor={4} />
      <PluginControlLabel
        label={
          <Flex>
            {t('shopify.showPopUpWhen')}
            <HelpIconWithTooltip
              content={t('shopify.DecideWhenThePopUpComesUp')}
            />
          </Flex>
        }
      >
        {() =>
          TRIGGER_ORDER.map(({ titleKey, id }) => (
            <React.Fragment key={id}>
              <label>
                <Flex alignItems="center">
                  <CheckBox
                    data-testid={`flows_popup-entry-point_dont-show-when-${id}`}
                    checked={(shopifyPopup.trigger?.type || '').includes(id)}
                    onChange={() => {
                      setTriggerType(id);
                      savePlugin();
                    }}
                  />
                  <Spacer horizontalFactor={2} />
                  <Type size="15px_DEPRECATED">{t(titleKey)}</Type>
                </Flex>
              </label>
              {id === TriggerTypes.idleTimeout &&
                (shopifyPopup.trigger?.type || '').includes(
                  TriggerTypes.idleTimeout,
                ) && (
                  <>
                    <Spacer factor={1} />
                    <Flex alignItems="center">
                      <Spacer horizontalFactor={7} />
                      <Type size="15px_DEPRECATED">
                        {t('shopify.setTimeout')}
                      </Type>
                      <Spacer horizontalFactor={2} />
                      <TimeoutInputByTimestamp
                        data-testid="flows_popup-entry-point_set-timeout"
                        allowedTimeIntervalsIds={[
                          TimeIntervalsIds.immediately,
                          TimeIntervalsIds.seconds,
                          TimeIntervalsIds.minutes,
                          TimeIntervalsIds.hours,
                        ]}
                        customTimeIntervals={customTimeIntervals}
                        value={
                          (shopifyPopup.trigger?.idle_timeout_seconds || 0) *
                          1000
                        }
                        onChange={(value) => {
                          setPluginConfigState({
                            config: {
                              ...pluginConfig,
                              shopify_popup: {
                                ...shopifyPopup,
                                trigger: {
                                  __typename: 'ShopifyIntegrationPopupTrigger',
                                  type: TriggerTypes.idleTimeout,
                                  ...shopifyPopup.trigger,
                                  idle_timeout_seconds: value * 0.001,
                                },
                              },
                            },
                          });
                          sendEvent({
                            category: 'flows',
                            action: 'change timeout',
                            label: PluginType.popup_entry_point,
                            propertyBag: {
                              idle_timeout_seconds: value,
                            },
                          });
                          savePlugin();
                        }}
                      />
                    </Flex>
                  </>
                )}
              <Spacer factor={2} />
            </React.Fragment>
          ))
        }
      </PluginControlLabel>
      <Spacer factor={4} />
    </>
  );
};
