import React, { useMemo } from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { PluginHeader } from '../../common/PluginHeader';
import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';
import { Spacer } from '@ui/Spacer';
import { ComboboxWithTriangleButton } from '@ui/SimpleCombobox';
import { Input } from '@ui/Input';
import { MenuItem } from '@ui/Menu';
import { useReactPluginConfig } from '@components/Plugins/common/useReactPluginConfig';
import { EditorPanelPluginProps } from '@components/FlowBuilder/EditorPanel/types';
import { KommoPluginConfig } from '@components/Plugins/KommoPlugin/KommoPlugin';
import Maybe from 'graphql/tsutils/Maybe';
import css from './KommoPlugin.css';
import { CollapsibleSection } from '@ui/CollapsibleSection';
import { Button, ButtonUnstyled } from '@ui/Button';
import { Icon } from '@ui/Icon';
import cn from 'classnames';
import { useAttributes } from '@utils/AttributesUtils/AttributesData';
import { useFlowPlatform } from '@utils/Data/Flow/useFlowPlatform';
import { VariableSuggestType } from '@utils/AttributesUtils/AttributesUtilsTypes';
import {
  ITextWithAttributesEditorProps,
  TextWithAttributesEditor,
  deserialize,
} from '@ui/TextWithAttributesEditor';
import { ignoreEnterKey } from '@utils/DOM/keyHandlers';
import { IconButton } from '@ui/IconButton';
import { useKommoCustomFields } from './useKommoCustomFields';
import { useKommoPipelines } from './useKommoPipelines';
import { useKommoUsers } from './useKommoUsers';
import { CenteredLoader } from '@ui/Loader';
import { BASE_REQUIRED_ATTRIBUTES } from './consts';
import { Block } from './Block';
import { TaskView } from './TaskView';
import { KommoActionType } from '@globals';

type AttributeFieldProps = {
  onAttributeStringChange: ITextWithAttributesEditorProps['onStringChange'];
  attributes: ITextWithAttributesEditorProps['attributes'];
  defaultAttributeValue: string;
  onAttributeBlur: ITextWithAttributesEditorProps['onBlur'];
  customFields: Array<{ id: string; title: string; type: string }>;
  selectedField: Maybe<{ id: string; title: string; type: string }>;
  onFieldChange(field: { id: string; title: string; type: string }): void;
  onRemoveField: VoidFunction;
};

const AttributeField: React.FC<AttributeFieldProps> = ({
  onAttributeStringChange,
  defaultAttributeValue,
  attributes,
  onAttributeBlur,
  customFields,
  selectedField,
  onFieldChange,
  onRemoveField,
}) => {
  const { t } = useSafeTranslation();

  return (
    <Flex>
      <Flex flexDirection="column">
        <Input
          containerClassName={css.attributeContainer}
          error={!defaultAttributeValue && t('KommoPlugin.attributeNotSet')}
          render={() => (
            <TextWithAttributesEditor
              defaultValue={deserialize(defaultAttributeValue)}
              onStringChange={onAttributeStringChange}
              onBlur={onAttributeBlur}
              attributes={attributes}
              onKeyDown={ignoreEnterKey}
              singleLine
              hasManageAttributes
            />
          )}
        />
      </Flex>
      <Spacer factor={0} horizontalFactor={3} />
      <ComboboxWithTriangleButton
        intent="secondary"
        items={customFields}
        className={cn(css.comboboxWithTriangleButton, css.fieldContainer)}
        selectedItem={selectedField}
        onChange={onFieldChange}
      >
        {({ downshift, props: { currentItems, item, index } }) => (
          <MenuItem
            className={css.comboboxMenuItem}
            {...downshift.getItemProps({ item, index })}
            active={downshift.highlightedIndex === index}
            title={currentItems[index].title}
          />
        )}
      </ComboboxWithTriangleButton>
      <Spacer factor={0} horizontalFactor={3} />
      <IconButton
        icon="delete"
        className={css.deleteButton}
        onClick={onRemoveField}
      />
    </Flex>
  );
};

export const KommoPlugin: React.FC<EditorPanelPluginProps<KommoPluginConfig>> =
  ({ pluginParams, botId }) => {
    const { t } = useSafeTranslation();

    const { configState, updateConfig, savePluginSafely } =
      useReactPluginConfig(pluginParams);
    const { pipelineId, pipelineName } = configState ?? {};

    const { data: customFieldsData, loading: customFieldsLoading } =
      useKommoCustomFields({
        variables: {
          botId,
        },
      });

    const { data: pipelinesData, loading: pipelinesLoading } =
      useKommoPipelines({
        variables: {
          botId,
        },
      });

    const { data: usersData, loading: usersLoading } = useKommoUsers({
      variables: {
        botId,
      },
    });

    const { kommoPipelines } = pipelinesData ?? {};

    const pipelines = useMemo(
      () =>
        (kommoPipelines ?? []).map((item) => ({
          id: String(item.id),
          title: item.name,
        })),
      [kommoPipelines],
    );

    const steps = useMemo(
      () =>
        (
          kommoPipelines?.find((item) => item.id === configState.pipelineId)
            ?.statuses ?? []
        )
          .filter((pipelineStatus) => pipelineStatus.type !== 1)
          .map((item) => ({ id: String(item.id), title: item.name })),
      [configState, kommoPipelines],
    );

    const platform = useFlowPlatform();
    const { attributes } = useAttributes(
      botId,
      VariableSuggestType.template,
      platform,
    );

    const customFields = useMemo(
      () =>
        (customFieldsData?.kommoCustomFields ?? []).map((item) => ({
          id: String(item.id),
          title: item.name,
          type: item.type,
        })),
      [customFieldsData],
    );

    const users = useMemo(
      () =>
        (usersData?.kommoUsers ?? []).map((item) => ({
          id: String(item.id),
          title: item.name,
        })),
      [usersData],
    );

    if (configState.actionType === KommoActionType.create_task)
      return (
        <>
          <PluginHeader title={t('KommoPlugin.kommo')} />
          <TaskView
            configState={configState}
            updateConfig={updateConfig}
            users={users}
            usersLoading={usersLoading}
          />
        </>
      );

    return (
      <>
        <PluginHeader title={t('KommoPlugin.kommo')} />
        <Block
          data-testid="kommo-plugin-funnel"
          loading={pipelinesLoading}
          title={t('KommoPlugin.funnel')}
          items={pipelines}
          error={pipelineId === null ? t('KommoPlugin.requiredFiles') : null}
          onChange={(value) =>
            updateConfig({
              pipelineId: Number(value.id),
              pipelineName: value.title,
              statusId: null,
              statusName: null,
            })
          }
          selectedItem={
            pipelineId === null
              ? null
              : {
                  id: String(pipelineId),
                  title: pipelineName ?? String(pipelineId),
                }
          }
        />
        <Spacer factor={4} />
        <Block
          data-testid="kommo-plugin-step"
          loading={pipelinesLoading}
          title={t('KommoPlugin.step')}
          items={steps}
          onChange={(value) =>
            updateConfig({
              statusId: Number(value.id),
              statusName: value.title,
            })
          }
          error={
            configState.statusId === null
              ? t('KommoPlugin.requiredFiles')
              : null
          }
          selectedItem={
            configState.statusId === null
              ? null
              : {
                  id: String(configState.statusId),
                  title: configState.statusName ?? String(configState.statusId),
                }
          }
        />
        <Spacer factor={4} />
        <Block
          data-testid="kommo-plugin-assignee-optional"
          loading={usersLoading}
          title={t('KommoPlugin.assigneeOptional')}
          items={users}
          onChange={(value) =>
            updateConfig({
              assigneeId: Number(value.id),
              assigneeName: value.title,
            })
          }
          selectedItem={
            configState.assigneeId === null
              ? null
              : {
                  id: String(configState.assigneeId),
                  title:
                    configState.assigneeName ?? String(configState.assigneeId),
                }
          }
        />
        <Spacer factor={16} />
        <div className={css.collapsibleSection}>
          <CollapsibleSection
            anchor={({ changeExtended }) => (
              <ButtonUnstyled
                data-testid="kommo-plugin-attributes-container"
                className={css.dataTitle}
                onClick={() => {
                  changeExtended();
                }}
              >
                <Flex flexDirection="column">
                  <Type size="15px" weight="medium">
                    {t('KommoPlugin.data')}
                  </Type>
                  <Spacer factor={1} />
                  <Type size="12px" weight="medium" color="baseTertiary">
                    {(configState.customFields?.length ?? 0) +
                      BASE_REQUIRED_ATTRIBUTES}{' '}
                    {t('KommoPlugin.attributes')}
                  </Type>
                </Flex>
                <Icon icon="triangle" />
              </ButtonUnstyled>
            )}
          >
            {({ bind }) => (
              <div {...bind}>
                <Spacer factor={4} />
                <Input
                  readOnly
                  value="whatsapp name"
                  data-testid="kommo-plugin-whatsapp-name"
                />
                <Spacer factor={4} />
                <Input
                  readOnly
                  value="whatsapp phone"
                  data-testid="kommo-plugin-whatsapp-phone"
                />
                <Spacer factor={4} />
                {customFieldsLoading ? (
                  <CenteredLoader />
                ) : (
                  (!!customFields?.length ||
                    !!configState.customFields?.length) && (
                    <>
                      {!!configState.customFields?.length && (
                        <>
                          <Flex>
                            <Type
                              className={css.attributeContainer}
                              size="12px"
                              weight="medium"
                              color="baseTertiary"
                            >
                              {t('KommoPlugin.attribute')}
                            </Type>
                            <Spacer factor={0} horizontalFactor={3} />
                            <Type
                              size="12px"
                              weight="medium"
                              color="baseTertiary"
                            >
                              {t('KommoPlugin.field')}
                            </Type>
                          </Flex>
                          <Spacer factor={2} />
                          {configState.customFields.map((item, i) => (
                            <>
                              <AttributeField
                                attributes={attributes}
                                onAttributeStringChange={(value) => {
                                  const newFields = [
                                    ...(configState.customFields ?? []),
                                  ];
                                  newFields[i] = { ...item, attribute: value };
                                  savePluginSafely({
                                    customFields: newFields,
                                  });
                                }}
                                defaultAttributeValue={item.attribute ?? ''}
                                onAttributeBlur={() => {
                                  updateConfig();
                                }}
                                customFields={customFields}
                                selectedField={{
                                  id: String(item.fieldId),
                                  title: item.fieldName ?? '',
                                  type: item.fieldType ?? '',
                                }}
                                onFieldChange={(value) => {
                                  const newFields = [
                                    ...(configState.customFields ?? []),
                                  ];
                                  newFields[i] = {
                                    ...item,
                                    fieldId: Number(value.id),
                                    fieldName: value.title,
                                    fieldType: value.type,
                                  };
                                  savePluginSafely({
                                    customFields: newFields,
                                  });
                                }}
                                onRemoveField={() => {
                                  const newFields = [
                                    ...(configState.customFields ?? []),
                                  ];
                                  newFields.splice(i, 1);
                                  updateConfig({
                                    customFields: newFields,
                                  });
                                }}
                              />
                              <Spacer factor={4} />
                            </>
                          ))}
                        </>
                      )}
                      <Button
                        data-testid="kommo-plugin-add-custom-field-button"
                        onClick={() =>
                          updateConfig({
                            customFields: [
                              ...(configState.customFields || []),
                              {
                                __typename: 'KommoFieldMapping',
                                attribute: null,
                                fieldId: null,
                                fieldName: null,
                                fieldType: null,
                              },
                            ],
                          })
                        }
                        intent="secondary"
                        fullWidth
                      >
                        <Flex alignItems="center" justifyContent="center">
                          <Icon icon="plus" />
                          <Spacer factor={0} horizontalFactor={1.5} />
                          {t('KommoPlugin.addCustomField')}
                        </Flex>
                      </Button>
                    </>
                  )
                )}
              </div>
            )}
          </CollapsibleSection>
        </div>
      </>
    );
  };
