import { Timezone } from '@globals';
import { DateFormat } from '@utils/DateTime';

export enum TimezoneLabel {
  utc = 'UTC',
  bot = 'Bot',
}

export const getTimezoneOptions = (timezone_offset: number) => [
  {
    id: Timezone.bot,
    title: `${TimezoneLabel.bot} (GMT${DateFormat.UTC(timezone_offset)})`,
  },
  {
    id: Timezone.utc,
    title: TimezoneLabel.utc,
  },
];
