import React from 'react';
import { Focus } from 'react-powerplug';
import { Type } from '@ui/Type';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import {
  deserialize,
  TextWithAttributesEditor,
} from '@ui/TextWithAttributesEditor';
import { useAttributes } from '@utils/AttributesUtils/AttributesData';
import { useCurrentBotId } from '@utils/Routing';
import { VariableSuggestType } from '@utils/AttributesUtils/AttributesUtilsTypes';
import { Platform } from '@globals';
import { Input } from '@ui/Input';
import { insertText } from '@utils/Slite/insertText';
import { Editor } from 'slate-react';
import { TextOutsideControls } from '@ui/TextOutsideControls';
import { ignoreEnterKey } from '@utils/DOM/keyHandlers';

interface Touchable {
  touched: boolean;
  setTouched: (value: boolean) => void;
}

export interface WhatsappListDescriptionProps extends Touchable {
  value: string;
  setValue: (value: string) => void;
}

const DESCRIPTION_LIMIT = 72;

export const WhatsappListDescription: React.FC<WhatsappListDescriptionProps> =
  ({ touched, setTouched, value, setValue }) => {
    const { t } = useSafeTranslation();
    const botId = useCurrentBotId();
    const { attributes } = useAttributes(
      botId,
      VariableSuggestType.template,
      Platform.whatsapp,
    );

    return (
      <Flex flexDirection="column">
        <Type size="12px" weight="medium" color="cobaltTertiary">
          {t(
            'modernComponents.FlowBuilder.plugins.WhatsappListPlugin.magicButton.title',
          )}
        </Type>
        <Spacer factor={2} />
        <Focus>
          {({ bind: { onFocus, onBlur } }) => (
            <Input
              render={() => (
                <TextOutsideControls
                  initialShow={false}
                  onInsertRequest={(val, el) => {
                    insertText(val, el as Editor);
                  }}
                  currentTextLimit={DESCRIPTION_LIMIT - (value?.length || 0)}
                  shouldShowOutsideControls={{
                    emoji: true,
                    attributes: true,
                    symbolsLimit: true,
                  }}
                  tight
                >
                  {({ ref, getInputProps }) => (
                    <TextWithAttributesEditor
                      placeholder={t(
                        'modernComponents.FlowBuilder.plugins.WhatsappListPlugin.magicButton.placeholder',
                      )}
                      defaultValue={deserialize(value || '')}
                      autoFocus={touched}
                      attributes={attributes}
                      singleLine={false}
                      onStringChange={(description) => {
                        setValue(description.trim());
                      }}
                      onFocus={(event: Event) => {
                        onFocus();
                        setTouched(true);
                        getInputProps().onFocus(event);
                      }}
                      onBlur={(event: Event) => {
                        onBlur();
                        getInputProps().onBlur(event);
                      }}
                      onKeyDown={ignoreEnterKey}
                      maxLength={DESCRIPTION_LIMIT}
                      hasManageAttributes
                      shouldMoveCursorToEndOnFocus
                      editorRef={ref}
                    />
                  )}
                </TextOutsideControls>
              )}
            />
          )}
        </Focus>
      </Flex>
    );
  };
