export enum HeaderParameterMediaType {
  document = 'document',
  image = 'image',
  video = 'video',
}

export interface HeaderMediaFileSelectorData {
  link: string | null;
  filename: string | null;
}
