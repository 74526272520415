import React from 'react';
import { OverlayType, OverlayProps } from './types';
import * as Overlays from './overlays';

export type AvailableOverlays = Record<
  OverlayType,
  React.FC<OverlayProps<any>>
>;

export const AVAILABLE_OVERLAYS_DEFAULT: AvailableOverlays = {
  [OverlayType.textareaWithAttributes]: Overlays.TextareaWithAttributes,
  [OverlayType.textarea]: Overlays.Textarea,
  [OverlayType.menu]: Overlays.Menu,
  [OverlayType.tooltip]: Overlays.Tooltip,
  [OverlayType.inputAttribute]: Overlays.InputAttribute as React.FC<
    OverlayProps<any>
  >,
  [OverlayType.blocksSuggest]: Overlays.BlocksSuggestOverlay,
  [OverlayType.buttonPopup]: Overlays.ButtonPopupOverlay,
  [OverlayType.nativeHtml]: Overlays.NativeHtml,
  [OverlayType.testInFacebook]: Overlays.TestInChatfuel,
  [OverlayType.bubbleInput]: Overlays.BubbleInputOverlay,
  [OverlayType.accountSelector]: Overlays.ExternalAccount,
};

export const AVAILABLE_OVERLAYS_SHARED = {
  [OverlayType.menu]: Overlays.Menu,
  [OverlayType.tooltip]: Overlays.Tooltip,
} as AvailableOverlays;
