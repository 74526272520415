import React from 'react';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import { sendEvent } from '@utils/Analytics';
import { customAiPluginFragment_config_aiIntents } from '../../../../../../Plugins/CustomAiPlugin/@types/customAiPluginFragment';
import { Intent } from './Intent/Intent';
import { SupportedModels } from './TokenCounter/useTokenCount';
import { InfoCallout } from './InfoCallout/InfoCallout';
import { ServerStorageItemKeys } from '@utils/ServerStorage';
import { useSafeTranslation } from '@utils/useSafeTranslation';

interface InfoTabProps {
  onUpdateIntents(
    newIntents: Array<customAiPluginFragment_config_aiIntents>,
  ): void;
  onSaveIntents(): void;
  intents: Array<customAiPluginFragment_config_aiIntents>;
  botId: string;
  model: SupportedModels;
}

export const InfoTab: React.FC<InfoTabProps> = ({
  onUpdateIntents,
  onSaveIntents,
  intents,
  botId,
  model,
}) => {
  const { t } = useSafeTranslation();
  return (
    <div>
      <InfoCallout
        infoText={t(
          'modernComponents.FlowBuilder.views.components.CustomAiPlugin.Callouts.info',
        )}
        storageKey={ServerStorageItemKeys.customAiInfoCalloutHide}
      />
      <Spacer factor={6} />
      <Flex flexDirection="column" gapFactor={1}>
        {intents.map((intent, i) => (
          <Intent
            model={model}
            description={intent.description || ''}
            prompt={intent.prompt || ''}
            title={intent.title}
            key={intent.title}
            onBlur={() => onSaveIntents()}
            onUpdate={(prompt) => {
              const updatedIntents = intents.map((intent, index) => {
                if (index !== i) {
                  return intent;
                }
                return {
                  ...intent,
                  prompt,
                };
              });
              onUpdateIntents(updatedIntents);
              sendEvent({
                category: 'ai plugin',
                label: 'custom ai',
                action: 'fill info tab',
                propertyBag: {
                  botId,
                  field_name: intent.title,
                },
              });
            }}
          />
        ))}
      </Flex>
    </div>
  );
};
