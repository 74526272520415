import { useEventListener } from 'cf-common/src/hooks/useEventListener';

const preventKeyboardWheel = (event: WheelEvent) => {
  if (event.ctrlKey === true) {
    event.preventDefault();
  }
};

export const usePreventPageZoom = () => {
  useEventListener<WheelEvent>('wheel', preventKeyboardWheel, {
    options: { passive: false },
  });
};
