import React, { useCallback, useEffect, useMemo } from 'react';
import cn from 'classnames';
import { refetchAttributesQueryObservables } from '@utils/AttributesUtils/AttributesUtils';
import { Editor } from 'slate-react';
import {
  deserialize,
  EditorModeEnum,
  TextWithAttributesEditor,
} from '@ui/TextWithAttributesEditor';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { TextOutsideControls } from '@ui/TextOutsideControls';
import { insertText } from '@utils/Slite/insertText';
import { AttributeInputBase } from '@components/AttributeInput';
import {
  Divider,
  GenericFilter,
  ParameterFilterValueOperatorSelector,
} from './GenericFilter';
import { UserFilterProps } from '../types';
import { Input } from '@ui/Input';
import {
  useAttributes,
  useAttributesValuesLazy,
} from '@utils/AttributesUtils/AttributesData';
import { VariableSuggestType } from '@utils/AttributesUtils/AttributesUtilsTypes';
import { Platform, UserAttributeType, VariablesType } from '@globals';
import { AttributeFilterCombobox } from './AttributeFilterCombobox';
import { getFlowPlatform } from '../../../../../utils/getFlowPlatform';
import * as css from './Filter.css';
import { DateInput } from '@ui/DateInput';
import { DATE_ATTRIBUTES, EXCLUDE_ATTRIBUTES } from '../const';
import moment from 'moment/moment';
import { DateFormat } from '@utils/DateTime';
import { DateType } from '@utils/DateTime/types';
import { usePrevious } from 'cf-common/src/utils/hooks';

export function AttributeFilter<T>(props: UserFilterProps<T>) {
  const {
    botId,
    customAttributesOnly,
    suggestAttributesInValue,
    parameters,
    parameter,
    parametersOperatorDisabled,
    onParameterUpdate,
    savePlugin,
    savePluginAsync,
    userAttributeTypeSelectorHidden,
    disabled,
    disableGenericFilter,
    forceMultilineValue = false,
    autoCheckPlatform,
  } = props;
  const { t } = useSafeTranslation();
  const platform = autoCheckPlatform
    ? null
    : getFlowPlatform() ?? Platform.facebook;
  const { attributes: allAttributes, loading: attributesLoading } =
    useAttributes(
      botId,
      VariableSuggestType.omnibox,
      platform,
      EXCLUDE_ATTRIBUTES,
    );
  const [
    getAttributeSuggests,
    { attributeValuesSuggests, loading: attributeValuesSuggestsLoading },
  ] = useAttributesValuesLazy(
    botId,
    parameter.type as unknown as VariablesType,
    parameter.name ?? '',
  );
  const customAttributes = allAttributes.filter(
    ({ type }) => type === VariablesType.custom,
  );
  const attributeName = parameter.name ?? '';
  const currentAttributeValue = parameter.values[0];

  const singleLine = !parameters || parameters?.length === 1;
  const nameAttributes = customAttributesOnly
    ? customAttributes
    : allAttributes;

  const onInputAttributeChange = useCallback(
    (value: string) => {
      if (!value && parameter.type === UserAttributeType.custom) {
        onParameterUpdate({
          ...parameter,
          name: '',
        });
        return;
      }
      if (parameter.name !== value) {
        const existedAttribute = nameAttributes.find((a) => a.name === value);
        if (existedAttribute) {
          const { name, type } = existedAttribute;
          onParameterUpdate({
            ...parameter,
            type: type as unknown as UserAttributeType,
            name,
          });
        } else {
          onParameterUpdate({
            ...parameter,
            type: UserAttributeType.custom,
            name: value ?? null,
          });
        }
      }
    },
    [nameAttributes, onParameterUpdate, parameter],
  );

  useEffect(
    () => () => {
      refetchAttributesQueryObservables(botId, platform);
    },
    [botId, platform],
  );

  useEffect(() => {
    getAttributeSuggests();
    // eslint-disable-next-line
  }, []);

  const isDateAttribute = useMemo(
    () => DATE_ATTRIBUTES.includes(parameter.name || ''),
    [parameter.name],
  );

  const prevIsDateAttribute = usePrevious(isDateAttribute, false);
  const dateInputValue = parameter.values[0] || DateFormat.YMMDD(Date.now());

  useEffect(() => {
    if (isDateAttribute !== prevIsDateAttribute) {
      onParameterUpdate({
        ...parameter,
        values: [dateInputValue],
      });
    }
  }, [
    dateInputValue,
    isDateAttribute,
    onParameterUpdate,
    parameter,
    prevIsDateAttribute,
  ]);

  return (
    <GenericFilter {...props} disabled={disableGenericFilter || disabled}>
      <AttributeInputBase
        disabled={disabled}
        attributes={nameAttributes}
        value={attributeName}
        title={attributeName}
        platform={platform}
        placeholder={t(
          'modernComponents.FlowBuilder.views.components.CollectUserData.common.attribute',
        )}
        onChange={(value) => {
          onInputAttributeChange(value);
        }}
        inputContainerClassName={cn(
          css.inputAttributeContainer,
          css.inputControlDecorator,
          {
            [css.secondColumnSelector]: !userAttributeTypeSelectorHidden,
            [css.secondColumnSelectorLarge]: userAttributeTypeSelectorHidden,
          },
        )}
        inputContainerStyle={{
          maxWidth: userAttributeTypeSelectorHidden ? 95 : 58,
        }}
        customAttributesOnly={customAttributesOnly}
        style={{
          color: 'var(--blue)',
          fontSize: 15,
          lineHeight: 1.4,
          textAlign: 'left',
        }}
        onBlur={async () => {
          if (!suggestAttributesInValue) {
            getAttributeSuggests();
          }

          await savePluginAsync();
          refetchAttributesQueryObservables(botId, platform);
        }}
      />
      <Divider />
      <ParameterFilterValueOperatorSelector
        className={css.thirdColumnSelector}
        parameter={parameter}
        onParameterUpdate={onParameterUpdate}
        disabled={disabled || parametersOperatorDisabled}
      />
      <Divider />

      {isDateAttribute ? (
        <DateInput
          dateFormat={DateFormat.YMMDD}
          value={moment(dateInputValue)}
          onChange={(date) => {
            onParameterUpdate({
              ...parameter,
              values: [date && DateFormat.YMMDD(date as unknown as DateType)],
            });
          }}
        />
      ) : suggestAttributesInValue ? (
        <Input
          containerClassName={css.inputControlDecorator}
          render={() => (
            <TextOutsideControls
              initialShow={false}
              onInsertRequest={(val, el) => {
                insertText(val, el as Editor);
              }}
              shouldShowOutsideControls={{ attributes: true }}
              tight
            >
              {({ ref, getInputProps }) => (
                <TextWithAttributesEditor
                  className={cn({
                    [css.fourthColumnShortSelector]: !singleLine,
                    [css.fourthColumnMediumSelector]: singleLine,
                    [css.fourthColumnLargeSelector]:
                      userAttributeTypeSelectorHidden,
                  })}
                  editorRef={ref}
                  defaultValue={deserialize(currentAttributeValue ?? '')}
                  onStringChange={(value) => {
                    onParameterUpdate({
                      ...parameter,
                      values: value ? [value] : [],
                    });
                  }}
                  onBlur={(event) => {
                    savePlugin();
                    getInputProps().onBlur(event);
                  }}
                  onKeyDown={(event: any) => {
                    if (event.key === 'Enter') {
                      event.stopPropagation();
                      ref.current?.blur?.();
                      return false;
                    }
                    return undefined;
                  }}
                  onFocus={getInputProps().onFocus}
                  placeholder="value"
                  attributes={allAttributes}
                  disabled={attributesLoading || disabled}
                  fixedEditorMode={EditorModeEnum.edit}
                  shouldMoveCursorToEndOnFocus
                  hasManageAttributes
                  singleLine={!forceMultilineValue}
                />
              )}
            </TextOutsideControls>
          )}
        />
      ) : (
        <AttributeFilterCombobox
          botId={botId}
          disabled={disabled}
          savePlugin={savePlugin}
          singleLine={singleLine}
          parameter={parameter}
          onParameterUpdate={onParameterUpdate}
          attributeValuesSuggests={attributeValuesSuggests}
          attributeValuesSuggestsLoading={attributeValuesSuggestsLoading}
        />
      )}
    </GenericFilter>
  );
}
