import { Container, Graphics, IPoint, Sprite } from 'pixi.js-legacy';
import nanoid from 'nanoid';
import { LS, LSKey } from 'cf-common/src/localStorage';
import { getPixiField, getPixiFieldStrict } from '../../../PixiFieldRepository';
import { Point, Card } from '../../../types';
import { DESTROY_OPTIONS, Rect } from '../Shapes';
import {
  DraggingContext,
  LayoutProps,
  View,
  AddToLayoutProps,
  CursorProps,
  UpdateParams,
  AlignType,
  VerticalAlignType,
  BackgroundProps,
} from './types';
import { resByFunc, findParentByCallback } from '../../../views/utils';
import { DragHandler } from '../../../views/entry-points/common/utils/eventHandlers/DragHandler';
import { marginByFunc } from './utils';
import { HEXColors } from '@ui/_common/colors';

export class LayoutView<Props = AddToLayoutProps> {
  view: View;

  props: Props;

  constructor(view: View, props: Props) {
    this.view = view;
    this.props = props;
  }
}

export const getAlignPosition = (
  align: AlignType | VerticalAlignType,
  containerSize: number,
  elementSize: number,
) => {
  if (align === 'top' || align === 'start') {
    return 0;
  }
  if (align === 'bottom' || align === 'end') {
    return containerSize - elementSize;
  }
  return (containerSize - elementSize) / 2;
};

export class Layout extends Container {
  TEST_NAME = 'MainLayout';

  _views: LayoutView[];
  _parentLayout?: Layout;
  _width: number = 0;
  _height: number = 0;
  _background: Rect;
  _layoutProps: LayoutProps;
  _dragHandler?: DragHandler;
  _disable: boolean = false;
  hovered: boolean;
  layoutProperties?: AddToLayoutProps;
  private savedLayoutBackground?: BackgroundProps;
  private wasDestroyed?: boolean;
  private privateAnimationFunction?: () => void;
  readonly layoutId: string;

  _textAreaShown?: boolean; // TODO check in CommentPlugin

  loggingFlag: boolean;

  constructor(props: LayoutProps = {}, loggingFlag = false) {
    super();
    if (props.name) this.name = props.name;
    this.layoutId = nanoid();
    this._views = [];
    this.hovered = false;
    if (props.x) this.x = props.x;
    if (props.y) this.y = props.y;
    this.width(props.width);
    this.height(props.height);

    this.loggingFlag = loggingFlag;

    this._background = new Rect({ name: props.backgroundName });
    this._layoutProps = {
      width: 0,
      height: 0,
      itemsOffset: 0,
      draggableItems: false,
      clip: false,
      draggable: false,
      opacity: 1,
      zIndexFunc: (zIndex: number) => zIndex,
      background: {},
      margin: { left: 0, right: 0, top: 0, bottom: 0 },
      ...props,
    };
    this.addChild(this._background.shape());
    getPixiFieldStrict()
      .hoverHandler()
      .subscribe({
        view: this,
        eventId: 'layout_hover',
        over: () => {
          this.hovered = true;
          this._updateBackground();
        },
        out: () => {
          this.hovered = false;
          this._updateBackground();
        },
      });

    this.initDebugMode();
  }

  protected setBackgroundName(name: string) {
    this._background._shape.name = name;
  }

  protected _updateBackground() {
    const cursor = resByFunc(this._layoutProps.cursor, this) as CursorProps;
    if (cursor?.in !== undefined && this.hovered) {
      if (
        getPixiFieldStrict()
          .cursorHandler()
          .createCursor(this, cursor.in, cursor.out)
      ) {
        getPixiFieldStrict().hoverHandler().mouseEvt();
      }
    } else {
      getPixiFieldStrict().cursorHandler().removeCursor(this);
    }

    if (this._layoutProps.draggable && !getPixiFieldStrict().isViewOnly()) {
      if (!this._dragHandler) {
        this._dragHandler = new DragHandler(this, getPixiFieldStrict());
      }
    } else if (this._dragHandler) {
      this._dragHandler.stop();
      delete this._dragHandler;
    }

    const layoutProps = this._layoutProps;
    if (
      !layoutProps.background?.fill &&
      !layoutProps.background?.backgroundStyle
    ) {
      this._background.hide();
      return;
    }
    this._background.show();
    let bProps = layoutProps.background;
    if (this.hovered) {
      bProps = { ...bProps, ...(bProps?.onhover || {}) };
    }
    const backgroundProps = {
      x: 0,
      y: 0,
      width: this.width(),
      height: this.height(),
      cornerRadius: resByFunc(bProps.cornerRadius, this),
      corners: resByFunc(bProps.corners, this),
      fill: resByFunc(bProps.fill, this),
      backgroundStyle: resByFunc(bProps.backgroundStyle, this) ?? '',
      stroke: resByFunc(bProps.stroke, this),
      strokeWidth: resByFunc(bProps.strokeWidth, this) || 0,
      strokeOpacity: resByFunc(bProps.strokeOpacity, this),
      strokeAlignment: resByFunc(bProps.strokeAlignment, this),
      opacity: resByFunc(bProps.opacity, this),
    };

    this._background.updateProperties(backgroundProps);
  }

  shape() {
    return this;
  }

  add(view: Container | Sprite | Graphics) {
    this.addChild(view);
  }

  // overrides super property with another params
  // @ts-ignore
  width(w?: number) {
    if (w === undefined) {
      return this._width;
    }
    this._width = w;
    return w;
  }

  getOccupiedWidth(): number {
    return this.width();
  }

  // @ts-ignore
  height(h?: number) {
    if (h === undefined) {
      return this._height;
    }
    this._height = resByFunc(h, this);
    return this._height;
  }

  strokeWidth() {
    return this._background.strokeWidth();
  }

  parentLayout(layout?: Layout) {
    if (layout) {
      this._parentLayout = layout;
    }
    return this._parentLayout;
  }

  /**
   * Renders entire element (with its children)
   *
   * @param shouldRunOnBeforeRender {boolean?} predicate to determine the need to run `onBeforeRender` callback
   */
  renderElement({
    shouldRunOnBeforeRender,
    disabledRenderAfterUpdate,
  }: UpdateParams = {}) {
    let zIndex = 0;
    this._background.zOrder(zIndex++);
    let maxHeight = 0;
    let maxWidth = 0;
    this._views.forEach((lView) => {
      const { view } = lView;
      if (shouldRunOnBeforeRender) {
        view.onBeforeRender();
      }
      const { props: vProps } = lView;
      const gone = resByFunc(vProps.gone, this);
      const visible = resByFunc(vProps.visible, this);
      if (!gone) {
        this._updateSizeAndZIndex(vProps, view, zIndex);
        const margin = marginByFunc(vProps.margin, this);
        view.y = margin.top;
        view.x = margin.left;
        view.renderElement({
          shouldRunOnBeforeRender,
          disabledRenderAfterUpdate,
        });

        maxWidth = Math.max(
          maxWidth,
          margin.left + view.width() + margin.right,
        );
        maxHeight = Math.max(
          maxHeight,
          margin.top + view.height() + margin.bottom,
        );
        zIndex += 1;
      }
      if (gone || !visible) {
        view.hide();
      } else {
        view.show();
      }
    });
    this.width(this._layoutProps.width || maxWidth);
    let newHeight = this._layoutProps.height || maxHeight;
    if (this._layoutProps.minHeight !== undefined) {
      newHeight = Math.max(this._layoutProps.minHeight, newHeight);
    }
    this.height(newHeight);
    this._updateBackground();
    this._views.forEach((lView) => {
      const { view, props: vProps } = lView;
      const margin = marginByFunc(vProps.margin, this);
      if (vProps.align) {
        view.x =
          getAlignPosition(
            vProps.align,
            this.width(),
            view.width() + view.strokeWidth(),
          ) + margin.left;
      }
      if (vProps.verticalAlign) {
        view.y =
          getAlignPosition(vProps.verticalAlign, this.height(), view.height()) +
          margin.top;
      }
    });
    if (!disabledRenderAfterUpdate) {
      getPixiFieldStrict().render();
    }
    return this;
  }

  protected _updateSizeAndZIndex(
    vProps: AddToLayoutProps,
    view: View,
    zIndex: number,
  ) {
    const margin = marginByFunc(vProps.margin, this);

    const width = this._layoutProps.width || 0;
    if ((vProps.width || 0) > 0) {
      view.width(vProps.width);
    } else if (width > 0 && vProps.width === 0) {
      view.width(width - margin.left - margin.right);
    }

    const height = this._layoutProps.height || 0;
    if ((vProps.height || 0) > 0) {
      view.height(vProps.height);
    } else if (height > 0 && vProps.height === 0) {
      view.height(height - margin.top - margin.bottom);
    }

    view.zOrder(this._layoutProps.zIndexFunc?.(zIndex) || zIndex);
  }

  size() {
    return this._views.length;
  }

  on(event: string, fn: (...args: any[]) => void, context?: any) {
    this.interactive = true;
    super.on(event, fn, context);
    return this;
  }

  layout(view: View, props?: AddToLayoutProps, idx?: number) {
    let exists = false;
    let p = {
      margin: { left: 0, top: 0, bottom: 0, right: 0 },
      visible: true,
      gone: false,
    } as LayoutProps;
    this._views = this._views.filter((v) => {
      if (v.view === view) {
        exists = true;
        // TODO: will be refactored in validation
        p = v.props as LayoutProps;
        return false;
      }
      return true;
    });

    // TODO: will be refactored in validation
    p = { ...p, ...props } as LayoutProps;
    if (view instanceof Layout) {
      view.parentLayout(this);
    }
    if (!exists) {
      this.addChild(view.shape());
    }
    if (typeof idx !== 'undefined' && idx >= 0) {
      this._views.splice(idx, 0, new LayoutView(view, p));
    } else {
      this._views.push(new LayoutView(view, p));
    }
    // eslint-disable-next-line no-param-reassign
    view.layoutProperties = p;
    return this;
  }

  addToLayout(view: View, props?: AddToLayoutProps, index?: number) {
    return this.layout(view, props, index);
  }

  removeView(view: View) {
    this._views = this._views.filter((v) => v.view !== view);
  }

  findViewIndex(view: View) {
    return this._views.findIndex((v) => v.view === view);
  }

  removeAllViews() {
    this.views().forEach((view) => {
      view.destroy();
      this.removeView(view);
    });
  }

  views() {
    return this._views.map((v) => v.view);
  }

  viewByCardId(cardId: Partial<string>) {
    return this._views.find((v) => {
      const view = v.view as unknown;
      const card =
        view &&
        typeof view === 'object' &&
        '_card' in view &&
        (view as { _card: unknown })._card; // Что-то не так с ts не может расширить тип после '_card' in view приходится использовать кастинг
      return (
        card &&
        typeof card === 'object' &&
        'id' in card &&
        (card as { id: unknown }).id === cardId
      );
    })?.view;
  }

  /**
   * Renders entire node (block in flow builder) in which element is situated
   *
   * @param shouldRunOnBeforeRender {boolean?} predicate to determine the need to run `onBeforeRender` callback
   */
  renderNode(
    { shouldRunOnBeforeRender }: UpdateParams = {
      shouldRunOnBeforeRender: true,
    },
  ) {
    if (this._parentLayout) {
      this._parentLayout.renderNode({ shouldRunOnBeforeRender });
    } else {
      if (shouldRunOnBeforeRender) {
        this.onBeforeRender();
      }
      this.renderElement({
        shouldRunOnBeforeRender,
        disabledRenderAfterUpdate: true,
      });
      getPixiFieldStrict().render();
    }
    this.height(this.height());
    this.width(this.width());
    return this;
  }

  /**
   * Callback runs before the render to update all dependent data
   * or for any extra logic that should be done on every render
   */
  onBeforeRender() {}

  isEditing() {}

  globalPosition(): IPoint | Point {
    if (this._destroyed) {
      return { x: 0, y: 0 };
    }
    return this.getGlobalPosition();
  }

  moveToTop() {
    let maxOrder = 0;
    if (this.parent) {
      const { children } = this.parent;
      children.forEach((child) => {
        if (child.zIndex === undefined) {
          // eslint-disable-next-line no-param-reassign
          child.zIndex = maxOrder;
          maxOrder++;
        } else {
          maxOrder = Math.max(child.zIndex, maxOrder);
        }
      });
      if (!maxOrder) {
        maxOrder = 0;
      }
      this.zIndex = maxOrder + 1;
    }
  }

  moveToBottom() {
    let minOrder = Infinity;
    this.parent.children.forEach((child) => {
      minOrder = Math.min(child.zIndex);
    });
    if (minOrder === Infinity) {
      minOrder = 0;
    }
    if (!minOrder) {
      minOrder = 0;
    }
    this.zIndex = minOrder - 1;
  }

  indexOf(view: View) {
    let result = -1;
    this._views.forEach((v, index) => {
      if (v.view === view) {
        result = index;
      }
    });
    return result;
  }

  isLast(view: View) {
    return this.findViewIndex(view) === this.size() - 1;
  }

  show() {
    this.visible = true;
    getPixiFieldStrict().render();
  }

  hide() {
    this.visible = false;
    getPixiFieldStrict().render();
  }

  zOrder(z: number) {
    this.zIndex = z;
    getPixiField()?.render();
  }

  setDisabled(disabled: boolean) {
    this._disable = disabled;
    this.alpha = disabled ? 0.6 : 1;
    this.interactiveChildren = !disabled;
    this.interactive = !disabled;
    getPixiFieldStrict().render();
  }

  get animationFunction() {
    return this.privateAnimationFunction;
  }

  set animationFunction(func: (() => void) | undefined) {
    this.privateAnimationFunction = func;
  }

  startAnimation() {
    getPixiFieldStrict().startAnimation({
      animationId: this.layoutId,
      animationFunction: this.animationFunction,
    });
  }

  stopAnimation() {
    getPixiFieldStrict().stopAnimation({
      animationId: this.layoutId,
    });
  }

  _dragView?: Layout;
  _draggingControlView?: Layout;
  _draggingContext?: DraggingContext;
  _elementsDragHandler?: DragHandler;

  controlDragging(dragView: Layout, draggingControlView: Layout) {
    this._dragView = dragView;
    this._draggingControlView = draggingControlView;
    if (!this._dragView._elementsDragHandler) {
      this._dragView._elementsDragHandler = new DragHandler(
        this._dragView,
        getPixiFieldStrict(),
      );
    }
    this._handleDraggingEvents();
  }

  dragFunc(view: Layout) {
    const limits = this.dragLimits({ view });
    return (pos: Point) => {
      return {
        x: Math.min(Math.max(pos.x, limits.x.min), limits.x.max),
        y: Math.min(Math.max(pos.y, limits.y.min), limits.y.max),
      };
    };
  }

  dragLimits(_: { view?: Layout }) {
    return { x: { min: 0, max: 0 }, y: { min: 0, max: 0 } };
  }

  distanceFunc(view: View) {
    return {
      coordinate: view.y,
      distance: view.height(),
    };
  }

  onElementPositionChanged(_: View, __: number, ___: number) {}

  _handleDraggingEvents() {
    const dragView = this._dragView;
    if (!dragView) {
      return;
    }
    const layout = this;
    const { viewport } = getPixiFieldStrict();
    dragView.off('dragmove').off('dragstart').off('dragend');
    dragView.on('dragmove', (e: any) => {
      const context = layout._draggingContext;
      const view = layout._draggingControlView;
      if (!view || !context) {
        return;
      }
      view.y = e.y / viewport.scale.y + context.startY;
      view.x = e.x / viewport.scale.x + context.startX;

      if (!context.started) {
        context.tempView = new Rect({
          width: view.width(),
          height: view.height(),
          opacity: 0,
        });
        layout._views.forEach((v, i) => {
          if (v.view === view) {
            context.idx = i;
          }
        });
        layout.removeView(view);
        layout.layout(context.tempView, view.layoutProperties, context.idx);
        this.renderNode({ shouldRunOnBeforeRender: false });
        context.started = true;
      }
      let done = false;
      let self = false;

      layout._views.forEach((v1, idx) => {
        if (context.tempView !== v1.view) {
          if (!done) {
            const v1dist = this.distanceFunc(v1.view);
            const viewDist = this.distanceFunc(view);
            const tmpDist = this.distanceFunc(context.tempView);

            if (self) {
              if (v1dist.distance <= viewDist.coordinate - tmpDist.coordinate) {
                layout.layout(context.tempView, view.layoutProperties, idx);
                this.renderNode();
                context.idx = idx;
                done = true;
              } else {
                done = true;
              }
            } else if (v1dist.coordinate + 1 >= viewDist.coordinate) {
              layout.layout(context.tempView, undefined, idx);
              this.renderNode();
              context.idx = idx;
              done = true;
            }
          }
        } else {
          self = true;
        }
      });
    });
    dragView.on('dragend', () => {
      const context = layout._draggingContext;
      const view = layout._draggingControlView;
      if (!context || !view) {
        return;
      }
      layout.removeView(context.tempView);
      context.tempView.destroy();
      layout.layout(view, view.layoutProperties, context.idx);
      if (layout._layoutProps.onElementPositionChanged)
        layout._layoutProps.onElementPositionChanged(
          view,
          context.startIdx,
          context.idx,
        );
      if (this._dragView?.onElementPositionChanged) {
        this._dragView.onElementPositionChanged(
          view,
          context.startIdx,
          context.idx,
        );
      }
      context.started = false;
    });
    dragView.on('dragstart', () => {
      dragView.moveToTop();
      layout._draggingControlView?.moveToTop();
      layout._createDraggingContext();
    });
  }

  _createDraggingContext() {
    const layout = this;
    const draggingControlView = layout._draggingControlView;
    if (!draggingControlView) {
      return;
    }
    this._draggingContext = {
      started: false,
      tempView: new Rect({
        width: draggingControlView.width(),
        height: draggingControlView.height(),
        opacity: 0,
      }),
      height: draggingControlView.height(),
      width: draggingControlView.width(),
      startY: draggingControlView.y,
      startX: draggingControlView.x,
      idx: 0,
      startIdx: layout._views.findIndex((v) => v.view === draggingControlView),
    };
    if (this._dragView?._elementsDragHandler) {
      this._dragView._elementsDragHandler.dragFunc =
        this.dragFunc(draggingControlView);
    }
  }

  initDebugMode() {
    if (LS.getRaw(LSKey.flowBuilderDebug)) {
      this.on('pointerdown', (event: any) => {
        const { ctrlKey, metaKey } = event.data.originalEvent;
        if (!ctrlKey && !metaKey) {
          return;
        }
        event.stopPropagation();
        // eslint-disable-next-line no-console
        console.clear();
        const out = {
          view: this,
          blockId: '',
          cardId: '',
          pluginId: '',
          config: {},
        };
        const blockId =
          // @ts-ignore
          this._node?.block.id ||
          (findParentByCallback(this, ({ _node }: { _node: any }) => !!_node)
            ?._node?.block.id as Node);
        if (blockId) {
          out.blockId = blockId;
        }
        const card =
          // @ts-ignore
          this._card ||
          (findParentByCallback(this, ({ _card }: { _card: any }) => !!_card)
            ?._card as Card);
        if (card) {
          out.cardId = card.id;
          out.config = card.config;
          out.pluginId = card.plugin_id;
        }
        // eslint-disable-next-line no-console
        console.log(out);
        const { background } = this._layoutProps;
        this._layoutProps.background = {
          fill: HEXColors.white,
          ...background,
          strokeWidth: 3,
          stroke: HEXColors._00ff00,
          onhover: {
            strokeWidth: 3,
            stroke: HEXColors._00ff00,
          },
        };
        this.renderNode();
        setTimeout(() => {
          this._layoutProps.background = {
            strokeWidth: background?.strokeWidth || 0,
            ...background,
          };
          this.renderNode();
        }, 500);
      });
    }
  }

  changeLayoutBgProps(bgProps: BackgroundProps) {
    this.savedLayoutBackground = this._layoutProps.background;
    this._layoutProps.background = {
      ...this._layoutProps.background,
      ...bgProps,
    };
  }

  revertLayoutBgChanges() {
    if (this.savedLayoutBackground) {
      this._layoutProps.background = this.savedLayoutBackground;
      this.savedLayoutBackground = undefined;
    }
  }

  destroy() {
    if (this.wasDestroyed) {
      return;
    }
    this.wasDestroyed = true;
    this.stopAnimation();
    getPixiFieldStrict().hoverHandler().remove(this);
    getPixiFieldStrict().eventHandler().remove(this);
    getPixiFieldStrict().cursorHandler().removeCursor(this);
    this._views.forEach((view) => {
      view.view.destroy();
    });
    this._parentLayout = undefined;
    super.destroy(DESTROY_OPTIONS);
    this._background.destroy();
  }
}
