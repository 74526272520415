import React, { useEffect } from 'react';
import { lensPath, set } from 'ramda';
import { ParametersOperator, UserAttributeType } from '@globals';
import { getErrorMessage } from '../../plugins/GoToBlockPlugin/utils';
import { UserFilter } from '.';
import { ValidationErrors } from '../../../../../Plugins/common';
import {
  defaultParameter,
  emptyParameterForType,
  SEGMENTATION_PARAMETERS_STRING,
} from '../../../../../../common/services/Segmentation';
import { ReactComponent as AddIcon } from '../../../../../../modern-ui/_deprecated/Icon/icons/ic_add_small.svg';
import { Button } from '../../../../../../modern-ui/Button';
import { sendEvent } from '../../../../../../utils/Analytics';
import { UserFilterParameter } from './types';
import * as css from './UserFilterControl.css';

const parameterIndexLens = (i: number) => lensPath(['parameters', i]);

interface UserFilterConfig {
  valid: boolean | null;
  operation: ParametersOperator;
  parameters: (UserFilterParameter | null)[];
}

interface UserFilterControlProps<T> {
  botId: string;
  config: T | null;
  saveConfig: (config: T) => void;
  saveConfigAsync: (config: T) => Promise<unknown>;
  onConfigUpdate: (config: T) => void;
  isLoading: boolean;
  validationErrors: ValidationErrors;
  disabled?: boolean;
  hideButton?: boolean;
  hideDelete?: boolean;
  disableGenericFilter?: boolean;
}

export const UserFilterControl = <T extends UserFilterConfig>({
  botId,
  config,
  saveConfig,
  saveConfigAsync,
  isLoading,
  disabled,
  onConfigUpdate,
  validationErrors,
  hideButton,
  disableGenericFilter,
}: UserFilterControlProps<T>) => {
  const handleAddFilter = () => {
    sendEvent({
      category: 'flows',
      action: 'add attribute',
      label: 'condition',
    });
    const newConfig: T = {
      ...config!,
      __typename: 'Segmentation',
      operation: config?.operation || ParametersOperator.and,
      valid: true,
      parameters: (config?.parameters || []).concat({
        ...(defaultParameter() as unknown as UserFilterParameter),
        __typename: 'SegmentationParameter',
      }),
    };
    onConfigUpdate(newConfig);
  };

  const handleRemoveFilter = (index: number) => () => {
    sendEvent({
      category: 'flows',
      action: 'delete attribute',
      label: 'condition',
    });
    const newConfig: T = {
      ...config!,
      parameters: (config?.parameters || []).filter((_, idx) => idx !== index),
    };
    onConfigUpdate(newConfig);
  };

  const handleParameterUpdate =
    (index: number) => (updatedParam: UserFilterParameter) => {
      const obsoleteParam = config?.parameters?.[index];
      if (obsoleteParam) {
        /* eslint-disable no-param-reassign */
        const typeChanged = obsoleteParam?.type !== updatedParam.type;
        const attributeTypeChanged =
          typeChanged &&
          ((updatedParam.type === UserAttributeType.custom &&
            obsoleteParam.type === UserAttributeType.system) ||
            (updatedParam.type === UserAttributeType.system &&
              obsoleteParam.type === UserAttributeType.custom));

        if (typeChanged && !attributeTypeChanged) {
          const newParam: UserFilterParameter = {
            ...(emptyParameterForType(
              SEGMENTATION_PARAMETERS_STRING[
                updatedParam.type! as Exclude<
                  UserAttributeType,
                  UserAttributeType.compound
                >
              ],
            ) as unknown as UserFilterParameter),
          };

          onConfigUpdate(set(parameterIndexLens(index), newParam, config));
          return;
        }

        onConfigUpdate(set(parameterIndexLens(index), updatedParam, config));
      }
    };

  const handleOperatorUpdate = (operator: ParametersOperator) => {
    const newConfig: T = {
      ...config!,
      operation: operator,
    };
    onConfigUpdate(newConfig);
  };

  useEffect(() => {
    if (!config || !config.parameters || config.parameters.length === 0) {
      handleAddFilter();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {config?.parameters.filter(Boolean).map((param, index, arr) => (
        <UserFilter
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          botId={botId}
          parameter={param!}
          disabled={disabled}
          parameters={arr as UserFilterParameter[]}
          operator={config.operation!}
          savePlugin={() => saveConfig(config)}
          savePluginAsync={() => saveConfigAsync(config)}
          onRemoveFilter={handleRemoveFilter(index)}
          onParameterUpdate={handleParameterUpdate(index)}
          onOperatorUpdate={handleOperatorUpdate}
          invalid={!!getErrorMessage(param!, validationErrors)}
          disableGenericFilter={disableGenericFilter}
        />
      ))}
      {!hideButton && (
        <Button
          className={css.addFilterButton}
          intent="secondary"
          icon={<AddIcon />}
          size="m"
          onClick={handleAddFilter}
          disabled={isLoading || disabled}
          data-testid="user-filter-control__add-filter-button"
        />
      )}
    </>
  );
};
