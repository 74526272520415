import React, { useCallback, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { Type } from '@ui/Type';
import { Flex } from '@ui/Flex';
import { Input } from '@ui/Input';
import { TokenCounter } from '../TokenCounter/TokenCounter';
import css from './Condition.css';
import { customAiPluginFragment_config_aiConditions } from '../../../../../../../Plugins/CustomAiPlugin/@types/customAiPluginFragment';
import { Anchor } from '@ui/Links';
import { SupportedModels } from '../TokenCounter/useTokenCount';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { debounce } from 'lodash-es';
import { CHANGE_DEBOUNCE_TIMEOUT } from '../../consts';

export const Condition = ({
  onDelete,
  onUpdate,
  onBlur,
  title,
  prompt,
  model,
}: {
  onDelete: () => void;
  onUpdate: (
    condition: Partial<
      Pick<customAiPluginFragment_config_aiConditions, 'prompt' | 'title'>
    >,
  ) => void;
  onBlur: () => void;
  title: string;
  model: SupportedModels;
  prompt: string;
}) => {
  const { t } = useSafeTranslation();
  const [newPrompt, setNewPrompt] = useState(prompt);
  const [newTitle, setNewTitle] = useState(title);

  const debouncedUpdatePrompt = useCallback(
    debounce((prompt: string) => {
      onUpdate({ prompt });
    }, CHANGE_DEBOUNCE_TIMEOUT),
    [onUpdate],
  );

  const debouncedUpdateTitle = useCallback(
    debounce((title: string) => {
      onUpdate({ title });
    }, CHANGE_DEBOUNCE_TIMEOUT),
    [onUpdate],
  );

  return (
    <div className={css.condition}>
      <Flex gapFactor={1.5} flexDirection="row">
        <Type className={css.label} color="greyDark" size="12px">
          {t(
            'modernComponents.FlowBuilder.views.components.CustomAiPlugin.connectorLabel',
          )}
        </Type>

        <Input
          containerClassName={css.input}
          value={newTitle}
          data-testid="custom_ai_condition_title"
          onChange={(e) => {
            setNewTitle(e.target.value);
            debouncedUpdateTitle(e.target.value);
          }}
          onBlur={() => onBlur()}
        />
      </Flex>

      <Flex gapFactor={1.5} flexDirection="row">
        <Type className={css.label} color="greyDark" size="12px">
          {t(
            'modernComponents.FlowBuilder.views.components.CustomAiPlugin.condition',
          )}
        </Type>

        <Input
          containerClassName={css.input}
          render={({ getInputProps }) => (
            <TextareaAutosize
              data-testid="custom_ai_condition_prompt"
              {...getInputProps({
                className: css.textarea,
                value: newPrompt,
                onBlur: () => onBlur(),
              })}
              onChange={(e) => {
                setNewPrompt(e.target.value);
                debouncedUpdatePrompt(e.target.value);
              }}
            />
          )}
        />
      </Flex>

      <div className={css.footer}>
        <TokenCounter
          model={model}
          text={`${title ? `${title}: ` : ''}${prompt}`.trim()}
        />
        <Anchor
          onClick={() => onDelete()}
          size="small"
          className={css.delete}
          intent="external"
        >
          {t(
            'modernComponents.FlowBuilder.views.components.CustomAiPlugin.delete',
          )}
        </Anchor>
      </div>
    </div>
  );
};
