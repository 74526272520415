import { Graphics, Polygon } from 'pixi.js-legacy';
import { colorToHex } from '../../../views/utils';
import { Shape } from './Shape';
import { LineProps, Point, FlowArrowProps } from './types';

export class Line extends Shape<LineProps, Graphics> {
  TEST_NAME = 'Line';

  constructor(props: LineProps) {
    super({ end: { x: 0, y: 0 }, ...props }, Graphics);
  }

  end(p?: Point) {
    return this._changeProp<Point>('end', p);
  }

  _drawShape() {
    const { _props: props, _shape: shape } = this;
    shape.clear();
    shape.lineStyle(
      props.strokeWidth || 0,
      colorToHex(props.stroke),
      props.opacity,
    );
    shape.moveTo(0, 0);
    shape.lineTo(props.width || 0, props.height || 0);
  }
}

export class FlowLine<T extends LineProps = LineProps> extends Shape<
  T,
  Graphics
> {
  TEST_NAME = 'FlowLine';

  constructor(props: T) {
    super({ end: { x: 0, y: 0 }, ...props }, Graphics);
  }

  end(p?: Point) {
    return this._changeProp<Point>('end', p);
  }

  _drawShape() {
    const { _props: props, _shape: shape } = this;
    shape.clear();
    shape.lineStyle(
      props.strokeWidth || 0,
      colorToHex(props.stroke),
      props.opacity,
    );
    const x1 = (props.end?.x || 0) - (props.x || 0);
    const y1 = (props.end?.y || 0) - (props.y || 0);
    const x = 0;
    const y = 0;
    shape.moveTo(x, y);
    shape.bezierCurveTo((x1 - x) / 2 + x, y, (x1 - x) / 2 + x, y1, x1, y1);
    this.move();
  }
}

export class FlowArrow extends FlowLine<FlowArrowProps> {
  TEST_NAME = 'FlowArrow';

  constructor(props: FlowArrowProps) {
    super({
      startOffset: 40,
      arrow: {
        width: (props?.strokeWidth || 0) * 4,
        height: (props?.strokeWidth || 0) * 4,
      },
      ...props,
    });
  }

  lineToPolygon(distance: number, points: number[]) {
    const output = Array.from({ length: points.length * 2 }) as number[];
    const numPoints = points.length / 2;
    for (let i = 0; i < numPoints; i++) {
      const j = i * 2;

      const x = points[j];
      const y = points[j + 1];

      const x0 = points[j - 2] ?? x;
      const y0 = points[j - 1] ?? y;

      const x1 = points[j + 2] ?? x;
      const y1 = points[j + 3] ?? y;

      const a = Math.atan2(-x1 + x0, y1 - y0);
      const deltaX = distance * Math.cos(a);
      const deltaY = distance * Math.sin(a);

      output[j] = x + deltaX;
      output[j + 1] = y + deltaY;

      output[output.length - 1 - j - 1] = x - deltaX;
      output[output.length - 1 - j] = y - deltaY;
    }
    output.push(output[0], output[1]);

    return new Polygon(output);
  }

  _drawShape() {
    const { _props: props, _shape: shape } = this;
    const arrow = props.arrow ?? { width: 0, height: 0 };
    shape.clear();
    shape.lineStyle(
      props.strokeWidth || 0,
      colorToHex(props.stroke),
      props.opacity,
    );
    const x1 = (props.end?.x || 0) - arrow.width - (props.x || 0);
    const y1 = (props.end?.y || 0) - (props.y || 0);
    shape.interactive = true;
    shape.buttonMode = true;
    shape.moveTo(0, 0);
    shape.lineTo(props.startOffset || 0, 0);
    const bezier1X = Math.max(x1 / 2, 200);
    const bezier2X = Math.min(x1 / 2, x1 - 200);
    shape.bezierCurveTo(bezier1X, 0, bezier2X, y1, x1, y1);
    // draw outline
    if (props.createHit !== false) {
      const hitArea = this.lineToPolygon(
        (props.strokeWidth || 0) * 3,
        // @ts-ignore (protected field getting)
        shape.currentPath.points.concat(x1 + arrow.width, y1),
      );
      shape.lineStyle(1, 0xff0000);
      shape.hitArea = hitArea;
    }

    // draw arrow
    shape.beginFill(colorToHex(props.stroke), props.opacity);
    shape.lineStyle(0, 0xff0000, 1);
    shape.moveTo(x1, y1 - arrow.height / 2);
    shape.lineTo(x1 + arrow.width, y1);
    shape.lineTo(x1, y1 + arrow.height / 2);
    shape.lineTo(x1, y1 - arrow.height / 2);
    shape.endFill();
  }
}
