import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Attributes } from '@ui/TextWithAttributesEditor';
import { Icon } from '@ui/Icon';
import { Button } from '@ui/Button';
import { CenteredLoader } from '@ui/Loader';
import { FollowUp } from './FollowUp';
import * as css from './FollowUpsList.css';

export interface FollowUpItem {
  id: string;
  delay: number;
  text: string;
  statSent: string;
  statSeen: string;
  statClicked: string;
}

export interface FollowUpsListProps {
  items: FollowUpItem[];
  textAttributes: Attributes;
  isAddInProcess: boolean;
  onTimeChange(id: string, quantValue: number): void;
  onTextChange(id: string, text: string): void;
  onRemove(id: string): void;
  onAdd(): void;
  updateFollowUps(): void;
  renderNote?: () => React.ReactNode;
}

export const FollowUpsList: React.FC<FollowUpsListProps> = ({
  items,
  textAttributes,
  isAddInProcess,
  onTimeChange,
  onTextChange,
  onRemove,
  onAdd,
  updateFollowUps,
  renderNote,
}) => {
  const { t } = useSafeTranslation();

  return (
    <div>
      {renderNote?.()}
      <div>
        {items.map((followUp) => (
          <div className={css.listItem} key={followUp.id}>
            <FollowUp
              delay={followUp.delay}
              text={followUp.text}
              textAttributes={textAttributes}
              statSent={followUp.statSent}
              statSeen={followUp.statSeen}
              statClicked={followUp.statClicked}
              onTimeChange={(value) => {
                onTimeChange(followUp.id, value);
              }}
              onTextChange={(value) => {
                onTextChange(followUp.id, value);
              }}
              onRemove={() => {
                onRemove(followUp.id);
              }}
              updateFollowUps={updateFollowUps}
            />
          </div>
        ))}
      </div>
      {isAddInProcess ? (
        <CenteredLoader />
      ) : (
        <Button
          data-testid="follow-ups_add-button"
          intent="secondary"
          onClick={onAdd}
          icon={<Icon icon="plus" />}
        >
          {t('FollowUpsList-JSXText-1163-add-follow-up')}
        </Button>
      )}
    </div>
  );
};
