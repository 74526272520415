import React, { useCallback, useRef, useState, useEffect } from 'react';
import debounce from 'lodash-es/debounce';
import { Icon } from '@ui/Icon';
import { Input } from '@ui/Input';
import {
  SearchType,
  EnhancedFilterableSearchType,
  useFilterableSearch,
} from './context';
import { ActionButton } from './components/ActionButton';
import { FilterTypeMenu, FilterMenuItem } from './components/FilterTypeMenu';
import * as css from './FilterableSearchField.css';
import useResizeObserver from 'use-resize-observer';

export interface RenderFilterableInputConfig<TFilter> {
  preparedFilterRef: React.MutableRefObject<HTMLInputElement | null>;
  setSearchInputValue(value: string): void;
  setPreparedFilter: React.Dispatch<React.SetStateAction<TFilter | null>>;
  searchValue: string;
  comboboxStyles: object;
  inputWidth: string;
}

export interface CustomInputFilter<TFilter> extends FilterMenuItem<TFilter> {
  activationString: string;
  onFilterActivated?(): void;
  renderFilterableInput(
    preparedFilter: TFilter,
    config: RenderFilterableInputConfig<TFilter>,
  ): JSX.Element;
}

interface ActivationStringOnlyFilter<TFilter> {
  id: TFilter;
  activationString: string;
}

type FilterConfig<TFilter> =
  | CustomInputFilter<TFilter>
  | ActivationStringOnlyFilter<TFilter>;

export interface FilterableSearchFieldProps<TFilter> {
  children: FilterConfig<TFilter>[];
  disableSearchInput?: Boolean;
  searchPlaceholderText?: string;
  filterMenuTitle: string;
  width?: string;
  onPrepareFilter?(filter: TFilter): void;
  onSearchClick?(): void;
}

export const FilterableSearchField = <TFilter,>({
  filterMenuTitle,
  children,
  disableSearchInput,
  searchPlaceholderText,
  width: propsWidth,
  onPrepareFilter,
  onSearchClick,
}: React.PropsWithChildren<FilterableSearchFieldProps<TFilter>>) => {
  const { ref: containerRef, width: boxWidth } =
    useResizeObserver<HTMLDivElement>();
  const searchInputRef = useRef<HTMLInputElement>(null);
  const preparedFilterInputRef = useRef<HTMLInputElement>(null);
  const [preparedFilter, setPreparedFilter] = useState<TFilter | null>(null);
  const { type, value, setFilter, resetFilter } =
    useFilterableSearch<EnhancedFilterableSearchType<TFilter>>();
  const updateFilterDebounced = useCallback(debounce(setFilter, 300), [
    setFilter,
  ]);

  const setSearchInputValue = (value: string) => {
    if (searchInputRef.current) {
      searchInputRef.current.value = value;
    }
  };

  useEffect(() => {
    if (preparedFilter) {
      onPrepareFilter?.(preparedFilter);
      if (preparedFilterInputRef.current) {
        const filter = children.find(({ id }) => id === preparedFilter);

        if (!filter) {
          return;
        }

        preparedFilterInputRef.current.value = filter?.activationString!;
        (filter as CustomInputFilter<TFilter>).onFilterActivated?.();
        preparedFilterInputRef.current.focus();
      }
    } else if (value !== null) {
      searchInputRef.current?.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preparedFilter, children]);

  const width = propsWidth ?? '100%';
  const comboboxStyles = {
    menuboxStyle: { width: boxWidth },
    downshiftContainerStyle: { width },
  };

  const filterType = preparedFilter ?? type;
  const activeFilter = children.find((v) => v.id === filterType);
  if (activeFilter && 'renderFilterableInput' in activeFilter) {
    return (activeFilter as CustomInputFilter<TFilter>).renderFilterableInput(
      preparedFilter as TFilter,
      {
        preparedFilterRef: preparedFilterInputRef,
        setSearchInputValue,
        setPreparedFilter,
        searchValue: searchInputRef.current?.value ?? '',
        comboboxStyles,
        inputWidth: width,
      },
    );
  }

  return (
    <div ref={containerRef}>
      <FilterTypeMenu
        title={filterMenuTitle}
        items={(children as CustomInputFilter<TFilter>[]).filter(
          (v) => v.title,
        )}
        comboboxProps={comboboxStyles}
        onChooseFilter={(type) => {
          (activeFilter as CustomInputFilter<TFilter>)?.onFilterActivated?.();
          setPreparedFilter(type as TFilter);
        }}
      >
        {(comboboxProps) => {
          const { openMenu, closeMenu, isOpen } = comboboxProps;
          return (
            <Input
              renderIcon={() => <Icon icon="search" />}
              renderIconEnd={() => {
                if (!children.length) {
                  return undefined;
                }

                if (value) {
                  return (
                    <ActionButton
                      icon="close"
                      onClick={() => {
                        setSearchInputValue('');
                        resetFilter();
                      }}
                    />
                  );
                }
                return (
                  <ActionButton
                    icon="filter"
                    onClick={() => {
                      if (isOpen) {
                        closeMenu?.();
                      } else {
                        openMenu?.();
                      }
                    }}
                  />
                );
              }}
              render={(inputProps) => (
                <div className={css.inputContainer}>
                  <input
                    {...inputProps.getInputProps({
                      ref: searchInputRef,
                      defaultValue: preparedFilterInputRef.current?.value,
                      placeholder: searchPlaceholderText,
                      disabled: !!disableSearchInput,
                      onClick: onSearchClick,
                      onChange(event: any) {
                        const value = event.target.value as string;
                        const activatedFilter = children.find(
                          ({ activationString }) =>
                            value.startsWith(activationString),
                        ) as FilterConfig<TFilter>;
                        if (activatedFilter) {
                          if (preparedFilter !== activatedFilter.id) {
                            setPreparedFilter(activatedFilter.id);
                            (
                              activatedFilter as CustomInputFilter<TFilter>
                            ).onFilterActivated?.();
                          }
                          if (!('renderFilterableInput' in activatedFilter)) {
                            updateFilterDebounced({
                              type: activatedFilter.id,
                              value: value.slice(
                                activatedFilter.activationString.length,
                              ),
                              parameters: null,
                            });
                          }
                        } else {
                          updateFilterDebounced({
                            type: SearchType.search,
                            value,
                            parameters: null,
                          });

                          if (value.length > 0 && isOpen) {
                            closeMenu?.();
                          }
                        }
                      },
                    })}
                  />
                </div>
              )}
            />
          );
        }}
      </FilterTypeMenu>
    </div>
  );
};

interface FilterableBaseInputProps {
  showPreparedFilterInput: boolean;
  preparedFilterRef: React.MutableRefObject<HTMLInputElement | null>;
  renderFilter(): JSX.Element;
  onPreparedFilterChange(event: React.FormEvent<HTMLElement>): void;
  onControlClick(): void;
  defaultValue?: string;
  width?: string;
}

export const FilterableBaseInput: React.FC<FilterableBaseInputProps> = ({
  showPreparedFilterInput,
  preparedFilterRef,
  renderFilter,
  onPreparedFilterChange,
  onControlClick,
  defaultValue,
  width,
}) => (
  <Input
    width={width}
    onControlDecoratorClick={onControlClick}
    renderIcon={() => <Icon icon="search" />}
    render={(inputProps) => (
      <div className={css.inputContainer}>
        {showPreparedFilterInput ? (
          <input
            ref={preparedFilterRef}
            defaultValue={defaultValue}
            {...inputProps.getInputProps({
              onChange: onPreparedFilterChange,
            })}
          />
        ) : (
          renderFilter()
        )}
      </div>
    )}
  />
);
