import { useApolloClient, useMutation } from 'react-apollo';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useCurrentFlowId } from '@utils/Routing';
import { useWhatsappEnabled } from '@utils/Whatsapp';
import { useGlobalFollowUpsForCurrenFlow } from './useGlobalFollowUpsForCurrenFlow';
import { useFlowStats } from '@utils/Data/Flow/useFlowStats';
import {
  FOLLOW_UP_TEMPLATE1_DELAY,
  FOLLOW_UP_TEMPLATE2_DELAY,
  NEW_FOLLOW_UP_DEFAULT_DELAY,
  NEW_FOLLOW_UP_DEFAULT_TEXT,
} from '../consts';
import {
  AddFlowFollowUpMutation,
  AddFlowFollowUpMutationVariables,
} from '../../../../../queries/@types/AddFlowFollowUpMutation';
import {
  ADD_FLOW_FOLLOW_UP_MUTATION,
  EDIT_FLOW_FOLLOW_UP_MUTATION,
  FOLLOW_UPS_QUERY,
  REMOVE_FLOW_FOLLOW_UP_MUTATION,
} from '../../../../../queries/FollowUpQuery';
import { useCallback, useEffect, useRef } from 'react';
import {
  FollowUpsQuery,
  FollowUpsQueryVariables,
} from '../../../../../queries/@types/FollowUpsQuery';
import { prepareFollowUpsItems } from '../helpers';
import { sendEvent } from '@utils/Analytics';
import {
  RemoveFlowFollowUpMutation,
  RemoveFlowFollowUpMutationVariables,
} from '../../../../../queries/@types/RemoveFlowFollowUpMutation';
import {
  EditFlowFollowUpMutation,
  EditFlowFollowUpMutationVariables,
} from '../../../../../queries/@types/EditFlowFollowUpMutation';
import { debounce } from 'lodash-es';
import { FollowUpItem, FollowUpsListProps } from '../FollowUpsList';
import { Messages, toaster } from '@services/MessageService';

interface GlobalFollowUpsRes
  extends Omit<FollowUpsListProps, 'textAttributes'> {
  setupGlobalFollowUps: () => void;
  hasGlobalFollowUps: boolean;
  globalFollowUpsLoading: boolean;
}

export const useGlobalFollowUps = (): GlobalFollowUpsRes => {
  const flowId = useCurrentFlowId()!;
  const client = useApolloClient();
  const { isWhatsappEnabled } = useWhatsappEnabled();
  const { t } = useSafeTranslation();

  const FOLLOW_UP_TEMPLATE1_TEXT = isWhatsappEnabled
    ? t(
        'modernComponents.FlowBuilder.EditorPanel.settings.templateFirstWhatsapp',
      )
    : t('modernComponents.FlowBuilder.EditorPanel.settings.templateFirst');
  const FOLLOW_UP_TEMPLATE2_TEXT = isWhatsappEnabled
    ? t(
        'modernComponents.FlowBuilder.EditorPanel.settings.templateSecondWhatsapp',
      )
    : t('modernComponents.FlowBuilder.EditorPanel.settings.templateSecond');

  const { followUps, loading: globalFollowUpsLoading } =
    useGlobalFollowUpsForCurrenFlow();
  const { flowStats } = useFlowStats(flowId);

  const [addFollowUpMutation, { loading: isAddInProcess }] = useMutation<
    AddFlowFollowUpMutation,
    AddFlowFollowUpMutationVariables
  >(ADD_FLOW_FOLLOW_UP_MUTATION);

  const addNewFollowUp = useCallback(
    (text: string, delay: number) => {
      return addFollowUpMutation({
        variables: {
          flowId,
          text,
          delay,
        },
        update: (cache, { data: addedFollowUpData }) => {
          if (!addedFollowUpData || !followUps) {
            return;
          }

          const newFollowUpsData: FollowUpsQuery = {
            flowFollowUps: followUps,
          };
          const newFollowUp = Object.assign(
            {},
            addedFollowUpData.addFlowFollowUp,
            { stats: null },
          );
          newFollowUpsData.flowFollowUps.push(newFollowUp);
          cache.writeQuery({
            query: FOLLOW_UPS_QUERY,
            variables: { flowId },
            data: newFollowUpsData,
          });
        },
      });
    },
    [addFollowUpMutation, flowId, followUps],
  );

  const onAdd = () => {
    addNewFollowUp(NEW_FOLLOW_UP_DEFAULT_TEXT, NEW_FOLLOW_UP_DEFAULT_DELAY);
    sendEvent({
      category: 'flows',
      action: 'add follow up',
      propertyBag: {
        flowId,
      },
    });
  };

  const [removeFollowUpMutation] = useMutation<
    RemoveFlowFollowUpMutation,
    RemoveFlowFollowUpMutationVariables
  >(REMOVE_FLOW_FOLLOW_UP_MUTATION);

  const onRemove = (followUpId: string) => {
    removeFollowUpMutation({
      variables: {
        flowId,
        followUpId,
      },
      optimisticResponse: {
        removeFlowFollowUp: {
          __typename: 'RemoveResult',
          success: true,
        },
      },
      update: (cache, { data: removedFollowUpData }) => {
        if (
          !removedFollowUpData ||
          !removedFollowUpData.removeFlowFollowUp.success ||
          !followUps
        ) {
          return;
        }

        const newFollowUpsData: FollowUpsQuery = {
          flowFollowUps: followUps,
        };
        newFollowUpsData.flowFollowUps = newFollowUpsData.flowFollowUps.filter(
          (followUp) => followUp.id !== followUpId,
        );
        cache.writeQuery({
          query: FOLLOW_UPS_QUERY,
          variables: { flowId },
          data: newFollowUpsData,
        });
      },
    });

    sendEvent({
      category: 'flows',
      action: 'delete follow up',
      propertyBag: {
        flowId,
      },
    });
  };

  const [editFollowUpMutation] = useMutation<
    EditFlowFollowUpMutation,
    EditFlowFollowUpMutationVariables
  >(EDIT_FLOW_FOLLOW_UP_MUTATION);

  /**
   * Пришлось сделать через ref а не через state, из-за лишнего вызова хука
   * который сохраняет фоллоу апы при закрытие при нажатии на флоу билдер
   */
  const updatedFollowUpIds = useRef<Array<string>>([]);

  const editFollowUp = (followUpId: string, text: string, delay: number) => {
    const updatedFollowUps = followUps.map((followUp) =>
      followUp.id === followUpId
        ? {
            ...followUp,
            text,
            delay_ms: delay,
          }
        : followUp,
    );

    client.writeQuery<FollowUpsQuery, FollowUpsQueryVariables>({
      query: FOLLOW_UPS_QUERY,
      variables: { flowId },
      data: {
        flowFollowUps: updatedFollowUps,
      },
    });

    if (!updatedFollowUpIds.current.includes(followUpId)) {
      updatedFollowUpIds.current = [...updatedFollowUpIds.current, followUpId];
    }
  };

  const updateFollowUps = useCallback(
    debounce(() => {
      const followUps = client.readQuery<
        FollowUpsQuery,
        FollowUpsQueryVariables
      >({
        query: FOLLOW_UPS_QUERY,
        variables: {
          flowId,
        },
      });

      updatedFollowUpIds.current.forEach((followUpId) => {
        const followUp = followUps!.flowFollowUps.find(
          ({ id }) => id === followUpId,
        );

        if (!followUp) {
          return;
        }

        editFollowUpMutation({
          variables: {
            flowId,
            followUpId,
            text: followUp.text,
            delay: followUp.delay_ms,
          },
        });
      });
      if (updatedFollowUpIds.current.length) {
        updatedFollowUpIds.current = [];
      }
    }, 200),
    [client, editFollowUpMutation, flowId],
  );

  useEffect(() => updateFollowUps, [updateFollowUps]);

  const items: FollowUpItem[] = prepareFollowUpsItems(flowStats, followUps);

  const findFollowUpItem = (followUpId: string) => {
    return items.find((item) => item.id === followUpId);
  };

  const onTimeChange = (followUpId: string, quantValue: number) => {
    const followUpItem = findFollowUpItem(followUpId);
    if (followUpItem) {
      editFollowUp(followUpId, followUpItem.text, quantValue);
    }
  };

  const onTextChange = (followUpId: string, text: string) => {
    const followUpItem = findFollowUpItem(followUpId);
    if (followUpItem) {
      editFollowUp(followUpId, text, followUpItem.delay);
    }
  };

  const hasGlobalFollowUps = followUps.length > 0;

  const setupGlobalFollowUps = useCallback(() => {
    addNewFollowUp(FOLLOW_UP_TEMPLATE1_TEXT, FOLLOW_UP_TEMPLATE1_DELAY)
      .then(() =>
        addNewFollowUp(FOLLOW_UP_TEMPLATE2_TEXT, FOLLOW_UP_TEMPLATE2_DELAY),
      )
      .catch(() => {
        toaster.error({
          payload: {
            message: Messages.somethingWentWrong,
          },
        });
      });
  }, [addNewFollowUp, FOLLOW_UP_TEMPLATE1_TEXT, FOLLOW_UP_TEMPLATE2_TEXT]);

  return {
    items,
    isAddInProcess,
    onTimeChange,
    onTextChange,
    onRemove,
    onAdd,
    updateFollowUps,
    setupGlobalFollowUps,
    hasGlobalFollowUps,
    globalFollowUpsLoading,
  };
};
