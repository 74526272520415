import React from 'react';
import { Type } from '@ui/Type';
import {
  FullStatsConfig,
  getFullCounterStatsShared,
} from '../../../../../utils/Statistics/Statistics';
import { inboundLinksEntryPointFragment_config_flows as InboundLinksFlow } from '../../../../../../Plugins/InboundLinksEntryPoint/@types/inboundLinksEntryPointFragment';
import { getFlowsBlockHref, logInboundLinkEvent } from '../helpers';
import { InboundLink } from './InboundLink';

interface FlowsBlockProps extends InboundLinksFlow {
  botId: string;
  flowId: string;
  blockId: string;
  statsConfig: FullStatsConfig | undefined;
}

export const FlowsBlock: React.FC<FlowsBlockProps> = ({
  id: flowId,
  flowId: currentFlowId,
  botId,
  blockId: inboundLinksBlockId,
  blocks,
  statsConfig,
  title: flowTitle,
}) => (
  <>
    <Type size="15px" weight="semibold">
      {flowTitle}
    </Type>
    {blocks.map(({ id: blockId, card_id: cardId, counter_id, title }) => {
      const href = getFlowsBlockHref({ botId, flowId, blockId, cardId });
      const stats = statsConfig
        ? getFullCounterStatsShared(
            counter_id,
            inboundLinksBlockId,
            statsConfig,
          )
        : undefined;

      return (
        <InboundLink
          key={blockId}
          title={title}
          stats={stats}
          href={href}
          onOpenHref={() => {
            logInboundLinkEvent({
              botId,
              flowId: currentFlowId,
              channel: 'flows',
              source: 'leftPanel',
              href,
            });
          }}
        />
      );
    })}
  </>
);
