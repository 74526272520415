import React from 'react';
import { CheckBox } from '@ui/CheckBox';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import { Flex } from '@ui/Flex';
import { LoadingPlaceholder } from '@ui/LoadingPlaceholder';
import { useSafeTranslation } from '@utils/useSafeTranslation';

interface AllAttributesCheckboxProps {
  checked: boolean;
  loading: boolean;
  disabled: boolean;
  onChange(): void;
}

export const AllAttributesCheckbox: React.FC<AllAttributesCheckboxProps> = ({
  checked,
  loading,
  disabled,
  onChange,
}) => {
  const { t } = useSafeTranslation();

  if (loading) {
    return <LoadingPlaceholder fullWidth height={24} />;
  }

  return (
    <Flex alignItems="center" as="label">
      <CheckBox
        checked={checked}
        disabled={disabled}
        onChange={onChange}
        data-testid="google-sheets-editor-panel__send-all-attributes-checkbox"
      />
      <Spacer horizontalFactor={2} />
      <Type size="15px">
        {t(
          'modernComponents.FlowBuilder.plugins.GoogleSheetPlugin.allAttributesLabel',
        )}
      </Type>
    </Flex>
  );
};
