import React from 'react';
import { useQuery } from 'react-apollo';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Spacer } from '@ui/Spacer';
import { useIsFlowStatsEnabled } from '@utils/Bot/stats/useIsFlowStatsEnabled';
import { useCurrentFlowId } from '@utils/Routing';
import {
  hasAutomateBlocksToRender,
  hasFlowsToRender,
  hasBroadcastsToRender,
  getI18nInboundLinksKey,
} from './helpers';
import {
  AutomateBlock,
  BroadcastBlock,
  ChannelTitle,
  FlowsBlock,
} from './components';
import { PluginDataChildrenParams } from '../../../../../Plugins/common';
import { EditorPanelPluginProps } from '../../../types';
import { PluginHeader } from '../../common/PluginHeader';
import { FLOW_INBOUND_LINKS_DATA_QUERY } from '../../../../utils/Statistics/queries';
import {
  FlowInboundLinksDataQuery,
  FlowInboundLinksDataQueryVariables,
} from '../../../../utils/Statistics/@types/FlowInboundLinksDataQuery';
import { inboundLinksEntryPointFragment_config as InboundLinksEntryPointConfig } from '../../../../../Plugins/InboundLinksEntryPoint/@types/inboundLinksEntryPointFragment';

import { FullStatsConfig } from '../../../../utils/Statistics/Statistics';
import * as css from './InboundLinksEntryPoint.css';

export const InboundLinksEntryPoint: React.FC<
  EditorPanelPluginProps<InboundLinksEntryPointConfig>
> = ({ botId, pluginData, pluginParams }) => {
  const { blockId } = pluginData;

  return (
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    <InboundLinksEntryPointContent
      botId={botId}
      blockId={blockId}
      {...pluginParams}
    />
  );
};

interface InboundLinksEntryPointContentProps
  extends PluginDataChildrenParams<InboundLinksEntryPointConfig> {
  botId: string;
  blockId: string;
}

const InboundLinksEntryPointContent: React.FC<InboundLinksEntryPointContentProps> =
  ({ botId, blockId, pluginConfig: { blocks, broadcasts, flows } }) => {
    const { t } = useSafeTranslation();
    const flowId = useCurrentFlowId()!;
    const { isFlowStatsEnabled: statsEnabled } = useIsFlowStatsEnabled();

    const { data: inboundLinksData } = useQuery<
      FlowInboundLinksDataQuery,
      FlowInboundLinksDataQueryVariables
    >(FLOW_INBOUND_LINKS_DATA_QUERY, {
      variables: { botId, flowId, flowBlockId: flowId },
      skip: !statsEnabled,
    });

    const statsConfig: FullStatsConfig | undefined = statsEnabled
      ? {
          blocks: inboundLinksData?.bot.flowBlocks ?? [],
          uniqStats: inboundLinksData?.flowStats ?? undefined,
        }
      : undefined;

    return (
      <>
        <PluginHeader
          title={t(getI18nInboundLinksKey('header'))}
          tooltip={t(getI18nInboundLinksKey('tooltip'))}
        />
        {hasFlowsToRender(flows) && (
          <>
            <ChannelTitle
              title={t(getI18nInboundLinksKey('channel.flows'))}
              icon="flow"
            />
            <Spacer factor={2} />
            <div className={css.linksContainer}>
              {flows.map(
                (flow) =>
                  flow.blocks.length > 0 && (
                    <span className={css.block}>
                      <FlowsBlock
                        key={flow.id}
                        statsConfig={statsConfig}
                        botId={botId}
                        blockId={blockId}
                        flowId={flowId}
                        {...flow}
                      />
                    </span>
                  ),
              )}
            </div>
          </>
        )}
        <Spacer factor={4} />
        {hasAutomateBlocksToRender(blocks) && (
          <>
            <ChannelTitle
              title={t(getI18nInboundLinksKey('channel.blocks'))}
              icon="blocksChannel"
            />
            <Spacer factor={2} />
            <div className={css.linksContainer}>
              {blocks.map((block) => (
                <span className={css.block}>
                  <AutomateBlock
                    key={block.id}
                    statsConfig={statsConfig}
                    botId={botId}
                    blockId={blockId}
                    flowId={flowId}
                    {...block}
                  />
                </span>
              ))}
            </div>
          </>
        )}
        <Spacer factor={4} />
        {hasBroadcastsToRender(broadcasts) && (
          <>
            <ChannelTitle
              title={t(getI18nInboundLinksKey('channel.broadcasts'))}
              icon="reengageChannel"
            />
            <Spacer factor={2} />
            <div className={css.linksContainer}>
              {broadcasts.map(
                ({ id, block }) =>
                  block &&
                  Boolean(
                    block.buttons?.length || block?.quick_replies?.length,
                  ) && (
                    <span className={css.block}>
                      <BroadcastBlock
                        key={id}
                        statsConfig={statsConfig}
                        botId={botId}
                        blockId={blockId}
                        flowId={flowId}
                        broadcastId={id}
                        {...block}
                      />
                    </span>
                  ),
              )}
            </div>
          </>
        )}
      </>
    );
  };
