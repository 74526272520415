import React from 'react';
import { Anchor } from '@ui/Links';
import { sendEvent } from '@utils/Analytics';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { PluginHeader } from '../../../common/PluginHeader';
import { tooltips } from '../../../../../views/Menu/menu_view';

export const JsonPluginHeader = () => {
  const { t } = useSafeTranslation();

  return (
    <PluginHeader
      title={t('JSONPlugin-string--134-json-request')}
      tooltip={
        <>
          {t('JSONPlugin-JSXText-2103-use-the-json-api-to-send-or-receive')}
          <br />
          {t('JSONPlugin-JSXText-6286-information-between-your-backend')}
          <br />
          {t('JSONPlugin-JSXText-8523-and-your-chatbot')}{' '}
          <Anchor
            intent="tooltip"
            size="small"
            href={tooltips().json.url}
            target="_blank"
            rel="noopener noreferrer"
            onMouseDown={() => {
              sendEvent({
                category: 'flows',
                action: 'learn more',
                label: 'json',
              });
            }}
          >
            {t('JSONPlugin-JSXText-1076-learn-more')}
          </Anchor>
        </>
      }
    />
  );
};
