import { PluginDataChildrenParams } from '@components/Plugins/common';
import { whatsappTemplatePluginFragment_config } from '@components/Plugins/WhatsappTemplatePlugin/@types/whatsappTemplatePluginFragment';
import { Attributes } from '@ui/TextWithAttributesEditor';

export enum TokenType {
  attribute = 'attribute',
  text = 'text',
  newline = 'newline',
}

export type Token =
  | { type: TokenType.attribute; value: number }
  | { type: TokenType.text; value: string }
  | { type: TokenType.newline };

export interface AdditionalProps
  extends Pick<
    PluginDataChildrenParams<whatsappTemplatePluginFragment_config>,
    'pluginConfig' | 'setPluginConfigState'
  > {
  onAttributeInputBlur: () => void;
  attributes: Attributes;
  disabled?: boolean;
}
