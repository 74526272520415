import React, { useMemo } from 'react';
import { Spacer } from '@ui/Spacer';
import { SimpleCombobox, Item as BaseItem } from '@ui/SimpleCombobox';
import { Input } from '@ui/Input';
import { Icon } from '@ui/Icon';
import { MenuItem } from '@ui/Menu';
import { ItemImage } from '@ui/NestedMenu/components/ItemImage';
import { PluginControlLabel } from '@components/Plugins/common/PluginControlLabel';
import { LoadingPlaceholder } from '@ui/LoadingPlaceholder';

export interface Item extends BaseItem {
  imageUrl?: string | null;
}

interface GoogleSheetsComboboxProps {
  items: Item[];
  selectedItemId?: string | null;
  label: string;
  placeholder: string;
  error?: boolean;
  addButtonTitle?: string;
  loading?: boolean;
  disabled?: boolean;
  onSelect(item: Item): void;
  onAdd?(): void;
}

const ADD_BUTTON_ID = 'addButton';

export const GoogleSheetCombobox: React.FC<GoogleSheetsComboboxProps> = ({
  items,
  selectedItemId,
  label,
  placeholder,
  error,
  addButtonTitle,
  loading,
  disabled,
  onAdd,
  onSelect,
}) => {
  const comboboxItems: Item[] = useMemo(() => {
    if (!addButtonTitle) return items;

    const connectAccountButton: Item = {
      id: ADD_BUTTON_ID,
      title: addButtonTitle,
    };

    return [connectAccountButton, ...items];
  }, [items, addButtonTitle]);

  const selectedItem = useMemo(() => {
    return items.find((item) => item.id === selectedItemId) ?? null;
  }, [items, selectedItemId]);

  const renderInput = (id: string) => {
    if (loading) return <LoadingPlaceholder fullWidth height={36} />;

    return (
      <SimpleCombobox
        items={comboboxItems}
        selectedItem={selectedItem}
        onChange={(item, { reset }) => {
          if (!item) return;

          if (item.id === ADD_BUTTON_ID) {
            reset();
            onAdd?.();
          } else if (item.id !== selectedItemId) {
            onSelect(item);
          }
        }}
        menuboxStyle={{
          minWidth: 350,
        }}
        renderInput={({ getInputProps, openMenu, isOpen, clearSelection }) => (
          <Input
            {...getInputProps({
              placeholder,
              disabled,
              onChange: () => clearSelection(),
            })}
            renderIconEnd={() => (
              <Icon icon="triangle" size="24px" onClick={openMenu} />
            )}
            error={error}
            onClick={() => !isOpen && openMenu()}
            autoComplete="off"
            id={id}
          />
        )}
        renderItem={({ item, index, highlightedIndex, getItemProps }) => {
          return (
            <MenuItem
              key={item.id}
              {...getItemProps({ item })}
              data-testid={`menu-item-${item.id}`}
              leftElement={
                item.imageUrl && (
                  <ItemImage id={item.id} imageUrl={item.imageUrl} />
                )
              }
              title={item.title}
              active={index === highlightedIndex}
            />
          );
        }}
      />
    );
  };

  return (
    <PluginControlLabel label={label}>
      {({ id }) => (
        <>
          <Spacer factor={2} />
          {renderInput(id)}
        </>
      )}
    </PluginControlLabel>
  );
};
