import { HEXColors } from '@ui/_common/colors';
import { sendEvent } from '@utils/Analytics';
import {
  getServerStorageObservable,
  serverStorageItemSet,
} from '@utils/ServerStorage';
import i18next from 'i18next';
import { Subscription } from 'rxjs';
import { PluginType } from '../../../Plugins/common/PluginTypes';
import { Node } from '../../Node';
import {
  getFlowControllerStrict,
  getPixiFieldStrict,
} from '../../PixiFieldRepository';
import {
  blockControlViewEmitter,
  BlockControlViewEmitterEvent,
  BlockControlViewEmitterEventPayload,
} from '../../views/block_control_view_emitter';
import { getEntryPointCard } from '../../views/entry-points/common/utils';
import { blockWidth } from '../../views/plugin_consts';
import { HLayout, MainLayout } from '../Elements/Layouts';
import { HTMLText, RightArrow } from '../Elements/Shapes';
import { Cross } from '../Elements/Shapes/Cross';
import { WATCH_VIDEO_CONFIGS_BY_ENTRY_POINT } from './consts';

interface WatchVideoButtonViewProps {
  node: Node;
  onClick: (params: { pluginType: PluginType; blockId: string }) => void;
  onCloseClick: (params: { pluginType: PluginType; blockId: string }) => void;
}

const HOVER_EVENT_ID = 'WatchVideoButtonView';

export class WatchVideoButtonView extends MainLayout {
  private readonly buttonView: HLayout;
  private readonly closeButtonView: HLayout;
  private isShow: boolean = false;
  private isBlockControlsShowed: boolean = false;
  private blockEventsSubscriptions: Function[];
  private readonly serverStorageSubscription: Subscription | undefined;

  constructor({
    node: {
      block: { cards, id: blockId },
    },
    onClick,
    onCloseClick,
  }: WatchVideoButtonViewProps) {
    super({
      width: blockWidth,
    });

    const pluginType = getEntryPointCard(cards)?.plugin_id;
    const serverStorageKey =
      WATCH_VIDEO_CONFIGS_BY_ENTRY_POINT[pluginType || '']?.serverStorageKey;

    const box = new HLayout({
      height: 28,
      background: {
        fill: HEXColors.greyLight10,
        cornerRadius: 14,
      },
    });

    this.addToLayout(box, {
      margin: {
        left: 80,
      },
      gone: () => {
        return !this.isShow || this.isBlockControlsShowed;
      },
    });

    this.buttonView = new HLayout({
      height: 28,
      background: {
        fill: HEXColors.white,
        cornerRadius: 14,
        opacity: 0,
      },
      cursor: { in: 'pointer' },
    });

    this.closeButtonView = new MainLayout({
      width: 24,
      height: 28,
      background: {
        fill: HEXColors.white,
        opacity: 0,
      },
      cursor: { in: 'pointer' },
    });

    box.addToLayout(this.buttonView);

    const triangleView = new RightArrow({
      width: 11,
      height: 13,
      fill: HEXColors.black,
    });

    const titleView = new HTMLText({
      fontSize: 15,
      fontStyle: 'semibold',
      fill: HEXColors.black,
      text: i18next.t('tutorial.FlowTutorial.EPTutorial'),
      trustedHtml: true,
    });

    const crossView = new Cross({
      width: 9,
      height: 9,
      stroke: HEXColors.black,
    });

    this.closeButtonView.addToLayout(crossView, {
      verticalAlign: 'center',
      align: 'center',
    });

    this.buttonView.addToLayout(triangleView, {
      margin: {
        left: 20,
        right: 4,
        top: 14,
      },
    });

    this.buttonView.addToLayout(titleView, {
      margin: {
        right: 9,
        top: 3,
      },
    });

    box.addToLayout(this.closeButtonView, {
      margin: {
        right: 13,
        top: 0,
      },
    });

    this.buttonView.on('click', () => {
      if (pluginType) {
        onClick({
          pluginType,
          blockId,
        });
      }
    });

    this.closeButtonView.on('click', () => {
      this.isShow = false;
      if (serverStorageKey) {
        serverStorageItemSet(serverStorageKey, true);
      }
      if (pluginType) {
        onCloseClick({
          pluginType,
          blockId,
        });
      }
      sendEvent({
        category: 'flow',
        action: 'close watch tutorial button click',
        label: pluginType,
        propertyBag: {
          flowId: getFlowControllerStrict().flow.id,
          blockId,
        },
      });
      this.renderElement();
    });

    const hoverHandler = getPixiFieldStrict().hoverHandler();

    hoverHandler.subscribe({
      view: this.buttonView,
      eventId: HOVER_EVENT_ID,
      propagate: false,
      over: () => {
        titleView.fill(HEXColors.blue);
        triangleView.fill(HEXColors.blue);
        this.renderElement();
      },
      out: () => {
        titleView.fill(HEXColors.black);
        triangleView.fill(HEXColors.black);
        this.renderElement();
      },
    });

    hoverHandler.subscribe({
      view: this.closeButtonView,
      eventId: HOVER_EVENT_ID,
      propagate: false,
      over: () => {
        crossView.stroke(HEXColors.blue);
        this.renderElement();
      },
      out: () => {
        crossView.stroke(HEXColors.black);
        this.renderElement();
      },
    });

    this.blockEventsSubscriptions = [
      blockControlViewEmitter.on<BlockControlViewEmitterEventPayload>(
        BlockControlViewEmitterEvent.show,
        ({ blockId: id }) => {
          if (id === blockId) {
            this.isBlockControlsShowed = true;
            this.renderElement();
          }
        },
      ),
      blockControlViewEmitter.on<BlockControlViewEmitterEventPayload>(
        BlockControlViewEmitterEvent.hide,
        ({ blockId: id }) => {
          if (id === blockId) {
            this.isBlockControlsShowed = false;
            this.renderElement();
          }
        },
      ),
    ];

    if (serverStorageKey && !getPixiFieldStrict().isViewOnly()) {
      this.serverStorageSubscription = getServerStorageObservable(
        WATCH_VIDEO_CONFIGS_BY_ENTRY_POINT[pluginType || '']?.serverStorageKey,
      ).subscribe((isClosed) => {
        this.isShow = !isClosed;
        this.renderElement();
      });
    }
  }

  destroy() {
    const hoverHandler = getPixiFieldStrict().hoverHandler();
    hoverHandler.unsubscribe(this.buttonView, HOVER_EVENT_ID);
    hoverHandler.unsubscribe(this.closeButtonView, HOVER_EVENT_ID);
    this.serverStorageSubscription?.unsubscribe();
    this.blockEventsSubscriptions.forEach((fn) => {
      fn();
    });
    super.destroy();
  }
}
