import { useEffect, useRef, useState } from 'react';
import { calculateTokenizedLength } from '@utils/calculateTokenizedLength';
import debounce from 'lodash-es/debounce';

export enum SupportedModels {
  OPENAI = 'open_ai',
}

export const getTokenizerByModel = (
  model: SupportedModels,
): ((prompt: string) => number) => {
  switch (model) {
    case SupportedModels.OPENAI:
    default:
      return calculateTokenizedLength;
  }
};

export const useTokenCount = (text: string, model: SupportedModels) => {
  const [count, setCount] = useState(0);
  const [calculatedText, setCalculatedText] = useState('');
  const debounceUpdateRef = useRef(
    debounce((newText: string) => {
      const tokenizer = getTokenizerByModel(model);
      const newCount = tokenizer(newText.trim());
      setCount(newCount);
      setCalculatedText(newText);
    }, 500),
  );

  useEffect(() => {
    const debounceUpdate = debounceUpdateRef.current;
    debounceUpdate(text);
    return () => debounceUpdate.cancel();
  }, [text]);

  return { calculatedText, count, loading: calculatedText !== text };
};
