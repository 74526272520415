import React, { useState } from 'react';
import debounce from 'lodash-es/debounce';
import copyToClipboard from 'clipboard-copy';
import { removeExtraSpaces } from '@utils/String/removeExraSpaces';
import { Spacer } from '@ui/Spacer';
import { CenteredLoader } from '@ui/Loader';
import { Flex } from '@ui/Flex';
import { Button } from '@ui/Button';
import { Anchor } from '@ui/Links';
import { usePageConnected } from '@utils/FacebookPages/usePageConnected';
import { sendEvent } from '@utils/Analytics';
import { EditorPanelPluginProps } from '../../../types';
import { PluginType } from '../../../../types';
import { PluginDataChildrenParams } from '../../../../../Plugins/common';
import { PluginHeader } from '../../common/PluginHeader';
import { tooltips } from '../../../../views/Menu/menu_view';
import { sendToMessengerEntryPointFragment_config as SendToMessengerEntryPointConfig } from '../../../../../Plugins/SendToMessengerEntryPoint/@types/sendToMessengerEntryPointFragment';
import { PluginControlLabel } from '../../../../../Plugins/common/PluginControlLabel';
import { SimpleCombobox } from '@ui/SimpleCombobox';
import { ReactComponent as DropdownIcon } from '../../../../../../modern-ui/_deprecated/Icon/icons/ic_dropdown_arr.svg';
import { SendToMessengerColor, SendToMessengerSize } from '@globals';
import * as css from './SendToMessengerEntryPoint.css';
import { FBPluginCodeInstructionsPopup } from '../common/FBPluginCodeInstructionsPopup';
import { getEntryPointClientCode } from '../common/getEntryPointClientCode';
import { DomainsWhitelisting } from '../../../../../DomainsWhitelisting';
import { NeedConnectPageNote } from '../common/NeedConnectPageNote';
import { BUTTON_COLORS, BUTTON_SIZE, getCTATexts } from './consts';

import {
  useCopiedToaster,
  CodeTextArea,
} from '../../../../../../modern-ui/CodeTextArea';

const sendEventDebounced = debounce(sendEvent, 300);

export const SendToMessengerEntryPoint: React.FC<
  EditorPanelPluginProps<SendToMessengerEntryPointConfig>
> = ({ botId, pluginData, pluginParams }) => {
  const { blockId, pluginId } = pluginData;
  const { pageId, loading } = usePageConnected(botId);

  if (loading) {
    return <CenteredLoader />;
  }

  return (
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    <SendToMessengerEntryPointContent
      botId={botId}
      blockId={blockId}
      pluginId={pluginId}
      pageId={pageId || undefined}
      {...pluginParams}
    />
  );
};

interface SendToMessengerEntryPointContentProps
  extends PluginDataChildrenParams<SendToMessengerEntryPointConfig> {
  botId: string;
  blockId: string;
  pluginId: string;
  pageId: string | undefined;
}

const SendToMessengerEntryPointContent: React.FC<SendToMessengerEntryPointContentProps> =
  ({ pluginConfig, savePlugin, setPluginConfigState, blockId, pageId }) => {
    const [showCodePopup, setShowCodePopup] = useState<boolean>(false);
    const showCopiedToaster = useCopiedToaster();

    const code = getEntryPointClientCode(blockId);

    const miniCode = removeExtraSpaces(code, ' ');

    return (
      <>
        <PluginHeader
          title={window.i18next.t(
            'SendToMessengerEntryPoint-string-1783-send-to-messenger-settings',
          )}
          tooltip={
            <Anchor
              noUnderline
              intent="unstyled"
              href={tooltips()[PluginType.send_to_messenger_entry_point].url}
              target="_blank"
              style={{
                color: 'var(--white)',
              }}
              onClick={() => {
                sendEvent({
                  category: 'flows',
                  action: 'click to tooltip (learn more)',
                  label: PluginType.send_to_messenger_entry_point,
                });
              }}
              dangerouslySetInnerHTML={{
                __html: tooltips()[PluginType.send_to_messenger_entry_point]
                  .html as string,
              }}
            />
          }
        />

        {!pageId && <NeedConnectPageNote />}

        <PluginControlLabel
          label={
            <Flex
              alignItems="center"
              justifyContent="space-between"
              style={{
                width: 302,
              }}
            >
              {window.i18next.t(
                'SendToMessengerEntryPoint-JSXText--211-code-to-insert-on-your-website',
              )}
            </Flex>
          }
        >
          {(bind) => (
            <CodeTextArea
              rows={5}
              value={code}
              onCopy={() => {
                sendEvent({
                  category: 'flows',
                  action: 'code copied from textarea',
                  label: PluginType.send_to_messenger_entry_point,
                });
              }}
              {...bind}
            />
          )}
        </PluginControlLabel>

        <Spacer factor={2} />

        <Flex>
          <Button
            intent="secondary"
            style={{
              marginRight: 8,
            }}
            onClick={() => {
              copyToClipboard(miniCode);
              showCopiedToaster();
              sendEvent({
                category: 'flows',
                action: 'code copied by button click',
                label: PluginType.send_to_messenger_entry_point,
              });
            }}
          >
            {window.i18next.t(
              'SendToMessengerEntryPoint-JSXText-9361-copy-code',
            )}
          </Button>
          <Button
            intent="text"
            onClick={() => {
              setShowCodePopup(true);
              sendEvent({
                category: 'flows',
                action: 'show instructions popup',
                label: PluginType.send_to_messenger_entry_point,
              });
            }}
          >
            {window.i18next.t(
              'SendToMessengerEntryPoint-JSXText-8069-see-instructions',
            )}
          </Button>
        </Flex>

        <Spacer factor={6} />
        <PluginControlLabel
          label={window.i18next.t(
            'SendToMessengerEntryPoint-string-1535-website-domain',
          )}
        >
          {(bind) => (
            <DomainsWhitelisting
              value={pluginConfig.domains?.[0] || ''}
              onChange={(domain) => {
                sendEventDebounced({
                  category: 'flows',
                  action: 'change domain',
                  label: PluginType.send_to_messenger_entry_point,
                  propertyBag: {
                    domain: [domain],
                  },
                });

                setPluginConfigState({
                  config: {
                    ...pluginConfig,
                    domains: [domain],
                  },
                });
              }}
              onSelect={() => {
                savePlugin();
              }}
              onBlur={() => {
                savePlugin();
              }}
              {...bind}
            />
          )}
        </PluginControlLabel>
        <Spacer factor={6} />
        <Flex justifyContent="space-between">
          <PluginControlLabel
            label={window.i18next.t(
              'SendToMessengerEntryPoint-string--149-button-size',
            )}
          >
            {(bind) => (
              <SimpleCombobox
                items={BUTTON_SIZE}
                selectedItem={BUTTON_SIZE.find(
                  ({ id }) =>
                    id === (pluginConfig.size || SendToMessengerSize.standard),
                )}
                onChange={(item) => {
                  setPluginConfigState({
                    config: {
                      ...pluginConfig,
                      size:
                        (item?.id as SendToMessengerSize) ||
                        SendToMessengerSize.standard,
                    },
                  });
                  savePlugin();
                  sendEvent({
                    category: 'flows',
                    action: 'change size',
                    label: PluginType.send_to_messenger_entry_point,
                    propertyBag: {
                      size: item?.id || SendToMessengerSize.standard,
                    },
                  });
                }}
                renderInput={({ getToggleButtonProps, selectedItem }) => (
                  <Button
                    intent="secondary"
                    {...getToggleButtonProps()}
                    iconRight={<DropdownIcon />}
                    className={css.ddButton}
                    {...bind}
                  >
                    {selectedItem?.title}
                  </Button>
                )}
                menuItemStyle={{
                  minWidth: 146,
                }}
              />
            )}
          </PluginControlLabel>
          <PluginControlLabel
            label={window.i18next.t(
              'SendToMessengerEntryPoint-string-8022-style',
            )}
          >
            {(bind) => (
              <SimpleCombobox
                items={BUTTON_COLORS}
                selectedItem={BUTTON_COLORS.find(
                  ({ id }) =>
                    id === (pluginConfig.color || SendToMessengerColor.blue),
                )}
                onChange={(item) => {
                  setPluginConfigState({
                    config: {
                      ...pluginConfig,
                      color:
                        (item?.id as SendToMessengerColor) ||
                        SendToMessengerColor.blue,
                    },
                  });
                  savePlugin();
                  sendEvent({
                    category: 'flows',
                    action: 'change color',
                    label: PluginType.send_to_messenger_entry_point,
                    propertyBag: {
                      color: item?.id || SendToMessengerColor.blue,
                    },
                  });
                }}
                renderInput={({ getToggleButtonProps, selectedItem }) => (
                  <Button
                    intent="secondary"
                    {...getToggleButtonProps()}
                    iconRight={<DropdownIcon />}
                    className={css.ddButton}
                    {...bind}
                  >
                    {selectedItem?.title}
                  </Button>
                )}
                menuItemStyle={{
                  minWidth: 146,
                }}
              />
            )}
          </PluginControlLabel>
        </Flex>

        <Spacer factor={6} />

        <PluginControlLabel
          label={window.i18next.t(
            'SendToMessengerEntryPoint-string--149-button-text',
          )}
        >
          {(bind) => (
            <SimpleCombobox
              items={getCTATexts()}
              selectedItem={
                getCTATexts().find(({ id }) => id === pluginConfig.cta_text) ||
                getCTATexts()[0]
              }
              onChange={(item) => {
                setPluginConfigState({
                  config: {
                    ...pluginConfig,
                    cta_text: item?.id || null,
                  },
                });
                savePlugin();
                sendEvent({
                  category: 'flows',
                  action: 'change cta_text',
                  label: PluginType.send_to_messenger_entry_point,
                  propertyBag: {
                    cta_text: item?.id || '',
                  },
                });
              }}
              renderInput={({ getToggleButtonProps, selectedItem }) => (
                <Button
                  intent="secondary"
                  {...getToggleButtonProps()}
                  iconRight={<DropdownIcon />}
                  className={css.ddButton}
                  style={{ width: 302 }}
                  {...bind}
                >
                  {selectedItem?.title}
                </Button>
              )}
            />
          )}
        </PluginControlLabel>

        {showCodePopup && (
          <FBPluginCodeInstructionsPopup
            onRequestClose={() => {
              setShowCodePopup(false);
            }}
            onCopy={(reason) => {
              sendEvent({
                category: 'flows',
                action: `code copied from popup ${reason}`,
                label: PluginType.send_to_messenger_entry_point,
              });
            }}
            code={code}
            text={
              <>
                {/* eslint-disable-next-line react/jsx-curly-brace-presence */}
                {window.i18next.t(
                  'SendToMessengerEntryPoint-string--192-copy-the-code-and-paste-it-into-your-websites-source-where-you-want-the-button-to-appear-learn-more',
                )}
                <Anchor
                  hideArrow
                  intent="external"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://developers.facebook.com/docs/messenger-platform/discovery/send-to-messenger-plugin/"
                >
                  {window.i18next.t(
                    'SendToMessengerEntryPoint-JSXText-8695-send-to-messenger-plugin-reference',
                  )}
                </Anchor>
                .
              </>
            }
          />
        )}
      </>
    );
  };
