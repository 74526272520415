import { PluginType } from './types';
import { getPluginMenuItem } from './views/Menu/menu_view';
import { typingPluginModule, assignAdminPluginModule } from './modules';
import { IModule, Module } from './Module';

export class FlowBuilderModule extends Module {
  constructor(modules?: ReadonlyArray<IModule>) {
    super({
      plugins: modules?.flatMap((m) => m.getPlugins()) || [],
    });
  }

  // TODO вынести в отдельный класс FlowBuilderMenu
  public getContentBlocksMenuCreators() {
    const typingPlugin = this.hasPlugin(PluginType.typing)
      ? (x: any) => this.getPlugin(PluginType.typing).getMenuItem(x)
      : () => undefined;

    return [
      getPluginMenuItem.text,
      getPluginMenuItem.textAndButtons,
      typingPlugin,
      getPluginMenuItem.image,
      getPluginMenuItem.video,
      getPluginMenuItem.audio,
      getPluginMenuItem.document,
      getPluginMenuItem.mediaShare,
      getPluginMenuItem[PluginType.whatsapp_list],
      getPluginMenuItem[PluginType.whatsapp_location],
      getPluginMenuItem[PluginType.whatsapp_template],
      getPluginMenuItem.gallery,
      getPluginMenuItem.facebookShopProducts,
    ];
  }

  // TODO вынести в отдельный класс FlowBuilderMenu
  public getContentPluginsCreators() {
    const typingPlugin = this.hasPlugin(PluginType.typing)
      ? (x: any) => this.getPlugin(PluginType.typing).getMenuItem(x)
      : () => undefined;

    return [
      getPluginMenuItem.text,
      getPluginMenuItem.textAndButtons,
      typingPlugin,
      getPluginMenuItem.image,
      getPluginMenuItem.video,
      getPluginMenuItem.audio,
      getPluginMenuItem.document,
      getPluginMenuItem.mediaShare,
      getPluginMenuItem.otn,
      getPluginMenuItem.nTNDaily,
      getPluginMenuItem.nTNWeekly,
      getPluginMenuItem.nTNMonthly,
      getPluginMenuItem[PluginType.whatsapp_list],
      getPluginMenuItem[PluginType.whatsapp_location],
      getPluginMenuItem[PluginType.whatsapp_template],
      getPluginMenuItem.gallery,
      getPluginMenuItem.facebookShopProducts,
    ];
  }

  public getActionItems() {
    const assignPluginMenuItem = this.hasPlugin(
      PluginType.conversation_assign_admin,
    )
      ? (x: any) =>
          this.getPlugin(PluginType.conversation_assign_admin).getMenuItem(x)
      : () => undefined;

    return [
      getPluginMenuItem.addAttribute,
      getPluginMenuItem.removeAttribute,
      getPluginMenuItem.json,
      assignPluginMenuItem,
      getPluginMenuItem.email,
      getPluginMenuItem.liveChat,
    ];
  }
}

export const flowBuilderModule = new FlowBuilderModule([
  typingPluginModule,
  assignAdminPluginModule,
]);

window.flowBuilderModule = flowBuilderModule;
