import React from 'react';
import { Flex } from '@ui/Flex';
import { Icon } from '@ui/Icon';
import { Spacer } from '@ui/Spacer';
import { sendEvent } from '@utils/Analytics';
import { Type } from '@ui/Type';
import { Tooltip2 } from '@ui/Tooltip2';
import {
  deserialize,
  TextWithAttributesEditor,
} from '@ui/TextWithAttributesEditor';
import { AttributesQuery_bot_variableSuggest } from '@utils/AttributesUtils/@types/AttributesQuery';
import { TokenCounter } from '../TokenCounter/TokenCounter';
import { Input } from '@ui/Input';
import css from './TextWithAttributeEditor.css';
import { OnboardingEmitter } from '@components/Onboarding/OnboardingTour/utils/onboardingEmitter';
import { OnboardingTourEventType } from '@globals';

interface TextWithAttributeEditorProps {
  botId: string;
  update(newValue: string): void;
  updateSafely(newValue: string): void;
  availableAttributes: AttributesQuery_bot_variableSuggest[];
  title: string;
  text: string;
  placeholder?: string;
  tooltip: React.ReactNode;
  inputContainerId?: string;
}

export const TextWithAttributeEditor: React.FC<TextWithAttributeEditorProps> =
  ({
    botId,
    title,
    text,
    update,
    updateSafely,
    availableAttributes,
    placeholder,
    tooltip,
    inputContainerId,
  }) => {
    return (
      <div>
        <Flex flexDirection="row" alignItems="center">
          <Type size="15px" weight="medium" as="span">
            {title}
          </Type>
          <Spacer horizontalFactor={1.5} />
          <Tooltip2
            placement="right"
            boundariesElement="viewport"
            type="small"
            content={
              <Type size="12px" color="white">
                {tooltip}
              </Type>
            }
            positionFixed
            hoverable
          >
            {(ref, bind) => (
              <Icon
                data-testid="openai_plugin-additional_instructions_tooltip"
                icon="help"
                color="blue"
                ref={ref}
                {...bind}
              />
            )}
          </Tooltip2>
          <TokenCounter className={css.counter} text={text ?? ''} />
        </Flex>
        <Spacer factor={2} />
        <Input
          inputContainerId={inputContainerId}
          data-testid="open_ai_add_intent_button"
          render={() => (
            <TextWithAttributesEditor
              className={css.textAreaContainer}
              defaultValue={deserialize(text || '')}
              onStringChange={(value) => {
                update(value);
                if (inputContainerId)
                  OnboardingEmitter.emit(OnboardingTourEventType.change, {
                    element: inputContainerId,
                    value,
                  });
              }}
              onBlur={(_, body: string) => {
                updateSafely(body);
                sendEvent({
                  category: 'open ai plugin',
                  action: 'change prompt',
                  propertyBag: {
                    botId,
                    prompt: body,
                  },
                });
              }}
              placeholder={placeholder}
              attributes={availableAttributes}
              trimOnBlur
              hasManageAttributes
              data-testid="open_ai_additional_instructions_input"
            />
          )}
        />
      </div>
    );
  };
