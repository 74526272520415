import React from 'react';
import {
  ExternalAccountDropdown,
  ExternalAccountDropdownProps,
} from '@utils/Integrations/common/ExternalAccountDropdown/ExternalAccountDropdown';
import { OverlayProps } from '../../types';

export const ExternalAccount = React.forwardRef<HTMLDivElement, OverlayProps>(
  ({ options: externalAccountDropdownProps }, outerRef) => (
    <ExternalAccountDropdown
      ref={outerRef}
      {...(externalAccountDropdownProps as ExternalAccountDropdownProps)}
    />
  ),
);
