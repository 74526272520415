import React, { useCallback } from 'react';
import { TextOutsideControls } from '@ui/TextOutsideControls';
import { TextArea } from '@ui/Input/TextArea';
import { InputLikeElement, insertText } from '@utils/documentExecCommand';
import { sendEvent } from '@utils/Analytics';
import { PluginControlLabel } from '../../../../../../../Plugins/common/PluginControlLabel';
import { GREETING_MESSAGE_LIMIT } from '../../consts';
import { DEFAULT_GREETING } from '../../../../../../../Plugins/CustomerChatEntryPoint/CustomerChatEntryPointConst';
import * as css from '../../CustomerChatEntryPoint.css';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { ControlProps } from '../types';

export interface LoggedOutGreetingConfig {
  logged_out_greeting: string | null;
}

interface LoggedOutGreetingControlProps<T> extends ControlProps<T> {
  prepareDataAndSavePlugin: () => void;
}

export function LoggedOutGreetingControl<T extends LoggedOutGreetingConfig>({
  pluginConfig,
  state,
  prepareDataAndSavePlugin,
  pluginType,
}: LoggedOutGreetingControlProps<T>) {
  const { t } = useSafeTranslation();

  const currentTextLimit =
    GREETING_MESSAGE_LIMIT -
    (pluginConfig.logged_out_greeting || '').trim().length;

  const onChange = useCallback(
    ({ currentTarget: { value } }: React.FormEvent<HTMLTextAreaElement>) => {
      state.set(({ config }) => ({
        config: { ...config, logged_out_greeting: value },
      }));
      state.save();
    },
    [state],
  );

  const onBlur = useCallback(() => {
    prepareDataAndSavePlugin();
    sendEvent({
      category: 'flows',
      action: 'change logged_out_greeting',
      label: pluginType,
      propertyBag: {
        logged_out_greeting: pluginConfig.logged_out_greeting,
      },
    });
  }, [pluginType, pluginConfig, prepareDataAndSavePlugin]);

  return (
    <PluginControlLabel
      label={t(
        'modernComponents.FlowBuilder.EditorPanel.LoggedOutGreetingControl',
      )}
    >
      {(bind) => (
        <TextOutsideControls
          onInsertRequest={(text, el) => {
            insertText(text, el as InputLikeElement);
          }}
          currentTextLimit={currentTextLimit}
          shouldShowOutsideControls={{
            emoji: true,
            symbolsLimit: true,
          }}
          tight
          initialShow={false}
        >
          {({ ref, getInputProps }) => (
            <TextArea
              rows={2}
              value={pluginConfig.logged_out_greeting || ''}
              onChange={onChange}
              placeholder={DEFAULT_GREETING}
              maxLength={GREETING_MESSAGE_LIMIT}
              {...getInputProps<HTMLTextAreaElement>({ onBlur })}
              className={css.textarea}
              {...bind}
              ref={ref}
            />
          )}
        </TextOutsideControls>
      )}
    </PluginControlLabel>
  );
}
