import { useState } from 'react';
import * as GoogleAuth from 'cf-common/src/utils/googleAuth';
import { log } from 'cf-common/src/logger';
import { showErrorToaster } from '@services/MessageService';
import i18next from 'i18next';
import { getRequestIdFromApolloError } from '@utils/GQL/utils';
import { useConfirmGoogleUser } from './useConfirmGoogleUser';
import { useGoogleAuthSdk } from './useGoogleAuthSdk';

export const useConnectGoogleAccount = (botId: string) => {
  const [accountConnecting, setAccountConnecting] = useState<boolean>(false);
  const { googleAuthPromise } = useGoogleAuthSdk();
  const { confirmGoogleUser } = useConfirmGoogleUser(botId);

  const connectGoogleAccount = async (): Promise<string | null> => {
    const googleAuthSdk = await googleAuthPromise;
    if (!googleAuthSdk) {
      showErrorToaster(
        i18next.t(
          'GoogleSheet-string--435-could-not-connect-google-account-sdk-error',
        ),
      );
      return null;
    }
    setAccountConnecting(true);
    try {
      const { code } = await googleAuthSdk.grantOfflineAccess({
        scope: GoogleAuth.getGoogleScope(GoogleAuth.GoogleScope.googleSheet),
      });

      const ownerId = await confirmGoogleUser(code);

      if (!ownerId) {
        showErrorToaster(
          i18next.t(
            'GoogleSheet-string--435-could-not-connect-google-account-please-try-again-later',
          ),
        );
        log.warn({
          msg: 'Can not got `owner_id` from ConfirmGoogleUser mutation',
          data: {
            label: 'flow_google_sheet',
          },
        });
      }
      return ownerId ?? null;
    } catch (error) {
      showErrorToaster(
        i18next.t(
          error === GoogleAuth.GET_USER_ID_ERROR
            ? 'GoogleSheet-disable-tracking-prevention'
            : 'GoogleSheet-string--435-could-not-connect-google-account-please-try-again-later',
        ),
        undefined,
        10000,
      );
      log.error({
        error,
        msg: 'Error while authentication via Google',
        data: {
          label: 'flow_google_sheet',
          requestId: getRequestIdFromApolloError(error as any),
        },
      });
      return null;
    } finally {
      setAccountConnecting(false);
    }
  };

  return {
    accountConnecting,
    connectGoogleAccount,
  };
};
