import React, { useCallback } from 'react';
import { Flex } from '@ui/Flex';
import { Input } from '@ui/Input';
import { sendEvent } from '@utils/Analytics';
import { PluginControlLabel } from '../../../../../../../Plugins/common/PluginControlLabel';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { ControlProps } from '../types';
import * as css from './GreetingDialogDelayControl.css';

export interface GreetingDialogDelayConfig {
  greeting_dialog_delay: number | null;
}

export interface GreetingDialogDelayControlProps<T> extends ControlProps<T> {
  field: string;
  value: number;
}

export function GreetingDialogDelayControl<T>({
  state,
  pluginType,
  field,
  value,
}: GreetingDialogDelayControlProps<T>) {
  const { t } = useSafeTranslation();

  const onFocus = useCallback((event: React.FocusEvent<HTMLInputElement>) => {
    event.currentTarget.select();
  }, []);

  const onChange = useCallback(
    ({ currentTarget: { value } }) => {
      state.set(({ config }) => ({
        config: {
          ...config,
          [field]: parseInt(value, 10),
        },
      }));
    },
    [field, state],
  );

  const onBlur = useCallback(() => {
    state.save();
    sendEvent({
      category: 'flows',
      action: `change ${field}`,
      label: pluginType,
      propertyBag: {
        [field]: value,
      },
    });
  }, [state, field, pluginType, value]);

  return (
    <PluginControlLabel
      light
      label={t(
        'modernComponents.FlowBuilder.EditorPanel.GreetingDialogDelayControl',
      )}
      data-testid="whatsapp-chat-widget__welcome-message-delay-input"
    >
      {(bind) => (
        <Flex alignItems="baseline">
          <div className={css.inputWrapper}>
            <Input
              max={99}
              min={0}
              maxLength={2}
              value={value}
              onFocus={onFocus}
              onChange={onChange}
              onBlur={onBlur}
              style={{
                textAlign: 'center',
              }}
              {...bind}
            />
          </div>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor={bind.id}>
            {t('CustomerChatEntryPointContent-JSXText-4755-seconds')}
          </label>
        </Flex>
      )}
    </PluginControlLabel>
  );
}
