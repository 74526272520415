import { useEffect, useRef, useState } from 'react';
import { calculateTokenizedLength } from '@utils/calculateTokenizedLength';
import debounce from 'lodash-es/debounce';

export const useTokenCount = (text: string) => {
  const [count, setCount] = useState(0);
  const [calculatedText, setCalculatedText] = useState('');
  const debounceUpdateRef = useRef(
    debounce((newText: string) => {
      const newCount = calculateTokenizedLength(newText.trim());
      setCount(newCount);
      setCalculatedText(newText);
    }, 500),
  );

  useEffect(() => {
    const debounceUpdate = debounceUpdateRef.current;
    debounceUpdate(text);
    return () => debounceUpdate.cancel();
  }, [text]);

  return { calculatedText, count, loading: calculatedText !== text };
};
