import { useMemo } from 'react';
import Maybe from 'graphql/tsutils/Maybe';
import { GoogleUsersQuery_googleUsers } from '@utils/Data/Google/@types/GoogleUsersQuery';
import { GoogleSpreadsheets_bot_googleSpreadsheets } from '@utils/Data/Google/@types/GoogleSpreadsheets';
import { Item } from '../GoogleSheetCombobox/GoogleSheetCombobox';
import { getFlowControllerStrict } from '../../../../../PixiFieldRepository';
import { getTimezoneOptions } from './constants';

export const useGoogleSheetItems = (
  googleUsers: GoogleUsersQuery_googleUsers[],
  googleSpreadsheets: GoogleSpreadsheets_bot_googleSpreadsheets[],
  selectedSpreadSheetId: Maybe<string>,
) => {
  const googleUserItems: Item[] = useMemo(
    () =>
      googleUsers.map((googleUser) => ({
        id: googleUser.id,
        title: googleUser.email,
        imageUrl: googleUser.picture,
      })),
    [googleUsers],
  );

  const googleSpreadsheetItems: Item[] = useMemo(
    () =>
      googleSpreadsheets
        .filter(
          (googleSpreadsheet) =>
            !googleSpreadsheet.deletedFromGoogle ||
            googleSpreadsheet.id === selectedSpreadSheetId,
        )
        .map((googleSpreadsheet) => ({
          id: googleSpreadsheet.id,
          title: googleSpreadsheet.title ?? '',
        })),
    [googleSpreadsheets, selectedSpreadSheetId],
  );

  return {
    googleUserItems,
    googleSpreadsheetItems,
    timezoneItems: getTimezoneOptions(
      getFlowControllerStrict().flow.bot.timezone_offset ?? 0,
    ),
  };
};
