import React, { useCallback } from 'react';
import copyToClipboard from 'clipboard-copy';
import { Modal } from '../../../../../../modern-ui/Modal';
import {
  Dialog,
  DialogCloseButton,
  DialogContent,
  DialogHeading,
} from '../../../../../../modern-ui/Dialog';
import { Flex } from '../../../../../../modern-ui/Flex';
import { Button } from '../../../../../../modern-ui/Button';
import { removeExtraSpaces } from '../../../../../../utils/String/removeExraSpaces';
import { useToaster } from '../../../../../../modern-ui/Toaster/ToasterProvider';
import { usePreventWheel } from '../../../../../../utils/Event/usePreventWheel';
import { Type } from '../../../../../../modern-ui/Type';
import { Spacer } from '../../../../../../modern-ui/Spacer';
import { CodeTextArea } from '../../../../../../modern-ui/CodeTextArea';

interface FBPluginCodeInstructionsPopupProps {
  onRequestClose: () => void;
  code: string;
  text: React.ReactNode;
  onCopy?: (reason?: string) => void;
}

export const FBPluginCodeInstructionsPopup: React.FC<FBPluginCodeInstructionsPopupProps> = ({
  onRequestClose,
  onCopy,
  code,
  text,
}) => {
  const { addToaster } = useToaster();

  const preventWheelRef = usePreventWheel();
  const showCopiedToaster = useCallback(() => {
    addToaster({
      type: 'info',
      content: (
        <div style={{ whiteSpace: 'nowrap' }}>
          {window.i18next.t(
            'FBPluginCodeInstructionsPopup-JSXText--128-code-copied',
          )}
        </div>
      ),
      timeout: 2000,
    });
  }, [addToaster]);

  const miniCode = removeExtraSpaces(code, ' ');

  return (
    <Modal onDismiss={onRequestClose}>
      <Dialog
        style={{
          width: 504,
        }}
        ref={preventWheelRef}
      >
        <DialogContent>
          <DialogCloseButton
            aria-label="close"
            type="button"
            onClick={onRequestClose}
          />
          <DialogHeading>
            {window.i18next.t(
              'FBPluginCodeInstructionsPopup-JSXText--153-add-the-plugin-to-your-website',
            )}
          </DialogHeading>
          <Type color="black" size="15px_DEPRECATED">
            {text}
          </Type>
          <Spacer factor={6} />
          <CodeTextArea
            value={code}
            style={{
              height: 76,
            }}
            onCopy={() => {
              onCopy?.('from text area');
            }}
          />
          <Spacer factor={10} />
          <Flex justifyContent="flex-end">
            <Button
              onClick={() => {
                copyToClipboard(miniCode);
                showCopiedToaster();
                onCopy?.('by button click');
              }}
            >
              {window.i18next.t(
                'FBPluginCodeInstructionsPopup-JSXText-1852-copy-code',
              )}
            </Button>
          </Flex>
        </DialogContent>
      </Dialog>
    </Modal>
  );
};
