import React from 'react';
import i18next from 'i18next';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { CollapsibleSectionWithShadow } from '@ui/CollapsibleSection/CollapsibleSectionWithShadow/CollapsibleSectionWithShadow';
import followUpsPreviewImageRu from './assets/follow_ups_ru.png';
import followUpsPreviewImageBr from './assets/follow_ups_br.png';
import followUpsPreviewImageEn from './assets/follow_ups_en.png';
import whatsappFollowUpsPreviewImageEn from './assets/whatsapp_follow_ups_en.png';
import { FollowUpsContainer } from '../../../settings/FollowUps/FollowUpsContainer';
import { PluginDataChildrenParams } from '../../../../../../Plugins/common';
import { QuickReplyPluginConfig } from '../../../../../../Plugins/QuickReplyPlugin/QuickReplyPluginConst';
import { Type } from '@ui/Type';
import { useFollowUpsTypeForCurrentFlow } from '../../../settings/FollowUps/hooks/useIsFollowUpsNotFound';
import { FollowUpsType } from '../../../settings/FollowUps/consts';
import { useWhatsappEnabled } from '@utils/Whatsapp';

interface FollowUpsTooltipProps {
  tooltipText: string;
}

const FollowUpsTooltip: React.FC<FollowUpsTooltipProps> = ({ tooltipText }) => {
  const { isWhatsappEnabled } = useWhatsappEnabled();
  const { language } = i18next;

  const followUpsPreview = isWhatsappEnabled
    ? whatsappFollowUpsPreviewImageEn
    : {
        en: followUpsPreviewImageEn,
        ru: followUpsPreviewImageRu,
        pt: followUpsPreviewImageBr,
      }[language] || followUpsPreviewImageEn;

  return (
    <div style={{ fontSize: '13px', lineHeight: '18px', width: '214px' }}>
      <img alt="" src={followUpsPreview} width="214" />
      <br />
      <br />
      {tooltipText}
    </div>
  );
};

interface FollowUpsProps
  extends FollowUpsTooltipProps,
    PluginDataChildrenParams<QuickReplyPluginConfig> {}

export const FollowUps: React.FC<FollowUpsProps> = ({
  tooltipText,
  ...pluginData
}) => {
  const { t } = useSafeTranslation();

  const { followUpsType, loading: followUpsTypeLoading } =
    useFollowUpsTypeForCurrentFlow();

  if (followUpsTypeLoading) {
    return null;
  }

  const hasAnyFollowUps =
    followUpsType === FollowUpsType.global ||
    (followUpsType === FollowUpsType.local &&
      pluginData.pluginConfig.follow_ups?.length);

  return (
    <CollapsibleSectionWithShadow
      initialValue={!hasAnyFollowUps}
      title={
        <>
          <Type size="15px">
            {t(
              'modernComponents.FlowBuilder.views.components.CollectUserData.common.followUps',
            )}
          </Type>
          {followUpsType === FollowUpsType.local && (
            <>
              {' '}
              <Type size="15px">·</Type>{' '}
              <Type
                size="15px"
                color="greyDark"
                data-testid="follow-ups_custom-label"
              >
                {t(
                  'modernComponents.FlowBuilder.views.components.CollectUserData.common.custom',
                )}
              </Type>
            </>
          )}
        </>
      }
      tooltip={<FollowUpsTooltip tooltipText={tooltipText} />}
    >
      <div style={{ padding: '0 16px 16px' }}>
        <FollowUpsContainer {...pluginData} />
      </div>
    </CollapsibleSectionWithShadow>
  );
};
