import { always, assoc, compose, cond, pipe, T } from 'ramda';
import {
  CardButtonType,
  RequestedUserInfo,
  WebviewHeightRatio,
} from '@globals';
import {
  CALENDLY_EVENTS_LIST,
  scheduleCalendlyMagicButton,
} from '../../../views/plugins/CalendlyIntegration/consts';
import {
  CALENDLY_BUTTON_TYPES,
  CALENDLY_URL_BUTTON_TYPES,
  DEFAULT_BUTTONS_LIST,
  DEFAULT_CONFIG,
  paymentButton,
  SHOPIFY_BUTTON_TYPES,
} from './constants';
import { ButtonPopupOverlayConfig, ButtonTypes } from './types';
import { MagicButton } from './MagicButton';
import { getDefaultPriceList } from '../../../EditorPanel/components/editors/EditorPanelPaymentButton/helpers/getDefaultPriceList';
import { DEFAULT_CURRENCY } from '@utils/Format/constants';

export const addPlusToNumber = (number: string) => {
  return number === undefined ? '' : number[0] === '+' ? number : `+${number}`;
};

export const isCalendlyUrlType = (type: CardButtonType) =>
  CALENDLY_URL_BUTTON_TYPES.includes(type);

export const isCalendlyButtonType = (type: CardButtonType) =>
  CALENDLY_BUTTON_TYPES.includes(type);

export const isShopifyButtonType = (type: CardButtonType) =>
  SHOPIFY_BUTTON_TYPES.includes(type);

export const isPaymentButtonType = (type: CardButtonType) =>
  type === CardButtonType.payment;

export const getTypeId = (
  config: ButtonPopupOverlayConfig,
  hideCallPhoneOption?: boolean,
) =>
  cond([
    [
      (config) =>
        typeof config.phone_number === 'string' && !hideCallPhoneOption,
      always(ButtonTypes.phone),
    ],
    [
      (config) =>
        (typeof config.url === 'string' || Boolean(hideCallPhoneOption)) &&
        !isShopifyButtonType(config.type) &&
        !isPaymentButtonType(config.type) &&
        !isCalendlyButtonType(config.type),
      always(ButtonTypes.url),
    ],
    [({ type }) => isCalendlyButtonType(type), ({ type }) => type],
    [({ type }) => isShopifyButtonType(type), ({ type }) => type],
    [({ type }) => isPaymentButtonType(type), ({ type }) => type],
    [T, always(ButtonTypes.default)],
  ])(config);

export const updateConfig = ({
  currentTypeId,
  config,
}: {
  currentTypeId?: string;
  config: ButtonPopupOverlayConfig;
}) =>
  cond([
    [
      () => Boolean(currentTypeId === ButtonTypes.listButton),
      compose(
        assoc('type', currentTypeId),
        assoc('title', config.title),
        assoc('description', config.description),
      ),
    ],
    [
      () => Boolean(currentTypeId === ButtonTypes.phone),
      compose(
        assoc('title', config.title),
        assoc('phone_number', config.phone_number || ''),
      ),
    ],
    [
      () => Boolean(currentTypeId === ButtonTypes.url),
      compose(
        assoc('title', config.title),
        assoc('url', config.url || ''),
        assoc(
          'webview_height_ratio',
          config.webview_height_ratio || WebviewHeightRatio.tall,
        ),
      ),
    ],
    [
      () => isCalendlyButtonType(currentTypeId as CardButtonType),
      compose(
        assoc('title', config.title),
        assoc('type', currentTypeId),
        assoc(
          'webview_height_ratio',
          config.webview_height_ratio || WebviewHeightRatio.tall,
        ),
      ),
    ],
    [
      () => currentTypeId === CardButtonType.shopify_order_list,
      compose(
        assoc('title', config.title),
        assoc('type', currentTypeId),
        assoc(
          'webview_height_ratio',
          config.webview_height_ratio || WebviewHeightRatio.tall,
        ),
      ),
    ],
    [
      () => currentTypeId === CardButtonType.shopify_page,
      pipe(
        assoc('title', config.title),
        assoc('url', config.url),
        assoc('shopify_page', config.shopify_page),
        assoc('type', currentTypeId),
        assoc(
          'webview_height_ratio',
          config.webview_height_ratio || WebviewHeightRatio.tall,
        ),
      ),
    ],
    [
      () => isPaymentButtonType(currentTypeId as CardButtonType),
      pipe(
        assoc('type', currentTypeId),
        assoc('title', config.title),
        assoc('payment_blocks', config.payment_blocks ?? []),
        assoc('variables', config.variables ?? []),
        assoc(
          'payment_summary',
          config.payment_summary || {
            currency: DEFAULT_CURRENCY,
            requested_user_info: [RequestedUserInfo.contact_email],
            price_list: getDefaultPriceList(),
          },
        ),
      ),
    ],
    [T, assoc('title', config.title)],
  ])(DEFAULT_CONFIG);

interface GetButtonTypesListParams {
  /**
   * Флаг определяющий, нужно ли показывать эвенты у календли cancel/join/reschedule.
   * Нужен тогда, когда блок календли есть во флоу и надо показывать дополнительные кнопки
   */
  shouldShowCalendlyMenuItems?: boolean;
  /**
   * Флаг определяющий, нужно ли показывать кнопку, с помощью которой можно создать календли.
   */
  hideCalendlyOptions?: boolean;

  hidePaymentOption?: boolean;

  disablePaymentOption?: boolean;
}

export const getButtonTypesList = ({
  shouldShowCalendlyMenuItems,
  hideCalendlyOptions,
  hidePaymentOption,
  disablePaymentOption,
}: GetButtonTypesListParams) => {
  const menu: MagicButton[] = DEFAULT_BUTTONS_LIST();

  if (!hidePaymentOption) {
    menu.push(paymentButton({ disabled: disablePaymentOption }));
  }

  if (!hideCalendlyOptions) {
    menu.push(scheduleCalendlyMagicButton());

    if (shouldShowCalendlyMenuItems) {
      menu.push(...CALENDLY_EVENTS_LIST());
    }
  }

  return menu;
};
