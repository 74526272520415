import { FetchResult } from 'apollo-link';
import { SavePluginMutationPluginData } from '@components/Plugins/common/@types/SavePluginMutationPluginData';
import { PluginType } from '@components/Plugins/common/PluginTypes';
import {
  ISetPluginStateParams,
  ValidationErrors,
} from '@components/Plugins/common';
import { Card } from '../types';

export {
  ISetPluginStateParams,
  ValidationErrors,
  PluginDataType,
} from '@components/Plugins/common';
export {
  PluginDataQuery,
  PluginDataQuery_card,
  PluginDataQueryVariables,
} from '@components/Plugins/common/@types/PluginDataQuery';
export { PluginType };
export { Node } from '../Node';
export { Card } from '../types';
export { LocalizationType } from '@components/Plugins/common/types';
export {
  SavePluginMutationPluginData,
  SavePluginMutationPluginDataVariables,
} from '@components/Plugins/common/@types/SavePluginMutationPluginData';

export type Plugin<TPluginConfig> = Card<TPluginConfig>;

export interface SetPluginResult<TPluginConfig> {
  data: Plugin<TPluginConfig>;
  validationErrors: ValidationErrors;
}

export type SavePluginResult = FetchResult<SavePluginMutationPluginData> | void;

export interface SavePluginConfig {
  pluginId: string;
  blockId: string;
  position?: number | null;
  pluginType: PluginType;
}

export type PluginGetter<TPluginConfig> =
  | ISetPluginStateParams<TPluginConfig>
  | ((
      prevState: Plugin<TPluginConfig>,
    ) => ISetPluginStateParams<TPluginConfig>);
