import { useEventListener } from 'cf-common/src/hooks/useEventListener';

const preventGesture = (e: Event) => {
  e.preventDefault();
};

export const usePreventGestures = (elementId: string) => {
  const htmlElement = document.getElementById(elementId) ?? undefined;
  useEventListener('gesturestart', preventGesture, { htmlElement });
  useEventListener('gesturechange', preventGesture, { htmlElement });
  useEventListener('gestureend', preventGesture, { htmlElement });
};
