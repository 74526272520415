import React from 'react';
import { CenteredLoader } from '@ui/Loader';
import { StatefulPlugin } from '../../../../StatefulPlugin';
import { EditorPanelPluginProps } from '../../../types';
import { usePageConnected } from '../../../../../../utils/FacebookPages/usePageConnected';
import { whatsappWidgetEntryPointFragment_config as WhatsappWidgetEntryPointConfig } from '../../../../../Plugins/WhatsappWidgetEntryPoint/@types/whatsappWidgetEntryPointFragment';
import { EntryPointWhatsappWidgetContent } from './EntryPointWhatsappWidgetContent';

export const EntryPointWhatsappWidget: React.FC<
  EditorPanelPluginProps<WhatsappWidgetEntryPointConfig>
> = ({ botId, pluginData, pluginParams }) => {
  const { loading, pageId } = usePageConnected(botId);

  if (loading) {
    return <CenteredLoader />;
  }

  return (
    <EntryPointWhatsappWidgetContent
      botId={botId}
      pageId={pageId || ''}
      {...pluginData}
      {...pluginParams}
      state={
        pluginData.state as unknown as StatefulPlugin<WhatsappWidgetEntryPointConfig>
      }
    />
  );
};
