import { isHeightOverflowDetect } from '@utils/DOM/isHeightOverflowDetect';
import { usePreventWheelOnRef } from '@utils/Event/usePreventWheel';
import React, { useEffect, useRef } from 'react';
import { Editor } from 'slate-react';
import { trim } from 'ramda';
import { BubbleEditor } from '@ui/BubbleEditor';
import { TextOutsideControls } from '@ui/TextOutsideControls';
import { insertText } from '@utils/Slite/insertText';
import { BubbleInputOverlayOptions, OverlayProps } from '..';

export interface BubbleInputOverlayConfig {
  value: string[];
}

const FIELD_PADDING = 4;

export const BubbleInputOverlay: React.FC<OverlayProps> = ({
  options,
  onChange,
  onKeyDown,
}) => {
  const {
    config: { value },
    width,
  } = options as BubbleInputOverlayOptions;

  const inputRef = useRef<any>();
  const overlayRef = useRef<HTMLDivElement | null>(null);

  usePreventWheelOnRef(overlayRef, !isHeightOverflowDetect(overlayRef.current));

  useEffect(() => {
    setTimeout(() => {
      inputRef.current.focus();
    }, 10); // whit for slate ready
  }, []);

  return (
    <TextOutsideControls
      onInsertRequest={(text, el) => {
        insertText(text, el as Editor);
      }}
      shouldShowOutsideControls={{
        emoji: true,
      }}
      externalInputRef={inputRef}
      tight
    >
      {({ ref }) => (
        <BubbleEditor
          defaultValue={(value || []).join('\n')}
          onStringChange={(phrasesString) => {
            onChange({
              value: phrasesString.split('\n').map(trim),
            });
          }}
          style={{
            width: width - FIELD_PADDING,
            maxHeight: 292,
            overflowY: 'auto',
          }}
          showEmojiPicker={false}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              return;
            }
            onKeyDown?.(event);
          }}
          bubbleStyle={{
            fontSize: 15,
            lineHeight: '16px',
            padding: '5px 12px 7px',
          }}
          editorRef={ref}
          containerRef={overlayRef}
          shouldMoveCursorToEndOnFocus
        />
      )}
    </TextOutsideControls>
  );
};
