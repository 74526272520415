import { PluginType } from '@components/Plugins/common/PluginTypes';
import { typingPluginFragment_config } from './@types/typingPluginFragment';

export type TypingPluginConfig = typingPluginFragment_config;

export const PLUGIN_TYPE = PluginType.typing;

export const PLUGIN_DEFAULT_CONFIG: TypingPluginConfig = {
  __typename: 'TypingPluginConfig',
  timeout_milliseconds: 2000,
};

export const PLUGIN_INPUT_FIELD = 'typingPluginConfig';
