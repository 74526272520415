import i18next from 'i18next';
import memoize from 'lodash-es/memoize';
import { HEXColors } from '@ui/_common/colors';
import { PluginType } from '../Plugins/common/PluginTypes';
import { Theme } from './views/utility_classes';
import { BlockContextType } from '@globals';

export const fontFamily =
  "'-apple-system', 'BlinkMacSystemFont', 'Segoe UI', Arial, sans-serif";

export enum ViewMode {
  view = 'view',
  edit = 'edit',
}

export enum Feature {
  gestures = 'gestures',
}

export const disabledTheme = new Theme({
  backgroundColor: HEXColors.grey,
});

export const FLOW_BUILDER_ROOT_ID = 'flow-builder-root';
export const TEMPLATES_MODAL_ROOT_ID = 'templates-modal-root-id';

/**
 * TODO: refactor as enum with keys to suit codestyle notation
 */
export const BLOCK_SUBTYPES = {
  entrypoint: 'entry_point',
  action: 'action',
  condition: 'condition',
  split_traffic: 'split_traffic',
  redirect: 'redirect',
  send_message: 'send_message',
  delay: 'delay',
  comments_autoreply_content: 'comments_autoreply_content',
  instagram_comments_autoreply_content: 'instagram_comments_autoreply_content',
  flow_root: 'flow_root',
  broadcast_flow_root: 'broadcast_flow_root',
  send_data: 'send_data',
  import_content: 'import_content',
  calendly: 'calendly',
  calendly_confirmation: 'calendly_confirmation',
  calendly_reminder: 'calendly_reminder',
  schedule_calendly_event: 'schedule_calendly_event',
  open_ai: 'open_ai',
  ai_custom: 'ai_custom',

  /** shopify blocks */
  reminder: 'reminder',
  receipt: 'receipt',
  shopify_discount: 'shopify_discount',
  postPurchase: 'post_purchase',
  back_in_stock_optin_confirmation: 'back_in_stock_optin_confirmation',
  back_in_stock_otn: 'back_in_stock_otn',
  back_in_stock_message: 'back_in_stock_message',
  visitedProductsReminder: 'visited_products_reminder',
  /** shopify blocks end */
};

export const isRootBlock = (subtype: string) =>
  [BLOCK_SUBTYPES.flow_root, BLOCK_SUBTYPES.broadcast_flow_root].includes(
    subtype,
  );

/**
 * List of deprecated blocks we want to delete soon
 */
export const HIDDEN_DEPRECATED_BLOCKS = [
  BLOCK_SUBTYPES.reminder,
  BLOCK_SUBTYPES.receipt,
  BLOCK_SUBTYPES.shopify_discount,
  BLOCK_SUBTYPES.postPurchase,
  BLOCK_SUBTYPES.back_in_stock_optin_confirmation,
  BLOCK_SUBTYPES.back_in_stock_otn,
  BLOCK_SUBTYPES.back_in_stock_message,
  BLOCK_SUBTYPES.visitedProductsReminder,
];

export enum FlowBuilderAnimations {
  elementDrag = 'elementDrag',
  viewportZoom = 'viewportZoom',
  viewportMove = 'viewportMove',
  blocksOrganize = 'blocksOrganize',
  screenResize = 'screenResize',
}

export const makeSubtypeToBlockTitle: () => Record<string, string> = memoize(
  () => ({
    [BLOCK_SUBTYPES.broadcast_flow_root]: i18next.t(
      'modernComponents.FlowBuilder.blockTitles.broadcast_flow_root',
    ),
    [BLOCK_SUBTYPES.reminder]: i18next.t(
      'modernComponents.FlowBuilder.blockTitles.reminder',
    ),
    [BLOCK_SUBTYPES.visitedProductsReminder]: i18next.t(
      'modernComponents.FlowBuilder.blockTitles.visitedProductsReminder',
    ),
    [BLOCK_SUBTYPES.receipt]: i18next.t(
      'modernComponents.FlowBuilder.blockTitles.receipt',
    ),
    [BLOCK_SUBTYPES.calendly_confirmation]: i18next.t(
      'modernComponents.FlowBuilder.blockTitles.calendly_confirmation',
    ),
    [BLOCK_SUBTYPES.calendly_reminder]: i18next.t(
      'modernComponents.FlowBuilder.blockTitles.calendly_reminder',
    ),
    [BLOCK_SUBTYPES.shopify_discount]: i18next.t(
      'modernComponents.FlowBuilder.blockTitles.shopify_discount',
    ),
    [BLOCK_SUBTYPES.action]: i18next.t(
      'modernComponents.FlowBuilder.blockTitles.action',
    ),
    [BLOCK_SUBTYPES.schedule_calendly_event]: i18next.t(
      'modernComponents.FlowBuilder.blockTitles.schedule_calendly_event',
    ),
    [BLOCK_SUBTYPES.condition]: i18next.t(
      'modernComponents.FlowBuilder.blockTitles.condition',
    ),
    [BLOCK_SUBTYPES.split_traffic]: i18next.t(
      'modernComponents.FlowBuilder.blockTitles.split_traffic',
    ),
    [BLOCK_SUBTYPES.redirect]: i18next.t(
      'modernComponents.FlowBuilder.blockTitles.redirect',
    ),
    [BLOCK_SUBTYPES.send_message]: i18next.t(
      'modernComponents.FlowBuilder.blockTitles.send_message',
    ),
    [BLOCK_SUBTYPES.delay]: i18next.t(
      'modernComponents.FlowBuilder.blockTitles.delay',
    ),
    [BLOCK_SUBTYPES.postPurchase]: i18next.t(
      'modernComponents.FlowBuilder.blockTitles.postPurchase',
    ),
    [BLOCK_SUBTYPES.open_ai]: i18next.t(
      'modernComponents.FlowBuilder.blockTitles.openAi',
    ),
    [BLOCK_SUBTYPES.ai_custom]: i18next.t(
      'modernComponents.FlowBuilder.blockTitles.customAi',
    ),
    [BLOCK_SUBTYPES.back_in_stock_optin_confirmation]: i18next.t(
      'modernComponents.FlowBuilder.blockTitles.back_in_stock_optin_confirmation',
    ),
    [BLOCK_SUBTYPES.back_in_stock_otn]: i18next.t(
      'modernComponents.FlowBuilder.blockTitles.back_in_stock_otn',
    ),
    [BLOCK_SUBTYPES.back_in_stock_message]: i18next.t(
      'modernComponents.FlowBuilder.blockTitles.back_in_stock_message',
    ),
    [BLOCK_SUBTYPES.comments_autoreply_content]: i18next.t(
      'modernComponents.FlowBuilder.blockTitles.comments_autoreply_content',
    ),
    [BLOCK_SUBTYPES.instagram_comments_autoreply_content]: i18next.t(
      'modernComponents.FlowBuilder.blockTitles.instagram_comments_autoreply_content',
    ),
  }),
);

export const makeContextTypeToSubtypeToBlockTitle: () => Record<
  BlockContextType,
  Record<string, string>
> = memoize(() => ({
  [BlockContextType.comments]: {
    [BLOCK_SUBTYPES.condition]: i18next.t(
      'modernComponents.FlowBuilder.blockTitles.autoreply_condition',
    ),
    [BLOCK_SUBTYPES.open_ai]: i18next.t(
      'modernComponents.FlowBuilder.views.menuView.openAiCommentsTitle',
    ),
  },
}));

export const makeEntryPointIdToSubtitle: () => Record<string, string> = memoize(
  () => ({
    [PluginType.external_integration_entry_point]: i18next.t(
      'modernComponents.FlowBuilder.blockTitles.external_integration_entry_point',
    ),
    [PluginType.popup_entry_point]: i18next.t(
      'modernComponents.FlowBuilder.blockTitles.popup_entry_point',
    ),
    [PluginType.shopify_event_entry_point]: i18next.t(
      'modernComponents.FlowBuilder.blockTitles.shopify_event_entry_point',
    ),
    [PluginType.shopify_customer_chat_entry_point]: i18next.t(
      'modernComponents.FlowBuilder.blockTitles.shopify_customer_chat_entry_point',
    ),
    [PluginType.fb_page_entry_point]: i18next.t(
      'modernComponents.FlowBuilder.blockTitles.fb_page_entry_point',
    ),
    [PluginType.send_to_messenger_entry_point]: i18next.t(
      'modernComponents.FlowBuilder.blockTitles.send_to_messenger_entry_point',
    ),
    [PluginType.ref_link_entry_point]: i18next.t(
      'modernComponents.FlowBuilder.blockTitles.ref_link_entry_point',
    ),
    [PluginType.instagram_bot_link_entry_point]: i18next.t(
      'modernComponents.FlowBuilder.blockTitles.ref_link_entry_point',
    ),
    [PluginType.customer_chat_entry_point]: i18next.t(
      'modernComponents.FlowBuilder.blockTitles.customer_chat_entry_point',
    ),
    [PluginType.persistent_menu_entry_point]: i18next.t(
      'modernComponents.FlowBuilder.blockTitles.persistent_menu_entry_point',
    ),
    [PluginType.instagram_persistent_menu_entry_point]: i18next.t(
      'modernComponents.FlowBuilder.blockTitles.persistent_menu_entry_point',
    ),
    [PluginType.comments_autoreply_entry_point]: i18next.t(
      'modernComponents.FlowBuilder.blockTitles.comments_autoreply_entry_point',
    ),
    [PluginType.ad_comments_autoreply_entry_point]: i18next.t(
      'modernComponents.FlowBuilder.blockTitles.ad_comments_autoreply_entry_point',
    ),
    [PluginType.facebook_shops_entry_point]: i18next.t(
      'modernComponents.FlowBuilder.blockTitles.facebook_shops_entry_point',
    ),
    [PluginType.ads_manager_ctm_entry_point]: i18next.t(
      'modernComponents.FlowBuilder.blockTitles.ads_manager_ctm_entry_point',
    ),
    [PluginType.ads_manager_ctm_json_entry_point]: i18next.t(
      'modernComponents.FlowBuilder.blockTitles.ads_manager_ctm_json_entry_point',
    ),
    [PluginType.instagram_ads_manager_ctm_entry_point]: i18next.t(
      'modernComponents.FlowBuilder.blockTitles.instagram_ads_manager_ctm_entry_point',
    ),
    [PluginType.ads_manager_sm_entry_point]: i18next.t(
      'modernComponents.FlowBuilder.blockTitles.ads_manager_sm_entry_point',
    ),
    [PluginType.shopify_back_in_stock]: i18next.t(
      'modernComponents.FlowBuilder.blockTitles.shopify_back_in_stock',
    ),
    [PluginType.instagram_direct_entry_point]: i18next.t(
      'modernComponents.FlowBuilder.blockTitles.instagram_direct_entry_point',
    ),
    [PluginType.instagram_comments_autoreply_entry_point]: i18next.t(
      'modernComponents.FlowBuilder.blockTitles.instagram_comments_autoreply_entry_point',
    ),
    [PluginType.instagram_story_reply_entry_point]: i18next.t(
      'modernComponents.FlowBuilder.blockTitles.instagram_story_reply_entry_point',
    ),
    [PluginType.instagram_story_mention_entry_point]: i18next.t(
      'modernComponents.FlowBuilder.blockTitles.instagram_story_mention_entry_point',
    ),
    [PluginType.inbound_links_entry_point]: i18next.t(
      'modernComponents.FlowBuilder.blockTitles.inbound_links_entry_point',
    ),
    [PluginType.whatsapp_direct_entry_point]: i18next.t(
      'modernComponents.FlowBuilder.blockTitles.whatsapp_direct',
    ),
    [PluginType.whatsapp_bot_link]: i18next.t(
      'modernComponents.FlowBuilder.blockTitles.whatsapp_bot_link',
    ),
    [PluginType.whatsapp_widget_entry_point]: i18next.t(
      'modernComponents.FlowBuilder.blockTitles.whatsapp_widget_entry_point',
    ),
    [PluginType.whatsapp_popup_entry_point]: i18next.t(
      'modernComponents.FlowBuilder.blockTitles.whatsapp_popup_entry_point',
    ),
  }),
);

export const ZOOM_ANIMATION_TIME = 150;
export const FIT_ANIMATION_TIME = 300;
