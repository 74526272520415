import { useEffect, useRef } from 'react';
import * as GoogleAuth from 'cf-common/src/utils/googleAuth';
import { log } from 'cf-common/src/logger';

export const useGoogleAuthSdk = () => {
  const googleAuthPromiseRef = useRef<Promise<void | GoogleAuth>>();

  useEffect(() => {
    GoogleAuth.initGoogleApi();
    googleAuthPromiseRef.current =
      GoogleAuth.getGoogleAuthInstanceAsync().catch((error) => {
        log.warn({
          msg: 'Google sdk init failed',
          error,
          data: {
            label: 'flow_google_sheet',
          },
        });
      });
  }, []);

  return {
    googleAuthPromise: googleAuthPromiseRef.current,
  };
};
