import gql from 'graphql-tag';
import {
  COMMON_PLUGIN_FRAGMENT,
  VALIDATION_FRAGMENT,
} from '../../../Plugins/common/PluginQuery';
import { FlowBuilderPluginParams } from '../../FlowBuilderPlugin';

export const PLUGIN_FRAGMENT = gql`
  fragment typingPluginFragment on TypingPlugin {
    ...commonPluginFragment
    config {
      timeout_milliseconds
    }
  }
  ${COMMON_PLUGIN_FRAGMENT}
`;

const TYPING_PLUGIN_DATA_QUERY = gql`
  query TypingPluginDataQuery($id: ID!) {
    card(id: $id) {
      ...typingPluginFragment
    }
  }
  ${PLUGIN_FRAGMENT}
`;

const SAVE_TYPING_PLUGIN_MUTATION = gql`
  mutation SaveTypingPluginMutationPluginData(
    $blockId: String!
    $pluginType: String!
    $position: Int
    $id: String
    $refresh: Boolean
    $enabled: Boolean
    $synced: Boolean
    $localization: [LocalizationInput!]
    $typingPluginConfig: TypingPluginConfigInput
  ) {
    saveCard(
      blockId: $blockId
      pluginType: $pluginType
      position: $position
      id: $id
      refresh: $refresh
      enabled: $enabled
      synced: $synced
      localization: $localization
      typingPluginConfig: $typingPluginConfig
    ) {
      ... on CardI {
        id
        plugin_id
        is_valid
      }
      ...validationFragment
    }
  }

  ${VALIDATION_FRAGMENT}
`;

const CREATE_TYPING_PLUGIN_MUTATION = gql`
  mutation SaveTypingPluginMutationCommon(
    $blockId: String!
    $pluginType: String!
    $position: Int
    $id: String
    $refresh: Boolean
    $enabled: Boolean
    $synced: Boolean
    $localization: [LocalizationInput!]
    $typingPluginConfig: TypingPluginConfigInput
  ) {
    saveCard(
      blockId: $blockId
      pluginType: $pluginType
      position: $position
      id: $id
      refresh: $refresh
      enabled: $enabled
      synced: $synced
      localization: $localization
      typingPluginConfig: $typingPluginConfig
    ) {
      id
      source_card_id
      plugin_id
      is_valid
      ...typingPluginFragment
    }
  }
  ${PLUGIN_FRAGMENT}
`;

export const documentNodes: FlowBuilderPluginParams = {
  cacheDocumentNode: TYPING_PLUGIN_DATA_QUERY,
  saveDocumentNode: SAVE_TYPING_PLUGIN_MUTATION,
  createDocumentNode: CREATE_TYPING_PLUGIN_MUTATION,
};
