import React from 'react';
import { Spacer } from '@ui/Spacer';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Platform } from '@globals';
import {
  FollowUps,
  InvalidInputMessage,
  AttributeInput,
  CheckboxWithTooltip,
  InputTimeout,
} from '../components';
import { PluginHeader } from '../../../common/PluginHeader';
import { emailFacebookSuggestLens } from '../../../../../views/plugins/QuickReplyPlugin/lenses';
import { getFlowPlatform } from '../../../../../utils/getFlowPlatform';
import { QuickReplyPluginData } from '../types';

export const CollectEmailsView: React.FC<QuickReplyPluginData> = ({
  showResponseTimeout,
  ...props
}) => {
  const { t } = useSafeTranslation();
  const isFacebookPlatform = getFlowPlatform() === Platform.facebook;

  return (
    <div>
      <PluginHeader
        title={t(
          'modernComponents.FlowBuilder.views.components.CollectUserData.emails.title',
        )}
      />

      <AttributeInput {...props} />

      {showResponseTimeout && (
        <>
          <Spacer factor={8} />
          <InputTimeout {...props} />
        </>
      )}

      <Spacer factor={6} />

      {isFacebookPlatform && (
        <CheckboxWithTooltip
          data-testid="suggest-email-autofill"
          tooltipText={t(
            'modernComponents.FlowBuilder.views.components.CollectUserData.emails.tooltipContent',
          )}
          label={t(
            'modernComponents.FlowBuilder.views.components.CollectUserData.emails.suggestEmailAutofill',
          )}
          lens={emailFacebookSuggestLens}
          {...props}
        />
      )}

      <Spacer factor={4} />

      <InvalidInputMessage {...props} />

      <Spacer factor={2} />

      <FollowUps
        tooltipText={t(
          'modernComponents.FlowBuilder.views.components.CollectUserData.emails.followUpsTooltip',
        )}
        {...props}
      />
    </div>
  );
};
