import i18next from 'i18next';
import { CardButtonType } from '@globals';
import { MagicButton } from './MagicButton';
import { ButtonPopupOverlayConfig, ButtonTypes } from './types';

export const defaultMagicButton = () =>
  new MagicButton({
    id: ButtonTypes.default,
    title: i18next.t(
      'modernComponents.FlowBuilder.overlays.ButtonPopupOverlay.default',
    ),
  });
export const phoneMagicButton = () =>
  new MagicButton({
    id: ButtonTypes.phone,
    title: i18next.t(
      'modernComponents.FlowBuilder.overlays.ButtonPopupOverlay.phone',
    ),
  });
export const urlMagicButton = () =>
  new MagicButton({
    id: ButtonTypes.url,
    title: i18next.t(
      'modernComponents.FlowBuilder.overlays.ButtonPopupOverlay.url',
    ),
  });
export const shopifyDiscountButton = () =>
  new MagicButton({
    id: ButtonTypes.shopify_page,
    title: i18next.t(
      'modernComponents.FlowBuilder.overlays.ButtonPopupOverlay.shopify_page',
    ),
  });
export const paymentButton = ({ disabled }: { disabled?: boolean }) =>
  new MagicButton({
    id: ButtonTypes.payment,
    title: i18next.t(
      'modernComponents.FlowBuilder.overlays.ButtonPopupOverlay.payment',
    ),
    disabled,
  });
export const listButton = ({ disabled }: { disabled?: boolean }) =>
  new MagicButton({
    id: ButtonTypes.listButton,
    title: i18next.t(
      'modernComponents.FlowBuilder.overlays.ButtonPopupOverlay.list',
    ),
    disabled,
  });

export const DEFAULT_BUTTONS_LIST = () => [
  defaultMagicButton(),
  phoneMagicButton(),
  urlMagicButton(),
];

export const BIG_CURSOR_POS = 10000;

export const DEFAULT_CONFIG: ButtonPopupOverlayConfig = {
  type: CardButtonType.regular,
  title: '',
};

export const CALENDLY_URL_BUTTON_TYPES = [
  CardButtonType.calendly_reschedule,
  CardButtonType.calendly_join_meeting,
  CardButtonType.calendly_cancel,
];

export const CALENDLY_BUTTON_TYPES = [
  ...CALENDLY_URL_BUTTON_TYPES,
  CardButtonType.calendly_schedule,
];

export const SHOPIFY_BUTTON_TYPES = [
  CardButtonType.shopify_page,
  CardButtonType.shopify_order_list,
];

export const WHATSAPP_LIST_BUTTON_TYPES = [
  CardButtonType.shopify_page,
  CardButtonType.shopify_order_list,
];
