import React, { useCallback, useEffect, useRef } from 'react';
import { Value } from 'react-powerplug';
import { EditorPanelPluginProps } from '../../../types';
import { PluginDataChildrenParams } from '../../../../../Plugins/common';
import { Spacer } from '../../../../../../modern-ui/Spacer';
import { PluginHeader } from '../../common/PluginHeader';
import { Input } from '../../../../../../modern-ui/Input';
import { Type } from '../../../../../../modern-ui/Type';
import {
  TextWithAttributesEditor,
  deserialize,
} from '../../../../../../modern-ui/TextWithAttributesEditor';
import { useToaster } from '../../../../../../modern-ui/Toaster/ToasterProvider';
import { AttributesData } from '../../../../../../utils/AttributesUtils/AttributesData';
import { isValidEmail } from '../../../../../../utils/emailUtils';
import { sendEmailPluginFragment_config as EmailPluginConfig } from '../../../../../Plugins/EmailPlugin/@types/sendEmailPluginFragment';
import * as css from './EmailPlugin.css';
import { getFlowPlatform } from '../../../../utils/getFlowPlatform';

export const EmailPlugin: React.FC<EditorPanelPluginProps<EmailPluginConfig>> =
  ({ botId, pluginParams }) => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return <EmailPluginContent botId={botId} {...pluginParams} />;
  };

interface EmailPluginContent
  extends PluginDataChildrenParams<EmailPluginConfig> {
  botId: string;
}

const EmailPluginContent: React.FC<EmailPluginContent> = ({
  botId,
  pluginConfig,
  savePlugin,
  saving,
  setPluginConfigState,
  validationErrors,
}) => {
  const { addToaster } = useToaster();
  const titleInputRef = useRef<HTMLInputElement | null>(null);

  const { body, title } = pluginConfig;
  const emails = pluginConfig.emails ?? [];
  const handleEmailsInputSave = useCallback(
    (value: string, afterStateSave?: () => void) => {
      if (!value) {
        return;
      }
      if (emails.includes(value)) {
        addToaster({
          type: 'error',
          content: (
            <div style={{ width: '300px' }}>
              {window.i18next.t('EmailPlugin-JSXText-7051-value-is-not-unique')}
            </div>
          ),
          timeout: 5000,
          closeButton: true,
        });
      } else {
        setPluginConfigState({
          config: {
            ...pluginConfig,
            emails: emails.concat(value.trim()),
          },
        });
        afterStateSave?.();
      }
    },
    [addToaster, emails, pluginConfig, setPluginConfigState],
  );

  const handleTitleSave = (title: string) => {
    setPluginConfigState({
      config: {
        ...pluginConfig,
        title,
      },
    });
  };

  const handleBodySave = (body: string) => {
    setPluginConfigState({
      config: {
        ...pluginConfig,
        body,
      },
    });
  };

  useEffect(
    () => () => {
      const inputValue = titleInputRef.current?.value;
      if (inputValue) {
        handleEmailsInputSave(inputValue);
      }
    },
    [pluginConfig, handleEmailsInputSave],
  );

  let escPressed = false;

  return (
    <>
      <PluginHeader
        title={window.i18next.t(
          'EmailPlugin-string-1888-notify-admin-by-email',
        )}
        tooltip={
          <>
            {window.i18next.t(
              'EmailPlugin-JSXText--107-use-this-plugin-to-solicit-user',
            )}
            <br />
            {window.i18next.t(
              'EmailPlugin-JSXText--100-feedback-send-notifications-to-bot',
            )}
            <br />
            {window.i18next.t(
              'EmailPlugin-JSXText--973-admins-or-export-data-from-your',
            )}
            <br />
            {window.i18next.t(
              'EmailPlugin-JSXText--108-chatbot-via-email-note-that-you-cant',
            )}
            <br />
            {window.i18next.t(
              'EmailPlugin-JSXText--499-send-emails-to-the-bot-users-with-this',
            )}
            <br />
            {window.i18next.t('EmailPlugin-JSXText--301-plugin')}
          </>
        }
      />
      <Spacer />
      <Input
        error={!saving && (!!validationErrors.emails || !emails?.length)}
        render={({ getInputProps }) => (
          <div className={css.inputContainer}>
            {emails?.map((value, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <div className={css.emailContainer} key={`${value}-${index}`}>
                <span className={css.email}>
                  <Type
                    size="15px_DEPRECATED"
                    color={isValidEmail(value) ? 'black' : 'red'}
                    data-testid="email-plugin_email-addresses_span"
                  >
                    {value}
                  </Type>
                </span>
              </div>
            ))}
            <Value initial="">
              {({ value, set }) => (
                <input
                  {...getInputProps({
                    ref: titleInputRef,
                    value,
                    onBlur: () => {
                      if (!escPressed) {
                        handleEmailsInputSave(value);
                        set(value.trim());
                      }
                      set('');
                      savePlugin();
                    },
                    type: 'email',
                    placeholder: window.i18next.t(
                      'EmailPlugin-string-1780-add-email-addresses',
                    ),
                    onChange: (e: any) => set(e.target.value),
                    onKeyDown: (event) => {
                      switch (event.key) {
                        case 'Enter':
                        case 'Tab':
                          handleEmailsInputSave(value, () => set(''));
                          break;
                        case 'Escape':
                          escPressed = true;
                          titleInputRef.current?.blur();
                          break;
                        case 'Backspace':
                          if (!value) {
                            setPluginConfigState({
                              config: {
                                ...pluginConfig,
                                emails: emails.slice(0, -1),
                              },
                            });
                          }
                          break;
                        default:
                          break;
                      }
                    },
                  })}
                  data-testid="email-plugin_email-addresses_input"
                />
              )}
            </Value>
          </div>
        )}
      />
      <Spacer factor={2} />
      <AttributesData botId={botId} platform={getFlowPlatform()}>
        {({ attributes }) => (
          <>
            <Input
              error={!saving && (!!validationErrors.title || !title)}
              render={() => (
                <TextWithAttributesEditor
                  defaultValue={deserialize(title || '')}
                  onStringChange={handleTitleSave}
                  onBlur={(_, value) => {
                    handleTitleSave(value);
                    savePlugin();
                  }}
                  placeholder={window.i18next.t(
                    'EmailPlugin-string--130-add-subject',
                  )}
                  attributes={attributes}
                  maxLength={250}
                  onKeyDown={(event) => {
                    if ((event as KeyboardEvent).key === 'Enter') {
                      return false;
                    }
                    return undefined;
                  }}
                  trimOnBlur
                  hasManageAttributes
                  data-testid="email-plugin_subject"
                />
              )}
            />
            <Spacer factor={2} />
            <Input
              error={!saving && (!!validationErrors.body || !body)}
              render={() => (
                <TextWithAttributesEditor
                  className={css.body}
                  defaultValue={deserialize(body || '')}
                  onStringChange={handleBodySave}
                  onBlur={(_, value) => {
                    handleBodySave(value);
                    savePlugin();
                  }}
                  placeholder={window.i18next.t(
                    'EmailPlugin-string-1519-add-message',
                  )}
                  attributes={attributes}
                  maxLength={5000}
                  trimOnBlur
                  hasManageAttributes
                  data-testid="email-plugin_message"
                />
              )}
            />
          </>
        )}
      </AttributesData>
    </>
  );
};
