import { useRef } from 'react';
import useOnClickOutside from 'use-onclickoutside';
import { NAV_PANEL_COLLAPSE_BUTTON_ID } from '../../../../pages/BotPage/FlowTab/FlowList/constants';
import { ONBORDING_CONTAINER_ID } from '@components/Onboarding/OnboardingTour/Overlay/consts';
import { useSearchParams } from '@utils/UrlUtils/useSearchParams';
import { ONBORDING_TOUR_OPENED } from '@components/MiniOnboarding/MiniOnboardingDialog/consts';

const isTargetCollapseButton = (target: EventTarget | null) =>
  (target as HTMLElement)?.id === NAV_PANEL_COLLAPSE_BUTTON_ID ||
  (target as HTMLElement)?.parentElement?.id === NAV_PANEL_COLLAPSE_BUTTON_ID;

const isTargetInOnbording = (target: EventTarget | null) =>
  (target as HTMLElement)?.closest(`#${ONBORDING_CONTAINER_ID}`);

interface UseEditorPanelOutsideClickProps {
  onOutsideClick: () => void;
}

export const useEditorPanelOutsideClick = ({
  onOutsideClick,
}: UseEditorPanelOutsideClickProps) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const { searchParams } = useSearchParams();
  useOnClickOutside(ref, (event) => {
    if (isTargetCollapseButton(event.target)) {
      return;
    }
    if (
      isTargetInOnbording(event.target) ||
      // если в данный момент открыл onboarding то панель можно закрыть только при явном нажатии на кнопку закрыть
      // предотвращает двойное нажатие - случайное закрытие
      searchParams.has(ONBORDING_TOUR_OPENED)
    )
      return;
    if ((event.target as HTMLElement)?.closest('[aria-modal="true"]')) {
      // prevent for all aria-modal entities
      return;
    }
    const rect = ref.current?.getBoundingClientRect();
    if (rect) {
      const { x, y } = event as any;
      const { left, top, right, bottom } = rect;
      const eventInPanel = x > left && x < right && y > top && y < bottom;
      if (!eventInPanel) {
        onOutsideClick();
      }
    }
  });

  return ref;
};
