import React from 'react';
import { Type } from '@ui/Type';
import { Spacer } from '@ui/Spacer';
import { Tooltip2 } from '@ui/Tooltip2';
import { Anchor } from '@ui/Links';
import { sendEvent } from '@utils/Analytics';
import { Icon } from '@ui/Icon';
import { Flex } from '@ui/Flex';
import { useSafeTranslation } from '@utils/useSafeTranslation';

export const AttributesMappingHeader = () => {
  const { t } = useSafeTranslation();

  return (
    <Flex alignItems="center">
      <Type weight="semibold" size="15px">
        {t('modernComponents.FlowBuilder.plugins.JSONPlugin.jsonResponse')}
      </Type>
      <Spacer horizontalFactor={1} />
      <Tooltip2
        hoverable
        content={
          <>
            {t(
              'modernComponents.FlowBuilder.plugins.JSONPlugin.jsonResponseTooltip',
            )}
            <br />
            <br />
            {t(
              'modernComponents.FlowBuilder.plugins.JSONPlugin.jsonResponseTooltipNote',
            )}
            <br />
            <br />
            <Anchor
              intent="tooltip"
              size="small"
              href={t('common.helpDocLinks.json_attributes_mapping')}
              target="_blank"
              rel="noopener noreferrer"
              onMouseDown={() => {
                sendEvent({
                  category: 'flows',
                  action: 'learn more',
                  label: 'json-attributes-mapping',
                });
              }}
            >
              {t('common.LearnMore')}
            </Anchor>
          </>
        }
        type="small"
        placement="right"
      >
        {(ref, bind) => <Icon icon="info" color="grey" ref={ref} {...bind} />}
      </Tooltip2>
    </Flex>
  );
};
