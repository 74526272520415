import React, { useCallback, useState } from 'react';
import { Callout } from '@ui/Callout';
import { Button } from '@ui/Button';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Spacer } from '@ui/Spacer';
import { RadioButton } from '@ui/Radio';
import { FollowUpsType, RADIO_BUTTONS_GROUP_NAME } from '../consts';
import { Type } from '@ui/Type';
import * as css from './FollowUpsEmpty.css';
import { useFollowUpsTypeForCurrentFlow } from '../hooks/useIsFollowUpsNotFound';
import { logFlowEvent } from '../../../../../utils/Analytics';

interface FollowUpsEmptyProps {
  onButtonClick: (type: FollowUpsType) => void;
}

export const FollowUpsEmpty: React.FC<FollowUpsEmptyProps> = ({
  onButtonClick,
}) => {
  const { t } = useSafeTranslation();
  const { followUpsType: currentFollowUpsType } =
    useFollowUpsTypeForCurrentFlow();
  const [followUpsType, setFollowUpsType] = useState<
    FollowUpsType | undefined
  >();

  const followUpsTypeChangeHandler = useCallback(
    ({ currentTarget: { value } }: React.FocusEvent<HTMLInputElement>) => {
      logFlowEvent('follow ups', 'change followups type', { type: value });
      setFollowUpsType(value as FollowUpsType);
    },
    [],
  );

  const type = currentFollowUpsType || followUpsType;

  return (
    <>
      {currentFollowUpsType === null && (
        <>
          <Callout
            flexDirection="row"
            text={t(
              'modernComponents.FlowBuilder.views.components.CollectUserData.common.addFollowUpInfo',
            )}
            type="info"
            iconDisabled
          />
          <Spacer factor={4} />
          <RadioButton
            id={FollowUpsType.global}
            name={RADIO_BUTTONS_GROUP_NAME}
            value={followUpsType}
            onChange={followUpsTypeChangeHandler}
            data-testid={`follow-ups_type_${FollowUpsType.global}`}
            renderLabel={() => (
              <>
                <Type size="15px">
                  {t(
                    'modernComponents.FlowBuilder.views.components.CollectUserData.common.types.global.title',
                  )}
                </Type>
                <Spacer factor={2} />
                <Type size="12px" color="greyDark">
                  {t(
                    'modernComponents.FlowBuilder.views.components.CollectUserData.common.types.global.text',
                  )}
                </Type>
              </>
            )}
          />
          <Spacer factor={6} />
          <RadioButton
            id={FollowUpsType.local}
            name={RADIO_BUTTONS_GROUP_NAME}
            value={followUpsType}
            onChange={followUpsTypeChangeHandler}
            data-testid={`follow-ups_type_${FollowUpsType.local}`}
            renderLabel={() => (
              <>
                <Type size="15px">
                  {t(
                    'modernComponents.FlowBuilder.views.components.CollectUserData.common.types.local.title',
                  )}
                </Type>
                <Spacer factor={2} />
                <Type size="12px" color="greyDark">
                  {t(
                    'modernComponents.FlowBuilder.views.components.CollectUserData.common.types.local.text',
                  )}
                </Type>
              </>
            )}
          />
          <Spacer factor={4} />
        </>
      )}
      <Button
        className={css.button}
        intent="secondary"
        onClick={() => {
          if (!type) {
            return;
          }
          if (currentFollowUpsType) {
            logFlowEvent('follow ups', 'setup followup', { type });
          } else {
            logFlowEvent('follow ups', 'select type and setup followup', {
              type,
            });
          }
          onButtonClick(type);
        }}
        data-testid="set-up-follow-ups"
        disabled={!type}
      >
        {t('QuickReplyPlugin-string--167-set-up-follow-ups')}
      </Button>
    </>
  );
};
