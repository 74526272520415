import React, { useCallback } from 'react';
import { PluginControlLabel } from '@components/Plugins/common/PluginControlLabel';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { refetchAttributesQueryObservables } from '@utils/AttributesUtils/AttributesUtils';
import { useCurrentBotId } from '@utils/Routing';
import { AttributeInput as AttributeInputView } from '@components/AttributeInput';
import { useAttributes } from '@utils/AttributesUtils/AttributesData';
import { VariableSuggestType } from '@utils/AttributesUtils/AttributesUtilsTypes';
import { Platform } from '@globals';
import { getFlowPlatform } from '../../../../../utils/getFlowPlatform';
import { logFlowEvent } from '../../../../../utils/Analytics';
import { PluginType } from '../../../../../types';
import { QuickReplyPluginData } from '../types';

export const AttributeInput: React.FC<QuickReplyPluginData> = ({
  savePlugin,
  pluginConfig,
  setPluginConfigState,
}) => {
  const { t } = useSafeTranslation();
  const botId = useCurrentBotId()!;
  const platform = getFlowPlatform() ?? Platform.facebook;
  const { attributes } = useAttributes(
    botId,
    VariableSuggestType.template,
    platform,
  );

  const onChange = useCallback(
    (attribute) => {
      const newProperty = attribute === '' ? null : attribute;
      setPluginConfigState({
        config: {
          ...pluginConfig,
          property: newProperty,
        },
      });
    },
    [pluginConfig, setPluginConfigState],
  );

  const onBlur = useCallback(() => {
    logFlowEvent(PluginType.quick_reply, 'attribute changed', {
      quickReplyType: pluginConfig.type,
    });
    savePlugin();
    refetchAttributesQueryObservables(botId, platform);
  }, [botId, platform, pluginConfig.type, savePlugin]);

  return (
    <PluginControlLabel
      label={t(
        'modernComponents.FlowBuilder.views.components.CollectUserData.common.saveTo',
      )}
    >
      {() => (
        <AttributeInputView
          attributes={attributes}
          platform={platform}
          value={pluginConfig.property || ''}
          placeholder={t(
            'modernComponents.FlowBuilder.views.components.CollectUserData.common.attribute',
          )}
          onChange={onChange}
          customAttributesOnly
          onBlur={onBlur}
        />
      )}
    </PluginControlLabel>
  );
};
