import React from 'react';
import { Attributes } from '@ui/TextWithAttributesEditor';
import { exhaustiveCheck } from '@utils/exhaustiveCheck';
import { AttributeInput } from './AttributeInput';
import { AttributeText } from './AttributeText';
import { Token, TokenType } from '../types';
import { OnboardingTourHTMLElementId } from '@globals';

export interface DisplayTokensProps {
  attributes: Attributes;
  getAttributeInputMaxLength: (index: number) => number | undefined;
  getAttributeName: (index: number) => string;
  getOnAttributeChange: (index: number) => (attibute: string) => void;
  onAttributeInputBlur: () => void;
  tokens: Array<Token>;
  disabled?: boolean;
}

export const DisplayTokens: React.FC<DisplayTokensProps> = ({
  tokens,
  disabled,
  attributes,
  getAttributeName,
  getAttributeInputMaxLength,
  getOnAttributeChange,
  onAttributeInputBlur,
}) => (
  <div>
    {tokens.map((token) => {
      const { type } = token;
      switch (type) {
        case TokenType.text:
          return <AttributeText>{token.value}</AttributeText>;
        case TokenType.attribute: {
          const index = (token.value as number) - 1;
          const attributeName = getAttributeName(index);

          return (
            <AttributeInput
              containerId={`${OnboardingTourHTMLElementId.WAAttributeInput}${index}`}
              disabled={disabled}
              placeholder={token.value.toString()}
              attributes={attributes}
              maxLength={getAttributeInputMaxLength(index)}
              attributeName={attributeName}
              onChange={getOnAttributeChange(index)}
              onBlur={onAttributeInputBlur}
            />
          );
        }
        case TokenType.newline:
          return <div />;
        default:
          return exhaustiveCheck(type);
      }
    })}
  </div>
);
