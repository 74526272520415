import React from 'react';
import cn from 'classnames';
import { IS_CHROME_BROWSER } from 'cf-common/src/environment';
import { FB_YOUTUBE_FRAME_PLAYER_PORTAL_ID } from '@components/PortalForYoutubePlayer/constants';
import { FlowBuilderOverlay } from './FlowBuilderOverlay';
import { FLOW_BUILDER_ROOT_ID, ViewMode, Feature } from './consts';
import { AvailableOverlays } from './FlowBuilderOverlay/consts';
import { pixiFieldRepository } from './PixiFieldRepository';
import './utils/flowBuilderOutsideTestingApi';
import {
  DrawFlowBuilderLifecycleActions,
  useCopyPaste,
  useDrawFlowBuilder,
  useFocusBlockOrConnectPage,
  useListenFlowListChanges,
  usePreventGestures,
  usePreventOverscrollBehavior,
  usePreventPageZoom,
} from './hooks';
import { useFixBodyOverscroll } from './utils/useFixBodyOverscroll';
import { FlowData } from './types';
import { EntryPointTutorialVideoPlayer } from './components/EntryPointTutorialVideoPlayer/EntryPointTutorialVideoPlayer';
import * as css from './FlowBuilder.css';
import { ChromeAlert } from '../ChromeAlert';

export { ViewMode };
export { Feature };

interface FlowBuilderProps extends DrawFlowBuilderLifecycleActions {
  flowId: string;
  mode: ViewMode;
  availableFeatures?: Feature[];
  availableOverlays?: AvailableOverlays;
  loadFlow(): Promise<FlowData>;
  rootElementId?: string;
  defaultScale?: number;
  className?: string;
  hideVideoTutorial?: boolean;
}

export const FlowBuilder = React.memo<FlowBuilderProps>(
  ({
    flowId: routeFlowId,
    mode,
    availableFeatures,
    availableOverlays,
    rootElementId = FLOW_BUILDER_ROOT_ID,
    className,
    hideVideoTutorial,
    ...rest
  }) => {
    useCopyPaste(routeFlowId, { disabled: mode === ViewMode.view });
    usePreventGestures(rootElementId);
    usePreventPageZoom();
    usePreventOverscrollBehavior();
    useFocusBlockOrConnectPage();
    useListenFlowListChanges(routeFlowId);
    useFixBodyOverscroll();

    const {
      loadFlow,
      onLoading,
      onReady,
      onRendered,
      onDestroy,
      onError,
      defaultScale,
    } = rest;
    useDrawFlowBuilder({
      loadFlow,
      mode,
      availableFeatures: availableFeatures || [],
      rootElementId,
      routeFlowId,
      defaultScale,
      drawLifecycleActions: {
        onLoading,
        onReady,
        onRendered,
        onDestroy,
        onError,
      },
    });

    return (
      <div
        className={cn(css.flowBuilderContainer, className)}
        data-flow-id={routeFlowId}
      >
        {!IS_CHROME_BROWSER && <ChromeAlert mode="bottom" />}
        <div
          className={cn(css.box, css.overlay)}
          id={FB_YOUTUBE_FRAME_PLAYER_PORTAL_ID}
        />
        <FlowBuilderOverlay availableOverlays={availableOverlays} />
        <div
          id={rootElementId}
          className={css.box}
          onPointerOver={() => {
            pixiFieldRepository.setActiveField(rootElementId);
          }}
          onMouseDown={(event) => {
            event.nativeEvent.preventDefault();
          }}
          onContextMenu={(event) => {
            event.preventDefault();
          }}
        />
        {hideVideoTutorial && <EntryPointTutorialVideoPlayer />}
      </div>
    );
  },
  (prevProps, props) =>
    prevProps.mode === props.mode && prevProps.flowId === props.flowId,
);
