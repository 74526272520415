import React, { useMemo, useState } from 'react';
import { Flex } from '@ui/Flex';
import { itemToString } from '@ui/TextWithAttributesEditor/Attribute';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { InputErrorBox } from '@ui/Input/InputErrorBox';
import { AttributeInput } from '@components/AttributeInput/AttributeInput';
import { AttributesQuery_bot_variableSuggest as Attribute } from '@utils/AttributesUtils/@types/AttributesQuery';
import { Platform } from '@globals';
import { AttributesDnd } from './AttributesDnd/AttributesDnd';

interface GoogleSheetAttributesProps {
  attributeNames: string[];
  platform: Platform;
  allAttributes: Attribute[];
  editable: boolean;
  onAttributeAdd(attributeName: string): void;
  onAttributesRearrange(nextAttributeNames: string[]): void;
  onAttributeDeleteAt(index: number): void;
}

export const GoogleSheetAttributes: React.FC<GoogleSheetAttributesProps> = ({
  attributeNames,
  platform,
  allAttributes,
  editable,
  onAttributeAdd,
  onAttributesRearrange,
  onAttributeDeleteAt,
}) => {
  const { t } = useSafeTranslation();
  const [attributeToAdd, setAttributeToAdd] = useState('');
  const attributeHasBeenAlreadyAdded = attributeNames.includes(attributeToAdd);

  const noYetAddedAttributes = useMemo(() => {
    return allAttributes.filter(({ name }) => !attributeNames.includes(name));
  }, [attributeNames, allAttributes]);

  const handleBlur = () => {
    if (!attributeToAdd.trim() || attributeHasBeenAlreadyAdded) return;
    onAttributeAdd(attributeToAdd);
    setAttributeToAdd('');
  };

  const handleSelect = (item: any) => {
    const attributeName: string | undefined = itemToString(item);
    if (!attributeName?.trim() || attributeNames.includes(attributeToAdd))
      return;
    onAttributeAdd(attributeName);
    setAttributeToAdd('');
  };

  return (
    <Flex flexDirection="column" data-testid="google_sheet_attributes_list">
      <AttributesDnd
        attributeNames={attributeNames}
        editable={editable}
        onAttributesRearrange={onAttributesRearrange}
        onAttributeDeleteAt={onAttributeDeleteAt}
      />
      <AttributeInput
        value={attributeToAdd}
        placeholder={t(
          'modernComponents.FlowBuilder.plugins.GoogleSheetPlugin.attributeInputPlaceholder',
        )}
        platform={platform}
        attributes={noYetAddedAttributes}
        error={attributeHasBeenAlreadyAdded}
        onChange={setAttributeToAdd}
        onSelect={handleSelect}
        onBlur={handleBlur}
      />
      {attributeHasBeenAlreadyAdded && (
        <InputErrorBox
          errorText={t(
            'modernComponents.FlowBuilder.plugins.GoogleSheetPlugin.attributeAlreadyInUse',
          )}
        />
      )}
    </Flex>
  );
};
