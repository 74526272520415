import i18next from 'i18next';
import { FlowBuilderPlugin } from '@components/FlowBuilder/FlowBuilderPlugin';
import {
  AssignAdminPluginConfig,
  PLUGIN_INPUT_FIELD,
  PLUGIN_DEFAULT_CONFIG,
} from './AssignAdminPluginConst';
import { SaveAssignAdminPluginMutationCommonVariables } from './@types/SaveAssignAdminPluginMutationCommon';
import { Node } from '@components/FlowBuilder/Node';
import { MainLayout } from '@components/FlowBuilder/components/Elements/Layouts';
import { PluginType, Card } from '@components/FlowBuilder/types';
import {
  Tooltip,
  CreateLineMenuParams,
  IMenuItem,
  MenuItemType,
} from '@components/FlowBuilder/views/Menu/types';
import { createStatefulPlugin } from '@components/FlowBuilder/views/createStatefulPlugin';
import { BLOCK_SUBTYPES } from '@components/FlowBuilder/consts';
import { PlatformHolder } from '@components/FlowBuilder/views/Menu/PlatformHolder';
import { createTexture } from '@components/FlowBuilder/assets/textures';
import { Texture, Resource } from 'pixi.js-legacy';
import { assignAdminPluginFragment_config } from './@types/assignAdminPluginFragment';
import { documentNodes } from './AssignAdminPluginQuery';
import { ReactComponent as AssignAdminPluginIcon } from './assets/menu_icon.svg';
import assignAdminSvg from './assets/assign_admin.svg';
import { AssignAdminPluginView } from './AssignAdminPluginView';
import { Platform } from '@globals';

export class AssignAdminPlugin extends FlowBuilderPlugin<
  AssignAdminPluginConfig,
  SaveAssignAdminPluginMutationCommonVariables
> {
  public id: PluginType = PluginType.conversation_assign_admin;
  public loadingPreviewHeight: number = 106;
  public isApolloBased: boolean = true;
  public inputField: string = PLUGIN_INPUT_FIELD;

  public tooltip(): Tooltip {
    // решили не добавлять тултип
    return {};
  }

  public getIconTexture(): Texture<Resource> {
    return createTexture(assignAdminSvg);
  }

  public getMenuItem(_: CreateLineMenuParams): IMenuItem | undefined {
    return {
      subtype: BLOCK_SUBTYPES.action,
      id: this.id,
      img: AssignAdminPluginIcon,
      platform: new PlatformHolder(
        Platform.whatsapp,
        Platform.facebook,
        Platform.instagram,
      ),
      title: this.getPluginDisplayName(),
      type: MenuItemType.plugin,
    };
  }

  public getPluginDisplayName(): string {
    return i18next.t(
      'modernComponents.FlowBuilder.views.components.AssignAdmin.plugin.displayName',
    );
  }

  public getDefaultConfig(): assignAdminPluginFragment_config {
    return PLUGIN_DEFAULT_CONFIG;
  }

  public getView(
    card: Card<assignAdminPluginFragment_config>,
    node: Node,
  ): MainLayout {
    return createStatefulPlugin(AssignAdminPluginView, card, node);
  }

  constructor() {
    super(documentNodes);
  }
}
