import React from 'react';
import { OverlayProps, TooltipOverlayOptions } from '../types';
import { TooltipPure } from '../../../../modern-ui/Tooltip2';
import { ButtonUnstyled } from '../../../../modern-ui/Button/Unstyled';
import { OverlayComponentEvent, overlayEventEmitter } from '../events';
import * as css from './Tooltip.css';

export const Tooltip: React.FC<OverlayProps> = ({ options }) => {
  const { text, html, url, width, height, onClick, children, position } =
    options as TooltipOverlayOptions;

  const htmlTitle = text || html;

  return (
    <TooltipPure
      data-testid="flowbuilder__tooltip"
      onTooltipMouseEnter={() => {
        overlayEventEmitter.emit(OverlayComponentEvent.mouseEnter);
      }}
      onTooltipMouseLeave={() => {
        overlayEventEmitter.emit(OverlayComponentEvent.mouseLeave);
      }}
      className={css.overlayTooltip}
      placement={position}
      content={
        children || (
          <ButtonUnstyled
            data-testid="flowbuilder__tooltip-button"
            onClick={({ target }) => {
              if (url) {
                overlayEventEmitter.emit(OverlayComponentEvent.onClick);
                window.open(url, '_blank');
              } else if (onClick) {
                overlayEventEmitter.emit(OverlayComponentEvent.onClick);
                onClick();
              } else {
                const {
                  tagName,
                  innerText: linkText,
                  href,
                } = target as HTMLAnchorElement;
                overlayEventEmitter.emit(
                  OverlayComponentEvent.onClick,
                  tagName.toUpperCase() === 'A'
                    ? { linkText, href }
                    : undefined,
                );
              }
            }}
            style={{
              outline: 'none',
            }}
          >
            <div
              data-testid="flowbuilder__tooltip-text"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={
                htmlTitle
                  ? {
                      __html: htmlTitle || '',
                    }
                  : undefined
              }
            />
          </ButtonUnstyled>
        )
      }
      boundariesElement="viewport"
      type="small"
      show={!!(htmlTitle || children)}
    >
      {(itemRef) => (
        <div
          data-testid="flowbuilder__tooltip-container"
          style={{
            width: width || 1,
            height: height || 1,
            opacity: 0,
            pointerEvents: 'none',
          }}
          ref={itemRef}
        />
      )}
    </TooltipPure>
  );
};
