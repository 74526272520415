import React from 'react';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import { sendEvent } from '@utils/Analytics';
import { customAiPluginFragment_config_aiConditions } from '../../../../../../Plugins/CustomAiPlugin/@types/customAiPluginFragment';
import { Condition } from './Condition/Condition';
import { Button } from '@ui/Button';
import { Icon } from '@ui/Icon';
import { EMPTY_CONDITION } from '../consts';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { SupportedModels } from './TokenCounter/useTokenCount';
import { Type } from '@ui/Type';
import { ServerStorageItemKeys } from '@utils/ServerStorage';
import { InfoCallout } from './InfoCallout/InfoCallout';

interface ConditionsTabProps {
  onUpdateConditions(
    newConditions: Array<customAiPluginFragment_config_aiConditions>,
  ): void;
  onSaveConditions(): void;
  conditions: Array<customAiPluginFragment_config_aiConditions>;
  botId: string;
  model: SupportedModels;
}

const MAX_CONNECTORS = 5;

export const ConditionsTab: React.FC<ConditionsTabProps> = ({
  onUpdateConditions,
  onSaveConditions,
  conditions,
  botId,
  model,
}) => {
  const { t } = useSafeTranslation();
  return (
    <div>
      <InfoCallout
        infoText={t(
          'modernComponents.FlowBuilder.views.components.CustomAiPlugin.Callouts.connectors',
        )}
        storageKey={ServerStorageItemKeys.customAiConnectorsCalloutHide}
      />
      <Spacer factor={6} />
      <Flex flexDirection="column" gapFactor={1}>
        {conditions.map((condition, i) => (
          <Condition
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            model={model}
            prompt={condition.prompt || ''}
            title={condition.title || ''}
            onBlur={() => onSaveConditions()}
            onDelete={() => {
              onUpdateConditions(conditions.filter((_, index) => index !== i))
              onSaveConditions();
              sendEvent({
                category: 'ai plugin',
                label: 'custom ai',
                action: 'delete connector',
                propertyBag: {
                  botId,
                },
              });
            }}
            onUpdate={(partialCondition) => {
              const updatedConditions = conditions.map((c, index) => {
                if (index !== i) {
                  return c;
                }
                return {
                  ...c,
                  ...partialCondition,
                };
              });
              onUpdateConditions(updatedConditions);
              sendEvent({
                category: 'ai plugin',
                label: 'custom ai',
                action: 'fill connectors tab',
                propertyBag: {
                  botId,
                  field_name: partialCondition.title,
                  field_content: partialCondition.prompt,
                },
              });
            }}
          />
        ))}

        {conditions.length < MAX_CONNECTORS && (
          <Flex gapFactor={2} alignItems="center">
            <Button
              intent="secondary"
              icon={<Icon icon="plus" />}
              data-testid="custom_ai_add_condition_button"
              onClick={() => {
                onUpdateConditions(conditions.concat([EMPTY_CONDITION]));
                onSaveConditions();
                sendEvent({
                  category: 'ai plugin',
                  label: 'custom ai',
                  action: 'add connector',
                  propertyBag: {
                    botId,
                  },
                });
              }}
            >
              {t(
                'modernComponents.FlowBuilder.views.components.CustomAiPlugin.connector',
              )}
            </Button>

            <Type size="15px" color="baseTertiary">
              {t(
                'modernComponents.FlowBuilder.views.components.CustomAiPlugin.maxConnectors',
                { count: MAX_CONNECTORS },
              )}
            </Type>
          </Flex>
        )}
      </Flex>
    </div>
  );
};
