import { CardButtonType } from '@globals';
import {
  cardButtonFragment_payment_summary,
  cardButtonFragment_shopify_page,
} from '../../../../Plugins/common/@types/cardButtonFragment';

export interface ButtonPopupOverlayConfig {
  type: CardButtonType;
  title: string;
  url?: string;
  phone_number?: string;
  webview_height_ratio?: string;
  shopify_page?: cardButtonFragment_shopify_page;
  payment_blocks?: string[];
  variables?: string[];
  payment_summary?: cardButtonFragment_payment_summary;
  description?: string;
}

export enum ButtonTypes {
  default = 'default',
  url = 'url',
  phone = 'phone',
  shopify_page = 'shopify_page',
  payment = 'payment',
  listButton = 'listButton',
}
