import { useEffect } from 'react';

export const usePreventOverscrollBehavior = () => {
  useEffect(() => {
    document.body.style.setProperty('overscroll-behavior', 'none');

    return () => {
      document.body.style.removeProperty('overscroll-behavior');
    };
  }, []);
};
