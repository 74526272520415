import React, { useCallback } from 'react';
import {
  HeaderMediaFileSelectorData,
  HeaderParameterMediaType,
} from '../../types';
import * as css from './HeaderMediaFileSelectorValue.css';
import { Flex } from '@ui/Flex';
import { Icon } from '@ui/Icon';
import { Type } from '@ui/Type';
import { TextEllipsis } from '@ui/TextEllipsis';
import { ButtonUnstyled } from '@ui/Button';
import { IconNames } from '@ui/Icon/icons';

interface HeaderMediaFileSelectorValueProps {
  value: HeaderMediaFileSelectorData;
  type: HeaderParameterMediaType;
  disabled?: boolean;
  onRemoveRequest: () => void;
}

const TYPE_TO_ICON_NAME: Record<HeaderParameterMediaType, IconNames> = {
  [HeaderParameterMediaType.document]: 'note',
  [HeaderParameterMediaType.video]: 'videoPlaceholder',
  [HeaderParameterMediaType.image]: 'imagePlaceholder',
};

export const HeaderMediaFileSelectorValue: React.FC<HeaderMediaFileSelectorValueProps> =
  ({ type, value: { link, filename }, onRemoveRequest, disabled }) => {
    const linkClickHandler = useCallback(() => {
      if (!link) {
        return;
      }
      window.open(link, '_blank');
    }, [link]);

    if (type === HeaderParameterMediaType.image && link) {
      return (
        <div className={css.imageBox}>
          <img className={css.image} src={link} alt="" />
          <ButtonUnstyled
            onClick={linkClickHandler}
            className={css.imageFileName}
          >
            <Type color="baseLight">
              <TextEllipsis>{filename || link.split('/').pop()}</TextEllipsis>
            </Type>
          </ButtonUnstyled>
          {!disabled && (
            <ButtonUnstyled
              className={css.imageRemove}
              onClick={onRemoveRequest}
            >
              <Icon icon="delete" color="blue" />
            </ButtonUnstyled>
          )}
        </div>
      );
    }

    return (
      <Flex gapFactor={2}>
        <Flex className={css.box}>
          <Flex
            className={css.iconBox}
            alignItems="center"
            justifyContent="center"
          >
            <Icon icon={TYPE_TO_ICON_NAME[type]} color="cobaltTertiary" />
          </Flex>
          <Flex
            className={css.filenameBox}
            justifyContent="space-between"
            alignItems="center"
            title={filename || undefined}
          >
            <Type>
              <TextEllipsis width={270}>
                {filename || link?.split('/').pop()}
              </TextEllipsis>
            </Type>
            <ButtonUnstyled onClick={linkClickHandler}>
              <Icon icon="expand" color="accent1Normal" />
            </ButtonUnstyled>
          </Flex>
        </Flex>
        {!disabled && (
          <ButtonUnstyled className={css.remove} onClick={onRemoveRequest}>
            <Icon icon="delete" color="accent1Normal" />
          </ButtonUnstyled>
        )}
      </Flex>
    );
  };
