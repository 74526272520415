import { EventEmitter } from '../../../utils/EventEmitter';
import { OverlayOptions, OverlayPosition, OverlayType } from './types';

export * from './types';

export enum FlowBuilderOverlayEvent {
  mount = 'flow:overlay:mount',
  unmount = 'flow:overlay:unmount',
  show = 'flow:overlay:show',
  hide = 'flow:overlay:hide',
  move = 'flow:overlay:move',
}

export enum OverlayComponentEvent {
  change = 'overlay:change',
  keydown = 'overlay:keydown',
  input = 'overlay:input',
  add = 'overlay:add',
  mouseEnter = 'overlay:mouseEnter',
  mouseLeave = 'overlay:mouseLeave',
  onClick = 'overlay:onClick',
  done = 'overlay:done',
}

export interface MountOverlayEvent {
  overlayType: OverlayType;
  getOverlayOptions: () => OverlayOptions;
}

export interface OverlayEventPayload<T> {
  value: T;
  target: HTMLElement;
}

export interface MoveOverlayEvent {
  position: OverlayPosition;
}

export const overlayEventEmitter = new EventEmitter();
