import { Circle as PixiCircle, Graphics } from 'pixi.js-legacy';
import { colorToHex } from '../../../views/utils';
import { Shape } from './Shape';
import { CircleProps } from './types';

export class Circle extends Shape<CircleProps, Graphics> {
  TEST_NAME = 'Circle';

  constructor(props: CircleProps) {
    super({ radius: 0, ...props }, Graphics);
  }

  move() {
    const radius = this._props.radius || 0;
    this._shape.x = (this._props.x || 0) + radius;
    this._shape.y = (this._props.y || 0) + radius;
  }

  _drawShape() {
    const { _props: props, _shape: shape } = this;
    shape.clear();
    shape.beginFill(colorToHex(props.fill), props.opacity);
    shape.lineStyle(
      props.strokeWidth || 0,
      colorToHex(props.stroke),
      props.opacity,
    );
    shape.drawCircle(0, 0, props.radius || 0);
    shape.endFill();
    if (props.hitRadius) {
      shape.hitArea = new PixiCircle(0, 0, props.hitRadius);
    }
  }

  radius(r?: number) {
    return this._changeProp<number>('radius', r);
  }

  width(w?: number) {
    if (w === undefined) {
      return this._changeProp<number>('radius') * 2;
    }
    return this._changeProp<number>('radius', w / 2) * 2;
  }

  height(h?: number) {
    if (h === undefined) {
      return this._changeProp<number>('radius') * 2;
    }
    return this._changeProp<number>('radius', h / 2) * 2;
  }
}
