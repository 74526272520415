import { resByFunc } from '../../../views/utils';
import { PropFunc } from '../../../types';
import { Layout } from './Layout';
import { MarginType } from './types';

export const marginByFunc = (
  margin: PropFunc<MarginType> | undefined,
  view: Layout,
) => {
  const defaultMargin = { left: 0, right: 0, top: 0, bottom: 0 };
  const marginRes = resByFunc(margin, view) || {};
  return { ...defaultMargin, ...marginRes };
};

/**
 * todo remove
 * @deprecated
 */
export const defaultObj = <T1 extends Object, T2 extends Object>(
  obj1: T1,
  obj2: T2,
): T1 & T2 => {
  return { ...obj1, ...obj2 };
};
