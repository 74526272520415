import { useSafeTranslation } from '@utils/useSafeTranslation';
import React from 'react';
import { connectPage } from '@components/ConnectPageDialog';
import { Anchor } from '@ui/Links';
import { Button } from '@ui/Button';
import { Flex } from '@ui/Flex';
import { Icon } from '@ui/Icon';
import { Spacer } from '@ui/Spacer';
import { Tooltip2 } from '@ui/Tooltip2';
import { Type } from '@ui/Type';
import { sendEvent } from '@utils/Analytics';
import { AutomatedMessagesSource } from '@components/LivechatAutomatedMessages/components/types';
import { ManageMessagesButton } from '@components/LivechatAutomatedMessages/ManageMessagesButton/ManageMessagesButton';
import { useWhatsappEnabled } from '@utils/Whatsapp';
import { ManageSubscriptions } from '../../../../../../pages/LiveChat/components/ManageSubscriptions';
import { PluginDataChildrenParams } from '../../../../../Plugins/common';
import { EditorPanelPluginProps } from '../../../types';
import { PluginHeader } from '../../common/PluginHeader';
import { flowBuilderLiveChatPluginFragment_config as FlowBuilderLiveChatPluginConfig } from '../../../../../Plugins/FlowBuilderLiveChatPlugin/@types/flowBuilderLiveChatPluginFragment';
import * as css from './LiveChatPlugin.css';

export const LiveChatPlugin: React.FC<
  EditorPanelPluginProps<FlowBuilderLiveChatPluginConfig>
> = ({ botId, pluginParams }) => {
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  return <LiveChatPluginContent botId={botId} {...pluginParams} />;
};

interface LiveChatPluginContent
  extends PluginDataChildrenParams<FlowBuilderLiveChatPluginConfig> {
  botId: string;
}

const LiveChatPluginContent: React.FC<LiveChatPluginContent> = ({ botId }) => {
  const { t } = useSafeTranslation();
  const { isWhatsappEnabled } = useWhatsappEnabled();

  return (
    <>
      <PluginHeader title={t('LiveChatPlugin-string--134-live-chat')} />
      <Flex className={css.infoBadge}>
        <Icon icon="info" color="blue" />
        <Spacer horizontalFactor={1} />
        <Type size="15px">
          {t(
            'modernComponents.AutomatedMessagesDialog.livechatConfigurationRulesNote.text',
          )}
        </Type>
      </Flex>
      <Spacer factor={8} />
      {!isWhatsappEnabled && (
        <>
          <Type as="p" weight="semibold" size="15px">
            {t('pages.LiveChat.PluginSettings.newChatsNotificationsTitle')}
          </Type>
          <Spacer factor={3} />
          <Tooltip2
            placement="right"
            boundariesElement="viewport"
            hoverable
            content={
              <Type as="p" size="12px" color="white">
                {t(
                  'LiveChatPlugin-JSXText-1050-if-a-team-member-has-subscribed-to-notifications-theyll',
                )}{' '}
                {t(
                  'LiveChatPlugin-JSXText--385-receive-new-message-alerts-within-the',
                )}{' '}
                <Anchor
                  className={css.anchor}
                  target="_blank"
                  rel="noreferrer noopener"
                  href={t('common.helpDocLinks.message_tags')}
                  onClick={() => {
                    sendEvent({
                      category: 'live chat plugin',
                      action: 'click on 24h docs link',
                      propertyBag: {
                        botId,
                      },
                    });
                  }}
                >
                  {t('LiveChatPlugin-JSXText-4786-24-hour-window')}
                </Anchor>{' '}
                {t(
                  'LiveChatPlugin-JSXText--116-from-all-live-chat-cards-in-flows-or-automate',
                )}
              </Type>
            }
            type="small"
            positionFixed
          >
            {(ref, bind) => (
              <ManageSubscriptions botId={botId}>
                {({ showModal, isConnected }) =>
                  isConnected ? (
                    <Button
                      {...bind}
                      ref={ref}
                      onClick={() => {
                        sendEvent({
                          category: 'live chat plugin',
                          action: 'manage subscriptions',
                        });
                        showModal();
                      }}
                    >
                      {t('LiveChatPlugin-JSXText--174-manage-subscriptions')}
                    </Button>
                  ) : (
                    <Tooltip2
                      placement="right"
                      boundariesElement="viewport"
                      hoverable
                      content={
                        <Type as="p" size="12px" color="white">
                          {t(
                            'LiveChatPlugin-JSXText--668-connect-your-facebook-page-to',
                          )}
                          <br />
                          {t(
                            'LiveChatPlugin-JSXText-1075-manage-live-chat-subscriptions',
                          )}
                          <br />
                          <br />
                          <Anchor
                            className={css.anchor}
                            rel="noreferrer noopener"
                            onClick={() => {
                              sendEvent({
                                category: 'live chat plugin',
                                action: 'connect page',
                              });
                              connectPage({ botId });
                            }}
                          >
                            {t('LiveChatPlugin-JSXText-1387-connect-now')}
                          </Anchor>
                        </Type>
                      }
                      type="small"
                      positionFixed
                    >
                      {(ref, bind) => (
                        <div
                          {...bind}
                          ref={ref}
                          className={css.manageButtonContainer}
                        >
                          <Button disabled>
                            {t(
                              'LiveChatPlugin-JSXText--201-manage-subscriptions',
                            )}
                          </Button>
                        </div>
                      )}
                    </Tooltip2>
                  )
                }
              </ManageSubscriptions>
            )}
          </Tooltip2>
          <Spacer />
        </>
      )}
      <Type as="p" weight="semibold" size="15px">
        {t('pages.LiveChat.PluginSettings.automatedMessagesTitle')}
      </Type>
      <Spacer factor={3} />
      <ManageMessagesButton
        botId={botId}
        source={AutomatedMessagesSource.flow}
      />
    </>
  );
};
