import { jsonPluginFragment_config as JSONPluginConfig } from '@components/Plugins/JsonPlugin/@types/jsonPluginFragment';
import { PluginType } from '@components/Plugins/common/PluginTypes';
import { logFlowEvent } from '../../../../utils/Analytics';

interface UseJsonResponseMappingActionsArgs {
  pluginConfig: JSONPluginConfig;
  updateConfig(configPatch?: Partial<JSONPluginConfig>): void;
  savePluginSafely(configPatch?: Partial<JSONPluginConfig>): void;
}

export const useJsonResponseMappingActions = ({
  pluginConfig,
  updateConfig,
  savePluginSafely,
}: UseJsonResponseMappingActionsArgs) => {
  const custom_response_config = pluginConfig.custom_response_config ?? [];

  const switchResponseMapping = () => {
    logFlowEvent(PluginType.json_plugin, 'switch response mapping', {
      switchingOn: !pluginConfig.use_custom_response,
    });
    savePluginSafely({
      use_custom_response: !pluginConfig.use_custom_response,
    });
  };

  const handleAdd = () => {
    logFlowEvent(PluginType.json_plugin, 'add json path mapping');
    savePluginSafely({
      custom_response_config: [
        ...custom_response_config,
        { attr: '', path: '', __typename: 'JsonPathToAttr' },
      ],
    });
  };

  const handleDeleteAt = (index: number) => {
    logFlowEvent(PluginType.json_plugin, 'delete json path mapping');
    savePluginSafely({
      custom_response_config: custom_response_config.filter(
        (_, itemIndex) => itemIndex !== index,
      ),
    });
  };

  const handleChangeAttributeAt = (
    index: number,
    nextAttributeName: string,
  ) => {
    updateConfig({
      custom_response_config: custom_response_config.map((item, itemIndex) =>
        itemIndex !== index ? item : { ...item, attr: nextAttributeName },
      ),
    });
  };

  const handleChangeJsonPathAt = (index: number, nextJsonPath: string) => {
    updateConfig({
      custom_response_config: custom_response_config.map((item, itemIndex) =>
        itemIndex !== index ? item : { ...item, path: nextJsonPath },
      ),
    });
  };

  return {
    switchResponseMapping,
    handleAdd,
    handleDeleteAt,
    handleChangeAttributeAt,
    handleChangeJsonPathAt,
  };
};
