import { useMutation } from '@apollo/react-hooks';
import {
  ConfirmGoogleUser,
  ConfirmGoogleUserVariables,
} from '@utils/Data/Google/@types/ConfirmGoogleUser';
import {
  CONFIRM_GOOGLE_USER_MUTATION,
  GOOGLE_USERS_QUERY,
} from '@utils/Data/Google';
import { toaster } from '@services/MessageService';
import i18next from 'i18next';
import { log } from 'cf-common/src/logger';
import { getRequestIdFromApolloError } from '@utils/GQL/utils';

export const useConfirmGoogleUser = (botId: string) => {
  const [confirmGoogleUserRequest] = useMutation<
    ConfirmGoogleUser,
    ConfirmGoogleUserVariables
  >(CONFIRM_GOOGLE_USER_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GOOGLE_USERS_QUERY,
        variables: {
          botId,
        },
      },
    ],
    onError: (error) => {
      toaster.error({
        payload: {
          message: i18next.t(
            'GoogleSheet-string-3944-something-went-wrong-please-try-again-later',
          ),
        },
      });
      log.error({
        error,
        msg: 'Could not confirm google user',
        data: {
          label: 'flow_google_sheet',
          requestId: getRequestIdFromApolloError(error),
        },
      });
    },
  });

  /**
   * Add user info to db to be able to use user token later
   *
   * @param {string} code from google auth, we need to send to BE
   *
   * @returns {string | undefined} user Id if operation successful, `undefined` otherwise
   */
  const confirmGoogleUser = async (code: string) => {
    const result = await confirmGoogleUserRequest({ variables: { code } });
    return result.data?.confirmGoogleUser?.user.id;
  };

  return {
    confirmGoogleUser,
  };
};
