import React from 'react';
import { Callout } from '@ui/Callout';
import { useSafeTranslation } from '@utils/useSafeTranslation';

interface AttributesCalloutProps {
  spreadsheetHasItems: boolean;
  allAttributesChecked: boolean;
}

export const AttributesCallout: React.FC<AttributesCalloutProps> = ({
  allAttributesChecked,
  spreadsheetHasItems,
}) => {
  const { t } = useSafeTranslation();

  const attributesText = allAttributesChecked
    ? t('modernComponents.FlowBuilder.plugins.GoogleSheetPlugin.attributeInfo')
    : t(
        'modernComponents.FlowBuilder.plugins.GoogleSheetPlugin.attributeOrderInfo',
      );
  const text = spreadsheetHasItems
    ? t(
        'modernComponents.FlowBuilder.plugins.GoogleSheetPlugin.spreadsheetHasItemsWarn',
      )
    : attributesText;

  return (
    <Callout
      alignItems="flex-start"
      text={text}
      type={spreadsheetHasItems ? 'warning' : 'info'}
    />
  );
};
