import * as Types from './types';

export { Plugin } from './types';

interface StatefulPluginPublicAPI<TPluginConfig> {
  data: Types.Plugin<TPluginConfig>;
  set(plugin: Types.PluginGetter<TPluginConfig>): void;
  save(): Promise<void>;
  destroy(): void;
}

export interface StatefulPluginDelegate<TPluginConfig> {
  pluginDidSet?(
    prevState: Types.Plugin<TPluginConfig>,
    result: Types.SetPluginResult<TPluginConfig>,
  ): void;
  pluginDidSetError?(error: any): void;
  pluginDidSave?(
    prevState: Types.Plugin<TPluginConfig>,
    result: Types.SavePluginResult,
  ): void;
  pluginDidSaveError?(error: any): void;
  pluginWillSave?(): void;
}

export abstract class StatefulPlugin<TPluginConfig extends {}>
  implements StatefulPluginPublicAPI<TPluginConfig>
{
  protected delegate?: StatefulPluginDelegate<TPluginConfig>;

  protected prevState = {} as Types.Plugin<TPluginConfig>;

  protected setPrevState() {
    this.prevState = { ...this.data };
  }

  protected saving = false;

  protected pluginDidSet(
    data: Types.Plugin<TPluginConfig>,
    validationErrors: Types.ValidationErrors,
  ) {
    this.delegate?.pluginDidSet?.(this.prevState, { data, validationErrors });
    this.setPrevState();
  }

  protected pluginDidSetError(error: any) {
    this.delegate?.pluginDidSetError?.(error);
  }

  protected pluginDidSave(result: Types.SavePluginResult) {
    this.delegate?.pluginDidSave?.(this.prevState, result);
    this.setPrevState();
  }

  protected pluginDidSaveError(error: any) {
    this.delegate?.pluginDidSaveError?.(error);
  }

  protected pluginWillSave() {
    this.delegate?.pluginWillSave?.();
  }

  protected async handleAsyncSaveWithStatus<T = {}>(
    asyncSave: () => Promise<T>,
  ) {
    this.saving = true;

    let result: Types.SavePluginResult;
    let error: any;
    try {
      result = await asyncSave();
    } catch (e) {
      error = e;
      throw error;
    } finally {
      this.saving = false;
      if (error) {
        this.pluginDidSaveError(error);
      } else {
        this.pluginDidSave(result);
      }
    }
  }

  /**
   *
   * @param plugin is deprecated.
   * Is used only for the link to isEditing property.
   * Consider passing pluginId instead after removing
   * @param blockId
   */
  constructor(
    protected plugin: Types.Plugin<TPluginConfig>,
    protected blockId: string,
  ) {}

  public get isSaving(): boolean {
    return this.saving;
  }

  public get isEditing(): boolean {
    return Boolean(this.plugin.isEditing);
  }

  public setDelegate(delegate: StatefulPluginDelegate<TPluginConfig>) {
    if (this.delegate) {
      const pluginType = this.data?.plugin_id ?? 'unknown';
      throw new Error(
        `You have already initialized delegate for ${pluginType} plugin:${this.plugin.id}!`,
      );
    }
    this.delegate = delegate;
  }

  public setAndSave(plugin: Types.PluginGetter<TPluginConfig>) {
    this.set(plugin);
    return this.save();
  }

  public abstract get data(): Types.Plugin<TPluginConfig>;
  public abstract set(plugin: Types.PluginGetter<TPluginConfig>): void;
  public abstract save(): Promise<void>;
  public abstract destroy(): void;
}
