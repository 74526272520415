import debounce from 'lodash-es/debounce';
import { Card } from '@components/Plugins/common/PluginData';
import {
  createFlowPlugin,
  generatePluginId,
} from '@components/Plugins/common/createPlugin';
import { removeFlowCard, updateFlowBlock, updateFlowCard } from './api/network';
import {
  BlockView,
  CommentsAutoreplyContentBlockView,
  InstagramCommentsAutoreplyContentBlockView,
  FacebookAdsEntryPointBlockView,
  InstagramAdsEntryPointBlockView,
  InboundLinksBlockView,
  FacebookAdsJsonEntryPointBlockView,
  BroadcastRootBlockView,
} from './views/block_view';
import { mergeCounterIdData } from './utils/mergeCounterIdData';
import { BLOCK_SUBTYPES } from './consts';
import {
  createDefaultPlugin,
  isFacebookAds,
  isFacebookAdsJson,
} from './views/utils';
import { isApolloBasedPlugin } from './views/plugins.configuration';
import { Block, PluginType, FlowBlock, UpdateError } from './types';
import { UpdateParams } from './components/Elements/Layouts';
import { removeNullFields, removeNullFieldsDeep } from '@utils/GQL/utils';
import { LineView } from './views/components/Line/LineView';
import { FlowController } from './flow/controller';
import { Callback, ErrorCallback } from './api/common';
import { getEntryPointCard } from './views/entry-points/common/utils';
import { refetchJsonAdsDataIfNeed } from '@utils/Data/Marketing/getAdsJsonDataObservable';
import { removePluginFromCache } from './StatefulPlugin/ApolloState/utils';

export class Node {
  public id: string;
  public block: Block;
  public blockView: BlockView;
  public controller: Readonly<FlowController>;

  public x = 0;
  public y = 0;
  public startX?: number;
  public startY?: number;
  public newX?: number;
  public newY?: number;
  public isEditing = true;
  public editMode = true;
  public outLinks: Record<string, LineView[]> = {};
  public inLinks: Record<string, LineView[]> = {};

  public title?: string;
  public startingPoint?: boolean;

  constructor(block: FlowBlock, controller: Readonly<FlowController>) {
    this.block = block as Block;
    this.id = block.id;
    this.controller = controller;
    const position = block.position_in_flow;
    if (position != null) {
      this.x = position.x!;
      this.y = position.y!;
    }

    const entryPointPluginType = getEntryPointCard(block.cards)?.plugin_id;

    if (block.subtype === BLOCK_SUBTYPES.comments_autoreply_content) {
      this.blockView = new CommentsAutoreplyContentBlockView(this);
    } else if (
      block.subtype === BLOCK_SUBTYPES.instagram_comments_autoreply_content
    ) {
      this.blockView = new InstagramCommentsAutoreplyContentBlockView(this);
    } else if (
      block.subtype === BLOCK_SUBTYPES.entrypoint &&
      isFacebookAdsJson(entryPointPluginType)
    ) {
      this.blockView = new FacebookAdsJsonEntryPointBlockView(this);
    } else if (
      block.subtype === BLOCK_SUBTYPES.entrypoint &&
      isFacebookAds(entryPointPluginType) &&
      !isFacebookAdsJson(entryPointPluginType)
    ) {
      this.blockView = new FacebookAdsEntryPointBlockView(this);
    } else if (
      block.subtype === BLOCK_SUBTYPES.entrypoint &&
      [PluginType.instagram_ads_manager_ctm_entry_point].includes(
        entryPointPluginType as PluginType,
      )
    ) {
      this.blockView = new InstagramAdsEntryPointBlockView(this);
    } else if (block.subtype === BLOCK_SUBTYPES.flow_root) {
      this.blockView = new InboundLinksBlockView(this);
    } else if (block.subtype === BLOCK_SUBTYPES.broadcast_flow_root) {
      this.blockView = new BroadcastRootBlockView(this);
    } else {
      this.blockView = new BlockView(this);
    }
  }

  newPosition(x: number, y: number) {
    this.newX = x;
    this.newY = y;
    this.startX = this.x;
    this.startY = this.y;
  }

  renderNode(props?: UpdateParams) {
    this.blockView.renderNode(props);
  }

  addOutLink(toId: string | undefined, lineView: LineView) {
    if (typeof toId !== 'undefined') {
      const node = this.controller.nodeById(toId);
      if (typeof node !== 'undefined') {
        let outLinks = this.outLinks[toId];
        if (typeof outLinks === 'undefined') {
          outLinks = [];
          this.outLinks[toId] = outLinks;
        }
        if (!outLinks.includes(lineView)) {
          outLinks.push(lineView);
        }
        node._addInLink(this.id, lineView);
        lineView?.renderLine(node.blockView);
      }
    }
  }

  removeOutLink(
    toId: string | undefined,
    lineView: LineView,
    noRender?: boolean,
  ) {
    if (typeof toId !== 'undefined') {
      let outLinks = this.outLinks[toId];
      if (typeof outLinks !== 'undefined') {
        outLinks = outLinks.filter((line) => line !== lineView);
        this.outLinks[toId] = outLinks;
      }
      const toNode = this.controller.nodeById(toId);
      if (typeof toNode !== 'undefined') {
        toNode._removeInLink(this.id, lineView);
      }
      if (noRender !== true) {
        lineView.renderLine();
      }
    }
  }

  updateBlock(callback?: Function) {
    this.blockDebounce(callback);
  }

  fixConfig(card: Card<any>) {
    let result = card;
    switch (card.plugin_id) {
      case PluginType.gallery:
        if (card.config != null && card.config.gallery_cards != null) {
          card.config.gallery_cards.forEach((gCard: any) => {
            if (gCard.subtitle === '') {
              // eslint-disable-next-line no-param-reassign
              gCard.subtitle = undefined;
            }
            if (gCard.title === '') {
              // eslint-disable-next-line no-param-reassign
              gCard.title = undefined;
            }
            if (gCard.item_url === '') {
              // eslint-disable-next-line no-param-reassign
              gCard.item_url = undefined;
            }
          });
        }
        break;
      case PluginType.comment:
        if (card.config.plugin_id) {
          result = {
            id: card.id,
            plugin_id: PluginType.comment,
            config: { text: JSON.stringify(card.config) },
          } as Card<any>;
        }
        break;
      case PluginType.external_integration_entry_point:
      case PluginType.instagram_direct_entry_point:
      case PluginType.instagram_comments_autoreply_entry_point:
      case PluginType.instagram_story_mention_entry_point:
      case PluginType.instagram_story_reply_entry_point:
        // eslint-disable-next-line no-param-reassign
        card.config = removeNullFields(card.config);
        break;
      case PluginType.shopify_event_entry_point:
        // eslint-disable-next-line no-param-reassign
        card.config = removeNullFieldsDeep(card.config);
        break;
      default:
        break;
    }
    return result;
  }

  removeCard({ id: cardId }: Card<any>) {
    this.removeCardLinks(cardId);
    removePluginFromCache(cardId);
    removeFlowCard(cardId, () => {
      this.block.cards =
        this.block.cards?.filter(({ id }: any) => id !== cardId) ?? null;
    });
  }

  removeConnection(lineView: LineView) {
    if (lineView.cardId) {
      const card = this.cardById(lineView.cardId);
      this.updateCard(card);
    }
  }

  cardById(cardId: string) {
    return this.block.cards.find((card) => card.id === cardId) ?? null;
  }

  stopEditing() {
    if (this.isEditing) {
      this.isEditing = false;
      this.renderNode();
    }
  }

  startEditing() {
    this.isEditing = true;
    this.renderNode();
  }

  removeCardLinks(cardId: string) {
    Object.keys(this.outLinks).forEach((toId) => {
      this.outLinks[toId] = this.outLinks[toId].filter((line) => {
        if (line.cardId === cardId) {
          if (typeof line.toId !== 'undefined') {
            const toNode = this.controller.nodeById(line.toId()!);
            if (typeof toNode !== 'undefined') {
              toNode._removeInLink(this.id, line);
            }
          }
          return false;
        }
        return true;
      });
    });
  }

  removeAllLinks() {
    Object.values(this.outLinks).forEach((lines) => {
      lines.forEach((line) => {
        if (typeof line.toId() !== 'undefined') {
          const toNode = this.controller.nodeById(line.toId()!);
          if (typeof toNode !== 'undefined') {
            toNode._removeInLink(this.id, line);
          }
        }
      });
    });
    Object.values(this.inLinks).forEach((lines) => {
      lines.forEach((line) => {
        line.fromView.removeLink();
        line.renderLine(undefined);
        line.updateEnd();
      });
    });
  }

  updateLines(createHit?: boolean) {
    Object.values(this.outLinks).forEach((lines) => {
      lines.forEach((line) => {
        // eslint-disable-next-line no-param-reassign
        line._props.createHit = createHit;
        line.updateEnd();
      });
    });
    Object.values(this.inLinks).forEach((lines) => {
      lines.forEach((line) => {
        // eslint-disable-next-line no-param-reassign
        line._props.createHit = createHit;
        line.updateEnd();
      });
    });
  }

  _addInLink(blockId: string, lineView: LineView) {
    let inLinks = this.inLinks[blockId];
    if (typeof inLinks === 'undefined') {
      inLinks = [];
      this.inLinks[blockId] = inLinks;
    }
    if (!inLinks.includes(lineView)) {
      inLinks.push(lineView);
    }
  }

  _removeInLink(blockId: string, lineView?: LineView) {
    let inLinks = this.inLinks[blockId];
    if (typeof inLinks !== 'undefined') {
      inLinks = inLinks.filter((line) => line !== lineView);
      this.inLinks[blockId] = inLinks;
    }
  }

  organizeNextLevel(skipIds: string[]) {
    const allLines = Object.values(this.outLinks).flatMap((v) => v);
    allLines.sort((l1, l2) => {
      return (
        this.blockView.toLocal(l1.fromView.shape().getGlobalPosition()).y -
        this.blockView.toLocal(l2.fromView.shape().getGlobalPosition()).y
      );
    });

    let startY = 0;
    const startX = this.x + 500;
    const nodesToUpdate = [];
    allLines.forEach((l) => {
      const toId = l.toId();
      if (toId && !skipIds.includes(toId)) {
        const nextNode = this.controller.nodeById(toId);
        if (nextNode) {
          nextNode.y = startY;
          nextNode.x = startX;
          startY += nextNode.blockView.height() + 100;
          nodesToUpdate.push(nextNode);
        }
      }
    });
  }

  destroy() {
    this.outLinks = {};
    this.inLinks = {};
    // @ts-expect-error
    this.controller = undefined;
  }

  // ================================================================
  // Warning!
  // These next methods should be re-checked and refactored!
  // ================================================================
  addPlugin(
    plugin_id: PluginType,
    onSuccess: Callback,
    idx: number,
    onError: ErrorCallback,
  ) {
    if (isApolloBasedPlugin(plugin_id)) {
      const optimisticId = generatePluginId();
      createFlowPlugin(optimisticId, {
        botId: this.controller.flow.botId,
        blockId: this.id,
        pluginType: plugin_id,
        position: idx,
        blockSubtype: this.block.subtype,
      })
        // @ts-expect-error
        .then(onSuccess)
        // @ts-expect-error
        .catch(onError);
    } else {
      const plugin = createDefaultPlugin(plugin_id);
      // @ts-ignore
      plugin.position = idx;
      updateFlowCard(
        this.id,
        plugin,
        (result) => {
          // @ts-expect-error
          // eslint-disable-next-line no-param-reassign
          result.plugin_id = plugin.plugin_id;
          // @ts-expect-error
          onSuccess(result);
        },
        onError,
      );
    }
  }

  updateCard<T = Card>(
    card: T,
    onSuccess?: (dataResult: any, data: any) => void,
    onError?: (error: UpdateError, requestId: string) => void,
  ) {
    return updateFlowCard(
      this.id,
      // @ts-expect-error
      this.fixConfig(card),
      (dataResult, data) => {
        // @ts-expect-error
        const config = dataResult?.config;
        if (config) {
          // @ts-expect-error
          mergeCounterIdData(card.config, config);
        }
        onSuccess?.(dataResult, data);
        refetchJsonAdsDataIfNeed(this.controller.flow.botId, this.block.id);
      },
      // @ts-expect-error
      onError,
    );
  }

  blockDebounce = debounce((callback) => {
    updateFlowBlock({
      blockId: this.id,
      title: this.block.title,
      nextBlockIds: this.block.next_block_ids ?? [],
      position: {
        x: this.x,
        y: this.y,
      },
      callback,
      messageTag: this.block.message_tag,
      otnPurpose: this.block.otn_purpose,
      notificationTopicId: this.block.notification_topic_id,
    });
  }, 300);
}
