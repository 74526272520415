import React from 'react';
import cn from 'classnames';
import { Icon } from '@ui/Icon';
import {
  DndDraggable,
  DndDraggableAnchor,
  DndDroppable,
  DndProvider,
  onDropParams,
} from '@utils/Dnd';
import { Attribute } from '../Attribute/Attribute';
import * as css from './AttributesDnd.css';

interface AttributeDndProps {
  attributeNames: string[];
  editable: boolean;
  onAttributesRearrange(nextAttributeNames: string[]): void;
  onAttributeDeleteAt(index: number): void;
}

const placeholderStyle: React.CSSProperties = { marginLeft: 24 };

export const AttributesDnd: React.FC<AttributeDndProps> = ({
  attributeNames,
  editable,
  onAttributesRearrange,
  onAttributeDeleteAt,
}) => {
  if (!editable) {
    return (
      <div className={css.attributesContainer}>
        {attributeNames.map((attributeName) => (
          <Attribute key={attributeName} attributeName={attributeName} />
        ))}
      </div>
    );
  }

  const handleDrop = ({ draggingId, position }: onDropParams) => {
    const nextAttributeNames = [...attributeNames];
    const dragIndex = nextAttributeNames.findIndex(
      (attributeName, index) => `${attributeName}_${index}` === draggingId,
    );
    if (dragIndex === -1) return;
    nextAttributeNames.splice(dragIndex, 1);
    nextAttributeNames.splice(position, 0, attributeNames[dragIndex]);
    onAttributesRearrange(nextAttributeNames);
  };

  return (
    <DndProvider onDrop={handleDrop}>
      <DndDroppable
        id="attribute"
        type="attribute"
        placeholderStyle={placeholderStyle}
        className={cn(css.attributesContainer, css.dropZone)}
      >
        {attributeNames.map((attributeName, index) => {
          const key = `${attributeName}_${index}`;
          return (
            <DndDraggable key={key} type="attribute" id={key}>
              <Attribute
                attributeName={attributeName}
                draggableAnchor={
                  <DndDraggableAnchor type="attribute">
                    <Icon icon="drag" size="24px" />
                  </DndDraggableAnchor>
                }
                onDelete={() => onAttributeDeleteAt(index)}
              />
            </DndDraggable>
          );
        })}
      </DndDroppable>
    </DndProvider>
  );
};
