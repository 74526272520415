import React from 'react';
import cn from 'classnames';
import { Type } from '@ui/Type';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useTokenCount } from './useTokenCount';
import css from './TokenCounter.css';

interface TokenCounterProps {
  className?: string;
  text: string;
}

export const TokenCounter: React.FC<TokenCounterProps> = ({
  className,
  text,
}) => {
  const { count, calculatedText } = useTokenCount(text);
  const { t } = useSafeTranslation();

  return (
    <Type
      data-testid="openai_plugin_token_counter"
      className={cn(
        css.counter,
        calculatedText !== text && css.loading,
        className,
      )}
      size="12px"
    >{`${count} ${t(
      'modernComponents.FlowBuilder.views.components.OpenAiPlugin.tokens',
      { count },
    )}`}</Type>
  );
};
