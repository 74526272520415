import React from 'react';
import { Type } from '@ui/Type';
import { inboundLinksEntryPointFragment_config_blocks as InboundLinksAutomateBlock } from '../../../../../../Plugins/InboundLinksEntryPoint/@types/inboundLinksEntryPointFragment';
import { getAutomationBlockHref, logInboundLinkEvent } from '../helpers';
import { InboundLink } from './InboundLink';
import {
  FullStatsConfig,
  getFullCounterStatsShared,
} from '../../../../../utils/Statistics/Statistics';

interface AutomateBlockProps extends InboundLinksAutomateBlock {
  botId: string;
  blockId: string;
  flowId: string;
  statsConfig: FullStatsConfig | undefined;
}

export const AutomateBlock: React.FC<AutomateBlockProps> = ({
  id: blockId,
  botId,
  flowId,
  blockId: inboundLinksBlockId,
  title: blockTitle,
  buttons,
  redirects,
  quick_replies,
  statsConfig,
}) => {
  const logAutomateBlockLinkEvent = (href: string) => {
    logInboundLinkEvent({
      botId,
      flowId,
      channel: 'blocks',
      source: 'leftPanel',
      href,
    });
  };

  const getBlockHref = (cardId: string | null) =>
    getAutomationBlockHref({ blockId, botId, cardId });
  return (
    <>
      <Type size="15px" weight="semibold">
        {blockTitle}
      </Type>
      {!!buttons?.length &&
        buttons.map(({ card_id, counter_id, title }) => {
          const href = getBlockHref(card_id);
          const stats = statsConfig
            ? getFullCounterStatsShared(
                counter_id,
                inboundLinksBlockId,
                statsConfig,
              )
            : undefined;
          return (
            <InboundLink
              key={counter_id}
              title={title}
              stats={stats}
              href={href}
              onOpenHref={() => {
                logAutomateBlockLinkEvent(href);
              }}
            />
          );
        })}
      {!!redirects?.length &&
        redirects.map(({ counter_id, card_id }) => {
          const href = getBlockHref(card_id);
          const stats = statsConfig
            ? getFullCounterStatsShared(
                counter_id,
                inboundLinksBlockId,
                statsConfig,
              )
            : undefined;
          return (
            <InboundLink
              key={counter_id}
              title="Redirect"
              stats={stats}
              href={href}
              onOpenHref={() => {
                logAutomateBlockLinkEvent(href);
              }}
            />
          );
        })}
      {!!quick_replies?.length &&
        quick_replies.map(({ card_id, counter_id, title }) => {
          const href = getBlockHref(card_id);
          const stats = statsConfig
            ? getFullCounterStatsShared(
                counter_id,
                inboundLinksBlockId,
                statsConfig,
              )
            : undefined;
          return (
            <InboundLink
              key={counter_id}
              title={title}
              stats={stats}
              href={href}
              onOpenHref={() => {
                logAutomateBlockLinkEvent(href);
              }}
            />
          );
        })}
    </>
  );
};
