import { clone } from 'ramda';
import { log } from 'cf-common/src/logger';
import Maybe from 'graphql/tsutils/Maybe';
import { prepareFlowBlock } from '@utils/Data/Flow/Aggregated/helpers';
import {
  FlowBlockQuery,
  FlowBlockQueryVariables,
} from '../../../Plugins/common/@types/FlowBlockQuery';
import { FLOW_BLOCK_QUERY } from '../../../Plugins/common/createPlugin';
import client from '../../../../common/services/ApolloService';
import { FlowData } from '../../types';
import { FLOW_BLOCKS_QUERY } from '@utils/Data/Flow';
import {
  FlowBlocksQuery,
  FlowBlocksQueryVariables,
} from '@utils/Data/Flow/@types/FlowBlocksQuery';

export const refetchFlowBlock = async (
  blockId: string,
  { botId, id: flowId }: FlowData,
) => {
  try {
    const {
      data: { flowBlock },
    } = await client.query<FlowBlockQuery, FlowBlockQueryVariables>({
      query: FLOW_BLOCK_QUERY,
      variables: {
        blockId,
        botId,
      },
      fetchPolicy: 'network-only',
    });
    return prepareFlowBlock(flowBlock, { botId, flowId });
  } catch (error) {
    log.warn({ error, msg: 'Error flow block refetch' });
  }
  return null;
};

const updateFlowBlocksCache = async (
  botId: string,
  flowId: string,
  updateFn: (data: FlowBlocksQuery | null) => Maybe<FlowBlocksQuery>,
) => {
  let data;
  try {
    data = client.readQuery<FlowBlocksQuery, FlowBlocksQueryVariables>({
      query: FLOW_BLOCKS_QUERY,
      variables: { botId, flowId },
    });
  } catch {
    data = null;
  }

  const updatedData = updateFn(data);

  if (updatedData === undefined) {
    return;
  }

  client.writeQuery({
    query: FLOW_BLOCKS_QUERY,
    variables: { botId, flowId },
    data: updatedData,
  });
};

export const refetchFlowBlockAndUpdateInFlowBlocksCache = async (
  blockId: string,
  flow: FlowData,
) => {
  const { id: flowId, botId } = flow;
  const block = await refetchFlowBlock(blockId, flow);
  if (block) {
    updateFlowBlocksCache(botId, flowId, (data) => {
      const dataClone = clone(data);

      if (!dataClone?.bot.flowBlocks) {
        return undefined;
      }

      const existingBlock = dataClone.bot.flowBlocks.find(
        ({ id }) => id === blockId,
      );
      if (!existingBlock) {
        dataClone.bot.flowBlocks.push(block);
      }

      return dataClone;
    });
  }
};

export const updateFlowBlocksCacheAfterDeleting = (
  botId: string,
  flowId: string,
  removedBlockId: string,
) => {
  updateFlowBlocksCache(botId, flowId, (data) => {
    const dataClone = clone(data);

    if (!dataClone?.bot.flowBlocks) {
      return undefined;
    }

    dataClone.bot.flowBlocks = dataClone.bot.flowBlocks.filter(
      ({ id }) => id !== removedBlockId,
    );

    return dataClone;
  });
};
