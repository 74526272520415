import i18next from 'i18next';
import { DataProxy } from 'apollo-cache';
import { useMutation } from '@apollo/react-hooks';
import {
  CreateGoogleSheetMutation,
  CreateGoogleSheetMutationVariables,
} from '@utils/Data/Google/@types/CreateGoogleSheetMutation';
import {
  CREATE_GOOGLE_SHEET_MUTATION,
  GOOGLE_SHEETS_QUERY,
} from '@utils/Data/Google';
import { toaster } from '@services/MessageService';
import { log } from 'cf-common/src/logger';
import { getRequestIdFromApolloError } from '@utils/GQL/utils';
import { GoogleSpreadsheets } from '@utils/Data/Google/@types/GoogleSpreadsheets';

const tryReadSpreadsheetsFromCache = (
  store: DataProxy,
  botId: string,
  ownerId: string,
) => {
  try {
    return store.readQuery<GoogleSpreadsheets>({
      query: GOOGLE_SHEETS_QUERY,
      variables: {
        botId,
        ownerId,
      },
    });
  } catch {
    return null;
  }
};

export const useCreateSpreadsheet = (
  botId: string,
  ownerId?: string | null,
) => {
  const [createGoogleSheetRequest, { loading }] = useMutation<
    CreateGoogleSheetMutation,
    CreateGoogleSheetMutationVariables
  >(CREATE_GOOGLE_SHEET_MUTATION, {
    update: (store, { data }) => {
      const newSpreadsheet = data?.createGoogleSpreadsheet?.spreadsheet;
      if (!newSpreadsheet || !ownerId) return;
      const cached = tryReadSpreadsheetsFromCache(store, botId, ownerId);
      if (!cached) return;
      store.writeQuery<GoogleSpreadsheets>({
        query: GOOGLE_SHEETS_QUERY,
        data: {
          bot: {
            ...cached!.bot,
            googleSpreadsheets: [
              ...cached!.bot.googleSpreadsheets,
              newSpreadsheet,
            ],
          },
        },
        variables: {
          botId,
          ownerId,
        },
      });
    },
    onError: (error) => {
      toaster.error({
        payload: {
          message: i18next.t(
            'GoogleSheet-string-4303-oops-couldnt-create-google-sheet-reconnect-your-account-or-try-again-later',
          ),
        },
      });
      log.error({
        error,
        msg: 'Could not create Google Sheet',
        data: {
          label: 'flow_google_sheet',
          requestId: getRequestIdFromApolloError(error),
        },
      });
    },
  });

  const createGoogleSheet = async (sheetTitle: string) => {
    if (!ownerId) return null;

    const { data } = await createGoogleSheetRequest({
      variables: {
        botId,
        title: sheetTitle,
        owner_id: ownerId,
      },
    });

    return data?.createGoogleSpreadsheet?.spreadsheet?.id ?? null;
  };

  return {
    createGoogleSheet,
    googleSheetCreating: loading,
  };
};
