import React from 'react';
import { head } from 'ramda';
import { Type } from '@ui/Type';
import { inboundLinksEntryPointFragment_config_broadcasts_block as InboundLinksBroadcastBlock } from '../../../../../../Plugins/InboundLinksEntryPoint/@types/inboundLinksEntryPointFragment';
import { getBroadcastBlockHref, logInboundLinkEvent } from '../helpers';
import { InboundLink } from './InboundLink';
import {
  FullStatsConfig,
  getFullCounterStatsShared,
} from '../../../../../utils/Statistics/Statistics';

interface BroadcastBlockProps extends InboundLinksBroadcastBlock {
  botId: string;
  flowId: string;
  blockId: string;
  broadcastId: string;
  statsConfig: FullStatsConfig | undefined;
}

export const BroadcastBlock: React.FC<BroadcastBlockProps> = ({
  botId,
  flowId,
  broadcastId,
  id: blockId,
  blockId: inboundLinksBlockId,
  title: broadcastTitle,
  buttons,
  quick_replies,
  statsConfig,
  type: broadcastType,
}) => {
  const logBroadcastBlockLinkEvent = (href: string) => {
    logInboundLinkEvent({
      botId,
      flowId,
      channel: 'broadcasts',
      source: 'leftPanel',
      href,
    });
  };
  const getBlockHref = (cardId: string | null) =>
    getBroadcastBlockHref({
      botId,
      blockId,
      broadcastId,
      broadcastType,
      cardId,
    });

  return (
    <>
      <Type size="15px" weight="semibold">
        {head(broadcastTitle.split('|'))}
      </Type>
      {!!buttons?.length &&
        buttons.map(({ card_id, counter_id, title }) => {
          const href = getBlockHref(card_id);
          const stats = statsConfig
            ? getFullCounterStatsShared(
                counter_id,
                inboundLinksBlockId,
                statsConfig,
              )
            : undefined;
          return (
            <InboundLink
              key={counter_id}
              title={title}
              stats={stats}
              href={href}
              onOpenHref={() => {
                logBroadcastBlockLinkEvent(href);
              }}
            />
          );
        })}
      {!!quick_replies?.length &&
        quick_replies.map(({ card_id, counter_id, title }) => {
          const href = getBlockHref(card_id);
          const stats = statsConfig
            ? getFullCounterStatsShared(
                counter_id,
                inboundLinksBlockId,
                statsConfig,
              )
            : undefined;
          return (
            <InboundLink
              key={counter_id}
              title={title}
              stats={stats}
              href={href}
              onOpenHref={() => {
                logBroadcastBlockLinkEvent(href);
              }}
            />
          );
        })}
    </>
  );
};
