import { customAiPluginFragment_config_aiConditions } from "../../../../../Plugins/CustomAiPlugin/@types/customAiPluginFragment";

export const EMPTY_CONDITION: customAiPluginFragment_config_aiConditions = ({
  prompt: '',
  title: '',
  statId: null,
  blockId: null,
  __typename: 'CustomAiPluginCondition',
});

export enum SettingsTab {
  info = 'info',
  connectors = 'connectors',
}

export const CHANGE_DEBOUNCE_TIMEOUT = 300;
