import { Container, SCALE_MODES, Sprite, Texture } from 'pixi.js-legacy';
import {
  getPixiFieldStrict,
  getFlowControllerStrict,
} from '../../../PixiFieldRepository';
import { PropFunc } from '../../../types';
import { CornerRadiusAdv } from '../Layouts/types';
import { DESTROY_OPTIONS, Shape } from './Shape';
import { ImageProps } from './types';
import { Rect } from './Rect';

export class Image extends Shape<ImageProps, Sprite> {
  TEST_NAME = 'Image';

  _loadedUrl?: string;

  _image: Sprite;

  _mask: Rect;

  _container: Container;

  private wasDestroyed?: boolean;

  constructor(props: ImageProps) {
    super({ url: '', ...props }, Sprite);
    this._image = new Sprite();
    this._mask = new Rect({
      cornerRadius: this._props.cornerRadius,
      corners: this._props.corners,
    });
    this._container = new Container();
    this._container.addChild(this._image);
    this._container.addChild(this._mask._rect);
    this._image.mask = this._mask._rect;
  }

  destroy() {
    if (this.wasDestroyed) {
      return;
    }
    this.wasDestroyed = true;
    super.destroy();
    this._image.destroy(DESTROY_OPTIONS);
    this._mask.destroy();
    this._container.destroy(DESTROY_OPTIONS);
  }

  url(url?: string) {
    return this._changeProp('url', url) as string;
  }

  corners(corners?: PropFunc<CornerRadiusAdv>) {
    return this._changeProp<PropFunc<CornerRadiusAdv>>('corners', corners);
  }

  _drawShape() {
    if (!this._props.url) {
      return;
    }
    if (this._loadedUrl !== this._props.url) {
      const { url } = this._props;
      const imgObj = this;
      getFlowControllerStrict()
        .imageLoader.load(url)
        .then(({ texture }) => {
          if (texture) {
            try {
              imgObj.cropAndScale(texture, url);
            } catch (e) {
              console.error(e);
            }
          }
          this._props.onReady?.();
        });
    } else {
      if (!this._image?.texture) {
        return;
      }
      this.cropAndScale(this._image.texture, this._loadedUrl);
    }
  }

  cropAndScale(texture: Texture, url: string) {
    this._loadedUrl = url;
    this._image.texture = texture;
    this._mask.width(this._props.width);
    this._mask.height(this._props.height);
    this._mask.cornerRadius(this._props.cornerRadius);
    this._mask.corners(this._props.corners);
    this._mask.renderElement();
    const width = this.width();
    const height = this.height();
    let imgY = 0;
    let imgX = 0;
    let imgHeight = texture.height;
    let imgWidth = texture.width;
    let scale;
    if (width / imgWidth > height / imgHeight) {
      scale = width / imgWidth;
      const newHeight = imgHeight * scale;
      imgY = (newHeight - height) / 2;
      imgHeight = newHeight;
      imgWidth = width;
    } else {
      scale = height / imgHeight;
      const newWidth = imgWidth * scale;
      imgX = (newWidth - width) / 2;
      imgWidth = newWidth;
      imgHeight = height;
    }
    if (this._image.position) {
      this._image.position.set(-imgX, -imgY);
    }
    this._image.width = imgWidth;
    this._image.height = imgHeight;
    this._shape.texture = getPixiFieldStrict().renderer.generateTexture(
      this._container,
      {
        scaleMode: SCALE_MODES.LINEAR,
        resolution: window.devicePixelRatio || 1,
      },
    );
  }
}
