import React from 'react';
import { Flex } from '@ui/Flex';
import { IconButton } from '@ui/IconButton';
import { Type } from '@ui/Type';
import { TextEllipsis } from '@ui/TextEllipsis';
import * as css from './Attribute.css';

interface AttributeProps {
  attributeName: string;
  draggableAnchor?: React.ReactNode;
  onDelete?(): void;
}

export const Attribute: React.FC<AttributeProps> = ({
  attributeName,
  draggableAnchor,
  onDelete,
}) => {
  return (
    <Flex
      alignItems="center"
      className={css.attributeLine}
      data-testid="google_sheet_attribute"
    >
      {draggableAnchor && (
        <div className={css.draggableAnchor}>{draggableAnchor}</div>
      )}
      <Flex
        alignItems="center"
        justifyContent="space-between"
        className={css.attributeBox}
      >
        <TextEllipsis width={300}>
          <Type size="15px">{attributeName}</Type>
        </TextEllipsis>
        {onDelete && (
          <IconButton
            icon="close"
            size="24px"
            className={css.closeButton}
            onClick={onDelete}
            noBackground
          />
        )}
      </Flex>
    </Flex>
  );
};
