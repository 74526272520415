import { BotTabs, getTabLink } from '@utils/Routing';
import { Translation } from '@translations';
import { logFlowEvent } from '../../../../utils/Analytics';
import {
  inboundLinksEntryPointFragment_config_blocks as Block,
  inboundLinksEntryPointFragment_config_broadcasts as Broadcast,
  inboundLinksEntryPointFragment_config_flows as Flow,
} from '../../../../../Plugins/InboundLinksEntryPoint/@types/inboundLinksEntryPointFragment';

export const hasFlowsToRender = (flows: Flow[]) =>
  flows.length > 0 && flows.some((flow) => flow.blocks.length);

export const hasAutomateBlocksToRender = (blocks: Block[]) =>
  blocks.length > 0 &&
  blocks.some((block) =>
    Boolean(
      block.buttons?.length ||
        block.quick_replies?.length ||
        block.redirects?.length,
    ),
  );

export const hasBroadcastsToRender = (broadcasts: Broadcast[]) =>
  broadcasts.length > 0 &&
  broadcasts.some(
    ({ block }) =>
      block && Boolean(block.buttons?.length || block.quick_replies?.length),
  );

export const getAutomationBlockHref = ({
  botId,
  blockId,
  cardId,
}: {
  botId: string;
  blockId: string;
  cardId: string | null;
}) =>
  `${getTabLink(BotTabs.automate, botId, {
    blockId,
    cardId: cardId ?? undefined,
  })}`;

export const getFlowsBlockHref = ({
  botId,
  flowId,
  blockId,
  cardId,
}: {
  blockId: string;
  botId: string;
  flowId: string;
  cardId: string | null;
}) =>
  `${getTabLink(BotTabs.flows, botId, {
    blockId,
    flowId,
    cardId: cardId ?? undefined,
  })}`;

export const getBroadcastBlockHref = ({
  blockId,
  botId,
  broadcastId,
  broadcastType,
  cardId,
}: {
  botId: string;
  blockId: string;
  broadcastId: string;
  broadcastType: string | null;
  cardId: string | null;
}) =>
  `${getTabLink(BotTabs.reEngage, botId, {
    blockId,
    broadcastType: broadcastType ?? undefined,
    broadcastId,
    cardId: cardId ?? undefined,
  })}`;

enum Source {
  card = 'card',
  leftPanel = 'leftPanel',
}

enum Channel {
  flows = 'flows',
  blocks = 'blocks',
  broadcasts = 'broadcasts',
}

interface LinkEventLogProps {
  botId: string;
  flowId: string;
  href: string;
  source: keyof typeof Source;
  channel: keyof typeof Channel;
}

export const logInboundLinkEvent = ({
  botId,
  flowId,
  href,
  source,
  channel,
}: LinkEventLogProps) => {
  logFlowEvent('inbound links', 'open link', {
    source: Source[source],
    channel: Channel[channel],
    href,
    flowId,
    botId,
  });
};

interface MoreButtonLogProps {
  botId: string;
  flowId: string;
  count: number;
  channel: keyof typeof Channel;
}

export const logMoreButtonEvent = ({
  flowId,
  botId,
  count,
  channel,
}: MoreButtonLogProps) => {
  logFlowEvent('inbound links', 'click more button', {
    channel: Channel[channel],
    count,
    botId,
    flowId,
  });
};

export const getI18nInboundLinksKey = (shortKey: string) =>
  `modernComponents.FlowBuilder.views.entryPoints.InboundLinks.${shortKey}` as Translation;
