import { AttributesQuery_bot_variableSuggest as Attribute } from '@utils/AttributesUtils/@types/AttributesQuery';
import { VariablesType } from '@globals';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { isValidJsonPath } from './utils';

interface UseJsonPathToAttributeValidateArgs {
  attr: string;
  path: string;
  botAttributes: Attribute[];
  duplicatedAttributeNames: string[];
}

export const useJsonPathToAttributeValidate = ({
  attr,
  path,
  botAttributes,
  duplicatedAttributeNames,
}: UseJsonPathToAttributeValidateArgs) => {
  const { t } = useSafeTranslation();

  const isDuplicated = attr !== '' && duplicatedAttributeNames.includes(attr);
  const isSystem = botAttributes.some(
    ({ name, type }) => name === attr && type === VariablesType.system,
  );
  const jsonPathIsValid = path === '' || isValidJsonPath(path);

  const getErrorText: () => string | undefined = () => {
    if (!jsonPathIsValid) {
      return t(
        'modernComponents.FlowBuilder.plugins.JSONPlugin.errors.invalidJsonPath',
      );
    }
    if (isSystem) {
      return t(
        'modernComponents.FlowBuilder.plugins.JSONPlugin.errors.noSystemAttributes',
      );
    }
    if (isDuplicated) {
      return t(
        'modernComponents.FlowBuilder.plugins.JSONPlugin.errors.noDuplicates',
      );
    }

    return undefined;
  };

  return {
    hasAttributeError: isDuplicated || isSystem,
    hasJsonPathError: !jsonPathIsValid,
    errorText: getErrorText(),
  };
};
