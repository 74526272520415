import React from 'react';
import { Flex } from '@ui/Flex';
import * as css from '../FollowUpsList.css';
import { Icon } from '@ui/Icon';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import { useSafeTranslation } from '@utils/useSafeTranslation';

export const GlobalFollowUpsNote: React.FC = () => {
  const { t } = useSafeTranslation();
  return (
    <>
      <Flex className={css.noteBlock}>
        <Icon icon="info" color="blue" />
        <Spacer horizontalFactor={1} />
        <Type size="15px_DEPRECATED">
          {t('FollowUpsList-JSXText-1326-note-any-changes-will-be-reflected')}
          <br />
          {t(
            'FollowUpsList-JSXText--172-across-all-quick-reply-cards-in-this-flow',
          )}
        </Type>
      </Flex>
      <Spacer factor={5} />
    </>
  );
};
