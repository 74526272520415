import React from 'react';
import { useCurrentBotId, useWhatsappReEngageTabParams } from '@utils/Routing';
import { PluginType } from '@components/Plugins/common/PluginTypes';
import { EditorPanelPluginProps } from '../../types';
import { getPanelWidth } from '../../utils/panelDimensions';
import { usePluginState } from '../../hooks/usePluginState';
import * as Plugins from '../plugins';
import { ClosableEditorPanelView } from './EditorPanelView';
import { PluginData } from '@components/Plugins/common';
import { CenteredLoader } from '@ui/Loader';
import { useWhatsappBroadcast } from '@pages/BotPage/WhatsappReEngageTab/hooks/useWhatsappBroadcast';
import * as css from './EditorPanelPlugin.css';
import { isEditableBroadcast } from '@pages/BotPage/WhatsappReEngageTab/utils';

const PLUGIN_BY_TYPE: Partial<
  Record<PluginType, React.FC<EditorPanelPluginProps<any>>>
> = {
  [PluginType.google_sheet]: Plugins.GoogleSheetPlugin,
  [PluginType.add_attribute]: Plugins.AddAttributePlugin,
  [PluginType.json_plugin]: Plugins.JSONPlugin,
  [PluginType.open_ai]: Plugins.OpenAiPlugin,
  [PluginType.custom_ai]: Plugins.CustomAiPlugin,
  [PluginType.go_to_block_plugin]: Plugins.GoToBlockPlugin,
  [PluginType.email]: Plugins.EmailPlugin,
  [PluginType.flowbuilder_livechat]: Plugins.LiveChatPlugin,
  [PluginType.send_to_messenger_entry_point]: Plugins.SendToMessengerEntryPoint,
  [PluginType.customer_chat_entry_point]: Plugins.CustomerChatEntryPoint,
  [PluginType.shopify_customer_chat_entry_point]:
    Plugins.CustomerChatEntryPoint,
  [PluginType.whatsapp_widget_entry_point]: Plugins.EntryPointWhatsappWidget,
  [PluginType.whatsapp_popup_entry_point]: Plugins.EntryPointWhatsappPopup,
  [PluginType.popup_entry_point]: Plugins.PopupEntryPoint,
  [PluginType.shopify_back_in_stock]: Plugins.BackInStockEntryPoint,
  [PluginType.inbound_links_entry_point]: Plugins.InboundLinksEntryPoint,
  [PluginType.notification_messages_request]: Plugins.NTimeNotificationPlugin,
  [PluginType.quick_reply]: Plugins.QuickReplyPlugin,
  [PluginType.date_picker]: Plugins.DatePickerPlugin,
  [PluginType.whatsapp_template]: Plugins.WhatsappTemplatePlugin,
  [PluginType.kommo_plugin]: Plugins.KommoPlugin,
};

export const EditorPanelPlugin: React.FC<{ editorPanelId?: string }> = ({
  editorPanelId,
}) => {
  const botId = useCurrentBotId();
  const { plugin, reset } = usePluginState();

  const whatsappReEngageTabParams = useWhatsappReEngageTabParams();
  const broadcastId = whatsappReEngageTabParams?.broadcastId;
  const { data: broadcastData } = useWhatsappBroadcast(broadcastId);
  const isReadOnly =
    broadcastId && !isEditableBroadcast(broadcastData?.whatsappBroadcast);

  const { data, error } = plugin;

  if (!botId || !data || error || (broadcastId && !broadcastData)) {
    return null;
  }

  const Plugin = PLUGIN_BY_TYPE[data.pluginType];

  if (!Plugin) {
    throw new Error(`There is no plugin view for ${data.pluginType}`);
  }

  const width = getPanelWidth(data?.pluginType);

  const { pluginId, blockId, pluginType, position } = data;

  return (
    <PluginData
      id={pluginId}
      blockId={blockId}
      pluginType={pluginType}
      position={position}
      preventRefetchBlockLinksData
    >
      {(params) => (
        <ClosableEditorPanelView
          editorPanelId={editorPanelId}
          width={width}
          onClose={() => {
            /**
             * Лайв чат при сохранении своего конфига сохраняет глобальную настройку бота,
             * это отличается от обычных плагинов поэтому Лайв чат самостоятельно сохраняет
             * конфиг в модалке отдельной кверeй, поэтому pluginConfig не хранит актуальные данные
             */
            if (pluginType !== PluginType.flowbuilder_livechat) {
              /**
               * Некоторые плагины (например whatsapp_widget_entry_point) могут конфликтовать между
               * собой. Если добавить обработку конфликтов в метод pluginDidSaveError, то при его
               * сохранении на закрытие, ошибка может не обработаться, так как savePluginAsync
               * предоставляемый PluginData компонентом, ничего не знает про обработку ошибок стейтфул
               * плагином, поэтому стейтфул плагины надо сохранять через его апи, предварительно
               * проверив что сейтфул плагин есть (его может не быть для не стейтфул плагинов)
               *
               * Можно удалить когда все плагины будут сейтфул, поидее эти 2 сохранения эквивалетны.
               */
              if (data.state?.save) {
                data.state.save();
              } else {
                params.savePluginAsync(params.pluginConfig);
              }
            }

            reset();
          }}
        >
          {params.loading ? (
            <CenteredLoader />
          ) : (
            <div className={isReadOnly ? css.readonly : undefined}>
              <Plugin botId={botId} pluginData={data} pluginParams={params} />
            </div>
          )}
        </ClosableEditorPanelView>
      )}
    </PluginData>
  );
};
