import React, { useRef } from 'react';
import { Toggle } from 'react-powerplug';
import cn from 'classnames';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Flex } from '@ui/Flex';
import { Icon } from '@ui/Icon';
import { Input } from '@ui/Input';
import { Spacer } from '@ui/Spacer';
import { Tooltip2 } from '@ui/Tooltip2';
import { Type } from '@ui/Type';
import {
  ShopifyDiscounts,
  ShopifyDiscountsCode,
} from '@components/ShopifyDiscounts';
import { ShopifyAccountButton } from '@utils/Integrations/Shopify/ShopifyAccountButton/ShopifyAccountButton';
import { InAppBrowserWindowSelector } from '../InAppBrowserWindowSelector';
import * as css from './ButtonPopupOverlayShopify.css';

export const ButtonPopupOverlayShopify: React.FC<{
  discount: Partial<ShopifyDiscountsCode>;
  path?: string;
  webviewHeightRatio: string;
  onPathChange(path: string): void;
  onWebviewHeightChange(webviewHeight: string): void;
  onDiscountChange(discount: ShopifyDiscountsCode | null): void;
}> = ({
  discount,
  path,
  onPathChange,
  webviewHeightRatio,
  onWebviewHeightChange,
  onDiscountChange,
}) => {
  const { t } = useSafeTranslation();
  const boxRef = useRef<HTMLDivElement | null>(null);

  return (
    <Flex flexDirection="column">
      <ShopifyAccountButton />

      <Spacer factor={3} />

      <Flex flexDirection="column">
        <Flex>
          <Type size="15px_DEPRECATED" weight="semibold">
            {t('shopify.openShopifyStore.buttonPopupOverlay.goToPage')}
          </Type>
          <Spacer horizontalFactor={1} />
          <Tooltip2
            content={
              <Type size="12px" color="white">
                {t('shopify.openShopifyStore.buttonPopupOverlay.tooltip')}
              </Type>
            }
          >
            {(ref, bind) => (
              <Icon icon="info" color="grey" ref={ref} {...bind} />
            )}
          </Tooltip2>
        </Flex>
        <Spacer factor={2} />
        <Input
          value={path}
          onChange={(e) => {
            onPathChange(e.target.value);
          }}
          placeholder={t(
            'shopify.openShopifyStore.buttonPopupOverlay.enterUrl',
          )}
        />
      </Flex>

      <Spacer factor={3} />

      <InAppBrowserWindowSelector
        webviewHeightRatio={webviewHeightRatio}
        onChange={(value) => {
          onWebviewHeightChange(value);
        }}
      />

      <Spacer factor={3} />

      <Flex flexDirection="column">
        <Type size="15px_DEPRECATED" weight="semibold">
          {t('shopify.openShopifyStore.buttonPopupOverlay.availableCodes')}
        </Type>
        <Spacer factor={2} />
        <Toggle>
          {({
            on: isDiscountCodeSelectorOpened,
            toggle: toggleDiscountCodeSelectorOpened,
            set: setDiscountCodeSelectorOpened,
          }) => (
            <Flex className={css.codeContainer}>
              <Flex
                className={css.code}
                justifyContent="space-between"
                alignItems="center"
                onClick={toggleDiscountCodeSelectorOpened}
                title={discount.code}
                ref={boxRef}
              >
                <div className={css.codeBox}>
                  {discount.code ??
                    t('shopify.openShopifyStore.buttonPopupOverlay.noCode')}
                </div>
                <Icon
                  className={cn({
                    [css.codeIconReversed]: isDiscountCodeSelectorOpened,
                  })}
                  icon="triangle"
                />
              </Flex>

              {isDiscountCodeSelectorOpened && (
                <ShopifyDiscounts
                  onSelect={(discount) => {
                    onDiscountChange(discount);
                    setDiscountCodeSelectorOpened(false);
                  }}
                  onClose={() => setDiscountCodeSelectorOpened(false)}
                  offset="0px, 0px"
                  placement="bottom-end"
                  referenceElement={boxRef.current as Element}
                />
              )}
            </Flex>
          )}
        </Toggle>
      </Flex>
    </Flex>
  );
};
