import React, { useCallback } from 'react';
import { ColorPicker } from '@ui/ColorPicker';
import { sendEvent } from '@utils/Analytics';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { PluginControlLabel } from '../../../../../../../Plugins/common/PluginControlLabel';
import { ControlProps } from '../types';
import { HEXColors } from '@ui/_common/colors';

export interface ThemeColorConfig {
  theme_color: string | null;
}

export function ThemeColorControl<T extends ThemeColorConfig>({
  pluginConfig,
  state,
  pluginType,
}: ControlProps<T>) {
  const { t } = useSafeTranslation();

  const initialValue = pluginConfig.theme_color || HEXColors.white;

  const onChange = useCallback(
    (color) => {
      state.set(({ config }) => ({
        config: { ...config, theme_color: color },
      }));
      state.save();
    },
    [state],
  );

  const onDone = useCallback(
    (color) => {
      state.set(({ config }) => ({
        config: { ...config, theme_color: color },
      }));
      state.save();
      sendEvent({
        category: 'flows',
        action: 'change theme_color',
        label: pluginType,
        propertyBag: {
          theme_color: color,
        },
      });
    },
    [state, pluginType],
  );

  return (
    <PluginControlLabel
      label={t('CustomerChatEntryPointContent-string-1427-custom-colors')}
    >
      {(bind) => (
        <>
          <ColorPicker
            initialValue={initialValue}
            onChange={onChange}
            onDone={onDone}
            {...bind}
          />
        </>
      )}
    </PluginControlLabel>
  );
}
