import { useQuery } from 'react-apollo';
import {
  FollowUpsQuery,
  FollowUpsQuery_flowFollowUps,
  FollowUpsQueryVariables,
} from '../../../../../queries/@types/FollowUpsQuery';
import { FOLLOW_UPS_QUERY } from '../../../../../queries/FollowUpQuery';

export const useGlobalFollowUpsData = (flowId: string | undefined = '') => {
  const { data, ...queryStatus } = useQuery<
    FollowUpsQuery,
    FollowUpsQueryVariables
  >(FOLLOW_UPS_QUERY, {
    variables: { flowId },
    skip: !flowId,
  });

  return {
    followUps: data?.flowFollowUps || ([] as FollowUpsQuery_flowFollowUps[]),
    ...queryStatus,
  };
};
