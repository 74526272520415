import { useConnectGoogleAccount } from './useConnectGoogleAccount';
import { useCreateSpreadsheet } from './useCreateSpreadsheet';
import { generateTitle } from './helpers';
import {
  useGoogleSpreadsheets,
  useGoogleUsers,
} from '@utils/Data/Google/hooks';

export const useGoogleSheetData = (botId: string, ownerId?: string | null) => {
  const { googleUsers, googleUsersLoading } = useGoogleUsers(botId);
  const { googleSpreadsheets, googleSpreadsheetsLoading } =
    useGoogleSpreadsheets(botId, ownerId);
  const { accountConnecting, connectGoogleAccount } =
    useConnectGoogleAccount(botId);

  const { createGoogleSheet, googleSheetCreating } = useCreateSpreadsheet(
    botId,
    ownerId,
  );

  return {
    googleUsers,
    googleSpreadsheets,
    googleUsersLoading,
    googleSpreadsheetsLoading,
    connectGoogleAccount,
    accountConnecting,
    createGoogleSheet: () => {
      return createGoogleSheet(generateTitle(googleSpreadsheets));
    },
    googleSheetCreating,
  };
};
