interface MagicButtonParams {
  id: string;
  title: string;
  disabled?: boolean;
}

export class MagicButton {
  id: string;
  title: string;
  disabled: boolean;

  constructor({ id, title, disabled }: MagicButtonParams) {
    this.id = id;
    this.title = title;
    this.disabled = Boolean(disabled);
  }
}
