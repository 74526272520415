import React, { useState } from 'react';
import { CenteredLoader } from '@ui/Loader';
import { EditorPanelPluginProps } from '../../../types';
import { usePageConnected } from '../../../../../../utils/FacebookPages/usePageConnected';
import { whatsappPopupEntryPointFragment_config as WhatsappPopupEntryPointConfig } from '../../../../../Plugins/WhatsappPopupEntryPoint/@types/whatsappPopupEntryPointFragment';
import { getEntryPointClientCode } from '../common/getEntryPointClientCode';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { ChatPluginHeader } from '../CustomerChatEntryPoint/components/ChatPluginHeader';
import { CodeControl } from '../CustomerChatEntryPoint/components/controls';
import { Spacer } from '@ui/Spacer';
import { Trans } from 'react-i18next';
import { Anchor } from '@ui/Links';
import * as css from './EntryPointWhatsappPopup.css';
import { CollapsibleSectionWithShadow } from '@ui/CollapsibleSection/CollapsibleSectionWithShadow/CollapsibleSectionWithShadow';
import { TextControl } from './TextControl';
import { ImageUploadControl } from './ImageUploadControl';
import { Button } from '@ui/Button';
import { Portal } from 'react-portal';
import { useWhatsappSettings } from '@utils/Data/Whatsapp';

// Direct import integrating component
import { WhatsappPopup } from '../../../../../../../../entry-points-integration/src/integrationPlugins/WhatsappPopup/WhatsappPopup';

export const EntryPointWhatsappPopup: React.FC<
  EditorPanelPluginProps<WhatsappPopupEntryPointConfig>
> = ({ botId, pluginData }) => {
  const [isPreviewVisible, setPreviewVisible] = useState(false);
  const { t } = useSafeTranslation();
  const { loading } = usePageConnected(botId);
  const { whatsappSettings } = useWhatsappSettings(botId);

  if (loading || !pluginData.state) {
    return <CenteredLoader />;
  }

  const code = getEntryPointClientCode(pluginData.blockId);
  const pluginType = pluginData.state.data.plugin_id;

  return (
    <>
      <ChatPluginHeader pluginType={pluginType}>
        {t('modernComponents.FlowBuilder.EditorPanel.WhatsappPopup.title')}
      </ChatPluginHeader>

      <CodeControl
        code={code}
        pluginType={pluginType}
        hideInstructions
        light
        customLabel={
          <Trans
            t={t}
            components={[
              <Anchor
                intent="external"
                target="_blank"
                forceArrow
                className={css.tutorial}
                noUnderline
                href="https://docs.chatfuel.com/en/articles/6951048-whatsapp-entry-points"
              />,
            ]}
            i18nKey="modernComponents.FlowBuilder.EditorPanel.WhatsappPopup.code"
          />
        }
      />

      <Spacer factor={6} />

      <CollapsibleSectionWithShadow
        title={t(
          'modernComponents.FlowBuilder.EditorPanel.WhatsappPopup.appearance',
        )}
        initialValue
      >
        <div className={css.section}>
          <ImageUploadControl
            field="image"
            value={pluginData.state.data.config.image}
            pluginConfig={pluginData.state.data.config}
            pluginType={pluginType}
            state={pluginData.state}
            testid="whatsapp-signup-form__image-control"
            label={t(
              'modernComponents.FlowBuilder.EditorPanel.WhatsappPopup.fields.image.label',
            )}
            description={t(
              'modernComponents.FlowBuilder.EditorPanel.WhatsappPopup.fields.image.description',
            )}
          />
          <TextControl
            type="text"
            maxLength={100}
            label={t(
              'modernComponents.FlowBuilder.EditorPanel.WhatsappPopup.fields.header.label',
            )}
            placeholder={t(
              'modernComponents.FlowBuilder.EditorPanel.WhatsappPopup.fields.header.placeholder',
            )}
            testid="whatsapp-signup-form__header-control"
            field="header"
            value={pluginData.state.data.config.header || ''}
            state={pluginData.state}
            pluginType={pluginType}
            pluginConfig={pluginData.state.data.config}
          />

          <TextControl
            type="textarea"
            maxLength={250}
            label={t(
              'modernComponents.FlowBuilder.EditorPanel.WhatsappPopup.fields.text.label',
            )}
            placeholder={t(
              'modernComponents.FlowBuilder.EditorPanel.WhatsappPopup.fields.text.placeholder',
            )}
            testid="whatsapp-signup-form__text-control"
            field="text"
            value={pluginData.state.data.config.text || ''}
            state={pluginData.state}
            pluginType={pluginType}
            pluginConfig={pluginData.state.data.config}
          />

          <TextControl
            required
            type="text"
            maxLength={30}
            label={t(
              'modernComponents.FlowBuilder.EditorPanel.WhatsappPopup.fields.buttonText.label',
            )}
            placeholder={t(
              'modernComponents.FlowBuilder.EditorPanel.WhatsappPopup.fields.buttonText.placeholder',
            )}
            testid="whatsapp-signup-form__buttonText-control"
            field="buttonText"
            value={pluginData.state.data.config.buttonText || ''}
            state={pluginData.state}
            pluginType={pluginType}
            pluginConfig={pluginData.state.data.config}
          />

          <TextControl
            required
            disableEmoji
            type="number"
            label={t(
              'modernComponents.FlowBuilder.EditorPanel.WhatsappPopup.fields.delay.label',
            )}
            placeholder={t(
              'modernComponents.FlowBuilder.EditorPanel.WhatsappPopup.fields.delay.placeholder',
            )}
            testid="whatsapp-signup-form__delay-control"
            field="delay_s"
            maxLength={3}
            unit={t(
              'modernComponents.FlowBuilder.EditorPanel.WhatsappPopup.fields.delay.unit',
            )}
            value={pluginData.state.data.config.delay_s || 0}
            state={pluginData.state}
            pluginType={pluginType}
            pluginConfig={pluginData.state.data.config}
          />

          {whatsappSettings?.phone?.display_phone_number && (
            <div>
              <Button
                intent="secondary"
                onClick={() => setPreviewVisible(true)}
              >
                {t(
                  'modernComponents.FlowBuilder.EditorPanel.WhatsappPopup.preview',
                )}
              </Button>
            </div>
          )}
        </div>
      </CollapsibleSectionWithShadow>

      <Spacer factor={6} />

      <CollapsibleSectionWithShadow
        initialValue
        title={t(
          'modernComponents.FlowBuilder.EditorPanel.WhatsappPopup.messages',
        )}
      >
        <div className={css.section}>
          <TextControl
            type="text"
            required
            maxLength={80}
            label={t(
              'modernComponents.FlowBuilder.EditorPanel.WhatsappPopup.fields.prefilled.label',
            )}
            tooltip={t(
              'modernComponents.FlowBuilder.EditorPanel.WhatsappPopup.fields.prefilled.tooltip',
            )}
            placeholder={t(
              'modernComponents.FlowBuilder.EditorPanel.WhatsappPopup.fields.prefilled.placeholder',
            )}
            testid="whatsapp-signup-form__prefilledMessage-control"
            field="prefilledMessage"
            value={pluginData.state.data.config.prefilledMessage || 0}
            state={pluginData.state}
            pluginType={pluginType}
            pluginConfig={pluginData.state.data.config}
          />
        </div>
      </CollapsibleSectionWithShadow>

      {isPreviewVisible && (
        <Portal>
          <WhatsappPopup
            onClose={() => setPreviewVisible(false)}
            previewMode
            config={{
              buttonText: pluginData.state.data.config.buttonText,
              delay_s: pluginData.state.data.config.delay_s,
              header: pluginData.state.data.config.header,
              image: pluginData.state.data.config.image,
              prefilledMessage: pluginData.state.data.config.prefilledMessage,
              text: pluginData.state.data.config.text,
            }}
            entryPointId={null}
            phoneNumber={whatsappSettings?.phone?.display_phone_number || null}
          />
        </Portal>
      )}
    </>
  );
};
