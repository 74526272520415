import { IBaseTextureOptions, Sprite, Texture } from 'pixi.js-legacy';

import addBlockSvg from './controller/main_add_block.svg';
import organizeSvg from './controller/main_organize.svg';
import zoomInSvg from './controller/main_zoom_in.svg';
import zoomOutSvg from './controller/main_zoom_out.svg';
import instagramSvg from './controller/instagram.svg';
import fitSizeSvg from './controller/main_fit_size.svg';
import handBlackSvg from './controller/hand_black.svg';
import activeWizardSvg from './controller/main_wizard_active.svg';
import inactiveWizardSvg from './controller/main_wizard_inactive.svg';
import blockSendMsgSvg from './block/block_send_msg.svg';
import blockActionSvg from './block/block_action.svg';
import blockConditionSvg from './block/block_condition.svg';
import blockRedirectSvg from './block/block_redirect.svg';
import blockDelaySvg from './block/block_delay.svg';
import pluginAudioSvg from './plugin/audio.svg';
import blockSendDataSvg from './block/block_send_data.svg';
import blockImportContentSvg from './block/block_import_content.svg';
import blockReminderSvg from './block/block_reminder.svg';
import inboundLinksSvg from './block/inbound_links.svg';
import blockOrderReceiptSvg from './block/block_order_receipt.svg';
import blockPostPurchaseSvg from './block/block_post_purchase.svg';
import blockShopifyBackInStockOptinConfirmationSvg from './block/block_shopify_back_in_stock_optin_confirmation.svg';
import blockShopifyBackInStockOTNSvg from './block/block_shopify_back_in_stock_otn.svg';
import blockShopifyBackInStockMessageSvg from './block/block_shopify_back_in_stock_message.svg';
import pluginFbAnalyticsSvg from './plugin/fb_analytics.svg';
import pluginGallerySvg from './plugin/gallery.svg';
import pluginImageSvg from './plugin/image.svg';
import pluginJsonSvg from './plugin/json.svg';
import pluginHandoverSvg from './plugin/handover.svg';
import pluginEmailSvg from './plugin/email.svg';
import pluginOtnSvg from './plugin/otn.svg';
import pluginNTNSvg from './plugin/ntn.svg';
import pluginPhoneCallSvg from './plugin/phone_call.svg';
import pluginQuickReplySvg from './plugin/quick_reply.svg';
import collectAnswerSvg from './plugin/collect_answers.svg';
import collectEmailSvg from './plugin/collect_emails.svg';
import collectPhonesSvg from './plugin/collect_phones.svg';
import collectNumbersSvg from './plugin/collect_numbers.svg';
import pluginRemoveAttributeSvg from './plugin/remove_attribute.svg';
import pluginRemoveTagSvg from './plugin/remove_tag.svg';
import pluginAddAttributeSvg from './plugin/save_attribute.svg';
import pluginSaveEmailSvg from './plugin/save_email.svg';
import pluginSavePhoneSvg from './plugin/save_phone.svg';
import pluginAddTagSvg from './plugin/save_tag.svg';
import pluginTextSvg from './plugin/text.svg';
import pluginOpenUrlSvg from './plugin/url.svg';
import pluginUserInputSvg from './plugin/user_input.svg';
import pluginTriangleSvg from './plugin/triangle.svg';
import pluginVideoSvg from './plugin/video.svg';
import pluginDatepickerSvg from './plugin/datepicker.svg';
import removeSvg from './controller/remove.svg';
import testSvg from './controller/test.svg';
import testInstagramSvg from './controller/testInstagram.svg';
import testWhatsappSvg from './controller/testWhatsapp.svg';
import dragVerticalSvg from './controller/drag_vertical.svg';
import plusSvg from './controller/plus.svg';
import closeSvg from './controller/close.svg';
import ratioSvg from './controller/gallery_ratio.svg';
import photoSvg from './controller/add_photo.svg';
import cropSvg from './controller/crop.svg';
import duplicateSvg from '../../../modern-ui/Icon/icons/actions/Duplicate.svg';
import menuSvg from './controller/menu.svg';
import noteSvg from './controller/note.svg';
import checkboxSvg from './plugin/checkbox.svg';
import alertSvg from './plugin/alert.svg';
import questionMarkSvg from './common/question-mark.svg';
import addImageSvg from './controller/gallery_card_add_image.svg';
import addGalleryImageSvg from '../../../modern-ui/Icon/icons/actions/Add_Image.svg';
import rightArrowSvg from '../../../modern-ui/Icon/icons/actions/Right_Arrow.svg';
import loaderSvg from './plugin/loader.svg';
import loaderWhiteSvg from './plugin/loader_white.svg';
import openUrlSvg from './plugin/open_url.svg';
import openUrlRedSvg from './plugin/open_url_red.svg';
import openDatepickerSvg from './plugin/open_datepicker.svg';
import callPhoneSvg from './plugin/call_phone.svg';
import blockStartingSvg from './block/block_starting.svg';
import pluginCommentSvg from './plugin/comment.svg';
import pluginLiveChatSvg from './plugin/live-chat.svg';
import restartSvg from './controller/restart.svg';
import productSvg from './plugin/product_icon.svg';
import pluginGoogleSheetSvg from './plugin/google-sheet.svg';
import alarmClockSvg from './plugin/alarm-clock.svg';
import alarmClockActiveSvg from './plugin/alarm-clock-active.svg';
import textQuickRepliesSvg from './plugin/text_quick_replies.svg';
import pluginScheduleCalendlyEventSvg from './plugin/schedule-calendly-event.svg';
import pluginCalendlyConnectAccountSvg from './plugin/calendly-connect-account.svg';
import pluginCalendlyEventConfirmationSvg from './plugin/calendly-event-confirmation.svg';
import pluginCalendlyEventReminderSvg from './plugin/calendly-event-reminder.svg';
import pluginShopifyDiscountSvg from './plugin/shopify-discount.svg';
import pluginOpenAiSvg from './plugin/open-ai.svg';
import pluginCustomAiSvg from './plugin/custom-ai.svg';
import googleLogoSvg from '../../../modern-ui/Icon/icons/social/Google.svg';
import mediaShareSvg from './plugin/media_share.svg';
import choosePostSvg from './controller/choose_post.svg';
import instagramGalleyPost from './plugin/instagram_gallery_post.svg';
import instagramVideoPost from './plugin/instagram_video_post.svg';
import instagramAccountPreviewSvg from './plugin/instagram_account_preview.svg';
import bannerInfoIconSvg from './plugin/banner-info-icon.svg';
import externalLinkIconSvg from '../../../modern-ui/Icon/icons/actions/External_Link.svg';
import listIconSvg from '../../../modern-ui/Icon/icons/actions/list.svg';
import listErrorIconSvg from '../../../modern-ui/Icon/icons/actions/listError.svg';

import flowsChannelIconSvg from './entry_point/flows.svg';
import blocksChannelIconSvg from '../../../modern-ui/Icon/icons/channels/Blocks.svg';
import reengageChannelIconSvg from '../../../modern-ui/Icon/icons/tabs/ReEngage.svg';

import messengerSvg from './entry_point/messenger.svg';
import messageTagSvg from './controller/message_tag.svg';
import infoSvg from './entry_point/info.svg';
import attachmentSvg from './entry_point/attachment.svg';
import errorInfoSvg from './common/error-info.svg';
import errorInfoTriangleSvg from './plugin/error-info-triangle.svg';
import profilePlaceholderSvg from './common/profile-placeholder.svg';
import faqSvg from './entry_point/faq.svg';
import zapierSvg from './entry_point/zapier_orange.svg';
import QRCodeSvg from './common/qr_code.svg';
import UnknownSvg from './common/unknown_user.svg';
import zapeirPluginIconSvg from './entry_point/zapeir_plugin_icon.svg';
import shopifySvg from './common/shopify.svg';
import crossSvg from './common/cross.svg';
import receiptProductSvg from './plugin/receipt-product.svg';

import entryPointFacebookPageSvg from './entry_point/facebook_page.svg';
import persistentMenuUserInputSvg from './entry_point/persistent_menu_user_input.svg';
import instagramPersistentMenuUserInputSvg from './entry_point/instagram_persistent_menu_user_input.svg';
import shopifyEventAbandonedCartSvg from './entry_point/shopify_event_abandoned_cart.svg';
import shopifyEventOrderConfirmationSvg from './entry_point/shopify_event_order_confirmation.svg';
import shopifyEventOrderStatusUpdateSvg from './entry_point/shopify_event_order_status_update.svg';
import shopifyEventProductVisitSvg from './entry_point/product_visit_icon.svg';

import blockEntryPointCustomerChatEnabledSvg from './entry_point/block_entrypoint_customer_chat_enabled.svg';
import blockEntryPointCustomerChatDisabledSvg from './entry_point/block_entrypoint_customer_chat_disabled.svg';

import blockEntryPointShopifyCustomerChatEnabledSvg from './entry_point/block_entrypoint_shopify_customer_chat_enabled.svg';

import blockEntryPointFacebookPageEnabledSvg from './entry_point/block_entrypoint_facebook_page_enabled.svg';
import blockEntryPointFacebookPageDisabledSvg from './entry_point/block_entrypoint_facebook_page_disabled.svg';

import blockEntryPointPersistentMenuEnabledSvg from './entry_point/block_entrypoint_persistent_menu_enabled.svg';
import blockEntryPointPersistentMenuDisabledSvg from './entry_point/block_entrypoint_persistent_menu_disabled.svg';

import blockEntryPointInstagramPersistentMenuEnabledSvg from './entry_point/block_entrypoint_instagram_persistent_menu_enabled.svg';
import blockEntryPointInstagramPersistentMenuDisabledSvg from './entry_point/block_entrypoint_instagram_persistent_menu_disabled.svg';

import blockEntryPointRefLinkEnabledSvg from './entry_point/block_entrypoint_ref_link_enabled.svg';
import blockEntryPointRefLinkDisabledSvg from './entry_point/block_entrypoint_ref_link_disabled.svg';

import blockEntryPointInstagramBotLinkEnabledSvg from './entry_point/block_entrypoint_instagram_bot_link_enabled.svg';
import blockEntryPointInstagramBotLinkDisabledSvg from './entry_point/block_entrypoint_instagram_bot_link_disabled.svg';

import blockEntryPointSendToMessengerEnabledSvg from './entry_point/block_entrypoint_send_to_messenger_enabled.svg';
import blockEntryPointSendToMessengerDisabledSvg from './entry_point/block_entrypoint_send_to_messenger_disabled.svg';

import blockEntryPointCommentsAutoreplyEnabledSvg from './entry_point/block_entrypoint_comments_autoreply_enabled.svg';
import blockEntryPointCommentsAutoreplyDisabledSvg from './entry_point/block_entrypoint_comments_autoreply_disabled.svg';

import blockEntryPointAdCommentsAutoreplyEnabledSvg from './entry_point/block_entrypoint_ad_comments_autoreply_enabled.svg';
import blockEntryPointAdCommentsAutoreplyDisabledSvg from './entry_point/block_entrypoint_ad_comments_autoreply_disabled.svg';

import blockEntryPointFacebookAdsClickToMessengerEnabledSvg from './entry_point/block_entrypoint_facebook_ads_click_to_messenger_enabled.svg';
import blockEntryPointFacebookAdsClickToMessengerDisabledSvg from './entry_point/block_entrypoint_facebook_ads_click_to_messenger_disabled.svg';

import blockEntryPointFacebookAdsClickToMessengerJsonEnabledSvg from './entry_point/block_entrypoint_facebook_ads_click_to_messenger_json_enabled.svg';
import blockEntryPointFacebookAdsClickToMessengerJsonDisabledSvg from './entry_point/block_entrypoint_facebook_ads_click_to_messenger_json_disabled.svg';

import blockEntryPointFacebookAdsSponsoredMessageEnabledSvg from './entry_point/block_entrypoint_facebook_ads_sponsored_message_enabled.svg';
import blockEntryPointFacebookAdsSponsoredMessageDisabledSvg from './entry_point/block_entrypoint_facebook_ads_sponsored_message_disabled.svg';

import blockEntryPointFacebookShopsEnabledSvg from './entry_point/block_entrypoint_facebook_shops_enabled.svg';
import blockEntryPointFacebookShopsDisabledSvg from './entry_point/block_entrypoint_facebook_shops_disabled.svg';

import blockEntryPointZapierEnabledSvg from './entry_point/block_entrypoint_zapier_enabled.svg';
import blockEntryPointZapierDisabledSvg from './entry_point/block_entrypoint_zapier_disabled.svg';

import blockEntryPointInstagramDirectEnabledSvg from './entry_point/block_entrypoint_instagram_direct_enabled.svg';
import blockEntryPointInstagramDirectDisabledSvg from './entry_point/block_entrypoint_instagram_direct_disabled.svg';

import blockEntryPointInstagramCTMEnabledSvg from './entry_point/block_entrypoint_instagram_ctm_enabled.svg';
import blockEntryPointInstagramCommentsAutoreplyEnabledSvg from './entry_point/block_entrypoint_instagram_comments_autoreply_enabled.svg';
import blockEntryPointInstagramCommentsAutoreplyDisabledSvg from './entry_point/block_entrypoint_instagram_comments_autoreply_disabled.svg';

import blockEntryPointInstagramStoryMentionEnabledSvg from './entry_point/block_entrypoint_instagram_story_mention_enabled.svg';
import blockEntryPointInstagramStoryMentionDisabledSvg from './entry_point/block_entrypoint_instagram_story_mention_disabled.svg';

import blockEntryPointInstagramStoryReplyEnabledSvg from './entry_point/block_entrypoint_instagram_story_reply_enabled.svg';
import blockEntryPointInstagramStoryReplyDisabledSvg from './entry_point/block_entrypoint_instagram_story_reply_disabled.svg';

import blockEntryPointPopupEnabledSvg from './entry_point/block_entrypoint_popup_enabled.svg';
import blockEntryPointPopupDisabledSvg from './entry_point/block_entrypoint_popup_disabled.svg';

import blockEntryPointShopifyEventEnabledSvg from './entry_point/block_entrypoint_shopify_event_enabled.svg';
import blockEntryPointShopifyEventDisabledSvg from './entry_point/block_entrypoint_shopify_event_disabled.svg';

import blockEntryPointShopifyBackInStockEnabledSvg from './entry_point/block_entrypoint_back_in_stock_enabled.svg';
import blockEntryPointShopifyBackInStockDisabledSvg from './entry_point/block_entrypoint_back_in_stock_disabled.svg';

import blockEntryPointWhatsappDirectEnabledSvg from './entry_point/block_entrypoint_whatsapp_direct_enabled.svg';
import blockEntryPointWhatsappDirectDisabledSvg from './entry_point/block_entrypoint_whatsapp_direct_disabled.svg';

import blockEntryPointWhatsappBotlinkEnabledSvg from './entry_point/block_entrypoint_whatsapp_bot_link_enabled.svg';
import blockEntryPointWhatsappBotlinkDisabledSvg from './entry_point/block_entrypoint_whatsapp_bot_link_disabled.svg';

import blockEntryPointWhatsappWidgetEnabledSvg from './entry_point/block_entrypoint_whatsapp_witget_enabled.svg';
import blockEntryPointWhatsappWidgetDisabledSvg from './entry_point/block_entrypoint_whatsapp_witget_disabled.svg';

import blockEntryWhatsappPopupEntripointEnabledSvg from './entry_point/block_entrypoint_whatsapp_popup_enabled.svg';
import blockEntryWhatsappPopupEntripointDisabledSvg from './entry_point/block_entrypoint_whatsapp_popup_disabled.svg';

import whatsappTemplateDocumentSvg from './plugin/WhatsappTemplate/document.svg';
import whatsappTemplateImageSvg from './plugin/WhatsappTemplate/image.svg';
import whatsappTemplateVideoSvg from './plugin/WhatsappTemplate/video.svg';
import whatsappLocationSvg from './plugin/WhatsappTemplate/location.svg';
import whatsappLinkSvg from './plugin/WhatsappTemplate/link.svg';

import kommoPng from './plugin/kommo.png';

export const createTexture = (
  image: string,
  options?: IBaseTextureOptions,
): Texture => {
  return Sprite.from(image, options).texture;
};

export const noteSvgTexture = createTexture(noteSvg);
export const loaderSvgTexture = createTexture(loaderSvg);
export const loaderWhiteSvgTexture = createTexture(loaderWhiteSvg);
export const addImageSvgTexture = createTexture(addImageSvg);
export const addGalleryImageSvgTexture = createTexture(addGalleryImageSvg);
export const instagramSvgTexture = createTexture(instagramSvg);
export const addBlockSvgTexture = createTexture(addBlockSvg);
export const organizeSvgTexture = createTexture(organizeSvg);
export const removeSvgTexture = createTexture(removeSvg);
export const testSvgTexture = createTexture(testSvg);
export const testInstagramTexture = createTexture(testInstagramSvg);
export const testWhatsappTexture = createTexture(testWhatsappSvg);
export const ratioSvgTexture = createTexture(ratioSvg);
export const plusSvgTexture = createTexture(plusSvg);
export const closeSvgTexture = createTexture(closeSvg);
export const photoSvgTexture = createTexture(photoSvg);
export const cropSvgTexture = createTexture(cropSvg);
export const duplicateSvgTexture = createTexture(duplicateSvg);
export const menuSvgTexture = createTexture(menuSvg);
export const dragVerticalSvgTexture = createTexture(dragVerticalSvg);
export const dragHorizontalSvgTexture = createTexture(dragVerticalSvg);
export const zoomInSvgTexture = createTexture(zoomInSvg, {
  resourceOptions: { scale: 2 },
});
export const zoomOutSvgTexture = createTexture(zoomOutSvg, {
  resourceOptions: { scale: 2 },
});
export const fitSizeSvgTexture = createTexture(fitSizeSvg);
export const handBlackSvgTexture = createTexture(handBlackSvg, {
  resourceOptions: { scale: 2 },
});
export const activeWizardSvgTexture = createTexture(activeWizardSvg);
export const inactiveWizardSvgTexture = createTexture(inactiveWizardSvg);
export const blockSendMsgSvgTexture = createTexture(blockSendMsgSvg);
export const blockActionSvgTexture = createTexture(blockActionSvg);
export const blockConditionSvgTexture = createTexture(blockConditionSvg);
export const blockRedirectSvgTexture = createTexture(blockRedirectSvg);
export const blockDelaySvgTexture = createTexture(blockDelaySvg);
export const blockImportContentSvgTexture = createTexture(
  blockImportContentSvg,
);
export const blockReminderSvgTexture = createTexture(blockReminderSvg);
export const blockOrderReceiptSvgTexture = createTexture(blockOrderReceiptSvg);
export const blockPostPurchaseSvgTexture = createTexture(blockPostPurchaseSvg);
export const blockSendDataSvgTexture = createTexture(blockSendDataSvg);
export const pluginAudioSvgTexture = createTexture(pluginAudioSvg);
export const pluginFbAnalyticsSvgTexture = createTexture(pluginFbAnalyticsSvg);
export const pluginGallerySvgTexture = createTexture(pluginGallerySvg);
export const pluginImageSvgTexture = createTexture(pluginImageSvg);
export const pluginJsonSvgTexture = createTexture(pluginJsonSvg);
export const pluginHandoverSvgTexture = createTexture(pluginHandoverSvg);
export const pluginEmailSvgTexture = createTexture(pluginEmailSvg);
export const pluginOtnSvgTexture = createTexture(pluginOtnSvg);
export const pluginNTNSvgTexture = createTexture(pluginNTNSvg);
export const pluginPhoneCallSvgTexture = createTexture(pluginPhoneCallSvg);
export const pluginQuickReplySvgTexture = createTexture(pluginQuickReplySvg);
export const pluginDatepickerSvgTexture = createTexture(pluginDatepickerSvg);
export const pluginCollectAnswerSvgTexture = createTexture(collectAnswerSvg);
export const pluginCollectEmailSvgTexture = createTexture(collectEmailSvg);
export const pluginCollectPhoneSvgTexture = createTexture(collectPhonesSvg);
export const pluginCollectNumbersSvgTexture = createTexture(collectNumbersSvg);
export const pluginRemoveAttributeSvgTexture = createTexture(
  pluginRemoveAttributeSvg,
);
export const pluginRemoveTagSvgTexture = createTexture(pluginRemoveTagSvg);
export const pluginAddAttributeSvgTexture = createTexture(
  pluginAddAttributeSvg,
);
export const pluginSaveEmailSvgTexture = createTexture(pluginSaveEmailSvg);
export const pluginSavePhoneSvgTexture = createTexture(pluginSavePhoneSvg);
export const pluginAddTagSvgTexture = createTexture(pluginAddTagSvg);
export const pluginTextSvgTexture = createTexture(pluginTextSvg);
export const pluginOpenUrlSvgTexture = createTexture(pluginOpenUrlSvg);
export const pluginUserInputSvgTexture = createTexture(pluginUserInputSvg);
export const pluginVideoSvgTexture = createTexture(pluginVideoSvg);
export const pluginLiveChatSvgTexture = createTexture(pluginLiveChatSvg);
export const pluginTriangleSvgTexture = createTexture(pluginTriangleSvg);
export const productSvgTexture = createTexture(productSvg);
export const pluginGoogleSheetSvgTexture = createTexture(pluginGoogleSheetSvg);
export const pluginCalendlyConnectAccountSvgTexture = createTexture(
  pluginCalendlyConnectAccountSvg,
);
export const pluginScheduleCalendlyEventSvgTexture = createTexture(
  pluginScheduleCalendlyEventSvg,
);
export const pluginCalendlyEventReminderSvgTexture = createTexture(
  pluginCalendlyEventReminderSvg,
);
export const pluginCalendlyEventConfirmationSvgTexture = createTexture(
  pluginCalendlyEventConfirmationSvg,
);
export const pluginShopifyDiscountSvgTexture = createTexture(
  pluginShopifyDiscountSvg,
);
export const pluginCustomAiSvgTexture = createTexture(pluginCustomAiSvg);
export const pluginOpenAiSvgTexture = createTexture(pluginOpenAiSvg);
export const checkboxSvgTexture = createTexture(checkboxSvg);
export const alertSvgTexture = createTexture(alertSvg);
export const questionMarkTexture = createTexture(questionMarkSvg);
export const openUrlTexture = createTexture(openUrlSvg);
export const openUrlRedTexture = createTexture(openUrlRedSvg);
export const openDatepickerTexture = createTexture(openDatepickerSvg);
export const callPhoneTexture = createTexture(callPhoneSvg);
export const blockStartingTexture = createTexture(blockStartingSvg);
export const inboundLinksTexture = createTexture(inboundLinksSvg);
export const pluginCommentTexture = createTexture(pluginCommentSvg);
export const messengerSvgTexture = createTexture(messengerSvg);
export const infoSvgTexture = createTexture(infoSvg);
export const attachmentSvgTexture = createTexture(attachmentSvg);
export const errorInfoSvgTexture = createTexture(errorInfoSvg);
export const errorInfoTriangleSvgTexture = createTexture(errorInfoTriangleSvg);
export const profilePlaceholderTexture = createTexture(profilePlaceholderSvg);
export const faqSvgTexture = createTexture(faqSvg);
export const restartSvgTexture = createTexture(restartSvg);
export const googleLogoSvgTexture = createTexture(googleLogoSvg);
export const alarmClockSvgTexture = createTexture(alarmClockSvg);
export const alarmClockActiveSvgTexture = createTexture(alarmClockActiveSvg);
export const zapierSvgTexture = createTexture(zapierSvg);
export const QRCodeTexture = createTexture(QRCodeSvg);
export const zapeirPluginIconSvgTexture = createTexture(zapeirPluginIconSvg);
export const shopifySvgTexture = createTexture(shopifySvg);
export const crossSvgTexture = createTexture(crossSvg);
export const receiptProductSvgTexture = createTexture(receiptProductSvg);
export const bannerInfoIconSvgTexture = createTexture(bannerInfoIconSvg);
export const externalLinkIconSvgTexture = createTexture(externalLinkIconSvg);
export const listIconSvgTexture = createTexture(listIconSvg);
export const listErrorIconSvgTexture = createTexture(listErrorIconSvg);

export const entryPointFacebookPageTexture = createTexture(
  entryPointFacebookPageSvg,
);
export const persistentMenuUserInputTexture = createTexture(
  persistentMenuUserInputSvg,
);
export const instagramPersistentMenuUserInputTexture = createTexture(
  instagramPersistentMenuUserInputSvg,
);
export const messageTagTexture = createTexture(messageTagSvg);
export const rightArrowTexture = createTexture(rightArrowSvg);
export const mediaShareTexture = createTexture(mediaShareSvg);
export const choosePostTexture = createTexture(choosePostSvg);
export const instagramGalleryPostTexture = createTexture(instagramGalleyPost);
export const instagramVideoPostTexture = createTexture(instagramVideoPost);
export const instagramAccountPreviewTexture = createTexture(
  instagramAccountPreviewSvg,
);
export const textQuickRepliesTexture = createTexture(textQuickRepliesSvg);
export const shopifyEventAbandonedTexture = createTexture(
  shopifyEventAbandonedCartSvg,
);
export const shopifyEventOrderConfirmationTexture = createTexture(
  shopifyEventOrderConfirmationSvg,
);
export const shopifyEventOrderStatusUpdateTexture = createTexture(
  shopifyEventOrderStatusUpdateSvg,
);
export const shopifyEventProductVisitTexture = createTexture(
  shopifyEventProductVisitSvg,
);
export const shopifyBackInStockOptinConfirmationTexture = createTexture(
  blockShopifyBackInStockOptinConfirmationSvg,
);
export const shopifyBackInStockOTNTexture = createTexture(
  blockShopifyBackInStockOTNSvg,
);
export const shopifyBackInStockMessageTexture = createTexture(
  blockShopifyBackInStockMessageSvg,
);

export const flowsChannelSvgTexture = createTexture(flowsChannelIconSvg);
export const blocksChannelSvgTexture = createTexture(blocksChannelIconSvg);
export const reengageChannelSvgTexture = createTexture(reengageChannelIconSvg);
export const blockBroadcastFlowRootSvgTexture = createTexture(
  blockEntryPointWhatsappDirectEnabledSvg,
);

export const whatsappTemplateDocumentSvgTexture = createTexture(
  whatsappTemplateDocumentSvg,
);
export const whatsappTemplateImageSvgTexture = createTexture(
  whatsappTemplateImageSvg,
);
export const whatsappTemplateVideoSvgTexture = createTexture(
  whatsappTemplateVideoSvg,
);
export const whatsappLocationSvgTexture = createTexture(whatsappLocationSvg);

export const whatsappLinkSvgTexture = createTexture(whatsappLinkSvg);

export const kommoPngPluginSvgTexture = createTexture(kommoPng);
export const unknownUserSvgTexture = createTexture(UnknownSvg);

export const entryPointsIconTextures = {
  inboundLinks: {
    enabled: createTexture(blockEntryPointCustomerChatEnabledSvg),
    disabled: createTexture(blockEntryPointCustomerChatDisabledSvg),
  },
  customerChat: {
    enabled: createTexture(blockEntryPointCustomerChatEnabledSvg),
    disabled: createTexture(blockEntryPointCustomerChatDisabledSvg),
  },
  shopifyCustomerChat: {
    enabled: createTexture(blockEntryPointShopifyCustomerChatEnabledSvg),
    disabled: createTexture(blockEntryPointCustomerChatDisabledSvg),
  },
  facebookPage: {
    enabled: createTexture(blockEntryPointFacebookPageEnabledSvg),
    disabled: createTexture(blockEntryPointFacebookPageDisabledSvg),
  },
  persistentMenu: {
    enabled: createTexture(blockEntryPointPersistentMenuEnabledSvg),
    disabled: createTexture(blockEntryPointPersistentMenuDisabledSvg),
  },
  instagramPersistentMenu: {
    enabled: createTexture(blockEntryPointInstagramPersistentMenuEnabledSvg),
    disabled: createTexture(blockEntryPointInstagramPersistentMenuDisabledSvg),
  },
  refLink: {
    enabled: createTexture(blockEntryPointRefLinkEnabledSvg),
    disabled: createTexture(blockEntryPointRefLinkDisabledSvg),
  },
  sendToMessenger: {
    enabled: createTexture(blockEntryPointSendToMessengerEnabledSvg),
    disabled: createTexture(blockEntryPointSendToMessengerDisabledSvg),
  },
  commentsAutoreply: {
    enabled: createTexture(blockEntryPointCommentsAutoreplyEnabledSvg),
    disabled: createTexture(blockEntryPointCommentsAutoreplyDisabledSvg),
  },
  adCommentsAutoreply: {
    enabled: createTexture(blockEntryPointAdCommentsAutoreplyEnabledSvg),
    disabled: createTexture(blockEntryPointAdCommentsAutoreplyDisabledSvg),
  },
  facebookAdsClickToMessenger: {
    enabled: createTexture(
      blockEntryPointFacebookAdsClickToMessengerEnabledSvg,
    ),
    disabled: createTexture(
      blockEntryPointFacebookAdsClickToMessengerDisabledSvg,
    ),
  },
  facebookAdsClickToMessengerJson: {
    enabled: createTexture(
      blockEntryPointFacebookAdsClickToMessengerJsonEnabledSvg,
    ),
    disabled: createTexture(
      blockEntryPointFacebookAdsClickToMessengerJsonDisabledSvg,
    ),
  },
  facebookAdsSponsoredMessage: {
    enabled: createTexture(
      blockEntryPointFacebookAdsSponsoredMessageEnabledSvg,
    ),
    disabled: createTexture(
      blockEntryPointFacebookAdsSponsoredMessageDisabledSvg,
    ),
  },
  facebookShops: {
    enabled: createTexture(blockEntryPointFacebookShopsEnabledSvg),
    disabled: createTexture(blockEntryPointFacebookShopsDisabledSvg),
  },
  zapier: {
    enabled: createTexture(blockEntryPointZapierEnabledSvg),
    disabled: createTexture(blockEntryPointZapierDisabledSvg),
  },
  instagramDirect: {
    enabled: createTexture(blockEntryPointInstagramDirectEnabledSvg),
    disabled: createTexture(blockEntryPointInstagramDirectDisabledSvg),
  },
  instagramCTM: {
    enabled: createTexture(blockEntryPointInstagramCTMEnabledSvg),
    disabled: null,
  },
  popup: {
    enabled: createTexture(blockEntryPointPopupEnabledSvg),
    disabled: createTexture(blockEntryPointPopupDisabledSvg),
  },
  shopifyEvent: {
    enabled: createTexture(blockEntryPointShopifyEventEnabledSvg),
    disabled: createTexture(blockEntryPointShopifyEventDisabledSvg),
  },
  instagramCommentsAutoreply: {
    enabled: createTexture(blockEntryPointInstagramCommentsAutoreplyEnabledSvg),
    disabled: createTexture(
      blockEntryPointInstagramCommentsAutoreplyDisabledSvg,
    ),
  },
  instagramStoryMention: {
    enabled: Sprite.from(blockEntryPointInstagramStoryMentionEnabledSvg)
      .texture,
    disabled: Sprite.from(blockEntryPointInstagramStoryMentionDisabledSvg)
      .texture,
  },
  instagramStoryReply: {
    enabled: Sprite.from(blockEntryPointInstagramStoryReplyEnabledSvg).texture,
    disabled: Sprite.from(blockEntryPointInstagramStoryReplyDisabledSvg)
      .texture,
  },
  instagramBotLink: {
    enabled: Sprite.from(blockEntryPointInstagramBotLinkEnabledSvg).texture,
    disabled: Sprite.from(blockEntryPointInstagramBotLinkDisabledSvg).texture,
  },
  shopifyBackInStock: {
    enabled: Sprite.from(blockEntryPointShopifyBackInStockEnabledSvg).texture,
    disabled: Sprite.from(blockEntryPointShopifyBackInStockDisabledSvg).texture,
  },
  whatsappDirect: {
    enabled: Sprite.from(blockEntryPointWhatsappDirectEnabledSvg).texture,
    disabled: Sprite.from(blockEntryPointWhatsappDirectDisabledSvg).texture,
  },
  whatsappBotlink: {
    enabled: Sprite.from(blockEntryPointWhatsappBotlinkEnabledSvg).texture,
    disabled: Sprite.from(blockEntryPointWhatsappBotlinkDisabledSvg).texture,
  },
  whatsappWidget: {
    enabled: Sprite.from(blockEntryPointWhatsappWidgetEnabledSvg).texture,
    disabled: Sprite.from(blockEntryPointWhatsappWidgetDisabledSvg).texture,
  },
  whatsappPopup: {
    enabled: Sprite.from(blockEntryWhatsappPopupEntripointEnabledSvg).texture,
    disabled: Sprite.from(blockEntryWhatsappPopupEntripointDisabledSvg).texture,
  },
};

export { ReactComponent as PluginDocumentIcon } from './plugin/document.svg';
export { ReactComponent as PluginAudioIcon } from './plugin/audio.svg';
export { ReactComponent as PluginFbAnalyticsIcon } from './plugin/fb_analytics.svg';
export { ReactComponent as PluginGalleryIcon } from './plugin/gallery.svg';
export { ReactComponent as PluginImageIcon } from './plugin/image.svg';
export { ReactComponent as PluginJsonIcon } from './plugin/json.svg';
export { ReactComponent as PluginHandoverIcon } from './plugin/handover.svg';
export { ReactComponent as PluginEmailIcon } from './plugin/email.svg';
export { ReactComponent as PluginOtnIcon } from './plugin/otn.svg';
export { ReactComponent as PluginNTNIcon } from './plugin/ntn.svg';
export { ReactComponent as PluginPhoneCallIcon } from './plugin/phone_call.svg';
export { ReactComponent as PluginQuickReplyIcon } from './plugin/quick_reply.svg';
export { ReactComponent as PluginRemoveAttributeIcon } from './plugin/remove_attribute.svg';
export { ReactComponent as PluginRemoveTagIcon } from './plugin/remove_tag.svg';
export { ReactComponent as PluginAddAttributeIcon } from './plugin/save_attribute.svg';
export { ReactComponent as PluginSaveEmailIcon } from './plugin/save_email.svg';
export { ReactComponent as PluginSavePhoneIcon } from './plugin/save_phone.svg';
export { ReactComponent as PluginAddTagIcon } from './plugin/save_tag.svg';
export { ReactComponent as PluginTextIcon } from './plugin/text.svg';
export { ReactComponent as PluginTextButtonsIcon } from './plugin/text_buttons.svg';
export { ReactComponent as PluginOpenUrlIcon } from './plugin/url.svg';
export { ReactComponent as PluginUserInputIcon } from './plugin/user_input.svg';
export { ReactComponent as PluginVideoIcon } from './plugin/video.svg';
export { ReactComponent as PluginAlertIcon } from './plugin/alert.svg';
export { ReactComponent as PluginDatepickerIcon } from './plugin/datepicker.svg';
export { ReactComponent as pluginLiveChatIcon } from './plugin/live-chat.svg';
export { ReactComponent as pluginFacebookShopProductsIcon } from './plugin/facebook_shop_products.svg';
export { ReactComponent as pluginGoogleSheetIcon } from './plugin/google-sheet.svg';
export { ReactComponent as pluginZapierIcon } from './plugin/zapier.svg';
export { ReactComponent as instagramMenuIcon } from './controller/instagram.svg';
export { ReactComponent as PluginTextQuickRepliesIcon } from './plugin/text_quick_replies.svg';
export { ReactComponent as PluginReminderIcon } from './plugin/reminder_plugin.svg';
export { ReactComponent as PluginOrderReceiptIcon } from './plugin/order_receipt_plugin.svg';
export { ReactComponent as PluginProductListIcon } from './plugin/product_list_plugin.svg';
export { ReactComponent as PluginCollectAnswersIcon } from './plugin/collect_answers.svg';
export { ReactComponent as PluginCollectEmailsIcon } from './plugin/collect_emails.svg';
export { ReactComponent as PluginCollectPhonesIcon } from './plugin/collect_phones.svg';
export { ReactComponent as PluginCollectNumbersIcon } from './plugin/collect_numbers.svg';
export { ReactComponent as PluginWhatsappListIcon } from './plugin/whatsapp_list.svg';
export { ReactComponent as PluginWhatsappTemplateIcon } from './plugin/whatsapp_tempalte_plugin.svg';
export { ReactComponent as PluginWhatsappLocationIcon } from './plugin/whatsapp_location.svg';
export { ReactComponent as PluginOpenAiIcon } from './plugin/open-ai.svg';

export { ReactComponent as ChoosePostIcon } from './controller/choose_post.svg';
export { ReactComponent as SendToMessengerIcon } from './entry_point/send_to_messenger_icon.svg';
export { ReactComponent as FacebookPageIcon } from './entry_point/facebook_page_icon.svg';
export { ReactComponent as CustomerChatIcon } from './entry_point/customer_chat_icon.svg';
export { ReactComponent as BotLinkIcon } from './entry_point/bot_link_icon.svg';
export { ReactComponent as InstagramBotLinkIcon } from './entry_point/instagram_bot_link_icon.svg';
export { ReactComponent as PersistentMenuIcon } from './entry_point/persistent_menu_icon.svg';
export { ReactComponent as InstagramPersistentMenuIcon } from './entry_point/instagram_persistent_menu_icon.svg';
export { ReactComponent as CommentsAutoreplyIcon } from './entry_point/comments_autoreply_icon.svg';
export { ReactComponent as AdCommentsAutoreplyIcon } from './entry_point/ad_comments_autoreply_icon.svg';
export { ReactComponent as FacebookAdsSmIcon } from './entry_point/facebook_ads_sm_icon.svg';
export { ReactComponent as FacebookAdsCtmIcon } from './entry_point/facebook_ads_ctm_icon.svg';
export { ReactComponent as FacebookAdsCtmJsonIcon } from './entry_point/facebook_ads_ctm_json_icon.svg';
export { ReactComponent as FacebookShopsIcon } from './entry_point/facebook_shops_icon.svg';
export { ReactComponent as ZapierIcon } from './entry_point/zapier_icon.svg';
export { ReactComponent as ZapierEventTriggerIcon } from './entry_point/zapier_event_trigger_icon.svg';
export { ReactComponent as ZapierImportContentIcon } from './entry_point/zapier_import_content_icon.svg';
export { ReactComponent as MediaShareIcon } from './plugin/media_share.svg';
export { ReactComponent as InstagramDirectIcon } from './entry_point/block_entrypoint_instagram_direct_enabled.svg';
export { ReactComponent as InstagramCTM } from './entry_point/block_entrypoint_instagram_ctm_enabled.svg';
export { ReactComponent as ShopifyPopupIcon } from './entry_point/shopify_popup_icon.svg';
export { ReactComponent as ShopifyBackInStockIcon } from './entry_point/shopify_back_in_stock_icon.svg';
export { ReactComponent as CartRemindersIcon } from './entry_point/cart_reminders_icon.svg';
export { ReactComponent as OrderConfirmationIcon } from './entry_point/order_confirmation_icon.svg';
export { ReactComponent as OrderStatusUpdateIcon } from './entry_point/order_status_update_icon.svg';
export { ReactComponent as ProductVisitIcon } from './entry_point/product_visit_icon.svg';
export { ReactComponent as InstagramCommentsAutoreplyIcon } from './entry_point/block_entrypoint_instagram_comments_autoreply_enabled.svg';
export { ReactComponent as InstagramStoryMentionIcon } from './entry_point/block_entrypoint_instagram_story_mention_enabled.svg';
export { ReactComponent as InstagramStoryReplyIcon } from './entry_point/block_entrypoint_instagram_story_reply_enabled.svg';
export { ReactComponent as ScheduleCalendlyEventIcon } from './plugin/schedule-calendly-event.svg';
export { ReactComponent as CalendlyEventConfirmationIcon } from './plugin/calendly-event-confirmation.svg';
export { ReactComponent as CalendlyEventReminderIcon } from './plugin/calendly-event-reminder.svg';
export { ReactComponent as ShopifyCustomerChatIcon } from './entry_point/shopify-customer-chat_icon.svg';
export { ReactComponent as ShopifyDiscountIcon } from './plugin/shopify-discount.svg';
export { ReactComponent as WhatsappDirectIcon } from './entry_point/block_entrypoint_whatsapp_direct_enabled.svg';
export { ReactComponent as WhatsappBotLinkIcon } from './entry_point/block_entrypoint_whatsapp_bot_link.svg';
export { ReactComponent as WhatsappWitgetIcon } from './entry_point/block_entrypoint_whatsapp_witget.svg';

export { ReactComponent as CustomAiPluginIcon } from './menu/custom-ai-plugin.svg';
export { ReactComponent as IntegrationsIcon } from './menu/integrations.svg';
export { ReactComponent as ContentBlocksIcon } from './menu/contentBlocks.svg';
export { ReactComponent as EntryPointsIcon } from './menu/entryPoints.svg';
export { ReactComponent as ActionsIcon } from './menu/actions.svg';
export { ReactComponent as NotificationsIcon } from './menu/notifications.svg';
export { ReactComponent as CollectUserDataIcon } from './menu/collectUserData.svg';

export { ReactComponent as BlockActionIcon } from './block/block_action.svg';
export { ReactComponent as BlockSendMsgIcon } from './block/block_send_msg.svg';
export { ReactComponent as ConditionIcon } from './block/block_condition.svg';
export { ReactComponent as ShopifyBackInStockOptInCofirmationIcon } from './block/block_shopify_back_in_stock_optin_confirmation.svg';
export { ReactComponent as ShopifyBackInStockMessageIcon } from './block/block_shopify_back_in_stock_message.svg';
export { ReactComponent as MessageTagIcon } from './controller/message_tag.svg';
export { ReactComponent as ConnectBlockIcon } from '../../../modern-ui/Icon/icons/actions/Connect_Block.svg';
export { ReactComponent as RemoveIcon } from './controller/remove.svg';
export { ReactComponent as RightArrowIcon } from '../../../modern-ui/Icon/icons/actions/Right_Arrow.svg';
export { ReactComponent as ReminderIcon } from '../../../modern-ui/Icon/icons/actions/Reminder.svg';
export { ReactComponent as BlockOrderReceiptIcon } from './block/menu-images/order_receipt_icon.svg';
export { ReactComponent as BlockPostPurchaseIcon } from './block/menu-images/post_purchase_icon.svg';

export { ReactComponent as BlockConditionActionsIcon } from './block/menu-images/block_condition_actions.svg';
export { ReactComponent as BlockSplitActionsIcon } from './block/menu-images/block_split_actions.svg';
export { ReactComponent as BlockDelayActionsIcon } from './block/menu-images/block_delay_actions.svg';
export { ReactComponent as BlockRedirectActionsIcon } from './block/menu-images/block_redirect_actions.svg';

export { ReactComponent as ShopifyEventAbandonedCart } from './entry_point/shopify_event_abandoned_cart.svg';
export { ReactComponent as ShopifyEventOrderConfirmation } from './entry_point/shopify_event_order_confirmation.svg';
export { ReactComponent as ShopifyEventOrderStatusUpdate } from './entry_point/shopify_event_order_status_update.svg';
export { ReactComponent as ShopifyEventProductVisit } from './entry_point/product_visit_icon.svg';
export { ReactComponent as ShopifyEvents } from './entry_point/block_entrypoint_shopify_event_enabled.svg';

export { ReactComponent as KommoMenuIcon } from './plugin/kommo_menu_icon.svg';
export { ReactComponent as WhatsupSignupFormIcon } from './entry_point/block_entrypoint_whatsapp_popup_enabled.svg';
