import { useEffect } from 'react';
import { getFlowController } from '../../PixiFieldRepository';
import {
  EntryPointEventTrigger,
  entryPointsEmitter,
  EntryPointsEvents,
} from '../../views/entry-points/events';
import { EntryPointEventPayload } from '../../views/entry-points/types';

export const useListenFlowListChanges = (routeFlowId: string) => {
  useEffect(() => {
    return entryPointsEmitter.on<EntryPointEventPayload>(
      EntryPointEventTrigger.flowList,
      async (payload) => {
        if (
          payload.data.event === EntryPointsEvents.create &&
          payload.data.flowId === routeFlowId &&
          payload.data.id
        ) {
          await getFlowController()?.fetchAndRenderBlock(payload.data.id);
          getFlowController()?.focusOnBlock(payload.data.id);
        }
      },
    );
  }, [routeFlowId]);
};
