import React from 'react';
import { UserFilterProps } from './types';
import { AttributeFilter, BlockFilter, SegmentFilter } from './components';
import { UserAttributeType } from '../../../../../../../@types/globalTypes';

export function UserFilter<T>(props: UserFilterProps<T>) {
  switch (props.parameter.type) {
    case UserAttributeType.system:
    case UserAttributeType.custom:
      return <AttributeFilter {...props} />;
    case UserAttributeType.segment:
      return <SegmentFilter {...props} />;
    case UserAttributeType.block:
      return <BlockFilter {...props} />;
    case UserAttributeType.sequence:
    default:
      return null;
  }
}
