import React from 'react';
import { CenteredLoader } from '@ui/Loader';
import { StatefulPlugin } from '../../../../StatefulPlugin';
import { EditorPanelPluginProps } from '../../../types';
import { CustomerChatEntryPointContent } from './components/CustomerChatEntryPointContent';
import { customerChatEntryPointFragment_config as CustomerChatEntryPointConfig } from '../../../../../Plugins/CustomerChatEntryPoint/@types/customerChatEntryPointFragment';
import { usePageConnected } from '../../../../../../utils/FacebookPages/usePageConnected';

export const CustomerChatEntryPoint: React.FC<
  EditorPanelPluginProps<CustomerChatEntryPointConfig>
> = ({ botId, pluginData, pluginParams }) => {
  const { loading, pageId } = usePageConnected(botId);

  if (loading) {
    return <CenteredLoader />;
  }

  return (
    <CustomerChatEntryPointContent
      botId={botId}
      pageId={pageId || ''}
      {...pluginData}
      {...pluginParams}
      state={
        pluginData.state as unknown as StatefulPlugin<CustomerChatEntryPointConfig>
      }
    />
  );
};
