export const changeTriggerType = (triggers: string, type: string) => {
  if (triggers.includes(type)) {
    return triggers
      .split(',')
      .filter((item) => item !== type && item.length > 0)
      .join();
  }
  return [
    ...triggers.split(',').filter((item) => item.length > 0),
    type,
  ].join();
};
