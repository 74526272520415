import { Graphics, Sprite, SCALE_MODES } from 'pixi.js-legacy';
import { getPixiField } from '../../../PixiFieldRepository';
import { colorToHex, resByFunc } from '../../../views/utils';
import { PropFunc } from '../../../types';
import { CornerRadiusAdv } from '../Layouts/types';
import { getCornersRadiusObject, renderRectBackgroundTexture } from './helpers';
import { DESTROY_OPTIONS, Shape } from './Shape';
import { RectProps } from './types';

export class Rect extends Shape<RectProps, Sprite> {
  TEST_NAME = 'Rect';
  _rect: Graphics;
  private wasDestroyed?: boolean;

  constructor(props: RectProps) {
    super({ cornerRadius: 0, ...props }, Sprite);
    this._rect = new Graphics();
  }

  destroy() {
    super.destroy();
    if (this.wasDestroyed) {
      return;
    }
    this.wasDestroyed = true;
    this._rect.destroy(DESTROY_OPTIONS);
  }

  cornerRadius(v?: number) {
    return this._changeProp<number>('cornerRadius', v);
  }

  corners(v?: PropFunc<CornerRadiusAdv>) {
    return this._changeProp<PropFunc<CornerRadiusAdv>>('corners', v);
  }

  move() {
    this._shape.x = this._props?.x || 0;
    this._shape.y = this._props?.y || 0;
  }

  _drawShape() {
    const props = this._props;
    const shape = this._rect;
    if (props.backgroundStyle) {
      renderRectBackgroundTexture({
        width: props.width ?? 0,
        height: props.height ?? 0,
        backgroundStyle: resByFunc(props.backgroundStyle),
        props,
        callback: (texture) => {
          this._shape.texture.destroy(true);
          this._shape.texture = texture;
        },
      });
    } else {
      shape.clear();
      const color = colorToHex(props.fill) as number;
      const strokeColor = colorToHex(props.stroke) as number;
      const { topLeft, topRight, bottomLeft, bottomRight } =
        getCornersRadiusObject({
          corners: props.corners,
          cornerRadius: props.cornerRadius,
        });
      const x = 0;
      const y = 0;
      const w = props.width || 0;
      const h = props.height || 0;
      const needDrawCornersRadius =
        topLeft || topRight || bottomLeft || bottomRight;

      shape.beginFill(color, props.opacity);
      shape.lineStyle(
        props.strokeWidth || 0,
        strokeColor,
        props.strokeOpacity ?? props.opacity,
        props.strokeAlignment,
      );
      shape.moveTo(x + topLeft, y);
      shape.lineTo(x + w - topRight, y);
      this.drawArcIfNeed(
        x + w - topRight,
        y + topRight,
        topRight,
        (3 / 2) * Math.PI,
        2 * Math.PI,
      );
      shape.lineTo(x + w, y + h - bottomRight);
      this.drawArcIfNeed(
        x + w - bottomRight,
        y + h - bottomRight,
        bottomRight,
        0,
        Math.PI / 2,
      );
      shape.lineTo(x + bottomLeft, y + h);
      this.drawArcIfNeed(
        x + bottomLeft,
        y + h - bottomLeft,
        bottomLeft,
        Math.PI / 2,
        Math.PI,
      );
      shape.lineTo(x, y + topLeft);
      this.drawArcIfNeed(
        x + topLeft,
        y + topLeft,
        topLeft,
        Math.PI,
        (3 / 2) * Math.PI,
      );
      shape.endFill();
      this._shape.texture.destroy(true);
      const pixiField = getPixiField();
      if (pixiField) {
        this._shape.texture = pixiField.renderer.generateTexture(this._rect, {
          scaleMode: SCALE_MODES.LINEAR,
          resolution: needDrawCornersRadius
            ? Math.max(window.devicePixelRatio || 2, 2) // force PixelRatio to 2 for better smoothing corners
            : window.devicePixelRatio || 1,
        });
      }
    }
  }

  drawArcIfNeed(
    cx: number,
    cy: number,
    radius: number,
    startAngle: number,
    endAngle: number,
  ) {
    if (!radius) {
      return;
    }
    this._rect.arc(cx, cy, radius, startAngle, endAngle);
  }
}
