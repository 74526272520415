import React from 'react';
import { EditorPanelPaymentButton } from './components/editors/EditorPanelPaymentButton';
import { EditorPanelPlugin } from './components/editors/EditorPanelPlugin';

export const EditorPanel: React.FC = () => (
  <>
    <EditorPanelPlugin />
    <EditorPanelPaymentButton/>
  </>
);
