import React from 'react';
import { Flex } from '@ui/Flex';
import { ComboboxWithTriangleButton } from '@ui/SimpleCombobox';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import { MenuItem } from '@ui/Menu';
import css from './KommoPlugin.css';

type Item = { id: string; title: string };

export const Block: React.FC<{
  title: string;
  items: Item[];
  onChange(item: Item): void;
  selectedItem: Item | null;
  error?: null | string;
  loading: boolean;
  'data-testid'?: string;
}> = ({ title, items, onChange, selectedItem, error, loading, ...props }) => {
  return (
    <Flex flexDirection="column">
      <Type size="12px" weight="medium" color="baseTertiary">
        {title}
      </Type>
      <Spacer factor={2} />
      <ComboboxWithTriangleButton
        loading={loading}
        intent={error ? 'error' : 'secondary'}
        items={items}
        className={css.comboboxWithTriangleButton}
        selectedItem={selectedItem}
        onChange={onChange}
        data-testid={props['data-testid']}
      >
        {({ downshift, props: { currentItems, item, index } }) => (
          <MenuItem
            className={css.comboboxMenuItem}
            {...downshift.getItemProps({ item, index })}
            active={downshift.highlightedIndex === index}
            title={currentItems[index].title}
          />
        )}
      </ComboboxWithTriangleButton>
      {error && (
        <>
          <Spacer factor={2} />
          <Type size="12px" color="accent4Dark">
            {error}
          </Type>
        </>
      )}
    </Flex>
  );
};
