import React, { useState } from 'react';
import { FileSelector } from '../../../../../../../../../FileSelector';
import { Platform } from '@globals';
import { HEADER_PARAMETER_TO_ATTACHMENT_TYPE } from '../../consts';
import { Type } from '@ui/Type';
import {
  HeaderMediaFileSelectorData,
  HeaderParameterMediaType,
} from '../../types';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { HeaderMediaFileSelectorValue } from '../HeaderMediaFileSelectorValue';
import Maybe from 'graphql/tsutils/Maybe';
import { QueryParams, uploadFile } from '../../../../../../../../api/network';
import { getFileSizeLimit } from '@utils/PlatformSupportedFiles/getFileSizeLimit';
import { Spacer } from '@ui/Spacer';
import { getExtStringByMimeTypes } from '@utils/PlatformSupportedFiles/getExtListByMimeTypes';
import { getSupportedTypes } from '@utils/PlatformSupportedFiles/getSupportedTypes';
import { FileSelectorChildrenProps } from '@components/FileSelector/FileSelector';
import { FileAttachmentType } from '@utils/UploadService/types';
import { exhaustiveCheck } from '@utils/exhaustiveCheck';

export interface HeaderMediaFileSelectorProps {
  type: HeaderParameterMediaType;
  value: Maybe<HeaderMediaFileSelectorData>;
  onChange: (
    value: HeaderMediaFileSelectorData,
    filetype: FileAttachmentType | null,
  ) => void;
  showFormatErrorInit: boolean;
  disabled?: boolean;
  children(
    props: FileSelectorChildrenProps & {
      loading: boolean;
      attachmentType: FileAttachmentType;
    },
  ): React.ReactNode;
  queryParams?: QueryParams;
}

const getFileType = (attachmentType: FileAttachmentType) => {
  switch (attachmentType) {
    case FileAttachmentType.audio:
      return 'audio';
    case FileAttachmentType.video:
    case FileAttachmentType.mp4:
      return 'video';
    case FileAttachmentType.file:
    case FileAttachmentType.pdf:
      return 'file';
    case FileAttachmentType.image:
      return 'image';
    default:
      exhaustiveCheck(attachmentType);
      return '';
  }
};

export const HeaderMediaFileSelector: React.FC<HeaderMediaFileSelectorProps> =
  ({
    type,
    value,
    disabled,
    onChange,
    showFormatErrorInit,
    children,
    queryParams,
  }) => {
    const { t } = useSafeTranslation();
    const [showFileSizeError, setShowFileSizeError] = useState<boolean>(false);
    const [showFormatError, setShowFormatError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const [attachmentType, setAttachmentType] = useState(
      HEADER_PARAMETER_TO_ATTACHMENT_TYPE[type],
    );

    return value?.link ? (
      <HeaderMediaFileSelectorValue
        type={type}
        value={value}
        disabled={disabled}
        onRemoveRequest={() => {
          onChange({ link: null, filename: null }, null);
        }}
      />
    ) : (
      <>
        <FileSelector
          platform={Platform.whatsapp}
          data-testid="whatsapp_template_plugin__file-selector"
          onFileChange={(_, type) => {
            setAttachmentType(type);
            setShowFileSizeError(false);
            setShowFormatError(false);
          }}
          hideToasters
          attachmentTypeInit={attachmentType}
          onFileProcessed={({ blob, name }, attachmentType) => {
            if (!blob) {
              return;
            }
            const formData = new FormData();
            formData.append('file', new File([blob], name));
            setLoading(true);
            uploadFile(
              formData,
              (link) => {
                onChange({ link, filename: name }, attachmentType);
                setLoading(false);
              },
              () => {
                setLoading(false);
              },
              {
                filetype: getFileType(attachmentType),
                platform: Platform.whatsapp,
                filename: name,
                ...queryParams,
              },
            );
          }}
          onFileSizeExceeded={() => {
            setShowFileSizeError(true);
          }}
          onFileWrongFormat={() => {
            setShowFormatError(true);
          }}
        >
          {(props) => children({ ...props, loading, attachmentType })}
        </FileSelector>
        {(showFileSizeError || showFormatError || showFormatErrorInit) && (
          <>
            <Spacer factor={1} />
            <Type color="redNormal" size="12px">
              {(showFormatError || showFormatErrorInit) &&
                t(
                  'modernComponents.FlowBuilder.plugins.WhatsappTemplatePlugin.header.errors.format',
                  {
                    formats: getExtStringByMimeTypes(
                      getSupportedTypes(attachmentType, Platform.whatsapp),
                    ),
                  },
                )}
              {showFileSizeError &&
                t(
                  'modernComponents.FlowBuilder.plugins.WhatsappTemplatePlugin.header.errors.size',
                  {
                    size: getFileSizeLimit(attachmentType, Platform.whatsapp),
                  },
                )}
            </Type>
          </>
        )}
      </>
    );
  };
