import gql from 'graphql-tag';
import { QueryHookOptions, useQuery } from 'react-apollo';
import {
  KommoCustomFieldsQuery,
  KommoCustomFieldsQueryVariables,
} from './@types/KommoCustomFieldsQuery';

const KOMMO_CUSTOM_FIELDS_QUERY = gql`
  query KommoCustomFieldsQuery($botId: String!) {
    kommoCustomFields(botId: $botId) {
      id
      name
      type
      required_statuses {
        status_id
        pipeline_id
      }
    }
  }
`;

export const useKommoCustomFields = (
  options: QueryHookOptions<
    KommoCustomFieldsQuery,
    KommoCustomFieldsQueryVariables
  >,
) =>
  useQuery<KommoCustomFieldsQuery, KommoCustomFieldsQueryVariables>(
    KOMMO_CUSTOM_FIELDS_QUERY,
    options,
  );
