import * as Types from '../types';
import * as StateUtils from './utils';
import { StatefulPlugin, StatefulPluginDelegate } from '../StatefulPlugin';

export class ApolloState<TPluginConfig> extends StatefulPlugin<TPluginConfig> {
  /**
   * Since watchQuery triggers callback even just after the subscription action,
   * this flag is needed to divide an update from the subscription to Apollo
   */
  private subscribed = false;

  private unsubscribeEditPlugin?(): void;

  protected pluginDidSet(
    data: Types.Plugin<TPluginConfig>,
    validationErrors: Types.ValidationErrors,
  ) {
    if (this.subscribed) {
      super.pluginDidSet(data, validationErrors);
    } else {
      this.subscribed = true;
      this.setPrevState();
    }
  }

  public get data(): Types.Plugin<TPluginConfig> {
    return StateUtils.getPluginData<TPluginConfig>(this.plugin.id)?.card!;
  }

  public setDelegate(delegate: StatefulPluginDelegate<TPluginConfig>) {
    super.setDelegate(delegate);

    this.unsubscribeEditPlugin = StateUtils.subscribeEditPlugin<TPluginConfig>(
      this.plugin.id,
      this.pluginDidSet.bind(this),
      this.pluginDidSetError.bind(this),
    );
  }

  public set(plugin: Types.PluginGetter<TPluginConfig>) {
    const newState = typeof plugin === 'function' ? plugin(this.data) : plugin;
    StateUtils.setPluginConfigState(this.plugin.id, newState);
  }

  public save() {
    const { plugin_id: pluginType, position } = this.data!;

    this.pluginWillSave();

    return super.handleAsyncSaveWithStatus(() =>
      StateUtils.savePlugin({
        blockId: this.blockId,
        pluginId: this.plugin.id,
        pluginType,
        position,
      }),
    );
  }

  public destroy() {
    this.unsubscribeEditPlugin?.();
  }
}
