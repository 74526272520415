import React from 'react';
import {
  TextWithAttributesEditor,
  deserialize,
  EditorModeEnum,
  Attributes,
  ITextWithAttributesEditorProps,
} from '@ui/TextWithAttributesEditor';
import { ignoreEnterKey } from '@utils/DOM/keyHandlers';
import * as css from './AttributeInput.css';

export interface AttributeInputProps
  extends Pick<ITextWithAttributesEditorProps, 'onBlur' | 'containerId'> {
  attributes: Attributes;
  attributeName: string;
  onChange(config: string): void;
  placeholder: string;
  disabled?: boolean;
  maxLength: number | undefined;
}

export const AttributeInput: React.FC<AttributeInputProps> = ({
  attributes,
  attributeName,
  onChange,
  onBlur,
  placeholder,
  disabled,
  maxLength,
  containerId,
}) => (
  <TextWithAttributesEditor
    containerId={containerId}
    maxLength={maxLength}
    disabled={disabled}
    className={css.textEditor}
    containerClassName={css.textEditorContainer}
    placeholderContainerClassName={css.textEditorPlaceholder}
    attributes={attributes}
    defaultValue={deserialize(attributeName)}
    placeholder={placeholder}
    onStringChange={onChange}
    onBlur={onBlur}
    multiLinePlaceholder={false}
    singleLine={false}
    onKeyDown={ignoreEnterKey}
    fixedEditorMode={EditorModeEnum.edit}
    shouldMoveCursorToEndOnFocus
    hasManageAttributes
    error={!attributeName.length}
    data-testid="text-with-attributes-editor"
  />
);
