import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useCurrentBotId, useCurrentFlowId } from '@utils/Routing';
import {
  connectPage,
  ConnectPageField,
  ConnectPageOrigin,
  getConnectPageUrlParams,
} from '@components/ConnectPageDialog';
import { getFlowController } from '../../PixiFieldRepository';
import { getFocusedBlock } from '../../utils/focusedBlock';

export const useFocusBlockOrConnectPage = () => {
  const location = useLocation();
  const botId = useCurrentBotId()!;
  const flowId = useCurrentFlowId();

  useEffect(() => {
    const controller = getFlowController();
    if (!controller) {
      return;
    }
    const { focusedBlockId } = getFocusedBlock();
    if (focusedBlockId) {
      controller.focusOnBlock(focusedBlockId);
    } else {
      const urlParams = getConnectPageUrlParams();
      const urlBotId = urlParams[ConnectPageField.botId];
      const origin = urlParams[ConnectPageField.origin];
      if (
        origin === ConnectPageOrigin.entryPoint ||
        origin === ConnectPageOrigin.testThis
      ) {
        connectPage({
          urlParams,
          botId: (urlBotId ?? botId)!,
          onPageConnected({ status, pageId, verifiedPermissions }) {
            controller.updateFlowBuilderPage(
              status,
              pageId,
              verifiedPermissions,
            );
          },
        });
      }
    }
  }, [botId, flowId, location]);
};
