import React, { useMemo, useState } from 'react';
import { EditorPanelPluginProps } from '../../../types';
import { openAiPluginFragment_config as OpenAiPluginConfig } from '@components/Plugins/OpenAiPlugin/@types/openAiPluginFragment';
import { PluginDataChildrenParams } from '@components/Plugins/common';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { PluginHeader } from '../../common/PluginHeader';
import { useReactPluginConfig } from '@components/Plugins/common/useReactPluginConfig';
import { useAttributes } from '@utils/AttributesUtils/AttributesData';
import { OnboardingTourHTMLElementId, VariablesType } from '@globals';
import { VariableSuggestType } from '@utils/AttributesUtils/AttributesUtilsTypes';
import { useFlowPlatform } from '@utils/Data/Flow/useFlowPlatform';
import { ButtonGroup } from './ButtonGroup';
import { Tabs } from './types';
import { IntentsTab } from './IntentTab/IntentsTab';
import { LiveChatTab } from './LiveChatTab/LiveChatTab';
import { PromptTab } from './PromptTab/PromptTab';
import { PluginTitle } from './PluginTitle/PluginTitle';

export const OpenAiPlugin: React.FC<
  EditorPanelPluginProps<OpenAiPluginConfig>
> = ({ botId, pluginData, pluginParams }) => {
  const { pluginId } = pluginData;

  return (
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    <OpenAiPluginContent botId={botId} pluginId={pluginId} {...pluginParams} />
  );
};

interface OpenAiContentProps
  extends PluginDataChildrenParams<OpenAiPluginConfig> {
  botId: string;
  pluginId: string;
}

const OpenAiPluginContent: React.FC<OpenAiContentProps> = ({
  botId,
  savePluginAsync,
  pluginConfig,
  saving,
  setPluginConfigState,
}) => {
  const { t } = useSafeTranslation();
  const { configState, updateConfig, savePluginSafely } = useReactPluginConfig({
    saving,
    pluginConfig,
    setPluginConfigState,
    savePluginAsync,
  });
  const platform = useFlowPlatform();
  const { attributes } = useAttributes(
    botId,
    VariableSuggestType.template,
    platform,
  );
  const availableAttributes = useMemo(
    () =>
      attributes.filter(
        (a) =>
          a.type === VariablesType.custom || a.type === VariablesType.system,
      ),
    [attributes],
  );

  const [selectedTabId, setSelectedTabId] = useState<Tabs>(Tabs.intents);

  const tabs = [
    {
      title: t(
        'modernComponents.FlowBuilder.views.components.OpenAiPlugin.ButtonGroup.intents',
      ),
      id: Tabs.intents,
    },
    {
      title: t(
        'modernComponents.FlowBuilder.views.components.OpenAiPlugin.ButtonGroup.liveChat',
      ),
      id: Tabs.liveChat,
      buttonId: OnboardingTourHTMLElementId.OpenAiPluginLiveChatTab,
    },
    {
      title: t(
        'modernComponents.FlowBuilder.views.components.OpenAiPlugin.ButtonGroup.promptPreview',
      ),
      id: Tabs.promptPreview,
    },
  ];

  const selectedTabContent = {
    [Tabs.intents]: (
      <IntentsTab
        botId={botId}
        intents={configState.intents}
        updateIntents={(newIntents) => updateConfig({ intents: newIntents })}
        updatePrompt={(newPrompt) => updateConfig({ prompt: newPrompt })}
        updatePromptSafely={(newPrompt) =>
          savePluginSafely({ prompt: newPrompt })
        }
        availableAttributes={availableAttributes}
        prompt={configState.prompt}
        messagesCount={configState.messagesHistory ?? 5}
        updateMessagesCount={(newCount) =>
          updateConfig({ messagesHistory: Number(newCount) })
        }
        updateMessagesCountSafely={(newCount) =>
          savePluginSafely({ messagesHistory: Number(newCount) })
        }
      />
    ),
    [Tabs.liveChat]: (
      <LiveChatTab
        botId={botId}
        availableAttributes={availableAttributes}
        liveChatPrompt={configState.liveChatPrompt ?? ''}
        updateText={(newText) => updateConfig({ liveChatPrompt: newText })}
        updateTextSafely={(newText) =>
          savePluginSafely({ liveChatPrompt: newText })
        }
      />
    ),
    [Tabs.promptPreview]: (
      <PromptTab intents={configState.intents} prompt={configState.prompt} />
    ),
  }[selectedTabId];

  return (
    <div id={OnboardingTourHTMLElementId.OpenAiPlugin}>
      <PluginHeader
        title={
          <PluginTitle
            intents={configState.intents}
            prompt={configState.prompt}
          />
        }
      />
      <ButtonGroup<Tabs>
        items={tabs}
        onSelect={setSelectedTabId}
        selectedItemId={selectedTabId}
      />
      {selectedTabContent}
    </div>
  );
};
