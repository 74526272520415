import React from 'react';
import { PluginDataChildrenParams } from '@components/Plugins/common';
import { StatefulPlugin } from '@components/FlowBuilder/StatefulPlugin';
import { ChatPluginHeader } from '../CustomerChatEntryPoint/components/ChatPluginHeader';
import {
  CodeControl,
  GreetingDialogDelayControl,
  GreetingDialogDisplayControl,
} from '../CustomerChatEntryPoint/components/controls';
import { CollapsibleSectionWithShadow } from '@ui/CollapsibleSection/CollapsibleSectionWithShadow/CollapsibleSectionWithShadow';
import { whatsappWidgetEntryPointFragment_config as WhatsappWidgetEntryPointConfig } from '../../../../../Plugins/WhatsappWidgetEntryPoint/@types/whatsappWidgetEntryPointFragment';
import { Spacer } from '@ui/Spacer';
import { PluginControlLabel } from '@components/Plugins/common/PluginControlLabel';
import { WelcomeMessageControl } from './WelcomeMessageControl';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { WELCOME_MESSAGE_DISPLAY_VARIANTS } from './const';
import { getEntryPointClientCode } from '../common/getEntryPointClientCode';
import { DisplayVariant } from '@globals';
import * as css from './EntryPointWhatsappWidgetContent.css';
import { Type } from '@ui/Type';
import { Icon } from '@ui/Icon';
import { Flex } from '@ui/Flex';
import { Tooltip2 } from '@ui/Tooltip2';

interface EntryPointWhatsappWidgetContentProps
  extends PluginDataChildrenParams<WhatsappWidgetEntryPointConfig> {
  botId: string;
  blockId: string;
  pluginId: string;
  pageId: string;
  state: StatefulPlugin<WhatsappWidgetEntryPointConfig>;
}

export const EntryPointWhatsappWidgetContent: React.FC<EntryPointWhatsappWidgetContentProps> =
  ({ blockId, pluginConfig, state }) => {
    const { t } = useSafeTranslation();
    const pluginType = state.data.plugin_id;

    const code = getEntryPointClientCode(blockId);

    return (
      <>
        <ChatPluginHeader pluginType={pluginType}>
          {t('modernComponents.FlowBuilder.EditorPanel.WhatsappChat.title')}
        </ChatPluginHeader>

        <CodeControl
          code={code}
          pluginType={pluginType}
          hideInstructions
          light
        />

        <Spacer factor={6} />

        <CollapsibleSectionWithShadow
          title={t(
            'modernComponents.FlowBuilder.EditorPanel.WhatsappChat.messages',
          )}
          initialValue
        >
          <div className={css.section}>
            <PluginControlLabel
              light
              label={t(
                'modernComponents.FlowBuilder.EditorPanel.WhatsappChat.welcomeMessage',
              )}
              description={t(
                'modernComponents.FlowBuilder.EditorPanel.WhatsappChat.description',
              )}
              data-testid="whatsapp-chat-widget__welcome-message-control"
            >
              {(bind) => (
                <WelcomeMessageControl
                  {...bind}
                  state={state}
                  pluginType={pluginType}
                  pluginConfig={pluginConfig}
                  value={pluginConfig.welcomeMessage || ''}
                  field="welcomeMessage"
                />
              )}
            </PluginControlLabel>

            <Spacer factor={5} />

            <PluginControlLabel
              light
              label={
                <Flex inline>
                  <Type weight="semibold" size="12px" color="cobaltTertiary">
                    {t(
                      'modernComponents.FlowBuilder.EditorPanel.WhatsappChat.customerMessage',
                    )}
                  </Type>

                  <Tooltip2
                    boundariesElement="viewport"
                    content={t(
                      'modernComponents.FlowBuilder.EditorPanel.WhatsappChat.tooltip',
                    )}
                    hoverable
                  >
                    {(ref, bind) => (
                      <Icon
                        icon="info"
                        size="16px"
                        color="cobaltTertiary"
                        ref={ref}
                        {...bind}
                      />
                    )}
                  </Tooltip2>
                </Flex>
              }
              data-testid="whatsapp-chat-widget__pre-filled-message-control"
            >
              {(bind) => (
                <WelcomeMessageControl
                  {...bind}
                  state={state}
                  pluginType={pluginType}
                  pluginConfig={pluginConfig}
                  value={pluginConfig.prefilledMessage || ''}
                  error={(pluginConfig.prefilledMessage || '').length === 0}
                  required
                  field="prefilledMessage"
                />
              )}
            </PluginControlLabel>
          </div>
        </CollapsibleSectionWithShadow>

        <Spacer factor={6} />

        {pluginConfig.welcomeMessage && (
          <CollapsibleSectionWithShadow
            title={t(
              'modernComponents.FlowBuilder.EditorPanel.WhatsappChat.appearance',
            )}
          >
            <div className={css.section}>
              <GreetingDialogDisplayControl
                field="displayVariant"
                value={pluginConfig.displayVariant || DisplayVariant.leaveOpen}
                items={WELCOME_MESSAGE_DISPLAY_VARIANTS()}
                state={state}
                pluginType={pluginType}
                pluginConfig={pluginConfig}
              />

              {pluginConfig.displayVariant ===
                DisplayVariant.tenSecThenHide && (
                <>
                  <Spacer factor={6} />

                  <GreetingDialogDelayControl
                    field="welcomeMessageDelay"
                    value={pluginConfig.welcomeMessageDelay || 0}
                    state={state}
                    pluginType={pluginType}
                    pluginConfig={pluginConfig}
                  />
                </>
              )}
            </div>
          </CollapsibleSectionWithShadow>
        )}
      </>
    );
  };
