import { useCurrentFlowId } from '@utils/Routing';
import { useFlowStats } from '@utils/Data/Flow/useFlowStats';
import {
  NEW_FOLLOW_UP_DEFAULT_DELAY,
  NEW_FOLLOW_UP_DEFAULT_TEXT,
} from '../consts';
import { quickReplyPluginFragment_config_follow_ups } from '../../../../../../Plugins/QuickReplyPlugin/@types/quickReplyPluginFragment';
import { useCallback, useEffect, useRef } from 'react';
import {
  createFollowUp,
  createLocalFollowUps,
  prepareFollowUpsItems,
} from '../helpers';
import { sendEvent } from '@utils/Analytics';
import { debounce } from 'lodash-es';
import { PluginDataChildrenParams } from '../../../../../../Plugins/common';
import { QuickReplyPluginConfig } from '../../../../../../Plugins/QuickReplyPlugin/QuickReplyPluginConst';
import { FollowUpsListProps } from '../FollowUpsList';
import { useWhatsappEnabled } from '@utils/Whatsapp';

interface LocalFollowUpsRes extends Omit<FollowUpsListProps, 'textAttributes'> {
  setupLocalFollowUps: () => void;
  hasPluginLocalFollowUps: boolean;
}

export const useLocalFollowUps = ({
  pluginConfig,
  setPluginConfigState,
  savePlugin,
}: PluginDataChildrenParams<QuickReplyPluginConfig>): LocalFollowUpsRes => {
  const flowId = useCurrentFlowId()!;
  const { flowStats } = useFlowStats(flowId);
  const { isWhatsappEnabled } = useWhatsappEnabled();

  const followUps =
    pluginConfig?.follow_ups ||
    ([] as quickReplyPluginFragment_config_follow_ups[]);

  const setPluginConfigFollowUps = useCallback(
    (followUps: quickReplyPluginFragment_config_follow_ups[]) => {
      setPluginConfigState({
        config: {
          ...pluginConfig,
          follow_ups: followUps,
        },
      });
    },
    [pluginConfig, setPluginConfigState],
  );

  const onAdd = () => {
    setPluginConfigFollowUps([
      ...followUps,
      createFollowUp(NEW_FOLLOW_UP_DEFAULT_TEXT, NEW_FOLLOW_UP_DEFAULT_DELAY),
    ]);
    savePlugin();
    sendEvent({
      category: 'flows',
      action: 'add follow up',
      propertyBag: {
        flowId,
      },
    });
  };

  const onRemove = (followUpId: string) => {
    setPluginConfigFollowUps(followUps.filter(({ id }) => id !== followUpId));
    savePlugin();

    sendEvent({
      category: 'flows',
      action: 'delete follow up',
      propertyBag: {
        flowId,
      },
    });
  };

  /**
   * Пришлось сделать через ref а не через state, из-за лишнего вызова хука
   * который сохраняет фоллоу апы при закрытие при нажатии на флоу билдер
   */
  const updatedFollowUpIds = useRef<Array<string>>([]);

  const editFollowUp = (followUpId: string, text: string, delay: number) => {
    const updatedFollowUps = followUps.map((followUp) =>
      followUp.id === followUpId
        ? {
            ...followUp,
            text,
            delay_ms: delay,
          }
        : followUp,
    );

    setPluginConfigFollowUps(updatedFollowUps);

    if (!updatedFollowUpIds.current.includes(followUpId)) {
      updatedFollowUpIds.current = [...updatedFollowUpIds.current, followUpId];
    }
  };

  const updateFollowUps = useCallback(
    debounce(() => {
      savePlugin();

      if (updatedFollowUpIds.current.length) {
        updatedFollowUpIds.current = [];
      }
    }, 200),
    [],
  );

  useEffect(() => updateFollowUps, [updateFollowUps]);

  const items = prepareFollowUpsItems(flowStats, followUps);

  const findFollowUpItem = (followUpId: string) => {
    return items.find((item) => item.id === followUpId);
  };

  const onTimeChange = (followUpId: string, quantValue: number) => {
    const followUpItem = findFollowUpItem(followUpId);
    if (followUpItem) {
      editFollowUp(followUpId, followUpItem.text, quantValue);
    }
  };

  const onTextChange = (followUpId: string, text: string) => {
    const followUpItem = findFollowUpItem(followUpId);
    if (followUpItem) {
      editFollowUp(followUpId, text, followUpItem.delay);
    }
  };

  const hasPluginLocalFollowUps = followUps.length > 0;

  const setupLocalFollowUps = useCallback(() => {
    setPluginConfigFollowUps(createLocalFollowUps(isWhatsappEnabled));
    savePlugin();
  }, [setPluginConfigFollowUps, savePlugin, isWhatsappEnabled]);

  return {
    items,
    onTimeChange,
    onTextChange,
    onRemove,
    onAdd,
    updateFollowUps,
    setupLocalFollowUps,
    hasPluginLocalFollowUps,
    isAddInProcess: false,
  };
};
