import React, { useCallback } from 'react';
import { Flex } from '@ui/Flex';
import { CheckBox, CheckBoxProps } from '@ui/CheckBox';
import { over, not, Lens, view } from 'ramda';
import { Type } from '@ui/Type';
import { Icon } from '@ui/Icon';
import { Spacer } from '@ui/Spacer';
import { PluginDataChildrenParams } from '@components/Plugins/common';
import { QuickReplyPluginConfig } from '@components/Plugins/QuickReplyPlugin/QuickReplyPluginConst';
import { Tooltip2, TooltipPureProps } from '@ui/Tooltip2';
import { PluginType } from '../../../../../types';
import { logFlowEvent } from '../../../../../utils/Analytics';

interface CheckboxWithTooltipViewProps extends TestLocator {
  label: string;
  tooltipText: TooltipPureProps['content'];
}

const CheckboxWithTooltipView: React.FC<
  CheckboxWithTooltipViewProps & CheckBoxProps
> = ({ label, tooltipText, ...checkBoxProps }) => (
  <Flex alignItems="center">
    <label style={{ display: 'flex', alignItems: 'center' }}>
      <CheckBox checked={checkBoxProps.checked || false} {...checkBoxProps} />
      <Spacer horizontalFactor={2} />
      <Type size="15px">{label}</Type>
    </label>
    <Spacer horizontalFactor={3} />

    <Tooltip2
      placement="right"
      boundariesElement="viewport"
      content={tooltipText}
    >
      {(ref, bind) => (
        <div ref={ref} {...bind}>
          <Icon icon="info" color="grey" />
        </div>
      )}
    </Tooltip2>
  </Flex>
);

export interface CheckboxWithTooltipProps
  extends PluginDataChildrenParams<QuickReplyPluginConfig>,
    CheckboxWithTooltipViewProps {
  lens: Lens;
}

export const CheckboxWithTooltip: React.FC<CheckboxWithTooltipProps> = ({
  pluginConfig,
  setPluginConfigState,
  label,
  lens,
  tooltipText,
  ...props
}) => {
  const onChange = useCallback(() => {
    setPluginConfigState({
      config: over(lens, not, pluginConfig),
    });

    logFlowEvent(PluginType.quick_reply, 'checkbox click', {
      quickReplyType: pluginConfig.type,
    });
  }, [pluginConfig, setPluginConfigState, lens]);

  return (
    <CheckboxWithTooltipView
      tooltipText={tooltipText}
      label={label}
      checked={view(lens, pluginConfig)}
      onChange={onChange}
      data-testid={props['data-testid']}
    />
  );
};
