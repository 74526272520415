import React from 'react';
import { Type } from '@ui/Type';
import { Flex } from '@ui/Flex';
import { wrapAllAttributes } from '@ui/TextWithAttributesEditor/attributesBoundariesUtils';

export interface GetLengthLimit {
  text: string;
  parameters: Array<{ text: string | null }> | null;
  getTotalLength(): number;
}

interface LengthLimit {
  current: number;
  total: number;
  remaining: number;
  error: boolean;
}

export const getLenghtLimit = ({
  text,
  parameters,
  getTotalLength,
}: GetLengthLimit): LengthLimit => {
  const total = getTotalLength();

  const attrs = parameters
    ?.map(({ text }) => wrapAllAttributes(text || '', () => ''))
    .join('');
  const current = text.length + (attrs?.length || 0);
  const error = current > total;

  return {
    remaining: total - current,
    current,
    total,
    error,
  };
};

export interface LengthLimitProps extends LengthLimit {
  children: string;
}

export const LengthLimit: React.FC<LengthLimitProps> = ({
  error,
  current,
  total,
  children,
}) => (
  <Flex
    justifyContent="space-between"
    alignItems="center"
    style={{ width: '410px' }}
  >
    <Type weight="semibold" size="15px" color="black">
      {children}
    </Type>

    <Type size="12px" weight="medium" color={error ? 'red' : 'cobaltSemilight'}>
      {current}/{total}
    </Type>
  </Flex>
);
