import { PluginType } from '@components/Plugins/common/PluginTypes';
import { assignAdminPluginFragment_config } from './@types/assignAdminPluginFragment';

export type AssignAdminPluginConfig = assignAdminPluginFragment_config;

export const PLUGIN_TYPE = PluginType.typing;

export const PLUGIN_DEFAULT_CONFIG: AssignAdminPluginConfig = {
  __typename: 'AssignAdminPluginConfig',
  admin_ids: [],
};

export const PLUGIN_INPUT_FIELD = 'assignAdminPluginConfig';
