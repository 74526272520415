import React from 'react';
import { Type } from '@ui/Type';
import { Tooltip2 } from '@ui/Tooltip2';
import { humanizeNumber } from '@utils/humanizeNumber';
import { FullCounterStats } from '../../utils/Statistics/types';
import { calcPercent } from '../../views/kit/ItemStatView/helpers';
import { StatTooltip } from '../../views/components/BlockStatsView/components/StatTooltip';
import { StatFieldsIds } from '../../views/components/BlockStatsView';

interface StatItemProps {
  stats: FullCounterStats;
  fieldId?: StatFieldsIds;
  hideUniqStat?: boolean;
}

export const StatItem: React.FC<StatItemProps> = ({
  stats: { id, counter, uniqStats: blockStats },
  fieldId = StatFieldsIds.clicked,
}) => {
  const uniqValue = counter.uniqStats?.[fieldId] || 0;
  const notUniqValue = counter.notUniqStats?.[fieldId] || 0;

  const percentValue = calcPercent(blockStats?.[fieldId], uniqValue);
  const value = humanizeNumber(percentValue);
  return (
    <Tooltip2
      hoverable
      type="small"
      placement="right"
      boundariesElement="viewport"
      content={
        <StatTooltip
          blockId={id}
          fieldId={fieldId}
          uniqValue={uniqValue}
          notUniqValue={notUniqValue}
        />
      }
    >
      {(ref, bind) => (
        <div {...bind} ref={ref}>
          <Type size="15px" weight="semibold">
            {value}%
          </Type>
        </div>
      )}
    </Tooltip2>
  );
};
