import {
  FlowNotFoundError,
  RecreatedFlowError,
} from '@utils/Data/Flow/Aggregated/errors';
import { EventEmitter } from '@utils/EventEmitter';
import { Feature, ViewMode } from './consts';
import { PixiField } from './PixiField';
import { FlowController } from './flow/controller';
import { FlowData } from './types';

interface RepositoryItem {
  pixiField: PixiField;
  controller: FlowController;
}

enum PixiFieldRepositoryEvent {
  fieldAdded = 'fieldAdded',
}

export class PixiFieldRepository {
  private repository: Record<string, RepositoryItem> = {};

  private activeElementId?: string;

  private eventEmitter = new EventEmitter();

  remove(elementId: string): void {
    if (this.repository[elementId]) {
      this.repository[elementId].controller.destroy();
      this.repository[elementId].pixiField.destroy();
      delete this.repository[elementId];
      const [activeElementIdCandidate] = Object.keys(this.repository);
      if (elementId === this.activeElementId && activeElementIdCandidate) {
        this.activeElementId = activeElementIdCandidate;
      }
    }
  }

  async add(
    elementId: string,
    flowLoader: () => Promise<FlowData>,
    mode = ViewMode.edit,
    availableFeatures: Feature[] = [],
  ): Promise<RepositoryItem | undefined> {
    const pixiField = new PixiField(elementId, mode, availableFeatures);
    const controller = new FlowController(pixiField, flowLoader);
    const repositoryItem: RepositoryItem = {
      pixiField,
      controller,
    };
    try {
      await controller.fetchFlow();
      this.remove(elementId);
      this.repository[elementId] = repositoryItem;
      this.activeElementId = elementId;
      pixiField.init();
      this.eventEmitter.emit(PixiFieldRepositoryEvent.fieldAdded);
      return repositoryItem;
    } catch (error) {
      controller.destroy();
      pixiField.destroy();
      if (
        !(
          error instanceof RecreatedFlowError ||
          error instanceof FlowNotFoundError
        )
      ) {
        throw error;
      }
    }

    return undefined;
  }

  setActiveField(elementId: string): void {
    if (Object.keys(this.repository).includes(elementId)) {
      this.activeElementId = elementId;
    }
  }

  getActiveField(): Readonly<PixiField> | undefined {
    if (this.activeElementId) {
      return this.repository[this.activeElementId]?.pixiField;
    }
    return undefined;
  }

  getActiveController(): Readonly<FlowController> | undefined {
    if (this.activeElementId) {
      return this.repository[this.activeElementId]?.controller;
    }
    return undefined;
  }

  getActiveControllerWhenReady(): Promise<Readonly<FlowController>> {
    return new Promise((resolve) => {
      const controller = this.getActiveController();
      if (controller) {
        resolve(controller);
      } else {
        const fieldAddedEventHandler = () => {
          const controller = this.getActiveController();
          if (controller) {
            resolve(controller);
          }
          this.eventEmitter.off(
            PixiFieldRepositoryEvent.fieldAdded,
            fieldAddedEventHandler,
          );
        };
        this.eventEmitter.on(
          PixiFieldRepositoryEvent.fieldAdded,
          fieldAddedEventHandler,
        );
      }
    });
  }
}

export const pixiFieldRepository = new PixiFieldRepository();

export function getPixiFieldStrict(): Readonly<PixiField> {
  const pixiField = pixiFieldRepository.getActiveField();
  if (!pixiField) {
    throw new Error("pixiField doesn't exist");
  }
  return pixiField;
}

export function getPixiField(): Readonly<PixiField> | undefined {
  return pixiFieldRepository.getActiveField();
}

export function getFlowControllerStrict(): Readonly<FlowController> {
  const controller = pixiFieldRepository.getActiveController();
  if (!controller) {
    throw new Error("controller doesn't exist");
  }
  return controller;
}

export function getFlowController(): Readonly<FlowController> | undefined {
  return pixiFieldRepository.getActiveController();
}
