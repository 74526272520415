import React, { useCallback } from 'react';
import cn from 'classnames';
import { Editor } from 'slate-react';
import { useCurrentBotId } from '@utils/Routing';
import { Input } from '@ui/Input';
import { TextOutsideControls } from '@ui/TextOutsideControls';
import {
  deserialize,
  Attributes,
  TextWithAttributesEditor,
  EditorModeEnum,
  serialize,
} from '@ui/TextWithAttributesEditor';
import { insertText } from '@utils/Slite/insertText';
import { AttributesData } from '@utils/AttributesUtils/AttributesData';
import { CollapsibleSectionWithShadow } from '@ui/CollapsibleSection/CollapsibleSectionWithShadow/CollapsibleSectionWithShadow';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { getQuickReplyConfig } from '@components/Plugins/QuickReplyPlugin/QuickReplyPluginConst';
import { Changable, QuickReplyPluginData } from '../types';
import { logFlowEvent } from '../../../../../utils/Analytics';
import { PluginType } from '../../../../../types';
import * as css from './InvalidInputMessage.css';
import { getFlowPlatform } from '../../../../../utils/getFlowPlatform';

interface InvalidInputMessageViewProps extends Changable<string> {
  currentTextLimit: number;
  attributes: Attributes;
  onBlur: (event: Event, value: string) => void;
  error: boolean;
}

const MAX_TEXT_LENGTH = 640;

const InvalidInputMessageView: React.FC<InvalidInputMessageViewProps> = ({
  currentTextLimit,
  attributes,
  onBlur,
  onChange,
  value,
  error,
}) => {
  const { t } = useSafeTranslation();

  return (
    <CollapsibleSectionWithShadow
      title={t(
        'modernComponents.FlowBuilder.views.components.CollectUserData.common.invalidInputMessage',
      )}
    >
      <div style={{ padding: '16px' }}>
        <Input
          error={error}
          render={() => (
            <TextOutsideControls
              initialShow={false}
              onInsertRequest={(val, el) => {
                insertText(val, el as Editor);
              }}
              currentTextLimit={currentTextLimit}
              shouldShowOutsideControls={{
                emoji: true,
                attributes: true,
                symbolsLimit: true,
              }}
            >
              {({ ref, getInputProps }) => (
                <TextWithAttributesEditor
                  style={{ height: '80px' }}
                  editorRef={ref}
                  defaultValue={deserialize(value)}
                  maxLength={MAX_TEXT_LENGTH}
                  onBlur={(event, value) => {
                    onBlur(event, value);
                    getInputProps().onBlur(event);
                  }}
                  onFocus={getInputProps().onFocus}
                  onChange={(v) => {
                    onChange(serialize(v));
                  }}
                  attributes={attributes}
                  fixedEditorMode={EditorModeEnum.edit}
                  placeholder={
                    error
                      ? t(
                          'modernComponents.FlowBuilder.views.components.CollectUserData.common.invalidInputMessageError',
                        )
                      : ''
                  }
                  placeholderContainerClassName={cn({ [css.error]: error })}
                  shouldMoveCursorToEndOnFocus
                  hasManageAttributes
                  multiLinePlaceholder
                />
              )}
            </TextOutsideControls>
          )}
        />
      </div>
    </CollapsibleSectionWithShadow>
  );
};

export const InvalidInputMessage: React.FC<QuickReplyPluginData> = ({
  savePlugin,
  pluginConfig,
  setPluginConfigState,
}) => {
  const errorMessage = pluginConfig.error_messages?.[pluginConfig.type!] || '';
  const botId = useCurrentBotId()!;

  const onChange = useCallback(
    (value) => {
      const errorMessages =
        pluginConfig.error_messages || getQuickReplyConfig({}).error_messages;
      const updatedErrorMessages = {
        ...errorMessages,
        [pluginConfig.type!]: value,
      };

      setPluginConfigState({
        config: {
          ...pluginConfig,
          error_messages: updatedErrorMessages,
        },
      });
    },
    [pluginConfig, setPluginConfigState],
  );

  const onBlur = useCallback(() => {
    savePlugin();
    logFlowEvent(PluginType.quick_reply, 'invalid input message changed', {
      quickReplyType: pluginConfig.type,
    });
  }, [savePlugin, pluginConfig.type]);

  const currentTextLimit = Math.max(
    0,
    MAX_TEXT_LENGTH - (errorMessage?.length ?? 0),
  );
  const error = errorMessage?.length === 0;

  return (
    <AttributesData botId={botId} platform={getFlowPlatform()}>
      {({ attributes }) => (
        <InvalidInputMessageView
          attributes={attributes}
          onBlur={onBlur}
          onChange={onChange}
          value={errorMessage}
          error={error}
          currentTextLimit={currentTextLimit}
        />
      )}
    </AttributesData>
  );
};
