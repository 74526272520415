import React from 'react';
import cn from 'classnames';
import { Flex } from '@ui/Flex';
import { Button } from '@ui/Button';
import css from './Tabs.css';

interface TabsProps<Id extends string = string> {
  selectedItemId: Id;
  onSelect(id: Id): void;
  items: Array<{ title: string; id: Id; buttonId?: string }>;
}

export const Tabs = <Id extends string = string>({
  selectedItemId,
  onSelect,
  items,
}: TabsProps<Id>) => (
  <Flex className={css.container}>
    {items.map(({ title, id, buttonId }) => (
      <Button
        key={id}
        size="s"
        className={css.item}
        intent={selectedItemId === id ? 'primary' : 'text'}
        textClassName={cn(selectedItemId !== id && css.itemText)}
        data-testid={`button-group_${buttonId}`}
        onClick={() => onSelect(id)}
      >
        {title}
      </Button>
    ))}
  </Flex>
);
