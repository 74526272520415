import React, { useState } from 'react';
import { Spacer } from '@ui/Spacer';
import { PluginControlLabel } from '@components/Plugins/common/PluginControlLabel';
import {
  isJsonEncoded,
  isNotShowJsonValidError,
} from '@components/Plugins/JsonPlugin/JsonPluginUtils';
import {
  FULL_PROFILE_BODY_BY_PLATFORM,
  JsonPluginRequestPostFormat,
  jsonPluginRequestPostFormats,
} from '@components/Plugins/JsonPlugin/JsonPluginConst';
import { Flex } from '@ui/Flex';
import { SegmentedGroupTrack } from '@ui/SegmentedGroup';
import { SegmentedGroupTrackColorWay } from '@ui/SegmentedGroup/SegmentedGroupTrack';
import * as ButtonDeprecated from '@ui/_deprecated/Button';
import { jsonPluginFragment_config as JSONPluginConfig } from '@components/Plugins/JsonPlugin/@types/jsonPluginFragment';
import { Input } from '@ui/Input';
import {
  deserialize,
  TextWithAttributesEditor,
} from '@ui/TextWithAttributesEditor';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { AttributesQuery_bot_variableSuggest as Attribute } from '@utils/AttributesUtils/@types/AttributesQuery';
import { Platform } from '@globals';
import { UrlEncodedData } from '@components/Plugins/JsonPlugin/UrlEncodedData';
import * as originCss from '@components/Plugins/JsonPlugin/JsonPlugin.css';
import * as css from '../JSONPlugin.css';

interface JsonPostDataProps {
  postFormat: JSONPluginConfig['post_format'];
  body: JSONPluginConfig['body'];
  botAttributes: Attribute[];
  platform: Platform;
  updateConfig(configPatch?: Partial<JSONPluginConfig>): void;
  savePluginSafely(configPatch?: Partial<JSONPluginConfig>): void;
}

export const JsonPostData: React.FC<JsonPostDataProps> = ({
  postFormat,
  body,
  botAttributes,
  platform,
  updateConfig,
  savePluginSafely,
}) => {
  const { t } = useSafeTranslation();
  const [jsonBody, setJsonBody] = useState('');
  const [urlEncodedBody, setUrlEncodedBody] = useState('');

  return (
    <>
      <PluginControlLabel
        description={
          isJsonEncoded(postFormat) ||
          postFormat === JsonPluginRequestPostFormat.full_json_profile ? (
            <Flex justifyContent="space-between">
              <div>
                {isJsonEncoded(postFormat) &&
                  t(
                    'JSONPlugin-string-3698-you-can-add-existing-user-attributes-with',
                    { skipInterpolation: true },
                  )}
                {postFormat === JsonPluginRequestPostFormat.full_json_profile &&
                  t(
                    'JSONPlugin-string--216-well-send-all-user-attributes-in-json-format-to-the-specified-url',
                  )}
              </div>
              {!isNotShowJsonValidError(body) && (
                <div className={originCss.error}>
                  {t('JSONPlugin-JSXText-6320-invalid-json')}
                </div>
              )}
            </Flex>
          ) : undefined
        }
      >
        {({ id: domId }) => (
          <React.Fragment>
            <Flex alignItems="center">
              <SegmentedGroupTrack
                role="tablist"
                colorWay={SegmentedGroupTrackColorWay.greyLight}
              >
                {jsonPluginRequestPostFormats().map((format) => (
                  <ButtonDeprecated.Button
                    key={format.type}
                    role="tab"
                    intent={ButtonDeprecated.ButtonIntent.toggle}
                    colorWay={ButtonDeprecated.ButtonColorWay.toggleWhiteHover}
                    size={ButtonDeprecated.ButtonSize.s}
                    aria-selected={
                      postFormat === format.type ||
                      (!postFormat &&
                        format.type === JsonPluginRequestPostFormat.json)
                    }
                    onClick={() => {
                      const fromFormat = postFormat;
                      const toFormat = format.type;

                      if (fromFormat === JsonPluginRequestPostFormat.json) {
                        setJsonBody(body || '');
                      }
                      if (
                        fromFormat === JsonPluginRequestPostFormat.urlencoded
                      ) {
                        setUrlEncodedBody(body || '');
                      }

                      const toBody =
                        toFormat === JsonPluginRequestPostFormat.json
                          ? jsonBody
                          : toFormat === JsonPluginRequestPostFormat.urlencoded
                          ? urlEncodedBody
                          : '';

                      savePluginSafely({
                        body: toBody,
                        post_format: toFormat,
                      });
                    }}
                  >
                    {format.title}
                  </ButtonDeprecated.Button>
                ))}
              </SegmentedGroupTrack>
            </Flex>
            <Spacer factor={4} />

            {isJsonEncoded(postFormat) && (
              <Input
                render={() => (
                  <TextWithAttributesEditor
                    data-testid="json-post"
                    defaultValue={deserialize(body || '')}
                    onStringChange={(value) =>
                      updateConfig({
                        body: value,
                      })
                    }
                    onBlur={() => savePluginSafely()}
                    placeholder={t('JSONPlugin-string--453-enter-request-body')}
                    attributes={botAttributes}
                    className={css.requestBody}
                    id={domId}
                    hasManageAttributes
                  />
                )}
              />
            )}

            {postFormat === JsonPluginRequestPostFormat.full_json_profile && (
              <Input
                render={() => (
                  <TextWithAttributesEditor
                    data-testid="json-post-data__request-body"
                    key={botAttributes.length}
                    defaultValue={FULL_PROFILE_BODY_BY_PLATFORM[platform]}
                    attributes={[]}
                    className={css.requestBody}
                    readonly
                    id={domId}
                    hasManageAttributes
                  />
                )}
              />
            )}

            {postFormat === JsonPluginRequestPostFormat.urlencoded && (
              <UrlEncodedData
                body={body}
                botAttributes={botAttributes}
                onBodyChange={(nextBody) => updateConfig({ body: nextBody })}
                onBodySave={(nextBody) => savePluginSafely({ body: nextBody })}
              />
            )}
          </React.Fragment>
        )}
      </PluginControlLabel>
    </>
  );
};
