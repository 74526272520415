import React, { useState } from 'react';
import { EditorPanelPluginProps } from '../../../types';
import { customAiPluginFragment_config } from '@components/Plugins/CustomAiPlugin/@types/customAiPluginFragment';
import { PluginDataChildrenParams } from '@components/Plugins/common';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { PluginHeader } from '../../common/PluginHeader';
import { Tabs } from './components/Tabs';
import { SettingsTab } from './consts';
import { InfoTab } from './components/InfoTab';
import { PluginTitle } from './components/PluginTitle/PluginTitle';
import { ConditionsTab } from './components/ConditionsTab';
import { SupportedModels } from './components/TokenCounter/useTokenCount';
import { sendEvent } from 'cf-common/src/analytics';

interface CustomAiPluginContentProps
  extends PluginDataChildrenParams<customAiPluginFragment_config> {
  botId: string;
  pluginId: string;
}

const CustomAiPluginContent: React.FC<CustomAiPluginContentProps> = ({
  botId,
  savePlugin,
  pluginConfig,
  setPluginConfigState,
}) => {
  const { t } = useSafeTranslation();

  const pluginName =
    pluginConfig.title ||
    t(
      'modernComponents.FlowBuilder.views.components.CustomAiPlugin.defaultTitle',
    );

  const [selectedTabId, setSelectedTabId] = useState<SettingsTab>(
    SettingsTab.info,
  );

  const tabs = [
    {
      title: t(
        'modernComponents.FlowBuilder.views.components.CustomAiPlugin.Tabs.info',
      ),
      id: SettingsTab.info,
    },
    {
      title: t(
        'modernComponents.FlowBuilder.views.components.CustomAiPlugin.Tabs.connectors',
      ),
      id: SettingsTab.connectors,
    },
  ];

  const selectedTabContent = {
    [SettingsTab.info]: (
      <InfoTab
        model={pluginConfig.aiModel as SupportedModels}
        botId={botId}
        intents={pluginConfig.aiIntents}
        onUpdateIntents={(aiIntents) =>
          setPluginConfigState({
            config: {
              ...pluginConfig,
              aiIntents,
            },
          })
        }
        onSaveIntents={() => savePlugin()}
      />
    ),
    [SettingsTab.connectors]: (
      <ConditionsTab
        model={pluginConfig.aiModel as SupportedModels}
        botId={botId}
        conditions={pluginConfig.aiConditions}
        onUpdateConditions={(aiConditions) =>
          setPluginConfigState({
            config: {
              ...pluginConfig,
              aiConditions,
            },
          })
        }
        onSaveConditions={() => savePlugin()}
      />
    ),
  }[selectedTabId];

  return (
    <div>
      <PluginHeader
        title={
          <PluginTitle
            botId={botId}
            model={pluginConfig.aiModel as SupportedModels}
            title={pluginName}
            intents={pluginConfig.aiIntents}
            conditions={pluginConfig.aiConditions}
            maxTokens={pluginConfig.userInputMaxTokens}
          />
        }
      />
      <Tabs<SettingsTab>
        items={tabs}
        onSelect={(tab) => {
          setSelectedTabId(tab);
          sendEvent({
            category: 'ai plugin',
            label: 'custom ai',
            action: `open ${tab} tab`,
            propertyBag: {
              botId,
            },
          });
        }}
        selectedItemId={selectedTabId}
      />
      {selectedTabContent}
    </div>
  );
};

export const CustomAiPlugin: React.FC<
  EditorPanelPluginProps<customAiPluginFragment_config>
> = ({ botId, pluginData, pluginParams }) => {
  const { pluginId } = pluginData;

  return (
    <CustomAiPluginContent
      botId={botId}
      pluginId={pluginId}
      {...pluginParams}
    />
  );
};
