import { cardButtonFragment_payment_summary_price_list } from '../../../../../../Plugins/common/@types/cardButtonFragment';
import { mongoObjectId } from 'cf-common/src/utils/mongo/mongoObjectId';
import i18next from 'i18next';

export const getPriceListItem = (label: string) => ({
  __typename: 'PriceListItem' as const,
  id: mongoObjectId(),
  amount: 0,
  label,
});

export const getDefaultPriceList =
  (): cardButtonFragment_payment_summary_price_list[] => [
    getPriceListItem(i18next.t('InBotPayments.Panel.PriceList.title')),
  ];
