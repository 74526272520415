import { useEffect } from 'react';
import { setFlowBuilderLoadingState } from 'cf-common/src/setFlowBuilderLoadingState';
import { FlowLoadingState } from '@pages/BotPage/FlowTab/FlowLoader';

export const useFlowBuilderStateWriter = (
  flowId: string | undefined,
  loadingState: string,
  flowNotFound: boolean = false,
) => {
  useEffect(() => {
    setFlowBuilderLoadingState(
      flowId,
      flowNotFound ? 'notFound' : loadingState,
    );
  }, [loadingState, flowId, flowNotFound]);
  useEffect(
    () => () => {
      setFlowBuilderLoadingState(flowId, FlowLoadingState.none);
    },
    [flowId],
  );
};
