import React from 'react';
import cn from 'classnames';
import { ReactComponent as InfoIcon } from '../../../../../../modern-ui/_deprecated/Icon/icons/ic_alert_circle2.svg';
import { Tooltip2 } from '../../../../../../modern-ui/Tooltip2';
import { WhiteLabelUserData } from '../../../../../../utils/WhiteLabelUtils';
import { Type } from '../../../../../../modern-ui/Type';
import * as css from './PluginHeader.css';

interface PluginHeaderProps {
  title: React.ReactNode;
  tooltip?: React.ReactNode;
  small?: boolean;
  showTooltip?: boolean;
  containerClassName?: string;
}

export const PluginHeaderView: React.FC<PluginHeaderProps> = ({
  title,
  small,
  tooltip,
  showTooltip,
  containerClassName,
}) => (
  <div
    className={cn(css.pluginHeader, containerClassName)}
    data-testid="plugin-title"
  >
    <Type size={small ? '15px_DEPRECATED' : '18px'} weight="semibold">
      {title}
    </Type>
    {showTooltip && (
      <Tooltip2
        content={
          <Type color="white" size="12px">
            {tooltip}
          </Type>
        }
        placement="right"
        hoverable={typeof tooltip !== 'string'}
        boundariesElement="viewport"
        type="small"
      >
        {(ref, bind) => (
          <div className={css.icon} ref={ref} {...bind}>
            <InfoIcon className={css.infoIcon} />
          </div>
        )}
      </Tooltip2>
    )}
  </div>
);

export const PluginHeader: React.FC<PluginHeaderProps> = (props) => (
  <WhiteLabelUserData>
    {({ isWhiteLabelUser }) => (
      <PluginHeaderView
        {...props}
        showTooltip={!!props.tooltip && !isWhiteLabelUser}
      />
    )}
  </WhiteLabelUserData>
);
