import React from 'react';
import cn from 'classnames';
import { sendEvent } from '@utils/Analytics';
import { useCurrentFlowId } from '@utils/Routing';
import { HtmlTextMemoized } from '@components/HtmlTextMemoized';
import { Tooltip } from '../../views/Menu/types';
import * as css from './TooltipContent.css';

interface TooltipContentProps {
  id: string;
  tooltipUrl: string;
  inactive: boolean;
  tooltipOptions: Tooltip;
}

export const TooltipContent = ({
  tooltipUrl,
  inactive,
  tooltipOptions,
  id,
}: TooltipContentProps) => {
  const flowId = useCurrentFlowId();

  return (
    <a
      href={tooltipUrl}
      target={tooltipUrl ? '_blank' : undefined}
      rel="noopener noreferrer"
      className={cn(css.tooltipLink, {
        [css.inactive]: inactive,
      })}
      onClick={(event) => {
        event.stopPropagation();

        if (inactive) {
          event.preventDefault();
        }
        sendEvent({
          category: 'flow',
          action: 'tooltip click',
          label: id,
          propertyBag: {
            flowId,
          },
        });
      }}
    >
      {tooltipOptions?.html ? (
        <HtmlTextMemoized title={tooltipOptions?.html} />
      ) : (
        tooltipOptions?.text
      )}
    </a>
  );
};
