import { Viewport, Plugin } from 'pixi-viewport';
import { IPoint, Point } from 'pixi.js-legacy';

export interface GestureEvent extends WheelEvent {
  rotation: number;
  scale: number;
}

interface GestureEventMap extends HTMLElementEventMap {
  gesturestart: GestureEvent;
  gesturechange: GestureEvent;
  gestureend: GestureEvent;
}

interface ExtendedHTMLElement {
  addEventListener<K extends keyof GestureEventMap>(
    eventName: K,
    listener: (
      this: HTMLElement,
      ev: GestureEventMap[K],
      options?: boolean | AddEventListenerOptions,
    ) => any,
  ): void;
  removeEventListener<K extends keyof GestureEventMap>(
    eventName: K,
    listener: (
      this: HTMLElement,
      ev: GestureEventMap[K],
      options?: boolean | AddEventListenerOptions,
    ) => any,
  ): void;
}

interface Options {
  viewport: Viewport;
  listenerNode?: ExtendedHTMLElement;
}

export class GesturePinchPlugin extends Plugin {
  viewport: Viewport;
  listenerNode: ExtendedHTMLElement;
  initialScale: number;
  initialLocalPosition?: IPoint;

  constructor(options: Options) {
    super(options.viewport);
    this.viewport = options.viewport;
    this.listenerNode = options.listenerNode || document.body;

    this.listenerNode.addEventListener('gesturestart', this.onGestureStart);
    this.listenerNode.addEventListener('gesturechange', this.onGestureChange);
    this.listenerNode.addEventListener('gestureend', this.onGestureEnd);

    this.initialScale = this.viewport.scale.x;
  }

  public destroy(): void {
    this.listenerNode.removeEventListener('gesturestart', this.onGestureStart);
    this.listenerNode.removeEventListener(
      'gesturechange',
      this.onGestureChange,
    );
    this.listenerNode.removeEventListener('gestureend', this.onGestureEnd);
  }

  private onGestureStart = (event: GestureEvent): void => {
    this.initialScale = this.viewport.scale.x;
    const initialGlobalPosition = this.viewport.input.getPointerPosition(event);
    this.initialLocalPosition = this.viewport.toLocal(
      initialGlobalPosition,
    ) as any;
  };

  private onGestureEnd = (): void => {
    this.viewport.emit('zoomed', { viewport: this.viewport, type: 'pinch' });
  };

  private onGestureChange = (event: GestureEvent) => {
    if (!this.initialLocalPosition) {
      throw new Error('Missing initial position');
    }

    const newScale = event.scale * this.initialScale;
    this.viewport.setZoom(newScale);

    const globalPosition = this.viewport.input.getPointerPosition(event);
    const localPosition = this.viewport.toLocal(globalPosition);

    const deltaX = localPosition.x - this.initialLocalPosition.x;
    const deltaY = localPosition.y - this.initialLocalPosition.y;

    this.moveRelative(deltaX, deltaY);
    this.viewport.emit('moved', { viewport: this.viewport, type: 'pinch' });
  };

  private moveRelative(deltaX: number, deltaY: number) {
    this.viewport.moveCenter(
      new Point(
        this.viewport.center.x - deltaX,
        this.viewport.center.y - deltaY,
      ),
    );
  }
}
