import * as Types from '../types';
import { StatefulPlugin } from '../StatefulPlugin';

export class ReadonlyState<
  TPluginConfig,
> extends StatefulPlugin<TPluginConfig> {
  public data = this.plugin;

  public set(_: Types.PluginGetter<TPluginConfig>) {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public async save() {}

  public destroy() {}
}
