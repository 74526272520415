import React from 'react';
import { filterXSS } from 'xss';
import { OverlayProps, TextFieldOverlayOptions } from '../types';

export const NativeHtml: React.FC<OverlayProps> = ({ options }) => {
  const { initialValue } = options;
  const { style } = options as TextFieldOverlayOptions;

  const innerHtml = filterXSS(initialValue, {
    stripIgnoreTag: true,
    whiteList: { a: ['href', 'class', 'target', 'rel', 'style'] },
  });

  return (
    <div
      style={{
        pointerEvents: 'none',
        ...style,
      }}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: innerHtml }}
    />
  );
};
