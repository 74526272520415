import React from 'react';
import { Spacer } from '@ui/Spacer';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { PluginHeader } from '../../../common/PluginHeader';
import {
  FollowUps,
  InvalidInputMessage,
  AttributeInput,
  InputTimeout,
} from '../components';
import { QuickReplyPluginData } from '../types';

export const CollectNumbersView: React.FC<QuickReplyPluginData> = ({
  showResponseTimeout,
  ...props
}) => {
  const { t } = useSafeTranslation();

  return (
    <div>
      <PluginHeader
        title={t(
          'modernComponents.FlowBuilder.views.components.CollectUserData.answers.title',
        )}
      />

      <AttributeInput {...props} />

      {showResponseTimeout && (
        <>
          <Spacer factor={8} />
          <InputTimeout {...props} />
        </>
      )}

      <Spacer factor={4} />

      <InvalidInputMessage {...props} />

      <Spacer factor={6} />

      <FollowUps
        tooltipText={t(
          'modernComponents.FlowBuilder.views.components.CollectUserData.numbers.followUpsTooltip',
        )}
        {...props}
      />
    </div>
  );
};
