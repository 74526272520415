import { useSafeTranslation } from '@utils/useSafeTranslation';
import React, { useEffect, useMemo } from 'react';
import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';
import { SupportedModels, useTokenCount } from '../TokenCounter/useTokenCount';
import cn from 'classnames';
import {
  customAiPluginFragment_config_aiConditions,
  customAiPluginFragment_config_aiIntents,
} from '@components/Plugins/CustomAiPlugin/@types/customAiPluginFragment';
import { getPromptPreview } from '../../utils';
import css from './PluginTitle.css';
import { Tooltip2 } from '@ui/Tooltip2';
import { Icon } from '@ui/Icon';
import { Anchor } from '@ui/Links';
import { sendEvent } from 'cf-common/src/analytics';

interface PluginTitleProps {
  title: string;
  intents: customAiPluginFragment_config_aiIntents[];
  conditions: customAiPluginFragment_config_aiConditions[];
  maxTokens: number;
  model: SupportedModels;
  botId: string;
}

const OPEN_AI_ABOUT_TOKENS_LINK =
  'https://help.openai.com/en/articles/4936856-what-are-tokens-and-how-to-count-them';

export const PluginTitle: React.FC<PluginTitleProps> = ({
  title,
  intents,
  conditions,
  maxTokens,
  model,
  botId,
}) => {
  const { t } = useSafeTranslation();

  const text = useMemo(
    () => getPromptPreview({ intents, conditions }),
    [intents, conditions],
  );
  const { count, loading } = useTokenCount(text, model);

  const error = (() => {
    if (loading) return null;
    if (count > maxTokens)
      return t(
        'modernComponents.FlowBuilder.views.components.CustomAiPlugin.excitedTheTokenLimit',
      );
    return null;
  })();

  useEffect(() => {
    if (error) {
      sendEvent({
        category: 'ai plugin',
        label: 'custom ai',
        action: 'show tokens limit notification',
        propertyBag: {
          botId,
        },
      });
    }
  }, [error, botId]);

  return (
    <Flex flexDirection="column" data-testid="customai_plugin_title">
      {title}
      <Flex>
        <Type
          data-testid="customai_plugin_title-tokens_count"
          className={cn(css.tokens, loading && css.loading)}
          size="18px"
          weight="medium"
          color={error ? 'red' : 'greyDark'}
        >{`${count}/${maxTokens} ${t(
          'modernComponents.FlowBuilder.views.components.CustomAiPlugin.tokens',
          { count },
        )}`}</Type>
        <Tooltip2
          placement="right"
          boundariesElement="viewport"
          type="small"
          content={
            <>
              <Type size="12px" color="white">
                {t(
                  'modernComponents.FlowBuilder.views.components.CustomAiPlugin.editorPanelTooltip',
                )}
              </Type>{' '}
              <Anchor
                href={OPEN_AI_ABOUT_TOKENS_LINK}
                intent="external"
                target="_blank"
                size="small"
              >
                {t(
                  'modernComponents.FlowBuilder.views.components.CustomAiPlugin.learnMore',
                )}
              </Anchor>
            </>
          }
          positionFixed
          hoverable
        >
          {(ref, bind) => (
            <Icon
              data-testid="customai_plugin_title-tooltip"
              icon="help"
              color="blue"
              ref={ref}
              {...bind}
            />
          )}
        </Tooltip2>
      </Flex>
      {error && (
        <Type size="12px" color="red" weight="medium">
          {error}
        </Type>
      )}
    </Flex>
  );
};
