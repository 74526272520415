import { useState, useEffect } from 'react';
import { ServiceMessageType } from '@ui/ServiceMessage2';
import { toaster, Messages } from '@services/MessageService';
import { editorEmitter, EditorEvent } from '../events';
import { EditorEventPayload } from '../types';

const DEFAULT_STATE: EditorEventPayload = { data: null };

export const usePluginState = () => {
  const [plugin, setPlugin] = useState<EditorEventPayload>(DEFAULT_STATE);

  useEffect(
    () =>
      editorEmitter.on<EditorEventPayload>(
        EditorEvent.flowPluginUpdate,
        (payload) => {
          if (payload.error) {
            toaster.show({
              type: ServiceMessageType.error,
              payload: {
                message: Messages.somethingWentWrong,
              },
            });
          }
          setPlugin(payload);
        },
      ),
    [],
  );

  return {
    plugin,
    setPlugin,
    reset: () => setPlugin(DEFAULT_STATE),
  };
};
