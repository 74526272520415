import React from 'react';
import { Icon } from '@ui/Icon';
import { MAX_BUTTON_LENGTH } from '../../../../../../views/constants';
import { Type } from '@ui/Type';
import { InputWithPostfix } from '@ui/Input/InputWithPostfix';
import { getButtonNamePostfix } from '../../helpers/getButtonNamePostfix';
import { getPaymentButtonTitle } from '../../helpers/getPaymentButtonTitle';
import { ButtonPopupOverlayConfig as CardButton } from '../../../../../../FlowBuilderOverlay/overlays';
import { useSafeTranslation } from '@utils/useSafeTranslation';

interface ButtonTitleProps {
  htmlId: string;
  config: CardButton;
  onSaveRequest: VoidFunction;
  onChange: (value: string) => void;
}

export const ButtonTitle: React.FC<ButtonTitleProps> = ({
  config,
  htmlId,
  onSaveRequest,
  onChange,
}) => {
  const { t } = useSafeTranslation();

  const buttonNamePostfix = getButtonNamePostfix(config);
  const hasButtonTitleError =
    getPaymentButtonTitle(config, buttonNamePostfix).length +
      (config.title.length === 0
        ? t('InBotPayments.Panel.PriceList.buttonTitle').length + 1
        : 0) >
    MAX_BUTTON_LENGTH;

  return (
    <InputWithPostfix
      error={hasButtonTitleError}
      value={config?.title}
      placeholder={t('InBotPayments.Panel.PriceList.buttonTitle')}
      renderIcon={
        hasButtonTitleError
          ? () => <Icon icon="circleWarning" color="accent4Normal" />
          : undefined
      }
      renderErrorText={
        hasButtonTitleError
          ? () =>
              t('InBotPayments.Panel.PriceList.buttonTitleError', {
                maxLength: MAX_BUTTON_LENGTH,
              })
          : undefined
      }
      renderPostfix={({ error }) => (
        <Type size="15px" color={error ? 'accent4Normal' : 'baseTertiary'}>
          {buttonNamePostfix}
        </Type>
      )}
      onChange={({ currentTarget: { value } }) => onChange(value)}
      onBlur={onSaveRequest}
      id={htmlId}
    />
  );
};
