import React from 'react';
import { Icon, IconProps } from '@ui/Icon';
import { ButtonUnstyled } from '@ui/Button';
import * as css from './ActionButton.css';

interface ActionButtonProps {
  icon: IconProps['icon'];
  onClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
}

export const ActionButton = React.forwardRef<
  HTMLButtonElement,
  ActionButtonProps
>(({ icon, onClick }, ref) => (
  <ButtonUnstyled className={css.actionButton} ref={ref} onClick={onClick}>
    <Icon icon={icon} />
  </ButtonUnstyled>
));
