import { EditorEventPayload } from './types';
import { Card } from '../types';
import { EventEmitter } from '../../../utils/EventEmitter';
import { StatefulPlugin } from '../StatefulPlugin';

export enum EditorEvent {
  flowPluginUpdate = 'flow:plugin:update',
  flowSettings = 'flow:settings',
}

export const editorEmitter = new EventEmitter();

export const editPluginInEditorPanel = <T>(
  blockId: string,
  plugin: Card<T>,
  state?: StatefulPlugin<any>,
  blockSubtype?: string,
) => {
  editorEmitter.emit<EditorEventPayload>(EditorEvent.flowPluginUpdate, {
    data: {
      blockId,
      blockSubtype,
      pluginId: plugin.id,
      pluginType: plugin.plugin_id,
      position: plugin.position ?? null,
      state,
    },
  });
};
