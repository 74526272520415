import React from 'react';
import { useCurrentBotId } from '@utils/Routing';
import { useLocalFollowUps } from '../hooks/useLocalFollowUps';
import { useAttributes } from '@utils/AttributesUtils/AttributesData';
import { VariableSuggestType } from '@utils/AttributesUtils/AttributesUtilsTypes';
import { getFlowPlatform } from '../../../../../utils/getFlowPlatform';
import { Platform } from '@globals';
import { CenteredLoader } from '@ui/Loader';
import { FollowUpsList } from '../FollowUpsList';
import { PluginDataChildrenParams } from '../../../../../../Plugins/common';
import { QuickReplyPluginConfig } from '../../../../../../Plugins/QuickReplyPlugin/QuickReplyPluginConst';

interface LocalFollowUpsProps
  extends PluginDataChildrenParams<QuickReplyPluginConfig> {}

export const LocalFollowUps: React.FC<LocalFollowUpsProps> = (params) => {
  const botId = useCurrentBotId()!;
  const {
    hasPluginLocalFollowUps,
    setupLocalFollowUps,
    ...localFollowUpsListParams
  } = useLocalFollowUps(params);
  const { attributes, loading: attributesLoading } = useAttributes(
    botId,
    VariableSuggestType.template,
    getFlowPlatform() || Platform.facebook,
  );

  if (attributesLoading) {
    return <CenteredLoader />;
  }

  if (!hasPluginLocalFollowUps) {
    return null;
  }

  return (
    <FollowUpsList textAttributes={attributes} {...localFollowUpsListParams} />
  );
};
