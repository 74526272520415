import React from 'react';
import { CenteredLoader } from '@ui/Loader';
import { FollowUpsEmpty } from './components/FollowUpsEmpty';
import { FollowUpsType } from './consts';
import { PluginDataChildrenParams } from '../../../../../Plugins/common';
import { QuickReplyPluginConfig } from '../../../../../Plugins/QuickReplyPlugin/QuickReplyPluginConst';
import { useGlobalFollowUps } from './hooks/useGlobalFollowUps';
import { useLocalFollowUps } from './hooks/useLocalFollowUps';
import { GlobalFollowUps } from './components/GlobalFollowUps';
import { LocalFollowUps } from './components/LocalFollowUps';

export const FollowUpsContainer = React.memo<
  PluginDataChildrenParams<QuickReplyPluginConfig>
>((params) => {
  const { hasGlobalFollowUps, globalFollowUpsLoading, setupGlobalFollowUps } =
    useGlobalFollowUps();
  const { hasPluginLocalFollowUps, setupLocalFollowUps } =
    useLocalFollowUps(params);

  if (globalFollowUpsLoading) {
    return <CenteredLoader />;
  }

  return !hasGlobalFollowUps && !hasPluginLocalFollowUps ? (
    <FollowUpsEmpty
      onButtonClick={(type) => {
        if (type === FollowUpsType.global) {
          setupGlobalFollowUps();
          window.ym?.(38620685, 'reachGoal', 'global_followup_on');
        }
        if (type === FollowUpsType.local) {
          setupLocalFollowUps();
          window.ym?.(38620685, 'reachGoal', 'local_followup_on');
        }
      }}
    />
  ) : (
    <>
      {hasGlobalFollowUps && <GlobalFollowUps />}
      {!hasGlobalFollowUps && hasPluginLocalFollowUps && (
        <LocalFollowUps {...params} />
      )}
    </>
  );
});
