import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';
import { Spacer } from '@ui/Spacer';

import MouseMovePng from './pictures/MouseMove.png';
import MouseZoomPng from './pictures/MouseZoom.png';
import MouseZoomWithTrackpadPng from './pictures/MouseZoomWithTrackpad.png';
import TrackpadMovePng from './pictures/TrackpadMove.png';
import TrackpadMoveWithMousePng from './pictures/TrackpadMoveWithMouse.png';
import TrackpadPinchPng from './pictures/TrackpadPinch.png';
import TrackpadZoomWithMousePng from './pictures/TrackpadZoomWithMouse.png';

interface InfoCardProps {
  picture: string;
  description: string;
  maxWidth: string;
}

export const InfoCard: React.FC<InfoCardProps> = ({
  picture,
  description,
  maxWidth,
}) => (
  <Flex alignItems="center" flexDirection="column" style={{ width: maxWidth }}>
    <img src={picture} alt={description} height="66px" />
    <Spacer factor={2} />
    <Type size="15px" align="center">
      {description}
    </Type>
  </Flex>
);

export const TrackpadInfo: React.FC = () => {
  const { t } = useSafeTranslation();
  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      style={{ height: '300px' }}
    >
      <Flex justifyContent="center">
        <InfoCard
          picture={TrackpadMovePng}
          description={t(
            'modernComponents.FlowBuilder.modals.GesturesModal.trackpadMovePng',
          )}
          maxWidth="272px"
        />
        <Spacer horizontalFactor={10} />
        <InfoCard
          picture={TrackpadMoveWithMousePng}
          description={t(
            'modernComponents.FlowBuilder.modals.GesturesModal.trackpadMoveWithMousePng',
          )}
          maxWidth="272px"
        />
      </Flex>
      <Spacer factor={6} />
      <Flex justifyContent="center">
        <InfoCard
          picture={TrackpadPinchPng}
          description={t(
            'modernComponents.FlowBuilder.modals.GesturesModal.trackpadPinchPng',
          )}
          maxWidth="272px"
        />
        <Spacer horizontalFactor={10} />
        <InfoCard
          picture={TrackpadZoomWithMousePng}
          description={t(
            'modernComponents.FlowBuilder.modals.GesturesModal.trackpadZoomWithMousePng',
          )}
          maxWidth="272px"
        />
      </Flex>
    </Flex>
  );
};

export const MouseInfo: React.FC = () => {
  const { t } = useSafeTranslation();
  return (
    <Flex alignItems="center" style={{ height: '300px' }}>
      <InfoCard
        picture={MouseMovePng}
        description={t(
          'modernComponents.FlowBuilder.modals.GesturesModal.mouseMove',
        )}
        maxWidth="150px"
      />
      <Spacer horizontalFactor={10} />
      <InfoCard
        picture={MouseZoomPng}
        description={t(
          'modernComponents.FlowBuilder.modals.GesturesModal.mouseZoom',
        )}
        maxWidth="150px"
      />
      <Spacer horizontalFactor={10} />
      <InfoCard
        picture={MouseZoomWithTrackpadPng}
        description={t(
          'modernComponents.FlowBuilder.modals.GesturesModal.mouseZoomWithTrackpad',
        )}
        maxWidth="150px"
      />
    </Flex>
  );
};
