import React, { useRef } from 'react';
import { MenuItem, MenuItemProps } from '@ui/Menu';
import { Tooltip2 } from '@ui/Tooltip2';
import css from './NTimeNotificationPlugin.css';

const MENU_ITEM_WIDTH = 320;

export function PluginMenuItem({
  tooltip,
  tooltipBoundariesElement,
  tooltipType,
  placement,
  title,
  ...props
}: Omit<
  MenuItemProps,
  'innerRef' | 'onMouseEnter' | 'onMouseLeave' | 'titleElement'
>) {
  const titleRef = useRef<HTMLDivElement>(null);

  return (
    <Tooltip2
      content={tooltip}
      boundariesElement={tooltipBoundariesElement}
      type={tooltipType}
      placement={placement || 'right'}
    >
      {(ref, { onMouseEnter, onMouseLeave }) => (
        <MenuItem
          {...props}
          innerRef={ref}
          title={title}
          titleElement={
            <div className={css.menuItemTitle} ref={titleRef}>
              {title}
            </div>
          }
          onMouseEnter={() => {
            const scrollWidth = titleRef.current?.scrollWidth || 0;
            if (!props.leftElement && scrollWidth > MENU_ITEM_WIDTH)
              onMouseEnter();
          }}
          onMouseLeave={onMouseLeave}
        />
      )}
    </Tooltip2>
  );
}
