import { useMemo } from 'react';
import { useAttributes } from '@utils/AttributesUtils/AttributesData';
import { VariableSuggestType } from '@utils/AttributesUtils/AttributesUtilsTypes';
import { containsAll } from '@utils/ArrayUtils';
import { Platform } from '@globals';

export const useGoogleSheetAttributes = (
  botId: string,
  platform: Platform,
  userAttributes: string[],
) => {
  const { attributes, loading: attributesLoading } = useAttributes(
    botId,
    VariableSuggestType.template,
    platform,
  );

  const allAttributesChecked = useMemo(() => {
    return containsAll(
      userAttributes,
      attributes.map(({ name }) => name),
    );
  }, [userAttributes, attributes]);

  return {
    attributes,
    attributesLoading,
    allAttributesChecked,
  };
};
