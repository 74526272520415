import { WithoutGQLTypename } from '@utils/GQL/utils';
import {
  FlowStatsQuery_flowStats,
  FlowStatsQuery_flowStats_blocks_counters,
} from '../../../../queries/@types/FlowStatsQuery';
import { quickReplyPluginFragment_config_follow_ups } from '../../../../../Plugins/QuickReplyPlugin/@types/quickReplyPluginFragment';
import { FOLLOW_UP_TEMPLATE1_DELAY, FOLLOW_UP_TEMPLATE2_DELAY } from './consts';
import i18next from 'i18next';
import Maybe from 'graphql/tsutils/Maybe';
import { FollowUpItem } from './FollowUpsList';
import { mongoObjectId } from 'cf-common/src/utils/mongo/mongoObjectId';

export const getStatsPercentage = (total: number, value: number): string => {
  if (total === 0) {
    return '0';
  }
  const roundedPersent = Math.round((100 * value) / total);
  return `${roundedPersent}%`;
};

interface Stats {
  sent: number;
  seen: number;
  clicked: number;
}
// followUpId to Stats
type FollowUpsCalculatedStats = Map<string, Stats>;

const REGEXP_FOR_PLUGIN_STATS = /follow_up_([0-9a-zA-Z_]+?)$/;
const CARD_SIGN = '_card_';

export const calculateFollowUpsStats = (
  counters: WithoutGQLTypename<FlowStatsQuery_flowStats_blocks_counters>[],
): FollowUpsCalculatedStats => {
  const result = new Map<string, Stats>();
  counters.forEach((couner) => {
    if (couner.id.includes(CARD_SIGN)) {
      return;
    }
    const match = couner.id.match(REGEXP_FOR_PLUGIN_STATS);
    const id = match?.[1];
    if (id) {
      result.set(id, {
        sent: couner.stats.sent,
        seen: couner.stats.seen ?? 0,
        clicked: couner.stats.clicked,
      });
    }
  });
  return result;
};

export const createFollowUp = (text: string, delayMs: number) =>
  ({
    __typename: 'FlowFollowUp',
    id: mongoObjectId(),
    text,
    delay_ms: delayMs,
  } as quickReplyPluginFragment_config_follow_ups);

export const createLocalFollowUps = (isWhatsappEnabled: boolean) => [
  createFollowUp(
    isWhatsappEnabled
      ? i18next.t(
          'modernComponents.FlowBuilder.EditorPanel.settings.templateFirstWhatsapp',
        )
      : i18next.t(
          'modernComponents.FlowBuilder.EditorPanel.settings.templateFirst',
        ),
    FOLLOW_UP_TEMPLATE1_DELAY,
  ),
  createFollowUp(
    isWhatsappEnabled
      ? i18next.t(
          'modernComponents.FlowBuilder.EditorPanel.settings.templateSecondWhatsapp',
        )
      : i18next.t(
          'modernComponents.FlowBuilder.EditorPanel.settings.templateSecond',
        ),
    FOLLOW_UP_TEMPLATE2_DELAY,
  ),
];

export const prepareFollowUpsItems = (
  flowStats: Maybe<FlowStatsQuery_flowStats>,
  followUps: quickReplyPluginFragment_config_follow_ups[],
) => {
  const statsData = flowStats?.blocks.flatMap(({ counters }) => counters) ?? [];
  const followUpsStats = calculateFollowUpsStats(statsData);
  const followUpsItems: FollowUpItem[] = followUps.map((followUp) => {
    const stats = followUpsStats.get(followUp.id) ?? {
      sent: 0,
      seen: 0,
      clicked: 0,
    };
    return {
      id: followUp.id,
      delay: followUp.delay_ms,
      text: followUp.text,
      statSent: stats.sent.toString(),
      statSeen: getStatsPercentage(stats.sent, stats.seen),
      statClicked: getStatsPercentage(stats.sent, stats.clicked),
    };
  });
  return followUpsItems;
};
