import React from 'react';
import { NAV_PANEL_COLLAPSE_BUTTON_ID } from '@pages/BotPage/FlowTab/FlowList/constants';
import { sendEvent } from '@utils/Analytics';
import { ReactComponent as CollapseIcon } from '@ui/_deprecated/Icon/icons/collapse.svg';
import { ButtonUnstyled } from '@ui/Button';
import * as css from './CollapseButton.css';
import { useCurrentBotId } from '@utils/Routing';
import cn from 'classnames';

interface CollapseButtonProps {
  active: boolean;
  onClick: VoidFunction;
  className?: string;
}

export const CollapseButton: React.FC<CollapseButtonProps> = ({
  onClick,
  active,
  className,
}) => {
  const botId = useCurrentBotId();
  return (
    <ButtonUnstyled
      id={NAV_PANEL_COLLAPSE_BUTTON_ID}
      className={cn(css.collapseButton, className)}
      data-testid="collapse-button"
      onClick={() => {
        sendEvent({
          category: 'navigation',
          action: 'collapsable panel view change',
          label: active ? 'show' : 'hide',
          propertyBag: { botId },
        });
        const resizeEvent = new Event('resize');
        window.dispatchEvent(resizeEvent);
        onClick();
      }}
    >
      <CollapseIcon
        style={{
          position: 'absolute',
          transform: `rotate(${active ? '0deg' : '-180deg'})`,
        }}
      />
    </ButtonUnstyled>
  );
};
