import React, { useMemo } from 'react';
import Maybe from 'graphql/tsutils/Maybe';
import groupBy from 'lodash-es/groupBy';
import { Icon } from '@ui/Icon';
import { Button } from '@ui/Button';
import { Spacer } from '@ui/Spacer';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { jsonPluginFragment_config_custom_response_config as JsonPathToAttributeConfig } from '@components/Plugins/JsonPlugin/@types/jsonPluginFragment';
import { Platform } from '@globals';
import { JsonPathToAttribute } from '../JsonPathToAttribute';
import { AttributesQuery_bot_variableSuggest as Attribute } from '@utils/AttributesUtils/@types/AttributesQuery';
import { useOneTimeState } from 'cf-common/src/utils/hooks';

const MAX_ATTRIBUTES_TO_SET = 40;
const EMPTY_PATH_TO_ATTRIBUTES: JsonPathToAttributeConfig[] = [];

interface AttributesMappingProps {
  pathToAttributes: Maybe<JsonPathToAttributeConfig[]>;
  platform: Platform;
  botAttributes: Attribute[];
  onAdd(): void;
  onDeleteAt(index: number): void;
  onChangeAttributeAt(index: number, nextAttributeName: string): void;
  onChangeJsonPathAt(index: number, nextJsonPath: string): void;
  onBlur(): void;
}

export const AttributesMapping: React.FC<AttributesMappingProps> = ({
  pathToAttributes,
  platform,
  botAttributes,
  onAdd,
  onDeleteAt,
  onChangeAttributeAt,
  onChangeJsonPathAt,
  onBlur,
}) => {
  const { t } = useSafeTranslation();
  const [isJustAdded, setIsJustAdded] = useOneTimeState(false);
  const justPathToAttributes = pathToAttributes ?? EMPTY_PATH_TO_ATTRIBUTES;

  const duplicatedAttributeNames = useMemo(() => {
    const groupedAttributes = groupBy(justPathToAttributes, 'attr');
    return Object.keys(groupedAttributes).filter(
      (attribute) => groupedAttributes[attribute].length > 1,
    );
  }, [justPathToAttributes]);

  const uniqueAttributes = useMemo(() => {
    return botAttributes.filter(
      ({ name }) => !justPathToAttributes.some(({ attr }) => attr === name),
    );
  }, [justPathToAttributes, botAttributes]);

  return (
    <div data-testid="json-path-to-attr_container">
      {justPathToAttributes.map(({ attr, path }, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <React.Fragment key={index}>
          <JsonPathToAttribute
            attr={attr}
            path={path}
            index={index}
            platform={platform}
            autoFocus={isJustAdded && index === justPathToAttributes.length - 1}
            botAttributes={botAttributes}
            uniqueAttributes={uniqueAttributes}
            duplicatedAttributeNames={duplicatedAttributeNames}
            onDeleteAt={onDeleteAt}
            onChangeAttributeAt={onChangeAttributeAt}
            onChangeJsonPathAt={onChangeJsonPathAt}
            onBlur={onBlur}
          />
          <Spacer factor={3} />
        </React.Fragment>
      ))}
      <Button
        intent="secondary"
        icon={<Icon icon="plus" />}
        data-testid="json-path-to-attr_add"
        onClick={() => {
          onAdd();
          setIsJustAdded(true);
        }}
        disabled={justPathToAttributes.length >= MAX_ATTRIBUTES_TO_SET}
      >
        {t('modernComponents.FlowBuilder.plugins.JSONPlugin.addAttribute')}
      </Button>
    </div>
  );
};
