import React from 'react';
import { Editor } from 'slate-react';
import { sendEvent } from '@utils/Analytics';
import { TimeIntervalsIds, TimeoutInputByTimestamp } from '@ui/TimeoutInput';
import { Input } from '@ui/Input';
import { Button } from '@ui/Button';
import { Icon } from '@ui/Icon';
import {
  deserialize,
  Attributes,
  TextWithAttributesEditor,
  EditorModeEnum,
  serialize,
} from '@ui/TextWithAttributesEditor';
import { TextOutsideControls } from '@ui/TextOutsideControls';
import { insertText } from '@utils/Slite/insertText';

import * as css from './FollowUp.css';
import i18next from 'i18next';
import { LS, LSKey } from 'cf-common/src/localStorage';

interface FollowUpProps {
  delay: number;
  text: string;
  textAttributes: Attributes;
  statSent: string;
  statSeen: string;
  statClicked: string;
  onTimeChange(quantValue: number): void;
  onTextChange(text: string): void;
  onRemove(): void;
  updateFollowUps(): void;
}

const ALLOWED_TIME_INTERVALS_IDS = LS.get(LSKey.followUpTestTimeouts)
  ? [TimeIntervalsIds.seconds, TimeIntervalsIds.minutes, TimeIntervalsIds.hours]
  : [TimeIntervalsIds.minutes, TimeIntervalsIds.hours];

const MAX_TEXT_LENGTH = 500;

export const FollowUp: React.FC<FollowUpProps> = ({
  delay,
  text,
  textAttributes,
  statSent,
  statSeen,
  statClicked,
  onTimeChange,
  onTextChange,
  onRemove,
  updateFollowUps,
}) => (
  <div className={css.body} data-testid="follow-ups__list">
    <div className={css.statsContainer}>
      <div className={css.statBlock}>
        <div className={css.statTitle}>
          {i18next.t('FollowUp-JSXText-2573-sent')}
        </div>
        <div data-testid="follow-up__stat-sent" className={css.statValue}>
          {statSent}
        </div>
      </div>
      <div className={css.statBlock}>
        <div className={css.statTitle}>
          {i18next.t('FollowUp-JSXText-2572-seen')}
        </div>
        <div data-testid="follow-up__stat-seen" className={css.statValue}>
          {statSeen}
        </div>
      </div>
      <div className={css.statBlock}>
        <div className={css.statTitle}>
          {i18next.t('FollowUp-JSXText--242-returned')}
        </div>
        <div data-testid="follow-up__stat-returned" className={css.statValue}>
          {statClicked}
        </div>
      </div>
    </div>
    <div className={css.settingsContainer}>
      <div className={css.settingsBlock}>
        <div className={css.settingsTime}>
          <span>{i18next.t('FollowUp-JSXText--536-follow-up-after')}</span>
          <TimeoutInputByTimestamp
            data-testid="follow-up__time-input"
            value={delay}
            allowedTimeIntervalsIds={ALLOWED_TIME_INTERVALS_IDS}
            onChange={onTimeChange}
            onBlur={() => {
              updateFollowUps();
              sendEvent({
                category: 'flows',
                action: 'follow up time changed',
              });
            }}
          />
        </div>
        <div>
          <Input
            render={() => (
              <TextOutsideControls
                initialShow={false}
                onInsertRequest={(val, el) => {
                  insertText(val, el as Editor);
                }}
                currentTextLimit={Math.max(0, MAX_TEXT_LENGTH - text.length)}
                shouldShowOutsideControls={{
                  emoji: true,
                  attributes: true,
                  symbolsLimit: true,
                }}
              >
                {({ ref, getInputProps }) => (
                  <TextWithAttributesEditor
                    className={css.textEditor}
                    editorRef={ref}
                    defaultValue={deserialize(text ?? '')}
                    maxLength={MAX_TEXT_LENGTH}
                    onBlur={(event) => {
                      updateFollowUps();
                      sendEvent({
                        category: 'flows',
                        action: 'follow up text change',
                      });
                      getInputProps().onBlur(event);
                    }}
                    onFocus={getInputProps().onFocus}
                    onChange={(v) => {
                      onTextChange(serialize(v));
                    }}
                    placeholder={i18next.t(
                      'FollowUp-string--124-write-something-or-leave-this-field-empty-and-well-just-resend-the-question',
                    )}
                    multiLinePlaceholder
                    attributes={textAttributes}
                    fixedEditorMode={EditorModeEnum.edit}
                    shouldMoveCursorToEndOnFocus
                    hasManageAttributes
                  />
                )}
              </TextOutsideControls>
            )}
          />
        </div>
      </div>
      <Button
        data-testid="follow-up__stat-delete-button"
        className={css.removeButton}
        intent="secondary"
        icon={<Icon icon="delete" />}
        size="m"
        onClick={onRemove}
      />
    </div>
  </div>
);
