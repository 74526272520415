import React from 'react';
import { Spacer } from '@ui/Spacer';
import { ValidationErrorFunc } from '@ui/BubbleEditor';
import {
  DomainsControl,
  DomainsConfig,
  PagesWhitelistControl,
  PagesWhitelistConfig,
  PagesBlackListControl,
  PagesBlackListConfig,
} from '../controls';
import { PluginType } from '../../../../../../../Plugins/common/PluginTypes';
import { ControlProps } from '../types';

interface SiteSettingsConfig
  extends DomainsConfig,
    PagesWhitelistConfig,
    PagesBlackListConfig {}

interface SiteSettingsSectionProps<T>
  extends ControlProps<T>,
    ValidationErrorFunc {}

export function SiteSettingsSection<T extends SiteSettingsConfig>({
  pluginConfig,
  pluginType,
  state,
  validationErrorFunc,
}: SiteSettingsSectionProps<T>) {
  return (
    <>
      {pluginType === PluginType.customer_chat_entry_point && (
        <>
          <DomainsControl
            state={state}
            pluginConfig={pluginConfig}
            pluginType={pluginType}
          />

          <Spacer factor={6} />
        </>
      )}

      <PagesWhitelistControl
        state={state}
        pluginConfig={pluginConfig}
        validationErrorFunc={validationErrorFunc}
        pluginType={pluginType}
      />

      <Spacer factor={6} />

      <PagesBlackListControl
        state={state}
        pluginConfig={pluginConfig}
        validationErrorFunc={validationErrorFunc}
        pluginType={pluginType}
      />
    </>
  );
}
