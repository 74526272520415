import gql from 'graphql-tag';

export const CREATE_N_TIME_NOTIFICATION_TOPICS_MUTATION = gql`
  mutation CreateNTimeNotificationTopicsMutation(
    $pageId: String
    $name: String
    $frequency: Frequency
  ) {
    createNTimeNotificationTopics(
      pageId: $pageId
      name: $name
      frequency: $frequency
    ) {
      id
      page_id
      name
      frequency
    }
  }
`;
