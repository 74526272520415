import React from 'react';
import { Callout } from '@ui/Callout';
import { Spacer } from '@ui/Spacer';
import css from './InfoCallout.css';
import { ServerStorageItemKeys, useServerStorage } from '@utils/ServerStorage';

export const InfoCallout = ({
  storageKey,
  infoText,
}: {
  storageKey: ServerStorageItemKeys;
  infoText: string;
}) => {
  const [isHidden, setHidden] = useServerStorage(storageKey);

  if (isHidden) {
    return null;
  }

  return (
    <>
      <Spacer factor={6} />
      <Callout
        closeButtonClassName={css.button}
        iconDisabled
        text={infoText}
        type="info"
        onClose={() => setHidden(true)}
      />
    </>
  );
};
