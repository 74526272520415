import { Graphics, Sprite } from 'pixi.js-legacy';
import i18next from 'i18next';
import noop from 'lodash-es/noop';
import { BotStatus } from '@utils/Bot';
import { EventEmitter } from '@utils/EventEmitter';
import { toaster } from '@services/MessageService';
import { ServiceMessageType } from '@ui/ServiceMessage2';
import { HEXColors } from '@ui/_common/colors';
import { removeTypename } from '@utils/GQL/utils';
import { log } from 'cf-common/src/logger';
import { IS_PRODUCTION_BUILD } from 'cf-common/src/environment';
import { LS, LSKey } from 'cf-common/src/localStorage';
import { Card, FlowBlock, FlowData, VerifiedPermissions } from '../types';
import type { PixiField } from '../PixiField';
import {
  createFlowBlock,
  getBlocks,
  removeBlock,
  updateRootBlock,
} from '../api/network';
import {
  getAdditionalPropertyBagField,
  logFlowEvent,
} from '../utils/Analytics';
import { Node } from '../Node';
import { FlowBuilderEvent, flowBuilderEventEmitter } from '../views/events';
import { blockControl, hideControls } from '../views/helpers/ControlsHelpers';
import { nonEmptyArray } from '../views/validation';
import {
  createCustomPlugin,
  createDefaultPlugin,
  getMessageTagForContentBlock,
  getOtnPurposeForContentBlock,
} from '../views/utils';
import {
  fitBlockPartInScreen,
  fitScreen,
  organizeBlocks,
  organizeBlocksToTargetPositions,
} from '../utils/organizeBlocks';
import { BLOCK_SUBTYPES, isRootBlock } from '../consts';
import { getRidOfHiddenDeprecatedBlocks, getUpdatedFlowData } from './helpers';
import { PluginType } from '../../Plugins/common/PluginTypes';
import { preparePersistentMenuFromConfigureTab } from '../views/entry-points/EntryPointPersistentMenu/utils';
import { getEntryPointCard } from '../views/entry-points/common/utils';
import { FlowBuilderImageLoader } from '../views/entry-points/common/utils/FlowBuilderImageLoader';
import { BlockLoadingView } from '../views/block_loading_view';
import {
  EntryPointEventTrigger,
  entryPointsEmitter,
  EntryPointsEvents,
} from '../views/entry-points/events';
import {
  refetchFlowBlock,
  refetchFlowBlockAndUpdateInFlowBlocksCache,
} from '../api/refetchFlowBlock';
import {
  getNodesWithNoInLinks,
  isSameNotEmptyPosition,
  NodePosition,
} from '../utils/organizeBlocks/helpers';
import { Point } from '../components/Elements/Shapes';
import { getBlockTitle, WithPluginId } from '../views/helpers/getBlockTitle';
import { updateFlowBlocksCacheAfterDeleting } from '../api/refetchFlowBlock/refetchFlowBlock';
import { sendFPSMetrics } from '../utils/fpsMetrics';
import {
  FlowBuilderOverlayEvent,
  overlayEventEmitter,
} from '../FlowBuilderOverlay';
import { AggregatedFlowQuery_bot_archiveBlocks as BlockTitle } from '@utils/Data/Flow/Aggregated/@types/AggregatedFlowQuery';
import { BlockContextType } from '@globals';
import { unlockEntryPointErrorTooltip } from '../utils/useActivateEntryPoint';
import { omit } from 'ramda';

const FOCUSED_BLOCK_SELECTION_TIMEOUT = 2000;

const FlowControllerNodesCreatedEvent = 'FlowControllerNodesCreatedEvent';

interface CreateBlockArgs {
  position: Point;
  subtype: string;
  contextType?: BlockContextType;
  extraPluginId?: string;
  context: any;
  onCreated: (newNode: Node) => void;
  onError: (dataResult: any, data: any) => void;
  cards?: Partial<Card>[];
}

export class FlowController {
  _blocksTitlesMap: Record<string, BlockTitle> = {};
  _groupsMap: Record<string, any> = {};
  /**
   * Используйте GraphQL для получения/удаления/добавления блоков
   *
   * @deprecated
   */
  _nodesMap: Record<string, Node> = {};
  _nodeToEdit?: Node | null = null;
  _oldPositions: NodePosition[] | null = null;
  _pixiField: PixiField;
  _segmentsMap: Record<string, any> = {};

  _eventEmitter = new EventEmitter();

  // @ts-ignore
  flow: FlowData;
  flowLoader: () => Promise<FlowData>;
  imageLoader: FlowBuilderImageLoader;

  activeBlocksIds: string[] = [];
  focusedBlockId?: string;
  updateFlowPositionInterval?: ReturnType<typeof setInterval>;

  viewportDragging = false;
  cancelDrawing?: () => void;

  constructor(pixiField: PixiField, flowLoader: () => Promise<FlowData>) {
    this._pixiField = pixiField;
    this.imageLoader = new FlowBuilderImageLoader();
    this.flowLoader = flowLoader;
    const { viewport } = pixiField;

    viewport.on('drag-start', () => {
      this.viewportDragging = true;
    });
    viewport.on('drag-end', () => {
      setTimeout(() => {
        this.viewportDragging = false;
      });
    });
    viewport.on('pointerdown', () => {
      unlockEntryPointErrorTooltip();
    });
    viewport.on('click', () => {
      // we need to blur focus from outside elements
      try {
        (document.activeElement as HTMLElement)?.blur?.();
      } catch (error) {
        log.warn({ error, msg: 'Flow Builder: click on viewport error' });
      }

      if (!this.viewportDragging) {
        this.allNodes().forEach((node) => {
          node.blockView._cardsLayout.views().forEach((view) => {
            try {
              // @ts-ignore
              const card: any = view._card;
              if (card?.isEditing) {
                card.isEditing = false;
                if (card.isFirstInteraction) {
                  card.isFirstInteraction = false;
                }
                // @ts-ignore
                view?.renderNode();
              }
            } catch (error) {
              log.warn({
                error,
                msg: 'Flow Builder: error while access block card',
              });
            }
          });
        });
        this.unsetActiveBlocksIds();
        hideControls();
      }
    });

    this._pixiField.setOnStopTicker(() => this.sendAnimationMetrics());
  }

  sendAnimationMetrics() {
    // calculate metrics asynchronously
    setTimeout(() => {
      const { id: flowId } = this.flow;
      const blocks = Object.values(this._nodesMap);
      const cardsCount = blocks.reduce(
        (acc, { block }) => acc + block.cards.length,
        0,
      );
      sendFPSMetrics({ flowId, blocksCount: blocks.length, cardsCount });
    });
  }

  createNode(block: FlowBlock) {
    const { viewport } = this._pixiField;
    const node = new Node(block, this);
    // @ts-ignore
    node.startingPoint =
      nonEmptyArray(this.flow.root.next_block_ids) &&
      node.id === this.flow.root.next_block_ids?.[0];
    viewport.addChild(node.blockView as any);
    this._nodesMap[block.id] = node;
    return node;
  }

  async asyncRenderNodes(isCanceled: () => boolean): Promise<void> {
    const blockConstructors = this.flow.blocks.map((block) => {
      return new Promise<void>((resolve, reject) => {
        setTimeout(() => {
          if (isCanceled()) {
            resolve();
            return;
          }
          try {
            this.createNode(block);
            resolve();
          } catch (e) {
            reject(e);
          }
        });
      });
    });

    await Promise.all(blockConstructors).catch((e) => {
      throw e;
    });

    const blockRenderers = this.allNodes().map((node) => {
      return new Promise<void>((resolve, reject) => {
        setTimeout(() => {
          if (isCanceled()) {
            resolve();
            return;
          }
          try {
            node.renderNode();
            resolve();
          } catch (e) {
            reject(e);
          }
        });
      });
    });

    await Promise.all(blockRenderers).catch((e) => {
      throw e;
    });
  }

  renderNodes() {
    this.flow.blocks.forEach((block) => {
      this.createNode(block);
    });

    this.allNodes().forEach((node) => {
      try {
        node.renderNode();
      } catch (e) {
        throw e;
      }
    });
  }

  async fetchFlow(): Promise<void> {
    this.flow = getRidOfHiddenDeprecatedBlocks(await this.flowLoader());
  }

  async drawFlow(scale?: number): Promise<void> {
    if (this.cancelDrawing) {
      if (IS_PRODUCTION_BUILD) {
        log.warn({
          msg: `Flow(id=${this.flow.id}) tried to redraw without destroy call`,
        });
      } else {
        throw new Error(
          `Flow(id=${this.flow.id}) tried to redraw without destroy call`,
        );
      }
      return;
    }
    let canceled = false;
    this.cancelDrawing = () => {
      canceled = true;
      this.cancelDrawing = undefined;
    };

    if (Array.isArray(this.flow.blocksTitles)) {
      this.flow.blocksTitles.forEach((bl) => {
        this._blocksTitlesMap[bl.id] = bl;
      });
    }
    if (Array.isArray(this.flow.segments)) {
      this.flow.segments.forEach((s) => {
        this._segmentsMap[s!.id] = s;
      });
    }
    if (Array.isArray(this.flow.groups)) {
      this.flow.groups.forEach((g) => {
        this._groupsMap[g.id] = g;
      });
    }

    const viewportLocalInfo = LS.getRaw(`${this.flow.id}_viewport`);
    if (viewportLocalInfo) {
      const data = JSON.parse(viewportLocalInfo);
      // @ts-ignore
      this._pixiField.viewport.scale = scale
        ? { x: scale, y: scale }
        : { x: data.scale, y: data.scale };
      this._pixiField.viewport.x = data.x;
      this._pixiField.viewport.y = data.y;
    }

    const shouldDoSyncRendering = LS.getRaw(LSKey.syncFbRender);

    if (shouldDoSyncRendering) {
      this.renderNodes();
    } else {
      await this.asyncRenderNodes(() => canceled);
      if (canceled) {
        return;
      }
    }

    this._eventEmitter.emit(FlowControllerNodesCreatedEvent);

    if (!viewportLocalInfo) {
      this.fitScreen();
    }
    if (this.updateFlowPositionInterval) {
      clearInterval(this.updateFlowPositionInterval);
    }

    if (!this._pixiField.isViewOnly()) {
      this.updateFlowPositionInterval = setInterval(() => {
        if (!document.hidden) {
          const data = {
            scale: this._pixiField.viewport.scale.x,
            x: this._pixiField.viewport.x,
            y: this._pixiField.viewport.y,
          };
          LS.setRaw(`${this.flow.id}_viewport`, JSON.stringify(data));
        }
      }, 5000);
    }

    // eslint-disable-next-line consistent-return
    return new Promise<void>((resolve) => {
      if (this.imageLoader.isEmpty()) {
        // to give HTMLText "time/place in queue" to render
        setTimeout(resolve);
      } else {
        const unsubscriber = this.imageLoader.onCompleted(() => {
          unsubscriber();
          setTimeout(resolve);
        });
      }
    });
  }

  async markStartingPoint(node: Node) {
    // eslint-disable-next-line no-param-reassign
    node.startingPoint = true;
    node.renderNode();
    const rootBlock = this.flow.root;
    let currentStartingPoint;
    if (nonEmptyArray(rootBlock.next_block_ids)) {
      currentStartingPoint = this.nodeById(rootBlock.next_block_ids?.[0]!);
    }
    if (currentStartingPoint && currentStartingPoint !== node) {
      currentStartingPoint.startingPoint = false;
      currentStartingPoint.renderNode();
    }
    rootBlock.next_block_ids = [node.id];
    try {
      updateRootBlock(rootBlock.id, rootBlock.title, rootBlock.next_block_ids);
      const rootBlockNode = this.getBlockNode(rootBlock.id);
      rootBlockNode?.renderNode();
    } catch {
      toaster.show({
        type: ServiceMessageType.error,
        payload: {
          message: i18next.t(
            'modernComponents.FlowBuilder.views.controller.markAsStartingPointError',
            {
              block: node.block.title,
            },
          ),
        },
      });
    }
  }

  blockById(id: string) {
    return this._blocksTitlesMap[id];
  }

  groupById(id: string) {
    return this._groupsMap[id];
  }

  segmentById(id: string) {
    return this._segmentsMap[id];
  }

  nodeById(id: string) {
    return this._nodesMap[id];
  }

  viewInNodeByCardId(nodeId: string, cardId: string) {
    return this.nodeById(nodeId).blockView._cardsLayout.viewByCardId(cardId);
  }

  async createBlock({
    position,
    subtype,
    contextType,
    extraPluginId,
    context,
    onCreated,
    onError,
    cards = [],
  }: CreateBlockArgs) {
    const predefinedCards = (
      [
        context.sourcePlugin
          ? createCustomPlugin(context.sourcePlugin, subtype, this.flow)
          : undefined,
        extraPluginId
          ? createDefaultPlugin(extraPluginId as PluginType, this.flow)
          : undefined,
        ...cards,
      ].filter(Boolean) as Card[]
    ).map(removeTypename);

    logFlowEvent('block', 'add block', {
      ...context,
      extraPluginId,
      type: subtype,
      ...getAdditionalPropertyBagField(
        getEntryPointCard(predefinedCards as Card[]),
      ),
    });

    return createFlowBlock({
      flowId: this.flow.id,
      subtype,
      contextType,
      position,
      cards: predefinedCards,
      callback: (block) =>
        this.onBlockAdded(block, {
          extraPluginId,
          subtype,
          position,
          callback: onCreated,
        }),
      onError,
      blockTitle: getBlockTitle({
        subtype,
        context_type: contextType,
        cards: predefinedCards as WithPluginId[],
      }),
      messageTag: getMessageTagForContentBlock(context.sourcePlugin, subtype),
      otnPurpose: getOtnPurposeForContentBlock(subtype),
    });
  }

  onBlockAdded = async (
    block: FlowBlock,
    {
      extraPluginId,
      subtype,
      position,
      callback,
    }: {
      extraPluginId?: string;
      subtype?: string;
      position?: any;
      callback?: any;
    } = {},
  ) => {
    if (position) {
      // eslint-disable-next-line no-param-reassign
      block.position_in_flow = position;
    }
    if (subtype === BLOCK_SUBTYPES.entrypoint) {
      entryPointsEmitter.emit(EntryPointEventTrigger.flowBuilder, {
        data: {
          event: EntryPointsEvents.create,
          pluginId: extraPluginId,
          flowId: this.flow.id,
        },
      });
    }

    if (
      extraPluginId === PluginType.persistent_menu_entry_point &&
      // @ts-ignore
      getEntryPointCard(block.cards)?.needCreateRedirects
    ) {
      const updatedBlocks = await getBlocks(this.flow.id);
      const newNode = preparePersistentMenuFromConfigureTab(
        block,
        updatedBlocks,
      );
      callback?.(newNode);
      return;
    }

    if (block.cards?.[0]) {
      // @ts-ignore
      // eslint-disable-next-line no-param-reassign
      block.cards[0].isEditing = true;
    }

    this.flow.blocks.push(block);
    await refetchFlowBlockAndUpdateInFlowBlocksCache(block.id, this.flow); // update GQL cache for Apollo based plugins
    const newNode = new Node(block, this);
    this.addNode(newNode);
    this._pixiField.fixBlockPosition(newNode.blockView, 0);
    // TODO: use callback ids concatenation to support multiple blocks selection

    if (this.validateIfBlockCanBeCopied(block)) {
      this.setActiveBlocksIds(() => [block.id]);
    }
    callback?.(newNode);
    flowBuilderEventEmitter.emit(FlowBuilderEvent.blockAdded);
  };

  async fetchAndRenderBlock(blockId: string): Promise<void> {
    const block: any = await refetchFlowBlock(blockId, this.flow);
    // @ts-ignore
    const entryPointCard: any = getEntryPointCard(block.cards);
    this.flow.blocks.push(block);
    const newNode = new Node(block, this);
    this.addNode(newNode);
    if (
      entryPointCard?.plugin_id === PluginType.persistent_menu_entry_point &&
      entryPointCard?.config?.localized_items.length !== 0
    ) {
      const updatedBlocks = await getBlocks(this.flow.id);
      preparePersistentMenuFromConfigureTab(block, updatedBlocks);
    }
  }

  addNode(node: Node): void {
    // eslint-disable-next-line no-param-reassign
    node.controller = this;
    const oldNode = this.nodeById(node.id);
    if (typeof oldNode !== 'undefined') {
      oldNode.blockView.destroy();
    }
    this._nodesMap[node.id] = node;
    node.renderNode();
    this._pixiField.viewport.addChild(node.blockView as any);
    node.blockView.moveToTop();
  }

  removeNode(node: Node): void {
    // eslint-disable-next-line no-param-reassign
    node.blockView.removing = true;
    blockControl().unsubscribe(node.blockView);
    node.blockView.renderNode();
    removeBlock(
      node.id,
      async () => {
        node.blockView.hide();
        if (this._nodeToEdit === node) {
          this._nodeToEdit = null;
        }
        delete this._nodesMap[node.id];
        // @ts-ignore
        node.removeAllLinks();
        // to give all inner destroys time to happen
        setTimeout(() => {
          node.blockView.destroy();
        });
        updateFlowBlocksCacheAfterDeleting(
          this.flow.bot.id,
          this.flow.id,
          node.block.id,
        );
        flowBuilderEventEmitter.emit(FlowBuilderEvent.blockRemoved);
        if (node.block.subtype === BLOCK_SUBTYPES.entrypoint) {
          entryPointsEmitter.emit(EntryPointEventTrigger.flowBuilder, {
            data: {
              event: EntryPointsEvents.delete,
              flowId: this.flow.id,
            },
          });
        }
      },
      () => {
        // eslint-disable-next-line no-param-reassign
        node.blockView.removing = false;
        blockControl(node.blockView);
        node.blockView.renderNode();
        toaster.show({
          type: ServiceMessageType.error,
          payload: {
            message: window.i18next.t(
              'controller-string--212-cant-remove-the-block-server-is-not-available',
            ),
          },
        });
      },
    );
  }

  allNodes(): Node[] {
    return Object.values(this._nodesMap);
  }

  allNodesWhenReady(): Promise<Node[]> {
    return new Promise((resolve) => {
      const nodes = this.allNodes();
      if (nodes.length > 0) {
        resolve(nodes);
      } else {
        const flowControllerNodesCreatedHandler = () => {
          resolve(this.allNodes());
          this._eventEmitter.off(
            FlowControllerNodesCreatedEvent,
            flowControllerNodesCreatedHandler,
          );
        };
        this._eventEmitter.on(
          FlowControllerNodesCreatedEvent,
          flowControllerNodesCreatedHandler,
        );
      }
    });
  }

  destroy(): void {
    this.cancelDrawing?.();
    if (this.updateFlowPositionInterval) {
      clearInterval(this.updateFlowPositionInterval);
    }
    const children = this._pixiField.getViewPortAllChildren();
    children.forEach((child) => {
      try {
        this.destroyChild(child);
      } catch (error) {
        log.error({
          error,
          msg: 'Flow builder controller child destroy error',
          data: { label: 'flow_builder_core' },
        });
      }
    });
    this.allNodes().forEach((node) => {
      // @ts-ignore
      node.destroy();
    });

    // @ts-ignore
    delete this._nodesMap;
    // @ts-ignore
    delete this._blocksTitlesMap;
    // @ts-ignore
    delete this._segmentsMap;
    // @ts-ignore
    delete this._groupsMap;

    this._nodesMap = {};
    this._blocksTitlesMap = {};
    this._segmentsMap = {};
    this._groupsMap = {};
    this.viewportDragging = false;
    /**
     * При редиректе на другой флоу, путем дабл клика по айтему редирект плагина,
     * остается его оверлей, его надо анмаунтить
     */
    overlayEventEmitter.emit(FlowBuilderOverlayEvent.unmount);
  }

  destroyChild(child: any) {
    this._pixiField.hoverHandler().remove(child);
    this._pixiField.cursorHandler().removeCursor(child);
    this._pixiField.eventHandler().remove(child);
    if (child.zoomedFunc) {
      this._pixiField.viewport.off('zoomed', child.zoomedFunc);
    }
    if (child instanceof Sprite) {
      return;
    }
    if (child instanceof Graphics) {
      if (child.geometry) {
        // @ts-ignore
        child.geometry.dispose();
      }
    }
    child.destroy();
  }

  private getDisconnectedSubgraphNodes(startNodes: Node[]) {
    const visited: string[] = [];
    const queue: Node[] = [...startNodes];

    while (queue.length) {
      const curr = queue.pop();

      if (!curr) {
        break;
      }

      if (visited.includes(curr?.id as string)) {
        break;
      }

      visited.push(curr.id);

      if (curr?.outLinks) {
        queue.push(
          ...Object.values(curr.outLinks)
            .flat(1)
            .map((link) => link.toView?._node!),
        );
      }
    }

    const disconnectedNodes = omit(visited, this._nodesMap);

    return Object.values(disconnectedNodes) as Node[];
  }

  async organizeAllBlocks() {
    // @ts-ignore
    const startNode = this.nodeById(this.flow.root.next_block_ids);
    const entryPointsNodes = Object.values(this._nodesMap).filter(
      ({ block: { subtype } }) => subtype === BLOCK_SUBTYPES.entrypoint,
    );
    const nodesWithNoInLinks = getNodesWithNoInLinks(this._nodesMap);
    const entryNodes = [
      ...entryPointsNodes,
      startNode,
      ...nodesWithNoInLinks,
    ].filter(Boolean);
    const disconnectedSubgraphNodes =
      this.getDisconnectedSubgraphNodes(entryNodes);
    const startNodes = entryNodes.concat(disconnectedSubgraphNodes);

    if (startNodes.length) {
      const { oldPositions, newPositions } = await organizeBlocks(
        this.flow.id,
        startNodes,
        this._nodesMap,
      );
      const newPositionsMap = newPositions.reduce((acc, current) => {
        acc[current.id] = current.position;
        return acc;
      }, {} as Record<string, NodePosition['position']>);

      const isSamePositionsArray = oldPositions.every(({ id, position }) =>
        isSameNotEmptyPosition(position, newPositionsMap[id]),
      );

      if (!isSamePositionsArray) {
        this._oldPositions = oldPositions;
      }

      toaster.show({
        type: ServiceMessageType.default,
        payload: {
          message: window.i18next.t(
            'controller-string-1950-your-blocks-have-been-organized',
          ),
          buttonLabel: window.i18next.t('controller-string-2641-undo'),
          timeout: 10000,
          onButtonClick: async () => {
            logFlowEvent(undefined, 'click undo organize blocks on toaster');
            toaster.hide();
            await organizeBlocksToTargetPositions(
              this.flow.id,
              this._nodesMap,
              this._oldPositions!,
            );
            this._oldPositions = null;
            logFlowEvent(undefined, 'undo organize blocks');
          },
        },
      });
    }
  }

  fitScreen(): void {
    fitScreen(Object.values(this._nodesMap));
  }

  getFlowId() {
    return this.flow.id;
  }

  markFlowElement(
    sourceBlockId?: string,
    sourceCardId?: string,
    focus = false,
  ) {
    const blockId =
      this.flow.blocks.find((block) => block.source_block_id === sourceBlockId)
        ?.id ?? '';

    const blockNode = this.getBlockNode(blockId);

    if (!blockNode) return noop;

    this.focusedBlockId = blockId;
    let offsetTop;
    let height;

    if (sourceCardId) {
      const cardView = blockNode.blockView.findCardViewBySourceId(sourceCardId);
      if (cardView) {
        this.focusedBlockId = undefined;
        height = cardView.height();
        offsetTop = cardView.y;
        cardView.changeLayoutBgProps({
          stroke: HEXColors.blue,
          strokeWidth: 3,
        });
      }
    }

    let movedToAnotherStep = false;
    fitBlockPartInScreen(blockNode, offsetTop, height).then((finished) => {
      if (movedToAnotherStep || !finished) return;
      const blockNode = this.getBlockNode(blockId);
      if (!blockNode) return;
      if (sourceCardId) {
        const cardView =
          blockNode.blockView.findCardViewBySourceId(sourceCardId);
        if (focus) {
          // @ts-expect-error
          cardView?.startEditing?.();
        }
      }
      blockNode.renderNode();
    });

    return () => {
      movedToAnotherStep = true;
      this.focusedBlockId = undefined;
      const blockNode = this.getBlockNode(blockId);
      if (sourceCardId && blockNode) {
        const cardView =
          blockNode.blockView.findCardViewBySourceId(sourceCardId);
        cardView?.revertLayoutBgChanges();
        // @ts-expect-error
        cardView?.stopEditing?.();
      }
      blockNode?.renderNode();
    };
  }

  async focusOnBlock(blockId: string) {
    if (this._nodesMap[blockId]) {
      const params = new URLSearchParams(document.location.search.slice(1));
      params.delete('blockId');
      window.history.replaceState(
        null,
        // @ts-ignore
        null,
        params.values.length > 0 ? `?${params}` : undefined,
      );

      this.focusedBlockId = blockId;
      const focusedHasFinished = await fitScreen([this._nodesMap[blockId]]);
      if (!focusedHasFinished) {
        return;
      }
      this._nodesMap[blockId].renderNode();
      this.focusedBlockId = undefined;
      setTimeout(() => {
        if (this._nodesMap[blockId]) {
          this._nodesMap[blockId].renderNode();
        }
      }, FOCUSED_BLOCK_SELECTION_TIMEOUT);
    }
  }

  getBlockNode(blockId: string): Node | null {
    return this._nodesMap[blockId] ?? null;
  }

  updateFlowBuilderPage = (
    status: BotStatus,
    pageId?: string | null,
    verifiedPermissions?: VerifiedPermissions[],
  ): void => {
    if (status !== BotStatus.noPage && pageId) {
      this.flow = getUpdatedFlowData(this.flow, pageId);

      this.flow.verifiedPermissions = verifiedPermissions ?? null;
      this.allNodes().forEach(({ blockView }) => {
        blockView.renderNode();
      });
    }
  };

  updateEntryPointEnableState = (
    _flowId: string,
    entryPointId: string,
  ): void => {
    this.allNodes().forEach(({ blockView }) => {
      if (blockView._node.id === entryPointId) {
        blockView.blockTitleView.updateEntryPointEnableState(true);
      }
    });
  };

  addLoadingView(location: Point): BlockLoadingView {
    const blockLoadingView = new BlockLoadingView(location);
    blockLoadingView.renderElement();
    // seems like TS don't work with very long chain of inheritance
    this._pixiField.viewport.addChild(blockLoadingView as any);
    return blockLoadingView;
  }

  removeLoadingView(blockLoadingView: BlockLoadingView) {
    // seems like TS don't work with very long chain of inheritance
    this._pixiField.viewport.removeChild(blockLoadingView as any);
    blockLoadingView.destroy();
  }

  isBlockActive(id: string): boolean {
    return this.activeBlocksIds.includes(id);
  }

  validateIfBlockCanBeCopied(block: { subtype: string | null }) {
    return !isRootBlock(block.subtype as string);
  }

  setActiveBlocksIds(ids: string[] | ((prevIds: string[]) => string[])): void {
    const prevActiveBlockIds = this.activeBlocksIds.slice();
    this.activeBlocksIds =
      typeof ids === 'function' ? ids(prevActiveBlockIds) : ids;
    prevActiveBlockIds.forEach((id) => {
      if (!this.isBlockActive(id)) {
        this.getBlockNode(id)?.renderNode();
      }
    });
  }

  unsetActiveBlocksIds(): void {
    this.setActiveBlocksIds([]);
  }

  get blocksTitlesMap(): Readonly<Record<string, BlockTitle>> {
    return this._blocksTitlesMap;
  }

  addBlockTitle = (blockTitle: BlockTitle) => {
    this._blocksTitlesMap[blockTitle.id] = blockTitle;
  };
}
