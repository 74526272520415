import { sendEvent } from '@utils/Analytics';
import React, { useCallback, useEffect, useState } from 'react';
import { YouTubeFramePlayer } from '../../../YouTubeFramePlayer';
import { getFlowController } from '../../PixiFieldRepository';
import { PortalForYoutubePlayer } from '../../../PortalForYoutubePlayer';
import { getEntryPointCard } from '../../views/entry-points/common/utils';
import { outsideBlockFixedViewsService } from '../../views/services';
import { WatchVideoButtonView } from './WatchVideoButtonView';
import { WATCH_VIDEO_CONFIGS_BY_ENTRY_POINT } from './consts';

export const EntryPointTutorialVideoPlayer: React.FC = () => {
  const [currentEntryPointType, setCurrentEntryPointType] = useState<
    string | null
  >(null);
  const [prevEntryPointType, setPrevEntryPointType] = useState<string | null>(
    null,
  );
  const [blockId, setBlockId] = useState<string | undefined>();

  const prepareEventData = useCallback(
    (entryPointType: string) => ({
      category: 'flow',
      label: entryPointType,
      propertyBag: {
        blockId,
        flowId: getFlowController()?.flow.id,
        isNewWatchVideo: true,
      },
    }),
    [blockId],
  );

  const sendEventVideoVisibility = useCallback(
    (isVisibleVideoValue: boolean, entryPointType: string) => {
      sendEvent({
        action: `video ${isVisibleVideoValue ? 'opened' : 'closed'}`,
        ...prepareEventData(entryPointType),
      });
    },
    [prepareEventData],
  );

  const buttonClickHandler = useCallback(({ pluginType, blockId }) => {
    setCurrentEntryPointType((currentEntryPointType) => {
      setPrevEntryPointType(currentEntryPointType);
      if (currentEntryPointType && pluginType === currentEntryPointType) {
        setBlockId(undefined);
        return null;
      }
      setBlockId(blockId);
      return pluginType;
    });
  }, []);

  const closeButtonClickHandler = useCallback(({ pluginType }) => {
    setCurrentEntryPointType((currentEntryPointType) => {
      setPrevEntryPointType(currentEntryPointType);
      if (currentEntryPointType && pluginType === currentEntryPointType) {
        setBlockId(undefined);
        return null;
      }
      return currentEntryPointType;
    });
  }, []);

  useEffect(() => {
    if (prevEntryPointType) {
      sendEventVideoVisibility(false, prevEntryPointType);
    }
  }, [prevEntryPointType, sendEventVideoVisibility]);

  useEffect(() => {
    if (currentEntryPointType) {
      sendEventVideoVisibility(true, currentEntryPointType);
    }
  }, [currentEntryPointType, sendEventVideoVisibility]);

  useEffect(() => {
    return outsideBlockFixedViewsService.addOutsideView({
      createView: (node) =>
        new WatchVideoButtonView({
          node,
          onClick: buttonClickHandler,
          onCloseClick: closeButtonClickHandler,
        }),
      positionFunction: ({ x, y }) => {
        return { x, y: y - 36 };
      },
      gone: ({ block: { cards } }) =>
        !Object.keys(WATCH_VIDEO_CONFIGS_BY_ENTRY_POINT).includes(
          getEntryPointCard(cards)?.plugin_id || '',
        ),
    });
  }, [buttonClickHandler, closeButtonClickHandler]);

  if (!currentEntryPointType) {
    return null;
  }

  return (
    <PortalForYoutubePlayer>
      <YouTubeFramePlayer
        videoId={
          WATCH_VIDEO_CONFIGS_BY_ENTRY_POINT[currentEntryPointType].videoHash
        }
        onChangeSize={() => {
          sendEvent({
            action: 'video changed size',
            ...prepareEventData(currentEntryPointType),
          });
        }}
        onMoveFinished={() => {
          sendEvent({
            action: 'player moved',
            ...prepareEventData(currentEntryPointType),
          });
        }}
        onClose={() => {
          sendEventVideoVisibility(false, currentEntryPointType);
          setCurrentEntryPointType(null);
        }}
      />
    </PortalForYoutubePlayer>
  );
};
