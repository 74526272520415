import gql from 'graphql-tag';

export const FOLLOW_UPS_QUERY = gql`
  query FollowUpsQuery($flowId: ID!) {
    flowFollowUps(flowId: $flowId) {
      id
      text
      delay_ms
    }
  }
`;

export const FLOW_STATS_QUERY = gql`
  query FlowStatsQuery($flowId: ID!) {
    flowStats(id: $flowId) {
      id
      blocks {
        id
        counters {
          id
          counterId
          stats {
            sent
            seen
            clicked
          }
        }
      }
    }
  }
`;

export const ADD_FLOW_FOLLOW_UP_MUTATION = gql`
  mutation AddFlowFollowUpMutation(
    $flowId: String!
    $text: String!
    $delay: Int!
  ) {
    addFlowFollowUp(flowId: $flowId, text: $text, delay: $delay) {
      id
      text
      delay_ms
    }
  }
`;

export const EDIT_FLOW_FOLLOW_UP_MUTATION = gql`
  mutation EditFlowFollowUpMutation(
    $flowId: String!
    $followUpId: String!
    $text: String!
    $delay: Int!
  ) {
    editFlowFollowUp(
      flowId: $flowId
      followUpId: $followUpId
      text: $text
      delay: $delay
    ) {
      id
      text
      delay_ms
    }
  }
`;

export const REMOVE_FLOW_FOLLOW_UP_MUTATION = gql`
  mutation RemoveFlowFollowUpMutation($flowId: String!, $followUpId: String!) {
    removeFlowFollowUp(flowId: $flowId, followUpId: $followUpId) {
      success
    }
  }
`;
