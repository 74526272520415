import { Container, Graphics, InteractionEvent, Sprite } from 'pixi.js-legacy';
import { AddToLayoutProps, UpdateParams } from '../Layouts/types';
import { getPixiField, getPixiFieldStrict } from '../../../PixiFieldRepository';
import { Point, ShapeProps } from './types';

export const DESTROY_OPTIONS = {
  children: true,
  texture: true,
  baseTexture: true,
};

export class Shape<
  T extends ShapeProps = ShapeProps,
  View extends Container = Sprite | Graphics,
> {
  TEST_NAME = 'Shape';

  _props: T;
  _shape: View;
  private destroyed?: boolean;
  _needUpdate: boolean;
  layoutProperties?: AddToLayoutProps;
  gone?: boolean; // TODO check in commentsAutoReply
  rendered?: boolean;
  name?: string;

  constructor(props: T, ShapeClass: { new (): View }) {
    this._props = {
      width: 0,
      height: 0,
      x: 0,
      y: 0,
      opacity: 1,
      stroke: 'black',
      strokeWidth: 0,
      ...props,
    };
    this._needUpdate = true;
    this._shape = new ShapeClass();
    if (props.name) this._shape.name = props.name;
    this.move();
  }

  shape() {
    return this._shape;
  }

  zOrder(z?: number) {
    if (z !== undefined) {
      this._shape.zIndex = z;
    }
    return this._shape.zIndex;
  }

  destroy({ destroyBaseTexture } = { destroyBaseTexture: true }) {
    if (this.destroyed) {
      return;
    }
    this.destroyed = true;
    getPixiFieldStrict().hoverHandler().remove(this);
    getPixiFieldStrict().eventHandler().remove(this);
    getPixiFieldStrict().cursorHandler().removeCursor(this);
    if (this._shape instanceof Sprite) {
      if (this._shape.texture) {
        this._shape.destroy(destroyBaseTexture ? DESTROY_OPTIONS : undefined);
      }
      return;
    }
    try {
      this._shape.destroy(DESTROY_OPTIONS);
    } catch {
      // todo understand why destroy fails on Graphics objects
      // eslint-disable-next-line no-empty
    }
  }

  moveToTop() {
    if (this._shape.parent) {
      const { children } = this._shape.parent;
      const zIndexes = children.map((child) => child.zIndex);
      this._shape.zIndex = Math.max(...zIndexes) + 1;
    }
  }

  moveToBottom() {
    if (this._shape.parent) {
      const { children } = this._shape.parent;
      const zIndexes = children.map((child) => child.zIndex);
      this._shape.zIndex = Math.min(...zIndexes) - 1;
    }
  }

  globalPosition(scaleX?: number): Point {
    const globalPosition = this._shape.getGlobalPosition();
    if (scaleX === undefined) {
      return globalPosition;
    }
    return {
      x: (globalPosition.x / scaleX - this._shape.x + this.x) * scaleX,
      y: globalPosition.y,
    };
  }

  on(event: string, func: (e: InteractionEvent) => void) {
    this.interactive(true);
    event.split(' ').forEach((e) => {
      this._shape.on(e, func);
    });
    return this;
  }

  off(event: string, func?: () => void) {
    event.split(' ').forEach((e) => {
      this._shape.off(e, func);
    });
    return this;
  }

  _drawShape() {}

  interactive(flag: boolean) {
    this._shape.interactive = flag;
    return this;
  }

  show() {
    this._shape.visible = true;
    getPixiField()?.render();
  }

  hide() {
    this._shape.visible = false;
    getPixiField()?.render();
  }

  move() {
    this._shape.x = this._props.x || 0;
    this._shape.y = this._props.y || 0;
  }

  set x(value: number) {
    if (this._props.x !== value) {
      this._props.x = value;
      this.move();
    }
  }

  get x(): number {
    return this._props.x!;
  }

  set y(value: number) {
    if (this._props.y !== value) {
      this._props.y = value;
      this.move();
    }
  }

  get y(): number {
    return this._props.y!;
  }

  width(w?: number) {
    const result = this._changeProp<number>('width', w);
    return result || this._shape.width;
  }

  height(h?: number) {
    const result = this._changeProp<number>('height', h);
    return result || this._shape.height;
  }

  fill(value?: string) {
    return this._changeProp('fill', value);
  }

  opacity(value?: number) {
    return this._changeProp('opacity', value);
  }

  stroke(value?: string) {
    return this._changeProp('stroke', value);
  }

  strokeWidth(value?: number) {
    return this._changeProp('strokeWidth', value);
  }

  strokeOpacity(value?: number) {
    return this._changeProp('strokeOpacity', value);
  }

  updateProperties(props: Partial<T>) {
    Object.keys(props).forEach((key) => {
      const newValue = props[key as keyof T];
      const oldValue = this._props[key as keyof T];
      if (newValue !== undefined && newValue !== oldValue) {
        this._changeProp(key as keyof T, newValue);
        if (key === 'x' || key === 'y') {
          this.move();
        } else {
          this._needUpdate = true;
        }
      }
    });
    if (this._needUpdate) {
      this.move();
      this.renderElement();
    }
  }

  _changeProp<V = string>(property: keyof T, value?: V): V {
    if (value === undefined) {
      return this._props[property] as unknown as V;
    }
    const currentValue = this._props[property] as unknown as V;
    if (currentValue !== value) {
      this._props[property] = value as any;
      this._needUpdate = true;
    }
    return value;
  }

  onBeforeRender() {}

  /**
   * Renders entire element
   *
   * @param _ just for same API as renderElement of Layout
   * @param disabledRenderAfterUpdate {boolean?} turns off force render after the update (render should be triggered only by root function)
   */
  renderElement({ disabledRenderAfterUpdate }: UpdateParams = {}) {
    if (this._needUpdate) {
      this._needUpdate = false;
      this._drawShape();
      if (!disabledRenderAfterUpdate) {
        getPixiFieldStrict().render();
      }
    }
  }
}
