import React, { useCallback } from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Clipboard } from '@services/index';
import { useToaster } from '@ui/Toaster';
import { getCurrentBroadcastId, useCurrentBotId } from '@utils/Routing';
import { useEventListener } from 'cf-common/src/hooks/useEventListener';
import debounce from 'lodash-es/debounce';
import { Icon } from '@ui/Icon';
import { Platform } from '@globals';
import { useCloneFlowBlocks } from '../../api/cloneFlowBlocks';
import { getFlowController, getPixiField } from '../../PixiFieldRepository';
import { FlowBlock } from '../../types';
import { logFlowEvent } from '../../utils/Analytics';
import { BlockLoadingView } from '../../views/block_loading_view';
import {
  CANVAS_CENTER,
  getBlocksPositionsOffsetFromMousePosition,
} from './helpers';
import { ClipboardFlowBlock } from './types';

const INPUTABLE_HTML_TAGS = ['TEXTAREA', 'INPUT'];

const isTargetInputable = (element: HTMLElement) => {
  return Boolean(
    INPUTABLE_HTML_TAGS.includes(element.tagName) ||
      // for bubble editor
      element.dataset.slateContent ||
      element.parentElement?.dataset.slateLeaf,
  );
};

interface CopyPasteConfig {
  disabled: boolean;
}

export const useCopyPaste = (flowId: string, { disabled }: CopyPasteConfig) => {
  const { addToaster } = useToaster();
  const botId = useCurrentBotId();
  const { cloneFlowBlocks, loading } = useCloneFlowBlocks();
  const { t } = useSafeTranslation();
  const isReengageTab = !!getCurrentBroadcastId();

  const onCopy = useCallback(
    debounce(async (event: ClipboardEvent) => {
      if (
        isReengageTab ||
        !event.target ||
        isTargetInputable(event.target as HTMLElement) ||
        disabled
      ) {
        return;
      }
      const controller = getFlowController();
      if (controller) {
        const { activeBlocksIds, flow } = controller;
        logFlowEvent(undefined, 'keyboard copy', {
          blocks: controller.activeBlocksIds,
          botId: flow.botId,
          flowId,
        });
        const isNothingCopiedButHasInClipboard = !activeBlocksIds.length;
        if (isNothingCopiedButHasInClipboard) {
          return;
        }
        Clipboard.overrideClipboardEvent<ClipboardFlowBlock[]>(
          event,
          activeBlocksIds.map((blockId) => {
            const node = controller.getBlockNode(blockId);
            return {
              blockId,
              flowId,
              flowPlatform: flow.platform ?? Platform.facebook,
              position: node && { x: node.x, y: node.y },
            };
          }),
        );
        if (activeBlocksIds.length) {
          addToaster({
            type: 'info',
            content: `${
              controller.getBlockNode(activeBlocksIds[0])?.block.title ??
              window.i18next.t('useCopyPaste-string-1379-unknown')
            }${window.i18next.t('useCopyPaste-Template-1495-block-is-copied')}`,
            timeout: 1000,
            closeButton: true,
          });
        }
      }
    }, 200),
    [flowId, disabled], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleCloneFlowBlocks = useCallback(
    async (flowBlocks: ClipboardFlowBlock[]) => {
      const controller = getFlowController();
      if (flowId && controller) {
        const mouseLocalPosition = getPixiField()?.getMouseLocalPosition();
        const viewsPositions = getBlocksPositionsOffsetFromMousePosition(
          mouseLocalPosition ?? CANVAS_CENTER,
          flowBlocks,
        );
        const loadingViews: BlockLoadingView[] = [];

        flowBlocks.forEach((_, index) => {
          loadingViews.push(controller.addLoadingView(viewsPositions[index]));
        });

        const blocksParentFlow = flowBlocks[0].flowId;
        const { blocks, errors } = await cloneFlowBlocks(
          blocksParentFlow,
          flowId,
          flowBlocks.map(({ blockId: id }, index) => ({
            id,
            position: viewsPositions[index],
          })),
          { botId, flowId },
        );

        flowBlocks.forEach((_, index) => {
          const blockLoadingView = loadingViews[index];
          if (blockLoadingView) {
            controller.removeLoadingView(blockLoadingView);
          }
        });

        if (!errors?.length && blocks.length) {
          blocks.forEach((block) => {
            controller.onBlockAdded(block as FlowBlock);
          });
        }
      }
    },
    [botId, cloneFlowBlocks, flowId],
  );

  const onPaste = useCallback(
    debounce(async (event: ClipboardEvent) => {
      if (
        isReengageTab ||
        !event.target ||
        isTargetInputable(event.target as HTMLElement) ||
        loading ||
        disabled
      ) {
        return;
      }

      const flowBlocks = await Clipboard.getClipboardData<
        ClipboardFlowBlock[]
      >();

      if (!flowBlocks?.length) {
        return;
      }

      const { botId, platform } = getFlowController()?.flow ?? {};
      logFlowEvent(undefined, 'keyboard paste', {
        botId,
        blocks: flowBlocks?.map((v) => v.blockId),
        fromFlowId: flowBlocks?.[0]?.flowId,
        toFlowId: flowId,
      });
      const isSamePlatform = flowBlocks?.every(
        (v) => v.flowPlatform === platform,
      );

      if (isSamePlatform) {
        handleCloneFlowBlocks(flowBlocks!);
      } else {
        addToaster({
          type: 'info',
          content: t(
            'modernComponents.FlowBuilder.actions.copyPaste.pasteToAnotherPlatformToasterTitle',
          ),
          timeout: 3000,
          leftIcon: <Icon icon="warning" color="yellow" />,
        });
      }
    }, 200),
    [flowId, handleCloneFlowBlocks, loading, disabled],
  );

  useEventListener<ClipboardEvent>('copy', onCopy);
  useEventListener<ClipboardEvent>('paste', onPaste);
  // TODO: add onCut when cmd + z is ready
};
