import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  FlowBuilderOverlayEvent,
  MountOverlayEvent,
  MoveOverlayEvent,
  OverlayComponentEvent,
  overlayEventEmitter,
  OverlayEventPayload,
  OverlayOptions,
  OverlayType,
} from './events';
import { AvailableOverlays, AVAILABLE_OVERLAYS_DEFAULT } from './consts';
import * as css from './FlowBuilderOverlay.css';

export const FlowBuilderOverlay: React.FC<{
  availableOverlays?: AvailableOverlays;
}> = ({ availableOverlays = AVAILABLE_OVERLAYS_DEFAULT }) => {
  const overlayRef = useRef<HTMLDivElement | null>(null);

  const [value, setValue] = useState<any>(undefined);
  const [shouldRenderingOverlayType, setShouldRenderingOverlayType] =
    useState<OverlayType | null>(null);
  const [overlayOptions, setOverlayOptions] = useState<
    OverlayOptions | undefined
  >();

  useEffect(() => {
    const unsubscribers = [
      overlayEventEmitter.on<MountOverlayEvent>(
        FlowBuilderOverlayEvent.mount,
        ({ overlayType, getOverlayOptions }) => {
          const overlayOptions = getOverlayOptions();

          setValue(overlayOptions.initialValue);
          setOverlayOptions(overlayOptions);
          setShouldRenderingOverlayType(overlayType);
        },
      ),
      overlayEventEmitter.on(FlowBuilderOverlayEvent.unmount, () => {
        setShouldRenderingOverlayType(null);
        setValue('');
      }),
      overlayEventEmitter.on(FlowBuilderOverlayEvent.show, () => {
        if (overlayRef.current) {
          overlayRef.current.style.opacity = '1';
          overlayRef.current.style.pointerEvents = 'auto';
        }
      }),
      overlayEventEmitter.on(FlowBuilderOverlayEvent.hide, () => {
        if (overlayRef.current) {
          overlayRef.current.style.opacity = '0';
          overlayRef.current.style.pointerEvents = 'none';
        }
      }),
      overlayEventEmitter.on<MoveOverlayEvent>(
        FlowBuilderOverlayEvent.move,
        ({ position: { x, y, scale = 1, width, height } }) => {
          if (!overlayRef.current) {
            return;
          }
          overlayRef.current.style.transform = `translate(${x}px,${y}px) scale(${scale},${scale})`;
          if (width) {
            overlayRef.current.style.width = `${width}px`;
          }
          if (height) {
            overlayRef.current.style.height = `${height}px`;
          }
        },
      ),
    ];
    return () => {
      unsubscribers.forEach((fn) => fn());
    };
  }, []);

  const changeHandler = useCallback((value: any) => {
    setValue(value);
    overlayEventEmitter.emit<OverlayEventPayload<string>>(
      OverlayComponentEvent.change,
      {
        value,
        target: overlayRef.current as HTMLDivElement,
      },
    );
  }, []);

  const keyDownHandler = useCallback((event: any) => {
    overlayEventEmitter.emit<React.KeyboardEvent<HTMLElement>>(
      OverlayComponentEvent.keydown,
      event,
    );
  }, []);

  const doneHandler = useCallback(() => {
    overlayEventEmitter.emit(OverlayComponentEvent.done);
  }, []);

  if (!shouldRenderingOverlayType || !overlayOptions) {
    return null;
  }

  const OverlayComponent = availableOverlays[shouldRenderingOverlayType];

  if (!OverlayComponent) {
    throw new Error(
      `Cannot find overlay component for type ${shouldRenderingOverlayType}`,
    );
  }

  return (
    <div
      className={css.box}
      style={{
        zIndex: overlayOptions.zIndex,
      }}
    >
      <div
        ref={overlayRef}
        className={css.content}
        onMouseDown={(e) => {
          e.nativeEvent.stopPropagation();
        }}
      >
        <OverlayComponent
          options={overlayOptions}
          value={value}
          onChange={changeHandler}
          onKeyDown={keyDownHandler}
          onDone={doneHandler}
        />
      </div>
    </div>
  );
};
