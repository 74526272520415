import React from 'react';
import cn from 'classnames';
import { Block } from './Block';
import { KommoPluginConfig } from '@components/Plugins/KommoPlugin/KommoPlugin';
import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';
import { Input } from '@ui/Input';
import { ComboboxWithTriangleButton } from '@ui/SimpleCombobox';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { MenuItem } from '@ui/Menu';
import css from './KommoPlugin.css';
import { Spacer } from '@ui/Spacer';
import {
  QuantIds,
  getQuantValue,
  getRegularValue,
} from '@utils/DateTime/quant';

interface TaskViewProps {
  updateConfig(configState: Partial<KommoPluginConfig>): void;
  configState: KommoPluginConfig;
  users: Array<{ id: string; title: string }>;
  usersLoading: boolean;
}

const MAX_HOURS_IN_MILLISECONDS = getQuantValue(23, QuantIds.hours);

export const TaskView: React.FC<TaskViewProps> = ({
  updateConfig,
  configState,
  users,
  usersLoading,
}) => {
  const { t } = useSafeTranslation();

  const timeTypes = [
    { id: QuantIds.days, title: t('KommoPlugin.day') },
    { id: QuantIds.hours, title: t('KommoPlugin.hour') },
  ];

  const selectedTimeType =
    (configState.deadline_ms ?? 0) > MAX_HOURS_IN_MILLISECONDS
      ? timeTypes[0]
      : timeTypes[1];

  const timeValue = configState.deadline_ms
    ? getRegularValue(configState.deadline_ms, selectedTimeType.id)
    : 1;

  const taskName = configState.text;

  return (
    <>
      <Flex
        flexDirection="column"
        data-testid="kommo-plugin-task-view-container"
      >
        <Type size="12px" weight="medium" color="baseTertiary">
          {t('KommoPlugin.task')}
        </Type>
        <Spacer factor={2} />
        <Input
          data-testid="kommo-plugin-task-name"
          placeholder={t('KommoPlugin.addTaskName')}
          error={!taskName}
          value={taskName ?? ''}
          onChange={(e) =>
            updateConfig({ ...configState, text: e.target.value })
          }
        />
        {!taskName && (
          <>
            <Spacer factor={2} />
            <Type size="12px" color="accent4Dark">
              {t('KommoPlugin.requiredFiles')}
            </Type>
          </>
        )}
      </Flex>
      <Spacer factor={4} />
      <Flex alignItems="center">
        <Type>{t('KommoPlugin.deadlineIn')}</Type>
        <Spacer horizontalFactor={3} />
        <Input
          data-testid="kommo-plugin-time-value"
          containerClassName={css.numberInput}
          value={timeValue}
          onChange={(e) => {
            const newTimeValue =
              QuantIds.hours === selectedTimeType.id
                ? Math.max(Math.min(23, parseInt(e.target.value, 10)), 1)
                : Math.max(Math.min(365, parseInt(e.target.value, 10)), 1);

            updateConfig({
              ...configState,
              deadline_ms: getQuantValue(newTimeValue, selectedTimeType.id),
            });
          }}
          type="number"
        />
        <Spacer horizontalFactor={3} />
        <ComboboxWithTriangleButton
          data-testid="kommo-plugin-time-type"
          intent="secondary"
          items={timeTypes}
          className={cn(css.comboboxWithTriangleButton, css.fieldContainer)}
          selectedItem={selectedTimeType}
          onChange={(item) => {
            const newTimeValue =
              QuantIds.hours === item.id
                ? Math.max(Math.min(23, timeValue, 10), 1)
                : Math.max(Math.min(365, timeValue, 10), 1);
            updateConfig({
              ...configState,
              deadline_ms: getQuantValue(newTimeValue, item.id),
            });
          }}
        >
          {({ downshift, props: { currentItems, item, index } }) => (
            <MenuItem
              className={css.comboboxMenuItem}
              {...downshift.getItemProps({ item, index })}
              active={downshift.highlightedIndex === index}
              title={currentItems[index].title}
            />
          )}
        </ComboboxWithTriangleButton>
      </Flex>
      <Spacer factor={4} />
      <Block
        data-testid="kommo-plugin-assignee-optional"
        loading={usersLoading}
        title={t('KommoPlugin.assigneeOptional')}
        items={users}
        onChange={(value) =>
          updateConfig({
            assigneeId: Number(value.id),
            assigneeName: value.title,
          })
        }
        selectedItem={
          configState.assigneeId === null
            ? null
            : {
                id: String(configState.assigneeId),
                title:
                  configState.assigneeName ?? String(configState.assigneeId),
              }
        }
      />
    </>
  );
};
