import gql from 'graphql-tag';
import { FLOW_BLOCK_FRAGMENT } from '@components/Plugins/common/PluginGQL';

export const CLONE_FLOW_BLOCKS_MUTATION = gql`
  mutation CloneFlowBlocksMutation(
    $fromFlowId: String!
    $toFlowId: String!
    $blocks: [FlowBlockToCloneInput!]!
  ) {
    cloneFlowBlocks(
      fromFlowId: $fromFlowId
      toFlowId: $toFlowId
      blocks: $blocks
    ) {
      ...FlowBlockFragment
    }
  }
  ${FLOW_BLOCK_FRAGMENT}
`;
