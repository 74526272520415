import React from 'react';
import { EditorPanelPluginProps } from '../../../types';
import { tooltips } from '../../../../views/Menu/menu_view';
import { PluginHeader } from '../../common/PluginHeader';
import { Spacer } from '../../../../../../modern-ui/Spacer';
import { goToBlockPluginFragment_config as GoToBlockPluginConfig } from '../../../../../Plugins/GoToBlockPlugin/@types/goToBlockPluginFragment';
import { UserFilterControl } from '../../common/UserFilter';

export const GoToBlockPlugin: React.FC<
  EditorPanelPluginProps<GoToBlockPluginConfig>
> = ({ botId, pluginParams }) => {
  return (
    <>
      <PluginHeader
        title={window.i18next.t('GoToBlockPlugin-string-1142-condition')}
        tooltip={tooltips().condition.text}
      />
      <Spacer />
      {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
      <UserFilterControl
        botId={botId}
        config={pluginParams.pluginConfig.user_filter}
        saveConfig={() => pluginParams.savePlugin()}
        saveConfigAsync={(userFilter) =>
          pluginParams.savePluginAsync({
            ...pluginParams.pluginConfig,
            user_filter: userFilter,
          })
        }
        isLoading={pluginParams.saving}
        onConfigUpdate={(userFilter) =>
          pluginParams.setPluginConfigState({
            config: {
              ...pluginParams.pluginConfig,
              user_filter: userFilter,
            },
          })
        }
        validationErrors={pluginParams.validationErrors}
      />
    </>
  );
};
