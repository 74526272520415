import React from 'react';
import { Type } from '@ui/Type';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { HelpIconWithTooltip } from '../HelpIconWithTooltip';

export const UrlsListHelp: React.FC<{ targetText?: string }> = ({
  targetText = 'exclude',
}) => {
  const { t } = useSafeTranslation();

  return (
    <HelpIconWithTooltip
      content={
        <span>
          {t('modernComponents.plugins.common.UrlsListHelp.Use')}{' '}
          <Type color="blue" size="15px_DEPRECATED">
            {/* eslint-disable-next-line react/jsx-curly-brace-presence */}
            {'/*'}
          </Type>{' '}
          {t('modernComponents.plugins.common.UrlsListHelp.to')} {targetText}{' '}
          {t('modernComponents.plugins.common.UrlsListHelp.allPagesInA')}
          <br />
          {t('modernComponents.plugins.common.UrlsListHelp.eG')}{' '}
          <Type color="blue" size="12px">
            {/* eslint-disable-next-line react/jsx-curly-brace-presence */}
            /product/*
          </Type>{' '}
          {t(
            'modernComponents.plugins.common.UrlsListHelp.willTtAllPagesInProductDirectory',
            { targetText },
          )}
        </span>
      }
    />
  );
};
