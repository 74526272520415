import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { CheckboxWithTooltip } from './CheckBoxWithTooltip';
import { allowSkipLens } from '../../../../../views/plugins/QuickReplyPlugin/lenses';
import { QuickReplyPluginData } from '../types';

interface AllowSkipButtonProps extends QuickReplyPluginData {
  tooltipText: string;
}

export const AllowSkipButton: React.FC<AllowSkipButtonProps> = (props) => {
  const { t } = useSafeTranslation();

  return (
    <CheckboxWithTooltip
      data-testid="allow-skip-button"
      label={t(
        'modernComponents.FlowBuilder.views.components.CollectUserData.common.allowSkipButton',
      )}
      lens={allowSkipLens}
      {...props}
    />
  );
};
