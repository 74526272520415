import { getQuantValue, QuantIds } from '@utils/DateTime/quant';

export const RADIO_BUTTONS_GROUP_NAME = 'FollowUpsType';

export enum FollowUpsType {
  global = 'global',
  local = 'local',
}

export const NEW_FOLLOW_UP_DEFAULT_TEXT = '';
export const NEW_FOLLOW_UP_DEFAULT_DELAY = getQuantValue(23, QuantIds.hours);

export const FOLLOW_UP_TEMPLATE1_DELAY = getQuantValue(15, QuantIds.minutes);

export const FOLLOW_UP_TEMPLATE2_DELAY = getQuantValue(23, QuantIds.hours);
