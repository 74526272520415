import { Graphics } from 'pixi.js-legacy';
import { colorToHex } from '../../../views/utils';
import { Shape } from './Shape';
import { ShapeProps } from './types';

export class LeftArrow extends Shape<ShapeProps, Graphics> {
  TEST_NAME = 'LeftArrow';

  constructor(props: ShapeProps) {
    super(props, Graphics);
  }

  _drawShape() {
    const { _props: props, _shape: shape } = this;
    shape.clear();
    const color = props.fill;
    shape.beginFill(colorToHex(color));
    shape.moveTo(0, 0);
    shape.lineTo(props.width || 0, -(props.height || 0) / 2);
    shape.lineTo(props.width || 0, (props.height || 0) / 2);
    shape.lineTo(0, 0);
    shape.endFill();
  }
}

export class RightArrow extends LeftArrow {
  TEST_NAME = 'RightArrow';

  _drawShape() {
    super._drawShape();
    const shape = this.shape();
    shape.pivot.x = shape.width / 2;
    shape.rotation = Math.PI;
  }
}

export class DownArrow extends Shape<ShapeProps, Graphics> {
  TEST_NAME = 'DownArrow';

  constructor(props: ShapeProps) {
    super(props, Graphics);
    this._drawShape();
  }

  _drawShape() {
    const { _props: props, _shape: shape } = this;
    shape.clear();
    const color = props.fill;
    shape.beginFill(colorToHex(color));
    shape.moveTo(0, 0);
    shape.lineTo(props.width || 0, 0);
    shape.lineTo((props.width || 0) / 2, props.height || 0);
    shape.lineTo(0, 0);
    shape.endFill();
  }
}
