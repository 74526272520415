import { ServerStorageItemKeys } from '@utils/ServerStorage';
import { EntryPointTutorialYoutube } from '../../../../constants/externalLinks';
import { PluginType } from '../../../Plugins/common/PluginTypes';

interface WatchVideoConfig {
  videoHash: EntryPointTutorialYoutube;
  serverStorageKey: ServerStorageItemKeys;
}

export const WATCH_VIDEO_CONFIGS_BY_ENTRY_POINT: Record<
  string,
  WatchVideoConfig
> = {
  [PluginType.ref_link_entry_point]: {
    videoHash: EntryPointTutorialYoutube.botLink,
    serverStorageKey: ServerStorageItemKeys.isClosedBotLinkFlowTutorial,
  },
  [PluginType.comments_autoreply_entry_point]: {
    videoHash: EntryPointTutorialYoutube.commentsAutoreply,
    serverStorageKey:
      ServerStorageItemKeys.isClosedCommentsAutoreplyFlowTutorial,
  },
  [PluginType.ads_manager_ctm_entry_point]: {
    videoHash: EntryPointTutorialYoutube.clickToMessenger,
    serverStorageKey: ServerStorageItemKeys.isClosedCtmAdsFlowTutorial,
  },
  [PluginType.customer_chat_entry_point]: {
    videoHash: EntryPointTutorialYoutube.customerChat,
    serverStorageKey: ServerStorageItemKeys.isClosedCustomerChatFlowTutorial,
  },
  [PluginType.fb_page_entry_point]: {
    videoHash: EntryPointTutorialYoutube.facebookPage,
    serverStorageKey: ServerStorageItemKeys.isClosedFacebookPageFlowTutorial,
  },
  [PluginType.facebook_shops_entry_point]: {
    videoHash: EntryPointTutorialYoutube.facebookShops,
    serverStorageKey: ServerStorageItemKeys.isClosedFacebookShopsFlowTutorial,
  },
  [PluginType.persistent_menu_entry_point]: {
    videoHash: EntryPointTutorialYoutube.persistentMenu,
    serverStorageKey: ServerStorageItemKeys.isClosedPersistentMenuFlowTutorial,
  },
  [PluginType.send_to_messenger_entry_point]: {
    videoHash: EntryPointTutorialYoutube.sendToMessenger,
    serverStorageKey: ServerStorageItemKeys.isClosedSendToMessengerFlowTutorial,
  },
  [PluginType.ads_manager_sm_entry_point]: {
    videoHash: EntryPointTutorialYoutube.sponsoredMessages,
    serverStorageKey:
      ServerStorageItemKeys.isClosedSponsoredMessagesFlowTutorial,
  },
  [PluginType.instagram_story_mention_entry_point]: {
    videoHash: EntryPointTutorialYoutube.instagramStoryMention,
    serverStorageKey:
      ServerStorageItemKeys.isClosedInstagramStoryMentionFlowTutorial,
  },
  [PluginType.instagram_story_reply_entry_point]: {
    videoHash: EntryPointTutorialYoutube.instagramStoryReply,
    serverStorageKey:
      ServerStorageItemKeys.isClosedInstagramStoryReplyFlowTutorial,
  },
  [PluginType.instagram_comments_autoreply_entry_point]: {
    videoHash: EntryPointTutorialYoutube.instagramCommentsAutoreply,
    serverStorageKey:
      ServerStorageItemKeys.isClosedInstagramCommentsAutoreplyFlowTutorial,
  },
  [PluginType.instagram_ads_manager_ctm_entry_point]: {
    videoHash: EntryPointTutorialYoutube.instagramAds,
    serverStorageKey: ServerStorageItemKeys.isClosedInstagramAdsFlowTutorial,
  },
};
